.about {
	padding: 15px 0 100px;

	&__banner {
		height: 450px;
		margin-bottom: 42px;

		@media (max-width: 767px) {
			height: 300px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}
	}

	&__block {
		+ .about__block {
			padding-top: 43px;
			margin-top: 47px;
			border-top: 1px solid #e6e6e6;

			@media (max-width: 767px) {
				padding-top: 25px;
				margin-top: 25px;
			}
		}
	}

	&__title {
		font-size: 30px;
		line-height: 38px;
		margin-bottom: 23px;

		@media (max-width: 767px) {
			font-size: 26px;
			line-height: 30px;
		}
	}

	&__subtitle {
		font-size: 20px;
		line-height: 32px;
		color: #4d4d4d;
		margin-bottom: 28px;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 22px;
		}
	}
	
	&__text {
		font-size: 15px;
		line-height: 24px;
		color: #4d4d4d;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 17px;
		}

		p + p {
			margin-top: 23px;

			@media (max-width: 767px) {
				margin-top: 15px;
			}
		}

		a {
			color: #ce171f;
			text-decoration: underline;

			@media (min-width: 1024px) {
				&:hover {
					text-decoration: none;
				}
			}
		}

		br {
			@media (max-width: 1200px) {
				display: none;
			}
		}
	}

	&__wrapper {
		display: flex;
		margin: 56px -25px 61px;

		@media (max-width: 767px) {
			flex-direction: column;
			margin: 40px 0 61px;
		}
	}

	&__col {
		width: calc(48% - 50px);
		margin: 0 25px;

		@media (max-width: 767px) {
			width: 100%;
			margin: 0;
		}

		&:last-child {
			width: calc(50% - 50px);

			@media (max-width: 767px) {
				width: 100%;
				margin-top: 30px;
			}
		}
	}

	&__welcome {
		font-size: 24px;
		line-height: 28px;
		color: #000;
		margin-top: 45px;

		@media (max-width: 1023px) {
			text-align: center;
		}
	}

	.gallery {
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			font-family: 'object-fit: cover;';
		}

		&__block {
			display: flex;
			justify-content: space-between;

			@media (max-width: 1023px) {
				flex-direction: column;
			}

			+ .gallery__block {
				margin-top: 61px;

				@media (max-width: 767px) {
					margin-top: 35px;
				}
			}

			&--reverse {
				.gallery__left {
					order: 1;

					@media (max-width: 1023px) {
						order: 0;
					}
				}

				.gallery__title {
					text-align: right;

					@media (max-width: 1023px) {
						text-align: center;
					}
				}

				.owl-nav {
					display: flex;
					justify-content: flex-end;
				}
			}
		}

		&__title {
			font-size: 30px;
			margin-bottom: 36px;
			padding-top: 4px;

			@media (max-width: 1023px) {
				text-align: center;
			}

			@media (max-width: 767px) {
				font-size: 26px;
			}
		}

		&__left {
			width: 34.7%;
			height: 263px;

			@media (max-width: 1023px) {
				width: 100%;
			}
		}

		&__right {
			width: 64%;

			@media (max-width: 1023px) {
				width: 100%;
				margin-top: 15px;
			}
		}

		&__slider {
			&:not(.owl-loaded) {
				display: flex;
				justify-content: flex-start;
				flex-wrap: wrap;
				opacity: 0;
			}
		}

		&__slide {
			display: block;
			height: 159px;

			@media (max-width: 450px) {
				height: 130px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}

		.owl-nav {
			display: flex;
			align-items: center;
			margin-top: 12px;

			@media (max-width: 1023px) {
				justify-content: center;
			}

			.owl-prev {
				margin-right: 10px;
			}
		}

		&__nav {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 35px;
			border-radius: 5px;
			background-color: #ce171f;
			transition: opacity 0.3s ease;

			@media (min-width: 1024px) {
				&:hover {
					opacity: 0.8;
				}
			}

			&--prev {
				svg {
					transform: rotate(180deg);
				}
			}
		}
	}
}
