.modal-fancy {
	padding: 42px 89px 66px 89px;

	@media (max-width: 560px) {
		padding: 19px 15px 18px 15px;
	}

	.fancybox-close-small {
		color: currentColor;
		padding: 5px;
		right: 4px;
		top: 7px;
		background-color: white;

		svg path {
			fill: #ce181f;
		}
	}
}

.modal-your-enquiry {
	.required {
		color: #d1282e;
	}

	.input-row:nth-child(1) {
		.input-block {
			margin-right: 26px;

			select {
				padding: 13px 10px;
			}

			&:nth-child(1) {
				max-width: 92px;
			}

			&:nth-child(3) {
				margin-right: 0;
			}
		}
	}

	.form-block .agree-row {
		justify-content: flex-start;
	}
}

.modal-reg-login {
	form {
		width: 362px;
		max-width: 100%;

		> .title {
			text-align: center;
			font-size: 30px;
			font-weight: 600;
			line-height: 33px;
			margin-bottom: 27px;
		}

		.input-block {
			.title {
				font-size: 16px;
				font-weight: 400;
				line-height: 28px;
				margin-bottom: 8px;

				.flex-block {
					display: flex;
					justify-content: space-between;
				}

				.resend {
					color: #ce181f;
					font-size: 16px;
					font-weight: 400;
					line-height: 18px;
					text-decoration: underline;

					@include hover {
						text-decoration: none;
					}
				}
			}

			input {
				width: 100%;
				max-width: 362px;
				height: 44px;
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				font-size: 16px;
				padding-left: 16px;
			}

			textarea {
				width: 100%;
				max-width: 362px;
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				font-size: 16px;
				padding-left: 16px;
				padding-top: 10px;
				padding-bottom: 10px;
				height: 120px;
			}

			margin-bottom: 13px;

			&:last-of-type {
				margin-bottom: 35px;
			}
		}

		.btn-reg {
			height: 46px;
			width: 100%;
			background-color: #124390;
			transition-duration: 0.2s;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 18px;
			font-weight: bold;
			line-height: 28px;
			text-transform: uppercase;

			@include hover {
				filter: brightness(1.2);
			}
		}

		.personal {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 18px;
			color: #666666;
			text-align: center;
			transition-duration: 0.2s;
			margin-bottom: 29px;

			a {
				text-decoration: underline;
				color: #666666;

				@include hover {
					text-decoration: none;
				}
			}
		}

		.alternate_sign,
		.signin {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 18px;
			font-weight: 400;
			line-height: 18px;
			color: #ce181f;
			text-decoration: underline;
			transition-duration: 0.2s;
			display: flex;
			justify-content: center;
			margin-top: 35px;

			@include hover {
				text-decoration: none;
			}
		}
	}
}

#modalSave {
	&.modal-reg-login form > .title {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;
	}

	&.modal-reg2 .text-top {
		width: auto;
		text-align: center;
	}
}

.modal-reg2 {
	padding: 42px 65px 60px 65px;

	> .title {
		margin-bottom: 15px;
	}

	.text-top {
		margin: 0 auto;
		color: #2c2c2c;
		font-size: 16px;
		font-weight: 400;
		line-height: 18px;
		width: max-content;
		margin-bottom: 35px;

		.text-ul {
			margin-top: 5px;
			color: #2c2c2c;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			list-style: none;

			li {
				padding-left: 20px;
				position: relative;

				&::before {
					content: "";
					display: block;
					position: absolute;
					width: 5px;
					height: 5px;
					background-color: #ce181f;
					left: 4px;
					top: 10px;
				}
			}
		}
	}

	form {
		width: 560px;
	}

	.grid-block {
		@include flex-row(23px, 30px);

		> * {
			@include flex-block(250px, 23px, 30px, true, 2);
			margin-bottom: 0;
		}

		.input-block {
			.title {
				margin-top: -10px;
			}
		}

		.personal-div {
			display: flex;
			align-items: center;

			.personal {
				margin-bottom: 0;
			}
		}
	}

	.signin {
		margin-top: 45px;
	}
}

#modal-reg {
	@media (max-width: 560px) {
		form {
			>.title {
				margin-bottom: 5px;
			}

			.input-block {
				.title {
					margin-bottom: 6px;
				}

				margin-bottom: 8px;

				&:last-of-type {
					margin-bottom: 12px;
				}
			}

			.btn-reg {
				margin-bottom: 10px;
			}

			.personal {
				margin-bottom: 15px;
			}
		}
	}
}

.modal-reservation {
	width: 689px;
	max-width: 100%;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;

	.title,
	.desc,
	.form-block {
		padding-left: 64px;
		padding-right: 64px;
	}

	.title {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 30px;
		font-weight: 400;
		line-height: 33px;
		color: #2c2c2c;
		text-align: center;
		margin-bottom: 12px;

		@media (max-width: 600px) {
			padding-left: 46px;
			padding-right: 46px;
		}

		@media (max-width: 450px) {
			font-size: 24px;
		}
	}

	.desc {
		max-width: 100%;
		width: 100%;
		text-align: center;
		margin: 0 auto;
		margin-bottom: 22px;

		@media (max-width: 600px) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@media (max-width: 450px) {
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 1px;
		}
	}

	.desc,
	.desc a {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 16px;
		font-weight: 400;
		color: #2c2c2c;
		line-height: 24px;
		text-align: center;

		@media (max-width: 450px) {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 13px;
			font-weight: 400;
			line-height: 24px;
			color: #2c2c2c;
			text-align: center;
		}
	}

	.modal-reservation-text {
		font-size: 12px;
		line-height: 15px;

		a {
			font-size: 12px;
			line-height: 14px;
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}

	.form-block {
		padding-top: 35px;
		position: relative;

		@media (max-width: 600px) {
			padding-left: 20px;
			padding-right: 20px;
		}

		@media (max-width: 450px) {
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 15px;
		}

		&::before {
			content: "";
			height: 1px;
			width: calc(100% + 64px);
			position: absolute;
			left: -64px;
			top: 0;
			background-color: #e6e6e6;
		}

		.input-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 15px;

			@media (max-width: 600px) {
				display: block;
				margin-bottom: 0;
			}

			@media (max-width: 450px) {
				display: block;
				margin-bottom: 0;
			}

			.input-block {
				width: calc(50% - 11.5px);

				@media (max-width: 600px) {
					width: 100%;
					margin-bottom: 10px;
				}

				@media (max-width: 450px) {
					width: 100%;
					margin-bottom: 10px;
				}
			}

			.inputbox::placeholder {
				color: #b3b3b3;
			}
		}

		.grey {
			margin-top: 41px;
			font-family: "Vauxhall Neue", sans-serif;
			color: #666666;
			font-size: 13px;
			font-weight: 400;
			line-height: 18px;

			@media (max-width: 450px) {
				margin-top: 16px;
			}
		}

		> .input-block {

			.agree-text,
			.agree-text a {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				color: #2c2c2c;
			}

			.agree-text a {
				a {
					font-size: 12px;
					line-height: 14px;
					text-decoration: underline;

					@include hover {
						text-decoration: none;
					}
				}
			}
		}

		.input-block {
			.agree-text,
			.agree-text a {
				@media (max-width: 450px) {
					font-size: 13px;
				}
			}
		}

		.btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 439px;
			max-width: 100%;
			height: 46px;
			margin: 35px auto 40px;
			background-color: #124390;
			font-size: 18px;
			font-weight: 400;
			line-height: 28px;
			color: #ffffff;
			text-transform: uppercase;

			@media (max-width: 450px) {
				white-space: normal;
				height: fit-content;
				margin: 12px auto 18px;
				padding: 5px 10px;
			}
		}
	}

	.cards-support {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 80px;
		background-color: #edf1f3;

		.item {
			margin: 0 14.5px;

			@media (max-width: 450px) {
				margin: 0 5px;
			}

			&:nth-last-child(1) {
				margin-left: 10px;

				@media (max-width: 450px) {
					margin-left: 2px;
				}
			}

			&:nth-last-child(2) {
				margin-right: 10px;

				@media (max-width: 450px) {
					margin-right: 2px;
				}
			}
		}
	}
}

#modal-membership3 {
	padding: 0;
	width: 800px;

	.fancybox-close-small {
		right: 6px;
		top: 5px;
		padding: 8px;
	}

	.fancybox-button svg {
		path {
			fill: #fff;
		}
	}

	.head {
		background-color: #ce181f;
		min-height: 100px;
		display: flex;
		justify-content: center;
		padding: 14px 50px 0;

		@media (max-width: 600px) {
			padding-left: 10px;
			padding-top: 10px;
			padding-right: 10px;
		}

		.icon {
			min-width: 69px;
			min-height: 70px;
			background: url(../img/mm3.png) no-repeat center center;
			margin-right: 14px;

			@media (max-width: 600px) {
				background-size: 100% auto;
			}
		}

		.text {
			width: 528px;
			max-width: 100%;
			padding-top: 11px;
			padding-bottom: 10px;

			@media (max-width: 600px) {
				padding-top: 0;
			}

			.title {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 26px;
				font-weight: bold;
				line-height: 28.09px;
				color: #ffffff;
				text-transform: uppercase;
				letter-spacing: 0.7px;

				@media (max-width: 600px) {
					font-size: 18px;
					line-height: 1.25;
					padding-right: 40px;
				}
			}

			.bottom {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				margin: 0 -5px;
			}

			.percent {
				display: inline-block;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 28.09px;
				color: #ffffff;
				margin: 0 5px;

				@media (max-width: 600px) {
					font-size: 13px;
					line-height: 20px;
				}
			}
		}
	}

	.main {
		padding: 21px 94px 58px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 14px;
		font-weight: 400;
		line-height: 25px;
		color: #2c2c2c;

		@media (max-width: 600px) {
			padding: 13px 20px 20px;
			font-size: 13px;
			line-height: 20px;
		}

		.title {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 24px;
			font-weight: bold;
			line-height: 33px;
			color: #2c2c2c;
			letter-spacing: 0.7px;
			margin-bottom: 8px;

			@media (max-width: 600px) {
				font-size: 17px;
				line-height: 1.25;
			}
		}

		> ul {
			padding-bottom: 33px;

			@media (max-width: 600px) {
				padding-bottom: 18px;
			}

			> li {
				position: relative;

				padding-left: 16px;

				&::before {
					content: "";
					width: 5px;
					height: 5px;
					background-color: #ce181f;
					display: block;
					position: absolute;
					top: 11px;
					left: 2px;
				}

				> ul {
					padding-left: 11px;
					padding-bottom: 6px;
				}
			}
		}

		p {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 15px;
			font-weight: 400;
			line-height: 24px;
			color: #2c2c2c;

			@media (max-width: 600px) {
				font-size: 13px;
				line-height: 20px;
			}
		}
	}
}

.modal-map-contacts {
	padding: 0;

	> .title {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 30px;
		font-weight: bold;
		line-height: 33px;
		text-align: center;
		color: #2c2c2c;
		padding: 37px 15px 30px;
		border-bottom: 1px solid #cccccc;

		@media (max-width: 650px) {
			font-size: 18px;
			line-height: 22px;
			padding: 12px 36px 12px;
			border-bottom: 0;
		}
	}

	.content {
		display: flex;

		@media (max-width: 650px) {
			flex-direction: column;
		}

		.map {
			width: 1600px;
			height: 665px;
			border-right: 1px solid #cccccc;

			@media (max-width: 650px) {
				width: 100%;
				height: 250px;
				border-right: 0;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}

		.contacts {
			cursor: auto;

			.title,
			.phone,
			.adress,
			.accordion h3,
			.accordion > div {
				width: 320px;
				padding-left: 47px;
				padding-right: 65px;

				@media (max-width: 650px) {
					width: 100%;
					padding-left: 15px;
					padding-right: 15px;
				}
			}

			.title {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 24px;
				font-weight: bold;
				line-height: 33px;
				color: #2c2c2c;
				margin-top: 30px;
				margin-bottom: 27px;

				@media (max-width: 650px) {
					margin-top: 12px;
					margin-bottom: 12px;
				}
			}

			.phone {
				display: flex;
				align-items: center;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 20px;
				font-weight: 400;
				line-height: 33px;
				color: #2c2c2c;

				img {
					width: 16px;
					height: 16px;
					margin-right: 12px;
				}
			}

			.adress {
				display: flex;
				padding-bottom: 32px;
				border-bottom: 1px solid #e6e6e6;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 22px;
				color: #4d4d4d;

				@media (max-width: 650px) {
					padding-bottom: 10px;
				}

				img {
					width: 14px;
					height: 20px;
					margin-right: 13px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
					color: #4d4d4d;
				}
			}

			.accordion {
				h3 {
					height: 51px;
					margin: 0;
					padding-top: 0;
					padding-bottom: 0;
					border: none;
					border-bottom: 1px solid #e6e6e6;
					background-color: transparent;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 18px;
					font-weight: bold;
					line-height: 51px;
					color: #2c2c2c;
					cursor: pointer;
					position: relative;

					.oh {
						font-family: "Vauxhall Neue", sans-serif;
						font-size: 14px;
						font-weight: 400;
						line-height: 22px;
						color: #666666;
						text-decoration: underline;
					}

					@include hover {
						opacity: 0.8;
					}

					&::after {
						content: "";
						width: 12px;
						height: 8px;
						background: url(../img/arrow-top-red.png) no-repeat center center;
						transform: rotate(-180deg);
						position: absolute;
						top: 23px;
						right: 66px;
						transition-duration: 0.2s;
					}
				}

				.ui-accordion-header-active {
					border-bottom: 0;

					&::after {
						transform: rotate(0deg);
					}
				}

				div {
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 14px;
					font-weight: 400;
					line-height: 26px;
					color: #4d4d4d;

					.row {
						display: flex;
						justify-content: space-between;
					}
				}

				.ui-accordion-content {
					border: none;
					padding-bottom: 7px;
				}

				.ui-accordion-content-active {
					border-bottom: 1px solid #e6e6e6;
					padding-bottom: 7px;
				}
			}
		}
	}
}

#modal-chat {
	width: 689px;
	max-width: 94vw;
	padding: 0;

	.chat-block {
		width: 100%;
	}

	.chat {
		> .title {
			padding: 27px 40px;
			border-bottom: 1px solid #d7d7d7;
			font-size: 30px;
			line-height: 33px;
			font-weight: bold;
			color: #2c2c2c;
			text-align: center;

			@media (max-width: 500px) {
				padding: 11px 40px;
				font-size: 17px;
				line-height: 33px;
			}
		}

		.messages-wrap {
			height: 413px;
			max-height: calc(100vh - 320px);
			padding: 11px 18px 11px 42px;
			background-color: #f6f7fd;

			@media (max-width: 700px) {
				padding: 11px 10px 11px 15px;
			}

			@media (max-width: 500px) {
				max-height: calc(100vh - 285px);
			}

			.mCSB_scrollTools {
				.mCSB_draggerContainer {
					right: -12px;
				}

				.mCSB_draggerRail {
					width: 2px;
					background-color: #d9d9d9;
					border-radius: 0;
					cursor: pointer;
				}

				.mCSB_dragger_bar {
					width: 4px;
					background-color: #d4141c;
					border-radius: 0;
				}
			}
		}

		.messages {
			display: flex;
			flex-direction: column;
			padding: 23px 0;

			.messages_message {
				padding-right: 30px;
				padding-bottom: 20px;
				font-size: 14px;
				width: 100%;

				.title {
					color: #a2a2a2;
					padding-bottom: 10px;

					span {
						font-weight: 600;
					}
				}

				.text {
					background-color: #f7f7f7;
					border: 1px solid #e6e6e6;
					padding: 16px 20px 18px 17px;
				}
			}

			.messages_message-manager {
				align-self: flex-start;
				width: auto;
				padding-right: 30px;

				@media (max-width: 500px) {
					padding-right: 15px;
				}

				.title {
					span {
						color: #ce171f;
					}
				}
			}

			.messages_message-your {
				align-self: flex-end;
				padding-right: 0;
				padding-left: 30px;
				margin-left: auto;
				box-sizing: border-box;

				@media (max-width: 500px) {
					padding-left: 15px;
				}

				.title {
					text-align: left;

					span {
						color: #124390;
					}
				}

				.text {
					background-color: #e4f2ff;
				}
			}
		}
	}

	.write {
		margin-top: 17px;
		border-top: 1px solid #d7d7d7;
		padding: 5px 15px 20px;

		@media (max-width: 500px) {
			padding: 0 15px 15px;
		}

		form {
			padding-top: 4px;
			font-size: 14px;
			line-height: 28px;

			textarea {
				width: 100%;
				height: 88px;
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				resize: none;
				padding: 16px 17px;
				overflow-y: scroll;

				&::placeholder {
					color: #a6a6a6;
				}

				&::-webkit-scrollbar {
					width: 0;
					/* Remove scrollbar space */
					background: transparent;
					/* Optional: just make scrollbar invisible */
					display: none;
				}

				/* Optional: show position indicator in red */
				&::-webkit-scrollbar-thumb {
					background: #ff0000;
				}
			}

			.send-btn {
				width: 161px;
				height: 35px;
				margin-top: 15px;
				background-color: #124390;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				font-weight: bold;
				line-height: 28px;
				color: #ffffff;
				transition-duration: 0.2s;

				@include hover {
					filter: brightness(1.2);
				}
			}
		}
	}
}

.chat-window iframe {
	max-height: calc(100% - 20px);
	box-shadow: 0 5px 29px rgba(6, 11, 9, 0.3);
}

#modal-chat-2 {
	display: none;
	width: 280px;
	max-width: calc(100vw - 60px);
	padding: 0;
	border-radius: 5px 5px 0 0;
	background-color: #ffffff;
	overflow: hidden;
	z-index: 10;

	@media (max-width: 500px) {
		max-width: 100%;
		right: 0;
		bottom: 0;
	}

	.chat-block {
		width: 100%;
	}

	.mCSB_inside > .mCSB_container {
		margin-right: 17px;
	}

	.chat {
		.title-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 9px 15px;
			border-radius: 5px 5px 0 0;
			background-color: #ce171f;

			> .title {
				color: #ffffff;
				font-size: 14px;
				font-weight: bold;
			}

			.close-chat {
				width: 18px;
				height: 18px;
				background: url("../img/white-close.png") no-repeat center center;
				cursor: pointer;
			}
		}

		.messages-wrap {
			max-height: calc(100vh - 123px);
			height: 348px;
			padding: 20px 15px;
			box-sizing: border-box;
			overflow: hidden;
			transition: height 0.1s linear, padding 0.1s linear;

			&.hided {
				height: 0;
				padding-top: 0;
				padding-bottom: 0;
			}

			.mCSB_scrollTools {
				.mCSB_draggerContainer {
					right: -12px;
				}

				.mCSB_draggerRail {
					width: 2px;
					background-color: #d9d9d9;
					border-radius: 0;
					cursor: pointer;
				}

				.mCSB_dragger_bar {
					width: 4px;
					background-color: #d4141c;
					border-radius: 0;
				}
			}
		}

		.messages {
			display: flex;
			flex-direction: column;
			padding: 0;

			.messages_message {
				width: 100%;
				padding-right: 20px;
				padding-bottom: 15px;
				font-size: 13px;

				.title {
					font-size: 13px;
					color: #a2a2a2;
					padding-bottom: 10px;

					span {
						font-size: 14px;
						font-weight: 600;
					}
				}

				.text {
					padding: 9px 10px 9px 12px;
					border-radius: 0 10px 10px;
					background-color: #e4f2ff;
				}
			}

			.messages_message-manager {
				align-self: flex-start;
				box-sizing: border-box;
				padding-right: 15px;

				.title {
					span {
						color: #ce171f;
					}
				}
			}

			.messages_message-your {
				align-self: flex-end;
				padding-right: 0;
				padding-left: 15px;
				margin-left: auto;
				box-sizing: border-box;

				.title {
					display: flex;
					flex-direction: row-reverse;
					text-align: left;

					span {
						margin-left: 15px;
						color: #124390;
					}
				}

				.text {
					border-radius: 10px 0 10px 10px;
					background-color: #3b609b;
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #ffffff;
				}
			}
		}
	}

	.write {
		border-top: 1px solid #d7d7d7;
		padding: 13px 10px 8px 10px;
		transition: height 0.1s linear, padding 0.1s linear;

		&.hided {
			height: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		form {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 14px;
			line-height: 28px;

			textarea {
				width: calc(100% - 60px);
				height: 35px;
				border: none;
				background-color: #fff;
				resize: none;
				padding: 8px 0 0;
				overflow-y: auto;

				&::placeholder {
					color: #a6a6a6;
				}

				&::-webkit-scrollbar {
					width: 0;
					/* Remove scrollbar space */
					background: transparent;
					/* Optional: just make scrollbar invisible */
					display: none;
				}

				/* Optional: show position indicator in red */
				&::-webkit-scrollbar-thumb {
					background: #ff0000;
				}
			}

			.send-btn {
				width: 30px;
				height: 28px;
				border-radius: 5px;
				background: #ce171f url(../img/send-icon.png) center center no-repeat;
				background-size: 18px 15px;
				transition: 0.3s;

				@include hover {
					background-color: #841215;
				}
			}
		}
	}
}

.ifchat-departament-choose {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: calc(100% - 36px);
	padding: 20px 25px;
	background: #ffffff;
	position: absolute;
	top: 36px;
	opacity: 1;
	transition-duration: .5s;
	box-sizing: border-box;

	&--choosed {
		opacity: 0;
		pointer-events: none !important
	}

	&__title {
		font-size: 14px;
		font-weight: 400;
		line-height: 28px;
		text-align: center;
		color: #2c2c2c;
	}

	&__buttons {
		width: 100%;
		margin-top: 12px;

		.btn {
			display: block;
			-webkit-appearance: none;
			width: 100%;
			margin-bottom: 10px;
			padding-left: 56px;
			color: #ffffff;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px;
			height: 44px;
			text-align: left;
			border-radius: 3px;

			&--ifchat-select-type,
			&--ifchat-select-type,
			&--ifchat-select-type  {
				position: relative;

				&::before {
					width: 31px;
					height: 21px;
					content: '';
					display: block;
					background-size: contain;
					background-position: center center;
					background-repeat: no-repeat;
					position: absolute;
					left: 13px;
					top: 11px;
				}

				@include hover {
					background: #454545;
				}
			}

			&--ifchat-sales::before {
				background-image: url(../img/iframe-chat-sales.png);
			}

			&--ifchat-service::before {
				background-image: url(../img/iframe-chat-service.png);
			}

			&--ifchat-parts::before {
				background-image: url(../img/enginePart.svg);
			}
		}
	}
}

.chat-window {
	transition: transform 0.2s;

	&.hided {
		bottom: 37px !important;
		transform: translateY(100%) !important;
	}
}

.messages-add-manager-flex {
	display: flex;
	align-items: center;
	border-radius: 0 10px 10px;
	padding: 21px 10px 24px 14px;
	border: 1px solid #e6e6e6;
	background-color: #f7f7f7;

	img {
		width: 69px;
		height: 69px;
		border-radius: 50%;
		margin-right: 8px;
		flex-shrink: 0;
	}

	& > span {
		display: block;
	}
}

.ncc-modal-new {
	display: none;
	width: 730px;
	max-width: 100%;
	padding: 0;

	.ncc-modal-new-head {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #e6e6e6;
		padding: 30px 45px 24px 43px;

		@media (max-width: 600px) {
			text-align: center;
			flex-direction: column;
			padding: 15px 45px 15px 20px;
		}

		&__text {
			font-size: 26px;
			line-height: 28.09px;
			font-weight: bold;
			color: #2c2c2c;
			text-transform: uppercase;

			@media (max-width: 600px) {
				font-size: 19px;
				line-height: 1.3;
			}
		}
	}

	.ncc-modal-new-main {
		max-width: 100%;
		max-height: 70vh;
		padding: 30px 10px 30px 43px;

		@media (max-width: 600px) {
			max-height: 85vh;
			padding: 15px 10px 20px 20px;
		}

		.mCSB_dragger {
			.mCSB_dragger_bar {
				border-radius: 0;
				opacity: 1 !important;
				background-color: #d4141c;
			}
		}

		.mCSB_draggerRail {
			background-color: #d9d9d9;
		}

		p + ul,
		p + p,
		ul + p,
		p + h4,
		h4 + p,
		ul + h4,
		li + li {
			margin-top: 15px;

			@media (max-width: 600px) {
				margin-top: 10px;
			}
		}

		h4 {
			font-size: 20px;
			line-height: 33px;
			font-weight: bold;
			color: #2c2c2c;

			@media (max-width: 600px) {
				font-size: 17px;
				line-height: 1.3;
			}
		}

		ul li,
		p {
			font-size: 14px;
			line-height: 20px;
			font-weight: 400;
			color: #4d4d4d;

			@media (max-width: 600px) {
				line-height: 22px;
			}
		}

		ul,
		p {
			margin-left: 18px;

			@media (max-width: 420px) {
				margin-left: 5px;
			}
		}
	}
}

.modal-newCar {
	width: 730px;
	max-width: 94vw;
	padding: 0;

	.fancybox-close-small {
		right: 6px;
		top: 3px;
	}

	.chat-block {
		width: 100%;
	}

	.chat {
		> .title {
			padding: 35px 40px;
			border-bottom: 1px solid #d7d7d7;
			font-size: 26px;
			line-height: 28px;
			font-weight: normal;
			color: #2c2c2c;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 1px;

			@media (max-width: 830px) {
				padding: 40px 15px;
				font-size: 18px;
			}
		}

		.messages-wrap {
			height: 554px;
			max-height: calc(100vh - 320px);
			padding: 11px 12px 11px 107px;
			background-color: #f6f7fd;

			@media (max-width: 830px) {
				padding: 15px;
			}

			.mCSB_scrollTools {
				.mCSB_draggerContainer {
					right: -12px;
				}

				.mCSB_draggerRail {
					width: 2px;
					background-color: #d9d9d9;
					border-radius: 0;
					cursor: pointer;
				}

				.mCSB_dragger_bar {
					width: 4px;
					background-color: #d4141c;
					border-radius: 0;
				}
			}
		}

		.messages {
			display: flex;
			flex-direction: column;
			padding: 12px 0;
			padding-right: 100px;

			@media (max-width: 830px) {
				padding-right: 12px;
			}

			span {
				display: block;
				font-size: 14px;
				line-height: 20px;
				margin-bottom: 10px;
				color: #4c4c4c;
			}

			.gearing {
				font-size: 20px;
				line-height: 33px;
				text-align: center;
				margin-bottom: 7px;
				color: #2c2c2c;
				margin-left: 25px;
			}

			.send-btn {
				width: 440px;
				height: 46px;
				margin-left: 40px;
				margin-top: 15px;
				background-color: #124390;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				font-weight: bold;
				line-height: 46px;
				color: #ffffff;
				transition-duration: .2s;
				text-transform: uppercase;

				@media (max-width: 830px) {
					width: 100%;
					margin: 0 auto;
				}

				@include hover {
					filter: brightness(1.2);
				}
			}
		}
	}

	.mCSB_inside > .mCSB_container {
		margin-right: 7px;
	}
}

#modal-newCar-2 {
	width: 1250px;
	max-width: 94vw;
	max-height: 94vh;

	.chat {
		padding: 0 20px;
	}

	span {
		display: block;
	}

	.messages-title {
		width: 100%;
		background: #fff;
		position: absolute;
		top: 0;
		font-size: 20px;
		line-height: 33px;
		color: #d4141c;
	}

	.messages-untitle {
		font-size: 16px;
		line-height: 33px;
		color: #2c2c2c;
	}

	.messages-text {
		font-size: 13px;
		line-height: 17px;
		color: #4c4c4c;
		margin-bottom: 20px;
	}

	.messages-wrap {
		padding: 32px 0;
		height: 720px;
		background-color: #fff;

		.flex-container {
			display: flex;
			flex-wrap: nowrap;
			width: auto;
		}
	}

	.mCSB_scrollTools_horizontal {
		.mCSB_draggerRail {
			width: 100%;
			height: 2px;
			right: 0;
		}

		.mCSB_dragger_bar{
			width: 100%;
		}
	}

	.messages-item {
		width: 250px;
		flex-shrink: 0;
		padding-right: 10px;
		border-right: 1px solid #E6E6E6;
		margin-right: 20px;
		margin-bottom: 25px;
		padding-top: 33px;
		position: relative;

		p {
			font-size: 13px;
			color: #4c4c4c;
			line-height: 17px;
			margin-bottom: 20px;
		}
	}
}

/////////////////////////////////////////

.modal-online-payment {
	padding-top: 0 !important;
}

.mod-op-title {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 30px 50px 22px;
	font-size: 30px;
	font-weight: bold;
	line-height: 33px;
	color: #2c2c2c;
	text-align: center;

	@media (max-width: 500px) {
		font-size: 22px;
		padding: 13px 50px 13px;
	}
}

.mod-op-input-row-one-item {
	margin-bottom: 15px;
}

.mod-op-agree-row {
	display: flex;
	justify-content: center;
}

.mod-op-radios-row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	margin: 0 -8px;

	.radio-label {
		margin-bottom: 0 !important;
	}

	.radio-btn {
		display: flex;
		align-items: center;
		margin: 0 8px 15px !important;
	}

	input[type="radio"] {
		min-width: 15px;
	}
}

/////////////////////////////////////////

.modal-deposit-payment-desc,
.modal-deposit-payment-sub-title {
	padding-left: 64px;
	padding-right: 64px;
	text-align: center;
	box-sizing: border-box;

	@media (max-width: 600px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.modal-deposit-payment-sub-title {
	max-width: 100%;
	margin: 0 auto;
	font-size: 22px;
	font-weight: 400;
	line-height: 28px;

	@media (max-width: 600px) {
		font-size: 19px;
		line-height: 1.2;
	}
}

.modal-deposit-payment-desc {
	padding-bottom: 37px;
	margin-top: 15px;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

#admin-chat {
	display: none;
	width: 320px;
	max-width: calc(100vw - 60px);
	height: 100%;
	padding: 0;
	background-color: #ffffff;
	overflow: hidden;
	z-index: 10;

	@media (max-width: 500px) {
		max-width: 100%;
		right: 0;
		bottom: 0;
	}

	.chat-block {
		width: 100%;
	}

	.mCSB_inside > .mCSB_container {
		margin-right: 17px;
	}

	.chat {
		.title-row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 9px 15px;
			background-color: #ce171f;

			> .title {
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}

			.close-chat {
				width: 18px;
				height: 18px;
				background: url("../img/white-close.png") no-repeat center center;
				cursor: pointer;
			}
		}

		.messages-wrap {
			padding: 10px 10px 10px 24px;
			box-sizing: border-box;
			overflow: hidden;
			transition: height 0.1s linear, padding 0.1s linear;

			&.hided {
				height: 0;
				padding-top: 0;
				padding-bottom: 0;
			}

			.mCSB_scrollTools {
				.mCSB_draggerContainer {
					right: -12px;
				}

				.mCSB_draggerRail {
					width: 2px;
					background-color: #d9d9d9;
					border-radius: 0;
					cursor: pointer;
				}

				.mCSB_dragger_bar {
					width: 4px;
					background-color: #d4141c;
					border-radius: 0;
				}
			}
		}

		.messages {
			display: flex;
			flex-direction: column;
			padding: 0;

			.messages_message {
				width: 100%;
				padding-right: 20px;
				padding-bottom: 19px;
				font-size: 13px;

				.title {
					padding-bottom: 1px;
					font-size: 12px;
					color: #a2a2a2;

					span {
						padding-right: 6px;
						font-size: 13px;
						color: #ce171f;
						font-weight: bold;
					}
				}

				.text {
					font-size: 14px;
					line-height: 19px;
					padding: 9px 10px 9px 12px;
					border: 1px solid #e6e6e6;
					background-color: #e4f2ff;
				}
			}

			.messages_message-user {
				.title {
					span {
						color: #124390;
					}
				}
			}
		}
	}

	.write {
		height: 212px;
		border-top: 1px solid #d7d7d7;
		padding: 5px 8px 16px 16px;
		transition: height 0.1s linear, padding 0.1s linear;

		&.hided {
			height: 0;
			padding-top: 0;
			padding-bottom: 0;
		}

		form {
			display: flex;
			align-items: center;
			flex-direction: column;
			font-size: 14px;
			line-height: 28px;

			.input-block {
				width: 100%;
				margin-bottom: 10px;
			}

			.input-block select {
				width: 100%;
				padding: 6px 25px 6px 5px;
				resize: none;
				border: 1px solid #a5a5a5;
				font-size: 14px;
				-moz-appearance: none;
				appearance: none;
				background: #fff url(../img/select_hov.svg) no-repeat;
				background-position: calc(100% - 8px) center;
				box-sizing: border-box;
			}

			textarea {
				width: 100%;
				height: 79px;
				border: 1px solid #a5a5a5;
				background-color: #fff;
				resize: none;
				padding: 8px;
				overflow-y: scroll;

				&::placeholder {
					font-size: 13px;
					color: #a6a6a6;
				}

				&::-webkit-scrollbar {
					width: 0;
					/* Remove scrollbar space */
					background: transparent;
					/* Optional: just make scrollbar invisible */
					display: none;
				}

				/* Optional: show position indicator in red */
				&::-webkit-scrollbar-thumb {
					background: #ff0000;
				}
			}

			.send-btn {
				width: 98px;
				height: 28px;
				background: #ce171f url(../img/send-icon.png) center center no-repeat;
				background-size: 18px 15px;
				transition: background-color 0.3s ease;
				align-self: flex-start;
				background-position: calc(100% - 14px) center;
				font-size: 14px;
				color: #fff;
				text-align: left;
				padding: 0 20px;
				margin-top: 10px;

				@include hover {
					background-color: #841215;
				}
			}
		}
	}
}

.modal {
	position: fixed;
	top: 108px;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;

	.modal-dialog {
		width: auto;
		margin: 0.5rem;
		position: relative;
		pointer-events: none;

		@media (min-width: 576px) {
			max-width: 500px;
			margin: 1.75rem auto;
		}

		.modal-content {
			display: flex;
			flex-direction: column;
			width: 100%;
			border-radius: 0.3rem;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid rgba(0, 0, 0, 0.2);
			outline: 0;
			pointer-events: auto;
			position: relative;
		}

		.modal-header {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			padding: 1rem 1rem;
			border-bottom: 1px solid #dee2e6;
			border-top-left-radius: 0.3rem;
			border-top-right-radius: 0.3rem;

			.close {
				float: right;
				font-size: 1.5rem;
				font-weight: 700;
				line-height: 1;
				color: #000;
				text-shadow: 0 1px 0 #fff;
				opacity: 0.5;
				padding: 1rem 1rem;
				margin: -1rem -1rem -1rem auto;
				background-color: transparent;
				border: 0;
				appearance: none;

				@include hover {
					opacity: 0.75;
				}
			}
		}

		.modal-body {
			flex: 1 1 auto;
			padding: 1rem;
			position: relative;

			p {
				margin-top: 0;
				margin-bottom: 1rem;
			}
		}

		.modal-title {
			font-size: 17px;
			line-height: 1.5;
			font-weight: 700;
			margin: 0 0 4px;
		}
	}
}

#thankyou {
	max-width: 560px;
	width: 100%;

	.form-title {
		font-size: 24px;
		line-height: 1.3;
		font-weight: 700;
		color: #2c2c2c;
		margin-top: 75px;
		margin-bottom: 45px;
		text-align: center;
	}

	.form-info-text {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #4d4d4d;
		text-align: center;
		margin-top: 48px;
	}

	.btn {
		width: 266px;
		margin: 27px auto 0;
	}
}

.modal-fancy-program {
	@media (max-width: 1200px) {
		padding-top: 35px;
		padding-right: 5px;
		padding-left: 5px;
		overflow-x: hidden;
	}
}

// Price menu
.c-price-menu {
	width: 841px;
	max-width: 99%;
	padding: 0;

	&__title {
		padding: 27px 0 24px;
		font-size: 26px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;
		text-align: center;
	}

	&__tab-controls {
		display: flex;
		justify-content: center;
		border-bottom: 1px solid #ce171f;
	}

	.c-price-menu-tab-control {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 228px;
		height: 40px;
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
		color: #2c2c2c;
		cursor: pointer;

		@include hover {
			background-color: lighten(#d9d9d9, 10%);
		}

		&.active {
			pointer-events: none;
			color: #ffffff;
			border: 1px solid transparent;
			background-color: #ce171f;
		}

		&:not(.active) {
			border: 1px solid #d9d9d9;
			border-bottom: 1px solid transparent;

			&:nth-child(1) {
				border-right: 1px solid transparent;
			}

			&:nth-child(2) {
				border-left: 1px solid transparent;
			}
		}
	}

	.c-price-menu-tab {
		display: none;
		padding: 25px 36px 57px 50px;

		&.active {
			display: flex;
			justify-content: space-between;
			width: 100%;

			@media (max-width: 750px) {
				display: block;
				padding: 15px;
			}
		}

		&__left,
		&__right {
			width: calc(50% - 35px / 2);

			@media (max-width: 750px) {
				width: 100%;
			}
		}

		&__left {
			@media (max-width: 750px) {
				margin-right: 0;
				margin-bottom: 15px;
			}
		}
	}

	.c-price-menu-tab-title {
		font-size: 18px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.c-price-menu-tab-desc {
		font-size: 14px;
		line-height: 18px;
		font-weight: 400;
		color: #666666;
	}

	.c-pm-price-list {
		margin-top: 13px;
	}

	.c-pm-price-list-item {
		display: flex;
		align-items: center;
		min-height: 35px;
		padding: 5px 6px 5px 13px;
		background-color: #eef3fb;
		cursor: pointer;
		box-sizing: border-box;

		@include hover {
			background-color: #d8e0ed;
		}

		@media (max-width: 380px) {
			flex-direction: column;
			align-items: center;
		}

		* {
			box-sizing: border-box;
		}

		&__name {
			width: calc(55% - 25px);
			padding-right: 15px;
		}

		&__price {
			width: 20%;
			padding-right: 15px;
		}

		&__duration {
			width: 25%;
			padding-right: 15px;
		}

		&__plus {
			width: 25px;
			height: 25px;
			background-color: #ce171f;
			background-image: url(../img/white-plus.png);
			background-repeat: no-repeat;
			background-position: center center;
		}

		> * {
			@media (max-width: 380px) {
				width: 100%;
				text-align: center;
			}

			& + * {
				@media (max-width: 380px) {
					margin-top: 5px;
				}
			}
		}

		& + .c-pm-price-list-item {
			margin-top: 4px;
		}
	}
}

// Cashflow
.modal-caslo-remove {
	.caslo-remove-btn {
		display: block;
		width: 14px;
		height: 18px;
		margin-right: 12px;
		background: url(../img/bin-red.png) no-repeat center center;
		background-size: contain;
		cursor: pointer;
	}

	.modal-caslo-remove {
		width: 501px;
		max-width: 100%;

		.input-block {
			width: 100%;
			max-width: 100%;

			.select {
				width: 100%;
			}
		}
	}

	.modal-caslo-remove-title {
		font-size: 26px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;
		text-align: center;
		margin-bottom: 36px;
	}

	button.modal-caslo-remove-btn {
		width: 179px;
		margin: 20px auto 0;
		background-color: #333333;
		position: relative;
		font-size: 15px;
		font-weight: 400;

		&::before {
			content: '';
			display: block;
			width: 14px;
			height: 18px;
			background: url(../img/bin.png) no-repeat center center;
			background-size: contain;
			position: absolute;
			left: 25px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.modal-caslo-add-line {
		.input-block {
			& + .input-block {
				margin-top: 20px;
			}
		}
	}

	button.modal-caslo-add-btn {
		width: 179px;
		margin: 29px auto 0;
	}
}

// Cars booking
.cars_booking__modal {
	.modal-title {
		margin-bottom: 30px;
		font-size: 26px;
		line-height: 1;
		font-weight: bold;
		color: #2c2c2c;
		text-align: center;

		span {
			color: #ce181f;
		}
	}

	.modal-subtitle {
		margin-bottom: 25px;
		margin-top: -25px;
		font-size: 16px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: center;

		span{
			color: #ce181f;
		}
	}

	.form-block {
		width: 382px;
		max-width: 100%;

		.input-block {
			margin-bottom: 20px;

			label:not(.checkbox-container) {
				margin-bottom: 6px;
			}

			.inputbox {
				padding: 7px;

				&.datepicker-input{
					background-size: 25px auto;
				}

				&:disabled {
					border: 1px solid #d9d9d9;
					background-color: #ffffff;
					color: #999999;
				}
			}

			select {
				padding: 8px 20px 9px 10px;
				background-image:url(../img/select_hov.svg);
			}

			.description {
				font-size: 16px;
				font-weight: 400;
				color: #ce171f;
			}

			.input-flex {
				display: flex;

				.check-btn {
					width: 111px;
					height: 40px;
					margin-left: 10px;
					margin-top: 4px;
					flex-shrink: 0;
					font-size: 16px;
					line-height: 35px;
				}
			}
		}

		.input-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media (max-width: 450px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.input-block {
				width: 48%;

				@media (max-width: 450px) {
					width: 100%;
				}
			}
		}

		.btns-row {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			button {
				min-width: 179px;
				width: 179px;
				height: 40px;
				margin-top: 10px;
				font-size: 15px;
				line-height: 40px;
				font-weight: 400;
				box-sizing: border-box;

				@media (max-width: 450px) {
					width: 100%;
				}

				img{
					margin-right: 8px;
				}
			}
		}
	}

	&#js-add-courtesy-car {
		.btns-row {
			justify-content: space-between;
		}
	}

	&#js-create-booking {
		.btns-row {
			justify-content: space-between;
		}
	}
}

.modal-vehicle {
	max-width: 1154px;
	width: 100%;
	min-height: 875px;
	padding: 34px 48px 98px;

	@media (max-width: 1023px) {
		padding: 30px 15px 98px;
	}

	@media (max-width: 767px) {
		padding: 30px 15px 150px;
	}

	&__title {
		font-size: 26px;
		line-height: 33px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 20px;
	}

	&__top {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		flex-wrap: wrap;
		border-bottom: 1px solid #e6e6e6;
		padding-bottom: 19px;
		margin-bottom: 25px;

		@media (max-width: 1200px) {
			align-items: center;
		}

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}

		.input-block {
			width: 335px;
			margin-right: 26px;
			margin-bottom: 15px;

			@media (max-width: 1200px) {
				width: calc(33.3333% - 20px);
				margin: 0 10px;
			}

			@media (max-width: 767px) {
				width: 100%;
				margin: 0 0 15px;
			}

			label {
				width: 100%;
				margin-bottom: 0;
			}

			&--small {
				width: 155px;

				@media (max-width: 1200px) {
					width: calc(33.3333% - 20px);
				}

				@media (max-width: 767px) {
					width: 100%;
				}
			}
		}

		&--info {
			padding-bottom: 18px;
			margin-bottom: 27px;
			justify-content: flex-start;
		}
	}

	&__item {
		margin-bottom: 15px;

		+ .modal-vehicle__item {
			margin-right: 19px;

			@media (max-width: 1200px) {
				margin-right: 25px;
			}
		}

		&:first-child,
		&:nth-child(2) {
			margin-right: 73px;

			@media (max-width: 1200px) {
				margin-right: 25px;
			}
		}

		p {
			font-size: 14px;

			&:first-child {
				color: #92a3ac;
				margin-bottom: 9px;

				@media (max-width: 1023px) {
					margin-bottom: 5px;
				}
			}
		}
	}

	&__search {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 155px !important;
		margin-bottom: 15px;

		@media (max-width: 1200px) {
			margin-top: 15px;
		}

		&:hover {
			color: #fff;
		}

		svg {
			fill: #fff;
			margin-right: 5px;
		}
	}

	&__subtitle {
		font-size: 20px;
		margin-bottom: 19px;

		@media (max-width: 767px) {
			font-size: 18px;
			text-align: center;
		}
	}

	&__table-body {
		height: 500px;
	}

	&__table-wrapper {
		position: relative;
		width: 100%;
		padding-right: 31px;

		.input-block {
			display: block;
			margin-bottom: 0;

			@media (max-width: 1200px) {
				width: 100%;
			}

			label {
				width: 100%;

				&.checkbox {
					display: flex;
					font-size: 16px;
					font-weight: 400;
					cursor: pointer;

					span {
						font-size: 20px;
						padding-left: 12px;
					}

					input {
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						max-width: 20px;
						width: 100%;
						height: 20px;
						margin: 0;
						border: 1px solid #a5a5a5;
						border-radius: 3px;
						background-color: #fff;
						cursor: pointer;
						box-sizing: border-box;

						&:checked {
							background-image: url(../img/checked.svg);
							background-repeat: no-repeat;
							background-position: center center;
							background-size: 100%;
						}
					}
				}
			}

			input[type="radio"] {
				background-color: #fff;
				margin-right: 0;
			}
		}

		table {
			width: 100%;

			tr {
				&:nth-child(odd) {
					background-color: #f7f7f7;
				}

				border-bottom: 1px solid #ebebeb;
			}

			td,
			th {
				width: 100%;
				font-size: 14px;
				font-weight: 400;
				vertical-align: middle;

				@media (min-width: 1201px) {
					&:first-child {
						width: 6%;
					}

					&:nth-child(2) {
						width: 20.14%;
					}

					&:nth-child(3) {
						width: 33%;
					}

					&:nth-child(4) {
						width: 5%;
					}

					&:nth-child(5) {
						width: 5%;
					}

					&:nth-child(6) {
						width: 6%;
					}

					&:nth-child(7) {
						width: 5%;
					}

					&:nth-child(8) {
						width: 5%;
					}

					&:nth-child(9) {
						width: 5.5%;
					}

					&:nth-child(10) {
						width: 10.63%;
					}
				}
			}

			td {
				padding: 15.5px 10px;

				@media (max-width: 1200px) {
					display: flex;
					justify-content: flex-start;
					flex-wrap: nowrap;
					background-color: transparent;
					border-bottom: none;
					font-size: 14px;
				}

				@media (max-width: 1023px) {
					padding: 10px;
				}

				&::before {
					display: none;

					@media (max-width: 1200px) {
						content: attr(aria-label);
						display: inline-block;
						max-width: 100px;
						width: 100%;
						margin-right: 20px;
						color: #999999;
					}
				}
			}

			th {
				padding: 8px;

				@media (max-width: 1200px) {
					display: none;
				}
			}
		}

		> table tr:nth-child(odd) {
			background-color: #fff;
		}

		&--width {
			table {
				td,
				th {
					width: 100%;

					@media (min-width: 1201px) {
						&:first-child {
							width: 4%;
						}

						&:nth-child(2) {
							width: 9.75%;
						}

						&:nth-child(3) {
							width: 21.75%;
						}

						&:nth-child(4) {
							width: 13.65%;
						}

						&:nth-child(5) {
							width: 41.17%;
						}

						&:nth-child(6) {
							width: 9.56%;
						}
					}
				}
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		bottom: 34px;
		left: 50%;
		transform: translateX(-50%);

		@media (max-width: 767px) {
			flex-direction: column;
		}

		a,
		input {
			display: block;
			width: 155px;
			height: 40px;
			border-radius: 5px;
			background-color: #ce171f;
			color: #fff;
			font-size: 15px;
			line-height: 28px;
			transition: background-color 0.3s ease, color 0.3s ease;

			&:hover {
				color: #fff;
				background-color: #981316;
			}
		}

		.modal-vehicle__back {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #4d4d4d;
			color: #fff;
			margin-right: 10px;

			@media (max-width: 767px) {
				margin-right: 0;
				margin-bottom: 10px;
			}

			&:hover {
				background-color: #333333;
			}
		}

		.modal-vehicle__next {
			display: flex;
			align-items: center;
			justify-content: center;

			&--disabled {
				background-color: #d9d9d9;
				color: #999999;
				pointer-events: none;
			}
		}
	}

	.mCSB_draggerRail {
		width: 2px;
		background-color: #d9d9d9;
		border-radius: 0;
		cursor: pointer;
	}

	.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		width: 4px;
		background-color: #d4141c;
		border-radius: 0;
	}

	.modal-close {
		position: absolute;
		top: 20px;
		right: 15px;
		cursor: pointer;
		transition: opacity 0.3s ease;

		@media (min-width: 1024px) {
			&:hover {
				opacity: 0.75;
			}
		}
	}
}

// Online payment 2
.payment {
	padding: 0;
	padding-top: 35px;
	max-width: 690px;
	width: 100%;

	&__title {
		font-size: 30px;
		line-height: 33px;
		font-weight: bold;
		text-align: center;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: -22px;
			left: 0;
			background-color: #e6e6e6;
		}
	}

	&__main {
		margin-top: 58px;
		padding: 0 65px;

		@media (max-width: 1023px) {
			padding: 0 15px;
		}
	}

	&__contacts {
		font-size: 16px;
		line-height: 28px;
		position: relative;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			position: absolute;
			bottom: -22px;
			left: 0;
			background-color: #e6e6e6;
		}
	}

	&__collect {
		span {
			color: #ce171f;
		}
	}

	&__address {
		display: flex;
		font-size: 15px;
		line-height: 22px;
		margin: 12px 0;

		@media (max-width: 1023px) {
			flex-direction: column;
		}
	}

	&__address-text {
		display: flex;
		align-items: center;
		margin-right: 20px;

		&::before {
			content: '';
			display: block;
			width: 14px;
			height: 20px;
			background: url(../img/pin.svg) no-repeat center center;
			margin-right: 5px;
		}

		@media (max-width: 1023px) {
			margin-right: 0;
		}
	}

	&__address-phone {
		display: flex;
		align-items: center;

		&::before {
			content: '';
			display: block;
			width: 16px;
			height: 16px;
			background: url(../img/phone.svg) no-repeat center center;
			margin-right: 5px;
		}
	}

	&__mode {
		font-size: 14px;
		line-height: 26px;
		color: #666666;

		span {
			&:first-child {
				margin-right: 25px;
			}

			@media (max-width: 1023px) {
				display: block;
			}
		}
	}

	form {
		margin-top: 50px;
	}

	&__inputs {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
	}

	.input-block {
		width: calc(50% - 30px);
		margin: 0 15px 20px;

		@media (max-width: 1023px) {
			width: 100%;
		}
	}

	&__input-text {
		width: 100%;
		margin-bottom: 0 !important;
	}

	.simple-input {
		display: block;
		box-sizing: border-box;
		width: 100%;
		padding: 8px 15px;
		resize: none;
		border: 1px solid #a5a5a5;
		border-radius: 0;
		outline: 0;
		font-size: 16px;
		line-height: 1em;
		margin-top: 7px;
	}

	&__policy {
		font-size: 13px;
		line-height: 18px;
		color: #666666;
		margin: 18px 0 25px;
	}

	&__checkbox {
		display: flex;
		align-items: center;
		justify-content: center;

		input {
			display: none;

			&:checked + b {
				background: #fff center/100% auto url(../img/admin_checked.png) no-repeat;
			}
		}

		b {
			display: block;
			flex-shrink: 0;
			width: 20px;
			height: 20px;
			box-sizing: border-box;
			border: 1px solid #a5a5a5;
			border-radius: 3px;
			margin-right: 10px;
			background: #fff;
		}

		span {
			a {
				color: #2c2c2c;
				text-decoration: underline;
			}
		}
	}

	.btn-payment {
		display: block;
		width: 440px;
		margin: 28px auto 40px;
		font-size: 18px;
		line-height: 28px;
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;

		@media (max-width: 1023px) {
			width: 200px;
			font-size: 14px;
			line-height: 26px;
		}
	}

	&__footer {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #edf1f3;
		padding: 20px 0;

		img {
			&:not(:last-child) {
				margin-right: 30px;
			}
		}
	}
}

.modal-payreq-details,
.modal-payreq-notes {
	&__title {
		font-size: 17px;
		border-bottom: 1px solid #e9ecef;
		margin: 0 -15px 12px;
		padding: 0 15px 12px;
	}

	.input-block {
		label {
			font-size: 16px;
		}

		input {
			border-radius: 3px;
		}
	}

	.btn {
		height: 40px;
		line-height: 40px;
		font-weight: 400;
		margin-top: 15px;

		&.modal-payreq-notes__add {
			max-width: 100px;
		}

		&.modal-payreq-notes__close {
			max-width: 120px;
			margin-left: auto;
			background-color: #6c757d;
			color: #fff;

			@media (min-width: 1024px) {
				&:hover {
					background-color: #5a6268;
				}
			}
		}
	}

	&__info {
		margin: 20px 0;

		p {
			font-size: 16px;
			line-height: 20px;
		}
	}

	&__bottom {
		border-top: 1px solid #e9ecef;
		margin: 0 -15px;
		padding: 0 15px;
	}

	&.modal-um {
		max-width: 500px;
		width: 100%;
		padding: 40px 15px 30px;

		input:disabled {
			border: 1px solid #d9d9d9;
			background-color: #ffffff;
			color: #999999;
		}

		.lbl-chb {
			display: block;
			padding-bottom: 10px;
		}

		.datepicker-input {
			background-size: auto 76% !important;
		}

		.btns-row {
			.btn {
				margin-top: 0;
			}
		}

		.modal-title {
			font-size: 26px;
			font-weight: bold;
			line-height: 33px;
			text-align: center;
			margin-bottom: 30px;
		}

		.form-block .input-block label:not(.checkbox-container) {
			margin-bottom: 4px;
		}

		.input-block {
			margin-bottom: 20px;

			input,
			select {
				height: 35px;
			}

			textarea {
				height: 70px;
			}

			select {
				line-height: 35px;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}

.modal-payreq-new-req {
	&.modal-um {
		padding: 47px 60px 53px;

		@media (max-width: 450px) {
			padding: 40px 20px 25px;
		}
	}

	.form-block {
		.input-block {
			input[type="text"],
			select,
			textarea {
				border-radius: 3px;
			}
		}
	}

	.btn {
		line-height: 40px;
	}

	.payreq-file-attach {
		input {
			display: none;
		}

		div {
			display: flex;
			align-items: center;
		}
	}

	.payreq-file-attach-skrepka {
		width: 11px;
		height: 22px;
		background: url(../img/attach-icon.png) no-repeat center center;
		background-size: contain;
	}

	.payreq-file-attach-text {
		font-size: 18px;
		line-height: 28px;
		font-weight: 400;
		color: #ce171f;
		text-decoration: underline;
		margin-left: 9px;
		cursor: pointer;

		@include hover {
			text-decoration: none;
		}
	}

	.btns-row--jcc {
		display: flex;
		justify-content: center;
	}

	.btns-row--jcsb {
		display: flex;
		justify-content: space-between;
	}

	.add-btn {
		max-width: calc(50% - 5px);
		width: 179px;
		height: 40px;
		background-color: #124390;
		font-size: 15px;
		line-height: 40px;
	}

	.close-btn {
		max-width: calc(50% - 5px);
		width: 179px;
		height: 40px;
		font-size: 15px;
	}
}

.cars_booking__modal {
	.modal-title {
		font-size: 26px;
		line-height: 1;
		font-weight: bold;
		text-align: center;
		color: #2c2c2c;
		margin-bottom: 30px;

		span {
			color: #ce181f;
		}
	}

	.modal-subtitle {
		margin-bottom: 25px;
		margin-top: -25px;
		font-size: 16px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: center;

		span {
			color: #ce181f;
		}
	}

	.form-block {
		width: 382px;
		max-width: 100%;

		.input-block {
			margin-bottom: 20px;

			label:not(.checkbox-container) {
				margin-bottom: 6px;
			}

			.inputbox {
				padding: 7px;

				&.datepicker-input {
					background-size: 25px auto;
				}

				&:disabled {
					border: 1px solid #d9d9d9;
					background-color: #ffffff;
					color: #999999;
				}
			}

			select {
				padding: 8px 20px 9px 10px;
				background-image:url(../img/select_hov.svg);
			}

			.description {
				color: #ce171f;
				font-size: 16px;
				font-weight: 400;
			}

			.input-flex {
				display: flex;

				.check-btn {
					flex-shrink: 0;
					width: 111px;
					height: 35px;
					margin-top: 0;
					margin-left: 10px;
					font-size: 16px;
					line-height: 35px;
					font-weight: bold;
				}
			}
		}

		.input-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media (max-width: 450px) {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
			}

			.input-block {
				width: 48%;

				@media (max-width: 450px) {
					width: 100%;
				}
			}
		}

		.btns-row {
			display: flex;
			justify-content: center;

			button {
				min-width: 179px;
				width: 179px;
				height: 40px;
				margin-top: 10px;
				font-size: 15px;
				line-height: 40px;
				font-weight: 400;
				box-sizing: border-box;

				@media (max-width: 450px) {
					width: 100%;
				}

				img {
					margin-right: 8px;
				}
			}
		}
	}

	&#js-add-courtesy-car {
		.btns-row {
			justify-content: space-between;
		}
	}

	&#js-create-booking {
		.btns-row {
			justify-content: space-between;
		}
	}
}

.modal-um {
	padding: 47px 60px 53px;
	width: 501px;
	max-width: 100%;

	@media (max-width: 450px) {
		padding: 40px 20px 25px;
	}

	input:disabled {
		border: 1px solid #d9d9d9;
		background-color: #ffffff;
		color: #999999;
	}

	.lbl-chb {
		padding-bottom: 10px;
		display: block;
	}

	.datepicker-input {
		background-size: auto 76% !important;
	}

	.btns-row {
		.btn {
			margin-top: 0;
		}
	}

	.modal-title {
		font-size: 26px;
		font-weight: bold;
		line-height: 33px;
		text-align: center;
		margin-bottom: 30px;

		@media (max-width: 450px) {
			font-size: 22px;
			margin-bottom: 10px;
		}
	}


	.form-block .input-block label:not(.checkbox-container) {

		margin-bottom: 4px;
	}

	.input-block {
		margin-bottom: 20px;

		input,
		select {
			height: 35px;
		}

		textarea {
			height: 70px;
		}

		select {

			line-height: 35px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.modal-addModule {
	.title {
		margin-bottom: 30px;
		padding-right: 40px;
		padding-left: 40px;
		text-align: center;
		font-size: 30px;
		line-height: 33px;

		@media (max-width: 810px) {
			font-size: 17px;
			line-height: 33px;
		}

		@media (max-width: 500px) {
			font-size: 17px;
			line-height: 33px;
		}
	}

	label {
		position: relative;
		display: block;
		margin-bottom: 4px;
		padding: 12px 30px 12px 43px;
		background: #eef3fb;
		font-size: 18px;
		line-height: 21px;

		input {
			position: absolute;
			top: 50%;
			left: 11px;
			width: 21px;
			height: 21px;
			margin-top: -10px;
			cursor: pointer;
			vertical-align: middle;
			border: 1px solid #a5a5a5;
			border-radius: 25px;
			outline: 0 !important;
			background-color: #fff;
			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				background: #fff url(../img/radio.svg) no-repeat center center;
			}
		}

		input:checked+span:before {
			position: absolute;
			top: 0;
			left: 0;
			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			content: '';
			border: 1px solid #1077ad;
		}

		i {
			display: block;
			color: #808080;
			font-size: 14px;
			line-height: 14px;
			font-style: normal;
		}
	}

	.btn {
		width: 360px;
		max-width: 100%;
		margin-top: 25px;
		margin-right: auto;
		margin-left: auto;
		font-size: 18px;
	}
}
