.mobile-toggle {
	height: 52px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	display: none;

	&:focus {
		outline: none;
	}

	img {
		display: block;
	}
}
