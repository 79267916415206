.header-pdf {
	padding-bottom: 20px;

	.header-pdf-logo-left {
		margin-right: 40px;
		margin-top: 50px;
		float: left;
	}

	.header-pdf-headline {
		width: 756px;
		margin-top: 63px;
		float: left;
		font-size: 40px;
		line-height: 1.2;
		text-align: center;
	}

	.header-pdf-logo-right {
		float: right;
	}
}
