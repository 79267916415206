.modal-map-contacts.mmc-contact-page {
	&:last-child {
		@media (max-width: 650px) {
			margin-bottom: 30px;
		}

		.content {
			border-bottom: 0;
		}
	}

	& > .title {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 24px;
		line-height: 28px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: left;
		border: none;
		padding-left: 0;
	}

	.content {
		padding-bottom: 61px;
		border-bottom: 1px solid #ccc;

		@media (max-width: 650px) {
			padding-bottom: 0;
		}

		.contacts {
			width: 308px;
			min-width: 308px;
			margin-right: 44px;
			box-sizing: border-box;

			@media (max-width: 650px) {
				width: 100%;
				min-width: unset;
				margin-right: 0;
			}

			.accordion h3,
			.accordion > div,
			.adress,
			.phone,
			.title {
				width: 100%;
				padding-left: 0;
				padding-right: 30px;
				box-sizing: border-box;
			}

			.adress {
				padding-right: 0;
				padding-bottom: 0;
				border-bottom: 0;
			}

			.enquiry {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: 15px;
				border-bottom: 1px solid #e6e6e6;
				font-size: 15px;
				line-height: 22px;
				color: #ce171f;
				text-decoration: underline;

				img {
					width: 20px;
					margin-right: 8px;
					flex-shrink: 0;
				}
			}

			.accordion {
				h3 {
					padding: 0;
				}

				& > div {
					padding: 0 19px 7px 0;

					@media (max-width: 650px) {
						padding-right: 0;
					}
				}
			}

			h3 {
				&:last-of-type {
					border: none;
				}

				&::after {
					right: 0;
				}
			}

			div {
				&:last-of-type {
					border: none;
				}
			}
		}

		.map {
			width: calc(100% - 308px - 44px);
			height: 420px;
			border: none;

			@media (max-width: 650px) {
				width: 100%;
				height: 302px;
			}
		}
	}

	.accordion-one-item {
		h3::after {
			display: none;
		}
	}
}
