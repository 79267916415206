.enqiry {
	.slide-title {
		height: 100px;
		background-color: #f0f0f0;
	}

	.form-your-enqiry {
		.form-block {
			.row-1 {
				@include flex-row(30px, 20px);

				.input-block {
					flex: 2 0 290px;
					margin-top: 20px;
					margin-left: 30px;

					&:nth-child(3n-2) {
						flex: 1 0 150px;
					}
				}
			}

			.row-2 {
				.part-branch .radio-label {
					position: relative;
					top: 4px;
				}
			}

			.row-4 {
				.row-title {
					font-size: 14px;
					line-height: 20px;
				}

				.input-block {
					display: flex;
					flex-wrap: wrap;

					.radio-btn {
						margin: 0 35px 15px 0;
					}
				}
			}

			button {
				font-size: 18px;
				font-weight: 700;
				width: 300px;
				margin-top: 40px;
				margin-left: auto;
				margin-right: auto;
			}

			.required-sign {
				font-size: 9px;
				color: #ce181e;
				position: relative;
				top: -6px;
			}
		}
	}

	.form-after-submit {
		.form-title {
			text-align: center;
		}

		.btn {
			width: 300px;
			max-width: unset;
			margin: auto;
			font-size: 18px;
			font-weight: 700;
		}
	}
}
