.warranty-log {
	.reg-war-block {
		width: 648px;
		max-width: 100%;
		margin: 48px auto 42px;
		border: 1px solid #a5a5a5;

		.head {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 20px 48px 20px 45px;
			border-bottom: 1px solid #a5a5a5;
			cursor: pointer;
			transition-duration: 0.2s;

			@media (max-width: 500px) {
				padding: 10px;
			}

			&.closed {
				border-bottom: 0;

				.arrow {
					transform: rotate(-180deg);
				}
			}

			.title {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 24px;
				line-height: 28px;
				font-weight: 400;
				color: #2c2c2c;

				@media (max-width: 500px) {
					font-size: 18px;
					line-height: 20px;
				}
			}

			.hide {
				display: flex;
				align-items: center;
				padding-left: 10px;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				line-height: 33px;
				font-weight: 400;
				color: #ce171f;
				text-decoration: underline;

				.arrow {
					width: 12px;
					height: 8px;
					margin-left: 5px;
					transition-duration: 0.2s;
					background: url('../img/arrow-top-red.png') center center;
				}
			}
		}

		.content {
			padding: 27px 43px 38px 43px;

			@media (max-width: 500px) {
				padding: 10px;
			}

			.branch {
				margin-bottom: 28px;

				p {
					margin-bottom: 8px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 18px;
					line-height: 28px;
					font-weight: 400;
					color: #2c2c2c;
				}

				.custom_radios {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					margin: 0 -5px;
				}

				label {
					display: flex;
					align-items: center;
					margin: 5px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 16px;
					line-height: 28px;
					font-weight: 400;
					color: #2c2c2c;
					cursor: pointer;

					input {
						display: flex;
					}
				}
			}

			.input-block {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 20px;

				@media (max-width: 500px) {
					flex-direction: column;
					align-items: flex-start;
					margin-bottom: 5px;
				}

				input {
					width: calc(100% - 151px);
					height: 40px;

					@media (max-width: 500px) {
						width: 100%;
					}
				}

				select {
					width: calc(100% - 151px);

					@media (max-width: 500px) {
						width: 100%;
					}

					.placeholder {
						color: #adadad;
					}
				}

				label {
					width: 151px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 18px;
					line-height: 28px;
					font-weight: 400;
					color: #2c2c2c;

					@media (max-width: 500px) {
						margin-bottom: 0;
					}
				}

				&.err-required::after {
					width: 100%;
					text-align: right;
				}
			}

			.buttons {
				display: flex;
				padding-top: 10px;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				line-height: 28px;
				font-weight: bold;
				color: #ffffff;

				div {
					display: flex;
					align-items: center;
					justify-content: center;
					transition-duration: 0.2s;
					cursor: pointer;
				}

				.submit {
					width: 242px;
					height: 40px;
					margin-right: 10px;
					background-color: #ce171f;
				}

				.cancel {
					width: 143px;
					height: 40px;
					background-color: #58585a;
				}
			}
		}
	}

	.log {
		.table-wrap {
			overflow-x: auto;
		}

		.filters {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			margin: 0 -20px;
			margin-bottom: 25px;

			> div {
				margin: 0 20px;
			}

			.input-block {
				display: flex;
				justify-content: center;
				align-items: center;
				flex-wrap: wrap;
				margin-bottom: 15px;

				label {
					display: flex;
					align-items: center;
					margin-bottom: 0;
				}

				select {
					width: 230px;
					margin-left: 15px;
					background: #fff url(../img/select_hov.svg) no-repeat;
					background-position: calc(100% - 15px) center;
				}
			}

			.apply {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 175px;
				height: 40px;
				margin-bottom: 15px;
				background-color: #ce171f;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 15px;
				line-height: 28px;
				color: #ffffff;
				cursor: pointer;
			}

			.reset {
				margin-bottom: 15px;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				line-height: 28px;
				font-weight: 400;
				color: #ce171f;
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.table {
			width: 1858px;
			margin: 0 auto;
			border: 1px solid #e6e6e6;

			@media (max-width: 500px) {
				width: 1633px;
			}

			.row {
				display: flex;
				margin-bottom: 0;

				.col {
					&:nth-child(1) {
						width: calc(58px - 26px);
					}

					&:nth-child(2) {
						width: calc(122px - 26px);
					}

					&:nth-child(3) {
						width: calc(149px - 26px);
					}

					&:nth-child(4) {
						width: calc(153px - 26px);
					}

					&:nth-child(5) {
						width: calc(144px - 26px);
					}

					&:nth-child(6) {
						width: calc(158px - 26px);

						@media (max-width: 500px) {
							width: calc(258px);
						}
					}

					&:nth-child(7) {
						width: calc(154px - 26px);
					}

					&:nth-child(8) {
						width: calc(203px - 26px);
					}

					&:nth-child(9) {
						width: calc(118px - 26px);

						@media (max-width: 500px) {
							width: calc(218px);
						}
					}

					&:nth-child(10) {
						width: calc(125px - 26px);
					}

					&:nth-child(11) {
						width: calc(115px - 26px);
					}

					&:nth-child(12) {
						width: calc(112px - 26px);
					}

					&:nth-child(13) {
						width: calc(107px - 26px);
					}

					&:nth-child(14) {
						width: calc(139px - 26px);
					}
				}
			}

			.headline {
				height: 70px;
				background-color: #ce171f;

				.col {
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 0 13px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 16px;
					line-height: 22px;
					font-weight: 400;
					color: #ffffff;

					@media (max-width: 500px) {
						padding: 10px 5px;
						font-size: 13px;
					}
				}
			}

			.content {
				.col {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					padding: 25px 15px;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 14px;
					line-height: 22px;
					font-weight: 400;

					@media (max-width: 500px) {
						padding: 10px 5px;
						font-size: 12px;
						line-height: 17px;
					}

					&:nth-child(even) {
						background-color: #f5f5f5;
					}

					.refs {
						display: flex;
						flex-wrap: wrap;
						width: 100%;

						a {
							font-family: "Vauxhall Neue", sans-serif;
							font-size: 14px;
							line-height: 22px;
							font-weight: 400;
							color: #ce171f;
							text-decoration: underline;

							&:not(:last-child) {
								margin-right: 15px;
							}
						}
					}

					&.col-select {
						padding-top: 20px;

						@media (max-width: 500px) {
							padding-top: 7px;
						}

						select {
							font-size: 14px;
							padding: 0 10px 0 15px;
						}

						&:nth-child(2) {
							select {
								width: 93px;
								height: 30px;
							}
						}

						&:nth-child(8) {
							select {
								width: 174px;
								height: 30px;
							}
						}

						&:nth-child(14) {
							select {
								width: 110px;
								height: 30px;
							}
						}
					}
				}
			}
		}
	}

	&.deals-log {
		.page-main-title {
			margin-bottom: 50px;
		}

		.log .table {
			width: 1640px;
			display: table;

			.row {
				display: table-row;

				.col {
					&:nth-child(1) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(2) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(3) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(4) {
						width: auto;
						box-sizing: border-box;
						padding-right: 5px;
					}

					&:nth-child(5) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(6) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(7) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(8) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(9) {
						width: auto;
						box-sizing: border-box;
						align-items: center;
						text-align: center;
					}

					&:nth-child(10) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(11) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(13) {
						width: auto;
						box-sizing: border-box;
					}

					&:nth-child(14) {
						width: auto;
						box-sizing: border-box;
					}

					&.left_bg {
						border-left: 1px solid #a91830;
					}

					&.right_bg {
						width: auto;
						background: #a91830;
						border-right: 1px solid #a91830;
						box-sizing: border-box;
					}
				}
			}

			.col {
				display: table-cell;
			}

			.headline {
				.col {
					justify-content: flex-start;
					vertical-align: middle;

					&.center_bg {
						background: #a91830;
					}

					&.left_bg {
						background: #a91830;
					}

					&:first-child {
						justify-content: center;
						text-align: center;
					}

					&:nth-child(9) {
						justify-content: center;
						text-align: center;
					}
				}
			}

			.content {
				border-top: 1px solid #d9d9d9;

				.col {
					justify-content: flex-start;
					flex-direction: column;

					&:first-child {
						justify-content: center;
						flex-direction: row;
						text-align: center;
					}

					&.blue_bg {
						background: #eef5fe;
					}

					p + p {
						margin-top: 10px;
					}
				}
			}
		}
	}

	&.reservation-log {
		.log .table {
			display: table;
			width: 1580px;

			.headline,
			.row {
				display: table-row;

				.col {
					display: table-cell;
					width: auto !important;
					vertical-align: middle;

					.name-before {
						content: '';
						display: inline-block;
						width: 16px;
						height: 14px;
						margin-top: -2px;
						margin-right: 3px;
						background: url("../img/name.png") no-repeat;
						vertical-align: middle;
						transition-duration: 0.2s;

						@include hover {
							opacity: 0.8;
						}
					}

					.button--red {
						display: block;
						width: 110px;
						height: 30px;
						font-size: 16px;
						line-height: 30px;
					}
				}

				.col:first-child {
					text-align: center;
				}
			}
		}

		.filters {
			display: block;
			max-width: 96%;
			width: 1580px;
			padding-top: 50px;
			margin-left: auto;
			margin-right: auto;

			.btns {
				display: flex;
				flex-wrap: wrap;
				margin: 0;

				.btn {
					display: block;
					min-width: 180px;
					height: 40px;
					padding: 0 7px;
					margin-right: 20px;
					margin-bottom: 30px;
					font-size: 15px;
					line-height: 40px;
					box-sizing: border-box;
					user-select: none;

					@media (max-width: 500px) {
						width: 100%;
						margin-right: 0;
					}
				}
			}

			.blue--btn {
				display: block;
				width: 180px;
				height: 40px;
				margin-bottom: 30px;
				background: #124390;
				font-size: 15px;
				line-height: 40px;

				@media (max-width: 768px) {
					margin-left: auto;
					margin-right: auto;
				}

				&:before {
					content: '';
					display: inline-block;
					width: 12px;
					height: 12px;
					margin-top: -2px;
					margin-right: 11px;
					background: url("../img/plus.svg") no-repeat center center;
					vertical-align: middle;
				}
			}

			.input-block {
				align-items: flex-start;
				justify-content: flex-start;
				margin: 0 auto 30px;

				@media (max-width: 768px) {
					display: block;
				}

				.nowrap {
					margin-right: 15px;
					margin-bottom: 20px;

					@media (max-width: 768px) {
						margin-right: 0;
						text-align: center;
					}

					@media (max-width: 590px) {
						display: block;
						margin-bottom: 5px;
					}

					label {
						@media (max-width: 768px) {
							width: 100%;
						}
					}

					> * {
						@media (max-width: 590px) {
							display: block;
							width: 250px !important;
							max-width: 100%;
							margin-left: auto !important;
							margin-right: auto !important;
						}
					}
				}

				label {
					font-size: 18px;
				}

				input[type="text"] {
					width: 160px;
					margin-left: 7px;
					margin-right: 7px;
					margin-top: 0;
				}

				&:not(.flex-block) {
					label {
						display: inline-block;
						line-height: 45px;
						vertical-align: top;

						@media (max-width: 768px) {
							display: block;
						}
					}
				}

				&.flex-block {
					align-items: center;

					@media (max-width: 768px) {
						margin-right: 0;
						text-align: center;
					}

					label:not(.checkbox) {
						@media (max-width: 768px) {
							display: block;
							text-align: center;
						}
					}

					select {
						margin-right: 25px;

						@media (max-width: 768px) {
							margin-left: 0;
							margin-top: 15px;
						}

						@media (max-width: 590px) {
							margin-right: 0;
						}
					}

					.checkbox {
						font-family: "Vauxhall Neue", sans-serif;
						font-size: 16px;
						font-weight: 400;
						color: #2c2c2c;
						margin-right: 25px;

						@media (max-width: 768px) {
							display: flex;
							justify-content: center;
							margin-right: 0;
							margin-top: 15px;
						}

						input {
							min-width: 20px;
							width: 20px;
							height: 20px;
							margin-right: 9px;
							margin-bottom: 0;
							margin-top: 0;
							border: 1px solid #a5a5a5;
							background-color: #fff;
							cursor: pointer;
							appearance: none;

							&:checked {
								background-image: url(../img/checked.svg);
								background-repeat: no-repeat;
								background-position: center center;
								background-size: 100%;
							}
						}
					}
				}
			}

			.hb1-2 {
				width: 227px;
				height: 40px;
				margin-bottom: 0;
				border-radius: 5px;
				line-height: 45px;
				text-align: center;

				@media (max-width: 768px) {
					margin-left: auto;
					margin-right: auto;
				}

				@media (max-width: 590px) {
					margin-top: 15px;
				}
			}
		}
	}
}
