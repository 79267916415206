.corsa-remake {
	padding-bottom: 25px;

	&__flex {
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid #e1e1e1;
		padding-bottom: 40px;

		&--no-border {
			border: 0;
		}
	}

	&__left-side {
		width: 65.8%;
		flex-shrink: 0;
		margin-right: 40px;

		.corsa {
			&__lead-block {
				margin-bottom: 16px;
			}

			&__lead {
				text-align: left;

				&.lead-text-1 {
					line-height: initial;
					font-size: 24px;
				}

				&.lead-text-2 {
					text-transform: initial;
					color: #da5157;
				}

				&.lead-text-3 {
					text-transform: initial;
					line-height: initial;
					font-size: 16px;
					color: #2c2c2c;
					margin-top: 3px;
				}
			}
		}

		.car-info {
			&__left-side {
				width: 100%;
				margin-right: 0;
			}

			&__slider {
				.owl-prev,
				.owl-next {
					width: 20px;
				}
			}
		}

		.video-play {
			position: absolute;
			right: 0;
			width: 18%;
			height: 96px;
			bottom: 0;
			background-size: cover;
			background-position: center center;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				right: 0;
				background: url("../img/play.svg") no-repeat center center rgba(0, 0, 0, 0.5);
				background-size: 44px auto;
			}
		}
	}

	&__right-side {
		flex-grow: 1;

		.button-calc {
			height: 60px;
			width: 100%;
			line-height: 60px;

			svg {
				width: 20px;
				height: 23px;
				display: inline-block;
				vertical-align: middle;
				margin-right: 15px;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				font-size: 18px;
				font-weight: 400;
				text-transform: uppercase;
			}
		}

		.car-info {
			&__options {
				margin-top: 30px;
				border: none;
				padding: 0;
			}

			&__title {
				color: #2c2c2c;
				font-size: 20px;
				font-weight: 400;
				padding-bottom: 12px;
				border-bottom: 1px solid #d9d9d9;
				margin-bottom: 33px;
			}

			&__options-row {
				columns: 2;
			}

			&__option {
				display: flex;
				align-items: center;
				margin-bottom: 50px;
			}

			&__option-icon {
				width: 45px;
				margin: 0 14px 0 0;

				img {
					max-width: 100%;
				}
			}

			&__option-title {
				color: #2c2c2c;
				font-size: 15px;
				font-weight: 400;
			}
		}

		.btn--blue {
			width: 100%;

			&.btn--mb {
				margin-bottom: 17px;
			}
		}
	}

	.corsa {
		&__wrapper {
			min-height: 220px;
			height: auto;
			padding: 20px 0;
			box-sizing: border-box;
		}

		&__tabs-title {
			margin-bottom: 10px;
		}

		&__tabs-desc {
			color: #2c2c2c;
			font-size: 16px;
			font-weight: 400;
		}

		&__tabs-header {
			flex-wrap: wrap;
		}

		&__tabs-img {
			width: 220px;
			height: 115px;
			margin-bottom: 10px;
		}
	}

	.car-info {
		margin-top: 30px;
		display: block;
		padding: 0;

		&__title {
			display: flex;
			justify-content: space-between;
			color: #2c2c2c;
			font-size: 24px;
			font-weight: 400;
			line-height: 28px;
		}

		&__items {
			column-count: 2;
			column-gap: 50px;
		}

		&__item {
			padding-left: 13px;
			line-height: 20px;
			font-size: 15px;

			&::before {
				content: '';
				top: 6px;
				left: 0;
				height: 5px;
				width: 5px;
				background-color: #ce171f;
				border-radius: 0;
			}
		}

		&__toggle {
			display: none;
			align-items: center;
			justify-content: center;
			width: 31px;
			height: 31px;
			border-radius: 5px;
			background-color: #ce171f;
			margin-left: 11px;
			position: relative;

			svg {
				fill: #ffffff;
				transition: transform 0.2s ease;
			}

			&.active {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&__hide {
			display: none;
			text-align: center;

			.car-info__toggle {
				margin: 0 auto 15px;
			}
		}

		&--mobile {
			display: none;
		}
	}

	#sync2 .owl-nav .owl-next,
	#sync4 .owl-nav .owl-next {
		border: 1px solid #d9d9d9;
		background-color: #f7f7f7;
		color: #131313;
		font-size: 14px;
		font-weight: 400;
	}
}

.personalise_remake {
	background: none;
	border-top: 2px solid #e1e1e1;

	.personalise__inner {
		padding: 35px 0 0;
		margin-bottom: 30px;
	}

	.personalise__title {
		text-align: left;
		color: #2c2c2c;
		font-size: 30px;
		font-weight: 400;
		line-height: 28px;
		margin-bottom: 24px;
	}

	.personal-table__title {
		color: #2c2c2c;
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
		margin-bottom: 18px;
		border: none;
	}

	.personal-table__table-row {
		&:nth-child(2n+1) {
			background-color: #f7f7f7;
		}
	}

	.personal-table__table-cell {
		font-size: 15px;

		@media (max-width: 450px) {
			font-size: 12px;
		}
	}

	.personalise__zag {
		color: #2c2c2c;
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
		margin-bottom: 15px;
		border: none;
	}

	.personalise__total {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		.input-lane__title {
			margin-right: 10px;
			margin-bottom: 0;
		}

		.personalise__value {
			color: #ce181f;
			font-size: 18px;
			font-weight: 400;
		}
	}

	.input-lane+.input-lane {
		margin-top: 30px;
	}

	.input-lane__title {
		font-size: 15px;
	}

	.custom-slider {
		.slider-val {
			.right {
				input {
					font-size: 16px;
				}
			}
		}

		.slider-desc {

			.left,
			.right {
				color: #808080;
				font-size: 14px;
				font-weight: 400;
			}
		}

		.slider .ui-slider-handle {
			box-sizing: border-box;
		}
	}

	.radiogroup--right {
		padding-top: 25px;
		align-items: center;
		justify-content: flex-start;
		border-top: 2px solid #e6e6e6;

		.radiogroup__radio {
			width: 33.3333%;

			+.radiogroup__radio {
				margin-left: 14px;
			}

			span {
				font-size: 15px;
				font-weight: 400;
				text-transform: uppercase;
				height: 38px;
				line-height: 38px;
				border: 1px solid #a5a5a5;
				min-width: 101px;
				box-sizing: border-box;
				padding-left: 41px;
				padding-right: 28px;
				position: relative;

				&:hover {
					border-color: #ce181f;
				}

				&::before {
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid #a5a5a5;
					background-color: #ffffff;
					box-sizing: border-box;
					border-radius: 100%;
					position: absolute;
					top: 50%;
					margin-top: -10px;
					left: 22%;
					margin-left: -10px;
				}

				&::after {
					content: "";
					display: none;
					width: 10px;
					height: 10px;
					background-color: #ce181f;
					box-sizing: border-box;
					border-radius: 100%;
					position: absolute;
					top: 50%;
					margin-top: -5px;
					left: 22%;
					margin-left: -5px;
				}
			}

			input:checked {
				+span {
					border-color: #ce181f;

					&::before {
						border-color: #ce181f;
					}

					&::after {
						display: block;
					}
				}
			}
		}
	}
}

.tip {
	margin-left: 12px;
	cursor: pointer;

	&:hover {
		svg path.fill {
			fill: #ce181f;
		}
	}
}

.corsa__btns_remake {
	background: none !important;
	padding-bottom: 40px !important;
}



@media screen and (max-width: 900px) {
	#sync1,
	#sync3 {
		padding-bottom: 56px !important;
	}

	.car-info__slider {
		overflow: visible;

		.owl-prev {
			left: -19px !important;
		}

		.owl-next {
			right: -19px;
		}
	}

	.corsa-remake__left-side {
		.car-info__left-side {
			overflow: visible;
		}

		.car-info__controls--bottom,
		.car-info__slider-control {
			top: auto !important;
			bottom: 118px;
		}

		.car-info__controls--bottom {
			padding-right: 0;
		}

		.car-info__slider-control {
			left: 0;
		}
	}

	.personalise_remake {
		border-top: 0;
	}

	.corsa__tabs-body {
		padding: 0 13px;
	}

	.corsa-remake {
		padding-bottom: 20px;

		.corsa__wrapper {
			min-height: 0;
		}

		.corsa__tabs-header {
			padding: 0;
		}

		.corsa__tabs-title {
			width: 50%;
			box-sizing: border-box;
		}

		.corsa__tabs-img {
			display: none;
		}

		.corsa__tabs-desc {
			border: 1px solid #cccccc;
			width: 98%;
			padding: 0 8px;
			font-size: 13px;
			line-height: 1;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		&__flex {
			display: block;
			border-bottom: none;
			padding-bottom: 20px;
		}

		&__left-side {
			width: 100%;
			margin-right: 0;

			.corsa__lead-block {
				margin-bottom: 35px;
			}

			.corsa__lead {
				text-align: center;

				&.lead-text-1 {
					font-size: 24px;
				}

				&.lead-text-2 {
					font-size: 20px;
					line-height: 26px;
					margin-top: 10px;
				}

				&.lead-text-3 {
					font-size: 16px;
					margin-top: 10px;
				}
			}

			.car-info__slide {
				height: 290px;
			}

			.video-play {
				width: 25%;
				height: 90px;
			}

			.car-info__slider-control,
			.car-info__controls--bottom {
				top: 240px;
			}
		}

		&__right-side {
			padding: 0;
			margin-top: 20px;


			.car-info__options {
				margin-top: 30px;
				border: none;
				padding: 0;

				.car-info__title {
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: space-between;
				}

				.car-info__options-row {
					padding: 0 30px;
				}
			}
		}

		#sync2,
		#sync4 {
			padding-right: calc(25% + 10px);
			margin-right: 25%;

			.car-info__slide {
				height: 90px;
			}

			.owl-nav .owl-next {
				width: 32%;
			}

			&.no-video {
				margin-right: 0;

				.owl-nav .owl-next {
					width: 25%;
				}
			}
		}

		.car-info__toggle {
			display: inline-flex;
		}

		.car-info__hide {
			display: block;
		}

		.car-info {
			margin-top: 20px;

			@media (max-width: 900px) {
				display: none;
			}

			.car-info__title {
				text-align: center;
			}

			.car-info__items {
				column-count: 1;
				column-gap: 0;
				display: none;
			}

			&--mobile {
				margin-top: 0;
				padding: 20px 8px;
				border-top: 1px solid #d9d9d9;

				&:last-child {
					border-bottom: 1px solid #d9d9d9;
				}

				@media (max-width: 900px) {
					display: block;
				}
			}
		}

		&--mob-accordions {
			padding: 0 13px;
			margin-bottom: 92px;
		}
	}

	.personalise_remake {
		padding-left: 13px;
		padding-right: 13px;

		.personalise__row {
			flex-direction: column-reverse;
		}

		.personalise__title {
			text-align: center;
			font-size: 24px;
		}

		.personal-table__title {
			text-align: center;
		}

		.personalise__zag {
			text-align: center;
		}

		.personalise__left-side {
			width: 100%;
			margin-right: 0;
			margin-bottom: 0;
			margin-top: 36px;
		}

		.radiogroup--right {
			justify-content: center;
		}

	}

	.corsa__btns_remake {
		padding-left: 13px;
		padding-right: 13px;

		.btn {
			width: 100%;
		}
	}

	.corsa-remake__right-side {
		.button-calc {
			display: none;
		}
	}
}

@media screen and (max-width: 540px) {
	.personalise_remake {
		.personalise__total {
			max-width: calc(100% - 34px);
			margin-left: auto;
			margin-right: auto;
		}

		.personal-table__table {
			width: calc(100% + 40px);
			margin-left: -20px;
			margin-right: -20px;
		}

		.personal-table__table-cell--w70 {
			padding-left: 20px;
		}

		.personal-table__table-cell--w30 {
			padding-right: 20px;
		}
	}
}

.new-corsa-heading-block {
	color: #2c2c2c;
	margin-bottom: 23px;

	&__title {
		font-size: 35px;
		font-weight: bold;
		line-height: 36px;
		text-transform: uppercase;
	}

	&__subtitle {
		font-size: 20px;
		font-weight: 400;
		line-height: 26px;
	}
}

.new-corsa-lb-credit-block {
	display: flex;
	padding: 9px 2.390438247%;
	background-color: #454545;
}

.new-corsa-lb-credit-item {
	flex-grow: 1;
	display: flex;
	justify-content: center;
	position: relative;

	&:not(:last-child)::after {
		content: '';
		display: block;
		height: 100%;
		width: 1px;
		right: -0.5px;
		top: 0;
		position: absolute;
		background-color: #999999;
	}

	&__top {
		color: #fff;
		font-size: 13px;
		font-weight: 400;
		line-height: 1;
		text-transform: uppercase;
		height: 13px;
		margin-bottom: 10px;
	}

	&__bottom {
		color: #ffffff;
		font-size: 15px;
		font-weight: 400;
		line-height: 28px;
		text-transform: uppercase;

		span {
			font-size: 40px;
			line-height: 28px;
			margin-right: 7px;
		}
	}

}


.advance-cash-price {

	border: 1px solid #e6e6e6;
	background-color: #f5f5f5;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #2c2c2c;
	padding: 22px 20px 18px;
	width: 100%;
	box-sizing: border-box;
	min-height: 81px;

	&__price {
		font-size: 40px;
		font-weight: 400;
		line-height: 28px;
		margin-right: 11px;
	}

	&__text {
		font-size: 15px;
		font-weight: 400;
		line-height: 18px;
		text-transform: uppercase;
	}
}


@media screen and(max-width:960px) {
	.new-corsa-lb-credit-item__bottom span {
		white-space: nowrap;
		font-size: 36px;
	}

	.new-corsa-heading-block__title {
		font-size: 30px;
		line-height: 1.2;
		margin-top: 25px;
		text-align: center;
	}

	.new-corsa-heading-block__subtitle {
		font-size: 18px;
		line-height: 26px;
		margin-top: 8px;
		text-align: center;
	}
}


@media screen and(max-width:610px) {
	.new-corsa-heading-block__title {
		font-size: 25px;
	}

	.new-corsa-lb-credit-item__top {
		font-size: 12px;
	}

	.new-corsa-lb-credit-item__bottom span {
		font-size: 28px;
		margin-right: 0;
		display: block;
	}

	.new-corsa-lb-credit-item-content {
		text-align: center;
	}

	.new-corsa-lb-credit-item__bottom {
		font-size: 13px;
	}

	.new-corsa-heading-block {
		text-align: center;
	}
}

@media screen and(max-width:400px) {
	.new-corsa-heading-block__title {
		font-size: 23px;
	}

	.advance-cash-price {
		padding: 10px;
	}

	.advance-cash-price__price {
		font-size: 35px;
	}
}



.cr-car-videos-wrap{
	padding-bottom: 30px;
	padding-top: 30px;
	border-bottom: 1px solid #e1e1e1;
}
.cr-car-videos-title {

	display: block;
	color: #2c2c2c;
	font-size: 24px;
	font-weight: 400;
	line-height: 28px;
}

@media screen and (max-width: 900px) {
	.cr-car-videos-title {
		text-align: center;
	}
}


.cr-car-videos {
	margin-top: 17px;
	&:not(.owl-loaded) {
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;

		.cr-car-videos-item {
			width: calc(33.3333% - 22.3px / 3);
			margin-bottom: 11px;

			&:not(:nth-child(3n)) {
				margin-right: 11px;
			}
		}
	}

	.owl-dots {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 10px 5px;

		.owl-dot.active,
		.owl-dot:hover {
			background-color: #1d1d1b;
			border: 1px solid #1d1d1b;

			span {}

			&:hover {}

			&:active {}

			&:focus {}
		}

		.owl-dot {
			margin: 0 4px;

			background: #fff;
			border: 1px solid #bfbfbf;
			border-radius: 50%;
			cursor: pointer;
			display: block;
			font-size: 0;
			line-height: 0;
			outline: 0;
			padding: 5px;
			transition-duration: .2s;

			span {}

			&:hover {}

			&:active {}

			&:focus {}
		}
	}
}


.cr-yt-video-wrap {
	width: 100%;
	position: relative;


	iframe {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;

		&+.cr-yt-video-preview {
			display: none;
		}
	}

	&::before {
		content: '';
		display: block;
		padding-top: 48.25%;

	}
}
.cr-yt-video-preview {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}

	&:hover {
		.ytp-large-play-button-bg {
			fill: #f00;
			fill-opacity: 1;
		}

	}
}

.cr-yt-vide-btn {
	border: none;
	background-color: transparent;
	padding: 0;
	pointer-events: none;

	position: absolute;
	left: 50%;
	top: 50%;
	width: 68px;
	height: 48px;
	margin-left: -34px;
	margin-top: -24px;


	svg {
		display: block;
		pointer-events: none;

	}

	.ytp-large-play-button-bg {

		transition: all .1s cubic-bezier(0.4, 0.0, 1, 1);
	}
}