.lockdown-event-page {
	.page-title {
		display: flex;
		align-items: center;
		background-color: #f6f6f6;
		text-align: center;
		padding: 10px 0;
		height: 132px;
		box-sizing: border-box;
	}

	.page-title-inner {
		width: 100%;
		max-width: 608px;
		margin: 0 auto;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
	}

	.ld-event {
		padding: 30px 20px 50px;
		max-width: 1141px;
		width: 100%;
		margin: 0 auto;
		text-align: center;
		box-sizing: border-box;

		@media (min-width: 1200px) {
			padding: 38px 0 100px;
		}

		> img {
			width: 100%;
		}

		&__title {
			margin-top: 39px;
			margin-bottom: 25px;

			p {
				&:first-child {
					font-size: 24px;
					line-height: 32px;
					font-weight: bold;
					letter-spacing: 0.2px;

					@media (max-width: 767px) {
						font-size: 22px;
						line-height: 28px;
						margin-bottom: 8px;
					}
				}

				&:last-child {
					font-size: 18px;
					line-height: 24px;
					color: #ce171f;

					@media (max-width: 767px) {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
		}

		&__text {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 34px;

			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 20px;
			}
		}

		&__btns {
			max-width: 1002px;
			margin: 0 auto;
		}

		&__btns-flex {
			display: flex;
			margin: 0 -10px;

			@media (max-width: 767px) {
				flex-direction: column;
				margin: 0;
			}

			button {
				width: calc(33.3333% - 20px);
				margin: 0 10px;
				border-radius: 5px;
				font-size: 18px;

				@media (max-width: 767px) {
					width: 100%;
					margin: 0 auto 10px;
				}

				@media (max-width: 999px) {
					height: 40px;
					font-size: 14px;
					line-height: 40px;
				}
			}
		}
	}
}
