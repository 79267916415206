.booking {
	$self: &;

	padding-bottom: 100px;

	.input-block {
		.radio-label {
			display: flex !important;

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		label.checkbox {
			display: inline-flex;
			font-size: 16px;
			font-weight: 400;
			cursor: pointer;

			span {
				font-size: 20px;
				padding-left: 12px;
			}

			input {
				max-width: 20px;
				width: 100%;
				height: 20px;
				margin: 0;
				border: 1px solid #a5a5a5;
				border-radius: 3px;
				background-color: #fff;
				cursor: pointer;
				box-sizing: border-box;
				appearance: none;

				&:checked {
					background-image: url(../img/checked.svg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100%;
				}
			}
		}
	}

	.tip {
		margin-left: 0;
		transition: fill 0.3s ease;

		@media (min-width: 1024px) {
			&:hover {
				fill: #ce171f;
			}
		}
	}

	&__container {
		@media (max-width: 767px) {
			width: 89%;
		}
	}

	&__breadcrumbs {
		@media (max-width: 767px) {
			margin-top: 8px;
		}
	}

	&__booking-banner {
		margin: 13px 0 32px;

		@media (max-width: 767px) {
			margin: 9px 0 19px;
		}
	}

	&__intermediate-text {
		font-size: 18px;
		line-height: 29px;
		text-align: center;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 23px;
		}
	}

	&__advantages {
		margin: 42px 0 54px;
		position: relative;

		@media (max-width: 767px) {
			margin: 46px 0 35px;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 90px;
			background-color: #f6f6f6;
			position: absolute;
			top: 31px;
			left: 0;
		}
	}

	&__flex-container {
		display: flex;
		justify-content: center;

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	&__advantages-item {
		max-width: 310px;
		width: 100%;

		@media (max-width: 767px) {
			max-width: 100%;
		}

		&:not(:last-child) {
			margin-right: 30px;

			@media (max-width: 767px) {
				margin-right: 0;
				margin-bottom: -1px;
			}
		}

		&:last-child {
			.advantage-card__inner {
				@media (max-width: 767px) {
					padding-bottom: 55px;
				}
			}
		}
	}

	&__main-title {
		font-size: 30px;
		margin-bottom: 26px;
		text-align: center;

		@media (max-width: 767px) {
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 14px;
		}
	}

	&__phone {
		font-size: 15px;
		text-align: center;
		margin-bottom: 45px;

		@media (max-width: 767px) {
			margin-bottom: 35px;
		}

		@media (min-width: 1024px) {
			&:hover {
				a {
					color: #ce171f;
				}
			}
		}

		img {
			padding: 0 8px;
		}

		a {
			font-size: 20px;
			color: #2c2c2c;
			transition: color 0.3s ease;
		}
	}

	&__first {
		max-width: 991px;
		margin: 0 auto;
	}

	&__first-wrapper {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -12px;

		@media (max-width: 767px) {
			margin: 0;
		}

		.input-block {
			width: calc(33.3333% - 24px);
			margin: 0 12px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0 0 23px;
			}

			label {
				width: 100%;

				@media (max-width: 1023px) {
					font-size: 12px !important;
				}

				@media (max-width: 767px) {
					font-size: 14px !important;
				}
			}
		}
	}

	&__main {
		display: flex;
	}

	&__left {
		max-width: 750px;
		width: 100%;

		@media (max-width: 1100px) {
			margin: 0 auto;
		}
	}

	&__step {
		+ .booking__step {
			border-top: 1px solid #e6e6e6;
		}

		&--2 {
			margin-top: 37px;

			@media (max-width: 767px) {
				margin-top: 56px;
			}
		}

		&--3 {
			margin-top: 39px;

			@media (max-width: 767px) {
				margin-top: 50px;
			}
		}
	}

	&__title {
		font-size: 20px;
		margin-bottom: 21px;

		@media (max-width: 767px) {
			margin-bottom: 17px;
		}

		&--toggle {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 18px 15px 0;
			margin-bottom: 0;
			border-bottom: 1px solid #e6e6e6;
			cursor: pointer;

			@media (max-width: 767px) {
				padding: 20px 0;
			}
		}

		&--details {
			margin-bottom: 32px;

			@media (max-width: 767px) {
				margin-bottom: 20px;
			}
		}

		&--address {
			margin-bottom: 26px;

			@media (max-width: 767px) {
				margin-bottom: 23px;
			}
		}
	}

	&__toggle-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 5px;
		background-color: #ce171f;
		cursor: pointer;

		&.rotate {
			svg {
				transform: rotate(270deg);
			}
		}

		svg {
			transform: rotate(90deg);
			transition: transform 0.3s ease;
		}
	}

	&__block {
		+ .booking__block {
			border-top: 1px solid #e6e6e6;
		}

		&--branch {
			padding-top: 28px;
			margin-top: 31px;

			@media (max-width: 767px) {
				padding-top: 30px;
				margin-top: 22px;
			}
		}

		&--service {
			padding-top: 31px;
			margin-top: 26px;

			@media (max-width: 767px) {
				padding-top: 30px;
				margin-top: 41px;
			}

			.input-block {
				&--service-type {
					margin: 16px 0 12px;

					&.inactive select {
						opacity: 0.5;
						background-color: #f7f7f7;
						pointer-events: none;
					}

					select {
						height: 35px;
					}
				}

				&--mot {
					margin-top: 41px;
					margin-bottom: 18px;

					@media (max-width: 767px) {
						margin-top: 32px;
					}
				}

				&--radio {
					margin-bottom: 18px;

					label {
						font-size: 16px !important;
					}

					input {
						margin-right: 4px !important;
					}
				}

				&--tooltip {
					display: flex;

					.radio-label {
						flex-basis: 55%;
						align-items: center;

						@media (max-width: 767px) {
							flex-basis: 67%;
							max-width: 67%;
							line-height: 21px;
						}
					}

					svg {
						margin-right: 7px;

						@media (max-width: 767px) {
							margin-right: 0;
						}
					}
				}
			}
		}

		&--table-1 {
			.booking__table-wrapper {
				margin-bottom: 37px;

				@media (max-width: 767px) {
					margin-bottom: 32px;
				}
			}
		}

		&--table-2 {
			border-top: 0;
		}

		&--customer {
			padding-top: 39px;

			@media (max-width: 767px) {
				padding-top: 51px;
			}

			&.without-table {
				padding-top: 0;
			}

			.input-block--checkbox {
				margin: 38px 0 21px;

				@media (max-width: 767px) {
					margin: 46px 0 21px;
				}

				label.checkbox {
					span {
						font-size: 15px;
						line-height: 24px;
						padding-left: 8px;
						margin-top: -3px;
					}
				}
			}

			.input-block--textarea {
				label {
					width: 100%;
				}

				textarea {
					height: 131px;
					padding: 13px 15px;

					@media (max-width: 767px) {
						height: 181px;
					}
				}
			}
		}

		&--personal {
			padding-top: 39px;

			@media (max-width: 767px) {
				padding-top: 29px;
			}

			.input-block {
				margin-bottom: 20px;

				label {
					width: 100%;
				}
			}
		}
	}

	&__branch {
		margin: 26px -12px 0;

		@media (max-width: 767px) {
			margin: 0;
		}
	}

	&__includes {
		@media (min-width: 1024px) {
			&:hover span {
				text-decoration: none;
			}
		}

		span {
			font-size: 14px;
			color: #ce171f;
			padding-left: 2px;
			text-decoration: underline;
		}
	}

	&__includes-tip {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
	}

	&__table {
		width: 100%;

		tr {
			&:nth-child(odd) {
				background-color: #f2f2f2;
			}
		}

		td {
			font-size: 15px;
			vertical-align: middle;
			padding: 7px 10px;

			@media (max-width: 767px) {
				font-size: 14px;
				padding: 12px 6px;
			}

			.radio-label input {
				margin-right: 0 !important;
			}

			span.blue {
				color: #124390;
			}

			&.price {
				font-size: 16px;
				text-align: right;

				@media (max-width: 767px) {
					font-size: 15px;
					padding-left: 0;
					padding-right: 0;
				}
			}

			&:first-child {
				width: 73%;

				@media (max-width: 767px) {
					width: 56%;
				}
			}

			&:nth-child(2) {
				width: 14%;

				@media (max-width: 767px) {
					width: 24%;
				}
			}

			&:nth-child(3) {
				width: 6%;

				@media (max-width: 767px) {
					width: 10%;
				}
			}

			&:last-child {
				width: 7%;

				@media (max-width: 767px) {
					width: 10%;
				}
			}
		}

		&--font {
			td:first-child {
				font-size: 15px;
			}
		}
	}

	.form-block {
		.booking {
			&__submit,
			&__continue {
				max-width: 241px;
				width: 100%;
				margin-top: 27px;

				@media (max-width: 767px) {
					max-width: 345px;
					margin: 18px auto 0;
				}
			}

			&__continue--first {
				max-width: 318px;
				height: 46px;
				margin: 40px auto 0;

				@media (max-width: 767px) {
					max-width: 344px;
					margin: 17px auto 0;
				}
			}
		}
	}

	.booking-banner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 350px;
		padding: 42px 50px 50px;
		color: #fff;
		background-repeat: no-repeat;
		background-size: cover;
		box-sizing: border-box;

		@media (max-width: 767px) {
			height: 162px;
			padding: 22px 18px 10px;
		}

		&__title {
			font-size: 32px;
			line-height: 39px;
			font-weight: bold;
			margin-bottom: 12px;
			text-transform: uppercase;

			@media (max-width: 767px) {
				font-size: 20px;
				line-height: 28px;
				margin-bottom: 8px;
			}
		}

		&__subtitle {
			font-size: 24px;
			color: #fffefe;
			margin-bottom: 22px;

			@media (max-width: 767px) {
				font-size: 16px;
			}
		}

		&__btn {
			display: block;
			width: 192px;

			@media (max-width: 767px) {
				display: none;
			}

			&--mobile {
				display: none;

				@media (max-width: 767px) {
					display: block;
					margin: 18px auto 0;
				}
			}
		}
	}

	.advantage-card {
		position: relative;

		&::before {
			content: '';
			display: block;
			width: 77%;
			height: 5%;
			border-radius: 5px;
			background-color: #ce171f;
			position: absolute;
			top: -3px;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;

			@media (max-width: 767px) {
				width: 93%;
			}
		}

		&__inner {
			padding: 33px 25px 56px 35px;
			border: 1px solid #d9d9d9;
			border-radius: 5px;
			background-color: #ffffff;

			@media (max-width: 767px) {
				padding: 33px 65px 37px 73px;
				margin: 0 -2%;
				border-radius: 0;
				border-left: 0;
				border-right: 0;
			}
		}

		&__top {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e6e6e6;
			padding-left: 8px;
			padding-bottom: 20px;
			margin-bottom: 18px;

			img {
				margin-right: 13px;
			}
		}

		&__title {
			p {
				font-size: 22px;
				line-height: 22px;
				margin-bottom: 5px;
			}

			span {
				font-size: 15px;
				color: #124390;
			}
		}

		&__list {
			li {
				font-size: 14px;
				padding-left: 22px;
				position: relative;

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background-color: #ce171f;
					border-radius: 50%;
					position: absolute;
					top: 5px;
					left: 7px;
				}

				span {
					color: #ce171f;
				}

				+ li {
					margin-top: 11px;
				}

				br {
					display: none;

					@media (max-width: 767px) {
						display: block;
					}
				}
			}
		}
	}

	.booking-details {
		margin-bottom: 17px;

		&__title {
			font-size: 18px;
			margin-bottom: 10px;
			margin-top: 10px;

			@media (max-width: 767px) {
				font-size: 15px;
				margin-bottom: 16px;
			}

			span {
				font-size: 15px;
				padding-left: 3px;

				@media (max-width: 767px) {
					display: block;
					padding-left: 0;
					margin-top: 8px;
				}
			}
		}

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
		}

		&__item {
			@media (max-width: 767px) {
				width: 38%;
				margin-bottom: 12px;
			}

			&:not(:last-child) {
				border-right: 1px solid #e6e6e6;
				padding-right: 30px;
				margin-right: 30px;

				@media (max-width: 767px) {
					border-right: 0;
					padding-right: 0;
					margin-right: 0;
				}
			}

			&:nth-child(odd) {
				@media (max-width: 767px) {
					border-right: 1px solid #e6e6e6;
					padding-right: 5px;
					margin-right: 30px;
				}
			}

			p {
				&:first-child {
					font-size: 12px;
					color: #9da4b0;
					margin-bottom: 2px;
				}

				&:last-child {
					font-size: 15px;
				}
			}
		}

		.no_vehicle {
			display: inline;
			margin-bottom: 0;

			input {
				width: calc(100% - 105px);
				min-width: 20px;
				height: 40px;
				margin-top: 5px;
				margin-right: 9px;
				margin-bottom: 0;
				padding: 8px 15px;
				border: 1px solid #a5a5a5;
				background-color: #fff;
				border-radius: 3px;
				outline: 0;
				font-size: 14px;
				line-height: 1em;
				appearance: none;
				box-sizing: border-box;
			}
		}
	}

	.booking-branch {
		display: flex;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		&__col {
			width: calc(50% - 24px);
			margin: 0 12px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0;
			}

			&:first-child {
				@media (max-width: 767px) {
					order: 1;
					margin-top: 13px;
				}
			}

			.input-block {
				&--radio {
					display: flex;
					flex-wrap: wrap;
					padding-top: 22px;

					@media (max-width: 767px) {
						padding-top: 8px;
					}

					.radio-label {
						width: 48%;
						margin-bottom: 18px;

						@media (max-width: 767px) {
							width: 50%;
						}
					}
				}
			}
		}

		&__buttons {
			display: flex;
			align-items: center;
			margin-top: 20px;

			a {
				display: flex;
				align-items: center;
				justify-content: center;
				max-width: 173px;
				width: 100%;
				box-sizing: border-box;

				@media (max-width: 767px) {
					max-width: 162px;
				}
			}
		}

		&__direction {
			background-color: #fff;
			color: #ce171f;
			border: 1px solid #ce171f;
			margin-left: 16px;

			@media (min-width: 1024px) {
				&:hover {
					opacity: 0.75;
				}
			}
		}
	}

	.booking-personal {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin: 0 -10px 13px;

		@media (max-width: 767px) {
			margin: 0 0 18px;
		}

		.input-block {
			width: calc(50% - 20px);
			margin: 0 10px 23px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0 0 21.5px;
			}

			&--row {
				@media (max-width: 767px) {
					width: 68%;
				}
			}
		}

		.btn--lookup {
			width: 100px;
			margin: 25px 0 23px 10px;

			@media (max-width: 767px) {
				width: 29%;
				margin: 21px 0 0 3%;
			}
		}
	}

	.sidebar {
		flex-shrink: 0;
		width: 350px;
		margin-left: 40px;

		@media (max-width: 1100px) {
			display: none;
		}

		.side-wrap {
			position: sticky;
			top: 100px;
			width: inherit;
		}

		.card {
			margin-bottom: 25px;
			background-color: #f7f7f7;
			border: 1px solid #d9d9d9;
			border-radius: 0.25rem;

			.card-header {
				margin-bottom: 0;
				padding: 34px 35px 17px;
				border-bottom: none;
				border-radius: 0;
				background: transparent;
				font-weight: bold;
				font-size: 18px;

				p {
					font-size: 14px;
					line-height: 22px;
					margin-top: 12px;
				}
			}

			.card-body {
				padding: 0 35px 29px;

				&__block {
					+ .card-body__block {
						margin-top: 20px;
					}

					&--total {
						td:first-child {
							font-size: 15px;
							color: #222222;
						}
					}
				}

				p {
					max-width: 240px;
					margin-bottom: 20px;
					font-size: 14px;
					line-height: 22px;
				}
			}

			table {
				width: 100%;

				td {
					padding: 10px 0;
					font-size: 14px;

					&:first-child {
						color: #595959;
					}

					&:last-child {
						width: 70px;
						text-align: right;
						font-weight: 700;
					}
				}
			}

			.ah1 {
				font-size: 15px;
				line-height: 16px;
				color: #9da4b0;
				margin-bottom: 5px;
			}

			.total {
				margin-top: 14px;
				padding-top: 0;
				border-top: 1px solid #d7d7d7;
			}
		}
	}

	&--v2 {
		#{$self} {
			&__flex-container {
				@media (max-width: 767px) {
					width: 90%;
				}
			}

			&__intermediate-text {
				@media (max-width: 767px) {
					text-align: left;
				}
			}

			&__advantages {
				@media (max-width: 767px) {
					margin: 35px 0 35px;
				}
			}

			&__advantages-item {
				@media (max-width: 767px) {
					&:not(:last-child) {
						margin-bottom: 20px;
					}
				}

				&:nth-child(2) {
					@media (max-width: 767px) {
						order: -1;
					}
				}
			}

			&__main-title {
				@media (max-width: 767px) {
					text-align: left;
				}
			}

			&__phone {
				@media (max-width: 767px) {
					text-align: left;
				}
			}
		}

		.booking-banner {
			@media (max-width: 767px) {
				background-image: none !important;
				color: #4d4d4d;
				height: auto;
				padding: 0;
				margin: 16px 0 8px;
			}

			&__title {
				@media (max-width: 767px) {
					font-size: 24px;
					line-height: 33px;
					font-weight: 400;
					text-transform: none;
				}
			}

			&__btn--mobile {
				@media (max-width: 767px) {
					display: block;
					margin: 25px 6px 0;
				}
			}
		}

		.advantage-card {

			&::before {
				@media (max-width: 767px) {
					display: none;
				}
			}

			&__inner {
				@media (max-width: 767px) {
					padding: 33px 25px 37px 25px;
					margin: 0;
					border-radius: 5px;
					border: 1px solid #d9d9d9;
				}
			}
		}
	}
}
