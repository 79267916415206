@media screen and (max-height: 800px) {
  .new-cars__title {
    height: 70px;
    line-height: 70px;
  }
  .new-cars__item-title--top {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1000px) {
  .wrapper--100vh {
    height: auto;
  }
  .page--100vh {
    height: auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 20px 0;
  }
  .express-store {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .express-store__title {
    font-size: 20px;
    line-height: 23px;
  }
  .express-store__subtitle {
    font-size: 18px;
    line-height: 21px;
  }
  .subfooter {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .car-info {
    padding-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .car-info__left-side {
    width: 100%;
  }
  .car-info__options-row {
    margin-left: 0;
  }
  .personalise__inner {
    padding: 20px 0;
  }
  .new-cars__title {
    font-size: 20px;
  }
  .new-cars__row {
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .new-cars__button {
    width: 100%;
    max-width: 250px;
  }
  .new-cars__item {
    width: 50%;
    padding: 10px 5px;
  }
  .new-cars__item--border {
    border: none;
  }
}

@media screen and (max-width: 900px) {
  .personalise__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .personalise__right-side{
    max-width: 450px;
    width: 100%;}
  .personalise__left-side {
    margin-right: 0;
    max-width: 450px;
    width: 100%;
    margin-bottom: 20px;
  }
  .personalise__left-side:after{display: none;}
  .personalise__button {
    float: none;
    margin: 20px auto 0;
  }
  .input-lane {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .input-lane--right {
    float: none;
    margin-left: 0;
  }
  .input-lane__input--right {
    margin-left: 0;
  }
  .input-lane .input-box{
    margin-top: 5px;
  }
  .radiogroup--right {
    width: auto;
    float: none;
    padding-right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media screen and (max-width: 800px) {
  .corsa__wrapper {
    position: relative;
    height: auto !important;
  }
  .corsa__tabs-header {
    position: relative;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px 0;
  }
  .corsa__tabs-body {
    padding-top: 20px;
  }
  .corsa__tabs-img {
    width: 100%;
  }
  .corsa__tabs-desc {
    font-size: 14px;
    padding: 0 10px;
    letter-spacing: -0.5px;
  }
  .corsa__lead {
    font-size: 18px;
    line-height: 21px;
  }
  .corsa__tabs-title {
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
  .corsa__tabs-title:before {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .mobile-toggle{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important;
  }
  .btns__inner {
    margin-top: 20px;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }
  .main-menu__container{
    padding-top: 30px;
  }
  .main-menu__close--top{
    background: url("../img/close-btn-2.png") center center no-repeat;
  }


  .main-menu__title {
    font-size: 22px;
    padding-bottom: 10px;
    max-width: 100%;
    text-align: left;
  }
  .main-menu__signin{
    display: block;
  }
  .main-menu__mobile-chat{
    display: block;
  }

  .footer{
    padding-top: 50px;
  }
  .footer__row{
    display: none;
  }
  .footer .footer-cols .col .soc-links{
    display: none !important;
  }
}

@media screen and (max-width: 500px) {
  .car-info__controls--bottom{
    top:208px;
  }
  #sync4 .car-info__slide, #sync2 .car-info__slide{
    margin-top: 20px;
  }
  .car-info__options {
    padding: 30px 20px;
  }
  .car-info__options-row {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
  }
  .car-info__slide {
    height: 250px;
  }
  .new-cars__button {
    margin-top: 10px;
  }
  .new-cars__img {
    height: 100px;
  }
}
