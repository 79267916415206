.thanks-f-p-page {
	padding-bottom: 190px;

	.line1 {
		margin-top: 72px;
		margin-left: auto;
		margin-right: auto;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 24px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;
		text-align: center;

		@media (max-width: 450px) {
			margin-top: 20px;
		}
	}

	.line2 {
		margin-top: 13px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: center;

		@media (max-width: 450px) {
			font-size: 14px;
			margin-top: 0;
		}

		.bold {
			font-weight: bold;
		}

		.red {
			color: #ce171f;
		}
	}

	.line3 {
		width: 700px;
		max-width: 100%;
		margin: 13px auto 0;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: center;

		@media (max-width: 450px) {
			font-size: 14px;
			margin-top: 0;
		}
	}

	.line4 {
		margin-top: 48px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 24px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;

		@media (max-width: 450px) {
			margin-top: 20px;
		}
	}

	.form-block {
		.input-block.branch {
			width: 558px;
			max-width: 100%;
			margin: 13px auto 0;

			.radios {
				width: 100%;
				max-width: 100%;
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-wrap: wrap;

				@media (max-width: 450px) {
					display: block;
				}
			}

			.radio-btn {
				max-width: 33%;
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				@media (max-width: 450px) {
					max-width: 100%;
				}

				.radio-label {
					margin-bottom: 0;
				}

				+ .radio-btn {
					margin-top: 0;
				}

				input[type=radio] {
					width: 20px;
					height: 20px;

					&:checked {
						background-size: 10px 10px;
					}
				}
			}
		}

		.input-row {
			width: 558px;
			max-width: 100%;
			margin: 19px auto 0;
			display: flex;
			justify-content: space-between;

			@media (max-width: 450px) {
				display: block;
			}

			.input-block {
				max-width: 48.3%;
				width: 100%;

				@media (max-width: 450px) {
					max-width: 100%;
					margin-bottom: 15px;
				}
			}
		}

		input[type="submit"] {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 266px;
			max-width: 100%;
			height: 46px;
			margin: 35px auto 0;
			background-color: #124390;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
			color: #ffffff;
			text-transform: uppercase;
		}

		.note {
			margin-top: 65px;
			margin-bottom: -10px;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
		}
	}

	&__no-email {
		.line3 {
			width: 560px;

			a {
				color: #d1252c;
				text-decoration: underline;
			}
		}

		.winter-service .reg-and-mile {
			max-width: 520px;
			margin-top: 10px;

			input[type="email"] {
				margin-bottom: 20px;
			}
		}

		.form-block .input-block label:not(.checkbox-container) {
			display: flex;
		}

		.form-block input[type=submit] {
			background-color: #d1252c;
		}
	}

	.thfp-v2-map-block.winter-service {
		padding-top: 38px;
		padding-bottom: 38px;
	}

	&--confirmation {
		.line1 {
			margin-top: 59px;
			font-weight: 400;

			@media (max-width: 768px) {
				font-size: 22px;
				line-height: 26px;
				margin-top: 30px;
			}

			@media (max-width: 450px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		.thanks-f-p-page {
			&__inner {
				max-width: 850px;
				padding-bottom: 102px;

				@media (max-width: 768px) {
					padding-bottom: 50px;
				}
			}
		}

		.thfp-v2-map-block.winter-service {
			padding-bottom: 9px;

			@media (max-width: 768px) {
				padding-top: 23px;
			}
		}

		.form-block {
			.input-row {
				width: 100%;
			}

			.note {
				font-size: 15px;
				line-height: 18px;
				margin-top: 42px;
				margin-bottom: 0;
			}

			input[type="submit"] {
				width: 300px;
				height: 45px;
				margin-top: 17px;
				border-radius: 5px;
				font-size: 14px;
				font-weight: 400;
				text-transform: none;
				box-sizing: border-box;
			}
		}

		.winter-service {
			.reg-and-mile {
				.row {
					&.border-row {
						.input-block:not(.input-standart) {
							padding-bottom: 22px;

							@media (max-width: 768px) {
								margin: 0 auto;
							}
						}
					}

					&.border-top-row {
						padding-top: 23px;
						padding-bottom: 37px;
					}

					&.item.active {
						align-items: center;
					}

					.input-block {
						.ah1 {
							margin-bottom: 22px;

							@media (max-width: 768px) {
								text-align: center;
							}
						}

						.radio-btn + .radio-btn {
							margin-top: 25px;
						}

						label {
							&:not(.checkbox-container) {
								font-size: 14px;
								margin-bottom: 3px;
							}

							&.radios-title {
								font-size: 16px;
								margin-bottom: 26px;

								@media (max-width: 768px) {
									text-align: center;
								}
							}

							&.radio-label {
								font-size: 16px;

								span {
									color: #ce171f;
								}
							}
						}
					}
				}
			}
		}
	}
}
