.new-cars-page {
	.active-model-year {
		margin-bottom: 25px;
		padding-bottom: 30px;
		border-bottom: 2px solid #e6e6e6;

		.ah2 {
			margin-bottom: 5px;
			font-size: 20px;
			line-height: 23px;
			font-weight: bold;
		}

		label {
			display: block;
			margin-bottom: 8px;
			font-size: 16px;
		}

		select {
			display: inline-block;
			width: 287px;
			max-width: 100%;
			margin-bottom: 10px;
			padding: 6px 15px;
			vertical-align: top;
			border: 1px solid #a5a5a5;
			border-radius: 0;
			outline: 0;
			background: #fff url(../img/select_bg.svg) no-repeat;
			background-position: calc(100% - 15px) center;
			font-size: 16px;
			line-height: 1em;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-sizing: border-box;

			&::-ms-expand {
				display: none;
			}
		}

		.button {
			display: inline-block;
			width: 85px;
			height: 30px;
			margin-right: 0;
			margin-left: 10px;
			background: #124390;
			font-size: 13px;
			line-height: 30px;
			text-transform: none;
			vertical-align: top;
			position: relative;

			@media (max-width: 500px) {
				display: block;
				margin-left: 0;
			}

			&:before {
				content: '';
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-top: -2px;
				margin-right: 8px;
				vertical-align: middle;
				background: url(../img/save.svg) no-repeat;
			}

			@include hover {
				background: #2c2c2c;
			}
		}
	}

	.overflow_color_blocks {
		overflow: auto;
		margin-bottom: 50px;
	}

	.color_blocks_add {
		margin-bottom: 35px;
		padding-bottom: 10px;
		border-bottom: 2px solid #e6e6e6;

		.ah2 {
			margin-bottom: 20px;
			font-weight: bold;
			font-size: 20px;
			line-height: 23px;
		}

		.row {
			display: flex;
			margin-bottom: 30px;

			@media (max-width: 768px) {
				display: block;
			}

			& > div {
				&:first-child {
					flex-shrink: 0;
					width: 290px;
					margin-right: 35px;

					@media (max-width: 768px) {
						width: auto;
						margin-bottom: 20px;
						margin-right: 0;
					}
				}

				&:last-child {
					width: 100%;
				}
			}

			label {
				display: block;
				margin-bottom: 5px;
				font-size: 16px;
			}

			input[type=text] {
				width: 100%;
				max-width: 380px;
				padding: 10px 15px;
				border: 1px solid #a5a5a5;
				font-size: 16px;
				line-height: 19px;
				box-sizing: border-box;
			}

			.image_block {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 215px;
				margin-bottom: 21px;
				border: 1px solid #e6e6e6;

				img {
					max-width: 90%;
					max-height: 90%;
				}
			}

			input[type="checkbox"] {
				width: 20px;
				height: 20px;
				margin-top: 5px;
				margin-left: 0;
				cursor: pointer;
				border: 1px solid #a5a5a5;
				background-color: #fff;
				font-size: 14px;
				line-height: 17px;
				-webkit-appearance: none;
				-moz-appearance: none;

				&:checked {
					background-image: url(../img/checked.svg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100%;
				}
			}

			.button {
				position: relative;
				width: 140px;
				height: 30px;
				margin-right: 0;
				text-transform: none;
				font-size: 13px;
				line-height: 30px;

				input[type="file"] {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					cursor: pointer;
					opacity: 0;
				}

				&--red {
					&:before {
						content: '';
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-top: -2px;
						margin-right: 8px;
						vertical-align: middle;
						background: url("../img/change.svg") no-repeat;
					}
				}

				&--blue {
					background: #124390;

					@include hover {
						background: #2c2c2c;
					}

					&:before {
						content: '';
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-top: -2px;
						margin-right: 8px;
						vertical-align: middle;
						background: url("../img/save.svg") no-repeat;
					}
				}
			}

			table {
				width: 100%;
				margin-bottom: 20px;

				input[type="text"] {
					padding-top: 5px;
					padding-bottom: 5px;
				}

				tr:nth-child(even) {
					background: #f2f2f2;
				}

				td {
					padding: 15px 15px 15px 8px;

					&:first-child {
						width: 10%;
					}

					&:nth-child(2) {
						width: 30%;

						input {
							max-width: 115px;
						}
					}

					&:nth-child(3) {
						input {
							max-width: 195px;
						}
					}
				}

				th {
					padding: 0 8px 8px;
					text-align: left;
					font-size: 16px;

					@media (max-width: 768px) {
						font-size: 13px;
					}
				}
			}
		}
	}

	.color_blocks {
		overflow-x: auto;
		padding-bottom: 10px;
		border-bottom: 2px solid #e6e6e6;

		.ah2 {
			margin-bottom: 20px;
			font-size: 20px;
			line-height: 23px;
			font-weight: bold;
		}

		table {
			width: 100%;

			input {
				width: 100% !important;
			}

			tr:nth-child(even) {
				background-color: #f2f2f2;
			}

			tr:last-child {
				background-color: #fff;

				td {
					padding-top: 16px;
					padding-bottom: 16px;
				}
			}

			th {
				padding: 9px 13px;
				text-align: left;
			}

			tr td:last-child {
				width: 200px;
			}

			tr td:nth-child(3) {
				padding-right: 30px;
			}

			td {
				box-sizing: border-box;
				height: 66px;
				padding: 7px 13px;
				vertical-align: middle;

				&:nth-child(3) {
					min-width: 163px;
				}

				&:nth-child(4) {
					min-width: 108px;
				}

				&:nth-child(5) {
					min-width: 110px;
				}

				&:nth-child(6) {
					width: 69px;
				}

				.image_block {
					width: 69px;
					height: 52px;
					border: 1px solid #e6e6e6;
					background-position: center center;
					background-size: cover;
					position: relative;

					.del {
						width: 15px;
						height: 15px;
						border-radius: 50%;
						background: url("../img/red_x.svg") no-repeat center center #ccc;
						background-size: 8px auto;
						position: absolute;
						top: 2px;
						right: 2px;
					}
				}

				a {
					cursor: pointer;
					color: #f01019;
				}

				.remove:before {
					content: '';
					display: inline-block;
					width: 13px;
					height: 13px;
					margin-top: -2px;
					margin-right: 5px;
					margin-left: 22px;
					vertical-align: middle;
					background: url("../img/red_x.svg") no-repeat;
				}

				.add_new_color {
					display: block;
					width: 155px;
					cursor: pointer;
					transition: .3s background-color;
					text-align: center;
					color: #fff;
					background: #124390;
					line-height: 30px;

					@include hover {
						background-color: #58585a;
					}
				}

				input[type="file"] {
					width: 70px;
					height: 30px;
					position: relative;

					&:before {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						content: 'Upload';
						cursor: pointer;
						transition: .3s background-color;
						text-align: center;
						color: #fff;
						background: #026fa8;
						line-height: 30px;
					}

					@include hover {
						&:before {
							background-color: #58585a;
						}
					}
				}

				input[type="text"] {
					width: 94px;
					padding: 5px;
					border: 1px solid #a5a5a5;
					box-sizing: border-box;
				}

				select {
					display: block;
					width: 133px;
					padding: 5px 15px;
					border: 1px solid #a5a5a5;
					border-radius: 0;
					outline: 0;
					background: #fff url(../img/select_bg.svg) no-repeat;
					background-position: calc(100% - 15px) center;
					font-size: 16px;
					line-height: 1em;
					-webkit-appearance: none;
					-moz-appearance: none;
					box-sizing: border-box;

					&::-ms-expand {
						display: none;
					}
				}
			}
		}
	}

	+ .footer {
		background-color: #f6f6f6;
	}

	.form-block {
		.input-block {
			input[type=radio] {
				width: 20px;
				height: 20px;
				background: #FFF;

				&:checked {
					background: #FFF url(../img/radio-b.svg) no-repeat center center;

					&:not(:hover) {
						background: #FFF url(../img/radio-g.svg) no-repeat center center;
					}
				}
			}
		}

		.input-block {
			> label {
				margin-bottom: 2em;
				font-weight: bold;
				font-size: 20px;
			}
		}
	}

	.no-select {
		color: #adadad;

		label {
			color: #adadad;
		}
	}

	form {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.btn-next {
		width: 136px;

		img {
			margin-left: 5px;
			vertical-align: middle;
		}
	}

	.step-1-2 {
		.step-2 {
			display: none;
			margin-top: 70px;
		}
	}

	.step-3 {
		display: none;

		.selected-table {
			margin-top: 35px;

			.variants-table {
				.selected-variant {
					td {
						background: transparent;

						&:first-child {
							padding-left: 0;
						}
					}
				}
			}
		}

		.inputs_block {
			margin-top: 70px;

			.images-upload-block {
				margin-top: 0;
				margin-bottom: 10px;
			}

			.checkbox-row {
				display: flex;
				align-items: center;

				.checkbox-container {
					width: auto;
					min-width: 20px;
				}

				.input-block {
					margin-left: 30px;

					input[type="number"] {
						width: 65px;
						margin-right: 8px;
					}
				}
			}

			.ah1 {
				margin-bottom: 7px;
				font-size: 14px;
				line-height: 17px;
				font-weight: 700;
				color: #2c2c2c;
			}

			.flex-w25 {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				& > * {
					width: 24%;
				}

				& > div {
					@media (max-width: 600px) {
						width: 48%;
					}
				}
			}

			.flex-w50 {
				display: flex;
				justify-content: space-between;
				width: 49%;

				@media (max-width: 600px) {
					width: auto;
				}

				& > * {
					width: 48%;
				}
			}

			hr {
				margin-bottom: 25px;
			}

			textarea {
				height: 150px;
				margin-bottom: 25px;
				padding: 12px 15px;
				border: 1px solid #a5a5a5;
				outline: 0;
				font-size: 16px;
				line-height: 1em;
				box-sizing: border-box;

				&.red {
					border-color: red;
				}
			}

			input {
				width: 100%;
				margin-bottom: 25px;
				padding: 12px 15px;
				border: 1px solid #a5a5a5;
				outline: 0;
				font-size: 16px;
				line-height: 1em;
				box-sizing: border-box;

				&.red {
					border-color: red;
				}
			}

			.checkbox-container {
				margin-bottom: 20px;
				padding-left: 30px;
			}
		}

		.key-spec-flex {
			display: flex;
			margin-top: 30px;

			@media (max-width: 768px) {
				display: block;
			}

			#specification_text {
				width: 500px;
				height: 600px;
				padding: 12px 15px;
				resize: vertical;
				border: 1px solid #a5a5a5;
				outline: 0;
				font-size: 16px;
				line-height: 1em;
				box-sizing: border-box;

				@media (max-width: 768px) {
					width: 100%;
				}
			}

			#specification_btn {
				width: 70px;
				min-width: 70px;
				height: 100px;
				margin: 95px 15px 0;
				cursor: pointer;
				border: none;
				background: url(../img/arrow-doble.png) center center no-repeat;
				background-size: cover;

				@media (max-width: 768px) {
					margin: 20px auto;
					width: 100px;
					height: 70px;
					background-image: url("../img/arrow-doble2.png");
				}

				&:focus {
					outline: 0;
				}
			}

			.key-spec table {
				@media (max-width: 768px) {
					max-width: 100%;
				}
			}
		}

		.key-spec {
			display: none;

			table {
				box-sizing: border-box;
				width: 100%;
				max-width: 550px;

				tr {
					th {
						padding: 10px;
						text-align: left;
						background-color: #f2f2f2;
						font-weight: bold;
						font-size: 13px;

						&:first-child {
							width: 70px;
						}
					}

					td {
						padding: 15px 10px 10px;
						font-size: 14px;
						line-height: 24px;

						.checkbox-container .checkbox-mark:after {
							border-color: #444444;
						}

						&:last-child {
							text-align: center;
						}
					}

					&.checked {
						td {
							text-decoration: underline;
							font-weight: bold;
						}
					}
				}
			}
		}

		.images-upload-block-title {
			margin-top: 70px;
		}

		.images-upload-block {
			margin-top: 24px;

			.label {
				font-size: 13px;
				line-height: 28px;
			}

			.grid-block {
				.item {
					min-width: 140px;
					max-width: 140px;

					&:nth-child(1) {
						margin-right: 50px;
					}
				}
			}
		}

		.textarea-block {
			margin-top: 40px;

			label {
				max-width: 420px;
				font-size: 16px;
				line-height: 24px;
			}
		}

		.btns-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			width: 100%;
			max-width: 397px;
			margin-top: 40px;
			margin-right: 0;
			margin-left: 0;

			button {
				margin-top: 20px;
			}

			.btn-save {
				width: 190px;

				@media (max-width: 400px) {
					width: 100%;
				}

				img {
					margin-left: 17px;
					vertical-align: middle;
				}
			}

			.btn-prev {
				width: 136px;

				@media (max-width: 400px) {
					width: 100%;
				}

				img {
					margin-right: 17px;
					vertical-align: middle;
				}
			}
		}
	}
}
