.terms-conditions {
	.inner {
		margin-bottom: 100px;
	}

	.line1 {
		margin-top: 36px;
	}

	.line2 {
		margin-top: 25px;

		a {
			font-weight: 400;
			line-height: 33px;
			color: #b72126;
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}
}