.invoice-page {
	.page-title {
		padding: 61px 0 47px;
		text-align: center;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
		margin-bottom: 14px;
	}

	.subtitle {
		font-size: 30px;
		line-height: 33px;
	}

	.invoice {
		width: 100%;
		max-width: 936px;
		margin: 0 auto;
		padding: 0 15px 70px;
		box-sizing: border-box;

		@media (min-width: 950px) {
			padding: 0 0 170px;
		}

		&__flex {
			display: flex;
			flex-wrap: wrap;

			@media (min-width: 768px) {
				margin: 0 -17.5px;
			}
		}

		&__col {
			width: 100%;
			margin-bottom: 30px;

			@media (min-width: 768px) {
				width: calc(50% - 35px);
				margin: 0 17.5px;
			}
		}

		&__table-name {
			font-size: 24px;

			span {
				font-size: 18px;
				color: #757575;
			}
		}

		&__reset {
			display: inline-block;
			font-size: 14px;
			color: #ce171f;
			text-decoration: underline;
			background-color: #fff;
			margin-left: 5px;
		}

		&__table {
			table {
				width: 100%;
				border-collapse: collapse;
				border-bottom: 1px solid #e6e6e6;

				tr {
					&:nth-child(odd) {
						background-color: #f2f2f2;
					}
				}

				td {
					padding: 13px 22px;
				}
			}

			&--vehicle-details {
				margin-bottom: 29px;

				.invoice__table-name {
					margin-bottom: 30px;
				}

				table {
					td {
						&:first-child {
							width: 68%;
						}

						&:last-child {
							width: 32%;
						}

						p {
							&:first-child {
								font-size: 13px;
								line-height: 16px;
								color: #757575;
								margin-bottom: 6px;
							}

							&:last-child {
								font-size: 14px;
								line-height: 16px;
							}
						}
					}
				}
			}

			&--service-details {
				.invoice__table-name {
					font-size: 20px;
					line-height: 28px;
					margin-bottom: 5px;
				}

				table {
					border-bottom: none;

					tr {
						&:last-child {
							background-color: #fff;
							border-top: 1px solid #e6e6e6;

							td {
								font-size: 18px;
								text-align: right;

								span {
									font-size: 20px;
								}
							}
						}
					}

					td {
						font-size: 16px;
						padding: 16.5px 22px;

						&:first-child {
							width: 90%;
						}

						&:last-child {
							width: 10%;
							text-align: right;
						}
					}
				}
			}

			&--new-invoice {
				.invoice__table-name:nth-child(2) {
					font-size: 18px;
					margin-top: 32px;
					margin-bottom: 12px;
				}

				table {
					border-bottom: none;

					thead {
						tr:nth-child(odd) {
							background-color: #fff;
						}

						td {
							font-size: 13px;
							color: #757575;
							padding: 7px 10px;
						}
					}

					tbody tr:last-child {
						border-top: 1px solid #e6e6e6;
					}

					td {
						padding: 11px 10px;

						&:first-child {
							width: 25%;
						}

						&:nth-child(2) {
							width: 56%;
						}

						&:last-child {
							width: 19%;
							text-align: right;
						}
					}
				}
			}

			&--history {
				border-top: 1px solid #e6e6e6;
				margin-top: 41px;
				padding-top: 34px;

				.invoice__table-name {
					font-size: 20px;
					text-align: center;
					margin-bottom: 18px;
				}

				table {
					border: 1px solid #e6e6e6;

					thead {
						display: none;

						@media (min-width: 768px) {
							display: table-header-group;
						}

						tr {
							border-bottom: 1px solid #e6e6e6;
						}
					}

					tbody tr {
						display: block;
						width: 100%;
						box-sizing: border-box;
						//position: relative;
						padding: 13px 0;

						@media (min-width: 768px) {
							display: table-row;
							padding: 0;
						}
					}

					tr {
						+ tr {
							border-top: 1px solid #e6e6e6;
						}

						&:nth-child(odd) {
							background-color: #fff;
						}

						&:nth-child(even) {
							background-color: #f7f7f7;

							@media (min-width: 768px) {
								background-color: #fff;
							}
						}
					}

					th {
						font-weight: 400;
						text-align: left;
					}

					td,
					th {
						width: 100%;
						font-size: 16px;
						padding: 25.5px 16px;
						display: flex;
						justify-content: flex-start;
						flex-wrap: nowrap;
						background-color: transparent;
						border-bottom: none;
						box-sizing: border-box;

						@media (min-width: 768px) {
							display: table-cell;
							margin: -15px 0;
						}

						&::before {
							content: attr(aria-label);
							display: inline-block;
							max-width: 100px;
							width: 100%;
							margin-right: 20px;
							color: #999999;

							@media (min-width: 768px) {
								display: none;
							}
						}

						&:nth-child(even) {
							@media (min-width: 768px) {
								background-color: #f7f7f7;
							}
						}

						&:nth-child(3) p + p {
							margin-top: 9px;
						}

						@media (min-width: 768px) {
							&:first-child {
								width: 15%;
							}

							&:nth-child(2) {
								width: 12%;
							}

							&:nth-child(3) {
								width: 58%;
							}

							&:last-child {
								width: 15%;
							}
						}
					}
				}
			}
		}

		.form-block {
			margin-top: 9px;

			.input-block {
				label {
					width: 100%;
				}

				input {
					height: 35px;
					margin-top: 4px;
					font-size: 16px;
					padding: 6px 8px;
				}
			}

			.btn {
				width: 168px;
				height: 35px;
				margin-top: 7px;
				font-size: 14px;
				line-height: 35px;
				font-weight: 400;
			}
		}
	}
}