.payment-request {
	padding-bottom: 160px;

	.payreq-btn-invoce-details {
		width: 132px;
	}

	.payreq-btn-remove {
		width: 89px;
	}

	.payreq-pdf-open {
		display: block;
		width: 30px;
		height: 27px;
		background: url(../img/pdf.png) no-repeat center center;
		background-size: contain;
		cursor: pointer;
		transition-duration: 0.2s;

		@include hover {
			opacity: 0.8;
		}
	}

	.payreq-toggler-chb-wrap {
		width: 100%;
	}

	.payreq-toggler.toggle {
		border-radius: 0;
		border-color: #cccccc;

		&.btn-payreq-toggler-on {
			border-color: #1abb64;
		}
	}

	.payreq-toggler .toggle-on {
		background-color: #1abb64;
		color: #ffffff !important;
		border-radius: 0;

		@include hover {
			opacity: 0.8;
		}
	}

	.payreq-toggler .toggle-off {
		border-radius: 0;
	}

	.payreq-toggler .toggle-handle.btn.btn-light {
		background: #ffffff;
		border-radius: 0;
		font-size: 14px;

		@include hover {
			transition-duration: 0.2s;
			opacity: .8;
		}
	}

	.payreq-color-select {
		transition-duration: 0.2s;
		background: #fff url(../img/select_hov.svg) no-repeat !important;
		background-position: calc(100% - 15px) center !important;
		line-height: 18px !important;
		cursor: pointer;
	}

	.payreq-color-select--yellow {
		border: 1px solid #dfcd26 !important;
		background-color: #f5efb8 !important;
	}

	.payreq-color-select--green {
		border: 1px solid #1abb64 !important;
		background-color: #b8f5d4 !important;
	}

	.payreq-color-select--red {
		border: 1px solid #ff8188 !important;
		background-color: #ffdbdd !important;
	}

	.payreq-filters + .doubleScroll-scroll-wrapper {
		margin-left: 117px;
		margin-right: 117px;
	}

	.pr-btn-new {
		display: block;
		max-width: 100%;
		height: 40px;
		padding: 0 7px;
		margin-bottom: 30px;
		margin-right: 20px;
		font-size: 15px;
		line-height: 40px;
		box-sizing: border-box;
		user-select: none;

		&:before {
			content: '';
			display: inline-block;
			width: 12px;
			height: 12px;
			margin-top: -2px;
			margin-right: 11px;
			background: url(../img/plus.svg) no-repeat center center;
			vertical-align: middle;
		}
	}

	.payreq-filters-bottom {
		display: flex;
		flex-wrap: wrap;
	}

	.payreq-filters-bottom .nowrap {
		display: flex;
		align-items: center;
		padding-right: 40px;
		margin-bottom: 20px;

		label {
			display: flex;
			align-items: center;
			padding-right: 11px;
			margin-bottom: 0 !important;
			line-height: 1.2;
		}

		select {
			width: 230px;
		}
	}

	.page-main-title {
		height: 115px;
		background-color: #fff;
		text-align: left;

		.inner {
			justify-content: flex-start;
			max-width: 1765px;
		}
	}

	.form-block {
		max-width: 1765px;
		width: 100%;
		margin: 0 auto;
	}

	.payreq-filters {
		padding: 0;
	}

	.payreq-filters-line {
		width: 92%;
		height: 1px;
		background-color: #e6e6e6;
		margin: 30px auto;
	}

	.payreq-filters-top {
		display: flex;
		align-items: center;

		@media (max-width: 1780px) {
			padding: 0 10px;
		}

		.btn {
			width: 235px;
			margin: 0;

			&.pr-btn-manage {
				width: 200px;
				height: 40px;
				line-height: 40px;
				margin-left: 15px;

				&::before {
					content: '';
					display: inline-block;
					width: 24px;
					height: 24px;
					vertical-align: middle;
					margin-top: -2px;
					margin-right: 11px;
					background: url("../img/list-2.svg") no-repeat center center;
				}
			}
		}
	}

	.payreq-filters-bottom {
		align-items: flex-end;
		max-width: 80%;

		@media (max-width: 1399px) {
			justify-content: center;
			max-width: 100%;
		}

		.input-block {
			width: 254px;
			flex-direction: column;
			align-items: flex-start;
			margin-right: 20px;
			margin-bottom: 29px;
			border-radius: 3px;

			@media (max-width: 1780px) {
				padding: 0 10px;
			}

			@media (max-width: 1399px) {
				margin: 0 10px 29px;
				padding: 0;
			}

			input,
			select {
				border-radius: 3px;
			}

			label:not(.checkbox-container) {
				font-size: 17px;
			}

			&--date {
				width: 160px;
				margin-right: 31px;
				position: relative;

				@media (max-width: 1399px) {
					margin-right: 10px;
				}

				&:first-child {
					margin-right: 24px;

					@media (max-width: 1399px) {
						margin-right: 10px;
					}

					&::before {
						content: '-';
						display: inline-block;
						position: absolute;
						bottom: 10px;
						right: -15px;

						@media (max-width: 1399px) {
							right: -13px;
						}

						@media (max-width: 359px) {
							display: none;
						}
					}
				}
			}
		}
	}

	.payreq-total {
		font-size: 24px;
		line-height: 33px;
		margin-bottom: 29px;
		padding: 0 10px;

		@media (max-width: 1399px) {
			text-align: center;
		}
	}

	.payreq-table {
		border: 1px solid #d9d9d9;
		border-bottom: 0;
		display: inline-block;
		flex-direction: column;
		min-width: 100%;
	}

	.payreq-table-wrap {
		margin-left: 0;
		margin-right: 0;
		position: relative;
		overflow-y: visible;
		box-sizing: border-box;
	}

	.payreq-table-col-wrapper {
		display: flex;
		align-items: center;
		min-height: 32px;
		padding-top: 14px;

		@media (max-width: 1399px) {
			padding-top: 0;
			align-items: flex-start;
		}
	}

	.payreq-table-row {
		display: flex;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		color: #2c2c2c;

		@media (max-width: 1399px) {
			flex-direction: column;
			background-color: #fff;
			border-bottom: 1px solid #d9d9d9;
		}

		&:first-child {
			background-color: #ce171f;
		}

		&:nth-child(2n) {
			@media (max-width: 1399px) {
				background-color: #f7f7f7;
			}
		}
	}

	.payreq-table-col {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		min-height: 82px;
		padding: 23px 15px 26px;
		box-sizing: border-box;

		&::before {
			@media (max-width: 1399px) {
				content: attr(aria-label);
				display: inline-block;
				max-width: 100px;
				width: 100%;
				margin-right: 20px;
				color: #999999;
			}
		}

		&.payreq-table-col--top {
			align-items: center !important;
			min-height: 56px;
			padding: 19px 20px;
			font-size: 16px;
			color: #ffffff;

			@media (max-width: 1399px) {
				display: none;
			}
		}

		&:not(.payreq-table-col--top) {
			border-bottom: 1px solid #d9d9d9;
		}

		&:nth-child(2n):not(.payreq-table-col--top) {
			background-color: #f7f7f7;

			@media (max-width: 1399px) {
				background-color: transparent;
			}
		}

		&-1 {
			width: 4.3059%;
			max-width: 4.3059%;
			min-width: 4.3059%;
		}

		&-2 {
			width: 8.9518%;
			max-width: 8.9518%;
			min-width: 8.9518%;
		}

		&-3 {
			width: 5.4957%;
			max-width: 5.4957%;
			min-width: 5.4957%;
		}

		&-4 {
			width: 8.8385%;
			max-width: 8.8385%;
			min-width: 8.8385%;
		}

		&-5 {
			width: 10.0849%;
			max-width: 10.0849%;
			min-width: 10.0849%;
		}

		&-6 {
			width: 7.6487%;
			max-width: 7.6487%;
			min-width: 7.6487%;
		}

		&-7 {
			width: 6.5722%;
			max-width: 6.5722%;
			min-width: 6.5722%;
			text-align: left;

			&:not(.payreq-table-col--top),
			&:not(.payreq-table-col--top) a {
				color: #2C2C2C;
			}
		}

		&-8 {
			width: 4.8725%;
			max-width: 4.8725%;
			min-width: 4.8725%;
		}

		&-9 {
			width: 43.2298%;
			max-width: 43.2298%;
			min-width: 43.2298%;
		}

		@media (max-width: 1399px) {
			width: 100%;
			max-width: 100%;
			min-width: 100%;
			background-color: transparent;
			border-bottom: none;
		}

		> a,
		.payreq-toggler {
			margin-top: 20px;
		}

		> a {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 30px;

			&.payreq-file {
				font-size: 13px;
				color: #ce171f;
				text-decoration: underline;
				margin: 20px 20px 0 0;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: none;
					}
				}
			}

			&.payreq-btn-remove {
				width: 24px;

				svg {
					transition: opacity 0.3s ease;

					@media (min-width: 1024px) {
						&:hover {
							opacity: 0.5;
						}
					}
				}
			}
		}

		.payreq-toggler {
			border-radius: 15px !important;
			box-sizing: border-box !important;
			margin-right: 40px;

			.btn {
				&.btn-payreq-toggler-on {
					text-align: left;
				}

				&.btn-payreq-toggler-off {
					text-align: right;
					color: #999999;
				}
			}

			.toggle-handle.btn.btn-light {
				padding: 0;
				line-height: 2;
				border-radius: 50%;
				width: 22px;
				height: 22px !important;
				border: 1px solid #a5a5a5;
				position: absolute;
				left: 52%;
				top: 50%;
				transform: translateY(-50%);
				transition: all 0.3s ease;
				box-sizing: border-box;

				&.move {
					left: 37%;
					border-color: transparent;
				}
			}
		}

		.input-block {
			@media (max-width: 1399px) {
				margin-bottom: 10px;
			}

			.select-title {
				font-size: 13px;
				line-height: 16px;
				color: #999999;
			}

			select {
				width: 100px;
				height: 30px;
				margin-right: 20px;
				border-radius: 3px;
				font-size: 14px;
				line-height: 13px !important;
				padding: 5px 22px 5px 10px;
				background-position: calc(100% - 8px) center !important;
				text-overflow: ellipsis;
			}

			.info {
				font-size: 13px;
				line-height: 16px;
				margin-top: 5px;
			}
		}

		&--multiple {
			flex-wrap: wrap;

			@media (max-width: 1023px) {
				width: 85%;
				max-width: 85%;
				min-width: 85%;
			}

			@media (max-width: 767px) {
				width: 60%;
				max-width: 60%;
				min-width: 60%;
			}
		}
	}
}