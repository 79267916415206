.vidms-page {
	@media screen and (max-width: 660px) {
		.inner2 {
			max-width: 96%;
		}

		.bottom_block {
			padding-left: 0;
			padding-right: 0;
		}

		.text_block {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.text_block {
		@media (max-width: 1100px) {
			margin-top: 30px;
			max-width: 100%;
		}
	}

	.relative_block.vidms-relative-block {
		margin-top: 28px;
	}

	.vidms-top-code {
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
		color: #666666;
		margin-top: 50px;
	}

	.vidms-title {
		font-size: 26px;
		line-height: 28px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.vidms-chars-with-pics {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;

		@media (max-width: 580px) {
			flex-wrap: wrap;
		}
	}

	.vidms-cwp {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 3px;

		@media (max-width: 580px) {
			width: calc(33.333% - 10px);
			margin-bottom: 15px;
		}

		&__text {
			font-size: 14px;
			line-height: 1.2;
			font-weight: bold;
			color: #2c2c2c;
			text-align: center;
			flex-grow: 1;
		}

		&__pic {
			width: 32px;
			height: 32px;
			margin-top: 10px;

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
				font-family: 'object-fit: contain; object-position: center center;';
			}
		}
	}

	.vidms-buttons-row-after-video {
		display: flex;
		justify-content: space-between;
		margin-top: 30px;

		@media (max-width: 798px) {
			flex-direction: column;
			align-items: center;

			.btn {
				width: 400px;
				max-width: 100%;

				& + .btn {
					margin-top: 10px;
				}
			}

		}
	}

	.vidms--btn-price {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		min-height: 80px;
		margin-bottom: 36px;
		padding: 27px 15px;
		font-size: 30px;
		line-height: 1.3;
		font-weight: 400;
		color: #ffffff;
		box-sizing: border-box;
	}

	.vidms-right-block-title {
		font-size: 18px;
		line-height: 28px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.vidms-right-block-text {
		font-size: 14px;
		line-height: 26px;
		font-weight: 400;
		color: #2c2c2c;
		margin-top: 19px;
	}

	.cr-yt-video-wrap--vidms {
		&.cr-yt-video-wrap::before {
			padding-top: 56.55%;
		}
	}
}