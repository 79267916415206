.manager-contact {
	margin-bottom: 59px;

	.inner {
		max-width: 1280px;
	}

	.profile-block {
		margin-top: 56px;

		>div {
			line-height: 33px;
			color: #2c2c2c;
		}

		.customer {
			font-size: 20px;

			a {
				color: #ce171f;
				font-size: 24px;
			}
		}

		.phone {
			font-size: 16px;

			a {
				font-size: 18px;
				color: #2c2c2c;
				margin-left: 15px;
				font-weight: 600;
			}
		}

		.mail {
			font-size: 16px;

			a {
				font-size: 18px;
				color: #2c2c2c;
				margin-left: 22px;
				font-weight: 600;
			}
		}
	}

	.tabs {
		display: flex;
		flex-wrap: wrap;
		min-width: 290px;
		padding: 0;
		margin: 0 auto;
		margin-top: 29px;

		> section {
			display: none;
			background: #fff;
		}

		> input {
			display: none;
			position: absolute;

			&:checked + label {
				background-color: #CE171F;
				color: #fff;
				border-color: #CE171F;
			}
		}

		> label {
			display: block;
			min-width: 150px;
			margin: 0;
			border: 1px solid #D9D9D9;
			background: transparent;
			font-size: 16px;
			line-height: 38px;
			font-weight: bold;
			text-align: center;
			color: #2c2c2c;
			box-sizing: border-box;

			@media (max-width: 700px) {
				width: 100%;
				margin-bottom: 5px;
			}
		}

		> .line {
			width: 100%;
			height: 1px;
			background-color: #CE171F;

			@media (max-width: 700px) {
				display: none;
			}
		}
	}

	#tab1:checked~#content-tab1,
	#tab2:checked~#content-tab2,
	#tab3:checked~#content-tab3,
	#tab4:checked~#content-tab4 {
		display: flex;
	}

	.control-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		width: 100%;
		padding: 29px 0 20px;

		@media (max-width: 800px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			margin: 0 !important;
		}

		> div {
			margin-right: 30px;

			@media (max-width: 800px) {
				width: 100%;
				margin-right: 0;
				margin-bottom: 10px;
				box-sizing: border-box;
			}

			&:first-child {
				margin-right: auto;

				@media (max-width: 800px) {
					margin-right: 0;
				}
			}

			&:last-child {
				margin-right: 0;
			}
		}

		.new-message-button,
		.new-sms-button {
			padding: 5px 34px 5px 15px;
			background-color: #124390;
			font-size: 16px;
			line-height: 28px;
			color: #fff;
			text-align: center;
			cursor: pointer;

			&::before {
				content: '';
				display: inline-block;
				width: 20px;
				height: 16px;
				margin-right: 15px;
				background-image: url(../img/message-icon-2.png);
				background-size: cover;
				background-repeat: no-repeat;
				vertical-align: middle;
			}
		}

		.search {
			display: flex;
			align-items: center;
			font-size: 18px;

			@media (max-width: 800px) {
				justify-content: space-between;
			}

			select {
				width: 180px;
				padding: 10px 15px 12px 15px;
				margin-left: 13px;
				outline: 0;
				border: 1px solid #a5a5a5;
				border-radius: 0;
				font-size: 16px;
				line-height: 1em;
				background: #fff url(../img/select_bg.svg) no-repeat;
				background-position: calc(100% - 15px) center;
				box-sizing: border-box;
				resize: none;
				appearance: none;
			}
		}

		.filter-button {
			a {
				display: block;
				padding: 0 41px 0 42px;
				background-color: #CE171F;
				font-size: 16px;
				line-height: 40px;
				color: #fff;
				text-align: center;
				cursor: pointer;
			}
		}

		.reset-button {
			@media (max-width: 800px) {
				width: auto;
				text-align: center;
			}

			a {
				display: block;
				font-size: 16px;
				line-height: 28px;
				color: #ce171f;
				position: relative;

				&::before {
					content: '';
					width: 100%;
					height: 1px;
					background-color: #ce171f;
					position: absolute;
					bottom: 2px;
					left: 0;
				}
			}
		}
	}

	.table {
		border: 1px solid #E6E6E6;
		box-sizing: border-box;

		tbody {
			width: 100%;
		}
	}

	.new-message-button {
		transition: 0.3s;

		@include hover {
			filter: brightness(1.2);
		}
	}

	.new-sms-button {
		transition: 0.3s;

		@include hover {
			filter: brightness(1.2);
		}
	}

	#content-tab3 .phone {
		transition: 0.3s;

		@include hover {
			filter: brightness(1.2);
		}
	}

	.send {
		transition: 0.3s;

		@include hover {
			filter: brightness(1.2);
		}
	}

	.filter-button a {
		transition: 0s;

		@include hover {
			background-color: #841215;
		}
	}

	#content-tab1 {
		//   display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.new-message-block {
			width: 100%;

			.receiver {
				max-width: 412px;
				width: 98%;
				margin-top: 20px;

				@media (max-width: 800px) {
					width: 100%;
					max-width: none;
				}
			}

			.subject,
			.text-message {
				margin-top: 10px;
			}

			label,
			input,
			textarea {
				display: block;
				font-size: 16px;
				line-height: 28px;
			}

			input,
			textarea {
				width: 100%;
				padding: 6px 16px;
				border: 1px solid #A5A5A5;
				box-sizing: border-box;
			}

			textarea {
				min-height: 500px;

				@media (max-width: 800px) {
					min-height: 120px;
				}
			}

			.load-block {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: wrap;
				max-width: 430px;
				margin-top: 19px;

				@media (max-width: 800px) {
					flex-wrap: wrap;
					max-width: none;
					width: 100%;
				}

				.file-name {
					color: #4c4c4c;
					margin-right: auto;
				}

				.close {
					width: 16px;
					height: 16px;
					margin-left: 25px;
					margin-bottom: -10px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url(../img/load-close.png);
					cursor: pointer;
				}

				.load {
					width: 100%;
					max-width: 388px;
					height: 3px;
					background-color: #CCCCCC;

					@media (max-width: 800px) {
						max-width: none;
						width: 100%;
						margin-top: 10px;
					}

					span {
						display: block;
						height: 3px;
						position: absolute;
						left: 0;
						top: 0;
					}
				}
			}

			.file-block {
				margin-bottom: 60px;

				@media (max-width: 800px) {
					margin-bottom: 0;
				}

				.attach-button {
					display: block;
					margin-top: 20px;
					margin-bottom: 20px;
					background: transparent;
					color: #ce171f;
					position: relative;

					&::before {
						content: '';
						display: inline-block;
						vertical-align: middle;
						width: 11px;
						height: 22px;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
						background-image: url(../img/attach-icon.png);
						margin-right: 10px;
					}

					&::after {
						content: '';
						display: block;
						position: absolute;
						left: 0;
						bottom: 4px;
						background-color: #CE171F;
						width: calc(100% - 21px);
						height: 1px;
						margin-left: 21px;
					}
				}

				.send {
					max-width: 242px;
					width: 98%;
					background-color: #124390;
					text-align: center;
					color: #fff;
					font-size: 16px;
					line-height: 40px;

					@media (max-width: 800px) {
						width: 100%;
						max-width: none;
					}
				}
			}
		}

		.new-message-block:not(.active) {
			display: none;
		}

		.new-message-button.active {
			visibility: hidden;
		}

		.table {
			width: 100%;
			position: relative;

			@media (max-width: 900px) {
				display: flex;
				flex-wrap: wrap;
			}

			tr {
				font-size: 14px;
				position: relative;
				cursor: pointer;

				@media (max-width: 900px) {
					display: flex;
					flex-direction: column;
					width: 100%;
				}

				td {
					height: 65px;
					padding: 0 10px;
					padding-top: 16px;

					@media (max-width: 900px) {
						height: auto;
					}

					&:first-child {
						@media (max-width: 900px) {
							padding-top: 15px !important;
						}
					}

					&:last-child {
						@media (max-width: 900px) {
							padding-bottom: 15px !important;
						}
					}
				}

				&:first-child {
					min-height: 40px;
					justify-items: center;
					background-color: #CE171F !important;
					font-size: 16px;
					color: #fff;

					@media (max-width: 900px) {
						display: none;
					}

					td,
					th {
						height: 40px;
						padding: 0 10px;
						padding-top: 11px;
						white-space: nowrap;

						&:last-child::after {
							display: none;
						}
					}
				}

				&:nth-child(4n) {
					background-color: #E8F6FF;
				}

				&:nth-child(4n+1) {
					background-color: #E8F6FF;
				}

				.table-item {
					box-sizing: border-box;
					text-align: left;

					@media (max-width: 900px) {
						width: 100% !important;
						padding: 2px 10px !important;
					}

					a {
						color: #2c2c2c;
					}

					&:nth-child(1) {
						padding-left: 22px;
					}

					&:last-child {
						padding-right: 30px;
						position: relative;

						a {
							display: block;
							visibility: hidden;
						}

						&:after {
							content: '';
							display: block;
							width: 13px;
							height: 8px;
							margin-top: 21px;
							position: absolute;
							right: 14px;
							top: -1px;
							background-size: cover;
							background-repeat: no-repeat;
							background-image: url(../img/accordion-arrow.svg);
							transform: rotate(180deg);
							cursor: pointer;
						}
					}

					&.date::before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 5px;
						margin-right: 8px;
						margin-left: -4px;
						vertical-align: middle;
						background-size: cover;
						background-repeat: no-repeat;
						background-image: url(../img/input-plus.png);
					}
				}
			}

			.hide-block {
				position: relative;
				display: none;

				td {
					padding: 16px 10px;
					position: relative;

					.line {
						width: 100%;
						height: 1px;
						background-color: #C5D8E5;
					}

					p {
						line-height: 20px;
						margin-top: 27px;
						padding-right: 16%;

						&:first-child {
							margin-top: 16px;
						}
					}

					a {
						color: #2c2c2c;
						display: inline-block;
						position: relative;

						&.file:after {
							content: '';
							width: 100%;
							position: absolute;
							height: 1px;
							background-color: #2c2c2c;
							left: 0;
							bottom: 0;
						}
					}

					.text {
						display: flex;
						flex-direction: column;
						max-width: 268px;
						padding: 25px 0;
						margin-top: 18px;
						border-top: 1px dashed #5B5F61;
						line-height: 20px;
					}

					.reply {
						display: inline-block;
						font-size: 18px;
						line-height: 28px;
						color: #ce171f;
						position: relative;

						&::after {
							content: '';
							display: block;
							width: 100%;
							height: 1px;
							background-color: #CE171F;
							position: absolute;
							left: 0;
							bottom: 4px;
						}
					}

					.hide-button {
						position: absolute;
						right: 35px;
						bottom: 35px;
						cursor: pointer;

						&::before {
							content: '';
							display: block;
							width: 100%;
							height: 1px;
							background-color: #2c2c2c;
							position: absolute;
							bottom: 0;
							left: 0;
						}

						&::after {
							content: '';
							display: block;
							width: 13px;
							height: 8px;
							position: absolute;
							right: -22px;
							bottom: 5px;
							background-size: cover;
							background-repeat: no-repeat;
							background-image: url(../img/accordion-arrow.svg);
							transform: rotate(0deg);
							cursor: pointer;
						}
					}
				}
			}
		}

		.table-email-gms .table {
			@media (max-width: 1221px) {
				display: flex;
				flex-wrap: wrap;

				tr {
					flex-direction: column;
					width: 100%;
					display: flex;

					&:first-child {
						display: none;
					}

					.table-item {
						width: 100% !important;
						padding: 2px 10px !important;
					}
				}

				.table tr td:first-child {
					padding-top: 15px !important;
				}

				.table tr td:last-child {
					padding-bottom: 15px !important;
				}
			}

			tr {
				.table-item:last-child::after {
					@media (max-width: 1221px) {
						top: -15px;
					}
				}

				&.hide-block td {
					width: 100%;
				}

				td {
					padding-bottom: 8px;

					@media (max-width: 1221px) {
						height: auto;
					}
				}
			}
		}
	}

	.accordion.active + .hide-block {
		display: table-row !important;
	}

	.accordion.active .table-item:last-child::after {
		transform: rotate(0deg) !important;
	}

	.accordion.active .table-item:last-child a {
		visibility: visible !important;
	}

	#content-tab2 {
		//   display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.new-sms .file-block {
			@media (max-width: 800px) {
				margin-bottom: 0;
			}
		}

		.new-sms-block {
			width: 100%;

			.receiver {
				max-width: 412px;
				width: 98%;
				margin-top: 20px;

				@media (max-width: 800px) {
					width: 100%;
					max-width: none;
				}
			}

			.text-message {
				margin-top: 10px;
			}

			span {
				color: #999999;
			}

			label,
			input,
			textarea {
				display: block;
				font-size: 16px;
				line-height: 28px;
			}

			input,
			textarea {
				width: 100%;
				padding: 6px 16px;
				border: 1px solid #A5A5A5;
				box-sizing: border-box;
			}

			textarea {
				min-height: 124px;
			}

			.send {
				max-width: 242px;
				width: 98%;
				margin-top: 25px;
				background-color: #124390;
				font-size: 16px;
				line-height: 40px;
				text-align: center;
				color: #fff;

				@media (max-width: 800px) {
					width: 100%;
					max-width: none;
				}
			}
		}

		.new-sms-button::before {
			width: 19px;
			background-image: url(../img/sms-icon.png);
		}

		.new-sms-block:not(.active) {
			display: none;
		}

		.new-sms-button.active {
			visibility: hidden;
		}

		.table {
			width: 100%;

			@media (max-width: 900px) {
				display: flex;
				flex-wrap: wrap;
			}

			tr {
				font-size: 14px;
				cursor: pointer;
				text-align: center;

				@media (max-width: 900px) {
					display: flex;
					flex-direction: column;
					width: 100%;
					border-bottom: 1px solid #D9D9D9;
				}

				td {
					height: 65px;
					padding: 0 10px;
					padding-top: 16px;

					@media (max-width: 900px) {
						height: auto;
					}

					&:first-child {
						@media (max-width: 900px) {
							padding-top: 15px !important;
						}
					}

					&:last-child {
						@media (max-width: 900px) {
							padding-bottom: 15px !important;
						}
					}
				}

				@include hover {
					background-color: #E8F6FF;
				}

				&:first-child {
					justify-items: center;
					min-height: 40px;
					background-color: #CE171F !important;
					font-size: 16px;
					color: #fff;

					@media (max-width: 900px) {
						display: none;
					}

					td,
					th {
						height: 40px;
						padding: 0 10px;
						padding-top: 11px;
						white-space: nowrap;
					}
				}

				.table-item {
					box-sizing: border-box;
					text-align: left;

					@media (max-width: 900px) {
						width: 100% !important;
						padding: 2px 10px !important;
					}

					&:nth-child(1) {
						padding-left: 22px;
					}

					&:last-child {
						padding-right: 22px
					}

					&.date:before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 5px;
						margin-right: 8px;
						margin-left: -4px;
						background-size: cover;
						background-repeat: no-repeat;
						background-image: url(../img/input-plus.png);
						vertical-align: middle;
					}
				}
			}
		}

		.control-block {
			@media (max-width: 900px) {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin: 0 !important;

				> div {
					width: 100%;
					margin-right: 0;
					margin-bottom: 10px;
					box-sizing: border-box;

					&:first-child {
						margin-right: 0;
					}
				}

				.search {
					justify-content: space-between;
				}

				.reset-button {
					text-align: center;
					width: auto;
				}
			}
		}
	}

	#content-tab3 {
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;

		.phone {
			padding: 5px 34px 5px 15px;
			margin-right: auto;
			background-color: #124390;
			font-size: 16px;
			line-height: 28px;
			color: #fff;
			text-align: center;
			cursor: pointer;
			box-sizing: border-box;

			@media (max-width: 900px) {
				width: 100%;
				margin-bottom: 10px;
			}

			&::before {
				content: '';
				display: inline-block;
				width: 20px;
				height: 20px;
				margin-right: 15px;
				background-image: url(../img/phone-icon-2.png);
				background-size: cover;
				background-repeat: no-repeat;
				vertical-align: middle;
			}
		}

		.table {
			width: 100%;

			@media (max-width: 900px) {
				display: flex;
				flex-wrap: wrap;
			}

			tr {
				font-size: 14px;
				position: relative;
				cursor: pointer;

				@media (max-width: 900px) {
					display: flex;
					flex-direction: column;
					width: 100%;
				}

				td {
					height: 65px;
					padding: 0 10px;
					padding-top: 16px;

					@media (max-width: 900px) {
						height: auto;
					}

					&:first-child {
						@media (max-width: 900px) {
							padding-top: 15px !important;
						}
					}

					&:last-child {
						@media (max-width: 900px) {
							padding-bottom: 15px !important;
						}
					}
				}

				@include hover {
					background-color: #E8F6FF;
				}

				&:first-child {
					justify-items: center;
					min-height: 40px;
					background-color: #CE171F !important;
					font-size: 16px;
					color: #fff;

					@media (max-width: 900px) {
						display: none;
					}

					td,
					th {
						height: 40px;
						padding: 0 10px;
						padding-top: 11px;
						white-space: nowrap;
					}
				}

				.table-item {
					box-sizing: border-box;
					text-align: left;

					@media (max-width: 900px) {
						width: 100% !important;
						padding: 2px 10px !important;
					}

					&:nth-child(1) {
						padding-left: 22px;
					}

					&:last-child {
						padding-right: 22px
					}

					&.date::before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 5px;
						margin-right: 8px;
						margin-left: -4px;
						background-size: cover;
						background-repeat: no-repeat;
						background-image: url(../img/input-plus.png);
						vertical-align: middle;
					}
				}
			}
		}

		.control-block {
			@media (max-width: 900px) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin: 0 !important;

				> div {
					width: 100%;
					margin-right: 0;
					margin-bottom: 10px;
					box-sizing: border-box;

					&:first-child {
						margin-right: 0;
					}
				}

				.search {
					justify-content: space-between;
				}

				.reset-button {
					text-align: center;
					width: auto;
				}
			}
		}
	}

	#content-tab4 {
		width: 100%;

		.chat-block {
			width: 100%;
		}

		.chat {
			padding: 27px 24px 36px 26px;
			margin-top: 30px;
			border: 1px solid #d7d7d7;
			background-color: transparent;

			> .title {
				margin-bottom: 10px;
				padding-bottom: 10px;
				font-size: 18px;
				line-height: 28px;
				font-weight: bold;
			}

			.messages-wrap {
				max-height: 333px;

				.mCSB_scrollTools {
					.mCSB_draggerContainer {
						right: -12px;

					}

					.mCSB_draggerRail {
						width: 2px;
						background-color: #d9d9d9;
						border-radius: 0;
						cursor: pointer;
					}

					.mCSB_dragger_bar {
						width: 4px;
						background-color: #d4141c;
						border-radius: 0;
					}
				}
			}

			.messages {
				display: flex;
				flex-direction: column;

				.messages_message {
					min-width: 1070px;
					width: fit-content;
					padding-left: 50px;
					padding-bottom: 20px;
					font-size: 14px;

					@media (max-width: 1200px) {
						min-width: auto;
					}

					@media (max-width: 700px) {
						padding-left: 15px;
					}

					.title {
						color: #a2a2a2;
						padding-bottom: 10px;

						span {
							font-weight: 600;
						}
					}

					.text {
						padding: 16px 20px 18px 17px;
						background-color: #F7F7F7;
						border: 1px solid #E6E6E6;
					}
				}

				.messages_message-manager {
					align-self: flex-start;

					.title {
						span {
							color: #ce171f;
						}
					}
				}

				.messages_message-your {
					align-self: flex-start;
					min-width: 1070px;
					padding-left: 0;

					.title {
						text-align: left;

						span {
							color: #124390;
						}
					}

					.text {
						background-color: #E4F2FF;
					}
				}
			}

			.write {
				margin-top: 17px;
				border-top: 1px solid #d7d7d7;

				form {
					padding-top: 4px;
					font-size: 14px;
					line-height: 28px;

					textarea {
						width: 100%;
						height: 88px;
						padding: 16px 17px;
						border: 1px solid #a5a5a5;
						background-color: #ffffff;
						resize: none;
						overflow-y: auto;

						&::placeholder {
							color: #a6a6a6;
						}

						&::-webkit-scrollbar {
							width: 0;
							/* Remove scrollbar space */
							background: transparent;
							/* Optional: just make scrollbar invisible */
							display: none;
						}

						/* Optional: show position indicator in red */
						&::-webkit-scrollbar-thumb {
							background: #FF0000;
						}
					}

					.send-btn {
						width: 161px;
						height: 35px;
						background-color: #124390;
						font-family: "Vauxhall Neue", sans-serif;
						font-size: 16px;
						line-height: 28px;
						font-weight: bold;
						color: #ffffff;
						transition-duration: 0.2s;
						margin-top: 15px;

						@include hover {
							filter: brightness(1.2);
						}
					}
				}
			}
		}
	}

	/* global-manager-contacts */
	.gmc-email {
		display: flex;
	}

	.sidebar-email-gms {
		width: 241px;

		@media (max-width: 750px) {
			width: 100%;
		}

		&__types {
			margin-top: 30px;
		}

		&__type {
			width: 100%;
			height: 35px;
			border: 1px solid #d9d9d9;
			color: #2c2c2c;
			font-size: 16px;
			line-height: 33px;
			font-weight: 400;
			text-align: center;
			transition-duration: 0.2s;
			box-sizing: border-box;
			cursor: pointer;

			&:not(:last-child) {
				margin-bottom: 22px;
			}

			&.active {
				background-color: #ce171f;
				border: 1px solid #ce171f;
				color: #ffffff;
				pointer-events: none;
			}

			@include hover {
				background-color: #ce171f;
				border: 1px solid #ce171f;
				color: #ffffff;
			}
		}


		&__filters {
			margin-top: 30px;

			.title {
				font-size: 18px;
				line-height: 28px;
				font-weight: bold;
				color: #2c2c2c;
			}

			label {
				display: flex;
				align-items: center;
				font-size: 16px;
				line-height: 28px;
				font-weight: 400;
				color: #2c2c2c;

				input {
					display: none;

					+ span {
						display: flex;
						align-items: center;
						position: relative;

						&::before {
							content: '';
							display: block;
							width: 20px;
							height: 20px;
							border: 1px solid #a5a5a5;
							background-color: #ffffff;
							border-radius: 50%;
							margin-right: 10px;
						}

						&::after {
							content: '';
							display: block;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							background-color: #000;
							position: absolute;
							left: 6px;
							z-index: 2;
							opacity: 0;
							transition-duration: 0.2s;
						}
					}

					&:checked + span::after {
						opacity: 1;
					}
				}
			}
		}

		&__input-box {
			margin-top: 10px;

			.title {
				font-size: 14px;
				line-height: 28px;
				font-weight: 400;
				color: #2c2c2c;
			}

			> input {
				display: block;
				width: 100%;
				height: 30px;
				padding: 0 13px 0 18px;
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				box-sizing: border-box;
			}

			.bottom {
				display: flex;

				.undeliter {
					width: 22px;
					font-size: 14px;
					line-height: 28px;
					font-weight: 400;
					text-align: center;
					color: #adadad;
				}

				input {
					width: calc(50% - 11px);
					height: 30px;
					padding: 0 13px 0 18px;
					border: 1px solid #a5a5a5;
					background-color: #ffffff;
					font-size: 14px;
					line-height: 28px;
					font-weight: 400;

					&::placeholder {
						color: #adadad;
					}
				}
			}
		}

		&__apply {
			display: block;
			width: 100%;
			height: 40px;
			margin-top: 44px;
			background-color: #ce171f;
			font-size: 16px;
			line-height: 40px;
			text-align: center;
			font-weight: bold;
			color: #ffffff;
			transition-duration: 0.2s;

			@include hover {
				opacity: 0.8;
			}
		}

		&__reset {
			display: block;
			width: 100%;
			margin-top: 10px;
			font-size: 16px;
			line-height: 28px;
			font-weight: 400;
			color: #ce171f;
			text-decoration: underline;
			text-align: center;
			transition-duration: 0.2s;

			@include hover {
				opacity: 0.8;
			}
		}
	}

	.search-line {
		display: flex;
		margin-top: 30px;
		margin-bottom: 20px;

		&__search {
			width: calc(100% - 15px - 112px);
			height: 40px;
			margin-right: 14px;
			padding: 0 15px;
			border: 1px solid #a5a5a5;
			background-color: #ffffff;
			font-size: 16px;
			line-height: 28px;
			font-weight: 400;
			box-sizing: border-box;

			&::placeholder {
				color: #a6a6a6;
			}
		}

		&__search-btn {
			width: 112px;
			height: 40px;
			background-color: #ce171f;
			font-size: 16px;
			line-height: 40px;
			font-weight: bold;
			color: #ffffff;
			text-align: center;
			transition-duration: 0.2s;
			cursor: pointer;

			@include hover {
				opacity: 0.8;
			}

			&::before {
				content: '';
				display: inline-block;
				width: 18px;
				height: 18px;
				background: url('../img/white-lupa.png') no-repeat center center;
				background-size: contain;
				position: relative;
				top: 3px;
				margin-right: 9px;
			}
		}
	}

	.content-email-gms {
		width: calc(100% - 284px);
		margin-left: 42px;

		@media (max-width: 750px) {
			width: 100%;
			margin-left: 0;
		}
	}

	.table-email-gms {
		max-width: 100%;

		.tov-el {
			max-width: 244px;

			@media (max-width: 1337px) {
				max-width: 182px;
			}

			@media (max-width: 1271px) {
				max-width: 140px;
			}
		}
	}

	.is-new {
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		padding-left: 15px;
		padding-top: 10px;
		padding-bottom: 10px;
		box-sizing: border-box;

		&::before {
			content: '';
			display: block;
			width: 10px;
			height: 10px;
			background-color: #ce171f;
			border-radius: 50%;
		}
	}
}
