.exclusive-2 {
	padding: 0;
	position: relative;
	max-width: 802px;
	width: 100%;
	overflow: hidden;

	img {
		width: 100%;
		object-fit: cover;
		margin-bottom: -4px;
	}

	.btn,
	&__note,
	&__bottom-text {
		position: absolute;
	}

	.btn,
	&__bottom-text {
		left: 50%;
		transform: translateX(-50%);
	}

	&__note {
		text-align: right;
		font-size: 19px;
		line-height: 27.26px;
		font-weight: 700;
		top: 195px;
		right: 47px;

		@media (max-width: 767px) {
			top: 25vw;
			right: 6vw;
			font-size: 3vw;
			line-height: 3.2vw;
		}
	}

	.btn {
		width: 266px;
		border: 1px solid #ffffff;
		font-size: 18px;
		text-transform: uppercase;
		bottom: 64px;
		cursor: pointer;

		@media (max-width: 767px) {
			width: 46vw;
			font-size: 4vw;
			bottom: 9vw;
			height: 8vw;
			line-height: 7.9vw;
		}
	}

	&__bottom-text {
		font-size: 14px;
		bottom: 32px;

		@media (max-width: 767px) {
			font-size: 3vw;
			bottom: 3vw;
		}
	}

	.fancybox-close-small {
		width: 48px;
		height: 48px;
		top: 8px;
		right: 4px;
		color: #ce181f;
		opacity: 1;
		transition: opacity 0.3s ease;

		@media (max-width: 600px) {
			width: 12vw;
			height: 12vw;
			top: -3vw;
			right: -3vw;
		}

		@media (min-width: 1024px) {
			@include hover {
				opacity: 0.7;
			}
		}
	}
}