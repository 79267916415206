.toggle {
	position: relative;
	overflow: hidden;

	.btn,
	&.btn {
		display: inline-block;
		width: auto;
		height: auto;
		padding: 0 .75rem;
		background-color: transparent;
		border: 1px solid transparent;
		border-radius: .25rem;
		font-size: 1rem;
		line-height: 2.3;
		font-weight: 400;
		color: #212529;
		text-align: center;
		transition: color 0.15s ease-in-out,
			background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out,
			box-shadow 0.15s ease-in-out;
		vertical-align: middle;
		user-select: none;

		@include hover {
			color: #212529;
			text-decoration: none;
		}
	}

	.btn-success,
	&.btn-success {
		color: #fff;
		background-color: #28a745;
		border-color: #28a745;

		@include hover {
			color: #fff;
			background-color: #218838;
			border-color: #1e7e34;
		}
	}

	.btn-danger,
	&.btn-danger {
		color: #fff;
		background-color: #dc3545;
		border-color: #dc3545;

		@include hover {
			color: #fff;
			background-color: #c82333;
			border-color: #bd2130;
		}
	}

	.btn-light,
	&.btn-light {
		color: #212529;
		background-color: #f8f9fa;
		border-color: #f8f9fa;

		@include hover {
			color: #212529;
			background-color: #e2e6ea;
			border-color: #dae0e5;
		}
	}

	.btn-group-xs > .btn,
	.btn-xs {
		padding: 0.35rem 0.4rem 0.25rem 0.4rem;
		font-size: 0.875rem;
		line-height: 0.5;
		border-radius: 0.2rem;
	}

	.checkbox label .toggle,
	.checkbox-inline .toggle {
		margin-left: -1.25rem;
		margin-right: 0.35rem;
	}

	&.btn.btn-light,
	&.btn.btn-outline-light {
		border-color: rgba(0, 0, 0, .15);
	}

	input[type="checkbox"] {
		display: none;
	}

	.toggle-group {
		width: 200%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		transition: left 0.35s;
		-moz-user-select: none;
		-webkit-user-select: none;

		label,
		span {
			cursor: pointer;
		}
	}

	&.off .toggle-group {
		left: -100%;
	}

	.toggle-on {
		margin: 0;
		border: 0;
		border-radius: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 50%;
	}

	.toggle-off {
		margin: 0;
		border: 0;
		border-radius: 0;
		box-shadow: none;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		right: 0;
	}

	.toggle-handle {
		width: 0;
		height: 100% !important;
		margin: 0 auto;
		padding-top: 0;
		padding-bottom: 0;
		border-width: 0 1px;
		background-color: #fff;
		position: relative;
	}

	&.btn-outline-primary .toggle-handle {
		background-color: #007bff;
		border-color: #007bff;
	}

	&.btn-outline-secondary .toggle-handle {
		background-color: #6c757d;
		border-color: #6c757d;
	}

	&.btn-outline-success .toggle-handle {
		background-color: #28a745;
		border-color: #28a745;
	}

	&.btn-outline-danger .toggle-handle {
		background-color: #dc3545;
		border-color: #dc3545;
	}

	&.btn-outline-warning .toggle-handle {
		background-color: #ffc107;
		border-color: #ffc107;
	}

	&.btn-outline-info .toggle-handle {
		background-color: #17a2b8;
		border-color: #17a2b8;
	}

	&.btn-outline-light .toggle-handle {
		background-color: #f8f9fa;
		border-color: #f8f9fa;
	}

	&.btn-outline-dark .toggle-handle {
		background-color: #343a40;
		border-color: #343a40;
	}

	&[class*="btn-outline"] {
		@include hover {
			.toggle-handle {
				background-color: #f8f9fa;
				opacity: 0.5;
			}
		}
	}

	&.btn { min-width: 3.7rem; min-height: 2.15rem; }
	.toggle-on.btn { padding-right: 1.5rem; }
	.toggle-off.btn { padding-left: 1.5rem; }

	&.btn-lg { min-width: 5rem; min-height: 2.815rem; }
	.toggle-on.btn-lg { padding-right: 2rem; }
	.toggle-off.btn-lg { padding-left: 2rem; }
	.toggle-handle.btn-lg { width: 2.5rem; }

	&.btn-sm { min-width: 3.125rem; min-height: 1.938rem; }
	.toggle-on.btn-sm { padding-right: 1rem; }
	.toggle-off.btn-sm { padding-left: 1rem; }

	&.btn-xs { min-width: 2.19rem; min-height: 1.375rem; }
	.toggle-on.btn-xs { padding-right: .8rem; }
	.toggle-off.btn-xs { padding-left: .8rem; }
}
