.car-info__slider-control {
	position: absolute;
	z-index: 99;
	top: 520px;
	left: 10px;
	display: flex;
}

.overflow-hidden {
	overflow: hidden;
}

.opened-menu-slide {
	transition: all 500ms;
}

.overflow-hidden .opened-menu-slide {
	overflow: hidden;
}

.overflow-hidden #wrapper {
	overflow: hidden;
}

.fz15 {
	font-size: 15px !important;
}

.red {
	color: #ce181e;
}

.btn-red {
	cursor: pointer;
	user-select: none;
	color: #ffffff;
	background-color: #ce181f;
	font-size: 14px;

	&:hover {
		background-color: #841215;
	}
}

.checkbox-container {
	position: relative;
	display: block;
	width: 20px;
	min-width: 20px;
	height: 20px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.checkbox-container input {
	position: absolute;
	width: 0;
	height: 0;
	cursor: pointer;
	opacity: 0;
}

.checkbox-mark {
	position: absolute;
	top: 0;
	left: 0;
	width: 18px;
	height: 18px;
	border: 1px solid #a9a9a9;
	background-color: #ffffff;
}

.checkbox-container:hover input ~ .checkbox-mark {
	background-color: #d7d7d7;
}

.checkbox-container input:checked ~ .checkbox-mark {
}

.checkbox-mark:after {
	position: absolute;
	display: none;
	content: "";
}

.checkbox-container input:checked ~ .checkbox-mark:after {
	display: block;
}

.checkbox-container .checkbox-mark:after {
	top: 0px;
	left: 5px;
	width: 5px;
	height: 10px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	border: solid #ce181e;
	border-width: 0 3px 3px 0;
}

.hide {
	display: none;
}

.images-upload-block {
	.label {
		color: #2c2c2c;
		font-size: 13px;

		strong {
			margin-right: 10px;
		}
	}

	.grid-block {
		display: flex;
		flex-wrap: wrap;
		margin-top: 12px;
		margin-left: -20px;

		.item {
			box-sizing: border-box;
			height: 130px;
			margin-bottom: 20px;
			margin-left: 20px;
			margin-top: 0;
			border: 1px solid #026fa8;
			background-color: #fff;
			border-radius: 5px;
			text-align: center;
			transition: background-color 0.3s ease;
			@include flex-block(140px, 20px, 0, true, 4);

			@include hover {
				background-color: rgba(2, 111, 168, 0.08);
			}

			label > span {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: space-between;
				box-sizing: border-box;
				height: 100%;
				padding: 10px 2px 7px;
				cursor: pointer;
			}

			.title {
				font-size: 13px;
				color: #2c2c2c;
			}

			.status {
				font-size: 12px;
				font-weight: 400;
			}

			&.uploaded {
				background-color: #026fa8;
				border-color: #ce171f;

				.title {
					color: #ffffff;
				}

				.status {
					padding-right: 25px;
					color: #ffffff;
					background: url("../img/sell-img-check.png") right center no-repeat;
				}

				.img-block {
					opacity: 0.21;
				}
			}

			.remove-link {
				display: none;
				margin-top: 9px;
				cursor: pointer;
				text-align: center;
				color: #6e7777;
				font-size: 12px;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	.or-checkbox {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 16px;

		.checkbox-container {
			margin: 0 18px;
		}

		.skip-text {
			color: #757575;
		}
	}
}

.form-page {
	box-sizing: border-box;
	margin-bottom: 40px;
	padding: 35px 0;

	.form-title {
		margin-bottom: 30px;
		color: #2c2c2c;
		font-weight: 700;
		font-size: 18px;
	}

	&.form-book-appointment {
		.form-block {
			.row-1 {
				@include flex-row(30px, 20px);

				.input-block {
					@include flex-block(250px, 30px, 20px, true, 3);
				}
			}

			.row-3 {
				.flex-block {
					@include flex-row(30px, 20px);

					.input-block {
						@include flex-block(250px, 30px, 20px, true, 3);
					}
				}
			}

			.border-row {
				display: flex;
				flex-wrap: nowrap;
				margin-top: 40px;
				padding-top: 22px;
				padding-bottom: 22px;
				border-top: 1px solid #cccccc;
				border-bottom: 1px solid #cccccc;

				@media (max-width: 768px) {
					display: block;
				}

				.input-block {
					flex-shrink: 0;
					width: 300px;
					margin-right: 40px;
					padding-top: 25px;

					@media (max-width: 768px) {
						padding-top: 0;
						width: auto;
						padding-bottom: 20px;
						margin-right: 0;
					}

					.ah1 {
						font-weight: bold;
						font-size: 24px;
						line-height: 27px;
					}

					label:not(.radio-label) {
						margin-top: 25px;
					}

					.row {
						display: flex;
						flex-wrap: nowrap;
						margin-top: 0;
						margin-bottom: 0;

						input {
							width: 100%;
						}

						.btn--red {
							width: 88px;
							margin-left: 11px;
							transition: .3s background;
							background: #ce171f;
							font-size: 16px;
							line-height: 44px;

							@include hover {
								background: #58585a;
							}
						}
					}
				}

				.form-map {
					width: 100%;
					height: 370px;
					border: 1px solid #cccccc;
					box-sizing: border-box;

					iframe {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
	}
}

#chat-select {
	position: relative;
	display: none;
	box-sizing: border-box;
	width: 566px;
	padding: 35px 35px 50px;
	background: #ffffff;

	.close-modal {
		position: absolute;
		top: 10px;
		left: 10px;
		display: block;
		cursor: pointer;

		&:hover {
			svg {
				path {
					fill: #841215;
				}
			}
		}
	}

	.go-back {
		position: absolute;
		top: 9px;
		right: 10px;
		display: none;
		cursor: pointer;
		user-select: none;
		color: #9d9d9d;
		font-weight: 400;
		font-size: 12px;
		line-height: 1;

		&:hover {
			color: #2c2c2c;
		}
	}

	.btn-red {
		width: 249px;
		height: 46px;
		margin: 0 auto;
		cursor: pointer;
		user-select: none;
		-webkit-transition: .3s;
		-o-transition: .3s;
		transition: .3s;
		text-align: center;
		color: #ffffff;
		background-color: #ce181f;
		font-size: 14px;
		line-height: 46px;

		&:hover {
			background-color: #841215;
		}
	}

	.big-title {
		margin-bottom: 25px;
		text-align: center;
		color: #58595b;
		font-weight: 400;
		font-size: 28px;
	}

	.small-title {
		max-width: 355px;
		margin-right: auto;
		margin-bottom: 30px;
		margin-left: auto;
		text-align: center;
		color: #2c2c2c;
		font-size: 18px;
		line-height: 28px;
	}

	input[type=radio] {
		width: 21px;
		height: 21px;
		margin: 0 0 0 12px;
		cursor: pointer;
		vertical-align: middle;
		border: 1px solid #2c2c2c;
		border-radius: 25px;
		outline: none !important;
		-webkit-appearance: none;
		-moz-appearance: none;

		&:checked {
			background: #FFF url(../img/chat/radio.svg) no-repeat center center;
		}
	}

	.select-row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 356px;
		margin: 10px auto 0;

		label {
			display: flex;
			align-items: center;
			margin-bottom: 45px;
			cursor: pointer;

			img {
				display: block;
				margin-left: 17px;
			}
		}
	}

	.step {
		display: none;

		&.active {
			display: block;
		}
	}

	.step-2 {
		.mode-row {
			display: flex;
			justify-content: space-between;
			width: 181px;
			margin: 0 auto;

			.mode {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				box-sizing: border-box;
				width: 56px;
				height: 56px;
				text-align: center;
				border: 1px solid #2c2c2c;
				border-radius: 100%;
				background-color: #2c2c2c;

				.image-block {
					display: block;
					width: 23px;
					height: 23px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}

				.label {
					display: block;
					margin-top: 5px;
					color: #fffefe;
					font-size: 11px;
					line-height: 1;
				}

				&.mode-1 {
					.image-block {
						background-image: url("../img/chat/chat-1.png");
					}

					&:hover {
						.image-block {
							background-image: url("../img/chat/chat-2.png");
						}
					}
				}

				&.mode-2 {
					.image-block {
						background-image: url("../img/chat/text-1.png");
					}

					&:hover {
						.image-block {
							background-image: url("../img/chat/text-2.png");
						}
					}
				}

				&:hover {
					background-color: #ffffff;

					.label {
						color: #2c2c2c;
					}
				}
			}
		}
	}

	.step-3 {
		.mode-row {
			display: flex;
			align-items: center;
			flex-direction: column;

			.branch {
				display: flex;
				align-items: center;
				width: 100%;
				max-width: 415px;

				+ .branch {
					margin-top: 18px;
				}

				.text {
					width: calc(100% - 162px);
					margin-right: 20px;
					text-align: right;
					color: #2c2c2c;
					font-size: 16px;
				}

				.modes {
					display: flex;
					justify-content: space-between;
					width: 142px;
					min-width: 142px;
				}
			}

			.mode {
				display: flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
				width: 65px;
				height: 25px;
				text-align: center;
				border: 1px solid #2c2c2c;
				border-radius: 13px;
				background-color: #2c2c2c;

				.image-block {
					display: block;
					width: 19px;
					height: 16px;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
				}

				.label {
					display: block;
					margin-left: 6px;
					color: #fffefe;
					font-size: 10px;
					line-height: 1;
				}

				&.mode-1 {
					.image-block {
						background-image: url("../img/chat/chat-1.png");
					}

					&:hover {
						.image-block {
							width: 15px;
							background-image: url("../img/chat/chat-2.png");
						}
					}
				}

				&.mode-2 {
					.image-block {
						background-image: url("../img/chat/text-1.png");
					}

					&:hover {
						.image-block {
							background-image: url("../img/chat/text-2.png");
						}
					}
				}

				&:hover {
					background-color: #ffffff;

					.label {
						color: #2c2c2c;
					}
				}
			}
		}
	}
}

.table-wrap {
	overflow-x: auto;
	width: 100%;
}

table.variants-table {
	min-width: 100%;
	border-collapse: collapse;

	tr {
		th {
			padding: 7px 5px;
			text-align: left;
			white-space: nowrap;
			font-weight: bold;
			font-size: 13px;
		}

		td {
			padding: 7px 5px;
			vertical-align: middle;
			white-space: nowrap;
			font-size: 14px;

			&:first-child {
				padding-left: 10px;
			}

			&:nth-child(n+4):not(:last-child) {
				text-align: center;
			}
		}

		&:nth-child(even) {
			td {
				background-color: #f2f2f2;
			}
		}
	}
}

.models-select-list {
	.radio-btns {
		display: flex;
		flex-wrap: wrap;

		.radio-btn {
			flex: 1 0 220px;
			margin-top: 0 !important;
			margin-bottom: 20px;
		}
	}

	.input-block {
		input[type=radio] {
			position: relative;
			top: -2px;
			width: 20px;
			height: 20px;
			background: #FFF;

			&:checked {
				background: #FFF url(../img/radio-b.svg) no-repeat center center;

				&:not(:hover) {
					background: #FFF url(../img/radio-g.svg) no-repeat center center;
				}
			}
		}
	}
}

.modal-block {
	display: none;

	.input-block {
		> label {
			margin-bottom: 40px !important;
			font-weight: bold;
			font-size: 20px !important;
		}
	}

	&.variant-select-list {
		.form-block {

			.input-block {
				> label {
					width: 100%;
					text-align: center !important;
				}

				input[type=radio] {
					width: 20px;
					height: 20px;
					background: #FFF;

					&:checked {
						background: #FFF url(../img/radio-b.svg) no-repeat center center;

						&:not(:hover) {
							background: #FFF url(../img/radio-g.svg) no-repeat center center;
						}
					}
				}
			}
		}
	}
}

#login {
	display: none;

	form {
		width: 290px;
		max-width: 100%;
	}

	.input-block {
		+ .input-block {
			margin-top: 10px;
		}
	}
}

.preloader {
	position: fixed;
	z-index: 99999;
	top: 0;
	left: 0;
	display: none;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.3);

	.preloader-load {
		position: absolute;
		top: 50%;
		left: 50%;
		display: none;
		box-sizing: border-box;
		width: 100px;
		height: 100px;
		margin: 0 auto;
		margin-top: -50px;
		margin-left: -50px;
		text-align: center;

		> span {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			box-sizing: border-box;
			width: 100%;
			height: 100%;
			transform: translate(-50%, -50%);
			animation: roll 2s infinite linear;
			border: 2px solid #ed1c24;
			border-left-color: transparent !important;
			border-radius: 50%;

			&::before,
			&::after {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				box-sizing: border-box;
				content: "";
				transform: translate(-50%, -50%);
				border: 2px solid #ed1c24;
				border-radius: 50%;
			}

			&::before {
				width: 75%;
				height: 75%;
				border-right-color: transparent !important;
			}

			&::after {
				width: 50%;
				height: 50%;
				border-bottom-color: transparent !important;
			}
		}
	}
}

@-webkit-keyframes roll {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes roll {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.blue-buttons_long {
	&--big {
		display: block;
		margin-top: 10px;

		@media (max-width: 900px) {
			max-width: 450px;
			margin: 30px auto 0;
		}

		.btn-finance-explained {
			display: block;
			width: 225px;
			height: 45px;
			box-sizing: border-box;
			line-height: 34px;
			margin-left: auto;
		}
	}
}

.check-holyday {
	margin: 15px 0 0 10px;

	.lbl-chb {
		display: inline-flex;
		align-items: center;
		margin-bottom: 0;

		span {
			font-size: 15px !important;
			line-height: 28px;
			font-weight: 400 !important;
		}

		.tip {
			line-height: 12px;
		}
	}
}

.finance__block {
	.btn-finance-explained {
		padding: 6px 11px;
		color: #fff;
		background: #000;
		font-size: 14px;

		@media (max-width: 580px) {
			max-width: 236px;
			height: 35px;
		}
	}

	.row {
		display: flex;
		justify-content: space-between;

		p {
			margin-top: 10px;
		}

		.credit-small-text {
			margin-top: 10px;
			padding-left: 19px;
			opacity: 0.7;
			font-size: smaller;
			line-height: 10px;
			font-style: italic;
		}

		ul {
			margin: 10px 0 0 19px;
			padding: 0;
			list-style-type: disc;
		}

		.blk {
			width: 48%;
		}
	}
}

.disabled-pointer {
	pointer-events: none;
	opacity: .5;

	span::before {
		background-color: #a7a7a7;
	}
}

.modal-reg-login.modal-info {
	width: 100%;
	max-width: 800px;
	padding: 41px 48px 41px 48px;

	form {
		width: unset;
	}

	.information {
		margin-bottom: 20px;
		font-size: 20px;
		line-height: 26px;
	}

	&-two-btn {

		.buttons-row {
			display: flex;
			justify-content: space-between;

			button {
				max-width: 45%;
			}
		}
	}
}

@media screen and (max-width: 560px) {
	.modal-reg-login.modal-info {
		padding: 19px 15px 18px 15px;

		.information {
			font-size: 15px;
			line-height: 19px;
		}
	}
}

.modal-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 220px;
	height: 46px;
	padding: 10px 15px;
	transition-duration: 0.2s;
	color: #fff;
	background-color: #124390;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;

	&:hover {
		filter: brightness(1.2);
	}

	&.modal-btn__fw {
		width: 100%;
	}

}

.modal-buttons-row-2 {
	display: flex;
	justify-content: center;

	.modal-btn {
		max-width: calc(50% - 10px);

		&:first-child {
			margin-right: 20px;
		}
	}
}

.mt10 {
	margin-top: 10px;
}

.mt15 {
	margin-top: 15px;
}

.mt30 {
	margin-top: 30px;
}

.mt45 {
	margin-top: 45px;
}

span.ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-e,
span.ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-s {
	display: none;
}

.ui-state-active {
	border-color: #ddd;
}

.ui-accordion .ui-accordion-content {
	overflow: auto;
	/* padding: 1em 2.2em; */
	border-top: 0;
}

.ui-slider-horizontal {
	height: 0.8em;

	&.ui-corner-all,
	&.ui-corner-bottom,
	&.ui-corner-right,
	&.ui-corner-br {
		border-bottom-right-radius: 3px;
	}

	&.ui-corner-all,
	&.ui-corner-bottom,
	&.ui-corner-left,
	&.ui-corner-bl {
		border-bottom-left-radius: 3px;
	}

	&.ui-corner-all,
	&.ui-corner-top,
	&.ui-corner-right,
	&.ui-corner-tr {
		border-top-right-radius: 3px;
	}

	&.ui-corner-all,
	&.ui-corner-top,
	&.ui-corner-left,
	&.ui-corner-tl {
		border-top-left-radius: 3px;
	}

	&.ui-widget-content {
		color: #333333;
		background: #ffffff;
	}

	&.ui-widget {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 1em;
	}

	&.ui-slider {
		position: relative;
		text-align: left;
	}

	.ui-slider-range-min {
		left: 0;
	}

	.ui-slider-range {
		top: 0;
		height: 100%;
		border-radius: 3px;
	}

	.ui-slider-range {
		position: absolute;
		z-index: 1;
		display: block;
		border: 0;
		background-position: 0 0;
		font-size: .7em;
	}

	.ui-corner-all,
	.ui-corner-bottom,
	.ui-corner-right,
	.ui-corner-br {
		border-bottom-right-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-bottom,
	.ui-corner-left,
	.ui-corner-bl {
		border-bottom-left-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-top,
	.ui-corner-right,
	.ui-corner-tr {
		border-top-right-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-top,
	.ui-corner-left,
	.ui-corner-tl {
		border-top-left-radius: 3px;
	}

	.ui-widget-header {
		color: #333333;
		border: 1px solid #dddddd;
		background: #e9e9e9;
		font-weight: bold;
	}

	/////
	.ui-widget-content .ui-state-default {
		color: #454545;
		border: 1px solid #c5c5c5;
		background: #f6f6f6;
		font-weight: normal;
	}

	&.ui-slider .ui-slider-handle {
		position: absolute;
		z-index: 2;
		width: 1.2em;
		height: 1.2em;
		cursor: default;
		-ms-touch-action: none;
		touch-action: none;
	}

	&.ui-slider-horizontal .ui-slider-handle {
		top: -.3em;
		margin-left: -.6em;
	}

	.ui-corner-all,
	.ui-corner-bottom,
	.ui-corner-right,
	.ui-corner-br {
		border-bottom-right-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-bottom,
	.ui-corner-left,
	.ui-corner-bl {
		border-bottom-left-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-top,
	.ui-corner-right,
	.ui-corner-tr {
		border-top-right-radius: 3px;
	}

	.ui-corner-all,
	.ui-corner-top,
	.ui-corner-left,
	.ui-corner-tl {
		border-top-left-radius: 3px;
	}
}

.modal-fancy {
	overflow-wrap: break-word;
}

.dn {
	display: none;
}

.custom_radios {
	input[type=radio]:checked {
		background: #fff url(../img/radio.svg) no-repeat center center;
	}

	input[type=radio] {
		width: 15px;
		height: 15px;
		margin: 0;
		margin-right: 8px;
		cursor: pointer;
		vertical-align: middle;
		border: 1px solid #a5a5a5;
		border-radius: 25px;
		outline: 0 !important;
		-webkit-appearance: none;
		-moz-appearance: none;
	}
}

.dn {
	display: none;
}

.hb1-2:hover {
	cursor: pointer;
	transition-duration: .2s;
	filter: brightness(1.2);
}

.pagi-type-wl {
	width: fit-content;
	max-width: 90%;
	margin: 30px auto 100px;

	.mwrap {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -10px;
	}

	/*Пагинация как на странице warranty-log применять вместе с классом form-block*/
	.show {
		display: flex;
		align-items: center;
		margin: 0 10px;
		margin-bottom: 20px;
		color: #2c2c2c;
		font-family: "Vauxhall Neue";
		font-weight: 400;
		font-size: 14px;
		line-height: 28px;

		.input-block {
			select {
				width: 68px;
				height: 35px;
				margin-left: 10px;
				padding: 0 15px 0 10px;
				color: #2c2c2c;
				background: #fff url(../img/select_hov.svg) no-repeat;
				background-position: calc(100% - 15px) center;
				font-family: "Vauxhall Neue";
				font-weight: 400;
				font-size: 14px;
				line-height: 28px;
			}
		}

	}

	.pages {
		display: flex;
		margin: 0 10px;
		border: 1px solid #d7d7d7;

		a {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 35px;
			height: 34px;
			transition-duration: .2s;
			color: #2c2c2c;

			&:not(:last-child) {
				border-right: 1px solid #d7d7d7;
			}

			&.current {
				pointer-events: none;
				color: #fff;
				background-color: #ce171f;
			}

			&:hover {
				color: #fff;
				background-color: #ce171f;
			}
		}

		a.ttt {
			pointer-events: none;
		}
	}
}

.tov-el {
	display: inline-block;
	overflow: hidden;
	width: 300px;
	max-width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.nowrap {
	white-space: nowrap;
}

.flex-row {
	display: flex;
}

.aic {
	align-items: center;
}

.all__page--err_block {
	margin-bottom: 40px;
	text-align: center;
	font-size: 18px;
	line-height: 24px;

	.inn_err_block {
		position: relative;
		padding: 30px 45px 35px;
		border: 2px solid #e6e6e6;
	}

	.closebtn {
		position: absolute;
		top: 8px;
		right: 10px;
		width: 22px;
		cursor: pointer;

		&:hover {
			transition-duration: .2s;
			filter: brightness(.5);
		}
	}

	.ah1 {
		margin-bottom: 13px;
		font-weight: 700;
		font-size: 30px;
		line-height: 33px;
	}

	p {
		max-width: 600px;
		margin-right: auto;
		margin-bottom: 0;
		margin-bottom: 13px;
		margin-left: auto;
	}

	.tel {
		display: inline-block;
		padding-bottom: 6px;
		vertical-align: top;
		color: #2c2c2c;
		font-weight: 700;
		font-size: 22px;
		line-height: 25px;

		&:before {
			display: inline-block;
			width: 19px;
			height: 18px;
			margin-top: -4px;
			margin-right: 8px;
			content: '';
			vertical-align: middle;
			background: url(../img/err.svg) no-repeat;
			background-size: auto 100%;
		}
	}
}

.deal-log-page {
	.deal-manager {
		.container {
			max-width: 1700px;
		}
	}
}

.checkbox-customized {
	/*input + label*/
	display: none;

	+ label,
	+ span {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		cursor: pointer;
		border: 1px solid #a9a9a9;
		background-color: #ffffff;

		&::before {
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 20px;
			height: 20px;
			content: '';
			transition: opacity .1s;
			transform: translate(-50%, -50%);
			opacity: 0;
			background: url('../img/checked.svg') no-repeat center center;
			background-size: contain;
		}
	}

	&:checked + label,
	&:checked + span {
		&::before {
			opacity: 1;
		}
	}
}

.checkbox-customizedv2-wrap {
	cursor: pointer;
	display: flex;

}

.checkbox-customizedv2 {
	/*input.checkbox-customizedv2 + span*/
	display: none;

	+ span {
		cursor: pointer;
		position: relative;
		padding-left: 30px;
		display: block;
		color: #2c2c2c;
		font-family: "Vauxhall Neue";
		font-size: 15px;
		font-weight: 400;
		line-height: 30px;

		&::after {
			position: absolute;
			top: 3px;
			left: -1px;
			display: block;
			width: 20px;
			width: 20px;
			height: 20px;
			height: 20px;
			content: '';
			cursor: pointer;
			transition: opacity .1s;
			border: 1px solid #a9a9a9;
			background-color: #ffffff;
			background-size: contain;
		}

		&::before {
			position: absolute;
			z-index: 1;
			top: 4px;
			left: 0;
			display: block;
			width: 20px;
			width: 20px;
			height: 20px;
			height: 20px;
			content: '';
			cursor: pointer;
			transition: opacity .1s;
			opacity: 0;
			background: url('../img/checked.svg') no-repeat center center;
			background-size: contain;
		}
	}

	&:checked + span {
		&::before {
			opacity: 1;
		}
	}
}

.redu {
	text-decoration: underline;
	color: #f01019;
}

.input-block--h-35 {

	label {
		margin-bottom: 0 !important;
		color: #2c2c2c;
		font-weight: 400;
		font-size: 14px;
		line-height: 28px;
	}

	input,
	select {
		height: 35px;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		font-size: 15px;
		line-height: 1;
	}
}

.radios-and-hint-right {
	display: block;

	@media (max-width: 900px) {
		display: none;
	}

	&--mobile {
		display: none;
		width: 100%;
		max-width: 500px;

		@media (max-width: 900px) {
			display: block;
		}
	}
}

.radiogroup-v2 {
	display: flex;
	flex-wrap: wrap;
	margin: -7.5px;

	&.radiogroup-v2--fg-lbl {
		.radiogroup-v2__lbl {
			flex-grow: 1;

			span {
				width: 100%;
			}
		}
	}

	&__lbl {
		margin: 7.5px;

		input {
			display: none;

			&:checked + span {
				height: 100%;
				pointer-events: none;
				color: #ffffff;
				border: 0;
				background-color: #ce181f;

				&::before {
					border: 5px solid #ffffff;
					background-color: #ce181f;
				}
			}
		}

		span {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			box-sizing: border-box;
			min-height: 44px;
			padding: 12px 25px 12px 12px;
			cursor: pointer;
			text-transform: uppercase;
			text-transform: uppercase;
			color: #2c2c2c;
			border: 1px solid #a5a5a5;
			background-color: #ffffff;
			font-weight: 400;
			font-size: 16px;
			line-height: 1.3;

			&:hover {
				background-color: rgb(255, 236, 236);
			}

			&::before {
				display: block;
				box-sizing: border-box;
				width: 20px;
				height: 20px;
				margin-right: 12px;
				content: '';
				border: 1px solid #a7a7a7;
				border-radius: 50%;
			}
		}
	}

	&--car {
		margin-top: 0;

		@media (max-width: 580px) {
			margin-top: 7px;
		}

		.radiogroup-v2 {
			&__lbl {
				width: calc(33.3333% - 15px);

				@media (max-width: 580px) {
					width: calc(50% - 15px);
				}

				&:nth-child(n+3) {
					@media (max-width: 580px) {
						width: 100%;
					}
				}

				span {
					width: 100%;
					padding: 11px;
					padding-left: 38px;
					position: relative;

					&::before {
						position: absolute;
						left: 11px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}

.radiogroup--info-column {
	flex-direction: column;

	@media (max-width: 987px) {
		width: 100%;
		margin: 20px -5px 0;
	}

	.radiogroup {
		&__tabs,
		&__content {
			width: 100% !important;
			box-sizing: border-box;
		}

		&__tabs {
			display: flex;
			flex-wrap: wrap;
			margin: 0 !important;
		}

		&__radio-wrapper {
			width: 100%;
			flex-direction: row !important;
			margin: 0 !important;
		}

		&__radio {
			width: calc(50% - 10px) !important;
			margin: 0 5px 14px !important;
		}
	}

	.check-holyday {
		margin-top: 0;
		margin-bottom: 14px;
	}
}

.custom-slider-v2-container {
}

.c-sli-v2-top {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 0 -7.5px;

	> * {
		margin: 0 7.5px;
	}
}

.c-sli-v2-current-value {
	flex-grow: 1;

	input {
		width: 180px;
		height: 35px;
		margin-bottom: 0 !important;
		color: #ce171f;
		border: 1px solid #a5a5a5;
		background-color: #ffffff;
		font-weight: 400;
		font-size: 18px;
		line-height: 1 !important;
	}
}

.c-sli-v2-top__left-desc {
	flex-grow: 99999;
	color: #a6a6a6;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;
}

.c-sli-v2-current-residual-max {
	flex-grow: 1;
	min-width: 100px;
	text-align: right;
	color: #124390;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
}

.c-sli-slider-v2 {
	width: 100%;
	height: 4px;
	margin-top: 20px;
	margin-bottom: 14px;
	border-radius: 2px;
	background-color: #b6c9e6 !important;

	.ui-slider-range {
		height: 4px;
		border: 0;
		border-radius: 2px;
		background-color: #c44b51;
	}

	.ui-slider-handle {
		top: -8px !important;
		width: 20px !important;
		height: 20px !important;
		cursor: pointer !important;
		border-radius: 50%;
		background-color: #ffffff;
		box-shadow: 0 1px 6px rgba(18, 67, 144, 0.6);

		&::before {
			position: absolute;
			top: 6px;
			left: 6px;
			display: block;
			width: 8px;
			height: 8px;
			content: '';
			border-radius: 50%;
			background-color: #124390;
		}
	}
}

.c-sli-v2-bottom {
	display: flex;
	justify-content: space-between;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.3;

	.left {
		margin-right: 10px;
		color: #ce171f;
	}

	.right {
		color: #124390;
	}
}

@media screen and (max-width: 1100px) {
	.build-your-deal {
		.c-sli-slider-v2 {
			width: calc(100% - 25px);
			margin-right: auto;
			margin-left: auto;
		}
	}
}

////////////////////////////////////////////////
.datepicker-input-u {
	box-sizing: border-box;
	padding: 13px 15px;
	padding-right: 41px !important;
	background: #fff url(../img/datepicker.png) no-repeat;
	background-position: calc(100% - 9px) center;
	background-size: auto 80%;
}

.system-error {
	display: none;
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 18px;
	padding: 15px 17px;
	border: 1px solid #ce181f;
	background-color: #fff5f5;

	.label {
		color: #d1282e;
		font-weight: bold;
		font-size: 16px;
	}

	.text {
		margin-top: 3px;
		color: #d1282e;
		font-weight: 400;
		font-size: 14px;
	}

	&.show {
		display: block;
	}

	&.system-error-green {
		border: 1px solid green;
		background-color: #e3fde3;

		.label {
			color: green;
		}

		.text {
			color: green;
		}
	}

	&.system-error-yellow {
		border: 1px solid #e9af00;
		background-color: #fefee7;

		.label {
			color: #e9af00;
		}

		.text {
			color: #e9af00;
		}
	}
}

// Sync slider
#sync1,
#sync3 {
	border-bottom: 1px solid #e1e1e1;
	padding-bottom: 10px;

	&:not(.owl-loaded){
		display: flex;
		flex-wrap: nowrap;
		opacity: 0;
	}
}

#sync2,
#sync4 {
	&:not(.owl-loaded){
		display: flex;
		flex-wrap: nowrap;
		opacity: 0;
	}

	.owl-nav {
		display: none;
	}
}

#sync2,
#sync4 {
	.car-info__slide {
		margin-top: 20px;
		height: 96px;
	}
}

.tabs__content {
	&.js-active > .car-info__slider {
		margin-top: 0;
		margin-left: 0;
		height: auto;
		opacity: 1;
		visibility: visible;
		position: relative;
	}
}

.tooltipster-od {
	.tooltipster-content {
		padding: 15px;
	}

	.tooltipster-box {
		box-shadow: none;
		background: none;
		border: none;
	}
}

// Video block
.usp2-video-play-wrap {
	position: relative;
}

.usp-2video-play {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center center;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		background: url(../img/play.svg) no-repeat center center rgba(0, 0, 0, .5);
		background-size: 44px auto;
	}
}

// Required symbol star
.fa-asterisk::before {
	content: "\f069";
}

// Custom datepicker ui
.ui-datepicker {
	width: 20.6em;
	padding: 0;

	.ui-datepicker-header {
		padding: 0.5em 0;
		border: none;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
		background-color: #f6f6f6;
	}

	.ui-datepicker-next,
	.ui-datepicker-prev {
		width: 1.5em;
		height: 1.5em;
		top: 9px;
		border-radius: 5px;
		background: #ce171f url(../img/controls/arrow.svg) no-repeat center;
		transition: background-color 0.3s ease;

		span {
			display: none;
		}
	}

	.ui-datepicker-title {
		line-height: 1.8em;
	}

	.ui-datepicker-prev {
		left: 14px;
		transform: rotate(180deg);
	}

	.ui-datepicker-next {
		right: 14px;
	}

	select.ui-datepicker-month,
	select.ui-datepicker-year {
		width: 39%;
		height: 19px;
		border: 1px solid #a5a5a5;
		border-radius: 3px;
		appearance: none;
		background-color: #fff;
		background-image: url(../img/select_hov.svg);
		background-repeat: no-repeat;
		background-position: calc(100% - 3px) center;
		font-family: 'Vauxhall Neue', sans-serif;
		font-size: 13px;
		background-size: 9px;
		padding: 0 5px;

		&::-ms-expand {
			display: none;
		}
	}

	.select.ui-datepicker-month {
		margin-right: 2px;
	}

	select.ui-datepicker-year {
		margin-left: 2px;
	}

	table {
		width: calc(100% - 1.4em);
		margin: 0 0.7em 0.9em;
	}

	th {
		padding: 1em 0.3em;
		font-size: 13px;
		font-weight: 400;
		color: #ce171f;
	}

	td {
		border-radius: 3px;
		transition: background-color 0.3s ease;

		span,
		a {
			padding: 0.5em;
			color: #2c2c2c;
		}

		@media (min-width: 1024px) {
			&:hover {
				background-color: #f1f1f1;
			}
		}

		&:hover {
			background-color: #f1f1f1;
		}

		&.ui-datepicker-today {
			background-color: #f1f1f1;

			a {
				color: #2c2c2c;
			}
		}
	}
}

.ui-widget.ui-widget-content {
	border-radius: 5px;

	.ui-state-hover {
		border: none;
		background-color: #981316;
	}

	.ui-datepicker-prev-hover {
		top: 9px;
		left: 14px;
	}

	.ui-state-default {
		border: none;
		background: none;
		text-align: center;
	}

	.ui-state-active {
		border: none;
		background: none;
		color: #454545;
	}
}
