.body-shop-diary {
	.bsd-table {
		display: flex;
		align-items: flex-start;
		width: 1620px;
		max-width: calc(100% - 40px);
		margin: 0 auto;
		margin-bottom: 30px;

		&--bodyshop-diary {
			.bsd-table-right-wrap {
				max-width: calc(100% - 113px);

				@media (max-width: 600px) {
					max-width: calc(100% - 100px);
				}
			}

			.bsd-table-cell--left {
				width: 113px;

				@media (max-width: 600px) {
					width: 100px;
				}
			}
		}

		&--courtsecy-cars-booking {
			.bsd-table-right-wrap {
				max-width: calc(100% - 402px);

				@media (max-width: 600px) {
					max-width: calc(100% - 100px);
				}
			}

			.bsd-table-cell--left {
				@media (max-width: 600px) {
					width: 100px;
				}
			}

			.bsd-table-left {
				flex-grow: 1;
				text-align: left;

				@media (max-width: 600px) {
					overflow: hidden;
					overflow-x: scroll;
				}

				.bsd-table-cell--left {
					justify-content: flex-start;
				}

				.bsd-table-col {
					&:nth-child(1) {
						width: 100%;
					}

					&:nth-child(1) .bsd-table-cell--left {
						width: 100%;
					}

					&:nth-child(2) .bsd-table-cell--left {
						width: 100px;
					}


					&:nth-child(3) .bsd-table-cell--left {
						width: 66px;
					}

					&:nth-child(4) .bsd-table-cell--left {
						width: 101px;
					}

					&:nth-child(2n) {
						background-color: #f7f7f7;
					}
				}
			}
		}
	}

	.bsd-table-left {
		display: flex;
	}

	.bsd-table-right {
		display: flex;

		.bsd-table-col:last-child .bsd-table-cell:first-child {
			border-right: 1px solid #ce171f;
		}
	}

	.bsd-table-cell {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 51px;
		min-width: 51px;
		height: 51px;
		padding: 5px;
		border: 1px solid #d9d9d9;
		border-top: 0;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.3;
		color: #2c2c2c;
		text-align: center;
		box-sizing: border-box;

		&--yellow {
			background-color: #fff5c5;
		}

		&--orange {
			background-color: #ffbd7d;
		}

		&--red {
			background-color: #ff4c4c;
		}

		&--left {
			&:first-child {
				background-color: #ce171f;
				border-left: 1px solid #ce171f;
				color: #ffffff;
			}
		}

		&--clickable {
			cursor: pointer;
			transition-duration: .2s;

			@include hover {
				opacity: 0.7;
			}
		}

		&:first-child {
			border-top: 1px solid #ce171f;
			border-bottom: 1px solid #ce171f;
		}
	}

	.ccb-btns-row {
		display: flex;
		flex-wrap: wrap;
		width: 1620px;
		max-width: calc(100% - 40px);
		margin: 50px auto 10px;

		.btn {
			width: auto;
			padding-left: 12px;
			padding-right: 18px;
			margin-bottom: 20px;
			margin-right: 20px;
			cursor: pointer;
			user-select: none;

			@media (max-width: 450px) {
				width: 100%;
				box-sizing: border-box;
			}

			img {
				margin-right: 8px;
				margin-top: 2px;
			}
		}
	}

	.datepickers-container {
		z-index: 9999999 !important;
	}

	#js-delete-user {
		.btns-row {
			img {
				vertical-align: middle;
				margin-right: 10px;
			}

			.btn{
				width: 179px;
				margin: 0 6px;
			}
		}

	}

	.mCS-my-theme1.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		height: 14px;
		border-radius: 2px;
		border: 1px solid #cccccc;
		background-color: #ffffff;
	}

	.mCS-my-theme1.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
		top: -2px;
	}

	.mCS-my-theme1.mCSB_scrollTools .mCSB_draggerRail {
		height: 9px;
		border-radius: 0;
		background-color: #cccccc;
	}

	.mCS-my-theme1.mCSB_scrollTools.mCSB_scrollTools_horizontal {
		bottom: 31px;
	}

	.mCS-my-theme1.mCSB_scrollTools {
		opacity: 1;
	}
}
