.about-detail {
	&__title {
		font-size: 20px;
		margin-bottom: 36px;

		@media (max-width: 767px) {
			font-size: 18px;
			line-height: 22px;
			margin-bottom: 24px;
		}
	}

	&__text {
		font-size: 15px;
		line-height: 24px;
		color: #4d4d4d;
		margin-bottom: 18px;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 17px;
		}

		p + p {
			margin-top: 24px;

			@media (max-width: 767px) {
				margin-top: 15px;
			}
		}
	}

	&__link {
		display: inline-flex;
		align-items: center;
		font-size: 16px;
		color: #ce181f;
		text-decoration: underline;

		@media (min-width: 1024px) {
			&:hover {
				text-decoration: none;

				svg {
					transform: translateX(3px);
				}
			}
		}

		svg {
			margin-left: 5px;
			fill: #ce181f;
			transition: transform 0.3s ease;
		}
	}
}