.body-shop-calculation {
	.table {
		.row {
			display: flex;
			padding: 12px 14px 12px 6px;

			&:nth-child(odd) {
				background-color: #f2f2f2;
			}
		}
	}

	.flex-block {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 1158px;
		margin: 0 auto;
		margin-top: 50px;

		@media (max-width: 1200px) {
			flex-direction: column;
			justify-content: flex-start;
			width: 100%;
			max-width: calc(100% - 30px);
			margin: 0 auto;
			margin-top: 33px;
		}

		> .left {
			width: 300px;

			@media (max-width: 1200px) {
				width: 100%;
			}

			.button-block {
				margin-top: 22px;

				.button--red {
					display: block;
					width: 100%;
					height: 35px;
					margin-bottom: 20px;
					font-size: 15px;
					line-height: 35px;
					font-weight: 400;
					text-align: center;
				}

				.button--kma-blue {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 35px;
					background-color: #124390;
					border-radius: 5px;
					font-size: 15px;
					line-height: 35px;
					font-weight: 400;
					text-align: center;
					text-transform: none;

					&:before {
						content: '';
						display: block;
						width: 17px;
						height: 15px;
						margin-right: 3px;
						background-image: url("../img/customer.svg");
						background-size: contain;
					}

					@include hover {
						background-color: #58585a;
					}
				}
			}

			> .title {
				margin-bottom: 4px;
				font-size: 18px;
				line-height: 33px;
				font-weight: bold;
				letter-spacing: 0.3px;
			}

			.block {
				margin-bottom: 19px;
				border: 1px solid #E6E6E6;

				> .title {
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
				}

				.table-vehicle {
					margin-top: 1px;

					.row {
						justify-content: space-between;

						.left {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.type {
								font-family: "Vauxhall Neue", sans-serif;
								font-size: 13px;
								line-height: 10px;
								font-weight: 400;
								color: #757575;
							}

							.name {
								line-height: 18px;
								text-decoration: underline;
							}
						}

						.right {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;
							height: 32px;

							@media (max-width: 1200px) {
								justify-content: flex-start;
							}

							.price {
								font-family: "Vauxhall Neue", sans-serif;
								font-size: 16px;
								font-weight: bold;
								color: #2c2c2c;
							}
						}
					}
				}

				.table-trade {
					.row {
						.left {
							width: 100%;
						}

						.parameter {
							margin-bottom: 0;
							font-size: 13px;
							line-height: 16px;
							font-weight: 400;
							color: #757575;
						}

						.value {
							font-size: 16px;
							line-height: 17px;

							span:nth-child(1) {
								margin-right: 8px;
							}

							&.description {
								font-size: 14px;
								line-height: 24px;
							}

							&.active {
								color: #ce171f;
								text-decoration: underline;
							}

							.images-block {
								display: flex;
								flex-wrap: wrap;
								justify-content: space-between;
								margin-top: 6px;

								> div {
									width: 49%;
									height: 102px;
									background-repeat: no-repeat;
									background-position: center;
									background-size: contain;
									margin-bottom: 8px;

									img {
										width: 100%;
										height: 100%;
										object-fit: contain;
									}
								}
							}
						}

						&.summary {
							.left {
								width: 100%;
							}
						}
					}
				}

				.table-other {
					.left {
						flex-grow: 1;
					}
				}
			}
		}

		> .right {
			width: calc(100% - 344px);
			padding-right: 12px;
			margin-left: 15px;
			box-sizing: border-box;

			@media (max-width: 1200px) {
				width: 100%;
				margin-left: 0;
			}

			@media (max-width: 760px) {
				padding-right: 0;
			}

			.section {
				margin-bottom: 23px;
				padding-bottom: 20px;

				@media (max-width: 550px) {
					.table-si {
						overflow-x: auto;

						.row {
							width: fit-content;

							.col {
								&:not(:last-child) {
									margin-right: 0;
									padding-right: 10px;

								}

								&:nth-child(1) {
									min-width: 150px;
								}

								&:nth-child(2) {
									min-width: 100px;
								}

								&:nth-child(3) {
									min-width: 100px;
								}

								&:nth-child(4) {
									min-width: 100px;
								}

								&:nth-child(5) {
									min-width: 100px;
								}
							}
						}
					}
				}

				&:nth-child(1) {
					padding-bottom: 0;

					@media (max-width: 810px) {
						overflow: auto;
					}
				}

				&:nth-child(2) {
					margin-bottom: 30px;
					padding-bottom: 30px;
				}

				&:nth-child(3) {
					margin-bottom: 34px;
					padding-bottom: 30px;
				}

				&:not(:last-child) {
					border-bottom: 1px solid #e6e6e6;
				}

				.table-list-el-module {
					width: 99%;
					min-width: 768px;
					margin-bottom: 5px;

					@media (max-width: 810px) {
						overflow-x: scroll;
					}

					tr {
						@media (max-width: 810px) {
							display: table-row;
						}

						&:nth-child(even) {
							background: #f2f2f2;
						}

						&.add-new-row {
							span {
								font-size: 16px;
							}
						}

						th {
							padding: 13px 8px;
							padding-top: 2px;
							font-size: 16px;
							text-align: left;
							letter-spacing: 0.5px;

							&:nth-child(3) {
								padding-left: 20px;
							}
						}

						td {
							height: 64px;
							padding: 16px 11px;
							vertical-align: middle;
							box-sizing: border-box;

							@media (max-width: 810px) {
								width: auto !important;
								padding: 16px 11px !important;
							}

							&:nth-child(1) {
								width: 213px;
							}

							&:nth-child(2) {
								width: 133px;
								padding: 0;

								@media (max-width: 810px) {
									display: table-cell !important;
									padding: 16px 11px !important;
								}
							}

							&:nth-child(3) {
								width: 70px;
								padding-left: 20px;
							}

							&:nth-child(4) {
								padding: 0;
								padding-left: 10px;
							}

							&:nth-child(5) {
								width: 70px;
							}

							&:nth-child(6) {
								padding-left: 7px;
							}

							&:nth-child(7) {
								padding-right: 0;
								padding-left: 20px;

								@media (max-width: 810px) {
									padding-right: 5px;
									padding-left: 5px;
								}
							}

							a.button--red {
								display: block;
								width: 133px;
								height: 30px;
								font-size: 13px;
								line-height: 30px;
								font-weight: 400;
								text-align: center;
							}

							a.button--kma-blue {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 156px;
								height: 30px;
								background-color: #124390;
								border-radius: 5px;
								font-size: 13px;
								line-height: 30px;
								font-weight: 400;
								text-align: center;
								text-transform: none;
								box-sizing: border-box;

								&:before {
									content: '';
									display: block;
									width: 12px;
									height: 12px;
									margin-right: 12px;
									background: url(../img/white-plus.png) no-repeat center center;
									background-size: contain;
								}

								@include hover {
									background-color: #58585a;
								}
							}

							select {
								width: 62px;
								padding: 8px 15px 7px 12px;
								border: 1px solid #a5a5a5;
								border-radius: 3px;
								outline: 0;
								background: #fff url(../img/select_hov.svg) no-repeat;
								background-position: calc(100% - 15px) center;
								font-size: 14px;
								line-height: 1em;
								box-sizing: border-box;
								appearance: none;
								resize: none;

								&::-ms-expand {
									display: none;
								}
							}

							input {
								border-radius: 3px;

								&.detail {
									width: 200px;
									padding: 6px 11px;
									margin-bottom: 0;
									border: 1px solid #a5a5a5;
									outline: 0;
									font-size: 14px;
									line-height: 1em;
									appearance: none;
									box-sizing: border-box;
								}

								&.hours {
									width: 60px;
									padding: 6px 10px;
									margin-bottom: 0;
									border: 1px solid #a5a5a5;
									outline: 0;
									font-size: 14px;
									line-height: 1em;
									appearance: none;
									box-sizing: border-box;
								}

								&.price {
									width: 70px;
									padding: 6px 9px;
									margin-bottom: 0;
									border: 1px solid #a5a5a5;
									outline: 0;
									font-size: 14px;
									line-height: 1em;
									appearance: none;
									box-sizing: border-box;
								}
							}

							span.bold {
								font-weight: bold;
							}

							.close-button {
								display: block;
								width: 13px;
								height: 13px;
								background: url(../img/red_x.svg) no-repeat;
								background-size: 100% auto;
							}
						}
					}
				}

				> .title {
					font-size: 20px;
					line-height: 33px;
					font-weight: 400;
					letter-spacing: 1px;
				}

				.offered-price {
					label {
						font-size: 16px;
					}

					.flex-wrap {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-top: 15px;

						@media (max-width: 550px) {
							flex-wrap: wrap;
						}

						input {
							width: 362px;
							height: 44px;
							padding-left: 19px;
							border: 1px solid #a5a5a5;
							background-color: #ffffff;
							font-size: 16px;

							@media (max-width: 550px) {
								width: 100%;
								padding-left: 10px;
							}
						}

						span {
							margin-left: 29px;

							@media (max-width: 550px) {
								margin-top: 10px;
							}

							b {
								font-size: 18px;
							}
						}
					}

					.price {
						margin-top: 28px;
						font-size: 18px;

						.red {
							margin-left: 11px;
							font-size: 24px;
							font-weight: bold;
							color: #ce171f;
						}
					}
				}

				.table-added {
					margin-top: 15px;

					.row {
						input[type=checkbox] {
							display: none;
						}

						input[type=checkbox] {
							+ label::before {
								content: '';
								display: inline-block;
								width: 20px;
								height: 20px;
								margin: -5px 5px 0 0;
								border: 1px solid #a9a9a9;
								background-color: #ffffff;
								line-height: 22px;
								text-align: center;
								vertical-align: middle;
								transition: color 0.3s ease;
							}

							&:checked + label::before {
								background: url(../img/checked.svg) center;
							}

							&[disabled] + label {
								&::before {
									border: 1px solid #d9d9d9;
								}

								+ span {
									margin-right: 10px;

									&,
									+ span + span {
										color: #999999;
									}
								}
							}
						}

						label {
							cursor: pointer;

							+span {
								flex-grow: 1;
							}
						}
					}
				}

				.modal-openers {
					display: flex;
					width: 348px;
					margin-top: 14px;
					margin-bottom: 25px;

					@media (max-width: 550px) {
						display: block;
						width: 100%;
					}

					.btn-pcp {
						margin-right: 19px;

						@media (max-width: 550px) {
							margin-right: 0;
							margin-bottom: 10px;
							margin-left: 0;
						}
					}

					.btn-pcp,
					.btn-hp {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 165px;
						height: 44px;
						border: 1px solid #ce181f;
						font-size: 16px;
						font-weight: bold;
						color: #2c2c2c;
						box-sizing: border-box;

						@include hover {
							color: #ffffff;
							border: 0;
							background-color: #ce181f;
						}

						@media (max-width: 550px) {
							width: 100%;
						}
					}
				}

				.table-summary {
					margin-bottom: 31px;
					padding-bottom: 0;
					border-bottom: 1px solid #f2f2f2;

					&.tab-bg-odd .row {
						&:nth-child(even) {
							background-color: unset;
						}

						&:nth-child(odd) {
							background-color: #f2f2f2;
						}
					}

					.row {
						align-items: center;

						.col {
							&:not(:last-child) {
								margin-right: 10px;
							}

							&:nth-child(1) {
								width: calc(55.692% - 10px);

							}

							&:nth-child(2) {
								width: calc(24.005% - 10px);
							}

							&:nth-child(3) {
								width: calc(20.301%);
							}
						}
					}
				}

				.input-block {
					label {
						display: block;
						margin-bottom: 16px;
						font-size: 16px;
					}

					textarea {
						width: 100%;
						height: 100px;
						padding: 12px 15px;
						border: 1px solid #a5a5a5;
						border-radius: 3px;
						outline: 0;
						font-size: 16px;
						line-height: 1em;
						box-sizing: border-box;
					}
				}

				&.btns-section{
					display: flex;
					flex-wrap: wrap;
					padding-bottom: 200px;
				}

				.save-button {
					display: block;
					width: 160px;
					height: 35px;
					margin-right: 30px;
					font-size: 13px;
					line-height: 35px;

					&:before {
						content: '';
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-top: -3px;
						margin-right: 10px;
						vertical-align: middle;
						background: url(../img/save.svg) no-repeat;
					}
				}

				.back-button {
					display: block;
					width: 100px;
					height: 35px;
					background-color: #ffffff;
					border: 2px solid #000;
					font-size: 13px;
					line-height: 35px;
					color: #000;
					box-sizing: border-box;

					@include hover {
						color: #fff;
						background: black;
					}
				}

				.wip-block {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					margin-bottom: 21px;

					span {
						font-size: 16px;
						font-weight: bold;
					}

					a {
						display: block;
						width: 133px;
						height: 30px;
						margin-left: 30px;
						font-size: 13px;
						line-height: 30px;
						font-weight: 400;
						text-align: center;
					}
				}

				.Due-block {
					display: flex;
					flex-wrap: wrap;

					@media (max-width: 760px) {
						flex-direction: column;
					}

					.block {
						label {
							display: block;
							margin-bottom: 3px;
							font-size: 15px;
						}

						> input {
							width: 194px;
							padding: 6px 11px;
							margin-bottom: 0;
							border: 1px solid #a5a5a5;
							border-radius: 3px;
							outline: 0;
							font-size: 14px;
							line-height: 1em;
							box-sizing: border-box;

							@media (max-width: 760px) {
								width: 100%;
							}
						}

						select {
							width: 194px;
							margin-bottom: 0;
							padding: 7px 11px;
							border: 1px solid #a5a5a5;
							border-radius: 3px;
							outline: 0;
							background: #fff url(../img/select_hov.svg) no-repeat;
							background-position: calc(100% - 15px) center;
							font-size: 14px;
							line-height: 1em;
							box-sizing: border-box;
							resize: none;
							appearance: none;

							@media (max-width: 760px) {
								width: 100%;
							}

							&::-ms-expand {
								display: none;
							}
						}
					}

					> * + * {
						margin-left: 8px;

						@media (max-width: 760px) {
							margin-top: 15px;
							margin-left: 0;
						}
					}
				}

				.status-block {
					label {
						display: block;
						margin-bottom: 3px;
						font-size: 15px;
					}

					select {
						width: 194px;
						padding: 7px 11px;
						margin-bottom: 0;
						border: 1px solid #a5a5a5;
						border-radius: 0;
						outline: 0;
						background: #fff url(../img/select_hov.svg) no-repeat;
						background-position: calc(100% - 15px) center;
						font-size: 14px;
						line-height: 1em;
						box-sizing: border-box;
						resize: none;
						appearance: none;
					}
				}

				.radiogroup--right .radiogroup__radio span {
					@media (max-width: 550px) {
						width: 100%;
					}
				}
			}

			.btn-block {
				display: flex;
				width: fit-content;
				padding-top: 20px;
				margin: 0 auto;

				.cbtn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 267px;
					height: 46px;
					background-color: #124390;
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
					color: #ffffff;
					transition-duration: 0.2s;

					@include hover {
						filter: brightness(1.1);
					}

					&:nth-child(1) {
						margin-right: 24px;
					}
				}
			}
		}
	}

	.table-si {
		.row {
			display: flex;
			padding: 12px 14px 12px 9px;

			&:nth-child(even) {
				background-color: #f2f2f2;
			}

			.col {
				font-size: 16px;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&:nth-child(1) {
					width: calc(33.821% - 10px);
					font-size: 18px;
					font-weight: bold;
				}

				&:nth-child(2) {
					width: calc(18.242% - 10px);
				}

				&:nth-child(3) {
					width: calc(18.375% - 10px);
				}

				&:nth-child(4) {
					width: calc(15.446% - 10px);
				}

				&:nth-child(5) {
					width: calc(14.114%);
				}
			}
		}

		.row.headline {
			.col {
				font-weight: 400;
			}
		}

		&.table-programm {
			@media (max-width: 760px) {
				overflow-x: scroll;
			}

			.row {
				align-items: center;
				min-height: 65px -12px * 2;

				@media (max-width: 760px) {
					width: fit-content;
				}

				.col {
					&:not(:last-child) {
						margin-right: 10px;
					}

					&:nth-child(1) {
						width: 43px;
						font-size: 18px;
						font-weight: bold;

						@media (max-width: 760px) {
							width: 30px;
							min-width: 30px;
						}
					}

					&:nth-child(2) {
						width: calc(11.675% - 10px - 14.333px);

						@media (max-width: 760px) {
							width: 50px;
							min-width: 50px;
						}
					}

					&:nth-child(3) {
						width: calc(47.664% - 10px - 14.333px);

						@media (max-width: 760px) {
							width: 150px;
							min-width: 150px;
						}
					}

					&:nth-child(4) {
						width: calc(35.439% - 10px - 14.333px);

						@media (max-width: 760px) {
							width: 150px;
							min-width: 150px;
						}
					}

					input {
						display: none;

						+ label {
							display: inline-block;
							width: 20px;
							height: 20px;
							border: 1px solid #a5a5a5;
							border-radius: 50%;
							background-color: #ffffff;
							cursor: pointer;
							position: relative;

							&::before {
								content: '';
								width: 10px;
								height: 10px;
								opacity: 0;
								border-radius: 50%;
								background-color: #124390;
								position: absolute;
								top: 4px;
								left: 4px;
								transition-duration: 0.2s;
							}
						}

						&:checked {
							+ label {
								&::before {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}
}
