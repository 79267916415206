.header {
	width: 100%;
	height: 80px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 4001;
	background-color: #fff;
	border: 1px solid #e6e6e6;

	@media (max-width: 1200px) {
		height: 60px;
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding-right: 26px;
		box-sizing: border-box;
		position: relative;
		z-index: 2;

	}

	&__group {
		display: flex;
		align-items: center;
	}

	&__logo {
		width: 186px;
		height: 45px;
		margin-left: 19px;
		margin-right: 40px;

		@media (max-width: 1200px) {
			width: 152px;
			height: 37px;
			margin-right: 12px;
			margin-left: 10px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__logo-last {
		width: 80px;
		height: 65px;
		margin-left: 26px;

		@media (max-width: 1200px) {
			width: 57px;
			height: 46px;
			margin-left: 10px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	&__burger {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		position: relative;
		cursor: pointer;
		transition-duration: 0.4s;
		border: 1px solid #e6e6e6;
		border-bottom: none;
		background-color: #fff;
		box-sizing: border-box;
		transition-duration: 0.4s;

		@media (max-width: 1200px) {
			width: 60px;
			height: 60px;
		}

		&.open {
			background-color: #f2f2f2;

			.header__burger-wrapper {
				.icon-left {
					transition-duration: 0.4s;
					background: transparent;

					&::before {
						transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px);
					}

					&::after {
						transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px);
					}
				}

				.icon-right {
					transition-duration: 0.4s;
					background: transparent;

					&::before {
						transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px);
					}

					&::after {
						transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px);
					}
				}
			}
		}
	}

	&__burger-wrapper {
		width: 28px;
		height: 28px;
		position: relative;

		.icon-left {
			position: absolute;
			top: 11px;
			width: 14px;
			height: 3px;
			transition-duration: 0.4s;
			background-color: #ce181f;

			&::before,
			&::after {
				content: '';
				width: 14px;
				height: 3px;
				position: absolute;
				transition-duration: 0.4s;
				background-color: #ce181f;
			}

			&::before {
				top: -9px;
			}

			&::after {
				top: 9px;
			}
		}

		.icon-right {
			position: absolute;
			top: 11px;
			left: 12px;
			width: 14px;
			height: 3px;
			transition-duration: 0.4s;
			background-color: #ce181f;

			&::before,
			&::after {
				content: '';
				width: 14px;
				height: 3px;
				position: absolute;
				transition-duration: 0.4s;
				background-color: #ce181f;
			}

			&::before {
				top: -9px;
			}

			&::after {
				top: 9px;
			}
		}
	}

	&__nav {
		display: flex;
		align-items: center;
		height: 100%;

		@media (max-width: 1815px) {
			display: none;
		}

		> li {
			display: flex;
			align-items: center;
			height: 100%;

			@media (min-width: 1024px) {
				&:hover > a {
					color: #ce181f;

					+ .header__submenu {
						opacity: 1;
						pointer-events: auto;
					}
				}
			}

			+ li {
				margin-left: 32px;
			}

			> a {
				display: flex;
				align-items: center;
				height: 100%;
				font-size: 15px;
				line-height: 33px;
				color: #2c2c2c;
				transition: color 0.3s ease;

				svg {
					fill: #b2b9bc;
					transform: rotate(90deg);
				}
			}
		}
	}

	&__submenu {
		width: 100%;
		padding: 25px 20px 45px 321px;
		background-color: #f7f7f7;
		position: absolute;
		top: 80px;
		left: 0;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;
	}

	&__submenu-list-wrapper {
		display: flex;
	}

	&__submenu-group {
		max-width: 482px;
		min-width: 228px;

		+ .header__submenu-group {
			margin-left: 30px;
		}

		> a {
			display: block;
			padding-bottom: 9px;
			margin-bottom: 9px;
			font-size: 18px;
			border-bottom: 1px solid #ac1919;
			color: #2c2c2c;
		}
	}

	&__submenu-list {
		display: flex;
		flex-wrap: wrap;

		li {
			width: 100%;

			+ li {
				margin-left: 0;
			}

			a {
				font-size: 14px;
				line-height: 32px;
				text-decoration: underline;
				color: #4d4d4d;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		&--wide {
			li {
				width: 53%;

				&:nth-child(even) {
					width: 47%;
				}
			}
		}
	}

	&__info {
		width: 216px;
		background-repeat: no-repeat;
		background-position: 20px center;
		border: 1px solid #e6e6e6;
		background-color: #fff;
		height: 52px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		padding: 10px 20px 10px 54px;
		border-radius: 5px;
		box-sizing: border-box;

		@media (max-width: 1200px) {
			display: none;
		}

		+ .header__info {
			margin-left: 8px;
		}

		p {
			display: flex;
			align-items: center;
			font-size: 14px;
			line-height: 16px;
			color: #2c2c2c;
		}

		&--mobile {
			display: none;

			@media (max-width: 1200px) {
				display: inline-flex;
				position: relative;
				left: 50%;
				transform: translateX(-50%);

				&:first-child {
					margin: 15px auto;
				}

				+ .header__info {
					margin: 8px auto 15px;
				}
			}
		}
	}

	&__links {
		display: flex;
		margin-left: 40px;

		@media (max-width: 1023px) {
			display: none;
		}
	}

	&__link {
		display: flex;
		align-items: center;

		+ .header__link {
			margin-left: 23px;
		}

		svg {
			width: 20px;
			height: 20px;
			fill: #b2b9bc;
			margin-right: 5px;
		}

		a {
			font-size: 14px;
			line-height: 17px;
			color: #2c2c2c;
			text-decoration: underline;

			@media (min-width: 1024px) {
				&:hover {
					text-decoration: none;
				}
			}
		}

		&--mobile {
			display: none;

			@media (max-width: 1023px) {
				display: flex;
				justify-content: space-between;
				margin-left: 23px;

				> div {
					display: flex;
					align-items: center;
				}

				&:first-child {
					border-top: 1px solid #e6e6e6;
				}

				> a {
					width: 100%;
					padding: 12.5px 20px 12.5px;

					svg {
						width: 24px;
						height: 24px;
						fill: #ac1919;
						margin-right: 0;
					}
				}

				.header-mobile__contact {
					display: flex;
					align-items: center;

					svg {
						fill: #b2b9bc;
						margin-right: 5px;
					}
				}

				+ .header__link--mobile {
					margin: 0;
				}

				a {
					text-decoration: none;
				}
			}
		}
	}
}

.header-mobile {
	position: absolute;
	top: 0;
	left: 0;
	width: 220px;
	min-height: 100vh;
	background-color: #fff;
	margin-top: 80px;
	border: 1px solid #e6e6e6;
	z-index: 201;
	transform: translateX(-100%);
	transition: transform 0.4s linear;

	@media (max-width: 1200px) {
		width: 255px;
		margin-top: 60px;
	}

	&--open {
		transform: translateX(0);
	}

	&--open-sub {
		transform: translateX(-95%);
	}

	&__layout {
		min-height: 100vh;
		background-color: #fff;
		margin-left: -2px;
	}

	&__menu-link {
		border-bottom: 1px solid #e6e6e6;
		transition: background-color 0.3s ease;

		@media (min-width: 1024px) {
			&:hover {
				background-color: #ac1919;

				.header-mobile__submenu {
					display: block;
				}

				span {
					color: #fff;
				}

				svg {
					fill: #fff;
				}
			}
		}

		> a {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 12.5px 20px 12.5px 30px;

			@media (max-width: 1023px) {
				padding: 12.5px 20px 12.5px;
			}
		}

		span {
			font-size: 15px;
			line-height: 18px;
			color: #2c2c2c;
			transition: color 0.3s ease;
		}

		svg {
			fill: #ac1919;
			transition: fill 0.3s ease;
		}
	}

	&__submenu {
		display: none;
		position: absolute;
		left: 220px;
		top: 0;
		width: 300px;
		min-height: 100vh;
		background-color: #f6f6f6;
		box-sizing: border-box;
		z-index: -1;

		@media (max-width: 1200px) {
			left: 255px;
		}

		@media (max-width: 1023px) {
			left: 0;
			display: block;
			width: 281px;
			transform: translateX(-101%);
			transition: transform 0.8s ease;
		}

		&--open {
			transform: translateX(91%);
			transition: transform 0.4s ease;
		}

		.accordion {
			padding: 14px 15px;
			margin-top: 0;

			&__hint {
				font-weight: 400;
				font-size: 12px;
				margin-left: 8px;
				color: grey;
			}

			&__note {
				display: flex;
				font-size: 14px;
				line-height: 17px;
				padding: 10px;
				border: 1px solid #b72126;
				margin-bottom: 20px;

				&::before {
					content: '';
					display: block;
					max-width: 24px;
					width: 100%;
					height: 24px;
					background: url("../img/error_outline.svg") no-repeat;
					margin-right: 8px;
				}
			}
		}
	}

	&__submenu-list-wrapper {
		padding: 24px 40px 30px 26px;

		> a {
			display: block;
			font-size: 18px;
			color: #2c2c2c;
			border-bottom: 1px solid #ac1919;
			padding-bottom: 11px;
			margin-bottom: 8px;

			&:not(:first-of-type) {
				margin-top: 25px;
			}
		}
	}

	&__submenu-list {
		li {
			padding: 8px 0;

			a {
				font-size: 14px;
				color: #4d4d4d;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__back {
		display: none;
		align-items: center;
		border-bottom: 1px solid #e6e6e6;
		padding: 14px 15px;
		cursor: pointer;

		@media (max-width: 1023px) {
			display: flex;
		}

		span {
			font-size: 20px;
		}

		svg {
			width: 20px;
			height: 20px;
			fill: #ac1919;
			margin-right: 13px;
		}
	}
}