* {
	box-sizing: content-box;
	outline: none !important;
	font-family: 'Vauxhall Neue', sans-serif;
}

html {
	height: 100%;
	font-size: 14px;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-o-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	text-size-adjust: 100%;
}

body {
	max-width: 100%;
	height: 100%;
	margin: 0 auto;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-family: 'Vauxhall Neue', sans-serif;
	font-size: 14px;
	line-height: 17px;
	color: #000;
	overflow-x: hidden;
	position: relative;
}

h1, h2, h3, h4, h5, h6, p {
	display: block;
	margin: 0;
	padding: 0;
	font-weight: 400;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0;
	padding: 0;
	float: none;
}

img, input, textarea, button, a {
	border: none;
	outline: none;
	font-family: 'Vauxhall Neue', sans-serif;
	font-weight: 400;
}

textarea {
	position: relative;
	overflow: hidden;
	resize: none;
	box-sizing: border-box;
	display: block;
	width: 100%;
}

a {
	cursor: pointer;
	color: #fff;
	text-decoration: none;
}

table {
	border-spacing: 0;
}

td, th {
	vertical-align: top;
	word-wrap: break-word;
}

ul, nav, dl {
	margin: 0;
	padding: 0;
	list-style: none;
}

.fa, .fab, .fal, .far, .fas {
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
}

.fa, .far, .fas {
	font-family: $fa;
}

.fa, .fas {
	font-weight: 900;
}

button {
	display: block;
	cursor: pointer;
	box-sizing: border-box;
	padding: 0;
}

body.compensate-for-scrollbar {
	overflow: hidden;
}

.inner {
	width: 96%;
	max-width: 960px;
	margin: 0 auto;
}

.inner2 {
	width: 96%;
	max-width: 1140px;
	margin: 0 auto;
}

.inner3 {
	width: 96%;
	max-width: 1320px;
	margin: 0 auto;
}

.inner1640 {
	width: 96%;
	max-width: 1640px;
	margin: 0 auto;
}

.clear {
	clear: both;
}

.mb10 {
	margin-bottom: 10px;
}

.mb20 {
	margin-bottom: 20px;
}

.pt15 {
	padding-top: 15px;
}

.page--100vh {
	height: calc(100vh - 203px);
}

.page--100vh-min {
	min-height: calc(100vh - 203px);
}

#wrapper {
	position: relative;
	padding-top: 80px;
}

@media (max-width: 1200px) {
	#wrapper {
		padding-top: 60px;
	}
}

.owl-stage-outer, .owl-stage {
	position: relative;
	overflow: hidden;
}

.owl-item {
	position: relative;
	overflow: hidden;
	float: left;
}

.owl-nav.disabled {
	display: none;
}

.inputbox {
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
		color: #a6a6a6;
	}

	&:focus {
		&::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
			opacity: 0;
			transition: opacity 0.3s ease;
		}
	}
}

.textarea {
	&::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
		opacity: 1;
		transition: opacity 0.3s ease;
	}

	&:focus {
		&::-webkit-input-placeholder, &::-moz-placeholder, &:-moz-placeholder, &:-ms-input-placeholder {
			opacity: 0;
			transition: opacity 0.3s ease;
		}
	}
}

/* global */

.g-hidden {
	position: absolute;
	top: -10000em;
}

.g-line {
	zoom: 1;

	&:after {
		content: ".";
		display: block;
		height: 0;
		clear: both;
		visibility: hidden;
	}
}

/* modal */

.b-modal {
	position: relative;
	margin: 0 auto;
	max-width: 1072px;
	width: 100%;
}

.b-modal_close {
	background: url(../img/modal_form_close.png) no-repeat top center;
	width: 36px;
	height: 36px;
	cursor: pointer;
	z-index: 400;
	position: absolute;
	top: -40px;
	right: -40px;

	&:hover {
		background-position: center bottom;
	}
}

.b-modal {
	position: relative;
	z-index: 990;
}

/* modal */

.arcticmodal-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 11000;
}

.arcticmodal-container {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 11000;
	overflow: auto;
	margin: 0;
	padding: 0;
	border: 0;
	border-collapse: collapse;
}

*:first-child + html .arcticmodal-container {
	height: 100%;
}

.arcticmodal-container_i {
	height: 100%;
	margin: 0 auto;
	width: 100%;
}

.arcticmodal-container_i2 {
	padding: 24px;
	margin: 0;
	border: 0;
	vertical-align: middle;
}

.arcticmodal-error {
	padding: 20px;
	-moz-border-radius: 10px;
	-webkit-border-radius: 10px;
	-khtml-border-radius: 10px;
	border-radius: 10px;
	background: #000;
	color: #fff;
}