.log-page {
	+ .footer {
		background-color: #f6f6f6;
	}

	.inner {
		max-width: 1500px;
	}

	.main-content {
		overflow: auto;
		margin-top: 60px;

		table {
			width: 100%;
			max-width: 1200px;
			margin-right: auto;
			margin-left: auto;
			border-collapse: collapse;

			@media (max-width: 1115px) {
				width: 100%;
			}

			tr {
				th {
					height: 33px;
					padding: 0 6px;
					text-align: left;
					vertical-align: middle;
					background-color: #e2edf8;
					font-size: 13px;
					font-weight: bold;
				}

				td {
					height: 33px;
					padding: 0 6px;
					vertical-align: middle;
					white-space: nowrap;
					font-size: 14px;
					font-weight: 400;
					color: #2c2c2c;

					.link {
						color: #2c2c2c;
						text-decoration: underline;
						transition: 0.3s;

						@include hover {
							text-decoration: none;
							color: #841215;
						}
					}
				}

				&:nth-child(even) {
					td {
						background-color: #f2f2f2;
					}
				}
			}
		}

		.left-side,
		.right-side {
			@media (max-width: 1115px) {
				width: 100%;
			}
		}

		.right-side {
			table {
				tr {
					th {
						background-color: #a9cdf3;
					}
				}
			}
		}
	}

	.phone-balloon,
	.email-balloon {
		display: block;
		width: 42px;
		height: 100%;
		min-height: 100%;
		cursor: pointer;
		background-repeat: no-repeat;
		background-position: center center;

		&.phone-balloon {
			background-image: url("../img/log-phone.svg");
		}

		&.email-balloon {
			background-image: url("../img/log-mail.svg");
		}

		@include hover {
			background-color: #464646;

			&.phone-balloon {
				background-image: url("../img/log-phone-w.svg");
			}

			&.email-balloon {
				background-image: url("../img/log-mail-w.svg");
			}
		}

		&.active {
			background-color: #464646;

			&.phone-balloon {
				background-image: url("../img/log-phone-w.svg");
			}

			&.email-balloon {
				background-image: url("../img/log-mail-w.svg");
			}
		}
	}
}