.appointment {
	.page-main-title {
		height: 100px;

		.title {
			font-size: 34px;
			line-height: 33px;
		}
	}

	.ah1 {
		font-size: 24px;
		line-height: 28px;
		margin-top: 40px;
	}

	.inner2 {
		width: 850px;

		@media (max-width: 900px) {
			width: 96%;
		}
	}

	.reg-and-mile {
		.personal-info {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -12px;
			padding-bottom: 25px;

			@media (max-width: 500px) {
				margin: 0;
			}

			.input-block {
				width: calc(33.3333% - 24px);
				margin: 0 12px 15px;

				@media (max-width: 900px) {
					width: calc(50% - 24px);
				}

				@media (max-width: 500px) {
					width: 100%;
					margin: 0 0 15px;
				}

				label:not(.checkbox-container) {
					width: 100%;
					font-size: 16px;
					margin-bottom: 0;

					.red {
						color: #ce171f;
					}
				}

				input,
				select {
					height: 40px;
					border-radius: 3px;
				}
			}
		}

		.btn--blue {
			max-width: 266px;
			border-radius: 5px;
			font-weight: bold;
		}

		.notice {
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			margin-top: 17px;
		}

		.notice-2 {
			font-size: 28px;
			line-height: 33px;
			text-align: center;
			margin-top: 33px;
		}

		.notice-3 {
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			margin-top: 15px;
		}

		.row {
			&.border-row {
				padding-bottom: 34px;
			}

			&.border-top-row {
				padding-top: 24px;
			}

			> .input-block {
				padding-top: 25px;

				@media (max-width: 768px) {
					margin-bottom: 0;
				}

				> label {
					margin-bottom: 0 !important;
				}

				.ah1 {
					margin-top: 0;
				}

				.row {
					align-items: flex-end;

					.input-block {
						width: 67%;
						margin-right: 0;
						padding-top: 0;

						label {
							margin-bottom: 0;
							font-size: 14px;
						}
					}

					.btn {
						width: 30% !important;
						height: 40px;
						border-radius: 5px;
						font-size: 15px;
						line-height: 41px;
					}
				}

				.radio-label-outer {
					margin-bottom: 27px !important;
				}

				.radio-btn {
					+ .radio-btn {
						margin-top: 25px;
					}

					label {
						margin-bottom: 0;
						margin-top: 2px;
					}
				}
			}

			.inputbox {
				height: 40px;
				border-radius: 3px;
			}

			.form-map {
				@media (max-width: 900px) {
					margin-top: 35px;
				}
			}
		}

		.btn--step-3 {
			margin: 50px auto 0;
		}

		&.form-block {
			max-width: none;
		}
	}

	.button_block {
		text-align: center;
		margin-top: 29px;

		&.hide {
			display: none;
		}

		.btn {
			display: inline-block;
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
			margin: 0;
			width: 100%;
			max-width: 266px;
			border-radius: 5px;

			@media screen and (max-width: 900px) {
				display: block;
				margin: 0 auto;
			}

			&.btn--blue {
				margin-right: 20px;

				@media screen and (max-width: 900px) {
					margin-right: auto;
					margin-bottom: 20px;
				}
			}

			&.btn--gray {
				border: 1px solid #124390;
				background-color: #fff;
				color: #124390;

				&:hover {
					background-color: #124390;
					color: #fff;
				}
			}
		}
	}

	.slice_block {
		display: none;

		.h2-left {
			font-size: 22px;
			line-height: 33px;
			text-align: center;
			margin-top: 20px;
		}

		&.active {
			display: block;
		}
		a.red {
			display: inline-block;
			font-size: 16px;
			text-decoration: underline;
			line-height: 33px;
			text-align: center;
			display: block;
		}

		.gqc-exchange-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
			margin: 15px -12px 13px;

			@media (max-width: 900px) {
				align-items: center;
				margin: 15px 0 13px;
			}

			@media (max-width: 500px) {
				margin: 15px 0 13px;
			}

			.input-block {
				width: calc(33.3333% - 24px);
				margin: 0 12px 15px;

				@media (max-width: 900px) {
					width: 100%;
					margin: 0 0 15px;
				}

				@media (max-width: 500px) {
					width: 100%;
					margin: 0 0 15px;
				}

				p {
					font-size: 13px;
					color: #999999;
				}

				label {
					width: 100%;
					font-size: 14px;
				}

				input {
					border-radius: 3px;
					height: 40px;
				}

				&--branch {
					.radio-btn {
						@media (max-width: 600px) {
							padding: 10px 10px 10px 0;
						}
					}
				}
			}
		}

		.gqc-exchange-btn-row {
			.btn {
				width: 351px;
				height: 40px;
				border-radius: 5px;
				font-size: 16px;
				line-height: 37px;
				margin: 0 auto;
				cursor: default;

				@media (max-width: 500px) {
					width: 100%;
				}
			}

			&__grey {
				margin-top: 10px;
				text-align: center;
				font-size: 14px;
				color: #666666;
			}
		}
	}

	&.quote-request {
		margin-top: 34px;

		.content {
			width: 100%;
			max-width: 100%;
			margin: 0;
		}

		.oqr__step {
			display: none;
		}

		.oqr__title {
			font-size: 22px;
			text-align: center;
			margin-top: 20px;
		}

		.oqr-options__inputs label:not(:last-child) {
			margin-right: 27px;
		}

		#lookUpSection {
			margin-top: 15px;

			.ah2 {
				margin-bottom: 10px;
				font-size: 18px;
				line-height: 21px;

				a {
					margin-left: 17px;
					font-size: 14px;
					color: #ce171f;
					text-decoration: underline;
				}
			}
		}

		.err_block {
			margin-bottom: 30px;
			font-size: 18px;
			line-height: 24px;

			.inn_err_block {
				padding: 30px 45px 35px;
				border: 2px solid #e6e6e6;
			}

			.ah1 {
				font-size: 24px;
				line-height: 28px;
				font-weight: 700;
				margin-top: 40px;
				margin-bottom: 13px;
			}

			p {
				margin-bottom: 13px;
			}

			.tel {
				display: inline-block;
				padding-bottom: 6px;
				font-size: 22px;
				line-height: 25px;
				font-weight: 700;
				color: #2c2c2c;
				vertical-align: top;

				&::before {
					content: '';
					display: inline-block;
					width: 19px;
					height: 18px;
					margin-top: -4px;
					margin-right: 8px;
					vertical-align: middle;
					background: url(../img/err.svg) no-repeat;
					background-size: auto 100%;
				}
			}

			.btn_red {
				display: inline-block;
				width: auto;
				margin-top: 11px;
				margin-right: 25px;
				padding-right: 42px;
				padding-left: 42px;
				background: #ce181f;
				font-size: 16px;
				font-weight: 600;
				color: #fff;
				text-transform: uppercase;
				vertical-align: top;
			}
		}

		.oqr {
			&__method-wrap {
				justify-content: center;

				.oqr-label {
					max-width: 150px;
				}
			}

			&__finance-options {
				font-size: 18px;
				font-weight: 400;
				text-align: center;
				margin: 31px 0 20px;
			}

			&__inputs {
				.input-block {
					label {
						font-size: 14px;
					}

					input,
					select {
						height: 40px;
						border-radius: 3px;
					}
				}
			}

			.oqr-block {
				width: 100%;

				&--accordion {
					padding: 45px 0 39px;
				}

				&--options {
					padding-top: 17px;

					.checkbox-customizedv2 + span::after {
						border-radius: 3px;
					}
				}

				&--payment {
					border-bottom: 1px solid #cccccc;
				}

				&--exchange {
					padding-top: 0;
				}
			}
		}
	}

	.form-after-submit,
	.form-after-submit-home {
		.form-title {
			text-align: center;
		}

		.btn {
			width: 300px;
			max-width: unset;
			margin: auto;
			font-size: 18px;
			font-weight: 700;
		}
	}
}
