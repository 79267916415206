.marketing-consent {
	.container {
		width: 100%;
		padding: 0 15px;
		margin: 0 auto;
		box-sizing: border-box;

		@media (min-width: 576px) {
			max-width: 540px;
		}

		@media (min-width: 768px) {
			max-width: 720px;
		}

		@media (min-width: 992px) {
			max-width: 960px;
		}

		@media (min-width: 1200px) {
			max-width: 1140px;
		}
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}

	.col {
		flex-grow: 1;
		max-width: 100%;
	}

	.col-sm-6 {
		position: relative;
		width: 100%;

		@media (min-width: 576px) {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}

	h1 {
		margin-top: 20px;
		margin-bottom: 20px;
		font-size: 29px;
		line-height: 1.2;
		font-weight: bold;
		text-transform: uppercase;
	}

	h3 {
		margin: 20px 0 20px;
		font-size: 20px;
		font-weight: 400;
	}

	.ah3 {
		margin: 20px 0 20px;
		font-size: 20px;
		line-height: normal;
		font-weight: 400;
	}

	.red_line {
		margin: 10px 0;
		border-bottom: 1px solid #ce181e;
	}

	.frbadge {
		text-align: right;
	}

	img {
		vertical-align: middle;
		max-width: 100%;
	}

	.consent-bordered {
		border: 1px solid #ddd;
		box-sizing: border-box;

		.col {
			padding: 0 15px;

			&.consent-header {
				background-color: #eaeaea;
				padding: 1rem 15px;

				p {
					margin: 0;
				}
			}
		}
	}

	.consent-bordered-bottom {
		border-bottom: 1px solid #ddd;
	}

	p {
		margin: 1rem 0;
	}
}