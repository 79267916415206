.used-stock-page {
	padding-bottom: 40px;

	.relative_block {
		position: relative;

		@media (max-width: 987px) {
			display: flex;
			flex-direction: column;
		}

		.gal_none {
			display: none;
		}

		.fancybox {
			width: 40px;
			height: 40px;
			z-index: 1;
			margin-left: -50px;
			background: url("../img/controls/3.svg") no-repeat center center rgba(128, 128, 128, 0.3);
			position: absolute;
			left: 57%;
			top: 439px;

			@media (min-width: 988px) {
				top: 382px;
			}

			@media (max-width: 987px) {
				left: 100%;
			}

			@media (max-width: 500px) {
				top: 200px;
			}

			@include hover {
				background-color: rgba(128, 128, 128, 0.5);
			}
		}
	}

	.back_button {
		display: inline-block;
		width: 162px;
		height: 38px;
		margin-right: 10px;
		margin-top: 62px;
		margin-bottom: 37px;
		border: 1px solid #58585a;
		font-size: 14px;
		font-weight: bold;
		text-align: center;
		line-height: 36px;
		text-decoration: none !important;
		color: #2c2c2c;
		vertical-align: top;
		text-transform: uppercase;
		clear: both;
	}

	.big-images {
		width: 57%;
		height: 488px;
		margin-right: 62px;
		position: relative;
		float: left;
		overflow: hidden;

		@media (min-width: 988px) {
			height: 433px;
		}

		.owl-prev {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			position: absolute;
			left: 0;
			top: 50%;
			background: url("../img/controls/arrow.svg") no-repeat center center rgba(128, 128, 128, 0.3);
			transform: rotate(180deg);
			cursor: pointer;

			&.disabled {
				opacity: 0;
				visibility: hidden;
			}

			@include hover {
				background-color: rgba(128, 128, 128, 0.5);
			}
		}

		.owl-next {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			background: url("../img/controls/arrow.svg") no-repeat center center rgba(128, 128, 128, 0.3);
			position: absolute;
			right: 0;
			top: 50%;
			cursor: pointer;

			&.disabled {
				opacity: 0;
				visibility: hidden;
			}

			@include hover {
				background-color: rgba(128, 128, 128, 0.5);
			}
		}

		.item {
			height: 488px;
			position: relative;
			background-size: cover;
			background-position: center center;
			background-repeat: no-repeat;

			@media (min-width: 988px) {
				height: 433px;
			}

			span {
				position: absolute;
				left: 10px;
				bottom: 7px;
				color: #fff;
			}
		}
	}

	.thumbs {
		height: 137px;
		margin-top: 33px;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid #e1e1e1;
		border-bottom: 1px solid #e1e1e1;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;

		.owl-prev {
			width: 40px;
			cursor: pointer;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background: url("../img/owl.png") no-repeat center center;
			transform: rotate(180deg);

			&.disabled {
				opacity: 0;
				visibility: hidden;
			}

			@include hover {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}

		.owl-next {
			width: 40px;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			background: url("../img/owl.png") no-repeat center center;
			cursor: pointer;

			&.disabled {
				opacity: 0;
				visibility: hidden;
			}

			@include hover {
				background-color: rgba(0, 0, 0, 0.2);
			}
		}

		.item {
			height: 95px;
			background-size: cover;
			background-position: center center;
			position: relative;
			cursor: pointer;
		}

		.current {
			.item {
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					box-sizing: border-box;
					border: 1px solid #ce181f;
					z-index: 2;
				}
			}
		}
	}

	.text_block {
		display: flex;
		flex-direction: column;
		max-width: 413px;

		@media (max-width: 987px) {
			flex-direction: row;
			justify-content: space-between;
			max-width: 100%;
			padding: 0;
		}

		@media (max-width: 620px) {
			flex-direction: column;
		}

		.model {
			font-size: 28px;
			line-height: 28px;
			font-weight: bold;
			color: #2c2c2c;
		}

		.type {
			font-size: 16px;
			line-height: 28px;
			font-weight: 400;
		}

		.info {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-start;
			margin-top: 22px;
			padding-bottom: 23px;

			.pay {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 81px;
				padding: 18px 19px;
				background-color: #124390;
				box-sizing: border-box;

				@media (max-width: 580px) {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					box-sizing: border-box;
				}

				.price {
					font-size: 23px;
					line-height: 28px;
					font-weight: 400;
					color: #ffffff;
				}

				.per_mount {
					font-size: 16px;
					line-height: 28px;
					font-weight: 400;
					color: #ffffff;
				}

				@include hover {
					background-color: #58585a;
				}
			}

			.attributes {
				margin-left: 33px;

				@media (max-width: 394px) {
					width: 100%;
					margin-left: 0;
					margin-top: 15px;
				}

				.mileage {
					margin-bottom: 7px;
					font-size: 18px;
					line-height: 28px;
					font-weight: 400;
				}

				.attribute {
					font-size: 14px;
					line-height: 19px;
					font-weight: 400;
				}
			}
		}

		.contacts {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding: 45px 0 40px 0;
			margin-top: 6px;
			border-bottom: 1px solid #e6e6e6;
			border-top: 1px solid #e6e6e6;

			@media (max-width: 620px) {
				padding: 25px 0 20px 0;
			}

			.adress,
			.phone {
				font-size: 18px;
				line-height: 27px;
				font-weight: 400;

				img {
					margin-right: 7px;
				}
			}

			.adress {
				margin-top: -7px;
				padding-bottom: 13px;
				color: #000;

				@media (max-width: 500px) {
					padding-bottom: 5px;
				}

				@include hover {
					color: #ce181f;
				}
			}

			.phone {
				font-weight: bold;
			}
		}

		.buttons {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 24px;
			font-size: 16px;
			line-height: 28px;
			font-weight: 400;

			@media (max-width: 1118px) {
				flex-direction: column;
			}

			@media (max-width: 987px) {
				justify-content: center;
			}

			@media (max-width: 620px) {
				justify-content: space-between;
				flex-direction: row;
				flex-wrap: wrap;
			}

			.button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: auto;
				height: 53px;
				color: #2c2c2c;
				cursor: pointer;

				&:nth-child(2) {
					padding: 0 36px;
					border-right: 1px solid #e1e1e1;
					border-left: 1px solid #e1e1e1;

					@media (max-width: 620px) {
						padding: 0 5px;
						border: none !important;
						flex-basis: 127px;
					}
				}
			}

			img {
				margin-right: 10px;
			}
		}
	}

	.description {
		margin: 34px 16px 59px 16px;

		@media (max-width: 620px) {
			margin: 34px 0 59px 0;
		}

		h4 {
			margin-bottom: 18px;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
			color: #2c2c2c;
		}

		.desc-text {
			font-size: 14px;
			line-height: 22px;
			font-weight: 400;
			color: #2c2c2c;
		}

		.parameters {
			display: flex;
			justify-content: space-between;
			margin-top: 57px;

			@media (max-width: 987px) {
				flex-wrap: wrap;
				width: 100%;
			}

			.col {
				flex-grow: 1;

				&:nth-child(2) {
					padding: 0 61px;
					margin: 0 61px;
					border-right: 1px solid #e1e1e1;
					border-left: 1px solid #e1e1e1;

					@media (max-width: 987px) {
						width: 100%;
						padding: 0 !important;
						border: none !important;
						margin: 0 !important;
					}
				}

				.item {
					display: flex;
					justify-content: space-between;

					.left {
						font-size: 14px;
						line-height: 32px;
						font-weight: 400;
					}

					.right {
						font-size: 14px;
						line-height: 32px;
						font-weight: bold;
					}
				}
			}
		}
	}

	#sync1 {
		@media (max-width: 987px) {
			float: none;
			display: flex;
			justify-content: center;
			width: auto;
			margin-right: 0;
			order: -2;
		}

		@media (max-width: 620px) {
			margin-bottom: 30px;
		}

		@media (max-width: 500px) {
			height: 250px;
		}
	}

	#sync2 {
		@media (max-width: 987px) {
			order: -1;
			margin-bottom: 30px;
		}

		@media (max-width: 620px) {
			display: none;
		}
	}

	#sync1,
	#sync2 {
		.owl-stage .owl-item .item {
			@media (max-width: 500px) {
				height: 250px !important;
			}
		}
	}

	.tabs {
		max-width: 100%;
		padding: 0;
		margin: 0 auto;

		> section {
			display: none;
			padding: 15px;
			background: #fff;
			border-top: 1px solid #ddd;

			> p {
				margin: 0 0 5px;
				line-height: 1.5;
				color: #383838;
			}
		}

		> input {
			display: none;
			position: absolute;

			&:checked + label {
				border: 1px solid #e8e8e8;
				background: #fff;
			}
		}

		> label {
			display: inline-block;
			margin: 0 6px -1px;
			padding: 15px 25px;
			border: 1px solid #e8e8e8;
			background-color: #e1e1e1;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
			color: #2c2c2c;
			text-align: center;

			@media (max-width: 706px) {
				font-size: 14px;
			}

			@media (max-width: 595px) {
				font-size: 14px;
				margin: 7px 3px -1px;
				padding: 5px 10px;
			}

			@media (max-width: 400px) {
				width: 100%;
				margin: 3px 0;
				padding: 0;
				font-size: 14px;
			}

			@include hover {
				color: #2c2c2c;
				cursor: pointer;
			}
		}
	}

	#tab1:checked~#content-tab1,
	#tab2:checked~#content-tab2,
	#tab3:checked~#content-tab3,
	#tab4:checked~#content-tab4 {
		display: flex;
	}

	#content-tab1 {
		justify-content: space-between;
		flex-wrap: wrap;
		padding-top: 40px;

		.col {
			display: flex;
			flex-direction: column;

			@media (max-width: 515px) {
				width: 100%;
			}

			.item {
				margin-bottom: 56px;

				@media (max-width: 515px) {
					margin-bottom: 15px !important;
				}

				.headline {
					font-size: 14px;
					line-height: 22px;
					font-weight: bold;
				}

				ul {
					list-style: disc;

					li {
						margin-left: 17px;
						font-size: 14px;
						line-height: 22px;
						font-weight: 400;
					}
				}
			}
		}
	}

	#content-tab2 {
		flex-wrap: wrap;
		margin: 0 -15px 0 -15px;

		.item {
			max-width: 350px;
			width: 350px;
			margin: 15px;

			.headline {
				font-size: 18px;
				line-height: 28px;
				font-weight: bold;
			}

			ul {
				li {
					display: flex;
					justify-content: space-between;

					&:nth-child(odd) {
						background-color: #f2f2f2;
					}

					.left,
					.right {
						font-size: 16px;
						line-height: 29px;
						font-weight: 400;
					}
				}
			}
		}
	}

	.finance {
		&__block {
			.btn-finance-explained {
				@media (max-width: 580px) {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					box-sizing: border-box;
				}
			}
		}
	}

	&__fixed {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 120px;
		padding: 0 170px;
		border-top: 1px solid #124390;
		background: #fff;
		position: fixed;
		left: 0;
		bottom: 0;
		z-index: 2;
		box-sizing: border-box;

		@media (max-width: 999px) {
			display: none;
		}

		.ah1 {
			margin-bottom: 3px;
			font-size: 18px;
			font-weight: 600;
		}

		.ah2 {
			font-size: 24px;
			line-height: 27px;
			font-weight: 600;
		}

		.used-stock-page__fixed-right {
			margin-left: 30px;

			.btn {
				display: block;
				width: auto;
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
}