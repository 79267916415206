.deal-setup {
	#saveLeadIdBtn,
	#saveEnquiryIdBtn {
		max-width: 94px;
		width: 100%;
		height: 35px;
		font-size: 15px;
		font-weight: 400;
		background-color: #124390;
		border-radius: 5px;
	}

	.rotate {
		transform: rotate(180deg);
	}

	.table_with_button {
		display: flex;
		align-items: center;
	}

	.wcl-custom-input {
		width: 100%;
		height: 35px;
		margin-right: 10px;
		border-radius: 3px;
		border: 1px solid #a5a5a5;
		background-color: #fff;
		box-sizing: border-box;
	}

	.sales-manager .table .row .right a {
		color: #298efd;
	}

	.sales-manager .section .btn {
		line-height: 18px;
	}

	.sales-manager .flex-block {
		margin-bottom: 26px;

		> .left {
			@media (max-width: 1200px) {
				width: 100% !important;
				max-width: 520px;
				margin: 0 auto;
			}

			.block {
				> .title {
					font-size: 18px;
					font-weight: 400;
				}

				.table-other .left {
					width: 37%;
					flex-grow: unset;
				}

				.table-other .right {
					.input-block {
						display: flex;
						align-items: center;
						margin-bottom: 0;
					}
				}

				&--table {
					border: 1px solid #e6e6e6;
					background-color: #f7f7f7;

					.title {
						padding: 9px;
					}

					.ds-inc-mis-edit-btn {
						display: inline-block;
						width: 13px;
						height: 13px;
						margin: 0 5px;
						background: url(../img/edit-btn.png) no-repeat center center;
						background-size: contain;
						cursor: pointer;
						transition-duration: 0.2s;

						@include hover {
							opacity: 0.8;
						}
					}

					.table {
						margin-bottom: 0;

						.row {
							justify-content: space-between;
							flex-wrap: nowrap;

							+ .row {
								border-top: 1px solid #e6e6e6;
							}

							&:nth-child(odd) {
								background-color: #f7f7f7;
								align-items: center;
							}
						}

						.left,
						.right {
							display: block;
							height: auto;
						}

						.left {
							color: #666666;
						}

						.right {
							text-align: right;

							@media (max-width: 1200px) {
								width: auto;
							}
						}

						.btn-red {
							max-width: 135px;
							width: 100%;
							height: 35px;
							line-height: 35px;
							border-radius: 5px;
						}
					}
				}
			}
		}

		.right {
			.section {
				&:not(:last-child) {
					border-bottom: 0;
					padding-bottom: 0;
					margin-bottom: 28px;
				}

				.table-si {
					.row {
						.col {
							font-size: 14px;
							padding: 0;
							margin: 0;
							flex-basis: unset;

							&:nth-child(1) {
								width: 35%;
							}

							&:nth-child(2) {
								width: 19%;
							}

							&:nth-child(3) {
								width: 18%;
							}

							&:nth-child(4) {
								width: 16%;
							}

							&:nth-child(5) {
								width: 12%;
							}
						}
					}

					&--mobile {
						@media (max-width: 1024px) {
							width: 100%;
							flex-direction: column;
							align-items: flex-start;
							box-sizing: border-box;
						}

						@media (max-width: 550px) {
							width: 100%;
						}

						.row {
							&.headline {
								@media (max-width: 1024px) {
									display: none;
								}
							}

							.col {
								@media (max-width: 1024px) {
									width: 100% !important;
									display: flex;
									padding: 8px 0;
								}

								&::before {
									content: attr(aria-label);
									display: none;
									max-width: 100px;
									width: 100%;
									margin-right: 20px;
									color: #999999;

									@media (max-width: 1024px) {
										display: inline-block;
									}
								}
							}
						}
					}
				}

				.subtitle {
					font-size: 18px;
					margin-top: 30px;
				}

				.table-added {
					width: 100%;
					padding: 12px 0;
					box-sizing: border-box;

					.row label {
						margin: 0;
					}
				}

				.modal-openers {
					width: auto;
					align-items: center;

					.btn-hp,
					.btn-pcp {
						height: 35px;
						border-radius: 5px;
						color: #ce171f;
						background-color: #fff;
						font-weight: 400;
						transition: all 0.3s ease;

						@include hover {
							color: #fff;
							background-color: #ce171f;
						}
					}

					.btn-red {
						width: 190px;
						height: 35px;
						font-weight: 400;
						border-radius: 5px;
					}

					.applied {
						font-size: 14px;

						span {
							color: #ce171f;
						}
					}
				}

				.offered-price {
					margin-top: 13px;
					margin-bottom: 54px;

					label {
						font-size: 14px;
						margin-bottom: 4px;
					}

					.flex-wrap {
						margin-top: 0;

						input {
							height: 31px;
							border-radius: 3px;
							font-size: 14px;
						}
					}

					.estimated-profit {
						padding: 2.5% 8%;
						font-size: 14px;
						float: right;

						p + p {
							margin-top: 10px;
						}
					}

					.price {
						font-size: 14px;
						margin-top: 15px;

						.red {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	.estimated-profit span + span {
		margin-top: 7px;
	}

	.table.table-other {
		.row {
			min-height: 42px;
			align-items: center;
			padding: 3px 14px 3px 9px;
			box-sizing: border-box;
		}

		select {
			margin: 0;
			padding: 9px 20px 9px 10px;
			border-radius: 3px;

			&::-ms-expand {
				display: none;
			}
		}
	}

	.row {
		margin-left: 0;
		margin-right: 0;
		align-items: flex-end;
	}

	.block--documents {
		border-top: 1px solid #e6e6e6;
		margin-top: 30px;
		padding-top: 20px;

		.title {
			font-size: 24px !important;
			font-weight: 400 !important;
			margin-bottom: 20px;
		}

		.file {
			display: none;
			margin-top: 15px;
		}

		.file-name {
			display: block;
			width: 100%;
			font-size: 16px;
			text-decoration: underline;
			color: #ce181f;

			+ .file-name {
				margin-top: 10px;
			}
		}

		.pdf-open-popup {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 184px;
			height: 35px;
			margin-bottom: 35px;
			font-size: 15px;
			box-sizing: border-box;
			background-color: #ce181f;
			margin-top: 30px;
			border-radius: 5px;

			&:hover {
				color: #fff;
				text-decoration: none;
			}
		}
	}

	.status-control {
		display: flex;

		@media (max-width: 767px) {
			flex-wrap: wrap;
			align-items: center;
			margin: 0 -5px;
		}

		@media (max-width: 500px) {
			margin: 0;
		}

		.btn-calms {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 165px;
			height: 40px;
			margin-right: 15px;
			background-color: #ff832f;
			font-size: 16px;
			font-weight: 400;
			color: #fefefe;
			box-sizing: border-box;
			position: relative;
			border-radius: 5px;

			@media (max-width: 767px) {
				width: calc(50% - 10px);
				margin: 0 5px 10px;
			}

			@media (max-width: 500px) {
				width: 100%;
				margin: 0 0 10px;
			}

			@include hover {
				background-color: #e0752c;
				text-decoration: none;
			}

			svg {
				transition: transform 0.3s ease;

				&:first-child {
					margin-right: 5px;
				}
			}

			figure {
				max-width: 235px;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
				z-index: 1;
				transform: translateY(113%);
				box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
				border-radius: 5px 0 0;
				border: 1px solid #d9d9d9;
				background-color: #ffffff;

				@media (max-width: 767px) {
					width: 100%;
				}

				a {
					display: block;
					padding: 13px 24px;
					font-size: 16px;
					color: #2c2c2c;
					margin-right: 0;
					transition: all 0.3s ease;

					+ a {
						border-top: 1px solid #d9d9d9;
					}

					&:hover {
						text-decoration: none;
						background-color: rgba(#d9d9d9, 0.5);
					}
				}
			}

			&--final {
				width: 200px;

				@media (max-width: 500px) {
					width: 100%;
				}
			}
		}

		#delete-deal-btn {
			background-color: #4d4d4d;
			margin-right: 0;
			margin-left: auto;

			@media (max-width: 767px) {
				margin: 0 5px 10px;
			}

			@media (max-width: 500px) {
				margin: 0 0 10px;
			}
		}
	}

	.section-button {
		display: flex;
		align-items: center;
		width: 100%;
		margin: 0;
		padding: 11px 0;
		background-color: #fff;
		border-top: 1px solid #d9d9d9;
		cursor: pointer;
	}

	.btn-caret {
		display: flex;
		align-items: center;
		justify-content: center;
		order: 1;
		max-width: 30px;
		width: 100%;
		height: 30px;
		margin-left: 11px;
		border-radius: 5px;
		background-color: #ce171f;

		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background: url(../img/controls/arrow.svg) no-repeat center center;
			transform: rotate(90deg);
			transition: transform 0.3s ease;
		}

		&.btn-up {
			transform: none;

			&::after {
				transform: rotate(270deg);
			}
		}
	}

	.section-button .title {
		font-size: 22px;
		font-weight: 400;
		margin-left: 0;
	}

	.table-si {
		.row {
			display: flex;
			flex-wrap: wrap;
			padding: 12px 14px 12px 9px;

			&:nth-child(even) {
				background-color: #f2f2f2;
			}

			&:nth-last-child(1) {
				color: #ce171f;
			}

			.col {
				font-size: 16px;

				&:not(:last-child) {
					margin-right: 10px;
				}

				&:nth-child(1) {
					width: calc(33.8215712383489% - 10px);
					font-weight: bold;
					font-size: 18px;
				}

				&:nth-child(2) {
					width: calc(18.2423435419441% - 10px);
				}

				&:nth-child(3) {
					width: calc(18.375499334221% - 10px);
				}

				&:nth-child(4) {
					width: calc(15.4460719041278% - 10px);
				}

				&:nth-child(5) {
					width: calc(14.1145139813582%);
				}
			}
		}

		.row.headline {
			.col {
				font-weight: 400;
			}
		}

		&.table-programm {
			@media (max-width: 760px) {
				overflow-x: scroll;
			}

			.row {
				align-items: center;
				min-height: 65px -12px * 2;

				@media (max-width: 760px) {
					width: fit-content;
				}

				.col {
					&:not(:last-child) {
						margin-right: 10px;
					}

					&:nth-child(1) {
						width: 43px;
						font-weight: bold;
						font-size: 18px;

						@media (max-width: 760px) {
							width: 30px;
							min-width: 30px;
						}
					}

					&:nth-child(2) {
						width: calc(11.6758241758242% - 10px - 14.3333333333333px);

						@media (max-width: 760px) {
							width: 50px;
							min-width: 50px;
						}
					}

					&:nth-child(3) {
						width: calc(47.6648351648352% - 10px - 14.3333333333333px);

						@media (max-width: 760px) {
							width: 150px;
							min-width: 150px;
						}
					}

					&:nth-child(4) {
						width: calc(35.4395604395604% - 10px - 14.3333333333333px);

						@media (max-width: 760px) {
							width: 150px;
							min-width: 150px;
						}
					}

					input {
						display: none;

						+ label {
							display: inline-block;
							width: 20px;
							height: 20px;
							border: 1px solid #a5a5a5;
							border-radius: 50%;
							background-color: #ffffff;
							cursor: pointer;
							position: relative;

							&::before {
								content: '';
								width: 10px;
								height: 10px;
								border-radius: 50%;
								background-color: #124390;
								position: absolute;
								top: 4px;
								left: 4px;
								transition-duration: 0.2s;
								opacity: 0;
							}
						}

						&:checked {
							+ label {
								&::before {
									opacity: 1;
								}
							}
						}
					}
				}
			}
		}
	}

	.ds-inc-mis {
		@media (max-width: 500px) {
			width: 600px;
		}
	}

	.ds-table-wrap {
		@media (max-width: 500px) {
			max-height: 100%;
			height: auto;
			overflow-x: scroll;
		}
	}

	.ds-inc-mis-row {
		display: flex;
		align-items: center;
		padding: 15px 16px 15px;

		&:nth-child(2n - 1):not(:first-child) {
			background-color: #fff;
		}

		&:nth-child(even) {
			background-color: #f2f2f2;
		}

		&.active {
			padding-top: 8px;
			padding-bottom: 7px;

			.ds-inc-mis-edit-btn {
				background-image: url(../img/save_red.svg);
			}
		}

		.ds-inc-mis-col--head {
			font-size: 14px;
			line-height: 16px;
			font-weight: 400;

			&:nth-child(1) {
				width: calc(100% - 68px / 3 - 20px + 34px);
			}

			&:nth-child(2) {
				width: 0;
			}
		}
	}

	.ds-inc-mis-col {
		&:nth-child(1) {
			width: 34px;
		}

		&:nth-child(2) {
			width: calc(60% - 68px / 3 - 20px);
			margin-right: 10px;
		}

		&:nth-child(3) {
			width: calc(20% - 68px / 3);
			margin-right: 10px;
		}

		&:nth-child(4) {
			width: calc(20% - 68px / 3);
		}

		&:nth-child(5) {
			width: 34px;
		}
	}

	.ds-inc-mis--without-edit-col {
		.ds-inc-mis-col:nth-child(2) {
			width: calc(60% - 68px / 3 - 20px + 34px);
			margin-right: 10px;
		}

		.ds-inc-mis-col--head {
			&:nth-child(1) {
				width: calc(60% - 68px / 3 - 20px + 68px);
			}

			&:nth-child(2) {
				width: 0;
			}
		}
	}

	.ds-inc-mis-remove-btn {
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background: url(../img/cancel-btn.png) no-repeat center center;
		background-size: contain;
		cursor: pointer;
		transition-duration: 0.2s;

		@include hover {
			opacity: 0.8;
		}
	}

	.ds-inc-mis-edit-btn {
		width: 19px;
		height: 19px;
		background: url(../img/edit-btn.png) no-repeat center center;
		background-size: contain;
		margin-left: 15px;
		cursor: pointer;
		transition-duration: .2s;

		@include hover {
			opacity: 0.8;
		}
	}

	.ds-inc-mis-input {
		width: 116px;
		margin-bottom: 0;
		padding: 8px 9px;
		border: 1px solid #a5a5a5;
		outline: 0;
		font-size: 14px;
		line-height: 1em;
		-webkit-appearance: none;
		box-sizing: border-box;
	}

	.deal-set-radiogroup {
		margin-top: 20px;

		.radiogroup__radio{
			max-width: calc(50% - 10px);
		}

		.radiogroup__radio span {
			width: 145px;
			max-width: 100%;
			font-size: 16px;
			font-weight: 400;
		}
	}

	.ds-fin-prog-alert {
		margin-top: 15px;
		font-size: 16px;
		line-height: 1.2;
		font-weight: 400;
		color: #ce181f;
	}

	.ds-submit-btn {
		font-size: 18px;
		font-weight: bold;
		margin-top: 19px;

		@media (max-width: 500px) {
			width: 100%;
		}
	}
}

.popup-file {
	max-width: 551px;
	width: 100%;

	&__title {
		font-size: 24px;
		line-height: 28px;
		font-weight: bold;
		text-align: center;
		margin-bottom: 20px;
	}

	.form-block {
		.input-block + .input-block {
			margin-top: 20px;
		}

		#up-file {
			display: none;
		}

		.file-name {
			display: block;
			width: 100%;
			font-size: 16px;
			text-decoration: underline;
			color: #ce181f;
			text-align: center;

			+ .file-name {
				margin-top: 10px;
			}


		}

		label {
			margin-bottom: 0 !important;
		}

		input,
		select {
			padding: 5px 10px !important;
			height: 35px;
			margin-top: 5px;
		}

		select {
			background-image: url(../img/select_hov.svg);
		}

		.btn--upload {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 34px;
			font-size: 15px;
			font-weight: 400;
			color: #fff;
			background-color: #6c757d;
			border: 1px solid #6c757d;
			margin-bottom: 20px !important;
			border-radius: 0;
			transition: all 0.3s ease;

			&:hover {
				@media (min-width: 768px) {
					background-color: #fff;
					color: #6c757d;
				}
			}
		}

		.selected {
			font-size: 14px;
			text-align: center;
		}
	}

	&__flex {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 60px;

		.btn {
			max-width: 181px;
			width: 100%;
			height: 40px;
			font-size: 15px;
			line-height: 30px;
			font-weight: 400;
			border-radius: 0;
			margin-top: 0;
			transition: all 0.3s ease;

			&--ok {
				background-color: #ce171f;
				border: 1px solid #ce171f;
				margin-right: 20px;
				color: #fff;

				&:hover {
					@media (min-width: 768px) {
						background-color: #fff;
						color: #ce171f;
					}
				}
			}

			&--cancel {
				background-color: #fff;
				color: #124390;
				border: 1px solid #124390;

				&:hover {
					@media (min-width: 768px) {
						background-color: #124390;
						color: #fff;
					}
				}
			}
		}
	}
}