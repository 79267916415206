.catalog {
	position: relative;
	overflow: hidden;

	.row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -14px;

		@media (max-width: 768px) {
			margin: 0;
		}

		.item {
			width: calc(33.33333% - 28px);
			margin: 0 14px 25px;
			box-sizing: border-box;

			@media (min-width: 1000px) and (max-width: 1400px) {
				width: calc(50% - 28px);
			}

			@media (max-width: 900px) {
				width: calc(50% - 28px);
			}

			@media (max-width: 768px) {
				width: 100%;
				margin: 0 0 25px;
			}

			&>div {
				display: flex;
				flex-direction: column;
				position: relative;
				overflow: hidden;
				height: 100%;

				@include hover {
					.gray_dark {
						background: #eae9e9;
					}
				}

				.num {
					font-size: 14px;
					position: absolute;
					top: 6px;
					left: 21px;
					z-index: 1;
				}

				.image_block {
					position: relative;
					height: 205px;
					background-color: #eeeeee;
					background-position: center center;
					background-size: cover;

					@media (max-width: 767px) {
						height: 400px;
					}

					@media (max-width: 500px) {
						height: 250px;
					}
				}

				.gray_light {
					position: relative;
					overflow: hidden;
					box-sizing: border-box;
					padding: 12px 20px 0;

					.ah1 {
						display: block;
						overflow: hidden;
						margin-bottom: 3px;
						color: #2c2c2c;
						font-weight: bold;
						font-size: 18px;
						line-height: 20px;
					}

					.text {
						width: 100%;
						font-size: 13px;
						line-height: 24px;
						color: #4d4d4d;
						margin-bottom: 9px;
					}
				}

				.bottom {
					margin-top: auto;

					.flex-block {
						display: flex;
						align-items: flex-end;
						flex-wrap: wrap;
						justify-content: center;
						padding: 0 20px;

						@media (min-width: 901px) and (max-width: 1400px) {
							flex-direction: column;
							align-items: center;
						}

						.price-block {
							display: flex;
							align-items: flex-end;
							box-sizing: border-box;
							margin-bottom: 13px;
							min-height: 24px;

							@media (min-width: 901px) and (max-width: 1400px) {
								justify-content: center;
							}

							+ .price-block {
								padding-left: 20px;
								margin-left: 20px;
								border-left: 1px solid #999999;

								@media (min-width: 901px) and (max-width: 1400px) {
									border-left: none;
									padding-left: 0;
									margin-left: 0;
									border-top: 1px solid #999999;
									margin-top: 10px;
									padding-top: 10px;
									text-align: left;
								}
							}

							&--vat p {
								font-size: 14px;
								line-height: 11px;
								white-space: nowrap;
							}
						}

						p {
							font-size: 28px;
							line-height: 24px;
						}

						span {
							display: inline-block;
							font-size: 12px;
							line-height: 11px;
							padding-left: 5px;

							@media (min-width: 1000px) and (max-width: 1400px) {
								text-align: left;
							}
						}

						&--van {
							.price-block + .price-block {
								padding-left: 8px;
								margin-left: 8px;

								@media (min-width: 901px) and (max-width: 1400px) {
									padding-left: 0;
									margin-left: 0;
								}
							}

							p {
								font-size: 24px;
							}
						}
					}

					.btn {
						width: calc(100% - 40px);
						height: 40px;
						border-radius: 5px;
						font-size: 16px;
						line-height: 40px;
						margin: 0 20px 13px;
					}
				}

				.gray_dark {
					display: flex;
					justify-content: space-between;
					padding: 2px 14px 2px 20px;
					background-color: #f6f6f6;
					line-height: 24px;
					transition: background-color 0.3s ease;
					margin-top: auto;

					.left {
						overflow: hidden;
						cursor: pointer;
						white-space: nowrap;
						text-overflow: ellipsis;
						color: #2c2c2c;
						font-size: 11px;

						@include hover {
							text-decoration: underline;
						}

						&:before {
							display: inline-block;
							width: 9px;
							height: 12px;
							margin-top: -3px;
							margin-right: 4px;
							content: '';
							vertical-align: middle;
							background: url("../img/mark.png") no-repeat;
						}
					}

					.call {
						display: flex;
						align-items: center;
						margin-left: 10px;
						font-size: 13px;
						color: #2c2c2c;

						svg {
							width: 15px;
							height: 15px;
							fill: #808080;
							margin-right: 4px;
						}
					}
				}
			}
		}
	}

	+ .pagi {
		margin-bottom: 40px;
		padding-top: 10px;
	}
}
