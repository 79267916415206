.video-appointment {
	&__inner {
		max-width: 995px;
		margin: 31px auto 100px;
		padding: 0 20px;
		width: 100%;
		box-sizing: border-box;

		@media (min-width: 768px) {
			margin: 71px auto 100px;
		}

		@media (min-width: 1024px) {
			padding: 0;
		}
	}

	.form-block .input-block.err-required::after {
		position: absolute;
		bottom: -18px;
		top: auto;
	}

	.row-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		@media (min-width: 768px) {
			margin: 0 -10px;
		}

		.input-block {
			width: 100%;
			margin-bottom: 23px;
			position: relative;

			@media (min-width: 768px) {
				width: calc(33.3333% - 20px);
				margin: 0 10px 23px;
			}

			label {
				width: 100%;
				margin-bottom: 0;

				input,
				select {
					margin-top: 11px;
				}
			}
		}
	}

	.row {
		margin-bottom: 26px;

		@media (min-width: 768px) {
			margin-bottom: 20px;
		}

		.row-title {
			margin-top: 38px;
			margin-bottom: 25px;
			font-size: 15px;
			line-height: 24px;
			text-align: center;
			width: 100%;

			@media (min-width: 768px) {
				margin-bottom: 14px;
			}
		}
	}

	.flex-block {
		display: flex;
		flex-direction: column;
		justify-content: center;

		@media (min-width: 768px) {
			flex-direction: row;
		}

		.input-block {
			position: relative;
			width: 100%;

			@media (min-width: 768px) {
				width: 318px;
			}

			&:nth-child(2) {
				margin-top: 25px;

				@media (min-width: 768px) {
					margin-top: 0;
					margin-left: 21px;
				}
			}

			label {
				width: 100%;
				margin-bottom: 0;

				span {
					color: #ce181f;
				}

				input {
					margin-top: 12px;
				}
			}
		}
	}

	.application {
		border-top: 1px solid #cccccc;
		border-bottom: 1px solid #cccccc;
		padding: 35px 0 29px;
		margin: 0 -20px;

		@media (min-width: 768px) {
			padding: 40px 0 55px;
			margin: 0;
		}

		&__title {
			font-size: 24px;
			line-height: 28px;
			text-align: center;
			margin-bottom: 25px;

			@media (min-width: 768px) {
				margin-bottom: 34px;
			}

			br {
				@media (min-width: 768px) {
					display: none;
				}
			}
		}

		&__wrapper {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin: 0 -10px;
		}

		&__radio {
			display: flex;
			align-items: center;
			width: calc(100% - 60px);
			height: 100px;
			margin-bottom: 20px;
			padding: 16px 20px 20px;
			border: 1px solid #a5a5a5;
			background-color: #ffffff;
			box-sizing: border-box;

			@media (min-width: 768px) {
				flex-direction: column;
				justify-content: space-between;
				width: 200px;
				height: auto;
				margin: 0 10px;
				padding: 16px 40px 20px;
			}

			> img {
				display: none;

				@media (min-width: 768px) {
					display: block;
				}
			}

			input {
				display: none;

				&:checked + .circle {
					&::before {
						border-color: #dd2036;
					}

					&::after {
						display: block;
					}
				}
			}

			.circle {
				display: flex;
				align-items: center;
				font-size: 16px;
				position: relative;
				margin-left: 30px;

				@media (min-width: 768px) {
					margin-top: 10px;
				}

				&::before,
				&::after {
					content: '';
					display: block;
					width: 30px;
					height: 30px;
					border: 1px solid #a7a7a7;
					border-radius: 50%;
					background-color: #ffffff;
					position: absolute;
					top: 50%;
					left: -30px;
					transform: translateY(-50%);

					@media (min-width: 768px) {
						width: 20px;
						height: 20px;
					}
				}

				&::after {
					display: none;
					width: 16px;
					height: 16px;
					background-color: #dd2036;
					border: none;
					left: -22px;

					@media (min-width: 768px) {
						width: 12px;
						height: 12px;
						left: -25px;
					}
				}

				img {
					max-width: 65px;
					margin: 0 28px;

					@media (min-width: 768px) {
						max-width: 100%;
						display: none;
					}
				}
			}

			&--red {
				border-color: #dd2036;
			}
		}
	}

	.btn {
		width: 100%;
		max-width: 100%;
		margin: 30px auto 0;

		@media (min-width: 768px) {
			width: 266px;
		}
	}
}