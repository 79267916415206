.commercial-vehicle {
	.inner {
		max-width: 1140px;
	}

	.dbr {
		display: none;

		@media (min-width: 768px) {
			display: block;
		}
	}

	.mbr {
		@media (min-width: 768px) {
			display: none;
		}
	}

	.content {
		&--banner .inner {
			margin: 0;
			width: 100%;

			@media (min-width: 1024px) {
				margin: 0 auto;
				width: 96%;
			}
		}

		&--line .inner::after {
			content: '';
			display: block;
			width: calc(100% - 33px);
			height: 1px;
			background-color: #e6e6e6;
			margin: 48px auto 60px;

			@media (min-width: 768px) {
				width: 100%;
				height: 2px;
				margin: 76px auto 55px;
			}
		}

		&:last-child {
			margin-bottom: 56px;
		}
	}

	.banner-block {
		width: 100%;
		height: 100%;
		margin-top: 6px;

		@media (min-width: 768px) {
			margin-top: 41px;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.description {
		position: relative;
		margin-top: 39px;
		text-align: center;

		@media (min-width: 768px) {
			margin-top: 31px;
		}

		p {
			font-size: 16px;
			line-height: 24px;
			margin-top: 10px;
		}

		> p {
			font-size: 15px;

			@media (min-width: 768px) {
				font-size: 16px;
			}
		}

		.heading {
			font-size: 23px;
			line-height: 33px;
			letter-spacing: 0.5px;
			font-weight: bold;

			@media (min-width: 768px) {
				font-size: 24px;
			}
		}

		.icon-block {
			margin-top: 36px;

			@media (max-width: 767px) {
				font-size: 37px;
			}

			.min-heading {
				font-size: 18px;
				line-height: 33px;
			}

			.item-block {
				display: flex;
				justify-content: space-around;
				flex-wrap: wrap;
				margin-top: 24px;
			}

			.item {
				max-width: 280px;

				+ .item {
					margin-left: 20px;
				}

				.icon {
					height: 79px;
					background-repeat: no-repeat;
					background-position: center;
				}

				p {
					line-height: 22px;
				}

				&:nth-child(1) {
					.icon {
						background-image: url("../images/delivery-mileage/icon-1.png")
					}
				}

				&:nth-child(2) {
					.icon {
						background-image: url("../images/delivery-mileage/icon-2.png")
					}
				}

				&:nth-child(3) {
					.icon {
						background-image: url("../images/delivery-mileage/icon-3.png")
					}
				}

				&:nth-child(4) {
					.icon {
						background-image: url("../images/delivery-mileage/icon-4.png")
					}
				}
			}

			@media (max-width: 767px) {
				.item-block {
					margin-top: 0;
					flex-direction: column;
					justify-content: center;
					align-items: center;
				}

				.item {
					display: flex;
					width: 100%;
					margin-top: 34px !important;

					+ .item {
						margin-left: 0;
					}

					.icon {
						width: 100%;
						max-width: 82px;
					}

					p {
						margin-top: 0;
						text-align: left;
						padding: 0 0 0 27px !important;
					}
				}
			}
		}
	}

	.products {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		padding: 0 18px;

		@media (min-width: 768px) {
			margin: 0 -15px;
			padding: 0;
		}
	}

	.product {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 30px;
		border: 1px solid #e6e6e6;
		background-color: #fff;
		position: relative;
		overflow: hidden;
		box-sizing: border-box;

		@media (min-width: 768px) {
			width: calc(50% - 30px);
			margin: 0 15px 30px;
		}

		@media (min-width: 1024px) {
			width: calc(33.3333% - 30px);
		}

		&::before {
			content: '';
			display: block;
			width: 102%;
			height: 195px;
			background-color: #eef3fb;
			transform: skewY(6deg);
			position: absolute;
			top: -42px;
			left: 0;
		}

		&__img {
			height: 200px;
			position: relative;
			padding: 22px 24px 0 12px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&__info {
			padding: 0 20px;
			margin: 18px 0 21px;
			text-align: center;
		}

		&__color {
			font-size: 14px;
			color: #666666;
		}

		&__name {
			font-size: 18px;
			text-transform: uppercase;
			margin: 9px 0 5px;
		}

		&__specification {
			font-size: 16px;
		}

		&__bottom {
			padding: 0 50px 42px;
			text-align: center;
			margin-top: auto;
		}

		&__from-price {
			font-size: 16px;
			color: #666666;
		}

		&__price {
			font-size: 24px;
			line-height: 27px;
			color: #ce171f;
			margin-bottom: 17px;

			span {
				font-size: 16px;
			}
		}

		&__btn {
			font-size: 18px;
			width: 100%;
			height: 46px;
			margin: 0 auto;
			border: 1px solid #124390;
			background-color: #fff;
			color: #124390;
			transition: background-color 0.3s ease, color 0.3s ease;

			@include hover {
				background-color: #124390;
				color: #fff;
			}

			@media (min-width: 1200px) {
				width: 250px;
			}

			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}
}