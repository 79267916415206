.manager-appraisal-page {
	+ .footer {
		background-color: #f6f6f6;
	}

	.form-block {
		margin-top: 60px;
		padding-bottom: 60px;

		.first-block,
		.second-block {
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				display: block;
			}

			&.first-block {
				.rows {
					border-left: 5px solid #ebebeb;
					background-color: #fafafa;
				}
			}

			&.second-block {
				margin-top: 55px;

				.rows {
					border-left: 5px solid #ebebeb;
					background-color: #f3f3f3;
				}
			}

			.rights {
				@media (max-width: 768px) {
					margin-top: 49px;
				}

				.rows {
					+ .rows {
						margin-top: 49px;
					}
				}
			}
		}

		.lefts,
		.rights {
			width: 49%;

			@media (max-width: 768px) {
				width: 100%;
			}
		}

		.rows {
			padding: 10px 15px;
			box-sizing: border-box;

			.item {
				display: flex;
				justify-content: space-between;

				+ .item {
					margin-top: 10px;
				}

				.label {
					display: flex;
					flex-direction: column;
					justify-content: center;
					width: 190px;
					min-width: 190px;
					font-size: 16px;
					font-weight: bold;
				}

				.input-block {
					width: calc(100% - 190px);

					input {
						width: 100%;
						padding: 0 0 5px;
						border: none;
						border-bottom: 1px solid #a5a5a5;
						background-color: transparent;
						font-size: 16px;
					}
				}
			}
		}

		.textarea-block {
			margin-top: 44px;

			label {
				font-weight: bold;
			}
		}

		.btn-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			@media (max-width: 700px) {
				flex-direction: column;
				align-items: center;
			}

			.btn--blue {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 260px;
				height: 46px;
				margin-top: 59px;
				font-family: 'Myriad Pro', 'Vauxhall Neue', sans-serif;
				font-size: 18px;

				@media (max-width: 700px) {
					margin-top: 30px;
				}
			}

			.btn-back {
				width: 190px;

				img {
					margin-right: 17px;
					vertical-align: middle;
				}
			}
		}
	}

	.images-row {
		display: flex;
		justify-content: space-between;
		margin-top: 5px;
		@include flex-row(10px, 10px);

		.image-block {
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing: border-box;
			height: 75px;
			border: 1px solid #a5a5a5;
			@include flex-block(50px, 10px, 10px, true, 4);

			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}
	}
}