.booking-tip {
	&__wrapper {
		padding: 20px 0;
	}

	&__title {
		font-size: 26px;
		line-height: 28px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	&__text {
		font-size: 14px;
		line-height: 17px;
	}
}