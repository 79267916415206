.refund-form {
	margin-bottom: 110px;

	.inner {
		max-width: 560px;
	}

	.form-block {
		.form-title {
			margin-top: 75px;
			margin-bottom: 45px;
			font-size: 24px;
			line-height: 1.3;
			font-weight: bold;
			color: #2c2c2c;
		}

		.input-block {
			margin-bottom: 26px;

			input::placeholder {
				color: #a6a6a6;
			}
		}

		.radiogroup-v2 {
			margin-bottom: 23px;

			.radiogroup-v2__lbl {
				min-width: 194px;

				span {
					text-transform: none;
				}
			}
		}

		.row {
			+ .row {
				margin-top: 10px;
				padding-top: 25px;
				border-top: 1px solid #e6e6e6;
			}

			.row-title {
				font-size: 18px;
				font-weight: bold;
				color: #2c2c2c;
				margin-right: 0;
			}

			.row-text {
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
				color: #4d4d4d;
				margin-bottom: 20px;
			}

			.flex-block {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				.input-block {
					width: calc(50% - 10px);

					@media (max-width: 400px) {
						width: 100%;
					}
				}
			}
		}

		.form-info-text {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: #4d4d4d;
			text-align: center;
			margin-top: 48px;
		}

		.btn {
			width: 266px;
			margin: 27px auto 0;
		}
	}

	.radio-tab {
		&:not(.active) {
			display: none;
		}
	}
}