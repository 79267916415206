.virus-info-page {
	.virus-video-block {
		max-width: 557px;
		width: 100%;
		padding-top: 49px;
		margin-left: 23px;
		flex-shrink: 0;

		.virus-ib-title {
			margin-bottom: 15px;
		}

		.virus-ib--text {
			margin-top: 0;
			margin-bottom: 15px;
		}

		.video {
			position: relative;
			height: 308px;

			@media (max-width: 610px) {
				height: 50vw;
			}

			video,
			iframe {
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 1;
			}

			&__prewiew {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				overflow: hidden;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;

				&:before {
					content: '';
					display: block;
					width: 77px;
					height: 50px;
					background: center / cover url(../images/btn__play.png) no-repeat;
					position: absolute;
					left: 50%;
					top: 50%;
					z-index: 10;
					transform: translateX(-50%) translateY(-50%);
					cursor: pointer;
				}

				&--hidden {
					display: none;
				}

				img {
					display: block;
					width: 100%;
				}
			}
		}
	}

	.inner2 {
		max-width: 1140px;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		@media screen and (max-width: 1000px) {
			flex-direction: column;

			.virus-info-block {
				padding-bottom: 20px;
			}

			.virus-video-block {
				margin-left: 0;
				padding-bottom: 20px;
			}
		}
	}

	.virus-info-block {
		max-width: 100%;
		width: 100%;
		padding-right: 18px;
		padding-top: 49px;
		padding-bottom: 98px;
		margin: 0 auto;
		box-sizing: border-box;
	}

	.virus-ib-title {
		font-size: 24px;
		font-weight: 400;
		line-height: 33px;
	}

	.virus-ib-call {
		padding: 22px 0 22px;
		margin: 16px 0;
		border-bottom: 1px solid #e6e6e6;

		&:last-child {
			margin-bottom: 0;
			margin-top: 0;
		}
	}

	.virus-ib-call-top {
		font-size: 16px;
		line-height: 1.2;
		font-weight: 400;
	}

	.virus-ib-call-bottom {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 12px;

		&__phone {
			display: flex;
			align-items: center;
			margin-right: 6px;
			font-size: 22px;
			line-height: 24px;
			font-weight: 400;
			color: #2c2c2c;

			&::before {
				content: '';
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-right: 7px;
				background-image: url(../img/call-red.svg);
			}
		}

		&__link {
			font-size: 16px;
			line-height: 24px;
			font-weight: 400;
			color: #d7444a;
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}

	.virus-ib--text {
		font-size: 15px;
		line-height: 24px;
		font-weight: 400;
		margin-top: 27px;

		a {
			transition-duration: 0.2s;
			color: #ce181f;
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}
}
