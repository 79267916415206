.my-enquiries-page {
	.inner {
		max-width: 1340px;
	}

	.flex-block {
		display: flex;
		margin-top: 39px;
		margin-bottom: 79px;

		@media (max-width: 1080px) {
			flex-direction: column;
		}

		.left {
			width: 100%;

			> .title {
				font-size: 24px;
				line-height: 33px;
				font-weight: bold;
				margin-bottom: 10px;
			}

			.filter {
				display: flex;

				p {
					font-size: 18px;
					line-height: 28px;
					font-weight: 400;
				}

				.select {
					display: block;
					width: 232px;
					height: 35px;
					margin-left: 16px;
					margin-bottom: 21px;
					padding: 10px;
					padding-right: 40px;
					border-radius: 0;
					border: 1px solid #a5a5a5;
					background: #fff url(../img/select.svg) no-repeat;
					background-position: calc(100% - 15px) center;
					resize: none;
					-webkit-appearance: none;
					-moz-appearance: none;
					outline: 0;
					font-size: 14px;
					line-height: 14px;
					white-space: nowrap;
					color: #a5a5a5;
					position: relative;
					cursor: pointer;
					box-sizing: border-box;

					@include hover {
						border: 1px solid #a5a5a5;
						background: #fff url(../img/select_hov.svg) no-repeat;
						background-position: calc(100% - 15px) center;
						color: #2c2c2c;
					}
				}
			}

			.table-block {
				min-width: 100%;

				@media (max-width: 620px) {
					overflow-x: scroll;
				}
			}

			.table {
				display: table;
				min-width: 100%;
				margin-bottom: 30px;
				border-bottom: 2px solid #e6e6e6;

				.row {
					display: table-row;
					padding: 17px 11px 17px 15px;
					font-size: 14px;

					&:not(.headline):nth-child(even) > div{
						background-color: #f2f2f2;
					}

					&.headline {
						font-size: 16px;
						font-weight: bold;
					}
				}

				.col {
					display: table-cell;
					padding-top: 17px;
					padding-bottom: 17px;
					vertical-align: top;

					&:first-child {
						padding-left: 15px;
					}

					&:last-child {
						padding-right: 11px;
						vertical-align: middle;
					}

					&:not(:last-child) {
						padding-right: 10px;
					}

					.arrow {
						display: block;
						width: 16px;
						height: 13px;
						padding-left: 10px;
						background: url(../img/red_arrow.png) center no-repeat;
						cursor: pointer;
					}
				}
			}

			.back {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 200px;
				height: 38px;
				border: 1px solid #58585a;
				font-size: 14px;
				font-weight: bold;
				line-height: 28px;
				text-transform: uppercase;
				color: #2c2c2c;
				cursor: pointer;
				transition-duration: 0.2s;

				@include hover {
					color: #ffffff;
					background: #2c2c2c;
				}
			}
		}

		.right {
			width: 349px;
			margin-left: 41px;
			flex-shrink: 0;

			@media (max-width: 1080px) {
				width: 100%;
				margin-left: 0;
				margin-top: 36px;
			}

			.chat {
				background-color: #f2f2f2;
				padding: 27px 24px 36px 26px;

				@media (max-width: 620px) {
					padding: 12px 5px 36px 5px;
				}

				> .title {
					margin-bottom: 10px;
					padding-bottom: 10px;
					border-bottom: 1px solid #d7d7d7;
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
				}

				.messages-wrap {
					max-height: 333px;

					.mCSB_scrollTools {
						.mCSB_draggerContainer {
							right: -12px;
						}

						.mCSB_draggerRail {
							width: 2px;
							background-color: #d9d9d9;
							border-radius: 0;
							cursor: pointer;
						}

						.mCSB_dragger_bar {
							width: 4px;
							background-color: #d4141c;
							border-radius: 0;
						}
					}
				}

				.messages {
					display: flex;
					flex-direction: column;

					.messages_message {
						max-width: 254px;
						width: fit-content;
						padding-bottom: 20px;
						font-size: 14px;

						@media (max-width: 1080px) {
							width: fit-content;
							max-width: 70%;
						}

						@media (max-width: 620px) {
							width: fit-content;
							max-width: 95%;
						}

						.title {
							color: #a2a2a2;
							padding-bottom: 10px;

							span {
								font-weight: 600;
							}
						}

						.text {
							background-color: #ffffff;
							padding: 16px 20px 18px 17px;
						}
					}

					.messages_message-manager {
						align-self: flex-start;

						.title {
							span {
								color: #ce171f;
							}
						}
					}

					.messages_message-your {
						align-self: flex-end;

						.title {
							text-align: right;

							span {
								color: #124390;
							}
						}
					}
				}

				.write {
					margin-top: 17px;
					border-top: 1px solid #d7d7d7;

					form {
						padding-top: 4px;
						font-size: 14px;
						line-height: 28px;

						textarea {
							width: 298px;
							height: 88px;
							padding: 16px 17px;
							border: 1px solid #a5a5a5;
							background-color: #ffffff;
							resize: none;
							overflow-y: scroll;

							@media (max-width: 1080px) {
								width: 100%;
							}

							&::placeholder {
								color: #a6a6a6;
							}

							&::-webkit-scrollbar {
								width: 0;
								/* Remove scrollbar space */
								background: transparent;
								/* Optional: just make scrollbar invisible */
								display: none;
							}

							/* Optional: show position indicator in red */
							&::-webkit-scrollbar-thumb {
								background: #FF0000;
							}
						}

						.send-btn {
							width: 161px;
							height: 35px;
							background-color: #124390;
							font-family: "Vauxhall Neue", sans-serif;
							font-size: 16px;
							line-height: 28px;
							font-weight: bold;
							color: #ffffff;
							transition-duration: 0.2s;
							margin-top: 15px;

							@media (max-width: 1080px) {
								width: 100%;
							}

							@include hover {
								filter: brightness(1.2);
							}
						}
					}
				}
			}
		}
	}
}
