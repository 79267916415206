.calc-new {
	max-width: 724px;
	width: 100%;
	border: 1px solid #e6e6e6;
	background-color: #f7f7f7;
	padding: 27px 46px 27px 28px;
	box-sizing: border-box;

	@media (max-width: 1100px) {
		padding: 27px 37px 0 28px;
		margin: 0 auto;
	}

	&__bottom {
		display: flex;
		margin: 34px 0 0;
		position: relative;

		@media (max-width: 1100px) {
			flex-direction: column;
		}
	}

	&__block {
		width: 105%;
		max-width: 310px;

		@media (max-width: 1100px) {
			width: 100%;
			max-width: 100%;
			margin: 0;
		}
	}

	&__ext {
		margin-top: 15px;

		@media (max-width: 1100px) {
			display: none;
		}

		.finance__block {
			.row {
				flex-direction: column;
				margin-top: 15px;

				.blk {
					width: 100%;
				}
			}
		}

		.btn-finance-explained {
			display: block;
			width: 236px;
			height: 30px;
			font-size: 14px;
			line-height: 19px;
			text-transform: none;
			box-sizing: border-box;
		}
	}

	.calc-new-result {
		width: 375px;
		margin-left: 37px;
		margin-right: -75px;
		margin-bottom: -61px;
		border: 1px solid #e6e6e6;
		background-color: #fff;
		padding: 28px 36px 22px 39px;
		box-sizing: border-box;

		@media (max-width: 1100px) {
			max-width: calc(100% + 67px);
			width: calc(100% + 67px);
			margin: 0 0 0 -29px;
		}

		&__title {
			font-size: 20px;
			margin-bottom: 7px;
		}

		&__subtitle {
			font-size: 15px;
			color: #9da4b0;
			margin-bottom: 13px;
		}

		&__sum {
			padding-bottom: 20px;
			border-bottom: 1px solid #e6e6e6;
			margin-bottom: 12px;

			p {
				&:first-child {
					font-size: 15px;
					margin: 0 0 9px;
				}

				&:last-child {
					font-size: 26px;
					margin: 0;

					span {
						font-size: 16px;
						padding-left: 14px;
					}
				}
			}
		}

		table {
			width: 100%;

			td {
				padding: 8px 0;

				&:first-child {
					width: 73%;
					font-size: 14px;
					color: #9da4b0;
				}

				&:last-child {
					width: 27%;
					font-size: 14px;
					text-align: right;
				}
			}
		}
	}

	.input-block {
		margin-bottom: 23px;

		p {
			width: 100%;
			font-size: 16px;
			margin: 0 5px 12px;
		}

		label {
			width: 100%;
			margin-bottom: 0;
		}

		input,
		select {
			margin-top: 4px;
			margin-bottom: 0;
			border-radius: 3px;
		}

		select {
			background: #fff url(../img/select_hov.svg) no-repeat;
			background-position: calc(100% - 15px) center;
		}

		&--radio {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -5px;

			.radio-label {
				width: calc(20% - 10px);
				margin: 0 5px 10px;
				height: 41px;

				@media (max-width: 1100px) {
					width: calc(33.3333% - 10px);
				}

				@media (max-width: 400px) {
					width: calc(50% - 10px);
				}

				span {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					height: 100%;
					padding: 15px;
					border: 1px solid #d9d9d9;
					background-color: #fff;
					border-radius: 3px;
					color: #2c2c2c;
					box-sizing: border-box;

					@media (max-width: 1100px) {
						padding: 10px;
						text-align: center;
					}
				}

				input {
					display: none;

					&:checked + span {
						border-color: #ce181f;
						background-color: #ce181f;
						color: #fff;
					}
				}
			}
		}

		&--big {
			@media (max-width: 1100px) {
				display: none;
			}

			.radio-label {
				width: calc(50% - 10px);
				margin-bottom: 10px;

				&--big {
					width: 100%;
				}
			}
		}

		&--mobile {
			display: none;

			@media (max-width: 1100px) {
				display: flex;
				margin-bottom: 30px;
			}
		}
	}

	&--mobile {
		display: none;
		border: none;
		background-color: #fff;
		padding: 20px 0 0;

		@media (max-width: 1100px) {
			display: block;
		}

		.calc-new__ext {
			display: none;
			margin-top: 0;
			margin-bottom: 20px;
			
			@media (max-width: 1100px) {
				display: block;
			}
		}

		.btn {
			width: 100%;
		}
	}
}