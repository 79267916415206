.part-order-enquiry {
	.content {
		width: 100%;
		max-width: 1140px;
		margin: 0 auto;

		.flex-table {
			margin-top: 30px;
			width: 100%;
			max-width: 100%;

			.row {
				display: flex;
				align-items: center;
				height: 46px;
				color: #2c2c2c;
				font-family: "Vauxhall Neue";
				font-size: 16px;
				font-weight: 400;
				line-height: 23px;
				background-color: #fff;

				&:nth-child(odd) {
					background-color: #f2f2f2;
				}

				.completed {
					color: #999999;
				}

				&.headline {
					color: #2c2c2c;
					font-family: "Vauxhall Neue";
					font-size: 18px;
					font-weight: bold;
					line-height: 28px;
					background-color: #fff;
				}
			}
		}

		&-bg {
			.flex-table {
				.row {
					&:nth-child(odd) {
						background-color: #fff;
					}

					&:nth-child(even) {
						background-color: #f7f7f7;
					}

					&.headline {
						background-color: #f7f7f7;
					}
				}
			}
		}
	}

	.subtitle {
		margin-top: 43px;
		text-align: center;

		@media (max-width: 650px) {
			margin-top: 23px;
			padding: 0 10px;
		}
	}

	.subtitle-thanks {
		font-size: 24px;
		line-height: 33px;

		@media (max-width: 650px) {
			font-size: 20px;
		}
	}

	.subtitle-select {
		font-size: 18px;
		line-height: 33px;
		text-align: center;

		a {
			color: #3b74ce;
		}

		@media (max-width: 650px) {
			font-size: 16px;
		}
	}

	.table-wrapper {
		overflow: auto;
	}

	.flex-table {
		border-bottom: 2px solid #e6e6e6;
	}

	th {
		text-align: left;
	}

	.headline {
		.field {
			font-size: 13px;
			line-height: 16px;
			color: #757575;
			font-weight: 400;
		}
	}

	tbody .field.discount {
		color: #ce181f;
	}

	.row {
		.field.num {
			width: 50px;
			padding-left: 12px;
		}

		.field.description{
			width: 362px;
		}

		.field.price {
			width: 94px;
		}

		.field.discount {
			width: 94px;
		}

		.field.selling-price {
			width: 105px;
		}

		.field.vat {
			width: 95px;
		}

		.field.stock {
			width: 168px;

			.in-stock {
				color: #3b74ce;
			}

			span {
				font-size: 14px;
				line-height: 42px;
				color: #a6a6a6;
			}
		}

		.field.button {
			width: 146px;
		}
	}

	.btn-add,
	.btn-added {
		position: relative;
		padding: 10px;
		width: 135px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 30px;
		font-size: 16px;
		line-height: 28px;
		font-weight: 400;
		box-sizing: border-box;

		&::before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			background: url(../img/plus.svg) no-repeat center center;
			background-size: contain;
			margin-right: 5px;
		}
	}

	.btn-added {
		display: none;

		&:hover {
			background-color: #124390 !important;
			color: #fff !important;
			cursor: auto;
		}

		&::before {
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			background: url(../img/checked-white.svg) no-repeat center center;
			background-size: contain;
			margin-right: 5px;
		}
	}

	.total {
		text-align: center;
		margin-top: 46px;

		@media (max-width: 650px) {
			margin-top: 26px;
		}
	}

	.total-vat,
	.total-price {
		display: inline-block;
		font-size: 16px;
		line-height: 42px;

		span {
			font-size: 20px;
			font-weight: bold;
		}
	}

	.total-price {
		margin-left: 20px;
		color: #ce181f;
	}

	.btn-continue {
		font-size: 18px;
		line-height: 28px;
		font-weight: bold;
		margin: 25px auto 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 350px;

		&::after {
			content: '';
			display: block;
			width: 25px;
			height: 25px;
			background: url(../img/arrow-white.svg) no-repeat center center;
			background-size: contain;
			margin-left: 10px;
		}

		@media (max-width: 650px) {
			width: 250px;
			margin-bottom: 40px;
		}
	}
}