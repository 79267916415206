.progress-bar {
	position: relative;
	margin-top: 60px;

	ul {
		display: flex;
		justify-content: space-between;
		position: relative;

		li {
			width: 20%;
			font-size: 14px;
			line-height: 17px;
			color: #9da4b0;

			@media (max-width: 768px) {
				padding-bottom: 56px;
			}

			.line-block {
				height: 3px;
				background: #e6e6e6;
				position: relative;

				.line-block-end-point {
					height: 100%;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 15px;

					&:after {
						content: '';
						width: 30px;
						height: 30px;
						border: 2px solid #e6e6e6;
						border-radius: 50%;
						background: #fff;
						box-sizing: border-box;
						position: absolute;
						right: -15px;
						top: -15px;

						@media (max-width: 768px) {
							width: 16px;
							height: 16px;
							top: -8px;
							background-size: 10px auto !important;
						}
					}
				}
			}

			.line-text-blue {
				display: none;
				color: #3b74ce;
			}

			.line-text-red,
			.line-text-blue {
				margin-top: 11px;
				margin-right: 30px;
				text-align: center;

				@media (max-width: 768px) {
					font-size: 10px;
					margin-top: 5px;
					margin-right: 18px;
				}
			}

			.btn {
				display: none;
				width: auto;
				margin-left: 0;
				margin-right: 30px;
				margin-top: 30px;

				@media (max-width: 768px) {
					position: absolute;
					left: 0;
					right: 0;
					margin-right: 0;
				}
			}

			&.edit-block {
				.btn {
					display: block;
				}

				.line-text-red {
					display: block;
					color: #ce181f;
				}

				.line-block {
					background: #ce181f;

					.line-block-end-point {
						&:after {
							border: 1px solid #ce181f;
							box-shadow: 0 1px 5px 0 rgba(23, 54, 67, 0.35);
						}

						&:before {
							content: '';
							width: 12px;
							height: 12px;
							margin-top: -7px;
							border-radius: 50%;
							background: #ce181f;
							position: absolute;
							top: 50%;
							right: -6px;
							z-index: 1;

							@media (max-width: 768px) {
								width: 6px;
								height: 6px;
								right: -10px;
								margin-top: -5px;
							}
						}
					}
				}
			}

			&.saved-block {
				.line-text-blue {
					display: block;
					color: #3b74ce;
				}

				.line-text-red {
					display: none;
				}

				.line-block {
					background: #3b74ce;

					.line-block-end-point {
						&:after {
							border: 1px solid #3b74ce;
							background: #3b74ce url("../img/progress-bar-chk.svg") no-repeat center center;
						}
					}
				}
			}

			&.apply_finance {
				display: none;
			}
		}
	}

	&.style-finance {
		li {
			width: calc(100% / 6);

			&.apply_finance {
				display: block;
			}
		}
	}
}
