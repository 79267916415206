.personalise {
	background: none;

	&__inner {
		padding: 35px 0 90px;
		box-sizing: border-box;
	}

	&__row {
		display: flex;
		margin-bottom: 10px !important;
	}

	&__title {
		margin-bottom: 35px;
		font-size: 24px;
		line-height: 29px;
		font-weight: bold;
		text-align: center;
	}

	&__left-side {
		width: 50%;
		flex-shrink: 0;
		margin-right: 40px;
		margin-bottom: 20px;
		position: relative;
		box-sizing: border-box;
	}

	&__right-side {
		width: 100%;
	}

	&__button {
		width: 100%;
		margin-top: 33px;
		font-size: 16px;
		font-weight: 700;
	}

	&__zag {
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 2px solid #e4e4e4;
		font-size: 18px;
		line-height: 21px;
	}

	.input-block {
		label {
			width: 100%;
		}

		input,
		select {
			margin-top: 5px;
		}

		select {
			background: #fff url(../img/select_hov.svg) no-repeat;
			background-position: calc(100% - 15px) center;
		}
	}
}
