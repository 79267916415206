.sales-manager {
	.table {
		.row {
			display: flex;
			padding: 12px 14px 12px 9px;

			&:nth-child(odd) {
				background-color: #f2f2f2;
			}
		}
	}

	.flex-block {
		display: flex;
		justify-content: space-between;
		max-width: 1140px;
		width: 100%;
		margin: 0 auto;
		margin-top: 33px;

		@media (max-width: 1200px) {
			flex-direction: column;
			justify-content: flex-start;
			max-width: calc(100% - 30px);
			width: 100%;
			margin: 0 auto;
			margin-top: 33px;
		}

		> .left {
			width: 349px;

			@media (max-width: 1200px) {
				width: 100%;
			}

			> .title {
				font-size: 25px;
				line-height: 33px;
				font-weight: bold;
				margin-bottom: 4px;
			}

			.block {
				margin-bottom: 19px;

				> .title {
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
				}

				.table-vehicle {
					margin-top: 1px;

					.row {
						justify-content: space-between;

						.left {
							display: flex;
							flex-direction: column;
							justify-content: space-between;

							.type {
								font-family: "Vauxhall Neue", sans-serif;
								font-size: 13px;
								line-height: 10px;
								font-weight: 400;
								color: #757575;
							}

							.name {
								line-height: 18px;
								text-decoration: underline;
							}
						}

						.right {
							display: flex;
							align-items: flex-end;
							justify-content: flex-end;
							height: 32px;

							@media (max-width: 1200px) {
								justify-content: flex-start;
							}

							.price {
								font-family: "Vauxhall Neue", sans-serif;
								font-size: 16px;
								font-weight: bold;
								color: #2c2c2c;
							}
						}
					}
				}

				.table-trade {
					.row {
						.left,
						.right {
							width: 50%;
						}

						.parameter {
							font-size: 13px;
							line-height: 15px;
							font-weight: 400;
							color: #757575;
							margin-bottom: 10px;
						}

						.value {
							line-height: 11px;
							text-transform: uppercase;
						}

						&.summary {
							.left {
								width: 100%;
							}
						}
					}
				}

				.table-other {
					.left {
						flex-grow: 1;
					}
				}
			}
		}

		> .right {
			width: calc(100% - 389px);
			margin-left: 15px;

			@media (max-width: 1200px) {
				width: 100%;
				margin-left: 0;
			}

			.section {
				padding-bottom: 28px;
				margin-bottom: 29px;

				&:not(:last-child) {
					border-bottom: 1px solid #e6e6e6;
				}

				> .title {
					font-size: 24px;
					line-height: 33px;
					font-weight: bold;
				}

				.offered-price {
					.estimated-profit {
						display: block;
						width: 50%;
					}

					label {
						font-size: 16px;
					}

					.flex-wrap {
						display: flex;
						align-items: center;
						justify-content: flex-start;
						margin-top: 15px;

						@media (max-width: 550px) {
							flex-wrap: wrap;
						}

						input {
							width: 362px;
							height: 44px;
							border: 1px solid #a5a5a5;
							background-color: #ffffff;
							font-size: 16px;
							padding-left: 19px;

							@media (max-width: 550px) {
								width: 100%;
								padding-left: 10px;
							}
						}

						span {
							margin-left: 29px;

							@media (max-width: 550px) {
								margin-top: 10px;
							}

							b {
								font-size: 18px;
							}
						}
					}

					.price {
						margin-top: 28px;
						font-size: 18px;

						.red {
							margin-left: 11px;
							font-size: 24px;
							font-weight: bold;
							color: #ce171f;
						}
					}
				}

				.table-added {
					margin-top: 15px;

					.row {
						input[type=checkbox] {
							display: none;

							+ label::before {
								content: '';
								display: inline-block;
								width: 20px;
								height: 20px;
								margin: -5px 5px 0 0;
								border: 1px solid #a9a9a9;
								background-color: #ffffff;
								line-height: 22px;
								text-align: center;
								vertical-align: middle;
								transition: color 0.3s ease;
							}

							&:checked + label::before {
								background: url(../img/checked.svg) center;
							}

							&[disabled] + label {
								&::before {
									border: 1px solid #d9d9d9;
								}

								+ span {
									margin-right: 10px;

									&,
									+ span + span {
										color: #999999;
									}
								}
							}
						}

						label {
							cursor: pointer;

							+ span {
								flex-grow: 1;
							}
						}
					}
				}

				.modal-openers {
					display: flex;
					width: 348px;
					margin-bottom: 25px;
					margin-top: 14px;

					@media (max-width: 550px) {
						display: block;
						width: 100%;
					}

					.btn-pcp {
						margin-right: 19px;

						@media (max-width: 550px) {
							margin-right: 0;
							margin-bottom: 10px;
							margin-left: 0;
						}
					}

					.btn-pcp,
					.btn-hp {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 165px;
						height: 44px;
						border: 1px solid #ce181f;
						box-sizing: border-box;
						font-size: 16px;
						font-weight: bold;
						color: #2c2c2c;

						@media (max-width: 550px) {
							width: 100%;
						}

						@include hover {
							border: 0;
							background-color: #ce181f;
							color: #ffffff;
						}
					}
				}

				.table-summary {
					margin-bottom: 31px;
					border-bottom: 1px solid #f2f2f2;
					padding-bottom: 0;

					&.tab-bg-odd .row {
						&:nth-child(even) {
							background-color: unset;
						}

						&:nth-child(odd) {
							background-color: #f2f2f2;
						}
					}

					.row {
						align-items: center;

						.col {
							&:not(:last-child) {
								margin-right: 10px;
							}

							&:nth-child(1) {
								width: calc(55.6927297668038% - 10px);
							}

							&:nth-child(2) {
								width: calc(24.0054869684499% - 10px);
							}

							&:nth-child(3) {
								width: calc(20.3017832647462%);
							}
						}
					}
				}
			}

			.btn-block {
				display: flex;
				width: fit-content;
				margin: 0 auto;
				padding-top: 20px;

				.cbtn {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 267px;
					height: 46px;
					background-color: #124390;
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
					color: #ffffff;
					transition-duration: 0.2s;

					&:nth-child(1) {
						margin-right: 24px;
					}

					@include hover {
						filter: brightness(1.1);
					}
				}
			}
		}
	}
}
