.car-handover {
	padding-bottom: 80px;

	.chp-inner {
		width: 96%;
		max-width: 608px;
		margin: 0 auto;
	}

	.chp-h2 {
		margin-top: 80px;
		font-size: 24px;
		line-height: 33px;
		font-weight: bold;
		text-align: center;
		color: #2c2c2c;
	}

	.chp-form {
		margin-top: 40px;
	}

	.chp-block-title {
		width: 100%;
		padding: 9px 25px 8px;
		background-color: #ce171f;
		font-size: 18px;
		line-height: 1.3;
		font-weight: bold;
		color: #ffffff;
		box-sizing: border-box;
	}

	.chp-block-content {
		width: 100%;
		padding: 25px 25px 40px;
		box-sizing: border-box;
	}

	.checkbox-customizedv2-wrap{
		.checkbox-customizedv2 {
			+ input + span {
				display: block;
				padding-left: 30px;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 15px;
				line-height: 30px;
				font-weight: 400;
				color: #2c2c2c;
				cursor: pointer;
				position: relative;

				&::after {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid #a9a9a9;
					background-color: #fff;
					background-size: contain;
					position: absolute;
					top: 3px;
					left: -1px;
					cursor: pointer;
					transition: opacity 0.1s;
				}

				&::before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					opacity: 0;
					background: url(../img/checked.svg) no-repeat center center;
					background-size: contain;
					position: absolute;
					top: 4px;
					left: 0;
					z-index: 1;
					cursor: pointer;
					transition: opacity 0.1s;
				}
			}

			&:checked + input + span::before {
				opacity: 1;
			}
		}
	}

	.chp-input-row {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: -25px;

		.input-block {
			width: calc(50% - 11px);
			margin-bottom: 25px;

			@media (max-width: 550px) {
				width: 100%;
			}
		}
	}

	.chp-checkbox-row {
		margin-bottom: -25px;
		columns: 2;
		column-gap: 22px;

		@media (max-width: 550px) {
			columns: 1;
		}
	}

	.chp-last-block {
		padding-top: 30px;
		border-top: 1px solid #e6e6e6;

		.input-block {
			margin-bottom: 20px;
		}
	}

	.chp-new-quote-request {
		padding-bottom: 50px;
	}

	.new-quote-request.chp-new-quote-request .thumbs {
		.thumb,
		.upload-more {
			width: 188px;
			height: 132px;
		}
	}

	.chp-images-block {
		p {
			color: #4d4d4d;
		}
	}

	.chp-retailer {
		&__r {
			font-size: 13px;
			line-height: 28px;
			font-weight: 400;
			color: #808080;
		}
	}

	.chp-retailer-p {
		font-size: 14px;
		line-height: 1.2;
		color: #2c2c2c;

		span {
			padding-right: 15px;
		}
	}

	.btn-chp-signature {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 266px;
		margin: 35px auto 0 !important;
		font-weight: bold;
		font-size: 18px;
		box-sizing: border-box;
	}

	.chp-custom-radios {
		display: block;

		label {
			display: flex;
			margin-top: 25px;
			cursor: pointer;

			input {
				min-width: 15px;
			}
		}
	}
}
