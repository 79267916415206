.new-car-config {
	padding-bottom: 40px;

	#sync2 {
		width: 93%;
		margin: 0 auto;

		@media (max-width: 767px) {
			width: 85%;
		}

		@media (max-width: 550px) {
			width: 78%;
		}

		.owl-nav {
			display: block;
			width: calc(100% + 80px);
			margin: 0 -40px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media (max-width: 900px) {
				width: calc(100% + 60px);
				margin: 0 -30px;
			}
		}

		.owl-prev,
		.owl-next {
			background: none;
			width: 40px;
			height: 50px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;

			svg {
				width: 100%;
				height: 100%;
				fill: #ce171f;
			}
		}

		.owl-prev {
			left: 0;

			@media (max-width: 900px) {
				left: -12px !important;
			}
		}

		.owl-next {
			right: 0;

			@media (max-width: 900px) {
				right: -12px;
			}
		}
	}

	.flex-block .content .block {
		border-bottom: 0;
		padding-bottom: 20px;
	}

	.picture-parameters {
		border-bottom: 1px solid #E6E6E6;
		padding-bottom: 31px;
	}

	.card {
		margin-bottom: 20px;
		margin-top: 31px;
		position: sticky;
		z-index: 2;
		top: 100px;

		.ah1 {
			font-size: 13px;
			line-height: 16px;
			color: #757575;
		}

		table {
			width: 100%;
			margin-bottom: 10px;

			td {
				font-size: 14px;
				padding: 5px 0;

				&:last-child {
					width: 70px;
					font-weight: 700;
					text-align: right;
				}
			}
		}

		.total {
			border-top: 1px solid #d7d7d7;
			margin-top: 5px;
			padding-top: 5px;
		}
	}

	.card-inner {
		border: 1px solid #d9d9d9;
		background-color: #f6f6f6;
	}

	.card-header {
		background: 0 0;
		border-radius: 0;
		border-bottom: none;
		padding: 34px 35px 14px;
		margin-bottom: 0;
		font-size: 18px;
		font-weight: 700;
		letter-spacing: 0.5px;
	}

	.card-body {
		padding: 0 35px 35px;

		p {
			font-size: 14px;
			line-height: 22px;
			max-width: 240px;
			margin-bottom: 20px;
		}
	}

	.button-block {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-top: 20px;

		.btn {
			background: #124390;
			text-transform: uppercase;
			font-weight: 400;
			width: 100%;
			color: #fff;
			font-size: 16px;
			margin-bottom: 10px;
			border-radius: 5px;

			&:nth-child(2),
			&:nth-child(3) {
				width: calc(50% - 5px);
			}

			@include hover {
				background: #2c2c2c;
				color: #fff;
			}
		}
	}

	.relative_block {
		display: flex;
		justify-content: space-between;
		padding-bottom: 10px;
		border-bottom: 1px solid #e1e1e1;
		position: relative;

		@media (max-width: 999px) {
			flex-direction: column-reverse;
		}

		.left {
			width: 66%;
			position: relative;

			@media (max-width: 999px) {
				width: auto;
			}

			.gal_none {
				display: none;
			}

			.fancybox {
				width: 40px;
				height: 40px;
				border-radius: 3px;
				position: absolute;
				right: 50px;
				top: 500px;
				z-index: 1;
				background: url(../img/controls/3.svg) no-repeat center center rgba(0, 0, 0, .3);

				@include hover {
					background-color: rgba(0, 0, 0, 0.2);
				}

				@media (max-width: 999px) {
					top: 400px;
				}

				@media (max-width: 500px) {
					top: 200px;
				}
			}

			.big-images {
				height: 567px;
				margin-right: 41px;
				border: none !important;
				position: relative;
				overflow: hidden;

				@media (max-width: 999px) {
					height: 450px;
				}

				@media (max-width: 500px) {
					height: 250px;
					margin-right: 0;
				}

				.owl-prev {
					display: none;
				}

				.owl-next {
					display: none;
				}

				.item {
					height: 567px;
					position: relative;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;

					@media (max-width: 999px) {
						height: 450px;
					}

					@media (max-width: 500px) {
						height: 250px;
					}
				}
			}

			> h4 {
				margin-top: 40px;
				margin-bottom: 8px;
				font-size: 18px;
				font-weight: bold;
				color: #2c2c2c;

				@media (max-width: 500px) {
					margin-top: 20px;
				}
			}

			.thumbs {
				position: relative;
				box-sizing: border-box;

				.item {
					width: 69px;
					height: 67px;
					background-size: cover;
					background-position: center center;
					position: relative;
					cursor: pointer;

					@media (max-width: 500px) {
						width: 40px;
						height: 40px;
					}

					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						box-sizing: border-box;
						border: 2px solid #ffffff;
						opacity: 0.3;
					}

					&:after {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						background-color: #000000;
						opacity: 0.1;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						box-sizing: border-box;
						clip-path: polygon(100% 0, 0% 100%, 100% 100%);
					}
				}

				.current {
					.item {
						&:before {
							content: '';
							position: absolute;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							box-sizing: border-box;
							border: 2px solid #ce181f;
						}
					}
				}
			}

			.description {
				margin: 34px 16px 0 0;
				border-top: 1px solid #e1e1e1;
				padding-top: 35px;

				@media (max-width: 999px) {
					display: none;
				}

				h4 {
					margin-bottom: 28px;
					font-size: 24px;
					font-weight: bold;
					color: #2c2c2c;
				}

				.picture-parameters {
					.row {
						display: flex;
						justify-content: space-between;
						flex-wrap: wrap;
					}

					.item {
						width: 90px;
						margin-bottom: 37px;
						text-align: center;

						.text {
							margin-bottom: 15px;
						}
					}
				}

			}

			.card,
			.btn_red-mobile,
			.button-block {
				display: none;
			}

			.button-block {
				@media (max-width: 999px) {
					display: flex;
					margin-bottom: 15px;
				}
			}

			.card {
				@media (max-width: 999px) {
					display: block;
					position: static;
				}
			}

			.btn_red-mobile {
				@media (max-width: 999px) {
					display: block;
					width: 100%;
					background: #ce171f;
					margin-bottom:10px;
					border-radius: 5px;
					font-size: 18px;
					font-weight: 600;
					color: #fff;
				}
			}
		}

		.text_block {
			width: 30.5%;
			margin-top: 11px;

			@media (max-width: 999px) {
				width: auto;
			}

			.card {
				@media (max-width: 999px) {
					display: none;
				}
			}

			.model {
				font-size: 28px;
				font-weight: bold;
				color: #2c2c2c;
				text-transform: uppercase;
			}

			.price {
				margin-top: 13px;
				font-size: 24px;
				font-weight: 400;
				color: #2c2c2c;

				span {
					font-size: 14px;
					font-weight: 400;
					color: #2c2c2c;
					text-transform: uppercase;
				}
			}

			.hr {
				height: 0;
				margin: 12px 0;
				border-top: 1px solid #e1e1e1;
			}

			.config {
				.flex-row {
					display: flex;
					justify-content: space-between;

					.config-input {
						max-width: 49%;
						width: 100%;
					}
				}

				.config-input {
					display: flex;
					justify-content: space-between;
					align-items: center;
					flex-grow: 1;

					input[type="radio"] {
						display: none;
					}

					.button {
						width: auto;
						flex-grow: 1;
						border-radius: 5px;
					}

					.hit {
						display: block;
						width: 20px;
						height: 20px;
						flex-shrink: 0;
						margin-left: 9px;
						background: url("../img/help-icon.png") center center no-repeat;
						background-size: 100% 100%;
						cursor: pointer;
					}
				}
			}

			.moblie-left {
				@media (max-width: 999px) {
					text-align: center;
				}
			}
		}

		.right {
			@media (max-width: 999px) {
				width: auto;
			}

			.card {
				margin-top: 50px;
				margin-bottom: 15px;
				background-color: #f6f6f6;

				@media (max-width: 999px) {
					margin-top: 0;
				}

				.card-header {
					padding: 34px 35px 14px;
					margin-bottom: 0;
					background: 0 0;
					border-radius: 0;
					border-bottom: none;
					font-size: 18px;
					font-weight: 700;
				}

				.card-body {
					padding: 0 35px 35px;
				}

				.ah1 {
					padding-top: 5px;
				}

				table {
					width: 100%;

					td {
						padding: 7px 0;
					}

					tr td:last-child {
						width: 70px;
						font-weight: 700;
						text-align: right;
					}
				}

				.ah1 {
					padding-top: 5px;
					font-size: 13px;
					line-height: 16px;
					color: #757575;
				}

				.total {
					border-top: 1px solid #d7d7d7;
					margin-top: 5px;
					padding-top: 5px;
				}
			}
		}
	}

	.custom-slider {
		.slider-desc {
			.left,
			.right {
				margin-bottom: 30px;
				color: #808080;
			}
		}
	}

	.build-your-deal {
		padding-bottom: 0;

		.flex-block {
			padding-top: 20px;
		}
	}

	.personalise {
		border-top: none;

		&__row .personalise__left-side {
			@media (max-width: 900px) {
				margin-right: 0;
				margin-top: 20px;
			}
		}

		.radiogroup--info {
			margin-top: 0;
		}
	}

	.button-calc {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 46px;
		border-radius: 5px;
		font-size: 16px;
		line-height: 17px;
		padding: 0 10px;
		margin-top: 18px;

		svg {
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-right: 10px;
		}

		@media (max-width: 999px) {
			margin-bottom: 25px;
		}
	}

	.car-info {
		flex-direction: column;
		margin-top: 20px;
		padding-top: 20px;
		padding-bottom: 20px;
		border-top: 1px solid #e1e1e1;
		border-bottom: 1px solid #d9d9d9;

		@media (max-width: 900px) {
			padding-bottom: 20px;
		}

		&__flex {
			width: 100%;
			flex-wrap: wrap;
		}

		&__title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			color: #2c2c2c;
			font-size: 24px;
			font-weight: 400;
			line-height: 28px;

			@media (max-width: 900px) {
				font-size: 20px;
				margin-bottom: 0;
			}
		}

		&__flex {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			margin-top: 10px;
		}

		&__option-item {
			display: flex;
			align-items: center;
			width: 25%;
			margin-bottom: 28px;

			@media (max-width: 900px) {
				width: 48%;
			}
		}

		&__text {
			margin-left: 10px;

			p {
				&:first-child {
					font-size: 13px;
					line-height: 18px;
					color: #999999;
				}

				&:last-child {
					font-size: 15px;
					line-height: 18px;
					color: #2c2c2c;
				}
			}
		}
	}

	.toggle-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 31px;
		height: 31px;
		border-radius: 5px;
		background-color: #ce171f;
		margin-right: 18px;
		cursor: pointer;

		&.active {
			svg {
				transform: rotate(180deg);
			}
		}

		svg {
			fill: #fff;
			transition: transform 0.3s ease;
		}
	}

	.radiogroup-v2 {
		flex-direction: column;
		margin: 22px 0 0;

		.radiogroup-v2__lbl {
			width: 100%;
			margin: 0 0 15px;

			span {
				border-radius: 5px;
			}
		}
	}

	.check-holyday {
		margin: 0;

		.checkbox-customizedv2 + span::after {
			border-radius: 3px;
		}
	}

	.personalise__ext {
		margin-top: 30px;

		.btn {
			display: block;
			height: 30px;
			font-size: 14px;
			line-height: 16px;
			box-sizing: border-box;
			border-radius: 5px;
			
			@media (max-width: 999px) {
				display: flex;
				justify-content: center;
				align-items: center;
				height: 46px;
			}
		}

		.finance__block .row {
			flex-direction: column;

			.blk {
				width: 100%;
			}
		}
	}

	.ncc-blck {
		border-top: 1px solid #e1e1e1;
		border-bottom: 1px solid #e1e1e1;
		padding-top: 23px;
		padding-bottom: 23px;
		margin-bottom: 28px;

		.personalise__row {
			@media (max-width: 900px) {
				flex-direction: column-reverse;
			}

			.personalise__left-side {
				@media (max-width: 900px) {
					margin-right: 0;
					margin-top: 20px;
					width: 100%;
				}
			}

			.radios-and-hint-right {
				@media (max-width: 900px) {
					justify-content: center;
				}
			}
		}
	}

	.ncc-title {
		font-size: 24px !important;
		line-height: 28px;
		font-weight: 400 !important;
		color: #2c2c2c;

		@media (max-width: 900px) {
			text-align: center;
			display: block;
		}
	}

	.ncc-item {
		font-size: 18px;
		line-height: 28px;

		@media (max-width: 900px) {
			text-align: center;
			display: block;
		}
	}
}

.ncc-r-total {
	width: 100%;
	font-size: 16px;
	line-height: 42px;
	font-weight: 400;
	color: #2c2c2c;
	text-align: right;

	span {
		font-size: 18px;
		margin-left: 19px;
		padding-right: 15px;
	}
}

.ncc-total-pers {
	color: #2c2c2c;
	font-size: 15px;
	font-weight: 400;
	line-height: 16px;
	margin-bottom: 10px;

	span {
		color: #ce181f;
		font-size: 18px;
		font-weight: 400;
		line-height: 28px;
		margin-left: 10px;
	}
}

.btn-sve-ncc {
	background-color: #124390;
	color: #ffffff;
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
	width: 350px;
	max-width: 100%;
	height: 46px;

	&:hover {
		background: #2c2c2c;

	}
}

.config-headline {
	color: #2c2c2c;
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
	margin-bottom: -12px;
}

.config-input--radio {
	margin: 14px 0;

	input + label {
		display: flex;
		align-items: center;
		justify-content: center;
		text-transform: none;
		font-size: 16px;
	}

	input:not(:checked) + label {
		box-sizing: border-box;
		border: 1px solid #ce181f;
		color: #2c2c2c;
		background: #ffffff;
		cursor: pointer;

		&:hover {
			background-color: lighten($color: #ce181f, $amount: 25);
		}
	}

	input:checked + label {
		pointer-events: none;
	}
}

form.config {
	position: relative;
}

.hit-desc-config {
	margin-top: 30px;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #e6e6e6;
	padding: 31px 36px 44px 29px;
	background: #fff;
	z-index: 1;
	&.active {
		display: block;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #2c2c2c;
		font-size: 24px;
		font-weight: 400;
		line-height: 33px;
		margin-bottom: 5px;
	}

	&__text {
		color: #4d4d4d;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		margin-bottom: 10px;
	}

	&__text-item {
		display: block;
		padding-left: 17px;
		position: relative;
		line-height: 22px;
		font-size: 14px;
		&:before {
			content: "";
			display:block;
			width: 5px;
			height: 5px;
			background-image: url("../img/red_square.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			position: absolute;
			left: 0;
			top: 8px;
		}
	}

	&__compare {
		color: #ce181f;
		font-size: 14px;
		font-weight: 400;
		line-height: 28px;
		text-decoration: underline;
		line-height: 1.3;

		&:hover {
			text-decoration: none;
		}

		&:active {
		}

		&:focus {
		}
	}
}

.title-cpb {
	color: #2c2c2c;
	font-size: 24px;
	font-weight: bold;
	line-height: 33px;
}

.save-config-btn {
	color: #ffffff;
	font-size: 18px;
	font-weight: bold;
	line-height: 28px;
	height: 46px;
	background-color: #124390;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
	cursor: pointer;

	&:hover {
		opacity: .8;
	}
}

.relative_block:last-child {
	border-bottom: none;
}
