.footer {
	position: relative;
	z-index: 10;
	background-color: #fff;

	&__row {
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transition: opacity 0.2s linear;
	}

	&__social-links {
		display: flex;
		position: fixed;
		bottom: 20px;
		left: 20px;
	}

	&__link {
		width: 27px;
		height: 36px;
		background-color: #ce181f;
		background-position: center;
		background-repeat: no-repeat;
		transition: 0.3s;

		@include hover {
			background-color: #841215;
		}

		&:last-child {
			width: 41px;
			border-top-right-radius: 18px;
			border-bottom-right-radius: 18px;
		}

		&:first-child {
			width: 41px;
			border-top-left-radius: 18px;
			border-bottom-left-radius: 18px;
		}
	}

	.inner {
		max-width: 1067px;
	}

	.footer-cols {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-between;

		.col {
			width: calc((100% - 69px) / 4);

			@media (max-width: 900px) {
				width: calc(50% - 13px);
				margin-bottom: 43px;
			}

			@media all and (max-width: 480px) {
				width: 100%;
				text-align: center;
			}

			.logo-f {
				a {
					display: inline-block;
					width: 160px;
					max-width: 100%;

					img {
						display: block;
						max-width: 100%;
					}
				}
			}

			.soc-links {
				display: flex;
				align-items: flex-start;
				margin-top: 15px;

				@media (max-width: 480px) {
					justify-content: center;
				}

				a {
					display: block;
					width: 26px;
					height: 26px;
					transition: transform 0.2s linear;
					border-radius: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: contain;

					+ a {
						margin-left: 12px;
					}

					&.fb {
						background-image: url("../img/social/f-fb.png");
					}

					&.gg {
						background-image: url("../img/social/f-gg.png");
					}

					&.tw {
						background-image: url("../img/social/f-tw.png");
					}

					@include hover {
						transform: scale(1.2);
					}
				}
			}

			.text {
				max-width: 250px;
				margin-top: 19px;
				color: #4d4d4d;
				font-weight: 400;
				font-size: 13px;
				line-height: 20px;

				@media (max-width: 480px) {
					margin: 22px auto 0;
				}

				p {
					+ p {
						margin-top: 0.8em;
					}
				}
			}

			.menu-block {
				+ .menu-block {
					margin-top: 43px;
				}

				.title {
					padding-bottom: 14px;
					border-bottom: 2px solid #bfbfbf;
					font-family: 'Myriad Pro', 'Vauxhall Neue', sans-serif;
					font-size: 15px;
					font-weight: 400;
					color: #2c2c2c;
				}

				ul.links {
					margin-top: 16px;
					list-style: none;

					li {
						display: block;

						+ li {
							margin-top: 13px;
						}

						a {
							color: #4d4d4d;
							font-weight: 400;
							font-size: 14px;

							@include hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}

	.footer-row-links {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 58px;

		@media (max-width: 900px) {
			justify-content: center;
		}

		a {
			position: relative;
			display: inline-block;
			margin-bottom: 15px;
			text-decoration: underline;
			color: #2c2c2c;
			font-weight: 400;
			font-size: 14px;

			@include hover {
				text-decoration: none;
			}

			&:not(:last-child)::after {
				content: "";
				display: block;
				width: 1px;
				height: 16px;
				background-color: #808080;
				position: absolute;
				top: 0;
				right: -8px;
			}

			+ a {
				margin-left: 16px;
			}
		}
	}

	.footer-text {
		margin-top: 21px;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: #4d4d4d;
	}

	.footer-copy-line {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 60px;
		margin-top: 65px;
		background-color: #ececec;

		.text {
			text-align: center;
			color: #2c2c2c;
			font-weight: 400;
			font-size: 14px;
		}
	}

	&.gray_footer {
		background: #f6f6f6;
	}
}