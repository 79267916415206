.accordion {
	&__desc,
	&__tabs-title,
	&__list-item {
		font-size: 13px;
		line-height: 16px;
	}

	&__title {
		font-size: 18px;
		font-weight: bold;
		color: #2c2c2c;

		&--top {
			margin-bottom: 8px;
		}
	}

	&__hint {
		font-size: 12px;
		font-weight: normal;
		color: #808080;
		margin-left: 8px;
	}

	&__zag {
		font-size: 14px;
		line-height: 32px;
		font-weight: bold;
		letter-spacing: 0.5px;
		color: #fff;
		text-align: center;
		height: 32px;
		padding: 0 !important;
		margin-top: 6px;
		background-color: #b72126 !important;
		border: none;
		border-radius: 0;
		cursor: pointer;
		transition: 0.3s;
	}

	&__body {
		padding: 22px 0 0 !important;
		border-left: 1px solid #bfbfbf;
		border-bottom: 1px solid #bfbfbf;
		border-right: 1px solid #bfbfbf;
		box-sizing: border-box;
	}

	&__phone-link {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 17px;
		line-height: 20px;
		font-weight: 700;
		color: #2c2c2c;
	}

	&__desc {
		padding: 0 20px;
		margin-top: 18px;
		box-sizing: border-box;
		color: #2c2c2c;

		a.onmap {
			display: block;
			font-size: 18px;
			color: rgb(0, 0, 238);
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}

	&__tabs {
		margin-top: 18px;
		border-top: 1px solid #bfbfbf;
		box-sizing: border-box;
	}

	&__tabs-header {
		display: flex;
	}

	&__tabs-title {
		width: 100%;
		height: 31px;
		line-height: 31px;
		font-weight: bold;
		text-align: center;
		background-color: #e6e6e6;
		border-bottom: 1px solid #bfbfbf;
		box-sizing: border-box;
		cursor: pointer;

		&:not(:last-child) {
			border-right: 1px solid #bfbfbf;
		}

		&.js-active {
			background-color: #fff;
			border-bottom: none;
		}
	}

	&__tabs-body {
		width: 0;
		height: 0;
		position: relative;
		overflow: hidden;
		opacity: 0;
		box-sizing: border-box;
		transition: opacity 0.3s;

		&.js-active {
			width: auto;
			height: auto;
			opacity: 1;
			padding: 16px;
		}
	}

	&__list-item {
		color: #2c2c2c;

		+ .accordion__list-item {
			margin-top: 5px;
		}

		&--right {
			float: right;
		}
	}

	&--top {
		margin-top: 20px;
	}
}
