@font-face {
    font-family: 'Vauxhall';
    src: url('../fonts/Vauxhall-BoldItalic.eot');
    src: url('../fonts/Vauxhall-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Vauxhall-BoldItalic.woff2') format('woff2'),
    url('../fonts/Vauxhall-BoldItalic.woff') format('woff'),
    url('../fonts/Vauxhall-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Vauxhall Neue';
    src: url('../fonts/VauxhallNeue-Regular.eot');
    src: url('../fonts/VauxhallNeue-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/VauxhallNeue-Regular.woff2') format('woff2'),
    url('../fonts/VauxhallNeue-Regular.woff') format('woff'),
    url('../fonts/VauxhallNeue-Regular.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Font Awesome';
    src: url('../fonts/fa-solid-900.eot');
    src: url('../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('../fonts/fa-solid-900.woff2') format('woff2'),
    url('../fonts/fa-solid-900.woff') format('woff'),
    url('../fonts/fa-solid-900.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
