.news-car-page {
	.banner_month {
		position: relative;
		margin-bottom: 70px;

		&:after {
			content: '';
			height: 100px;
			margin-top: -50px;
			background: #eef3fb;
			position: absolute;
			top: 50%;
			right: 0;
			left: 0;

			@media (max-width: 768px) {
				height: 240px;
				margin-top: -120px;
			}
		}

		.text {
			padding-right: 60px;
			padding-left: 25px;
			font-size: 15px;
			color: #2c2c2c;

			@media (max-width: 768px) {
				padding-left: 20px;
				padding-right: 20px;
				margin-top: 20px;
			}

			.ah1 {
				margin-bottom: 5px;
				font-size: 24px;
				line-height: 27px;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		.btn {
			width: 208px;
			font-size: 18px;
			font-weight: bold;

			@media (max-width: 768px) {
				display: block;
				margin-left: auto;
				margin-right: auto;
				margin-top: 20px;
			}

			&:after {
				content: '';
				display: inline-block;
				width: 9px;
				height: 14px;
				margin-left: 20px;
				vertical-align: middle;
				background: url("../img/read-more.svg") no-repeat;
			}
		}

		.inner {
			display: flex;
			align-items: center;
			justify-content: center;

			@media (max-width: 768px) {
				display: block;
				text-align: center;
			}

			& > * {
				position: relative;
				z-index: 1;
			}
		}
	}

	.main-content {
		margin-top: 67px;
		margin-bottom: 83px;

		.inner {
			max-width: 950px;
		}

		.inner2 {
			max-width: 1140px;
		}
	}

	.cars-grid {
		justify-content: center;
		@include flex-row(24px, 24px);

		.item {
			display: flex;
			align-items: center;
			flex-direction: column;
			@include flex-block(266px, 24px, 24px, true, 3);

			@media (max-width: 500px) {
				flex: 1 0 300px;
			}

			@include hover {
				.label {
					color: #ffffff;
					background-color: #124390;
				}
			}

			&:focus {
				outline: none;
			}

			.image-block {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 144px;

				img {
					display: block;
					max-width: 100%;
					max-height: 100%;
				}
			}

			.label {
				display: inline-block;
				margin-top: 10px;
				padding: 7px 25px 8px;
				text-align: center;
				border-radius: 50px;
				font-size: 21px;
				font-weight: 400;
				color: #2c2c2c;
			}

			.tripple {
				@media (max-width: 500px) {
					font-size: 10px;
				}

				b {
					@media (max-width: 500px) {
						font-size: 20px;
					}
				}
			}
		}

		.item-2 {
			display: flex;
			align-items: center;
			flex-direction: column;
			position: relative;
			box-sizing: border-box;
			transition: .3s transform, .3s box-shadow;
			border: 0;
			@include flex-block(363px, 25px, 25px, true, 3);

			.image-block {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 182px;
				margin-bottom: 4px;

				&:after {
					content: '';
					width: 100%;
					height: 100px;
					background-color: #eef3fb;
					position: absolute;
					top: 50px;
					left: 0;
					transition: .3s background-color;
				}

				img {
					display: block;
					max-width: 90%;
					max-height: 90%;
					position: relative;
					z-index: 1;
				}
			}

			.label {
				display: inline-block;
				margin-top: 0;
				padding: 7px 25px 8px;
				transition: .3s color;
				border-radius: 50px;
				font-size: 24px;
				font-weight: 600;
				color: #2c2c2c;
				text-align: center;

				&.label_big {
					text-transform: uppercase;
					line-height: 28px;
				}
			}

			p {
				font-size: 14px;
				color: #2c2c2c;
			}

			&:focus {
				outline: none;
			}

			@include hover {
				transform: translate3d(0, -20px, 0px);
				box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.18);

				.image-block:after {
					background-color: #ce171f;
				}

				.label {
					color: #ce171f;
					background: transparent;
				}
			}

			.tripple {
				display: flex;
				justify-content: space-between;
				width: 90%;
				padding-top: 20px;
				padding-bottom: 20px;
				font-size: 12px;
				line-height: 17px;
				color: #2c2c2c;
				text-align: center;
				text-transform: uppercase;

				b {
					display: block;
					margin-bottom: 4px;
					font-weight: 400;
					font-size: 24px;
				}

				& > span {
					width: 30%;
					box-sizing: border-box;
				}

				& > span:nth-child(2) {
					width: 40%;
					border-right: 1px solid #e6e6e6;
					border-left: 1px solid #e6e6e6;
				}
			}
		}
	}

	&.new-van {
		.ah2 {
			margin-bottom: 45px;
			font-size: 23px;
			line-height: 33px;
			text-align: center;
		}

		.block + .block {
			margin-top: 58px;
			padding-top: 45px;
			border-top: 1px solid #e6e6e6;
		}
	}
}