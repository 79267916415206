.imgload {
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-items: stretch;
	}

	&__item {
		width: 100%;
		height: max-content;
		max-width: 170px;
		margin-top: 10px;
		margin-right: 14px;
		border: 1px solid #a5a5a5;
		position: relative;

		img {
			display: block;
			width: 100%;
		}
	}

	&__del {
		width: 18px;
		height: 18px;
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;
	}
}