.reservation-policy {
	.inner {
		margin-bottom: 100px;
	}

	.line1 {
		margin-top: 36px;
	}

	.line2 {
		a {
			font-weight: 400;
			line-height: 33px;
			color: #b72126;
			text-decoration: underline;

			@include hover {
				text-decoration: none;
			}
		}
	}

	.line-title {
		font-size: 18px;
		font-weight: 800;
	}

	.reservation-list {
		li {
			font-size: 16px;
			line-height: 24px;
			color: #2c2c2c;
			text-align: justify;
			margin: 15px 0;
			list-style-type: none;

			@media (max-width: 450px) {
				font-size: 14px;
			}

			&::before {
				content: "•";
				padding-right: 10px;
				font-size: 22px;
				color: red;
				vertical-align: top;
			}
		}
	}
}