.quote-page {
	.page-title {
		padding: 61px 0 32px;
		text-align: center;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
		margin-bottom: 14px;
	}

	.subtitle {
		font-size: 30px;
		line-height: 33px;
	}

	.form-block {
		overflow: hidden;
	}

	.form-block.lookup {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		padding: 0 15px 50px;

		@media (min-width: 768px) {
			padding: 0 0 100px;
		}

		.input-block {
			max-width: 347px;
			width: 100%;
			margin: 0 10px 0 0;
		}

		.btn {
			width: 94px;
			height: 35px;
			font-size: 15px;
			line-height: 35px;
			font-weight: 400;
			margin: 0;
		}
	}

	.form-block .input-block {
		margin-bottom: 17px;

		label {
			width: 100%;
			margin-bottom: 0;

			span {
				color: #ce171f;
			}
		}

		input[type="text"],
		select {
			height: 35px;
			font-size: 14px;
			margin-top: 4px;
			padding: 12px 9px;
		}

		select {
			padding: 5px;
			background-image: url(../img/select_hov.svg);
		}

		.datepicker-input {
			background: #fff url(../img/datepicker.png) no-repeat;
			background-position: calc(100% - 5px) center;
			background-size: 26px;
			resize: none;
		}

		&--checkbox {
			margin-bottom: 0;

			label {
				display: flex;
				align-items: center;

				span {
					color: #2c2c2c;
				}

				input {
					width: 20px;
					height: 20px;
					margin: 0 10px 0 0;
					cursor: pointer;
					border: 1px solid #a5a5a5;
					background-color: #fff;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;

					&:checked {
						background-image: url(../img/checked.svg);
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 100%;
					}
				}
			}
		}
	}

	.quote {
		width: 100%;
		max-width: 936px;
		margin: 0 auto;
		padding: 0 15px 70px;
		box-sizing: border-box;

		@media (min-width: 950px) {
			padding: 0 0 170px;
		}

		&__vehicle {
			font-size: 24px;
			text-align: center;

			span {
				font-size: 18px;
				color: #757575;
			}
		}

		&__reset {
			font-size: 14px;
			font-weight: 400;
			color: #ce171f;
			text-decoration: underline;
			background-color: #fff;
			margin: 4px auto 0;
		}

		&__details {
			margin-top: 10px;
			margin-bottom: 20px;
		}

		&__detail {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			background-color: #fff;
			border-bottom: 1px solid #e6e6e6;

			&:first-child {
				background-color: #f2f2f2;
				border-bottom: none;

				.quote__detail-item {
					@media (min-width: 768px) {
						&:first-child {
							width: 34.324%;
						}

						&:nth-child(2) {
							width: 23.504%;
						}

						&:nth-child(3) {
							width: 20.94%;
						}

						&:last-child {
							width: 21.22%;
						}
					}
				}
			}

			&:nth-child(2) {
				.quote__detail-item {
					padding: 9px 17px;

					@media (min-width: 768px) {
						&:first-child {
							width: 34.4%;
						}

						&:nth-child(2) {
							width: 65.6%;
						}
					}
				}
			}

			&:last-child {
				.quote__detail-item {
					@media (min-width: 768px) {
						&:first-child {
							width: 22%;
						}

						&:nth-child(2) {
							width: 20.8%;
						}

						&:nth-child(3) {
							width: 20.4%;
						}

						&:nth-child(4) {
							width: 18.2%;
						}

						&:last-child {
							width: 18.6%;
						}
					}

					p {
						&:first-child {
							margin-bottom: 3px;
						}

						&:last-child {
							font-size: 16px;
						}
					}
				}
			}
		}

		&__detail-item {
			width: 50%;
			padding: 13px 17px 0;
			box-sizing: border-box;
			margin-bottom: 17px;

			p {
				&:first-child {
					font-size: 13px;
					color: #757575;
					margin-bottom: 5px;
				}

				&:last-child {
					font-size: 14px;
				}
			}
		}

		&__flex {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;

			@media (min-width: 768px) {
				flex-direction: row;
				margin: 0 -21px;
			}
		}

		&__col {
			width: 100%;

			@media (min-width: 768px) {
				width: calc(50% - 42px);
				margin: 0 21px;
			}
		}

		.summary {
			width: 100%;
			text-align: center;
			font-size: 20px;
			margin: 15px 0 13px;
		}

		&__flex-inner {
			display: flex;
			flex-direction: column;

			@media (min-width: 768px) {
				flex-direction: row;
				margin: 0 -10px;
			}

			.input-block {
				width: 100%;
				margin-bottom: 17px;

				@media (min-width: 768px) {
					width: calc(50% - 20px);
					margin: 0 10px 17px;
				}
			}
		}

		&__block-title {
			font-size: 20px;
			line-height: 28px;
			text-align: center;
			margin-bottom: 12px;

			&--small {
				font-size: 18px;
				margin-bottom: 6px;
			}
		}

		&__table {
			table {
				width: 100%;
				border-collapse: collapse;

				tr {
					&:nth-child(odd) {
						background-color: #f7f7f7;
					}

					&:last-child {
						border-top: 1px solid #e6e6e6;
						text-align: right;
						background-color: #fff;

						span {
							font-size: 18px;
						}
					}
				}

				td {
					padding: 16px 15px;
					font-size: 16px;

					&:first-child {
						width: 90%;
					}

					&:last-child {
						width: 10%;
						text-align: right;
					}
				}
			}

			&--add-on {
				.quote {
					&__block-title {
						margin-bottom: 5px;
					}
				}

				table {
					tr:last-child td {
						padding-top: 25px;
					}

					td {
						padding: 15px 13px;
					}
				}
			}
		}

		&__result {
			display: flex;
			justify-content: center;
			margin-top: 19px;
			padding-top: 27px;
			text-align: center;
			border-top: 1px solid #d9d9d9;

			.total {
				font-size: 18px;
				margin-right: 21px;

				span {
					font-size: 20px;
				}
			}

			.per-month {
				font-size: 15px;

				span {
					font-size: 16px;
				}
			}
		}

		&__nav-wrapper {
			width: 100%;
			max-width: 751px;
			margin: 0 auto;
		}

		&__nav {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 19px -23px 0;
			padding: 0 10px;

			@media (min-width: 768px) {
				flex-direction: row;
				padding: 0;
			}
		}

		&__nav-col {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: 100%;
			margin-bottom: 16px;

			@media (min-width: 768px) {
				width: calc(50% - 46px);
				margin: 0 23px;
			}

			.btn {
				width: calc(50% - 20px);
				height: 35px;
				font-size: 14px;
				line-height: 35px;
				font-weight: 400;
				margin: 0 10px;

				@media (min-width: 768px) {
					width: 168px;
					margin: 0 4px;
				}

				&:not(:last-child) {
					margin-bottom: 16px;

					@media (min-width: 768px) {
						margin-bottom: 0;
					}
				}

				&--icon {
					display: flex;
					align-items: center;
					justify-content: center;
					border: 1px solid #ce1820;
					background-color: #fff;
					color: #ce1820;
					transition: all 0.3s ease;

					&:hover {
						background-color: #fff;
						color: #981316;
						border-color: #981316;

						svg {
							fill: #981316;
						}
					}

					svg {
						fill: #ce1820;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.quote-customer {
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		margin: 50px 0 41px;
		padding: 40px 0 21px;

		+ .quote-customer {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}

		.input-block {
			margin-bottom: 19px;
		}

		&__container {
			width: 100%;
			max-width: 751px;
			margin: 0 auto;
		}

		&__title {
			font-size: 24px;
			text-align: center;
			margin-bottom: 33px;
		}

		&__flex {
			display: flex;
			flex-direction: column;

			@media (min-width: 768px) {
				flex-direction: row;
				margin: 0 -12px;
			}
		}

		&__flex-inner {
			display: flex;
			flex-wrap: wrap;

			@media (min-width: 768px) {
				margin: 0 -5px;
				align-items: flex-end;
			}

			.input-block {
				width: 100%;
				margin-bottom: 19px;

				@media (min-width: 768px) {
					width: calc(71% - 10px);
					margin: 0 5px 32px;
				}
			}

			.btn {
				width: 100%;
				font-size: 14px;
				line-height: 35px;
				font-weight: 400;
				height: 35px;
				margin: 0 0 19px;

				@media (min-width: 768px) {
					width: calc(29% - 10px);
					margin: 0 5px 32px;
				}
			}
		}

		&__col {
			width: 100%;

			@media (min-width: 768px) {
				width: calc(50% - 24px);
				margin: 0 12px;
			}
		}

		&__sms {
			display: flex;
			justify-content: center;
			margin-top: 40px;

			.input-block {
				margin-bottom: 0;
				margin-right: 7px;

				input[type="text"] {
					margin-top: 0;

					&::placeholder {
						font-size: 14px;
						color: #999999;
					}

					&:focus::placeholder {
						opacity: 0;
					}
				}
			}

			.btn--sign {
				width: 168px;
				height: 35px;
				margin-top: 0;
				font-size: 14px;
				line-height: 35px;
				font-weight: 400;
			}
		}

		&__signed {
			font-size: 16px;
			color: #ce1820;
			margin-top: 50px;
			text-align: center;
		}

		&--address {
			.quote-customer {
				&__title {
					margin-bottom: 25px;
				}

				&__flex-inner  {
					@media (min-width: 768px) {
						margin: 0 -10px;
					}

					.input-block {
						width: 100%;
						margin-bottom: 19px;

						@media (min-width: 768px) {
							width: calc(84% - 20px);
							margin: 0 10px 25px;
						}
					}

					.btn {
						width: 100%;
						margin-bottom: 19px;

						@media (min-width: 768px) {
							width: calc(16% - 20px);
							margin: 0 10px 25px;
						}
					}
				}
			}
		}

		&--payment {
			padding-bottom: 40px;
			margin-bottom: 31px;
		}
	}
}