.online-quote-request-2 {
	.content {
		width: 95%;
		max-width: 846px;
		margin: 0 auto;
	}

	.oqr {
		.red {
			color: #ce171f;
		}

		.oqr-block {
			+ .oqr-block {
				border-top: 1px solid #e6e6e6;
			}

			&--accordion {
				padding: 69px 0 30px;

				@media (max-width: 900px) {
					padding: 30px 0;
				}
			}

			&--options {
				padding-top: 15px;
			}

			&--exchange {
				padding: 52px 0 84px;

				@media (max-width: 900px) {
					padding: 30px 0;
				}

				.oqr__inputs {
					margin: 14px -12px 0;

					@media (max-width: 900px) {
						margin: 14px 0 0;
					}

					+ .oqr__btn-wrapper {
						display: flex;
						align-items: center;
						margin-top: 23px;

						.btn {
							max-width: 351px;
							width: 100%;
							font-size: 18px;
							font-weight: 400;
							margin-top: 0;
							margin-right: 24px;

							@media (max-width: 600px) {
								font-size: 15px;
							}
						}

						p {
							font-size: 14px;
							color: #666666;
						}
					}
				}
			}

			&--payment {
				padding: 48px 0;

				@media (max-width: 900px) {
					padding: 30px 0;
				}

				.oqr__inputs {
					margin: 0 -12px;

					@media (max-width: 900px) {
						margin: 0;
					}
				}
			}

			&--contacts {
				padding: 40px 0 36px;

				@media (max-width: 900px) {
					padding: 30px 0;
				}

				.oqr__inputs {
					margin: 25px -12px 35px;

					@media (max-width: 900px) {
						margin: 25px 0 35px;
					}
				}
			}
		}

		&__step {
			font-size: 18px;
			line-height: 22px;
			color: #ce171f;
		}

		&__title {
			font-size: 24px;
			line-height: 28px;
		}

		.oqr-label {
			display: flex;
			align-items: center;
			width: 100%;
			height: 40px;
			border: 1px solid #e6e6e6;
			background-color: #fff;
			border-radius: 5px;
			padding-left: 40px;
			position: relative;
			box-sizing: border-box;
			cursor: pointer;
			transition: border-color 0.3s ease;

			@include hover {
				border-color: #ce181f;
			}

			+ label {
				margin-top: 9px;
			}

			input {
				display: none;

				&:checked + span {
					color: #fff;

					&::before {
						background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3e%3cpath fill='%23ce171f' d='M6.992.062h.015a6.93 6.93 0 0 1 6.93 6.93v.015a6.93 6.93 0 0 1-6.93 6.93h-.015a6.93 6.93 0 0 1-6.93-6.93v-.015a6.93 6.93 0 0 1 6.93-6.93z'/%3e%3c/svg%3e") no-repeat center;
						background-size: 70%;
						border-color: #fff;
					}
				}
			}

			span {
				user-select: none;
				font-size: 16px;
				line-height: 28px;

				&::before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid #a9a9a9;
					margin-right: 10px;
					border-radius: 50%;
					position: absolute;
					top: 8px;
					left: 10px;
				}
			}
		}

		&__accordion {
			display: flex;
			margin: 27px -20px 0;

			@media (max-width: 900px) {
				flex-direction: column;
				margin: 27px 0 0;
			}
		}

		&__content,
		&__tab {
			margin: 0 20px;

			@media (max-width: 900px) {
				margin: 0;
			}
		}

		&__content {
			width: calc(65% - 40px);
			position: relative;

			@media (max-width: 900px) {
				width: 100%;
				padding-top: 60px;
			}

			&::before {
				content: '';
				display: block;
				width: 430px;
				height: 430px;
				background-color: #eef3fb;
				border-radius: 50%;
				position: relative;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);

				@media (max-width: 900px) {
					top: 0;
					transform: translate(-50%, 0);
				}

				@media (max-width: 600px) {
					width: 300px;
					height: 300px;
				}
			}
		}

		&__content-item {
			display: none;
			max-width: 520px;
			width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			@media (max-width: 900px) {
				max-width: 420px;
				top: calc(50% + 60px);
				transform: translate(-50%, calc(-50% - 30px));
			}

			@media (max-width: 600px) {
				max-width: 300px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__content-selected {
			max-width: 150px;
			text-align: right;
			position: absolute;
			top: 0;
			right: 0;

			@media (max-width: 900px) {
				max-width: 300px;
				text-align: center;
				top: 7px;
				right: auto;
				left: 50%;
				transform: translateX(-50%);
			}

			p {
				color: #ce171f;
				font-size: 14px;

				&.selected-car {
					color: #2c2c2c;
					font-size: 24px;
					line-height: 28px;
				}
			}
		}

		&-active {
			background-color: #ce171f !important;
			border-color: #ce171f !important;
		}

		&__tab {
			width: calc(35% - 40px);

			@media (max-width: 900px) {
				width: 100%;
			}
		}

		&__inputs {
			display: flex;
			align-items: flex-end;

			@media (max-width: 900px) {
				flex-direction: column;
			}

			.input-block {
				width: calc(33.3333% - 24px);
				margin: 0 12px;
				position: relative;

				&.err-required::after,
				&.err-format::after {
					position: absolute;
					top: auto;
					bottom: -20px;
				}

				@media (max-width: 900px) {
					width: 100%;
					margin: 0;
				}

				+ .input-block {
					@media (max-width: 900px) {
						margin-top: 22px;
					}
				}

				&.gb-reg-number {
					&::before {
						content: "";
						background: url(../img/new-gb-rn.png);
						width: 43px;
						height: 43px;
						min-width: 42px;
						min-height: 42px;
						position: absolute;
						left: 1px;
						top: 25px;
						display: block;
					}

					input {
						padding-left: 55px;
					}
				}

				label {
					width: 100%;
					margin-bottom: 0;
				}

				input,
				select {
					margin-top: 7px;
				}

				select {
					background: #fff url(../img/select_hov.svg) no-repeat;
					background-position: calc(100% - 15px) center;
				}

				span {
					font-size: 13px;
					color: #999999;

					&.red {
						color: #ce171f;
					}
				}
			}
		}

		.input-block--branch {
			display: flex;

			@media (max-width: 600px) {
				flex-direction: column;
				justify-content: center;
			}

			.radio-btn + .radio-btn {
				margin-top: 0;
				margin-left: 50px;

				@media (max-width: 600px) {
					margin-left: 0;
				}
			}
		}

		&__method-wrap {
			display: flex;
			margin-top: 20px;

			.oqr-label {
				max-width: 187px;
				width: 100%;

				+ .oqr-label {
					margin-left: 9px;
					margin-top: 0;
				}
			}
		}

		&__finance-options {
			font-size: 18px;
			font-weight: bold;
			margin: 45px 0 26px;
		}

		&__branch {
			font-size: 16px;
			margin-bottom: 20px;
		}

		&__submit {
			width: 266px;
			font-size: 18px;
			font-weight: bold;
			margin: 0 auto 100px;
		}
	}

	.oqr-options {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 19px;

		@media (max-width: 900px) {
			flex-direction: column;
			align-items: flex-start;
		}

		&__group {
			margin-bottom: 26px;

			@media (max-width: 600px) {
				width: 100%;
			}
		}

		&__type {
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
			margin-bottom: 3px;
		}

		&__inputs {
			display: flex;
			flex-wrap: wrap;

			label {
				@media (max-width: 600px) {
					width: 50%;
				}

				&:not(:last-child) {
					margin-right: 53px;

					@media (max-width: 600px) {
						margin-right: 0;
					}
				}
			}
		}
	}
}