.build-your-deal {
	padding-bottom: 100px;

	@media (max-width: 614px) {
		& >div.inner2>div>div>div.block.choose_block>div:nth-child(1) {
			>button:nth-child(2) {
				width: 100%;
				margin-right: 0;
			}

			>button:nth-child(3) {
				width: 100%;
				margin: 10px 0 10px 0;
			}
		}
	}

	&__top-nav {
		margin: 20px 0 0;
	}

	.page-main-title {
		height: 100px;
	}

	.ah2 {
		margin-bottom: 20px;
		font-size: 18px;
		line-height: 21px;
	}

	label {
		display: inline-block;
		margin-bottom: .5rem;
		cursor: pointer;
		user-select: none;
		vertical-align: top;
		font-size: 16px;

		span {
			font-size: 14px;
			color: #666666;
		}
	}

	input[type=checkbox] {
		width: 20px;
		height: 20px;
		cursor: pointer;
		border: 1px solid #A5A5A5;
		background-color: #FFF;
		-webkit-appearance: none;
		-moz-appearance: none;

		&::-ms-check{
			display:none
		}

		&:checked {
			background-image: url(../img/checked.svg);
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100%;
		}
	}

	input[type="text"] {
		box-sizing: border-box;
		width: 100%;
		height: 40px;
		padding: 7px 15px;
		border: 1px solid #A5A5A5;
		outline: none;
		font-size: 16px;
		line-height: 1em;

		&.gb-reg-number {
			padding-left: 15px;
			text-transform: uppercase;
			background-size: contain;
		}

		&.datepicker-input {
			box-sizing: border-box;
			width: 100%;
			height: 40px;
			padding: 10px 15px;
			resize: none;
			border: 1px solid #a5a5a5;
			border-radius: 0;
			outline: 0;
			background: #fff url(../img/datepicker.png) no-repeat;
			background-position: calc(100% - 9px) center;
			font-size: 16px;
			line-height: 1em;
			-webkit-appearance: none;
			-moz-appearance: none;
		}
	}

	select {
		box-sizing: border-box;
		width: 100%;
		height: 40px;
		margin-bottom: 8px;
		padding: 8.5px 15px;
		resize: none;
		border: 1px solid #a5a5a5;
		border-radius: 0;
		outline: 0;
		background: #fff url(../img/select_hov.svg) no-repeat;
		background-position: calc(100% - 15px) center;
		font-size: 16px;
		line-height: 1em;
		-webkit-appearance: none;
		-moz-appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	.btn_blue {
		width: 100%;
		background: #124390;
		border-radius: 5px;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
		color: #fff;

		@include hover {
			color: #FFF;
			background: #2C2C2C;
		}
	}

	.btn_red {
		text-transform: uppercase;
		color: #fff;
		background: #ce181f;
		font-weight: 600;
		font-size: 16px;

		@include hover {
			background: #2c2c2c;
		}
	}

	.flex-block {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding-top: 31px;

		@media (max-width: 1100px) {
			flex-direction: column;
		}

		h3,
		.ah2,
		.ah3 {
			@media (max-width: 1100px) {
				text-align: center;
			}
		}

		.sidebar {
			flex-shrink: 0;
			width: 350px;
			margin-left: 40px;

			@media (max-width: 1100px) {
				width: 100%;
				margin-top: 25px;
				margin-left: 0;
			}

			.side-wrap {
				width: inherit;
				position: sticky;
				top: 100px;

				@media (max-width: 1100px) {
					position: relative;
					top: 0;
				}
			}

			.card {
				margin-bottom: 25px;
				background-color: #f7f7f7;
				border: 1px solid #d9d9d9;

				.card-header {
					margin-bottom: 0;
					padding: 34px 35px 14px;
					border-bottom: none;
					border-radius: 0;
					background: transparent;
					font-size: 18px;
					font-weight: bold;
				}

				.card-body {
					padding: 0 35px 35px;

					p {
						max-width: 240px;
						margin-bottom: 20px;
						font-size: 14px;
						line-height: 22px;
					}
				}

				table {
					width: 100%;

					td {
						padding: 5px 0;
						font-size: 14px;

						&:last-child {
							width: 70px;
							text-align: right;
							font-weight: 700;
						}
					}
				}

				.ah1 {
					font-size: 13px;
					line-height: 16px;
					color: #757575;
				}

				.total {
					margin-top: 5px;
					padding-top: 5px;
					border-top: 1px solid #d7d7d7;
				}
			}

			&.sidebar--speco .card .total {
				margin-top: 12px;
				padding-top: 15px;
			}
		}

		.content {
			width: 100%;

			.title {
				margin-bottom: 15px;
				font-size: 24px;
				line-height: 33px;
				font-weight: 400;
			}

			.block {
				border-bottom: 2px solid #e6e6e6;

				&.byd-payment {
					border-bottom: none;

					.byd-payment__ch-p-m {
						margin-bottom: 6px;
					}

					.ah2 {
						margin-bottom: 13px;
					}

					.total-amount {
						font-size: 16px;
						margin-bottom: 15px;

						span {
							font-size: 24px;
							line-height: 28px;
							font-weight: bold;
						}
					}

					.radiogroup-v2 {
						margin: 0 0 33px 0;

						label {
							width: 150px;
							margin: 0;

							+ label {
								margin-left: 10px;
							}

							span {
								border-radius: 5px;
							}
						}
					}

					.calc-new {
						&__bottom {
							margin: 18px 0 0;
						}

						.check-holyday {
							margin: 0 0 0 5px;
						}
					}
				}

				.err_block {
					margin-bottom: 30px;
					font-size: 18px;
					line-height: 24px;


					p {
						margin-bottom: 13px;
					}

					.inn_err_block {
						padding: 30px 45px 35px;
						border: 2px solid #e6e6e6;

						@media (max-width: 614px) {
							padding: 20px;
						}
					}

					.ah1 {
						margin-bottom: 13px;
						font-size: 30px;
						line-height: 33px;
						font-weight: bold;
					}

					.tel {
						display: inline-block;
						padding-bottom: 6px;
						vertical-align: top;
						font-weight: bold;
						font-size: 22px;
						line-height: 25px;
						color: #2C2C2C;

						&:before {
							content: '';
							display: inline-block;
							width: 19px;
							height: 18px;
							margin-top: -4px;
							margin-right: 8px;
							vertical-align: middle;
							background: url("../img/err.svg") no-repeat;
							background-size: auto 100%;
						}
					}

					.btn_red {
						width: auto;
						margin-right: 25px;
						padding-right: 42px;
						padding-left: 42px;

						@media (max-width: 614px) {
							padding-right: 10px;
							padding-left: 10px;
						}
					}
				}

				.titl {
					font-size: 24px;
					line-height: 57px;
					font-weight: 600;
					color: #a6a6a6;
					cursor: pointer;
				}

				&.active {
					padding-bottom: 25px;
					margin-bottom: 25px;

					.titl {
						color: #2c2c2c;
					}
				}

				&.choose_block {
					padding-bottom: 50px;

					.btn_red {
						display: inline-block;
						width: 265px;
						margin-right: 21px;
						vertical-align: top;
					}

					p {
						max-width: 690px;
						margin-bottom: 25px;
						font-size: 16px;
						line-height: 24px;
					}

					.car_block {
						display: flex;
						justify-content: space-between;
						margin-top: 15px;

						@media (max-width: 614px) {
							display: block;
						}

						.image_block {
							flex-shrink: 0;
							width: 41.8%;
							background-position: center center;
							background-size: cover;

							@media (max-width: 614px) {
								width: 100%;
								height: 178px;
								margin: 0;
							}
						}

						.text_block {
							position: relative;
							width: 100%;
							padding: 25px 25px 35px 30px;
							background: #f7f7f7;

							@media (max-width: 614px) {
								max-width: calc(100% - 55px);
							}

							.change {
								position: absolute;
								top: 28px;
								right: 25px;
								cursor: pointer;
								text-decoration: underline;
								color: #ce171f;

								&:before {
									content: '';
									display: inline-block;
									width: 11px;
									height: 11px;
									margin-right: 4px;
									vertical-align: middle;
									background: url("../img/red_x.svg") no-repeat center center;
									background-size: 11px auto;
								}

								@include hover {
									text-decoration: none;
								}
							}

							.min-height {
								min-height: 105px;
								margin-right: 110px;

								@media (max-width: 450px) {
									min-height: unset;
									margin-top: 42px;
									margin-right: 0;
									padding-bottom: 23px;
								}

								.ah1 {
									margin-bottom: 4px;
									font-size: 18px;
									line-height: 21px;
									font-weight: bold;
								}
							}

							.fsz13 {
								font-size: 13px;
								line-height: 24px;
							}

							.flex {
								display: flex;
								justify-content: space-between;

								@media (max-width: 450px) {
									flex-direction: column;
								}

								li {
									display: inline-block;
									vertical-align: top;

									& + li:before {
										content: '';
										display: inline-block;
										width: 4px;
										height: 4px;
										margin-top: -3px;
										margin-right: 6px;
										margin-left: 3px;
										vertical-align: middle;
										border-radius: 50%;
										background: #2c2c2c;
									}
								}

								.price {
									margin-left: 30px;
									white-space: nowrap;
									font-weight: bold;
									font-size: 24px;

									@media (max-width: 450px) {
										margin-top: 10px;
										margin-left: 0;
									}
								}
							}
						}
					}
				}

				&.trade-in_block {
					.input-block,
					select {
						margin-bottom: 20px;
					}

					.row {
						display: flex;
						justify-content: space-between;

						@media (max-width: 1100px) {
							flex-direction: column;
						}

						& > div {
							flex-shrink: 0;
							width: 48%;

							@media (max-width: 1100px) {
								-ms-flex-negative: 0;
								flex-shrink: 0;
								width: 100%;
							}
						}
					}

					.btn_red {
						display: inline-block;
						margin-top: 11px;
						vertical-align: top;
						border-radius: 5px;

						@media (max-width: 1100px) {
							display: block;
							margin-right: auto;
							margin-left: auto;
						}
					}

					.no_trade {
						display: inline-block;
						margin-top: 11px;
						margin-left: 20px;
						vertical-align: top;
						text-decoration: underline;
						color: #ce171f;
						font-size: 18px;
						line-height: 46px;

						@media (max-width: 1100px) {
							display: block;
							margin-top: 0;
							margin-left: 0;
							text-align: center;
						}
					}

					.car_det {
						.ah2 {
							margin-bottom: 10px;

							a {
								margin-left: 17px;
								text-decoration: underline;
								color: #ce171f;
								font-size: 14px;
							}
						}

						table {
							width: 100%;
							margin-bottom: 25px;
							border-bottom: 2px solid #e6e6e6;

							@media (max-width: 1100px) {
								overflow-x: scroll;
							}

							tr {
								@media (max-width: 1100px) {
									display: flex;
									flex-wrap: wrap;
								}

								&:nth-child(odd) {
									background-color: #f7f7f7;
								}
							}

							td {
								width: 25%;
								padding: 13px 18px;

								@media (max-width: 1100px) {
									width: 50%;
									box-sizing: border-box;
								}

								.ah3 {
									margin-bottom: 5px;
									color: #9da4b0;
									font-size: 13px;

									@media (max-width: 1100px) {
										text-align: left;
									}
								}
							}
						}

						.price {
							text-align: right;
							line-height: 24px;

							b {
								margin-left: 10px;
								font-size: 24px;
							}
						}

						.ah3 {
							font-size: 16px;

							.bold {
								font-size: 24px;
								font-weight: bold;
								letter-spacing: 0.6px;
							}

							&.error {
								position: relative;
								margin-top: 11px;
								color: #ce171f;

								&::before {
									content: "";
									display: inline-block;
									width: 22px;
									height: 19px;
									margin-right: 7px;
									margin-left: 2px;
									background: url(../img/problem.png);
								}

								.bold {
									margin-right: 5px;
									letter-spacing: -.4px;
								}
							}
						}
					}

					.old-car {
						a {
							display: inline-block;
							cursor: pointer;
							text-decoration: underline;
							color: #ce171f;

							&::before {
								content: '';
								display: inline-block;
								width: 11px;
								height: 11px;
								margin-right: 4px;
								margin-left: 7px;
								vertical-align: middle;
								background: url(../img/red_x.svg) no-repeat center center;
								background-size: 11px auto;
							}
						}
					}
				}

				&.care-package_block {
					.table-striped {
						width: 100%;
						margin-top: 15px;
						font-size: 16px;

						tbody tr:nth-of-type(odd) {
							background-color: rgba(0, 0, 0, .05);
						}

						tr td {
							padding: 0.5rem;
							vertical-align: middle;

							&:first-child {
								width: 30px;
							}

							&:last-child {
								width: 64px;
							}
						}

						tr.not {
							pointer-events: none;
							opacity: 0.4;
						}
					}
				}

				&.payment_block {
					.ah3 {
						font-size: 16px;
					}

					.price {
						margin-top: -10px;
						margin-bottom: 15px;
						font-size: 16px;
						line-height: 24px;

						@media (max-width: 1100px) {
							width: fit-content;
							margin-right: auto;
							margin-left: auto;
						}

						b {
							margin-left: 10px;
							font-size: 24px;
						}

						.red {
							margin-left: 10px;

							@media (max-width: 1100px) {
								display: block;
							}
						}
					}

					.personalise__button {
						margin-top: 17px;
					}

					.personal-table__table {
						.personal-table__table-row:nth-child(odd) {
							background: #f2f2f2;
						}
					}

					.personalise__left-side {
						width: 52%;
						margin-right: 40px;

						@media (max-width: 1100px) {
							width: 100%;
							max-width: 100%;
							margin-right: 0;
						}
					}

					.personalise__right-side {
						@media (max-width: 1100px) {
							max-width: 100%;
						}
					}

					.buttons_block {
						text-align: center;

						.btn {
							display: inline-block;
							box-sizing: border-box;
							width: 266px;
							margin: 0 10px;
							vertical-align: top;

							&.btn_blue {
								@media (max-width: 614px) {
									margin-top: 10px;
								}
							}
						}

						.btn:not(.btn_blue) {
							border: 1px solid #58585a;
							background: transparent;
							font-weight: bold;
							font-size: 18px;

							@include hover {
								color: #fff;
								background: #2c2c2c;
							}
						}
					}

					.personalise__row {
						@media (max-width: 1100px) {
							display: block;
							margin-bottom: 20px;
						}
					}

					.radiogroup--notop {
						@media (max-width: 1100px) {
							margin-right: auto;
							margin-left: auto;
						}
					}
				}
			}

			.custom-slider {
				@media (max-width: 1100px) {
					max-width: calc(100% - 40px);
					margin: 0 auto;
				}

				.right {
					@media (max-width: 1100px) {
						margin-left: 10px;
						text-align: right;
					}
				}
			}
		}
	}

	#slider-val {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.left {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 22px;
			line-height: 28px;
			font-weight: bold;
			color: #ce171f;
		}

		.right {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 22px;
			line-height: 28px;
			font-weight: bold;
			color: #124390;
		}
	}

	#slider {
		height: 4px;
		background-color: #85aded;

		.ui-slider-handle {
			top: calc(50% - 15px);
			width: 30px;
			height: 30px;
			border: 1px solid #124390;
			border-radius: 50%;
			background-color: #ffffff;

			&::before {
				content: "";
				width: 12px;
				height: 12px;
				border-radius: 50%;
				background-color: #124390;
				position: absolute;
				top: calc(50% - 6px);
				left: calc(50% - 6px);
			}
		}

		.ui-slider-range {
			border-radius: 2px;
			background-color: #ce171f;
		}
	}

	#slider-desc {
		display: flex;
		justify-content: space-between;
		margin-top: 17px;

		.left,
		.right {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 14px;
			line-height: 16px;
			font-weight: 400;
			color: #2c2c2c;
		}
	}

	.radiogroup--notop {
		max-width: 348px;
		margin-top: 0;
		margin-bottom: 30px;
	}

	.personalise__right-side--fin {
		max-width: 225px;
		margin-left: 30px;

		@media (max-width: 900px) {
			max-width: 100%;
			margin-left: 0;
		}
	}
}

///////////////////////////

.byd--speco {
	padding-bottom: 0;

	label span {
		color: #2c2c2c;
	}
}

.byd-payment {
	color: #2c2c2c;

	&__ch-p-m {
		font-size: 18px;
		line-height: 28px;
		font-weight: 400;
	}

	&__amount {
		margin-top: 10px;
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;

		span {
			margin-left: 10px;
			font-size: 24px;
			line-height: 28px;
			font-weight: bold;
		}
	}

	> .radiogroup-v2 {
		margin-top: 5px;

		@media (max-width: 770px) {
			margin: 0 auto;
			align-items: center;
			flex-direction: column;
		}

		label + label {
			@media (max-width: 770px) {
				margin-left: 0 !important;
				margin-top: 10px !important;
			}
		}
	}

	&__part-title {
		width: 100%;
		margin-top: 35px;
		border-bottom: 1px solid #e6e6e6;
		font-size: 18px;
		line-height: 28px;
		font-weight: 400;
		color: #2c2c2c;
	}
}

.byd-payment-pyf {
	display: flex;
	margin-top: 30px;

	@media (max-width: 770px) {
		display: block;
	}
}

.byd-payment-pyf-table {
	flex-grow: 1;
}

.byd-paymet-pyf-table-row {
	display: flex;
	justify-content: space-between;
	padding: 0 32px 0 12px;
	color: #2c2c2c;
	font-weight: 400;
	font-size: 15px;
	line-height: 33px;

	@media (max-width: 450px) {
		font-size: 14px;
		line-height: 24px;
		padding: 0 15px;
	}

	@media (max-width: 380px) {
		display: block;
		text-align: center;
	}

	&:nth-child(2n - 1) {
		background-color: #f7f7f7;
	}

	&__left {
		margin-right: 15px;

		@media (max-width: 380px) {
			margin-right: 0;
		}
	}

	&__right {
		@media (max-width: 380px) {
			font-weight: 600;
		}
	}
}

.byd-payment-pyf-form {
	box-sizing: border-box;
	width: 330px;
	padding: 33px 35px 38px 34px;
	border: 1px solid #e6e6e6;

	@media (max-width: 770px) {
		margin: 0 auto;
		width: 550px;
		max-width: 100%;
		margin-top: 20px;
	}

	@media (max-width: 450px) {
		padding: 15px 15px;
	}

	&__total {
		margin-bottom: 11px;
		font-size: 14px;
		line-height: 16px;
		font-weight: 400;
		color: #2c2c2c;

		span {
			font-size: 18px;
			line-height: 28px;
			font-weight: 400;
			color: #ce181f;
		}
	}

	.flex-row {
		margin-top: 13px;
	}
}

.simple-hint {
	display: block;
	width: 20px;
	height: 20px;
	margin-left: 12px;
	cursor: pointer;
	background: url(../img/help-icon-gray.png) no-repeat center center;
	background-size: contain;
}

.byd-depostion-right{
	display: flex;
	align-items: center;
	margin-bottom: 14px;
	margin-top: -7px;

	.input-lane{
		&__input {
			&--right {
				width: 100px!important;
				padding: 6px 7px!important;
				border: 1px solid #a5a5a5!important;
				margin-bottom: 0 !important;

				&:focus{
					padding: 6px 7px!important;
					height: auto !important;
					line-height: 23px !important;
				}
			}
		}

		&__label {
			margin-right: 5px;
			font-size: 12px;
			letter-spacing: -0.4px;
		}
	}
}

#outstanding+.ah2 {
	margin-top: 24px;
}

.label-before-c-sli-v2 {
	color: #2c2c2c;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	margin-bottom: 10px;
}
