.breadcrumbs {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 14px;

	li {
		display: flex;
		align-items: center;
		margin-bottom: 14px;

		svg {
			width: 34px;
			height: 22px;
		}
	}

	&__link {
		font-size: 16px;
		color: #ce171f;
		text-decoration: underline;

		@media (min-width: 1024px) {
			&:hover {
				text-decoration: none;
			}
		}

		&--active {
			color: #2c2c2c;
			text-decoration: none;
		}
	}
}