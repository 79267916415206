.used-stock-page-2 {
	padding-bottom: 146px;

	.page-main-title {
		margin-bottom: 10px;
	}

	.top-nav {
		margin: 20px 0;
		padding-left: 8px;
	}

	.relative_block {
		display: flex;
		justify-content: space-between;

		.left,
		.text_block {
			@media (max-width: 987px) {
				width: 100%;
				margin-right: 0;
			}
		}

		.left {
			.description {
				@media (max-width: 580px) {
					margin: 18px auto 20px auto;
				}
			}
		}

		> .left {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			width: 59%;
			margin-right: 45px;
			padding-left: 8px;
			position: relative;

			@media (max-width: 1091px) {
				margin-right: 40px;
			}

			@media (max-width: 987px) {
				width: 100%;
				padding-left: 0;
			}

			.fancybox {
				width: 38px;
				height: 38px;
				top: 386px;
				left: auto;
				right: 35px;
				z-index: 2;
				background-size: 30px;
				border-radius: 5px;

				@media (max-width:987px) {
					top: 435px;
				}

				@media (max-width: 500px) {
					top: 200px;
					right: 47px;
				}
			}
		}
	}

	.big-images {
		width: 100%;
	}

	.text_block {
		display: block;
		width: 38%;
		max-width: none;

		@media (max-width: 987px) {
			flex-direction: column;
		}

		.num {
			font-size: 16px;
			color: #999999;

			@media (max-width: 767px) {
				text-align: center;
			}
		}

		.model {
			margin-bottom: 9px;
			line-height: 36px;

			@media (max-width: 767px) {
				font-size: 24px;
				line-height: 34px;
				text-align: center;
			}
		}

		.short-info {
			font-size: 16px;
			color: #4d4d4d;

			@media (max-width: 767px) {
				text-align: center;
			}

			span.mileage {
				font-size: 18px;
				padding-right: 10px;
			}
		}

		.contacts {
			flex-direction: row;
			align-items: center;
			padding: 14px 0 12px 0;
			margin-top: 20px;

			@media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
				flex-direction: column;
			}

			a {
				display: flex;
				align-items: center;
				font-size: 20px;
				color: #2c2c2c;
				transition: color 0.3s ease;

				@include hover {
					color: #CE171F;
				}

				+ a {
					margin-left: 36px;

					@media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
						margin-left: 0;
						margin-top: 20px;
					}
				}

				svg {
					margin-right: 8px;
				}
			}
		}
	}

	.price {
		margin-top: 29px;
		background-color: #f5f5f5;
		border: 1px solid #e6e6e6;
		border-radius: 3px;

		&__wrapper {
			display: flex;
			align-items: center;
			padding: 13px 15px 13px 38px;
			border-bottom: 1px solid #e6e6e6;

			@media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
				flex-direction: column;
				text-align: center;
			}


			> p {
				width: 40%;

				@media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
					width: 60%;
				}
			}

			> div {
				width: 60%;
				display: flex;
				align-items: center;
				padding-left: 29px;
				margin-left: 13px;
				border-left: 1px solid #d9d9d9;
				box-sizing: border-box;

				@media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
					width: 60%;
					justify-content: center;
					padding-left: 0;
					margin-left: 0;
					border-left: none;
					border-top: 1px solid #d9d9d9;
					margin-top: 8px;
					padding-top: 8px;
					text-align: left;
				}
			}

			p {
				font-size: 40px;
				line-height: 40px;

				@media (min-width: 988px) and (max-width: 1279px) {
					font-size: 36px;
					line-height: 36px;
				}

				@media (max-width: 767px) {
					font-size: 24px;
					line-height: 24px;
				}
			}

			span {
				display: inline-block;
				font-size: 14px;
				line-height: 18px;
				text-transform: uppercase;
				padding-left: 10px;
			}
		}
	}

	.blue-buttons {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 21px 41px 32px;

		@media (max-width: 517px) {
			flex-direction: column;
		}

		.blue-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 360px;
			background-color: #124390;
			padding: 8.5px;
			margin: 0 auto;
			font-size: 16px;
			line-height: 29.14px;
			text-align: center;
			border-radius: 5px;

			@media (max-width: 517px) {
				width: 100%;
				margin: 10px 0;
			}

			+ .blue-button {
				margin-top: 14px;
			}

			@include hover {
				background-color: #58585a;
			}
		}
	}

	.hr {
		height: 0;
		border-top: 1px solid #e1e1e1;
	}

	.calculator {
		margin-top: 18px;

		.calc-headline-1 {
			margin-bottom: 6px;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
		}

		.radiogroup {
			justify-content: space-between;
			padding-top: 15px;

			.radiogroup__radio {
				width: 49%;

				span {
					width: 100%;
				}
			}
		}

		.inputs {
			margin-bottom: 11px;
		}

		.input {
			display: block;
			width: 100%;
			height: 42px;
			margin-bottom: 20px;
			padding: 0 35px 0 15px;
			border: 1px solid #a5a5a5;
			font-size: 16px;
			line-height: 19px;
			color: #2c2c2c;
			box-sizing: border-box;
		}

		.numericupdown {
			position: relative;

			.up {
				width: 42px;
				height: 100%;
				background: #ce181e url(../img/input-plus.png) no-repeat center center;
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
			}

			.down {
				width: 42px;
				height: 100%;
				background: #ce181e url(../img/input-minus.png) no-repeat center center;
				position: absolute;
				top: 0;
				left: 0;
				cursor: pointer;
			}

			.input {
				padding-left: 40px;
				padding-right: 40px;
				text-align: center;
			}
		}

		.button-calc {
			width: 100%;
			margin-top: 15px;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
		}

		.radiogroup-v2 {
			display: flex;
			margin: 32px -4px 0;

			&__lbl {
				display: flex;
				align-items: center;
				width: calc(33.3333% - 8px);
				height: 46px;
				margin: 0 4px 14px;
				font-size: 16px;
				font-weight: 400;
				line-height: 28px;
				box-sizing: border-box;

				@media (max-width: 1279px) {
					width: calc(50% - 8px);
				}

				&:nth-child(3n) {
					@media (max-width: 1279px) {
						width: 100%;
					}
				}

				span {
					width: 100%;
					border-radius: 5px;
				}
			}

			&--car {
				margin: 32px -7.5px 0;

				.radiogroup-v2__lbl {
					width: calc(50% - 15px);
					margin: 0 7.5px 14px;
				}
			}
		}

		.check-holyday {
			margin-left: 4px;
			margin-top: 10px;
		}

		.radiogroup--info {
			.radiogroup__radio {
				margin-bottom: 14px;
				margin-left: 0;
			}
		}

		.personalise_remake {
			padding: 0;
		}

		.calc-headline-2 {
			margin-top: 20px;
			font-size: 18px;
			line-height: 28px;
			font-weight: bold;
		}
	}

	.personal-table {
		margin-top: 25px;

		@media (max-width: 987px) {
			margin-top: 0;
		}

		&__title {
			height: 40px;
			margin-bottom: 0;
			padding-bottom: 0;
			border: 0;
			font-weight: 700;
		}

		&__table-row:nth-child(2n+1) {
			background: #f2f2f2;
		}

		&__table-cell--w30 {
			font-weight: 400;
		}
	}

	.blue-buttons_long {
		margin-top: 31px;

		.blue-button {
			width: 49%;
		}
	}

	.block-wrapper {
		+ .block-wrapper {
			margin-top: 25px;
		}
	}

	.block-title {
		margin-bottom: 15px;
		font-size: 24px;

		@media (max-width: 987px) {
			text-align: center;
		}
	}

	.block-subtitle {
		font-size: 16px;
		line-height: 26px;

		@media (max-width: 987px) {
			text-align: center;
		}
	}

	.description {
		width: 100%;
		margin: 35px 0 0;

		@media (max-width: 987px) {
			max-width: 700px;
			margin: 35px auto 59px auto;
		}

		+ .finance__block {
			@media (max-width: 580px) {
				margin-bottom: 20px;
			}
		}

		&__tabs {
			display: flex;
			border-bottom: 1px solid #ce171f;
		}

		&__tab {
			padding: 3px 20px;
			font-size: 18px;
			line-height: 28px;
			color: #4d4d4d;
			cursor: pointer;
			transition: all 0.3s ease;

			&.active {
				border-radius: 5px 5px 0 0;
				background-color: #ce171f;
				color: #fff;
			}
		}

		&__content {
			padding-top: 27px;
		}

		&__content-item {
			display: none;
		}

		&__table {
			width: 100%;
			border-collapse: collapse;

			tr + tr {
				border-top: 1px solid #e6e6e6;
			}

			td {
				font-size: 18px;
				padding: 8.7px 0;
				vertical-align: middle;

				@media (max-width: 767px) {
					font-size: 16px;
				}

				&:first-child {
					padding-left: 10px;
				}

				&:last-child {
					padding-right: 14px;
					text-align: right;
				}
			}
		}

		.picture-parameters {
			@media (max-width: 517px) {
				flex-wrap: wrap;
				justify-content: center;
			}

			.item {
				@media (max-width: 517px) {
					margin-right: 15px;
				}
			}
		}

		.features {
			@media (max-width: 580px) {
				display: block;
			}

			.left,
			.right {
				@media (max-width: 580px) {
					width: 100% !important;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.list {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;

		@media (max-width: 767px) {
			margin: 0;
		}

		&__col {
			width: calc(50% - 20px);
			margin: 0 10px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0;
			}

			+ .list__col {
				@media (max-width: 767px) {
					margin-top: 49px;
				}
			}
		}

		&__block + .list__block {
			margin-top: 49px;
		}

		&__title {
			font-size: 18px;
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid #e6e6e6;
		}

		ul {
			li {
				position: relative;
				padding-left: 15px;
				font-size: 14px;
				line-height: 21px;

				+ li {
					margin-top: 13px;
				}

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background-color: #ce171f;
					position: absolute;
					top: 8px;
					left: 1px;
				}
			}
		}
	}

	.finance {
		&__block {
			width: 143%;
		}
	}

	.finance-calc {
		.personalise__ext {
			margin: 0;
		}

		.btn-finance-explained {
			display: block;
			width: 236px;
			height: 30px;
			font-size: 14px;
			line-height: 18px;
			margin-left: auto;
			text-transform: capitalize;
			box-sizing: border-box;

			@media (max-width: 767px) {
				margin-right: auto;
			}
		}

		.btn--build {
			width: 100%;
			max-width: 432px;
			border-radius: 5px;
			font-size: 16px;
			text-transform: uppercase;
			margin: 20px auto 0;
		}
	}

	.vehInfo {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		width: 162px;
		height: 38px;
		margin-top: 62px;
		margin-bottom: 37px;
		border: 1px solid #58585a;
		font-size: 14px;
		line-height: 28px;
		font-weight: bold;
		color: #2c2c2c;
		text-transform: uppercase;
	}

	#sync1 {
		border-bottom: none;
		width: 96.5%;
		overflow: visible;
		padding-bottom: 20px;

		@media (max-width: 987px) {
			padding-left: 15px;
		}

		@media (max-width: 900px) {
			padding-bottom: 20px !important;
		}

		@media (max-width: 767px) {
			width: 95%;
		}

		@media (max-width: 500px) {
			width: 90%;
		}

		&:not(.owl-loaded){
			display: flex;
			flex-wrap: nowrap;
			opacity: 0;
		}

		.owl-stage-outer {
			z-index: 1;
		}

		.owl-nav {
			width: calc(100% + 40px);
			margin: 0 -20px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@media (max-width: 987px) {
				width: calc(100% + 25px);
				margin: 0;
			}
		}

		.owl-prev,
		.owl-next {
			width: 20px;
			height: 36px;
			background-color: #CE171F;
			border-radius: 0 5px 5px 0;
			margin-top: -17px;
			position: absolute;
			top: 0;

			@media (max-width: 900px) {
				height: 40px;
				border-radius: 0;
			}
		}

		.owl-prev {
			left: 0;

			@media (max-width: 900px) {
				left: 0 !important;
			}
		}

		.owl-next {
			right: 1px;
		}
	}

	#sync2 {
		width: 100%;
		height: 120px;
		padding: 15px 25px;
		margin: 0 auto;
		overflow: visible;

		&:not(.owl-loaded){
			display: flex;
			flex-wrap: nowrap;
			opacity: 0;
		}

		.owl-nav {
			display: block;
			position: absolute;
			left: -15px;
			top: 50%;
			transform: translateY(-50%);
			width: calc(100% + 23px);

			@media (max-width: 987px) {
				width: 100%;
				left: 0;
			}
		}

		.owl-prev,
		.owl-next {
			width: 40px;
			height: 50px;
			background: none;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;

			svg {
				width: 100%;
				height: 100%;
				fill: #ce171f;
			}
		}

		.owl-prev {
			left: 0;

			@media (max-width: 987px) {
				left: -12px !important;
			}
		}

		.owl-next {
			right: 0;

			@media (max-width: 987px) {
				right: -12px;
			}
		}

		.item {
			height: 88px;
			border: 1px solid #e6e6e6;
			box-sizing: border-box;
		}
	}

	#sync3 {
		@media (max-width: 517px) {
			height: auto !important;
		}
	}

	.car-info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		padding-top: 12px;
		margin-top: 15px;

		@media (max-width: 900px) {
			flex-direction: row;
		}

		&__option-item {
			display: flex;
			align-items: center;
			margin-bottom: 10px;

			@media (max-width: 987px) {
				width: 48%;
			}

			+ .car-info__option-item {
				margin-left: 10px;

				@media (max-width: 987px) {
					margin-left: 0;
				}
			}
		}

		&__text {
			margin-left: 10px;

			p {
				&:first-child {
					font-size: 13px;
					line-height: 18px;
					color: #999999;
				}

				&:last-child {
					font-size: 15px;
					line-height: 18px;
					color: #2c2c2c;
				}
			}
		}
	}

	.birka {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 240px;
		max-width: 100%;
		height: auto;
		padding: 8px;
		background: #ce171f;
		font-size: 16px;
		line-height: 28px;
		color: #fff;
		position: absolute;
		top: 10px;
		right: 3.5%;
		z-index: 1000;
		box-sizing: border-box;

		@media (max-width: 987px) {
			font-size: 14px;
			line-height: 24px;
			padding: 5px;
			right: auto;
			left: 20px;
		}
	}
}