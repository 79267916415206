.header-covid-message {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 52px;
	margin-right: 30px;
	padding: 10px 30px;
	border: 1px solid #d9d9d9;
	background-color: #fff;
	box-sizing: border-box;

	@media (min-width: 1400px) and (max-width: 1600px) {
		flex-wrap: wrap;
		width: 355px;
		height: unset;
		padding: 10px;
		margin-right: 15px;
		text-align: center;
	}

	@media (max-width: 1399px) {
		width: 100%;
		max-width: 100%;
		border-left: 0;
		border-right: 0;
		position: absolute;
		left: 0;
		bottom: -52px;
	}

	@media (max-width: 520px) {
		flex-wrap: wrap;
		height: 76px;
		padding: 10px;
		margin-right: 15px;
		text-align: center;
		bottom: -77px;
	}

	@media (max-width: 363px) {
		height: 86px;
		bottom: -87px;
	}

	&__text {
		display: flex;
		align-items: center;
		font-size: 18px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;

		@media (min-width: 1400px) and (max-width: 1600px) {
			font-size: 16px;
			line-height: 22px;
		}

		@media (max-width: 520px) {
			line-height: 1.2;

			&::before {
				margin-right: 10px;
			}
		}

		@media (max-width: 363px) {
			position: relative;
			padding: 0 30px;
			font-size: 16px;
			line-height: 22px;

			&::before {
				position: absolute;
				left: 0;
				top: -4px;
				margin-right: 0;
			}
		}

		&::before {
			content: '';
			display: inline-block;
			width: 24px;
			height: 24px;
			background-image: url(../img/error_outline.svg);
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center center;
			margin-right: 14px;
		}
	}

	&__read-more {
		margin-left: 14px;
		font-size: 18px;
		line-height: 33px;
		font-weight: 400;
		color: #b72126;
		text-decoration: underline;

		@media (min-width: 1400px) and (max-width: 1600px) {
			width: 100%;
			margin-left: 0;
			font-size: 16px;
			line-height: 22px;
		}

		@media (max-width: 520px) {
			width: 100%;
			line-height: 1;
			margin-left: 0;
		}

		@include hover {
			text-decoration: none;
		}
	}
}