.van-offer {
	.corsa {
		&__tabs-title {
			margin-bottom: 0;

			@media (max-width: 900px) {
				width: 100%;
			}

			&.js-active .corsa__tabs-desc {
				background-color: transparent;
				border: 1px solid #ac1919;
				color: #2c2c2c;

				@media (max-width: 900px) {
					background-color: #b72126;
					color: #fff;
				}
			}
		}

		&__tabs-header {
			display: block;
			position: relative;

			@media (max-width: 900px) {
				margin-top: 7px;
			}

			.owl-stage {
				@media (max-width: 900px) {
					left: -30px;
				}
			}

			&:not(.owl-loaded){
				display: flex;
				flex-wrap: nowrap;
				opacity: 0;
			}

			.owl-nav {
				width: calc(100% + 80px);
				margin: 0 -40px;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);

				@media (max-width: 1400px) {
					width: 100%;
					margin: 0;
				}
			}

			.owl-prev,
			.owl-next {
				background: none;
				width: 40px;
				height: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;

				svg {
					width: 100%;
					height: 100%;
					fill: #ce171f;
				}
			}

			.owl-prev {
				left: 0;

				@media (max-width: 1400px) {
					left: -20px;
				}
			}

			.owl-next {
				right: 0;

				@media (max-width: 1400px) {
					right: -20px;
				}
			}
		}

		&__tabs-desc {
			font-size: 14px;
			line-height: 28px;
			height: 28px;
			padding: 0 25px;
			color: #2c2c2c;

			@media (max-width: 900px) {
				font-size: 13px;
				line-height: 15px;
				height: 40px;
				border-radius: 20px;
				padding: 0 15px;
			}
		}

		&__tabs-img {
			margin-bottom: 3px;
		}

		&__wrapper {
			min-height: auto;
			padding: 10px 0;
			position: relative;

			@media (max-width: 900px) {
				min-height: 80px;

				&::after {
					content: '';
					display: block;
					width: 60px;
					height: 100%;
					background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(245,245,245,1) 100%);
					position: absolute;
					top: 0;
					right: 0;
					pointer-events: none;
				}
			}
		}

		&__tabs-body {
			padding-top: 0;
		}

		.new-corsa-heading-block {
			margin-bottom: 33px;

			@media (max-width: 900px) {
				margin-bottom: 9px;
			}

			&__title {
				font-size: 28px;
				line-height: 36px;

				@media (max-width: 900px) {
					font-size: 24px;
					line-height: 30px;
					margin-top: 0;
				}
			}

			&__subtitle-red {
				font-size: 18px;
				line-height: 26px;
				color: #CE171F;

				@media (max-width: 900px) {
					display: none;
				}

				@media (max-width: 960px) {
					text-align: center;
				}

				&--mobile {
					display: none;

					@media (max-width: 900px) {
						display: block;
						font-size: 16px;
						line-height: 24px;
						color: #2c2c2c;
						text-align: left;
						margin: 30px 0 10px;
					}
				}
			}

			&__subtitle {
				font-size: 14px;
				line-height: 26px;

				@media (max-width: 900px) {
					display: none;
				}

				&--mobile {
					display: none;

					@media (max-width: 900px) {
						display: block;
						font-size: 13px;
						line-height: 22px;
						color: #4c4c4c;
						text-align: left;

					}
				}
			}
		}

		.advance-cash-price {
			display: block;
			padding: 28px 25px 34px;
			margin-bottom: 30px;

			@media (max-width: 900px) {
				display: none;
				margin: 18px -6% 0;
				width: 112%;
				padding: 12px 42px 24px;
			}

			&--mobile {
				display: none;

				@media (max-width: 900px) {
					display: block;
				}
			}

			&__price {
				@media (max-width: 900px) {
					font-size: 30px;
				}
			}

			&__text {
				@media (max-width: 900px) {
					font-size: 14px;
					line-height: 18px;

					br {
						display: none;
					}
				}
			}

			&__top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 1px solid #d9d9d9;
				margin: 0 12px 20px;
				padding-bottom: 15px;

				@media (max-width: 900px) {
					margin: 0 0 9px;
					padding-bottom: 9px;
				}
			}

			&__bottom {
				display: flex;
				margin: 0 12px;
			}

			&__item {
				width: 33.3333%;

				+ .advance-cash-price__item {
					border-left: 1px solid #e6e3e3;
					margin-left: 12px;
					padding-left: 12px;
				}

				p {
					&:first-child {
						font-size: 30px;
						line-height: 28px;

						@media (max-width: 900px) {
							font-size: 24px;
						}
					}

					&:last-child {
						font-size: 12px;
						line-height: 16px;
						text-transform: uppercase;

						@media (max-width: 900px) {
							font-size: 12px;
							line-height: 18px;
						}
					}
				}
			}

			.button-calc {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 5px;
				line-height: 17px;
				padding: 0 10px;

				@media (max-width: 900px) {
					width: 100%;
					margin-top: 14px;
				}

				svg {
					margin-right: 10px;

					@media (max-width: 900px) {
						width: 20px;
						height: 23px;
					}
				}

				span {
					@media (max-width: 900px) {
						font-size: 16px;
						text-transform: none;
					}
				}
			}
		}

		#slider1,
		#slider2 {
			border-bottom: none;
			width: 97.5%;
			overflow: visible;

			.car-info {
				&__slide {
					height: 493px;

					@media (max-width: 900px) {
						height: 231px;
					}
				}

				&__img {
					margin-right: 1px;
				}
			}

			&:not(.owl-loaded){
				display: flex;
				flex-wrap: nowrap;
				opacity: 0;
			}

			.owl-stage-outer {
				z-index: 1;
			}

			.owl-nav {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: calc(100% + 40px);
				margin: 0 -20px;
			}

			.owl-prev,
			.owl-next {
				width: 20px;
				height: 36px;
				background-color: #CE171F;
				border-radius: 0 5px 5px 0;
				margin-top: -17px;
				position: absolute;
				top: 0;

				@media (max-width: 900px) {
					height: 40px;
					border-radius: 0;
				}
			}

			.owl-prev {
				left: 0;

				@media (max-width: 900px) {
					left: 0 !important;
				}
			}

			.owl-next {
				right: 1px;
			}
		}

		#thumb1,
		#thumb2 {
			width: 90%;
			margin: 76px auto 0;
			overflow: visible;

			@media (max-width: 900px) {
				margin: 66px auto 0;
			}

			.car-info {
				&__slide {
					border: 1px solid #e6e6e6;
					height: 98px;
					box-sizing: border-box;

					@media (max-width: 900px) {
						height: 78px;
					}
				}
			}

			&:not(.owl-loaded){
				display: flex;
				flex-wrap: nowrap;
				opacity: 0;
			}

			.owl-item.current .car-info__slide {
				border-color: #ce171f;
			}

			.owl-nav {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				width: calc(100% + 80px);
				margin: 0 -40px;

				@media (max-width: 900px) {
					width: calc(100% + 60px);
					margin: 0 -30px;
				}
			}

			.owl-prev,
			.owl-next {
				background: none;
				width: 40px;
				height: 50px;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;

				svg {
					width: 100%;
					height: 100%;
					fill: #ce171f;
				}
			}

			.owl-prev {
				left: 0;

				@media (max-width: 900px) {
					left: -12px !important;
				}
			}

			.owl-next {
				right: 0;

				@media (max-width: 900px) {
					right: -12px;
				}
			}
		}

		.car-info {
			margin-top: 20px;
			padding-bottom: 14px;

			@media (max-width: 900px) {
				margin-top: 0;
				padding-bottom: 20px;
			}

			&__title {
				margin-bottom: 20px;

				@media (max-width: 900px) {
					font-size: 20px;
					margin-bottom: 0;
				}
			}

			&__flex {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: space-between;
			}

			&__options-row {
				display: flex;
				flex-wrap: wrap;
			}

			&__option-item {
				display: flex;
				align-items: center;
				margin-bottom: 10px;

				@media (max-width: 900px) {
					width: 48%;
				}

				+ .car-info__option-item {
					margin-left: 10px;

					@media (max-width: 900px) {
						margin-left: 0;
					}
				}
			}

			&__text {
				margin-left: 10px;

				p {
					&:first-child {
						font-size: 13px;
						line-height: 18px;
						color: #999999;
					}

					&:last-child {
						font-size: 15px;
						line-height: 18px;
						color: #2c2c2c;
					}
				}
			}

			&__left-side {
				overflow: visible;

				@media (max-width: 900px) {
					padding-bottom: 14px;
					margin-bottom: 30px;
					border-bottom: 1px solid #e1e1e1;
				}
			}

			&__control {
				border-radius: 5px;
			}

			&__controls {
				position: absolute;
				top: 493px;
				left: 0;
				width: 100%;
				padding-top: 10px;
				padding-bottom: 10px;
				margin-bottom: 15px;
				border-bottom: 1px solid #e1e1e1;

				@media (max-width: 900px) {
					top: 231px;
					padding-top: 5px;
				}
			}

			.js-expand {
				margin-top: 15px;
			}
		}
	}

	.personalise {
		padding-bottom: 28px;

		@media (max-width: 900px) {
			border-top: 1px solid #e1e1e1;
		}

		&__inner {
			@media (max-width: 900px) {
				padding: 19px 0 0;
			}

			.personalise__row {
				margin-bottom: 0 !important;
			}
		}

		.finance-calc__title {
			@media (max-width: 900px) {
				text-align: center;
			}
		}

		.finance__block .row {
			display: block;

			.blk {
				width: 100%;
			}
		}

		.radiogroup-v2 {
			margin: 7.5px 0;
			flex-direction: column;

			&__lbl {
				width: 100%;
				margin: 7.5px 0;

				span {
					border-radius: 5px;
				}
			}
		}

		.check-holyday {
			margin-left: 0;
		}

		&__ext {
			margin: 22px 0 0;

			.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 236px;
				line-height: 0;
				box-sizing: border-box;
				height: 30px;
				border-radius: 5px;

				@media (max-width: 900px) {
					height: 35px;
				}
			}
		}

		&__row {
			margin-bottom: 0 !important;
		}
	}

	.cr-car-videos-wrap {
		padding-top: 53px;
		padding-bottom: 9px;
		border-bottom: none;
		border-top: 2px solid #e1e1e1;

		@media (max-width: 900px) {
			display: none;
		}

		.cr-car-videos-title {
			@media (max-width: 900px) {
				text-align: left;
			}
		}

		.cr-car-videos {
			margin-top: 12px;

			.owl-dots {
				justify-content: flex-start;
			}

			.owl-dot {
				&.active,
				&:hover {
					background-color: #ce171f;
					border-color: #ce171f;
				}
			}
		}

		.cr-yt-video-wrap::before {
			padding-top: 56%;

			@media (max-width: 900px) {
				padding-top: 54%;
			}
		}

		&--mobile {
			display: none;

			@media (max-width: 900px) {
				display: block;
				border-top: none;
				padding-top: 14px;
				padding-left: 13px;
				margin-bottom: 60px;
			}
		}
	}

	.corsa-remake {
		&__flex {
			padding-bottom: 15px;
		}

		&__left-side {
			@media (min-width: 901px) and (max-width: 1200px) {
				width: 60%;
				margin-right: 20px;
			}
		}

		&__right-side {
			.button-calc {
				height: 46px;
				line-height: 46px;
				margin-top: 18px;
				border-radius: 5px;

				span {
					font-size: 16px;
				}
			}

			.btn--blue {
				height: auto;
				line-height: 28px;
				border-radius: 5px;
				padding: 9px;

				+ .btn--blue {
					margin-top: 15px;
				}

				p {
					font-size: 14px;
					line-height: 16px;
					color: #a7c1ea;
					text-transform: none;
				}
			}
		}

		&--mob-accordions {
			@media (max-width: 900px) {
				margin-bottom: 20px;
			}
		}
	}
}
