.car-info {
	display: flex;
	align-items: flex-start;
	padding-bottom: 26px;
	padding-top: 55px;

	&__left-side {
		width: 65.8%;
		flex-shrink: 0;
		margin-right: 40px;
		position: relative;
		overflow: hidden;
	}

	&__right-side {
		position: relative;
	}

	&__slider {
		width: 100%;
		height: 343px;
		visibility: hidden;
		overflow: hidden;
		opacity: 0;
		position: absolute;

		.js-slider-1 {
			margin-bottom: 50px;
		}

		.owl-next {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			background-image: url(../img/controls/arrow.svg);
			background-color: rgba(128, 128, 128, 0.5);
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: 50%;
			right: 0;
			cursor: pointer;
			transition: 0.3s;
		}

		.owl-prev {
			width: 40px;
			height: 40px;
			margin-top: -20px;
			background-image: url(../img/controls/arrow.svg);
			background-color: rgba(128, 128, 128, 0.5);
			background-repeat: no-repeat;
			background-position: center center;
			position: absolute;
			top: 50%;
			left: 0;
			cursor: pointer;
			transform: rotate(180deg);
			transition: 0.3s;
		}
	}

	&__slider-control {
		@media (max-width: 500px) {
			top: 208px;
		}
	}

	&__slide {
		height: 570px;
		position: relative;
	}

	&__content {
		&.js-active > .car-info__slider {
			margin-top: 0;
			margin-left: 0;
			height: auto;
			opacity: 1;
			visibility: visible;
			position: relative;
		}
	}

	&__img {
		position: relative;
		height: 100%;

		> div {
			display: block;
			position: relative;
			height: 100%;
			width: 100%;
			background: no-repeat center / cover;
		}
	}

	&__controls {
		display: flex;

		&--bottom {
			width: 100%;
			padding-right: 10px;
			position: absolute;
			left: 0;
			top: 520px;
			z-index: 98;
			box-sizing: border-box;
		}
	}

	&__control {
		height: 40px;
		background: rgba(0, 0, 0, 0.3) no-repeat center;

		&.js-active {
			background-color: #ce171f;
		}

		&--small {
			display: none;
			width: 40px;

			&.active {
				display: block;
			}
		}

		&--big {
			width: 40px;
		}

		&--margin-auto {
			margin-left: auto;
		}

		&--margin-right {
			margin-right: 12px;
		}
	}

	&__title {
		font-size: 18px;
		line-height: 21px;
		color: #2c2c2c;
		font-weight: bold;

		&--top {
			margin-bottom: 30px;
		}

		&--top-tiny {
			margin-bottom: 30px;
			font-size: 24px;
			line-height: 27px;
		}

		&--margin-left {
			font-size: 24px;
			line-height: 27px;
			padding-bottom: 24px;
			margin-bottom: 27px;
			border-bottom: 2px solid #eeeeee;
		}
	}

	&__item {
		padding-left: 11px;
		margin-bottom: 15px;
		position: relative;
		font-size: 16px;
		line-height: 18px;
		color: #2c2c2c;
		box-sizing: border-box;

		&:before {
			content: '';
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #212121;
			position: absolute;
			top: 6px;
			left: 0;
		}
	}

	&__options {
		padding: 30px 0 0 0;
		margin-top: 20px;
		border-top: 2px solid #eeeeee;
		box-sizing: border-box;
	}

	&__options-row {
		-webkit-columns: 4;
		-moz-columns: 4;
		columns: 4;
	}

	&__option {
		margin-bottom: 5px;
	}

	&__option-title {
		margin-bottom: 5px;
		font-size: 15px;
		line-height: 18px;
		font-weight: bold;
		color: #2c2c2c;
		text-align: center;
	}

	&__option-icon {
		width: 60px;
		line-height: 30px;
		text-align: center;
		margin: 0 auto;
		height: 30px;
		margin-bottom: 25px;
		vertical-align: middle;

		img {
			display: inline-block;
			vertical-align: middle;
			margin-left: auto;
			margin-right: auto;
		}

		span {
			display: block;
			width: 100%;
			height: 100%;
			background: no-repeat center;
		}
	}
}
