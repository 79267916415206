.winter-service {
	padding-bottom: 100px;

	.title_h2 {
		font-size: 18px;
		line-height: 21px;

		b {
			font-weight: 500;
		}

		a {
			display: inline-block;
			margin-left: 10px;
			font-size: 14px;
			color: #ce171f;
			text-decoration: underline;
			vertical-align: top;
		}
	}

	.page-main-title {
		margin-bottom: 43px;
	}

	.big_banner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 380px;
		padding: 30px 65px;
		margin-bottom: 50px;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		text-transform: uppercase;
		box-sizing: border-box;

		@media (max-width: 768px) {
			height: 164px;
			padding: 20px;
		}

		&__border {
			border: 1px solid #cccccc;

			@media (max-width: 768px) {
				height: auto;
				background-position: left center;
			}
		}

		.ah2 {
			font-size: 36px;
			line-height: 46px;
			color: #2c2c2c;
			max-width: 400px;
			margin-bottom: 21px;
			font-weight: bold;

			@media (max-width: 768px) {
				max-width: 230px;
				font-size: 21px;
				line-height: 31px;
			}

			&.white{
				color: #fff;
			}
		}

		.ah3 {
			max-width: none !important;
			margin-top: 70px;
			margin-bottom: 0;
			font-size: 24px;
			line-height: 27px;
			text-align: left;
			text-transform: none;
		}

		.ah4 {
			margin-bottom: 5px;
			font-size: 27px;
			line-height: 30px;
			text-align: left;
			text-transform: none;
		}

		.button--red {
			display: block;
			width: 275px;
			font-size: 18px;
			line-height: 45px;
			text-align: center;
		}
	}

	.ah3 {
		max-width: 630px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 14px;
		font-size: 24px;
		line-height: 33px;
		color: #2c2c2c;
		text-align: center;
	}

	.ah4 {
		margin-bottom: 70px;
		font-size: 16px;
		line-height: 26px;
		text-align: center;

		p + p {
			max-width: 755px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.second_block {
		margin-bottom: 60px;
		padding-bottom: 70px;
		overflow: hidden;
		position: relative;

		&:before {
			content: '';
			width: 100%;
			height: 542px;
			background: #f7f7f7;
			position: absolute;
			left: 0;
			bottom: 0;
		}

		.row {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: flex-end;
			margin-bottom: 21px;
			position: relative;

			@media (max-width: 999px) {
				flex-direction: column;
				justify-content: center;
			}

			.block {
				width: 32%;
				position:relative;
				padding-bottom: 90px;
				text-align: center;
				border: 1px solid #d9d9d9;
				border-top: 6px solid #ce171f;
				background: #ffffff;

				@media (max-width: 999px) {
					width: 100%;
					margin-bottom: 30px;
				}

				.image_block {
					display: flex;
					justify-content: flex-end;
					align-items: center;
					flex-direction: column;
					height: 105px;
					margin-bottom: 10px;
				}

				.ah3 {
					margin-bottom: 8px;
					padding-left: 20px;
					padding-right: 20px;
					font-size: 24px;
					line-height: 24px;
					font-weight: bold;
					color: #2c2c2c;

					@media (max-width: 768px) {
						font-size: 22px;
					}
				}

				.price {
					font-size: 20px;
					line-height: 23px;
					color: #124390;
				}

				ul {
					border-top: 1px solid #e6e6e6;
					margin-top: 20px;
					margin-bottom: -45px;
					padding: 25px 24px 1px;

					li {
						padding-left: 17px ;
						margin-bottom: 10px;
						font-size: 14px;
						line-height: 22px;
						text-align: left;
						position: relative;

						&:before {
							content: '';
							width: 7px;
							height: 7px;
							background-color: #ce171f;
							position: absolute;
							left: 0;
							top: 4px;
						}
					}
				}

				&:first-child {
					padding-top: 45px;
				}

				&:last-child {
					padding-top: 45px;
				}

				&__image {
					min-height: 260px;
					align-items:flex-start;
					padding-top: 25px;
				}
			}
		}

		.row2 {
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			@media (max-width: 999px) {
				flex-direction: column;
				justify-content: center;
			}

			.block {
				width: 49%;
				height: 198px;
				border:1px solid #d9d9d9;
				background: #fff;
				position: relative;

				@media (max-width: 999px) {
					width: 100%;
					margin-bottom: 30px;
				}

				@media (max-width: 768px) {
					height: auto;
				}

				.image_block {
					float: left;
					height: 198px;
					width: 237px;
					text-align: center;

					@media (max-width: 768px) {
						float: none;
						width: auto;
						margin-bottom: 10px;
					}
				}

				.text {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 198px;
					position: relative;
					overflow: hidden;

					@media (max-width: 768px) {
						align-items: center;
					}

					.ah3 {
						margin-left: 0;
						margin-right: 20px;
						margin-bottom: 5px;
						line-height: 24px;
						font-weight: bold;
						text-align: left;

						@media (max-width: 768px) {
							font-size: 22px;
							margin-right: 0;
							text-align: center;
							padding-left: 20px;
							padding-right: 20px;
						}
					}

					.price {
						font-size: 20px;
						line-height: 23px;
						color: #124390;
					}
				}
			}
		}
	}

	.reg-and-mile {
		max-width: 755px;
		margin-left: auto;
		margin-right: auto;

		.tel-block {
			margin-top: -25px;
			margin-bottom: 25px;
			font-size: 16px;
			line-height: 23px;
			text-align: center;
		}

		.tel-block a {
			margin-left: 10px;
			text-decoration: none !important;
			font-size: 20px;
			color: inherit;
		}

		.title {
			margin-bottom: 33px;
			font-family: "Vauxhall Neue";
			font-size: 24px;
			font-weight: 700;
			line-height: 33px;
			color: #2c2c2c;
			text-align: center;
		}

		.ah2 {
			margin-bottom: 20px;
			font-size: 18px;
			line-height: 21px;
			font-weight: 700;
			text-align: center;
		}

		.row2 {
			padding-bottom: 15px;
		}

		.row2 .input-block {
			display: flex;
			justify-content: space-between;
			max-width: 927px;
			margin-left: auto;
			margin-right: auto;
			margin-top: 10px;

			@media (max-width: 768px) {
				flex-wrap: wrap;
			}

			.radio-btn {
				display: flex;
				margin: 0 35px;

				@media (max-width: 768px) {
					margin-bottom: 10px;
				}

				input[type=radio] {
					max-width: 15px;
					min-width: 15px;
					width: 100%;
					height: 15px;
					margin: 0;
					margin-right: 8px;
					border: 1px solid #a5a5a5;
					border-radius: 25px;
					cursor: pointer;
					vertical-align: middle;
					outline: 0 !important;
					-webkit-appearance: none;
					-moz-appearance: none;
				}
				input[type=radio]:checked {
					background: #fff url(../img/radio.svg) no-repeat center center;
				}
			}
		}

		.row {
			display: flex;
			justify-content: space-between;

			@media (max-width: 768px) {
				display: block;
			}

			&.border-top-row {
				padding-top: 20px;
				border-top:1px solid #cccccc;
			}

			&.border-row {
				padding-bottom: 22px;
				border-bottom: 1px solid #cccccc;

				.input-block:not(.input-standart) {
					width: 300px;
					margin-right: 35px;
				}

				.input-block {
					.row {
						margin-bottom: 35px;

						@media (max-width: 768px) {
							display: flex;
						}

						.btn {
							align-self: flex-end;
							flex-shrink: 0;
							width:88px;
							margin-left: 10px;

							&:before {
								content: '';
								display: inline-block;
								width: 11px;
								height: 17px;
								background: url("../img/map-mark.svg") no-repeat center center;
								background-size: contain;
								margin-top: -2px;
								margin-right: 5px;
								vertical-align: middle;
							}

							&.no-img:before {
								display: none;
							}
						}
					}
				}
			}

			.input-block {
				flex-shrink: 0;
				width: calc(50% - 10px);
				position: relative;

				@media (max-width: 768px) {
					width: 100%;
					max-width: 100%;
					margin-bottom: 20px;
				}

				.ah1 {
					font-size: 24px;
					line-height: 27px;
					font-weight: 700;
					margin-bottom: 25px;
				}

				label {
					line-height: 17px;
				}

				label:not(.checkbox-container) {
					display: inline-block;
					margin-bottom: 1rem;
					font-size: 16px;
					color: #2c2c2c;
					vertical-align: top;
					user-select: none;
				}

				input:not([type=checkbox]):not([type=radio]) {
					width: 100%;
					padding: 12px 15px;
					border: 1px solid #a5a5a5;
					font-size: 16px;
					line-height: 1em;
					box-sizing: border-box;
					-webkit-appearance: none;
					-moz-appearance: none;
					outline: 0;
				}

				&.gb-reg-number input {
					padding-left: 57px !important;
				}
			}

			.form-map {
				width: 100%;
				height: 370px;
				border: 1px solid #cccccc;

				iframe {
					display: block;
					width: 100%;
					height: 100%;
				}
			}

			.input-block.gb-reg-number::before {
				content: '';
				display: block;
				width: 40px;
				height: 40px;
				min-width: 40px;
				min-height: 40px;
				border-top-left-radius: 3px;
				border-bottom-left-radius: 3px;
				background: url(../img/new-gb-rn.png);
				position: absolute;
				left: 0;
				top: 35px;
			}
		}

		.btn--blue {
			width: 100%;
			max-width: 365px;
			margin-top: 39px;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;
		}
	}

	.table-wrap {
		margin-bottom: 38px;

		table {
			width: 100%;
			border-bottom: 1px solid #e6e6e6;

			tr:nth-child(even) {
				background: #f7f7f7;
			}

			td {
				width: 25%;
				padding: 13px 18px;
				border-top: 1px solid #e6e6e6;
				vertical-align: middle;

				.ah3 {
					margin-bottom: 5px;
					font-size: 13px;
					line-height: 17px;
					text-align: left;
					color: #9da4b0;

					&.red {
						color: #ce181e;
					}
				}
			}
		}
	}

	label {
		display: flex;
		font-family: "Vauxhall Neue";
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: #2c2c2c;

		input {
			width: 20px;
			min-width: 20px;
			height: 20px;
			margin-right: 9px;
			margin-bottom: 0;
			margin-top: 0;
			border: 1px solid #a5a5a5;
			background-color: #fff;
			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				background-image: url(../img/checked.svg);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100%;
			}
		}
	}

	.disabled-pointer label span.red {
		color: inherit;
	}

	.input-row {
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		margin: 19px auto 0;

		@media (max-width: 768px) {
			display: block;
		}

		.input-block {
			max-width: 48.3%;
			width: 100%;

			@media (max-width: 768px) {
				width: 100%;
				max-width: 100%;
				margin-bottom: 20px;
			}
		}
	}

	.border-block {
		margin-top: 40px;
		padding-top: 25px;
		border-top: 1px solid #d7d7d7;
	}

	/* Special-offer page */
	&.special-offer {
		.ah3 {
			max-width: 750px;
		}

		.ah4 {
			text-align: left;
		}

		.second_block {
			padding-bottom: 0;

			&:before {
				height: 50%;
				bottom: 25%;
			}
		}

		.second_block .row {
			align-items: center;
			margin-bottom: 0;

			&.row-center {
				justify-content: center;

				.block {
					margin-left: 10px;
					margin-right: 10px;
				}
			}
		}

		.second_block .row .block {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-bottom: 50px;

			& > div {
				text-align: left;
			}

			.image_block {
				justify-content: center;
				align-items: flex-end;
				flex-shrink: 0;
				width: 50%;
				padding-right: 15px;
				margin-bottom: 0;
				box-sizing: border-box;

				@media (max-width: 999px) {
					width: auto;
				}
			}

			.ah3 {
				padding-left: 0;
				text-align: left;
			}

			&:nth-child(3) {
				.image_block {
					width: 135px;
					max-width: 50%;
				}
			}

			&__image {
				align-items: flex-start;
				justify-content: flex-start;
				padding-left: 20px;
				padding-right: 20px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				font-size: 20px;
				line-height: 26px;
				color: #fff;
				box-sizing:border-box;

				.ah3 {
					text-transform: uppercase;
					color: #fff;
				}
			}
		}

		.reg-and-mile .title {
			font-size: 32px;
			line-height: 35px;
			text-transform: uppercase;
		}
	}

	/* Arrange-collection page */
	&.arrange-collection {
		.ah1-mini-title {
			font-size: 16px;
		}

		.ah1-title {
			margin-top: 5px;
			margin-bottom: 25px;
			font-size: 24px;
			line-height: 26px;
			font-weight: 500;

			span.grey {
				display: inline-block;
				font-size: 16px;
				font-weight: 400;
				color: #737373;
				vertical-align: middle;
			}

			span.mr10 {
				margin-right: 10px;
			}
		}

		.my_tabs ul {
			margin-bottom: 34px;

			li {
				display: inline-block;
				vertical-align: top;

				@media (max-width: 768px) {
					display: block;
				}

				+ li {
					margin-left: 15px;

					@media (max-width: 768px) {
						margin-top: 10px;
						margin-left: 0;
					}
				}

				a {
					display: block;
					padding-left: 40px;
					padding-right: 20px;
					border:1px solid #a5a5a5;
					font-size: 16px;
					line-height: 42px;
					color: #2c2c2c;
					position: relative;

					@media (max-width: 768px) {
						font-size: 14px;
					}

					&::before {
						content: '';
						width: 20px;
						height: 20px;
						margin-top: -10px;
						border-radius: 50%;
						background: #fff;
						border:1px solid #a9a9a9;
						box-sizing: border-box;
						position: absolute;
						left: 11px;
						top: 50%;
					}

					&.active {
						background: #ce181f;
						border-color: #ce181f;
						color: #fff;

						&::before {
							border:5px solid #fff;
							background: #ce181f;
						}
					}
				}
			}
		}

		.reg-and-mile {
			max-width: 993px;

			.row.no-flex {
				display: block;
			}

			.row.item {
				display: none;
			}

			.row.item.active {
				display: flex;

				@media (max-width: 768px) {
					display: block;
				}
			}

			.row.item.active.no-flex {
				display: block;
			}

			.row .input-block .ah1 {
				font-size: 23px;
				font-weight: 400;
			}
		}

		.input-row {
			max-width: 653px;
			margin-left: 0;
		}

		.ah3.alLeft {
			max-width: none;
			text-align: left;
			margin-top: 40px;
		}

		.red_num {
			padding-left: 0;
			list-style: none;
			color: #4c4c4c;
			counter-reset: Counter;

			li {
				margin-bottom: 12px;

				&:before {
					counter-increment: Counter;
					content: counter(Counter) '.';
					display: inline-block;
					margin-right: 16px;
					color: #ce171f;
					font-weight: bold;
					vertical-align: top;
				}
			}
		}

		.button-width-text {
			display: flex;
			align-items: center;
			padding-top: 30px;

			@media (max-width: 768px) {
				align-items: flex-start;
				flex-direction: column
			}

			.btn--blue {
				width: 266px;
				margin-top: 0;
				margin-right: 30px;
				flex-shrink: 0;

				@media (max-width: 768px) {
					margin-right: auto;
					margin-bottom: 15px;
				}
			}

			.text {
				width: 100%;
				padding-right: 45px;
				font-size: 15px;
				line-height: 24px;
				box-sizing: border-box;

				@media (max-width: 768px) {
					padding-right: 0;
					text-align: center;
				}
			}
		}
	}
}
