.exclusive {
	max-width: 801px;
	border-top: 4px solid #ce171f;
	padding: 45px 30px;
	position: relative;
	overflow: hidden;

	@media (min-width: 1024px) {
		padding: 43px 60px 48px 78px;
	}

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: 356px;
		background-color: #2a59a4;
		transform: skewY(3deg);
		position: absolute;
		left: 0;
		bottom: -22px;
	}

	&__content {
		position: relative;
		z-index: 1;
	}

	&__date {
		font-size: 24px;
		color: #124390;
		margin-bottom: 6px;
	}

	&__title {
		font-size: 30px;
		line-height: 32px;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: 10px;

		@media (min-width: 1024px) {
			font-size: 40px;
			line-height: 48px;
		}
	}

	&__subtitle {
		font-size: 24px;
		line-height: 27px;
		color: #ce171f;
		text-transform: uppercase;
		margin-bottom: 25px;
	}

	img {
		width: 113%;
		height: 100%;
		margin-left: -80px;
	}

	&__bottom-note {
		font-size: 18px;
		line-height: 26px;
		color: #fff;
		text-align: center;
		margin: 9px 0 20px;

		br {
			display: none;

			@media (min-width: 1024px) {
				display: block;
			}
		}
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 46px;
		margin: 0 auto;
		border: 1px solid #fff;
		background-color: #2a59a4;
		font-size: 18px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		transition: all 0.3s ease;

		@media (min-width: 1024px) {
			width: 266px;

			@include hover {
				background-color: #fff;
				color: #2a59a4;
			}
		}
	}

	.fancybox-close-small {
		width: 48px;
		height: 48px;
		top: 4px;
		right: 4px;
		color: #ce181f;
		opacity: 1;
		transition: opacity 0.3s ease;

		@include hover {
			opacity: 0.7;
		}
	}
}