.users-management {
	.filters {
		margin-bottom: 5px !important;

		.blue--btn:before {
			width: 22px !important;
			height: 16px !important;
			background: url("../img/add-user.png") center center no-repeat !important;
		}

		.input-block {
			display: flex;
			justify-content: space-between !important;
			margin-bottom: 0 !important;

			.row-bnts-filter {
				display: flex;

				.reset {
					width: auto;
					margin-left: 18px;
				}
			}
		}
	}

	.log .table .row .col {
		border-bottom: #d9d9d9 1px solid;
		padding: 15px !important;

		&.btns-col {
			width: 210px !important;
		}

		.btns-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 10px;
		}

		.btn {
			margin: 0 !important;
			width: auto !important;

			&.edit-btn {
				display: block;
				vertical-align: middle;
				width: 30px !important;

				&::before {
					content: '';
					display: inline-block;
					width: 15px;
					height: 15px;
					margin-right: 0;
					margin-bottom: 0;
					background: url("../img/edit.png") center center no-repeat;
					background-size: contain;
					vertical-align: middle;
				}
			}

			&.button--delete {
				display: block;
				width: 30px !important;
				font-size: 14px;
				font-weight: 400;
				color: #ce171f;
				text-decoration: underline;
				vertical-align: middle;

				&::before {
					content: '';
					display: inline-block;
					width: 14px;
					height: 14px;
					margin-right: 0;
					margin-bottom: 0;
					background: url("../img/delete.png") center center no-repeat;
					background-size: contain;
					vertical-align: middle;
				}
			}

			&.button--block {
				display: block;
				height: 25px;
				font-size: 14px;
				line-height: initial;
				font-weight: 400;
				color: #ce171f;
				text-decoration: underline;
			}


			&.button--unblock,
			&.button--change {
				color: #ce171f;
				font-size: 14px;
				font-weight: 400;
				text-decoration: underline;
				display: block;
				height: 25px;
				line-height: initial;

				@include hover {
					text-decoration: none;
				}
			}

			&.reset-btn {
				display: block;
				height: 25px;
				font-size: 14px;
				line-height: initial;
				color: #ce171f;
			}
		}
	}

	.btn-details-um {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100px;
		height: 30px;
		padding: 6px 11px 7px 9px;
		font-size: 16px;
		font-weight: 400;
		line-height: 1;
		text-align: right;
		transition-duration: 0.2s;
		box-sizing: border-box;

		&::before {
			content: '';
			display: block;
			width: 22px;
			height: 19px;
		}

		&:not(.btn-details-um--alert) {
			color: #ce171f;
			background-color: #f7f7f7;
			border: 1px solid #ce171f;

			&::before {
				background: url(../img/htr.png) no-repeat center center;
				background-size: contain;
			}

			@include hover {
				background-color: #ffcbce;
			}
		}

		&--alert {
			background-color: #ce171f;
			color: #ffffff;

			&::before {
				background: url(../img/problem-white.png) no-repeat center center;
				background-size: contain;
			}

			@include hover {
				background-color: #841215;
			}
		}
	}
}
