.sell-car-page {
	.booking__page--err_block {
		margin-bottom: 26px;
		font-size: 18px;
		line-height: 24px;
		text-align: center;

		.inn_err_block {
			margin-bottom: 30px;
			text-align: center;
		}

		.ah1 {
			font-size: 30px;
			margin-bottom: 30px;
		}

		p {
			font-size: 14px;
			line-height: 1.5em;
			margin-bottom: 15px;
		}

		.tel {
			color: inherit;
			font-size: 19px;
			transition: color 0.3s ease;
			
			@include hover {
				color: #ce181f;
			}

			&::before {
				content: '';
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-right: 7px;
				background: url(../img/err.svg) no-repeat;
			}
		}
	}

	.owl-stage-outer {
		transition: height 0.3s linear;
	}

	.slides {
		width: 100%;
		height: 100%;

		&:not(.owl-loaded) {
			display: flex;
			opacity: 0;
		}

		.slide {
			min-height: calc(100vh - 203px);
			padding-bottom: 80px;
			box-sizing: border-box;

			.btns-row {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				justify-content: center;
				margin-top: 10px;

				.btn {
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 10px;
				}
			}

			.btn-prev-slide {
				width: 200px;
				font-family: 'Vauxhall Neue', sans-serif;
				font-size: 15px;
				font-weight: 400;
				color: #fff;
				cursor: pointer;

				svg {
					transform: rotate(180deg);
				}
			}

			.content {
				.inner {
					height: 100%;
				}

				.big-block-title {
					font-size: 30px;
					line-height: 33px;
					font-weight: 700;
					text-transform: uppercase;

					@media (max-width: 600px) {
						font-size: 28px;
						line-height: 30px;
					}
				}

				.block-title {
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 29px;

					@media (max-width: 600px) {
						font-size: 16px;
						margin-bottom: 30px;
					}

					&.fz24 {
						font-size: 24px;
						line-height: 1;
						font-weight: 400;
						margin-bottom: 29px;
					}
				}

				.text {
					font-size: 15px;
					line-height: 26px;
					font-weight: 400;
				}

				.sub-text {
					width: 365px;
					max-width: 365px;
					font-size: 14px;
					line-height: 24px;
					font-weight: 400;
					color: #757575;

					@media (max-width: 600px) {
						text-align: center;
					}

					&.center {
						text-align: center;
					}
				}

				.btn-next-slide {
					width: 200px;
					font-size: 15px;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;
				}

				.private-policy {
					font-size: 13px;
					line-height: 16px;
					font-weight: bold;
					text-decoration: underline;
					color: #757575;

					@include hover {
						text-decoration: none;
					}
				}
			}

			.rows {
				margin-top: 35px;

				&.w560 {
					width: 560px;

					@media (max-width: 600px) {
						width: 100%;
					}
				}
			}

			.row {
				max-width: 830px;
				width: 100%;
				@include flex-row(30px, 20px);

				@media (max-width: 600px) {
					width: 100%;
					margin-left: 0;
					margin-top: -20px;
				}

				.input-block {
					@include flex-block(250px, 30px, 20px, false, 2);

					@media (max-width: 600px) {
						margin-left: 0;
						width: 100%;
						min-width: 100%;
					}

					label {
						width: 100%;
					}
				}
			}

			.btn-next-slide {
				margin-top: 0;
			}

			.block-title {
				text-align: center;
			}

			.center-block {
				display: flex;
				align-items: center;
				flex-direction: column;
				padding-top: 50px;
			}

			.private-policy {
				margin-top: 13px;
			}

			.sub-text {
				max-width: 330px;
				margin-top: 16px;
			}

			&.slide-1 {
				padding-bottom: 0;

				.content {
					.inner {
						max-width: 1080px;
					}

					.flex-block {
						display: flex;
						justify-content: space-between;
						box-sizing: border-box;
						height: 100%;
						min-height: calc(100vh - 300px);
						padding-top: 90px;

						@media (max-width: 600px) {
							padding-top: 50px;
							flex-direction: column-reverse;
							align-items: center;
						}

						.left-block {
							display: flex;
							align-items: flex-end;
							justify-content: center;
							width: 48%;

							@media (max-width: 600px) {
								margin-top: 30px;
							}

							img {
								display: block;
								max-width: 100%;
								max-height: 100%;
							}
						}

						.right-block {
							width: 46.3%;
							padding-top: 50px;
							padding-bottom: 30px;

							@media (max-width: 600px) {
								width: 90%;
								text-align: center;
								padding-top: 0;
								padding-bottom: 0;
							}

							.block-title {
								margin-top: 7px;
								text-align: left;

								@media (max-width: 600px) {
									text-align: center;
								}
							}

							.text {
								margin-top: 30px;
							}

							.btn-next-slide {
								width: 248px;
								margin-top: 41px;

								@media (max-width: 600px) {
									margin-left: auto;
									margin-right: auto;
								}
							}

							.sub-text {
								margin-top: 19px;

								@media (max-width: 600px) {
									max-width: 100%;
									width: 90%;
								}
							}
						}
					}
				}
			}

			&.slide-3 {
				.block-title {
					margin-bottom: 35px;

					img {
						margin-left: 15px;
					}

					svg {
						fill: #1abb64;
						stroke: #1abb64;
						margin-right: 5px;
					}
				}

				.content {
					.inner {
						max-width: 516px;

						.center-block {
							justify-content: space-between;
							min-height: calc(100vh - 375px);
							box-sizing: border-box;

							.rows {
								margin-top: 10px;

								@media (max-width: 600px) {
									margin-top: 25px;
								}

								.input-block {
									display: flex;
									justify-content: space-between;

									@media (max-width: 600px) {
										display: block;
									}

									label {
										flex-shrink: 0;
										width: 200px;
									}

									input[type="text"] {
										height: 21px;
										padding: 0;
										border: 0;
										border-bottom: 1px solid #000;
										line-height: 19px;
									}
								}
							}

							> div {
								width: 100%;
							}
						}
					}
				}

				.vehicle-name {
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-left: 20px;
					margin-bottom: 18px;

					@media (max-width: 600px) {
						flex-direction: column;
					}

					&__text {
						font-size: 16px;
						height: 20px;

						@media (max-width: 600px) {
							text-align: center;
							margin-bottom: 10px;
						}
					}

					&__link {
						display: flex;
						align-items: center;
						font-size: 14px;
						color: #ce171f;
						text-decoration: underline;
						white-space: nowrap;

						@include hover {
							text-decoration: none;
						}

						svg {
							fill: #ce171f;
							margin-right: 5px;
						}
					}
				}

				.vehicle-info {
					margin-bottom: 18px;

					table {
						width: 100%;
						
						tr {
							&:nth-child(odd) {
								background-color: #f7f7f7;
							}
						}

						td {
							width: 50%;
							padding: 9.7px 20px;
							font-size: 15px;

							@media (max-width: 600px) {
								font-size: 14px;
								padding: 8px;
							}
						}
					}
				}
			}

			&.slide-4 {
				.images-upload-block {
					max-width: 620px;
					margin: 0 auto 21px;

					.label {
						font-size: 16px;
						color: #757575;
						text-align: center;

						span {
							color: #2c2c2c;
						}
					}

					.grid-block {
						.item {
							.title {
								font-weight: 400;
							}

							.status {
								font-size: 12px;
								font-weight: 400;
							}

							&.uploaded {
								background-color: #ce171f;

								.status {
									padding-right: 0;
									background: none;
								}

								.img-block {
									width: 36px;
									height: 27px;
									opacity: 1;
									background: url("../images/check-img.svg") center center no-repeat;

									img {
										display: none;
									}
								}
							}
						}
					}

					.or-checkbox {
						display: flex;
						align-items: center;
						justify-content: center;
						margin-top: 16px;

						.checkbox-container {
							margin: 0 18px;
						}

						.skip-text {
							color: #757575;
						}
					}
				}
			}

			&.slide-5 {
				.row-2 {
					.input-block {
						width: 100%;
						max-width: 100%;
						
						label {
							span {
								color: #999;
							}
						}
					}
				}
			}

			&.slide-6 {
				.finish-text {
					max-width: 800px;
					font-size: 20px;
					line-height: 33px;
					font-weight: 400;
					text-align: center;
					color: #2c2c2c;

					@media (max-width: 600px) {
						font-size: 16px;
						line-height: 26px;
					}

					span {
						margin-bottom: 10px;
						font-size: 30px;
					}

					p {
						margin-top: 18px;
						font-size: 18px;
					}
				}

				.btn-go-home {
					width: 300px;
					margin-top: 56px;
					font-family: 'Vauxhall Neue', sans-serif;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
					cursor: pointer;

					@media (max-width: 600px) {
						margin-top: 20px;
					}
				}

				.content {
					.inner {
						.center-block {
							box-sizing: border-box;
							min-height: calc(100vh - 375px);
							padding-top: 140px;
						}
					}
				}
			}
		}
	}

	+ .footer {
		background-color: #f6f6f6;
	}
}