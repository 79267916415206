.top-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 900px) {
		flex-direction: column;
		align-items: flex-start;
	}

	.back {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px 20px;
		border: 1px solid #b2b9bc;
		background-color: #fff;
		border-radius: 5px;
		font-size: 16px;
		color: #2c2c2c;
		transition: opacity 0.3s ease;

		@media (min-width: 1024px) {
			&:hover {
				opacity: 0.7;
			}
		}

		@media (max-width: 900px) {
			order: -1;
		}

		svg {
			max-width: 24px;
			width: 24px;
			margin-right: 8px;
			fill: #b2b9bc;
			flex-shrink: 0;
		}
		span{
			white-space: nowrap;
		}
	}
}
