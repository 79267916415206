@mixin flex-row($gutter-left, $gutter-top) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-left;
  margin-top: -$gutter-top;
}

@mixin flex-block($min-width, $gutter-left, $gutter-top, $last-equal: false, $max-cols: 3) {
  flex: 1 0 $min-width;
  margin-left: $gutter-left;
  margin-top: $gutter-top;

  @if $last-equal {
    @for $i from 2 through $max-cols {
      $screen-width: ($min-width*$i)+($gutter-left*$i);
      $column-width: (100%/$i);
      @media (min-width: $screen-width) {
        max-width: calc(#{$column-width} - #{$gutter-left});
      }
    }

    $column-width: (100%/$max-cols);
    @media (min-width: $min-width*$max-cols) {
      min-width: calc(#{$column-width} - #{$gutter-left});
    }
  }
}



@mixin prop-img($x-prop, $y-prop){
  position: relative;
	overflow: hidden;

	&::before {
		content: "";
		display: block;
		padding-top: ($y-prop/$x-prop*100%);
	}

	img {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		max-width: 100%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

@mixin hover {
	@media (min-width: 1024px) {
		&:hover {
			@content;
		}
	}
}
