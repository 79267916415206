.vehicle-info-page {
	@media (max-width: 1009px) {
		padding-top: 30px;
	}

	&__inner {
		padding: 100px 0;

		@media (max-width: 1009px) {
			padding: 20px 0 50px;
		}
	}

	.top {
		.left {
			font-size: 34px;
			float: left;
		}

		.right {
			width: 300px;
			max-width: 100%;
			text-align: right;
			float: right;

			@media (max-width: 800px) {
				margin: 20px auto 0;
				max-width: 100%;
			}

			.price-new {
				@media (max-width: 800px) {
					text-align: center;
				}
			}
		}

		.left,
		.right {
			@media (max-width: 800px) {
				float: none;
				text-align: center;
			}
		}

		.model {
			font-size: 42px;
			line-height: 1.1;
			font-weight: 600;

			@media (max-width: 800px) {
				font-size: 30px;
			}
		}

		.type {
			font-size: 28px;
			line-height: 1.1;
			font-weight: 400;

			@media (max-width: 800px) {
				font-size: 23px;
			}
		}

		.price {
			display: block;
			padding: 17px 52px 17px 52px;
			background: #ef1923;
			font-size: 24px;
			line-height: 28px;
			font-weight: 400;
			color: #ffffff;
			text-transform: uppercase;

			@media (max-width: 800px) {
				display: inline-block;
				padding: 10px;
				text-align: center;
			}

			span {
				font-size: 36px;
				line-height: 28px;
				font-weight: bold;
			}
		}
	}

	.main {
		border-top: 1px solid #ce171f;
		border-bottom: 1px solid #ce171f;
		margin-top: 29px;

		> .right {
			width: 50%;
			padding-left: 20px;
			padding-bottom: 20px;
			padding-top: 15px;
			box-sizing: border-box;
			float: right;

			@media (max-width: 1009px) {
				width: 100%;
				display: block;
				padding-left: 0;
				padding-right: 0;
			}

			@media (min-width: 1010px) {
				float: left;
			}

			ul {
				list-style: none !important;
				padding-left: 0;

				&:not(:first-child) {
					@media (min-width: 1010px) {
						margin-top: 15px;
					}
				}

				li {
					font-size: 18px;
					line-height: 36px;
					border-bottom: 1px solid #e6e6e6;

					@media (max-width: 550px) {
						font-size: 14px;
						line-height: 1.5;
					}

					&:last-child {
						border-bottom: 0;
					}

					.left {
						display: inline-block;
					}

					.right {
						display: inline-block;
						padding-left: 10px;
						float: right;
					}
				}
			}

			p {
				&:not(:first-child) {
					@media (min-width: 1010px) {
						margin-top: 15px;
					}
				}
			}

			.star {
				@media (min-width: 1010px) {
					display: block;
					margin-top: 15px;
					font-size: 11px;
					font-style: italic;
					line-height: 13px;
				}
			}
		}

		> .left {
			width: 50%;
			padding-right: 20px;
			padding-bottom: 20px;
			padding-top: 15px;
			box-sizing: border-box;
			float: left;

			@media (max-width: 1009px) {
				width: 100%;
				display: block;
				padding-left: 0;
				padding-right: 0;
				text-align: center;
			}

			@media (min-width: 1010px) {
				float: left;
			}

			img {
				max-width: 100%;
				height: auto;

				@media (min-width: 1010px) {
					width: 100%;
					height: auto;
				}
			}

			.title {
				text-align: center;
				width: 160px;
				height: 36px;
				border: 2px solid #bfdeff;
				background: #000;
				font-size: 13px;
				line-height: 36px;
				font-weight: 600;
				color: #fff;
				box-sizing: border-box;
			}

			p {
				&:not(:first-child) {
					margin-top: 15px;
				}
			}

			ul {
				list-style: disc;
				padding-left: 20px;

				&:not(:first-child) {
					margin-top: 15px;
				}

				.star {
					display: block;
					margin-top: 15px;
					font-size: 11px;
					line-height: 13px;
					font-style: italic;
				}

				li {
					.left {
						@media (min-width: 1010px) {
							display: inline-block;
						}
					}

					.right {
						@media (min-width: 1010px) {
							display: inline-block;
							float: right;
						}
					}
				}
			}
		}
	}

	.features {
		.col {
			max-width: 33.33332%;
			width: 33.33332%;
			float: left;

			@media (max-width: 800px) {
				width: 49.8%;
				max-width: 49.8%;
			}

			@media (max-width: 550px) {
				width: 100%;
				max-width: 100%;
			}

			@media (min-width: 1010px) {
				float: left;
			}
		}

		.item {
			&:not(.first-child) {
				margin-top: 20px;
			}

			.title {
				padding: 7px;
				margin-left: 18px;
				margin-right: 5px;
				margin-bottom: 7px;
				border-bottom: 2px solid #e6e6e6;
				font-size: 20px;
				line-height: 28px;
				font-weight: bold;
				color: #2c2c2c;

				@media (max-width: 550px) {
					padding: 0;
					margin-left: 0;
					margin-right: 0;
					margin-bottom: 7px;
					font-size: 16px;
					line-height: 25px;
					font-weight: 700;
				}
			}

			ul {
				list-style: none;

				li {
					min-height: 22px;
					margin-left: 32px;
					margin-right: 5px;
					font-size: 15px;
					line-height: 32px;
					font-weight: 400;
					position: relative;
					box-sizing: border-box;

					@media (max-width: 550px) {
						margin-left: 21px;
						line-height: 26px;
					}

					&::before {
						content: "▪";
						display: inline-block;
						width: 1em;
						margin-left: -1em;
						font-weight: bold;
						color: #ce171f;
					}
				}
			}
		}
	}

	.sub-footer {
		margin-top: 20px;
		border-top: 1px solid #b3b3b3;

		.cols {
			.col {
				width: 33.333333333333333%;
				padding-top: 10px;
				box-sizing: border-box;
				float: left;

				p {
					font-size: 13px;
					line-height: 18.98px;
					font-weight: 400;
					color: #46494c;

					b {
						font-size: 15px;
						line-height: 10.85px;
						font-weight: 700;
						color: #46494c;
					}
				}

				&:not(:last-child) {
					width: 33.33332%;
					padding-right: 10px;

					@media (max-width: 800px) {
						width: 49.9%;
					}

					@media (max-width: 550px) {
						width: 100%;
					}
				}

				&:last-child {
					text-align: right;
				}
			}
		}

		.desc {
			padding-top: 20px;
			font-size: 12px;
			line-height: 17.18px;
			font-weight: 400;
			color: #46494c;
		}
	}
}
