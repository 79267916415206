.men-from-factory {
	&__container {
		max-width: 1140px;
		width: 100%;
		margin: 0 auto;
		padding: 80px 0 130px;
		box-sizing: border-box;

		@media (max-width: 1200px) {
			padding: 50px 15px 70px;
		}

		@media (max-width: 900px) {
			padding: 0 15px 70px;
		}
	}

	&__banner {
		width: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			font-family: 'object-fit: contain;';
		}
	}

	.mff-description {
		padding-bottom: 32px;
		margin-bottom: 52px;
		border-bottom: 1px solid #e6e6e6;

		@media (max-width: 900px) {
			padding-bottom: 25px;
			margin-bottom: 25px;
		}

		&__title {
			font-size: 30px;
			line-height: 35px;
			font-weight: bold;
			text-transform: uppercase;
			letter-spacing: 0.01em;
			margin-top: 50px;

			@media (max-width: 900px) {
				font-size: 24px;
				line-height: 28px;
				margin-top: 30px;
			}
		}

		&__subtitle {
			font-size: 22px;
			line-height: 32px;

			@media (max-width: 900px) {
				font-size: 20px;
				line-height: 24px;
			}
		}

		ul {
			margin-top: 18px;

			@media (max-width: 900px) {
				margin-top: 10px;
			}

			li {
				font-size: 15px;
				line-height: 26px;
				padding-left: 14px;
				position: relative;

				@media (max-width: 900px) {
					font-size: 14px;
					line-height: 17px;
				}

				+ li {
					margin-top: 8px;
				}

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background-color: #ce171f;
					position: absolute;
					top: 10px;
					left: 2px;
				}
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;
			margin-top: 27px;

			@media (max-width: 900px) {
				flex-direction: column;
			}

			.mff-description__text {
				width: 45%;

				@media (max-width: 900px) {
					width: 100%;
				}

				&:last-child {
					width: 48.6%;

					@media (max-width: 900px) {
						width: 100%;
						margin-top: 20px;
					}
				}
			}
		}

		&__text {
			font-size: 15px;
			line-height: 26px;

			@media (max-width: 900px) {
				font-size: 14px;
				line-height: 17px;
			}

			br {
				@media (max-width: 900px) {
					display: none;
				}
			}

			p + p {
				margin-top: 26px;

				@media (max-width: 900px) {
					margin-top: 15px;
				}
			}
		}

		&--center {
			padding-bottom: 50px;
			margin-bottom: 52px;

			@media (max-width: 900px) {
				padding-bottom: 25px;
				margin-bottom: 25px;
			}

			.mff-description {
				&__title,
				&__text {
					text-align: center;
				}

				&__title {
					margin-bottom: 18px;
				}
			}
		}
	}

	.mff-registration {
		max-width: 850px;
		width: 100%;
		margin: 0 auto;

		&__title {
			font-size: 24px;
			line-height: 28px;
			text-align: center;

			@media (max-width: 900px) {
				font-size: 22px;
				line-height: 26px;
			}
		}

		&__subtitle {
			font-size: 15px;
			line-height: 18px;
			text-align: center;
			margin-top: 12px;
		}

		.input-block {
			margin-top: 37px;

			@media (max-width: 900px) {
				margin-top: 20px;
			}

			p {
				width: 100%;
				font-size: 15px;
				line-height: 18px;
				margin-bottom: 16px;
			}

			&--branch {
				.radio-label {
					display: inline-flex;
					align-items: center;
					font-size: 15px;
					margin-bottom: 15px;

					&:not(:last-child) {
						margin-right: 53px;

						@media (max-width: 900px) {
							margin-right: 15px;
						}
					}
				}
			}

			&--date {
				display: flex;
				flex-wrap: wrap;
				margin: 37px -6px 0;

				p {
					margin: 0 6px 16px;
				}

				.radio-date {
					width: 158px;
					margin: 0 6px;
					margin-bottom: 15px;
					cursor: pointer;

					@media (max-width: 767px) {
						width: calc(50% - 12px);
					}

					> span {
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: center;
						width: 100%;
						border-radius: 5px;
						padding: 7px 5px;
						border: 1px solid #d9d9d9;
						background-color: #fff;
						font-size: 14px;
						color: #999999;
						text-align: center;
						box-sizing: border-box;
						transition: border-color 0.3s ease;

						@include hover {
							border-color: #ce181f;
						}

						.day {
							display: block;
							font-size: 15px;
							color: #2c2c2c;
							text-align: center;
						}
					}

					input {
						display: none;

						&:checked + span {
							border-color: #ce181f;
							background-color: #ce181f;
							color: #fff;

							.day {
								font-size: 15px;
								color: #fff;
							}
						}
					}
				}
			}

			&--time {
				margin: 23px -6px 0;

				.radio-date {
					width: calc(16.6666% - 12px);

					@media (max-width: 900px) {
						width: calc(25% - 12px);
					}

					@media (max-width: 767px) {
						width: calc(50% - 12px);
					}

					> span {
						height: 40px;
						padding: 0 5px;
						font-size: 15px;
						color: #2c2c2c;

						span {
							display: block;
							font-size: 13px;
						}
					}

					&--disabled {
						> span {
							border: 1px solid #d9d9d9;
							background-color: #f2f2f2;
							color: #bfbfbf;
							cursor: default;

							@include hover {
								border-color: #d9d9d9;
							}
						}
					}
				}
			}
		}

		&__row {
			display: flex;
			margin: 35px -10px 0;

			@media (max-width: 900px) {
				flex-direction: column;
				margin: 20px 0 0;
			}

			.input-block {
				width: calc(33.3333% - 20px);
				margin: 0 10px 15px;

				@media (max-width: 900px) {
					width: 100%;
					margin: 0 0 15px;
				}

				label {
					width: 100%;
				}
			}
		}

		button[type='submit'] {
			width: 320px;
			height: 46px;
			margin: 37px auto 0;

			@media (max-width: 900px) {
				margin: 15px auto 0;
			}

			@media (max-width: 767px) {
				width: 100%;
			}
		}

		&__nav {
			border-top: 1px solid #e6e6e6;
			margin-top: 23px;
			padding-top: 34px;
		}

		&__nav-title {
			font-size: 24px;
			line-height: 28px;
			text-align: center;

			@media (max-width: 900px) {
				font-size: 22px;
				line-height: 26px;
			}
		}

		&__nav-buttons {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 24px;

			@media (max-width: 767px) {
				flex-direction: column;
			}

			button,
			button[type="submit"] {
				max-width: 320px;
				width: 100%;
				height: 46px;
				margin: 0;
				font-size: 16px;

				&:first-child {
					margin-right: 20px;

					@media (max-width: 767px) {
						margin-right: 0;
						margin-bottom: 15px;
					}
				}
			}
		}
	}

	&__choise {
		.oqr {
			&__title {
				font-size: 22px;
			}

			&__inputs {
				align-items: flex-start;

				.input-block {
					margin: 0 12px;

					@media (max-width: 900px) {
						margin: 0;
					}

					span {
						line-height: 20px;
					}
				}
			}

			.oqr-block {
				&--exchange {
					padding: 28px 0 40px;

					.oqr__inputs + .oqr__btn-wrapper {
						margin-top: 10px;

						.btn {
							max-width: 265px;
							font-size: 16px;
						}
					}
				}

				&--accordion {
					padding: 30px 0 30px;
				}

				&--payment {
					padding: 35px 0 12px;

					.oqr {
						&__method-wrap {
							margin-top: 16px;
						}

						&__finance-options {
							margin: 38px 0 26px;
						}
					}
				}
			}
		}
	}

	&__step {
		&--2 {
			.mff-registration {
				.input-block--branch {
					margin-top: 29px;
				}
			}
		}

		&--3 {
			.mff-registration {
				.input-block--branch {
					margin-top: 29px;
				}
			}
		}
	}
}