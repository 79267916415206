.event-offer {
	.eop-promotion-pic {
		width: 100%;
		margin-bottom: 45px;

		@media (max-width: 970px) {
			margin-bottom: 25px;
		}

		@media (max-width: 620px) {
			margin-bottom: 22px;
		}

		img {
			width: 100%;
			max-width: 100%;
			max-height: auto;
		}
	}

	.eop-dear-customer {
		&__line1 {
			font-size: 18px;
			line-height: 33px;
			font-weight: 400;

			@media (max-width: 970px) {
				font-size: 15px;
			}

			@media (max-width: 620px) {
				font-size: 14px;
			}
		}

		&__line-2 {
			font-size: 24px;
			font-weight: bold;
			line-height: 33px;
			text-transform: uppercase;
			margin-bottom: 4px;

			@media (max-width: 970px) {
				font-size: 22px;
				line-height: 150%;
				margin-bottom: 8px;
			}

			@media (max-width: 620px) {
				font-size: 20px;
				line-height: 150%;
				margin-bottom: 8px;
			}
		}

		&__description {
			font-size: 18px;
			font-weight: 400;
			line-height: 28px;

			@media (max-width: 970px) {
				font-size: 15px;
				line-height: 150%;
			}

			@media (max-width: 620px) {
				font-size: 14px;
				line-height: 150%;
			}
		}

		&__line-3 {
			font-size: 20px;
			font-weight: bold;
			line-height: 33px;
			margin-top: 30px;

			@media (max-width: 970px) {
				font-size: 18px;
				line-height: 150%;
				margin-top: 15px;
			}

			@media (max-width: 620px) {
				font-size: 14px;
				line-height: 150%;
				margin-top: 15px;
			}
		}
	}

	.eop-cards-container {
		display: flex;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 50px;

		@media (max-width: 850px) {
			flex-wrap: wrap;
		}

		@media (max-width: 580px) {
			justify-content: center;
			margin-bottom: 20px;
		}
	}

	.eop-cards-container--fourth {
		@media (min-width: 851px) {
			margin-bottom: -30px;
		}

		.eop-card {
			border:0;
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;

			@media (min-width: 851px) {
				width: calc(25% - 37px * 2 / 3);
			}

			&__bg {
				display: none;
			}
		}
	}

	.eop-cards {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin: 0 -13px;

		@media (max-width: 620px) {
			margin: 0;
		}
	}

	.eop-card-item {
		width: calc(33.3333% - 26px);
		margin: 0 13px 26px;
		border: 1px solid #e6e6e6;
		box-sizing: border-box;

		@media (min-width: 1024px) {
			@include hover {
				.eop-card-item__bottom {
					background-color: #eae9e9;
				}
			}
		}

		@media (max-width: 850px) {
			width: calc(50% - 26px);
		}

		@media (max-width: 620px) {
			width: 100%;
			margin: 0 0 20px;
		}

		&__pic {
			width: 100%;
			height: 239px;
			padding: 10px;
			box-sizing: border-box;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				font-family: 'object-fit: contain;';
			}
		}

		&__title {
			font-size: 24px;
			font-weight: bold;
			text-align: center;
			margin: 19px 0;
		}

		&__bottom {
			padding: 15px;
			background-color: #f6f6f6;
			transition: background-color 0.3s ease;

			.btn {
				max-width: 260px;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 0 auto;
				font-size: 16px;
			}
		}
	}

	.eop-card {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: calc(33.33333% - 37px * 2 / 3);
		margin: 18.5px 0;
		padding: 15px 35px 30px;
		border: 1px solid #e6e6e6;
		background-color: #ffffff;
		text-align: center;
		position: relative;
		z-index: 1;
		box-sizing: border-box;

		@media (max-width: 850px) {
			width: calc(50% - 37px / 2);
		}

		@media (max-width: 580px) {
			width: 100%;
			max-width: 400px;
			margin: 8px 0;
		}

		&__pic {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			flex-grow: 1;

			img {
				width: 100%;
				max-width: 271px;
			}
		}

		.eop-card__bg {
			width: 100%;
			height: 30%;
			background-color: #eef3fb;
			position: absolute;
			top: 20%;
			left: 0;
			z-index: -1;
		}

		&__title {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 24px;
			line-height: 28px;
			font-weight: bold;
			color: #2c2c2c;

			@media (max-width: 620px) {
				font-size: 20px;
			}
		}

		&__desc {
			font-size: 12px;
			line-height: 28px;
			font-weight: 400;
			text-transform: uppercase;

			span {
				font-size: 20px;
				color: #ce171f;
			}
		}
	}

	.eop-text {
		p {
			font-size: 15px;
			line-height: 28px;
			font-weight: 400;
			margin-bottom: 33px;

			@media (max-width: 970px) {
				font-size: 15px;
				line-height: 150%;
				font-weight: 400;
				margin-bottom: 20px;
			}

			@media (max-width: 620px) {
				font-size: 14px;
				font-weight: 400;
				line-height: 150%;
				margin-bottom: 15px;
			}
		}

		ul {
			font-size: 16px;
			line-height: 150%;
			font-weight: 400;
			margin-bottom: 45px;

			@media (max-width: 970px) {
				margin-bottom: 25px;
			}

			@media (max-width: 620px) {
				margin-bottom: 15px;
			}

			li {
				margin-bottom: 10px;
				padding-left: 18px;
				position: relative;

				@media (max-width: 970px) {
					margin-bottom: 4px;
				}

				@media (max-width: 620px) {
					margin-bottom: 4px;
				}

				&::before {
					content: '';
					display: inline-block;
					width: 5px;
					height: 5px;
					background-color: #ce171f;
					position: absolute;
					left: 4px;
					top: 8px;
				}
			}
		}

		h3 {
			font-size: 20px;
			line-height: 33px;
			font-weight: bold;
			margin-bottom: 20px;

			@media (max-width: 970px) {
				font-size: 17px;
				line-height: 150%;
				margin-bottom: 10px;
			}

			@media (max-width: 620px) {
				font-size: 16px;
				line-height: 150%;
				margin-bottom: 10px;
			}

			+ .eop-red-par {
				margin-top: -20px;

				@media (max-width: 970px) {
					margin-top: 0;
				}
			}
		}
	}

	.eop-red-par {
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
		text-transform: uppercase;
		color: #ce171f;
	}

	.eop-call-to-us {
		width: 100%;
		padding: 30px 20px;
		margin: 30px 0;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		font-size: 16px;
		font-weight: 400;
		line-height: 28px;
		text-align: center;
		box-sizing: border-box;

		p {
			span {
				font-size: 22px;
				font-weight: 400;
				line-height: 24px;

				&::before {
					content: '';
					display: inline-block;
					width: 18px;
					height: 18px;
					margin: 0 10px;
					background: url(../img/err.svg) no-repeat center center;
					background-size: contain;
				}
			}
		}
	}

	.form-block--eop {
		width: 756px;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 100px;

		.red {
			color: #ce181f;
			font-weight: bold;
		}

		.title {
			font-size: 20px;
			line-height: 33px;
			font-weight: bold;
			text-align: center;
		}

		.ah2 {
			font-size: 16px;
			font-weight: 400;
			line-height: 28px;
			text-align: center;
			margin-top: 15px;
			margin-bottom: 5px;
		}

		.row2 {
			display: flex;

			.input-block {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 490px;
				max-width: 100%;
				margin: 0 auto;

				@media (max-width: 620px) {
					flex-direction: column;
				}

				.radio-btn {
					display: flex;
					align-items: center;

					@media (max-width: 620px) {
						margin-bottom: 10px;
					}
				}

				.radio-label {
					margin-bottom: 0;
				}

				.radio-btn + .radio-btn {
					margin-top: unset;
				}
			}
		}

		.row {
			display: flex;
			justify-content: space-between;
			margin-top: 30px;

			@media (max-width: 620px) {
				display: block;
				margin-top: 5px;
			}

			.input-block {
				width: calc(33.3333333% - 16px * 2 / 3);

				@media (max-width: 620px) {
					width: 100%;
					margin-bottom: 10px;
				}
			}
		}

		.btn {
			width: 266px;
			height: 46px;
			margin: 0 auto;
			margin-top: 40px;
		}
	}

	.form-after-submit {
		text-align: center;

		.btn {
			margin-left: auto;
			margin-right: auto;
		}
	}
}
