.main-menu {
	&__wrapper {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.35);
		z-index: -1;
		opacity: 0;
		transition: 0.3s;

		&--active {
			z-index: 4000;
			opacity: 1;
		}
	}

	&__container {
		position: fixed;
		top: 0;
		right: 0;
		width: 300px;
		height: 100%;
		overflow: auto;
		box-sizing: border-box;
		background-color: #fff;
		z-index: 5000;
		padding: 48px 20px 20px;
		transition: 0.5s;

		&--hidden {
			transform: translateX(300px);
		}
	}

	&__close {
		height: 21px;
		width: 21px;
		background-image: url(../img/btn-close.png);
		cursor: pointer;

		&--top {
			position: absolute;
			top: 28px;
			right: 15px;
		}
	}

	&__title {
		font-size: 18px;
		line-height: 21px;
		color: #2c2c2c;
		font-weight: bold;
		padding-bottom: 20px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		max-width: 190px;
		border-bottom: 1px solid #e1e1e1;
		margin: 0 auto;
		text-align: center;
	}

	&__signin {
		padding: 1px 0 2px;
		border-bottom: 1px solid #e1e1e1;
		display: none;
	}

	&__signin-link {
		color: #2c2c2c;
		font-size: 16px;
		font-weight: 400;
		line-height: 38px;
		text-decoration: underline;
	}

	&__mobile-chat {
		display: none;
		margin-top: 20px;
		padding-top: 18px;
		border-top: 1px solid #e1e1e1;
	}

	.mobile-chat {
		&__link {
			color: #2c2c2c;
			font-size: 16px;
			font-weight: 400;
			text-decoration: underline;

			img {
				margin-right: 7px;
				vertical-align: middle;
			}
		}

		&__socs {
			display: flex;
			margin-top: 16px;
		}

		&__soc {
			width: 26px;
			height: 26px;
			margin-right: 11px;
		}
	}
}
