.privacy-statement {
	padding-bottom: 50px;

	.inner {
		margin-bottom: 100px;
	}

	.page-main-title {
		margin-bottom: 30px;
	}

	.ah1 {
		margin-bottom: 5px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;
	}

	.ah2 {
		margin-top: 25px;
		margin-bottom: 5px;
		font-size: 16px;
		font-weight: bold;
	}

	a {
		text-decoration: underline;
		color: #b72126;

		@include hover {
			text-decoration: none;
		}
	}

	.underline {
		text-decoration: underline;
	}

	ul {
		list-style-position: inside;
		list-style-type: disc;
	}
}