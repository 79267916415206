.valuation-requests {
	.content {
		width:  calc(100% - 60px);
		max-width: 1320px;
		margin: 0 auto;
		padding-bottom: 100px;

		@media (max-width: 1020px) {
			width: 100%;
			max-width: calc(100% - 60px);
		}

		@media (max-width: 700px) {
			width: 100%;
			max-width: calc(100% - 60px);
		}

		@media (max-width: 650px) {
			width: 100%;
			max-width: calc(100% - 60px);
			padding-bottom: 50px;
		}

		.flex-table {
			width: 100%;
			max-width: 100%;
			margin-top: 52px;

			.row {
				display: flex;
				align-items: center;
				height: 46px;
				font-size: 16px;
				line-height: 23px;

				@media (max-width: 700px) {
					height: 38px;
					line-height: 22px;
				}

				@media (max-width: 650px) {
					display: flex;
					flex-direction: column;
					height: unset;
					margin-bottom: 35px;
					background-color: #f2f2f2;
					line-height: 22px;
				}

				&:nth-child(even) {
					background-color: #f2f2f2;
				}

				.field {
					@media (max-width: 1020px) {
						font-size: 14px;
					}

					@media (max-width: 700px) {
						font-size: 12px;
					}

					@media (max-width: 650px) {
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: unset;
						font-size: 16px;
						line-height: 22px;
					}

					&:not(:last-child) {
						@media (max-width: 650px) {
							border-bottom: 1px solid #999999;
						}
					}

					&.date {
						width: calc(22.727% - 41px);
						padding-left: 41px;

						@media (max-width: 700px) {
							width: calc(22.727% - 18px);
							padding-left: 18px;
						}
					}

					&.time {
						width: 17.272%;

						@media (max-width: 1020px) {
							width: 9.272%;
						}

						@media (max-width: 700px) {
							width: 9.272% + 2%;
						}
					}

					&.model {
						width: 31.06%;

						@media (max-width: 1020px) {
							width: 31.06% + 4%;
						}

						@media (max-width: 700px) {
							width: 31.06% + 5%;
						}
					}

					&.status {
						width: 19.924%;

						@media (max-width: 1020px) {
							width: 19.924%;
						}

						@media (max-width: 700px) {
							width: 14.924% + 2%;
						}
					}

					&.open {
						width: 9.015%;
						font-family: "Vauxhall Neue", sans-serif;
						font-size: 16px;
						line-height: 23px;
						font-weight: 400;
						color: #2c2c2c;

						@media (max-width: 1020px) {
							width: 9.015% + 4%;
						}

						@media (max-width: 700px) {
							width: 9.015% + 4%;
						}

						@media (max-width: 650px) {
							justify-content: center;
						}

						a {
							display: flex;
							align-items: center;
							font-family: "Vauxhall Neue", sans-serif;
							font-size: 16px;
							line-height: 46px;
							font-weight: 400;
							color: #2c2c2c;
							text-decoration: underline;
							transition-duration: 0.2s;

							@media (max-width: 1020px) {
								font-size: 14px;
							}

							@media (max-width: 700px) {
								font-size: 12px;
							}

							@media (max-width: 650px) {
								font-size: 19px;
							}

							@include hover {
								filter: opacity(0.5);
							}

							img {
								margin-left: 6px;
							}
						}
					}

					&.date,
					&.time,
					&.model,
					&.status,
					&.open {
						@media (max-width: 650px) {
							width: calc(100% - 30px);
							padding: 10px 0;
							margin: 0 15px;
						}
					}

					.bold {
						@media (max-width: 650px) {
							font-weight: 600;
							margin-right: 15px;
						}
					}
				}

				.completed {
					color: #999999;
				}

				&.headline {
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 18px;
					line-height: 28px;
					font-weight: bold;
					color: #2c2c2c;

					@media (max-width: 1020px) {
						font-size: 16px;
					}

					@media (max-width: 700px) {
						font-size: 14px;
					}

					@media (max-width: 650px) {
						display: none;
					}
				}
			}
		}

		.bottom {
			display: flex;
			justify-content: flex-end;

			@media (max-width: 650px) {
				justify-content: center;
				margin-top: -10px;
			}

			.comeback {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 160px;
				height: 36px;
				margin-top: 38px;
				border: 1px solid #58585a;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 14px;
				font-weight: bold;
				line-height: 28px;
				color: #2c2c2c;
				text-transform: uppercase;
				transition-duration: 0.2s;

				@include hover {
					background: #2c2c2c;
					color: #fff;
				}
			}
		}
	}
}
