.thanks-credit {
	max-width: 562px;
	width: 100%;
	margin: 0 auto;
	padding: 50px 0 100px;
	box-sizing: border-box;

	@media (max-width: 609px) {
		padding: 30px 20px;
	}

	&__title {
		font-size: 24px;
		line-height: 33px;
		margin-bottom: 16px;

		@media (max-width: 609px) {
			font-size: 20px;
			line-height: 28px;
		}
	}

	&__list-title {
		font-size: 18px;
		line-height: 33px;
		margin-bottom: 5px;

		span {
			font-size: 14px;
			line-height: 24px;
			color: #999999;
		}
	}

	&__row {
		font-size: 15px;
		padding: 10px 20px;
		margin: 0 -20px;

		@media (max-width: 609px) {
			padding: 10px;
			margin: 0;
		}

		span {
			&:not(:last-child) {
				margin-right: 7px;
			}
		}

		&:nth-child(odd) {
			background-color: #f7f7f7;
		}
	}

	&__block {
		border-top: 1px solid #e5e5e5;
		margin: 15px -20px 0;
		padding: 22px 20px 0;

		@media (max-width: 609px) {
			margin: 15px 0 0;
			padding: 22px 0 0;
		}

		.btn {
			width: 150px;
			height: 40px;
			margin-top: 0;
			margin-right: 18px;
			font-size: 14px;
			line-height: 40px;
			font-weight: 400;

			@media (max-width: 609px) {
				margin-right: 0;
				margin-bottom: 10px;
			}

			&--get-code {
				width: 200px;
				margin-top: 20px;

				@media (max-width: 609px) {
					margin: 15px auto 0;
				}
			}
		}

		&--code {
			padding-top: 24px;
			margin-top: 27px;
		}
	}

	&__text {
		font-size: 15px;
		line-height: 26px;

		br {
			@media (max-width: 609px) {
				display: none;
			}
		}

		&--small {
			font-size: 14px;
			line-height: 23px;
		}
	}

	&__file {
		display: flex;
		align-items: center;
		margin-top: 10px;
		font-size: 14px;
		color: #ce171f;
		text-decoration: underline;

		@include hover {
			text-decoration: none;
		}

		svg {
			width: 100%;
			max-width: 19px;
			fill: #ce171f;
			margin-right: 5px;
		}
	}

	&__form {
		display: flex;
		align-items: center;
		margin-top: 21px;

		@media (max-width: 609px) {
			flex-direction: column;
		}

		.input-block {
			margin-right: 10px;

			@media (max-width: 609px) {
				margin-right: 0;
				margin-bottom: 10px;
			}

			input[type="text"] {
				width: 230px;
				margin: 0;
				border-radius: 3px;

				&::placeholder {
					font-size: 14px;
				}
			}
		}
	}

	&__new-code {
		font-size: 14px;
		color: #ce171f;
		text-decoration: underline;

		@include hover {
			text-decoration: none;
		}
	}
}