.automotive-compliance {
	.au-co-first {
		.title {
			margin-top: 90px;
			margin-bottom: 27px;
			font-size: 20px;
			font-weight: bold;
			text-align: center;

			@media (max-width: 500px) {
				margin-top: 30px;
				line-height: 1.3;
			}
		}

		.button--red {
			display: block;
			width: 355px;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;

			@media (max-width: 500px) {
				max-width: 100%;
			}
		}
	}

	.au-co-second {
		max-width: 630px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 55px;

		.title {
			font-size: 20px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 37px;

			@media (max-width: 500px) {
				margin-top: 30px;
				line-height: 1.3;
			}
		}

		.row {
			display: flex;
			justify-content: space-between;

			@media (max-width: 500px) {
				display: block;
				width: calc(100% - 30px);
				margin: 0 auto;
			}
		}

		.block {
			width: 48%;

			@media (max-width: 500px) {
				width: 100%;
			}

			label {
				display: block;
				margin-bottom: 3px;
				font-size: 16px;
				line-height: 20px;
			}

			input {
				width: 100%;
				padding: 12px 15px;
				margin-bottom: 15px;
				border: 1px solid #a5a5a5;
				font-size: 16px;
				line-height: 1em;
				outline: 0;
				box-sizing: border-box;
				appearance: none;
			}

			select {
				width: 100%;
				padding: 13px 15px;
				margin-bottom: 15px;
				border: 1px solid #a5a5a5;
				border-radius: 0;
				font-size: 16px;
				line-height: 1em;
				background: #fff url(../img/select_bg.svg) no-repeat;
				background-position: calc(100% - 15px) center;
				outline: 0;
				box-sizing: border-box;
				resize: none;
				appearance: none;
			}
		}

		hr {
			display: block;
			height: 1px;
			margin-bottom: 15px;
			border: 0;
			background: #e6e6e6;
		}

		.button--red {
			display: block;
			width: 355px;
			margin-top: 25px;
			margin-left: auto;
			margin-right: auto;
			font-size: 18px;

			@media (max-width: 500px) {
				max-width: 100%;
			}
		}
	}

	.au-co-inner {
		display: flex;
		width: 960px;
		max-width: calc(100% - 30px);
		margin: 61px auto 144px;

		@media (max-width: 1000px) {
			flex-direction: column;
			margin: 30px auto 40px;
		}
	}

	.au-co-sidebar {
		width: 303px;
		max-width: 100%;
		margin-right: 45px;

		@media (max-width: 1000px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}
	}

	.au-co-s-title {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 18px;
		line-height: 33px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.au-co-s-info {
		border: 1px solid #e6e6e6;
		box-sizing: border-box;

		&__item {
			padding: 13px 9px 17px;

			@media (max-width: 1000px) {
				padding: 10px 8px 10px;
			}

			&:nth-child(2n-1) {
				background-color: #f7f7f7;
			}

			&--two-cols {
				display: flex;

				@media (max-width: 500px) {
					display: block;
				}

				.col {
					width: 50%;

					@media (max-width: 500px) {
						width: 100%;
					}

					&:not(:last-child) {
						@media (max-width: 500px) {
							margin-bottom: 10px;
						}
					}
				}
			}

			.name {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 13px;
				line-height: 1.3;
				font-weight: 400;
				color: #666666;
			}

			.value {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				line-height: 1.3;
				font-weight: 400;
				color: #2c2c2c;
			}

			.red {
				color: #ce171f;
			}

			.underline {
				text-decoration: underline;
			}

			.ttu {
				text-transform: uppercase;
			}
		}
	}

	.au-co-content {
		display: block;
		width: calc(100% - 45px - 303px);

		@media (max-width: 1000px) {
			display: block;
			width: 100%;
		}

		.au-co-block {
			width: 100%;

			&:not(:first-child) {
				padding-top: 19px;

				@media (max-width: 700px) {
					padding-top: 10px;
				}
			}

			&:not(:last-child) {
				padding-bottom: 25px;
				border-bottom: 1px solid #e6e6e6;

				@media (max-width: 700px) {
					padding-bottom: 8px;
				}
			}

			&__title {
				margin-top: 14px;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 20px;
				line-height: 1.3;
				font-weight: bold;
				color: #2c2c2c;

				@media (max-width: 700px) {
					font-size: 18px;
					margin-bottom: 10px;
				}
			}
		}
	}

	.au-co-block__button, .au-co-next {
		display: block;
		width: 170px;
		height: 35px;
		margin-top: 6px;
		font-size: 15px;
		line-height: 35px;
	}

	.au-co-next {
		width: 97px;
		margin-top: 20px;

		&.inb {
			display: inline-block;
			vertical-align: top;
			margin-right: 6px;
		}

		&__gray {
			background: #333333;
		}
	}

	.btn-ecd {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 216px;
		max-width: 100%;
		height: 35px;
		padding: 11px 0 12px;
		background-color: #d1252c;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 15px;
		line-height: 1.3;
		font-weight: 400;
		color: #ffffff;
		cursor: pointer;
		box-sizing: border-box;

		&::after {
			content: '';
			display: block;
			width: 25px;
			height: 25px;
			background: url('../img/controls/arrow.svg') no-repeat center center;
			background-size: contain;
			transform: rotate(90deg);
		}
	}

	.btns-row {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;

	}

	.btn-after-pic {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 5px 5px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 15px;
		line-height: 28px;
		font-weight: 400;
		color: #ffffff;
		cursor: pointer;

		@include hover {
			opacity: 0.8;
		}

		&::after {
			content: '';
			display: block;
			margin-left: 10px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: contain;
		}

		&--sos {
			width: 178px;
			height: 35px;
			background-color: #d1252c;

			&::after {
				width: 19px;
				height: 17px;
				background-image: url('../img/desktop.png');
			}
		}

		&--mail {
			width: 167px;
			height: 35px;
			background-color: #124390;

			&::after {
				width: 17px;
				height: 13px;
				background-image: url('../img/mail.png');
			}
		}
	}

	.au-co-s-question-number {
		margin-bottom: 2px;
		font-size: 13px;
		line-height: 1.3;
		font-weight: 400;
		color: #999999;
	}

	.au-co-s-question-title {
		font-size: 18px;
		line-height: 1.3;
		font-weight: 400;
		color: #2c2c2c;

		@media (max-width: 700px) {
			font-size: 14px;
		}
	}

	.au-co-s-radio-group {
		margin-top: 6px;

		.aucos-radio-item {
			display: block;
			width: 100%;
			margin-bottom: 0;

			span {
				display: flex;
				align-items: center;
				width: 100%;
				padding-top: 8px;
				padding-bottom: 9px;
				padding-left: 39px;
				transition: background-color .2s;
				font-size: 14px;
				line-height: 1.3;
				font-weight: 400;
				color: #2c2c2c;
				box-sizing: border-box;
				position: relative;

				@include hover {
					background-color: #eef6fb;
				}

				&::before {
					content: '';
					display: block;
					width: 20px;
					height: 20px;
					border: 1px solid #a5a5a5;
					border-radius: 50%;
					background-color: #ffffff;
					position: absolute;
					top: 50%;
					left: 9px;
					transform: translateY(-50%);
				}

				&::after {
					content: '';
					display: block;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #124390;
					opacity: 0;
					position: absolute;
					top: 50%;
					left: 15px;
					transform: translateY(-50%);
					transition: opacity 0.3s ease;
				}
			}

			input {
				display: none;

				&:checked + span {
					background-color: #e0f2fe;

					&::after {
						opacity: 1;
					}
				}
			}
		}
	}

	.au-co-text-inp {
		width: 100%;
		height: 32px;
		margin-top: 6px;
		padding-left: 10px;
		border: 0;
		background-color: #e0f2fe;
		font-size: 14px;
		line-height: 1;
		font-weight: 400;
		color: #2c2c2c;
		outline: 0;
		box-sizing: border-box;

		&.not {
			max-width: 280px;
			background: transparent;
			border: 1px solid #a5a5a5;
		}
	}

	.au-co-form-bottom-btns {
		margin: 40px -8px 0;

		@media (max-width: 700px) {
			justify-content: center;
		}

		> * {
			margin: 0 8px 8px;
			cursor: pointer;

			@include hover {
				opacity: 0.8;
			}
		}

		.au-co-submit {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 129px;
			height: 35px;
			background-color: #d1252c;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 15px;
			line-height: 28px;
			font-weight: 400;
			color: #ffffff;
		}

		.au-co-cds-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 14px;
			line-height: 28px;
			font-weight: 400;
			color: #d1252c;
			text-decoration: underline;
		}
	}
}
