.slide-title {
	width: 100%;
	height: 100px;
	text-align: center;
	background-color: #f6f6f6;

	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
		font-weight: 400;
		color: #2c2c2c;

		@media (max-width: 768px) {
			font-size: 30px;
		}

		@media (max-width: 600px) {
			font-size: 27px;
			line-height: 29px;
		}
	}

	.step {
		width: 100px;
		text-align: center;
		font-size: 14px;
		line-height: 14px;
		font-weight: 400;
		color: #ce181f;
		position: absolute;
		bottom: 19px;
		left: calc(50% - 50px);

		&.step-1 {
			@media (max-width: 900px) {
				bottom: 10px;
			}
		}
	}
}