.cashflow-log {
	.caslo-table-wrap {
		width: 1610px;
		max-width: 100%;
		margin: 0 auto;
		margin-bottom: 80px;
		overflow: hidden;
		overflow-x: auto;
		white-space: nowrap;
	}

	.caslo-table {
		display: inline-block;
		position: relative;
	}

	.caslo-table-row {
		display: flex;
		margin-right: 25px;
		border-bottom: 1px solid #d9d9d9;
		background-color: #ffffff;
		box-sizing: border-box;

		&:last-child .caslo-table-cell:first-child {
			border-bottom: 1px solid #ce171f;
		}

		&:not(.caslo-table-row--heading) {
			@include hover {
				background-color: #f7f7f7;

				.caslo-table-cell:nth-child(2n) {
					background-color: #ededed;
				}

				.caslo-table-cell.caslo-table-cell--current-day {
					background-color: #c1e0ec;
				}
			}
		}

		&--heading {
			width: 1584px;
			border: 1px solid #ce171f;
			border-left: 0;
			z-index: 3;
			position: static;
		}

		&--big-letters {
			height: 46px;

			.caslo-table-cell:first-child {
				font-size: 20px;
			}
		}

		&--gray-bg {
			background-color: #56697b;

			.caslo-table-cell {
				color: #ffffff !important;
				border-color: rgba(0, 0, 0, 0) !important;
				background-color: #56697b !important;

				&:not(.caslo-table-cell--edit-mode) {
					input {
						color: #ffffff !important;
					}
				}
			}
		}

		&--red-bg {
			background-color: #ce171f;

			.caslo-table-cell {
				color: #ffffff !important;
				border-color: rgba(0, 0, 0, 0) !important;
				background-color: #ce171f !important;

				&:not(.caslo-table-cell--edit-mode) {
					input {
						color: #ffffff !important;
					}
				}
			}
		}
	}

	.caslo-table-title {
		display: flex;
		align-items: center;
		height: 100%;
		padding-left: 16px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 24px;
		line-height: 28px;
		font-weight: 400;
		text-transform: uppercase;
		color: #2c2c2c;
	}

	.caslo-table-cell {
		position: relative;
		min-height: 33px;

		&:first-child {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 492px;
			min-width: 492px;
			padding: 0 10px 0 16px;
			border-right: 1px solid #ce171f;
			border-left: 1px solid #ce171f;
			font-size: 14px;
			line-height: 1;
			font-weight: 400;
			color: #2c2c2c;
		}

		&:not(:first-child) {
			width: 118px;
			min-width: 118px;

			&:not(.caslo-table-cell--heading) {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				line-height: 1;
				font-weight: 400;
				text-align: center;
				color: #2c2c2c;
			}
		}

		&--heading {
			min-height: 70px;

			&:not(:first-child) {
				display: flex;
				align-items: center;
				flex-direction: column;
				text-align: center;
			}
		}

		&:nth-child(2n) {
			background-color: #f7f7f7;
		}

		&.caslo-table-cell--current-day {
			border-right: 1px solid #dadada;
			border-left: 1px solid #dadada;
			background-color: #e3f7ff;

			&:nth-child(2) {
				border-left: 0;
			}
		}

		&--negative input {
			color: #ce171f !important;
		}

		input {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 110px;
			max-width: 110px;
			height: 33px;
			margin: 0 auto;
			border: none;
			background: none;
			font-size: 14px;
			line-height: 1px;
			font-weight: 400;
			color: #2c2c2c;
			text-align: center;
			box-sizing: border-box;
			pointer-events: none;
		}

		&--edit-mode {
			input {
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				pointer-events: auto;
			}
		}
	}

	.drill-down {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 21px;
		margin-right: 10%;
		padding: 0 13px;
		border-radius: 3px;
		background-color: #56697b;
		cursor: pointer;
		transition-duration: .2s;

		@include hover {
			opacity: 0.8;
		}

		&::before {
			content: '';
			display: block;
			width: 16px;
			height: 4px;
			margin-right: 7px;
			background: url(../img/more-horiz.svg) no-repeat center center;
			background-size: contain;
		}

		&::after {
			content: 'Drill Down';
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 13px;
			line-height: 28px;
			font-weight: 400;
			color: #ffffff;
		}
	}

	.caslo-tc-date {
		margin-top: 8px;
		font-size: 14px;
		line-height: 1;
		font-weight: 400;
		color: #666666;
	}

	.caslo-tc-date-number {
		font-size: 16px;
		color: #ce171f;
	}

	.caslo-tc-day {
		margin-top: 4px;
		font-size: 13px;
		line-height: 1;
		font-weight: 400;
		color: #2c2c2c;
	}

	.caslo-tc-edit-btn {
		display: flex;
		justify-content: space-between;
		margin-top: 6px;
		text-align: center;
		cursor: pointer;
		transition-duration: 0.2s;

		@include hover {
			opacity: 0.7;
		}

		&::before {
			content: '';
			display: block;
			width: 25px;
			height: 13px;
			background: url(../img/edit-blue.svg) no-repeat center center;
			background-size: contain;
		}

		&::after {
			content: 'Save changes';
			display: none;
			width: 100%;
			font-size: 13px;
			line-height: 19.59px;
			font-weight: 400;
			text-decoration: underline;
			color: #007aff;
		}

		&--active {
			&::after {
				display: block;
			}

			&::before {
				display: none;
			}
		}
	}

	.caslo-table-prev-page,
	.caslo-table-next-page {
		position: absolute;
		top: 50%;
		left: 0;
		width: 26px;
		height: 41px;
		transform: translate(-100%, -50%) rotate(-180deg);
		border: 1px solid #bfbfbf;
		border-left: 0;
		background: url(../img/kar-red.svg) no-repeat center center;
		background-size: 30px;
		cursor: pointer;
		transition-duration: 0.2s;

		@include hover {
			background-color: #ff000d1e;
		}
	}

	.caslo-table-next-page {
		right: 0;
		left: auto;
		transform: translate(100%, -50%) rotate(0deg);
	}

	.caslo-table-row--fixed {
		position: fixed !important;
		top: 0;

		& + .caslo-table-row {
			margin-top: 72px;
		}
	}

	.caslo-table-row--heading-fix-left-big {
		left: calc((100% - 1584px - 26px) / 2) !important;
	}

	.caslo-table-row:not(.caslo-table-row--fixed) {
		left: unset !important;
	}

	.cashflow-controls {
		width: 1609px !important;

		& > .input-block {
			justify-content: flex-end !important;

			@media (max-width: 768px) {
				display: flex !important;
				flex-direction: column !important;
				align-items: center !important;
			}

			& * {
				order: -1;
			}
		}

		.cfl-btn-add-new-line-wrap {
			@media (max-width: 768px) {
				width: 100%;
				order: 10;
			}
		}
	}

	.cfl-btn-add-new-line-wrap {
		flex-grow: 1;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.cfl-btn-add-new-line {
		width: 168px;
		margin-right: 15px;
		position: relative;

		@media (max-width: 768px) {
			width: 100%;
			box-sizing: border-box;
		}

		&::before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			background: url(../img/plus.svg) no-repeat center center;
			background-size: contain;
			position: absolute;
			left: 10px;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.caslo-remove-btn {
		display: block;
		width: 14px;
		height: 18px;
		margin-right: 12px;
		background: url(../img/bin-red.png) no-repeat center center;
		background-size: contain;
		cursor: pointer;
	}
}

.modal-caslo-remove {
	width: 501px;
	max-width: 100%;

	.input-block {
		width: 100%;
		max-width: 100%;

		.select {
			width: 100%;
		}
	}

}

.modal-caslo-remove-title {
	color: #2c2c2c;
	font-size: 26px;
	font-weight: bold;
	line-height: 33px;
	text-align: center;
	margin-bottom: 36px;
}

button.modal-caslo-remove-btn {
	width: 179px;
	margin: 20px auto 0;
	background-color: #333333;
	position: relative;
	font-size: 15px;
	font-weight: 400;

	&::before {
		content: '';
		display: block;
		width: 14px;
		height: 18px;
		background: url(../img/bin.png) no-repeat center center;
		background-size: contain;
		position: absolute;
		left: 25px;
		top: 50%;
		transform: translateY(-50%);
	}
}




.modal-caslo-add-line {
	.input-block {
		&+.input-block {
			margin-top: 20px;
		}
	}
}


button.modal-caslo-add-btn {
	width: 179px;
	margin: 29px auto 0;
}
