.personal-table {
	&__title {
		padding-bottom: 10px;
		margin-bottom: 30px;
		border-bottom: 2px solid #e4e4e4;
		font-size: 18px;
		line-height: 21px;
	}

	&__table {
		display: table;
		width: 100%;
	}

	&__table-row {
		display: table-row;
		width: 100%;

		&:nth-child(2n+1) {
			background-color: #fff;
		}
	}

	&__table-cell {
		display: table-cell;
		height: 32px;
		font-size: 16px;
		line-height: 19px;
		color: #2c2c2c;
		text-align: left;
		vertical-align: middle;
		box-sizing: border-box;

		@media (max-width: 450px) {
			font-size: 12px;
		}

		&__w30 {
			width: 30%;
		}

		&--w30 {
			padding-right: 10px;
			font-weight: 400;
			text-align: right;
		}

		&--w70 {
			width: 70%;
			padding-left: 11px;
		}
	}
}
