.check-list {
	padding-bottom: 0 !important;

	.inner-clu {
		width: 1370px;
		margin: 0 auto;
	}

	.logo__img {
		display: block;
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}

	.clearfix {
		display: table;
		clear: both;
	}

	.inner-clu {
		width: 1370px;
		margin: 0 auto;
	}

	.clu-fw-bbg {
		width: 100%;
		border-bottom: 1px solid #ababae;
		line-height: 1;
	}

	.clu-redhead {
		display: inline-block;
		width: 516px;
		height: 48px;
		background: #bb292c;
		padding-left: 27px;
		margin: 0;
		padding-top: 12px;
		font-size: 23px;
		line-height: 1;
		color: #ffffff;
		box-sizing: border-box;
		float: left;
	}

	.clu-redhead-traingle {
		display: inline-block;
		width: 68px;
		height: 48px;
		margin: 0;
		line-height: 1;
		outline: none;
		float: left;
	}

	.clu-table {
		width: 100%;
	}

	.clu-table-row {
		width: 100%;
		font-size: 22px;
		line-height: 1.2;
	}

	.clu-table-row {
		width: 100%;
	}

	.clu-cell__left {
		width: 47.4452554745%;
		padding-top: 15px;
	}

	.clu-cell__right {
		width: 47.4452554745%;
		padding-top: 15px;
	}

	.clu-input-box {
		width: 100%;
		margin-bottom: 25px;
		box-sizing: border-box;
	}

	.clu-input-box--pl {
		padding-left: 27px;
	}

	.clu-input-box .label {
		margin-top: 5px;
		font-size: 16px;
	}

	.clu-input-box input {
		width: 100%;
		height: 25px;
		border-bottom: 2px solid #a7a7a9;
		font-size: 22px;
		line-height: 25px;
	}

	.clu-redhead-block {
		height: 48px;
	}

	.clu-cell-main {
		width: 558px;
		padding-left: 27px;
		padding-top: 15px;
	}

	.clu-checkbox-block {
		cursor: pointer;
		margin-bottom: 8px !important;
	}

	.clu-checkbox-block input {
		display: none;
	}

	.clu-checkbox-block input:checked + .clu-s-chb,
	.clu-checkbox-block input:checked + input + clu-s-chb {
		background-image: url(img/checked.svg);
	}

	.clu-s-chb {
		display: inline-block;
		width: 30px;
		min-width: 30px;
		height: 30px;
		border: 2px solid #a9a9a9;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 95%;
		margin-right: 13px;
		box-sizing: border-box;
	}

	.clu-chb-text {
		font-size: 20px;
		line-height: 1;
		margin-top: 3px;
	}

	.clu-after-table {
		height: 240px;
		padding-left: 27px;
	}

	.clu-small-grey {
		font-size: 18px;
		line-height: 1.5;
		color: #6a6a6a;
	}

	.clu-small-grey {
		display: block;
	}

	.clu-small-grey__star {
		display: inline-block;
		min-width: 38px;
		padding-top: 17px;
		margin-left: 13px;
		text-align: right;
		float: left;
	}

	.clu-small-grey__text {
		display: block;
		width: 1200px;
		float: left;
	}

	.clu-bottom {
		font-size: 22px;
		margin-top: 10px;
		padding-bottom: 10px;
	}

	.clu-bottom__item-1 {
		float: left;
	}

	.clu-bottom__item-2 {
		float: left;
	}

	.clu-bottom__item-3 {
		float: right;
	}

	.check-list-used-page {
		padding-bottom: 0 !important;
	}

	.winter-service label {
		display: flex;
		font-family: Arial, sans-serif;
		font-size: 14px;
		line-height: 22px;
		font-weight: 400;
		color: #2c2c2c;
		margin-bottom: 19px;
	}
}