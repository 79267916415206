.your-order-page {
	.page-title {
		display: flex;
		align-items: center;
		background-color: #f6f6f6;
		text-align: center;
		padding: 10px 0;
		height: 132px;
		box-sizing: border-box;
	}

	.page-title-inner {
		width: 100%;
		max-width: 609px;
		margin: 0 auto;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
	}

	.your-order {
		max-width: 609px;
		width: 100%;
		margin: 0 auto;
		padding: 40px 20px;
		box-sizing: border-box;
		text-align: center;

		@media (min-width: 609px) {
			padding: 72px 0 100px;
		}

		.incorrect {
			color: #ce171f;
		}

		&__title {
			font-size: 20px;
			line-height: 27px;
			font-weight: bold;
			margin-bottom: 20px;

			@media (min-width: 768px) {
				font-size: 24px;
				line-height: 33px;
				margin-bottom: 39px;
			}

			br {
				display: none;

				@media (min-width: 768px) {
					display: block;
				}
			}
		}

		&__block {
			+ .your-order__block {
				margin-top: 17px;
				padding-top: 24px;
				border-top: 1px solid #e6e6e6;

				&--contacts {
					margin-top: 32px;
					padding-top: 33px;
				}

				&--sms {
					margin-top: 40px;
					padding-top: 34px;
				}
			}
		}

		&__number {
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 11px;

			span {
				color: #ce171f;
			}
		}

		&__info {
			display: flex;
			flex-wrap: wrap;
			background-color: #f7f7f7;
			text-align: left;
			margin: 0 -20px 11px;

			@media (min-width: 609px) {
				margin: 0 0 11px;
			}
		}

		&__info-item {
			padding: 15px 25px;
			box-sizing: border-box;

			@media (min-width: 768px) {
				&:first-child {
					width: 24%;
				}

				&:nth-child(2) {
					width: 54%;
				}

				&:last-child {
					width: 22%;
				}
			}

			p {
				&:first-child {
					font-size: 13px;
					color: #757575;
					margin-bottom: 4px;
				}

				&:last-child {
					font-size: 14px;
				}
			}
		}

		&__offered-price {
			font-size: 15px;
		}

		&__terms {
			font-size: 15px;
			line-height: 23px;
			margin-bottom: 9px;

			a {
				font-weight: bold;
				color: #2c2c2c;
				text-decoration: underline;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: none;
					}
				}
			}

			br {
				display: none;

				@media (min-width: 768px) {
					display: block;
				}
			}
		}

		&__order {
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 18px;
				height: 18px;
				margin-right: 5px;
				fill: #ce171f;
			}

			a {
				font-size: 15px;
				color: #ce171f;
				text-decoration: underline;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		&__contacts {
			> a {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				color: #2c2c2c;
				margin-bottom: 6px;

				svg {
					fill: #ce181f;
				}
			}

			p {
				font-size: 15px;

				&:first-of-type {
					margin-bottom: 8px;
				}

				&:last-of-type {
					a {
						color: #d7444a;
						text-decoration: underline;

						@media (min-width: 1024px) {
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		&__sms {
			font-size: 15px;
			line-height: 23px;

			a {
				color: #ce171f;
				text-decoration: underline;

				@media (min-width: 1024px) {
					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.form-block {
			margin: 25px 0 11px;

			> p {
				font-size: 16px;
				margin-bottom: 10px;
			}

			.input-block {
				max-width: 200px;
				width: 100%;
				margin-right: 10px;
			}

			input {
				margin-top: 0;

				&::placeholder {
					font-size: 16px;
					color: #999999;
				}

				&:focus::placeholder {
					opacity: 0;
				}
			}

			.btn {
				max-width: 108px;
				margin-top: 0;
				background-color: #ce171f;
				font-size: 16px;
				font-weight: bold;
			}
		}

		&__sms-flex {
			display: flex;
			max-width: 320px;
			margin: 0 auto;
		}

		&__btn {
			width: 266px;
			font-size: 18px;
			font-weight: bold;
			margin: 16px auto 0;
		}

		&__send-again {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			color: #ce171f;

			svg {
				fill: #ce171f;
			}
		}
	}
}