.vehicle-pre-delivery-checklist {
	padding-bottom: 50px;

	input[type=checkbox] {
		width: 20px;
		height: 20px;
		border: 1px solid #a5a5a5;
		background-color: #fff;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	input[type=checkbox]:checked {
		background-image: url(../img/checked.svg);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;
	}

	input[type=radio] {
		width: 20px;
		height: 20px;
		border: 1px solid #a5a5a5;
		background-color: #fff;
		cursor: pointer;
		border-radius: 50%;
		position: relative;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	input[type=radio]:checked::before {
		content: '';
		width: 10px;
		height: 10px;
		background: blue;
		border-radius: 50%;
		position: absolute;
		left:5px;
		top:5px;
	}

	.block {
		padding-top: 40px;
		overflow: auto;

		table {
			width: 100%;
			min-width: 750px;
			border: 1px solid #000;

			th {
				padding:15px 5px 5px 5px;
				vertical-align: bottom;
				text-align: left;
				background: #c00000;
				color: #fff;
				border: 0.5px solid #000;
				font-size: 110%;
			}

			th:first-child {
				width: 45%;
			}

			td {
				position: relative;
				vertical-align: bottom;
				text-align: left;
				border: 0.5px solid #000;
				padding: 15px 5px 5px 5px;
				font-weight: 400;

				textarea {
					width: 100%;
					height: 100%;
					padding: 10px;
					background: transparent;
					position: absolute;
					left: 0;
					top: 0;
					box-sizing: border-box;
				}
			}
		}
	}
}