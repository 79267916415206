.ancl-page {
	padding-bottom: 100px;

	.full-width-banner-ancl {
		margin-top: 50px;
		margin-bottom: 50px;

		@media (max-width: 580px) {
			position: relative;
		}

		&::before {
			@media (max-width: 580px) {
				content: '';
				display: block;
				padding-top: 100%;
			}
		}

		img {
			width: 100%;
			height: 405px;
			object-fit: cover;
			object-position: center center;
			font-family: 'object-fit: cover; object-position: center center;';

			@media (max-width: 580px) {
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}

	.full-width-banner-ancl-wrap {
		@media (max-width: 580px) {
			width: 100%;
			margin: 0;
		}
	}

	.fwb-ancl-i-mob {
		display: none;

		@media (max-width: 580px) {
			display: block;
		}
	}

	.fwb-ancl-i-desk {
		@media (max-width: 580px) {
			display: none;
		}
	}

	.ancl-dear-customer {
		width: 100%;
		margin-bottom: 50px;
		text-align: center;

		@media (max-width: 700px) {
			margin-bottom: 50px;
		}

		p {
			color: #2c2c2c;
			font-weight: 400;
			font-size: 16px;
			line-height: 1.3;

			@media (max-width: 700px) {
				font-size: 14px;
				line-height: 25px;
			}

			& + h2 {
				margin-top: 15px;

				@media (max-width: 700px) {
					margin-top: 10px;
				}
			}

			& + p {
				margin-top: 24px;

				@media (max-width: 700px) {
					margin-top: 20px;
				}
			}
		}

		.h2 {
			text-transform: uppercase;
			color: #2c2c2c;
			font-weight: bold;
			font-size: 28px;
			line-height: 1.3;

			@media (max-width: 700px) {
				font-size: 20px;
			}

			& + p {
				margin-top: 25px;
			}
		}

		&__row {
			display: flex;
			justify-content: space-between;

			@media (max-width: 700px) {
				display: block;
			}
		}

		&__image {
			width: 65%;
			margin-left: 30px;
			flex-shrink: 0;

			@media (max-width: 700px) {
				width: auto;
				margin-left: 0;
				margin-bottom: 50px;
			}

			iframe {
				display: block;
				width: 100%;
				height: 415px;

				@media (max-width: 700px) {
					height: 250px;
				}

				@media (max-width: 580px) {
					height: 200px;
				}
			}
		}
	}

	.ancl-form {
		border: 1px solid #d9d9d9;
		background-color: #ffffff;
		box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07);

		@media (max-width: 700px) {
			border: 0;
			box-shadow: none;
		}

		&__title {
			padding: 18px 15px;
			border-bottom: 1px solid #d9d9d9;
			background-color: #ce171f;
			font-size: 24px;
			line-height: 33px;
			font-weight: bold;
			text-align: center;
			color: #ffffff;
			box-sizing: border-box;

			@media (max-width: 700px) {
				width: 100%;
				border-bottom: 0;
				background-color: #ce171f;
				font-size: 20px;
				position: absolute;
				left: 0;
			}
		}

		&__content {
			width: 765px;
			max-width: calc(100% - 30px);
			margin: 0 auto;
			padding: 29px 0 58px;

			@media (max-width: 700px) {
				padding: 100px 0 58px;
			}

			.ah2 {
				font-size: 16px;
				text-align: center;
			}
		}
	}

	.ancl-form-row-1 {
		margin-top: 18px;
		margin-bottom: 18px;

		.input-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 500px;
			max-width: 100%;
			margin: 0 auto;

			@media (max-width: 700px) {
				flex-direction: column;
			}
		}

		.radio-btn {
			display: flex;
			align-items: center;
			margin: 0 10px !important;

			@media (max-width: 700px) {
				display: flex;
				align-items: center;
				width: 100%;
				box-sizing: border-box;
			}

			& + .radio-btn {
				@media (max-width: 700px) {
					margin-top: 10px !important;
				}
			}

			input {
				@media (max-width: 700px) {
					display: none;

					&:checked + .radio-label {
						background-color: #124390;
						color: #ffffff;

						&::before {
							background: #fff url(../img/radio.svg) no-repeat center center;
						}
					}
				}
			}

			.radio-label {
				margin: 0;

				@media (max-width: 700px) {
					display: flex !important;
					align-items: center;
					width: 100%;
					border: 1px solid #cccccc;
					padding: 10px 12px;
					margin: 0 !important;
				}

				&::before {
					@media (max-width: 700px) {
						content: '';
						display: block;
						width: 15px;
						height: 15px;
						margin: 0;
						margin-right: 8px;
						border: 1px solid #a5a5a5;
						border-radius: 25px;
						cursor: pointer;
						vertical-align: middle;
						outline: 0 !important;
					}
				}
			}
		}
	}

	.ancl-form-row-2 {
		display: flex;
		justify-content: space-between;

		@media (max-width: 700px) {
			margin-top: 35px;
		}

		.input-block {
			width: calc(33.33333% - 8px);

			@media (max-width: 700px) {
				width: 100%;
			}
		}
	}

	.ancl-form-row-3 {
		display: flex;
		justify-content: space-between;
		margin-top: 25px;

		.input-block {
			width: calc(50% - 12px);
		}
	}

	.ancl-form-row-2,
	.ancl-form-row-3 {
		@media (max-width: 700px) {
			display: block;
		}

		.input-block {
			@media (max-width: 700px) {
				width: 100%;
			}

			&:not(:first-child) {
				@media (max-width: 700px) {
					margin-top: 19px;
				}
			}
		}
	}

	.ancl-form-btn {
		width: 266px !important;
		margin: 40px auto 0;

		@media (max-width: 700px) {
			width: 100% !important;
			max-width: 100% !important;
		}
	}
}
