.bqr-page {
	padding-bottom: 50px;

	.form-after-submit {
		width: 460px;
		max-width: 100%;
		text-align: left;
		margin: 0 auto;
		.form-title {
			line-height: 1.6;
			margin-bottom: 1em;
			font-weight: 400;
		}

	}
}

.full-width-banner {
	width: 100%;
	margin-top: 50px;
	margin-bottom: 60px;

	img {
		width: 100%;
		max-width: 100%;
		height: auto;
	}

}

.bqr-main-flex {
	display: flex;
	justify-content: space-between;
}

.bqr-main-flex-left {
	width: calc(50% - 25px);

	p {
		color: #4d4d4d;
		font-weight: 400;
		font-size: 15px;
		line-height: 22px;

		& + p {
			margin-top: 20px;
		}

		& + .bqr-main-flex__h2 {
			margin-top: 35px;
		}
	}

	.bqr-main-flex__h2 {
		& + * {
			margin-top: 30px;
		}
	}
}

.bqr-main-flex__h2 {
	font-weight: 400;
	font-size: 24px;
	line-height: 33px;
}

.bqr-icons-with-text {
}

.bqr-iwt-item {
	display: flex;
	align-items: center;

	&__pic {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 37px;

		min-width: 37px;
		height: 37px;
		margin-right: 11px;
		border: 1px solid #ce171f;
		border-radius: 50%;
		background-color: #ce1820;
	}

	&__text {
		color: #4d4d4d;
		font-weight: 400;
		font-size: 18px;
		line-height: 24px;
	}

	& + .bqr-iwt-item {
		margin-top: 19px;
	}
}

.call-us-on-btn {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	box-sizing: border-box;
	width: 100%;
	margin-top: 32px;
	padding: 18px 10px;
	cursor: pointer;
	transition-duration: .2s;
	border: 1px solid #e6e6e6;
	background-color: #ffffff;

	&:hover {
		background-color: #f6f6f6;
	}

	&__text {
		font-weight: 400;
		font-size: 16px;
		line-height: 1.1
	}

	&__icon {
		margin: 0 11px;
	}

	&__number {
		font-weight: 400;
		font-size: 22px;
		line-height: 1.1
	}

	img {
	}
}

.bqr-main-flex-right {
	width: calc(50% - 25px);
}

.bqr-main-flex-right-desc {
	margin-top: 16px;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.new-quote-request.bqr-wrap {
	margin-top: 25px;
}

.form-block.bqr-form {
	.bqr-your-vehicle {
		margin-top: -10px;
		margin-bottom: 25px;
		color: #808080;
		font-family: "Vauxhall Neue";
		font-weight: 400;
		font-size: 16px;
		line-height: 28px;

		span {
			margin-left: 8px;
			color: #2c2c2c;
		}
	}

}

.form-block button.bqr-form-btn-look-up {
	margin-top: 0;
	color: #ffffff;
	background-color: #ce1820;

	&:hover {
		background-color: #58585a;
	}

}

.bqr-wrap .input-row--lookup {
	.input-block {
		width: calc(100% - 192px - 20px);

		&:nth-child(2) {
			width: 192px;
		}
	}
}

.bqr-images-block {
	.ah2 {
		color: #2c2c2c;
		font-weight: bold;
		font-size: 18px;
		line-height: 28px;
	}

	p {
		color: #4d4d4d;
	}

	.thumbs {
		.flex-block {

			.thumb,
			.upload-more {
				width: calc(33% - 20px);
				margin: 0 10px 20px;
			}

			.selection {
			}

			input {
			}
		}
	}
}

.bqr-form-privacy-policy {
	margin-top: 29px;
	margin-bottom: -20px;
	font-family: "Myriad Pro", sans-serif;
	color: #4d4d4d;
	font-weight: 400;
	font-size: 15px;
	line-height: 24px;

	a {
		font-family: "Myriad Pro", sans-serif;
		text-decoration: underline;
		color: #4d4d4d;

		&:hover {
			text-decoration: none;
		}
	}
}

.btn.btn-submit-bqr {
	margin-left: 0;
	font-weight: bold;
	font-size: 18px;

	&--blue {
	}
}

@media screen and (max-width: 900px) {
	.bqr-main-flex {
		align-items: center;
		flex-direction: column;
	}

	.bqr-main-flex-left {
		width: 100%;
	}

	.bqr-main-flex-right {
		width: 100%;
		margin-top: 30px;
	}

	.input-row--lookup {
	}

	.bqr-wrap .input-row--lookup .input-block {
		width: 100%;
		margin-bottom: 10px;
	}

	.bqr-wrap .input-row--lookup .input-block:nth-child(2) {
		width: 100%;

		label {
			display: none;
		}

		button {
			max-width: 100%;
		}
	}

	.input-row--lookup {
		display: block !important;
	}

	.btn-submit-bqr {
		width: 100%;
		box-sizing: border-box;
	}

	.bqr-wrap {
		padding-bottom: 0;
	}
}

@media screen and (max-width: 500px) {

	.bqr-images-block .thumbs .flex-block .thumb,
	.bqr-images-block .thumbs .flex-block .upload-more {
		width: calc(50% - 20px);
	}

	.bqr-iwt-item + .bqr-iwt-item {
		margin-top: 9px;
	}

	.bqr-main-flex-left p + .bqr-main-flex__h2 {
		margin-top: 20px;
	}

	.bqr-main-flex-left p + p {
		margin-top: 10px;
	}

	.bqr-main-flex-left p {

		font-size: 14px;
		line-height: 1.3;
	}

	.bqr-main-flex__h2 {
		font-size: 21px;
		line-height: 1.3;
	}

	.bqr-main-flex-left .bqr-main-flex__h2 + * {
		margin-top: 20px;
	}
	.full-width-banner {
		margin-bottom: 35px;
	}
}



