.corsa {
	&__wrapper {
		height: 241px;
		background-color: #f0f0f0;
	}

	&__tabs-header {
		display: flex;
		align-items: center;
		justify-content: space-around;
		height: 100%;
	}

	&__tabs-title {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: center;
		cursor: pointer;
		position: relative;

		&.js-active {
			.corsa__tabs-desc {
				color: #fff;
				background-color: #b72126;
			}

			&:before {
				content: '';
				width: 49px;
				height: 19px;
				margin-left: -24.5px;
				background-image: url(../img/corsa-tabs-icon.png);
				position: absolute;
				top: 100%;
				left: 50%;
				z-index: 200;
			}
		}
	}

	&__tabs-desc {
		display: inline-block;
		padding: 0 13px;
		height: 31px;
		border-radius: 15.5px;
		border: 1px solid rgba(206, 24, 31, 0);
		font-size: 18px;
		line-height: 31px;
		font-weight: bold;
		color: #2c2c2c;
		transition: 0.3s;
		box-sizing: border-box;

		@include hover {
			border-color: #b72126;
		}
	}

	&__tabs-img {
		height: 123px;
		display: block;
		margin-bottom: 11px;
		width: 240px;

		@include hover {
			+ .corsa__tabs-desc {
				border-color: #b72126;
			}
		}

		span {
			display: block;
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	&__tabs-body {
		height: 0;
		padding-top: 65px;
		opacity: 0;
		overflow: hidden;
		position: relative;
		box-sizing: border-box;
		transition: opacity 0.3s;

		&.js-active {
			height: auto;
			width: auto;
			opacity: 1;
		}
	}

	&__lead {
		margin-left: auto;
		margin-right: auto;
		font-size: 23px;
		line-height: 36px;
		color: #2c2c2c;
		text-align: center;
		text-transform: uppercase;
	}

	&__slider {
		position: relative;
	}

	#sync2,
	#sync4 {
		width: unset;
		padding-right: calc(18% + 10px);
		margin-right: 19%;

		.owl-nav {
			.owl-prev {
				display: none;
			}

			.owl-next {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 21%;
				height: calc(100% - 20px);
				text-align: center;
				cursor: pointer;
				position: absolute;
				right: 0;
				top: 40px;
				bottom: 0;
				margin-right: 7px;
				background: 0 0;
				background: #e5e5e5;
				text-decoration: none;
				color: #37383a;
				padding: 10px;
				box-sizing: border-box;
				font-weight: 700;
				user-select: none;
			}
		}

		.owl-item {
			cursor: pointer;

			&.active.current {
				.car-info__img::before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					border: 3px solid #ce181f;
					z-index: 1;
					box-sizing: border-box;
				}
			}
		}

		&.no-video {
			margin-right: 0;

			.owl-nav {
				.owl-next {
					width: 18%;
					margin-right: 0;
				}
			}
		}
	}

	.car-info {
		&__options {
			position: relative;

			.video-play {
				width: 18%;
				height: 96px;
				background-size: cover;
				background-position: center center;
				position: absolute;
				top: -118px;
				right: 0;

				&:after {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					bottom: 0;
					right: 0;
					background: url("../img/play.svg") no-repeat center center rgba(0,0,0,0.5);
					background-size: 44px auto;
				}
			}
		}
	}
}

.custom-slider {
	@media (max-width: 540px) {
		max-width: calc(100% - 34px);
		margin: 0 auto;
	}

	.slider-val {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;

		.right input {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 150px;
			height: unset;
			border: 0;
			padding: 0;
			background: none;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 18px;
			font-weight: bold;
			line-height: 23px;
			color: #ce171f;
			text-align: right;
			text-decoration: underline;
			cursor: pointer;

			@media (max-width: 540px) {
				width: 100px;
			}

			&:focus {
				width: 100px;
				height: 24px;
				padding-left: 8px;
				border: 1px solid #a5a5a5;
				background-color: #ffffff;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				font-weight: 400;
				line-height: 28px;
				color: #2c2c2c;
				text-align-last: left;
				text-decoration: none;
				cursor: text;
			}
		}
	}

	.slider {
		height: 4px;
		background-color: #85aded;
		position: relative;
		border-radius: 2px;

		.ui-slider-handle {
			display: block;
			position: absolute;
			width: 30px;
			height: 30px;
			border: 1px solid #124390;
			background-color: #ffffff;
			border-radius: 50%;
			top: calc(50% - 15px);
			margin-left: -15px;
			cursor: pointer;

			&::before {
				content: "";
				width: 12px;
				height: 12px;
				background-color: #124390;
				border-radius: 50%;
				position: absolute;
				top: calc(50% - 6px);
				left: calc(50% - 6px);
			}
		}

		.ui-slider-range {
			height: 100%;
			border-radius: 2px;
			background-color: #ce171f;
		}
	}

	.slider-desc {
		display: flex;
		justify-content: space-between;
		margin-top: 17px;

		.left,
		.right {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;
			color: #2c2c2c;
		}
	}
}
