.page-404 {
	.page-title {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f6f6f6;
		width: 100%;
		padding: 33px 10px;
		box-sizing: border-box;
	}

	.title {
		font-size: 35px;
		line-height: 33px;

		@media (max-width: 1023px) {
			font-size: 30px;
		}

		@media (max-width: 767px) {
			font-size: 24px;
			line-height: 29px;
		}
	}

	.error {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 918px;
		margin: 0 auto;
		padding: 78px 0 164px;

		@media (max-width: 1023px) {
			flex-direction: column;
			padding: 78px 0;
		}

		&__pic {
			max-width: 619px;
			width: 100%;

			@media (max-width: 1023px) {
				margin-bottom: 25px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}

		&__content {
			@media (max-width: 1023px) {
				text-align: center;
			}
		}

		&__title {
			font-size: 28px;
			font-weight: 700;
			text-transform: uppercase;
			margin-bottom: 17px;

			@media (max-width: 767px) {
				font-size: 24px;
			}
		}

		&__text {
			font-size: 16px;
			line-height: 26px;
			color: #666666;
			margin-bottom: 13px;

			@media (max-width: 767px) {
				font-size: 14px;
			}
		}

		a {
			font-size: 16px;
			color: #ce171f;
			text-decoration: underline;

			@media (min-width: 1024px) {
				&:hover {
					text-decoration: none;
				}
			}

			@media (max-width: 767px) {
				font-size: 14px;
			}
		}
	}
}