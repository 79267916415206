.print-page {
	&__container {
		max-width: 800px;
		padding: 58px 0 92px;
		margin: 0 auto;

		@media (max-width: 880px) {
			padding: 30px 10px;
		}
	}

	&__top {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 30px;
		padding-bottom: 14px;
		border-bottom: 1px solid #e6e6e6;

		@media (max-width: 767px) {
			flex-direction: column;
		}
	}

	&__top-group {
		display: flex;
		align-items: center;

		@media (max-width: 767px) {
			flex-direction: column;
		}

		@media (max-width: 450px) {
			flex-direction: column;
		}
	}

	&__top-item {
		font-size: 14px;
		color: #999999;

		&:not(:last-child) {
			margin-right: 32px;

			@media (max-width: 767px) {
				margin-right: 0;
			}
		}

		@media (max-width: 767px) {
			margin-bottom: 10px;
		}

		span {
			padding-left: 7px;
			font-size: 16px;
			color: #2c2c2c;
		}
	}

	&__print-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 150px;
		height: 38px;
		font-size: 14px;
		line-height: 38px;

		svg {
			margin-right: 11px;
		}
	}

	.print-detail {
		&__title {
			font-size: 18px;
			margin-bottom: 15px;

			span {
				font-size: 16px;
			}
		}

		&__table {
			margin-bottom: 32px;
		}

		&__row {
			display: flex;
			flex-wrap: wrap;

			&:nth-child(odd) {
				background-color: #f7f7f7;
			}
		}

		&__item {
			padding: 7px 0 13px 0;

			@media (max-width: 767px) {
				width: auto !important;
				padding: 7px 15px 13px;
			}

			&:first-child {
				width: 26.7%;
			}

			&:nth-child(2) {
				width: 22%;
			}

			&:nth-child(3) {
				width: 13%;
			}

			&:nth-child(4) {
				width: 14.7%;
			}

			&:nth-child(5) {
				width: 13%;
			}

			&:nth-child(6) {
				width: 10.6%;
			}

			p {
				&:first-child {
					font-size: 12px;
					color: #9da4b0;
				}

				&:last-child {
					font-size: 15px;
				}
			}
		}
	}

	&__condition {
		font-size: 16px;
		margin-bottom: 17px;
	}

	&__wrapper {
		display: flex;
		align-items: flex-start;
		margin: 0 -28px 24px -10px;

		@media (max-width: 767px) {
			flex-direction: column;
			margin: 0 0 24px;
		}
	}

	&__condition-table {
		width: calc(50% - 30px);
		margin-right: 15px;

		@media (max-width: 767px) {
			width: 100%;
			margin-right: 0;
			margin-bottom: 20px;
		}

		tr {
			&:nth-child(odd) {
				background-color: #f7f7f7;
			}
		}

		td {
			font-size: 14px;
			padding: 10px;
		}
	}

	&__total-price {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: calc(50% - 30px);
		padding: 41px 10px;
		margin-left: 15px;
		border: 1px solid #e6e6e6;
		background-color: #f7f7f7;
		font-size: 14px;
		line-height: 24px;
		color: #666666;
		text-align: center;
		box-sizing: border-box;

		@media (max-width: 767px) {
			width: 100%;
			padding: 20px 10px;
			margin: 0;
		}

		p {
			font-size: 28px;
			color: #ce171f;
			margin-top: 7px;

			@media (max-width: 767px) {
				font-size: 24px;
			}
		}
	}

	.print-note {
		&__title {
			font-size: 16px;
			margin-bottom: 10px;
		}

		&__block {
			padding: 18px 33px 22px;
			background-color: #eef3fb;
			border: 1px solid #3b74ce;
		}

		&__info {
			display: flex;
			align-items: center;
			margin-bottom: 12px;

			@media (max-width: 450px) {
				flex-direction: column;
			}

			svg {
				max-width: 24px;
				width: 100%;
				fill: #3b74ce;
				margin-right: 2px;
			}
		}

		&__name {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 700;
			margin-right: 14px;

			@media (max-width: 450px) {
				margin-bottom: 10px;
			}
		}

		&__date {
			font-size: 14px;
			color: #808080;
		}

		&__text {
			font-size: 14px;
			line-height: 24px;
			color: #4d4d4d;
		}
	}

	&__finish-text {
		margin: 33px 0 35px;
		font-size: 15px;
		line-height: 26px;

		@media (max-width: 767px) {
			font-size: 14px;
			line-height: 23px;
			margin: 15px 0;
		}

		br {
			@media (max-width: 767px) {
				display: none;
			}
		}

		&--center {
			text-align: center;
			margin: 0;
		}
	}

	&__button {
		width: auto;
		height: 40px;
		margin: 12px auto 13px;
		padding: 0 17px;
		font-size: 15px;
		line-height: 40px;

		@media (max-width: 767px) {
			height: auto;
			padding: 8px 17px;
			font-size: 14px;
			line-height: 18px;
		}
	}
}