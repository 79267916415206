.holyday-info {
	&__wrapper {
		padding: 23px 23px 26px 27px;
		box-shadow: -2px 1px 10px rgba(29, 30, 34, 0.18);
		border: 1px solid #d9d9d9;
		background-color: #ffffff;
	}

	&__title {
		font-size: 20px;
		font-weight: bold;
		line-height: 33px;
		color: #2c2c2c;
	}

	&__text {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
		color: #2c2c2c;

		a {
			color: #ce181f;
		}
	}
}