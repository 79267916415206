.used-stock {
	.page-main-title {
		margin-bottom: 19px;

		.title {
			@media (max-width: 999px) {
				font-size: 28px;
				line-height: 32px;
			}

			@media (max-width: 768px) {
				font-size: 24px;
				line-height: 28px;
			}

			@media (max-width: 500px) {
				font-size: 20px;
				line-height: 24px;
			}
		}
	}

	.inner {
		max-width: 1320px;
	}

	.inner3 {
		padding-bottom: 110px;
	}

	.breadcrumbs {
		margin-bottom: 20px;
	}

	.filter_button {
		display: none;

		@media (max-width: 999px) {
			display: block;
			width: 270px;
			margin-bottom: 20px;
			margin-left: auto;
			margin-right: auto;
			border-radius: 5px;
			color: #fff;
			background-color: #ce181f;
			text-align: center;
			height: 46px;
			line-height: 46px;
		}

		&.active:before {
			@media (max-width: 999px) {
				content: 'CLOSE ';
			}
		}
	}

	.flex-block {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		@media (max-width: 999px) {
			display: block;
		}

		.left_side {
			width: 290px;
			flex-grow: 0;
			flex-shrink: 0;
			margin-right: 45px;
			padding-top: 25px;
			border: 1px solid #d9d9d9;

			@media (max-width: 999px) {
				display: none;
				margin-left: auto;
				margin-right: auto;
			}

			.ah0 {
				float: left;
				margin-bottom: 18px;
				margin-left: 25px;
				font-size: 18px;
				line-height: 19px;
				font-weight: bold;
				color: #2c2c2c;

				& + .reset {
					margin-right: 25px;
					color: #ce171f;
					text-decoration: underline;
				}
			}

			.reset {
				margin-right: 25px;
				color: #ce171f;
				text-decoration: underline;
				float: right;
				background: transparent;
				font-size: 14px;
				line-height: 19px;

				@include hover {
					text-decoration: underline;
				}
			}

			.alCenter {
				text-align: center;

				.reset {
					display: inline-block;
					float: none;
					vertical-align: top;
					color: #ce181f;
					text-decoration: underline;
					margin-right: 0;
				}
			}

			.tabs {
				clear: both;
				margin-bottom: 36px;
				text-align: center;
				border-bottom: 1px solid #ce171f;
				display: flex;
				background: #f6f6f6;

				a {
					display: block;
					width: auto;
					flex-grow: 1;
					white-space: nowrap;
					padding-right: 6px;
					padding-left: 6px;
					vertical-align: top;
					color: #2c2c2c;
					border: 1px solid transparent;
					border-bottom: 0;
					font-size: 13px;
					line-height: 34px;

					@include hover {
						border-color: #a5a5a5;
					}

					&.active {
						color: #fff;
						background: #ce171f;
					}
				}
			}

			.padding {
				clear: both;
				padding-right: 25px;
				padding-bottom: 30px;
				padding-left: 25px;

				.ah1 {
					clear: both;
					margin-bottom: 7px;
					color: #2c2c2c;
					font-size: 14px;
					line-height: 17px;
				}

				select {
					display: block;
					box-sizing: border-box;
					width: 100%;
					margin-bottom: 20px;
					padding: 10px;
					padding-right: 40px;
					resize: none;
					white-space: nowrap;
					color: #a5a5a5;
					border: 1px solid #a5a5a5;
					border-radius: 3px;
					outline: none;
					background: #FFF url(../img/select.svg) no-repeat;
					background-position: calc(100% - 15px) center;
					font-size: 14px;
					line-height: 14px;
					-webkit-appearance: none;
					-moz-appearance: none;

					&::-ms-expand {
						display: none;
					}

					@include hover {
						color: #2c2c2c;
						border-color: #2c2c2c;
						background-image: url("../img/select_hov.svg");
					}

					&.forsed {
						border-color: #658dcc;
						background-color: #e9f2ff;
					}
				}

				.input_block {
					input {
						display: block;
						width: 100%;
						box-sizing: border-box;
						margin-bottom: 21px;
						padding: 10px;
						color: #a5a5a5;
						border: 1px solid #a5a5a5;
						border-radius: 3px;
						outline: none;
						background: #FFF;
						font-size: 14px;
						line-height: 14px;

						@include hover {
							color: #2c2c2c;
							border-color: #2c2c2c;
						}

						&:focus {
							color: #2c2c2c;
							border-color: #2c2c2c;
						}
					}
				}

				.check_block {
					display: block;

					input {
						display: none;
					}

					span {
						position: relative;
						display: inline-block;
						margin-bottom: 10px;
						padding-left: 29px;
						cursor: pointer;
						vertical-align: top;
						font-size: 13px;
						line-height: 20px;

						@include hover {
							text-decoration: underline;
						}

						&:before {
							content: '';
							width: 18px;
							height: 18px;
							border: 1px solid #a7a7a7;
							border-radius: 50%;
							background: #fff;
							position: absolute;
							top: -1px;
							left: 0;
						}
					}

					input:checked + span:after {
						content: '';
						width: 10px;
						height: 10px;
						border-radius: 50%;
						background: #124390;
						position: absolute;
						top: 4px;
						left: 5px;
					}
				}

				.two-inputs {
					display: flex;
					justify-content: space-between;

					select {
						&:first-child {
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}

						&:last-child {
							border-left: none;
							border-top-left-radius: 0;
							border-bottom-left-radius: 0;
						}
					}

					input {
						display: block;
						box-sizing: border-box;
						width: 50%;
						margin-bottom: 23px;
						padding: 8px 10px;
						border: 1px solid #a5a5a5;
						font-size: 14px;
						line-height: 17px;

						& + input {
							border-left: 0;
						}
					}

					> * {
						width: 50%;
						box-sizing: border-box;
					}
				}

				.red_button {
					display: block;
					width: 100%;
					height: 35px;
					margin-top: 30px;
					margin-bottom: 8px;
					text-align: center;
					color: #fff;
					background: #ce181f;
					font-weight: 400;
					font-size: 16px;
					line-height: 35px;
					border-radius: 5px;

					@include hover {
						background: #841215;
					}
				}
			}
		}

		.content {
			flex-basis: 100%;
			flex-grow: 0;
			width: 500px;

			@media (max-width: 999px) {
				width: auto;
			}

			.top_row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 35px;

				@media (max-width: 1400px) {
					flex-direction: column;
				}

				@media (max-width: 768px) {
					display: block;
					text-align: center;
				}

				p {
					width: 29%;
					margin-right: 20px;
					font-size: 16px;
					line-height: 35px;

					@media (max-width: 1400px) {
						width: 100%;
						text-align: center;
						margin-right: 0;
						margin-bottom: 15px;
					}

					@media (max-width: 999px) {
						margin-top: 20px;
					}
				}

				b {
					@media (max-width: 768px) {
						width: auto;
						margin-bottom: 10px;
					}
				}

				.right_block {
					width: 32%;
					margin-left: 20px;
					text-align: right;
					white-space: nowrap;
					font-size: 14px;
					line-height: 35px;

					@media (max-width: 1400px) {
						width: 100%;
						margin-left: 0;
						margin-top: 15px;
						text-align: center;
					}

					@media (max-width: 768px) {
						width: auto;
						margin-top: 20px;
						float: none;
						text-align: center;
					}

					select {
						display: inline-block;
						box-sizing: border-box;
						width: auto;
						margin-left: 8px;
						padding: 9px 40px 9px 10px;
						resize: none;
						vertical-align: top;
						white-space: nowrap;
						color: #a5a5a5;
						border: 1px solid #a5a5a5;
						border-radius: 3px;
						outline: none;
						background: #FFF url(../img/select.svg) no-repeat;
						background-position: calc(100% - 15px) center;
						font-size: 16px;
						line-height: 16px;
						-webkit-appearance: none;
						-moz-appearance: none;

						@include hover {
							color: #2c2c2c;
							border-color: #2c2c2c;
							background-image: url("../img/select_hov.svg");
						}
					}
				}
			}
		}
	}

	.catalog {
		overflow: unset;

		.item {
			overflow: unset;

			> div {
				overflow: unset;
				border: 1px solid #e6e6e6;
			}

			.birka {
				position: absolute;
				top: 0;
				right: 0;
				width: 146px;
				max-width: 100%;
				height: 28px;
				padding: 0 5px;
				background: #ce171f;
				z-index: 1000;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-size: 13px;
				line-height: unset;
			}
		}
	}
}