@charset "UTF-8";
@import url(common/reset.css);
@import url(common/fonts.css);
@import url(libs/jquery.fancybox.min.css);
@import url(libs/slick.css);
@import url(libs/jquery.mCustomScrollbar.min.css);
@import url(libs/nice-select.css);
@import url(libs/animate.css);
@import url(libs/tooltipster.bundle.css);
@import url(libs/fontawesome.min.css);
@import url(libs/jquery-ui.min.css);
/* common */
* {
  box-sizing: content-box;
  outline: none !important;
  font-family: 'Vauxhall Neue', sans-serif; }

html {
  height: 100%;
  font-size: 14px;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -o-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%; }

body {
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-family: 'Vauxhall Neue', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: #000;
  overflow-x: hidden;
  position: relative; }

h1, h2, h3, h4, h5, h6, p {
  display: block;
  margin: 0;
  padding: 0;
  font-weight: 400; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0;
  padding: 0;
  float: none; }

img, input, textarea, button, a {
  border: none;
  outline: none;
  font-family: 'Vauxhall Neue', sans-serif;
  font-weight: 400; }

textarea {
  position: relative;
  overflow: hidden;
  resize: none;
  box-sizing: border-box;
  display: block;
  width: 100%; }

a {
  cursor: pointer;
  color: #fff;
  text-decoration: none; }

table {
  border-spacing: 0; }

td, th {
  vertical-align: top;
  word-wrap: break-word; }

ul, nav, dl {
  margin: 0;
  padding: 0;
  list-style: none; }

.fa, .fab, .fal, .far, .fas {
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa, .far, .fas {
  font-family: "Font Awesome"; }

.fa, .fas {
  font-weight: 900; }

button {
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.inner {
  width: 96%;
  max-width: 960px;
  margin: 0 auto; }

.inner2 {
  width: 96%;
  max-width: 1140px;
  margin: 0 auto; }

.inner3 {
  width: 96%;
  max-width: 1320px;
  margin: 0 auto; }

.inner1640 {
  width: 96%;
  max-width: 1640px;
  margin: 0 auto; }

.clear {
  clear: both; }

.mb10 {
  margin-bottom: 10px; }

.mb20 {
  margin-bottom: 20px; }

.pt15 {
  padding-top: 15px; }

.page--100vh {
  height: calc(100vh - 203px); }

.page--100vh-min {
  min-height: calc(100vh - 203px); }

#wrapper {
  position: relative;
  padding-top: 80px; }

@media (max-width: 1200px) {
  #wrapper {
    padding-top: 60px; } }

.owl-stage-outer, .owl-stage {
  position: relative;
  overflow: hidden; }

.owl-item {
  position: relative;
  overflow: hidden;
  float: left; }

.owl-nav.disabled {
  display: none; }

.inputbox::-webkit-input-placeholder, .inputbox::-moz-placeholder, .inputbox:-moz-placeholder, .inputbox:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #a6a6a6; }

.inputbox:focus::-webkit-input-placeholder, .inputbox:focus::-moz-placeholder, .inputbox:focus:-moz-placeholder, .inputbox:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }

.textarea::-webkit-input-placeholder, .textarea::-moz-placeholder, .textarea:-moz-placeholder, .textarea:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease; }

.textarea:focus::-webkit-input-placeholder, .textarea:focus::-moz-placeholder, .textarea:focus:-moz-placeholder, .textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease; }

/* global */
.g-hidden {
  position: absolute;
  top: -10000em; }

.g-line {
  zoom: 1; }
  .g-line:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden; }

/* modal */
.b-modal {
  position: relative;
  margin: 0 auto;
  max-width: 1072px;
  width: 100%; }

.b-modal_close {
  background: url(../img/modal_form_close.png) no-repeat top center;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 400;
  position: absolute;
  top: -40px;
  right: -40px; }
  .b-modal_close:hover {
    background-position: center bottom; }

.b-modal {
  position: relative;
  z-index: 990; }

/* modal */
.arcticmodal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11000; }

.arcticmodal-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 11000;
  overflow: auto;
  margin: 0;
  padding: 0;
  border: 0;
  border-collapse: collapse; }

*:first-child + html .arcticmodal-container {
  height: 100%; }

.arcticmodal-container_i {
  height: 100%;
  margin: 0 auto;
  width: 100%; }

.arcticmodal-container_i2 {
  padding: 24px;
  margin: 0;
  border: 0;
  vertical-align: middle; }

.arcticmodal-error {
  padding: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -khtml-border-radius: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff; }

.car-info__slider-control {
  position: absolute;
  z-index: 99;
  top: 520px;
  left: 10px;
  display: flex; }

.overflow-hidden {
  overflow: hidden; }

.opened-menu-slide {
  transition: all 500ms; }

.overflow-hidden .opened-menu-slide {
  overflow: hidden; }

.overflow-hidden #wrapper {
  overflow: hidden; }

.fz15 {
  font-size: 15px !important; }

.red {
  color: #ce181e; }

.btn-red {
  cursor: pointer;
  user-select: none;
  color: #ffffff;
  background-color: #ce181f;
  font-size: 14px; }
  .btn-red:hover {
    background-color: #841215; }

.checkbox-container {
  position: relative;
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.checkbox-container input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0; }

.checkbox-mark {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #a9a9a9;
  background-color: #ffffff; }

.checkbox-container:hover input ~ .checkbox-mark {
  background-color: #d7d7d7; }

.checkbox-mark:after {
  position: absolute;
  display: none;
  content: ""; }

.checkbox-container input:checked ~ .checkbox-mark:after {
  display: block; }

.checkbox-container .checkbox-mark:after {
  top: 0px;
  left: 5px;
  width: 5px;
  height: 10px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border: solid #ce181e;
  border-width: 0 3px 3px 0; }

.hide {
  display: none; }

.images-upload-block .label {
  color: #2c2c2c;
  font-size: 13px; }
  .images-upload-block .label strong {
    margin-right: 10px; }

.images-upload-block .grid-block {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  margin-left: -20px; }
  .images-upload-block .grid-block .item {
    box-sizing: border-box;
    height: 130px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 0;
    border: 1px solid #026fa8;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease;
    flex: 1 0 140px;
    margin-left: 20px;
    margin-top: 0; }
    @media (min-width: 320px) {
      .images-upload-block .grid-block .item {
        max-width: calc(50% - 20px); } }
    @media (min-width: 480px) {
      .images-upload-block .grid-block .item {
        max-width: calc(33.33333% - 20px); } }
    @media (min-width: 640px) {
      .images-upload-block .grid-block .item {
        max-width: calc(25% - 20px); } }
    @media (min-width: 560px) {
      .images-upload-block .grid-block .item {
        min-width: calc(25% - 20px); } }
    @media (min-width: 1024px) {
      .images-upload-block .grid-block .item:hover {
        background-color: rgba(2, 111, 168, 0.08); } }
    .images-upload-block .grid-block .item label > span {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;
      height: 100%;
      padding: 10px 2px 7px;
      cursor: pointer; }
    .images-upload-block .grid-block .item .title {
      font-size: 13px;
      color: #2c2c2c; }
    .images-upload-block .grid-block .item .status {
      font-size: 12px;
      font-weight: 400; }
    .images-upload-block .grid-block .item.uploaded {
      background-color: #026fa8;
      border-color: #ce171f; }
      .images-upload-block .grid-block .item.uploaded .title {
        color: #ffffff; }
      .images-upload-block .grid-block .item.uploaded .status {
        padding-right: 25px;
        color: #ffffff;
        background: url("../img/sell-img-check.png") right center no-repeat; }
      .images-upload-block .grid-block .item.uploaded .img-block {
        opacity: 0.21; }
    .images-upload-block .grid-block .item .remove-link {
      display: none;
      margin-top: 9px;
      cursor: pointer;
      text-align: center;
      color: #6e7777;
      font-size: 12px; }
      .images-upload-block .grid-block .item .remove-link:hover {
        text-decoration: underline; }

.images-upload-block .or-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px; }
  .images-upload-block .or-checkbox .checkbox-container {
    margin: 0 18px; }
  .images-upload-block .or-checkbox .skip-text {
    color: #757575; }

.form-page {
  box-sizing: border-box;
  margin-bottom: 40px;
  padding: 35px 0; }
  .form-page .form-title {
    margin-bottom: 30px;
    color: #2c2c2c;
    font-weight: 700;
    font-size: 18px; }
  .form-page.form-book-appointment .form-block .row-1 {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-top: -20px; }
    .form-page.form-book-appointment .form-block .row-1 .input-block {
      flex: 1 0 250px;
      margin-left: 30px;
      margin-top: 20px; }
      @media (min-width: 560px) {
        .form-page.form-book-appointment .form-block .row-1 .input-block {
          max-width: calc(50% - 30px); } }
      @media (min-width: 840px) {
        .form-page.form-book-appointment .form-block .row-1 .input-block {
          max-width: calc(33.33333% - 30px); } }
      @media (min-width: 750px) {
        .form-page.form-book-appointment .form-block .row-1 .input-block {
          min-width: calc(33.33333% - 30px); } }
  .form-page.form-book-appointment .form-block .row-3 .flex-block {
    display: flex;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-top: -20px; }
    .form-page.form-book-appointment .form-block .row-3 .flex-block .input-block {
      flex: 1 0 250px;
      margin-left: 30px;
      margin-top: 20px; }
      @media (min-width: 560px) {
        .form-page.form-book-appointment .form-block .row-3 .flex-block .input-block {
          max-width: calc(50% - 30px); } }
      @media (min-width: 840px) {
        .form-page.form-book-appointment .form-block .row-3 .flex-block .input-block {
          max-width: calc(33.33333% - 30px); } }
      @media (min-width: 750px) {
        .form-page.form-book-appointment .form-block .row-3 .flex-block .input-block {
          min-width: calc(33.33333% - 30px); } }
  .form-page.form-book-appointment .form-block .border-row {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 40px;
    padding-top: 22px;
    padding-bottom: 22px;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc; }
    @media (max-width: 768px) {
      .form-page.form-book-appointment .form-block .border-row {
        display: block; } }
    .form-page.form-book-appointment .form-block .border-row .input-block {
      flex-shrink: 0;
      width: 300px;
      margin-right: 40px;
      padding-top: 25px; }
      @media (max-width: 768px) {
        .form-page.form-book-appointment .form-block .border-row .input-block {
          padding-top: 0;
          width: auto;
          padding-bottom: 20px;
          margin-right: 0; } }
      .form-page.form-book-appointment .form-block .border-row .input-block .ah1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 27px; }
      .form-page.form-book-appointment .form-block .border-row .input-block label:not(.radio-label) {
        margin-top: 25px; }
      .form-page.form-book-appointment .form-block .border-row .input-block .row {
        display: flex;
        flex-wrap: nowrap;
        margin-top: 0;
        margin-bottom: 0; }
        .form-page.form-book-appointment .form-block .border-row .input-block .row input {
          width: 100%; }
        .form-page.form-book-appointment .form-block .border-row .input-block .row .btn--red {
          width: 88px;
          margin-left: 11px;
          transition: .3s background;
          background: #ce171f;
          font-size: 16px;
          line-height: 44px; }
          @media (min-width: 1024px) {
            .form-page.form-book-appointment .form-block .border-row .input-block .row .btn--red:hover {
              background: #58585a; } }
    .form-page.form-book-appointment .form-block .border-row .form-map {
      width: 100%;
      height: 370px;
      border: 1px solid #cccccc;
      box-sizing: border-box; }
      .form-page.form-book-appointment .form-block .border-row .form-map iframe {
        width: 100%;
        height: 100%; }

#chat-select {
  position: relative;
  display: none;
  box-sizing: border-box;
  width: 566px;
  padding: 35px 35px 50px;
  background: #ffffff; }
  #chat-select .close-modal {
    position: absolute;
    top: 10px;
    left: 10px;
    display: block;
    cursor: pointer; }
    #chat-select .close-modal:hover svg path {
      fill: #841215; }
  #chat-select .go-back {
    position: absolute;
    top: 9px;
    right: 10px;
    display: none;
    cursor: pointer;
    user-select: none;
    color: #9d9d9d;
    font-weight: 400;
    font-size: 12px;
    line-height: 1; }
    #chat-select .go-back:hover {
      color: #2c2c2c; }
  #chat-select .btn-red {
    width: 249px;
    height: 46px;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
    color: #ffffff;
    background-color: #ce181f;
    font-size: 14px;
    line-height: 46px; }
    #chat-select .btn-red:hover {
      background-color: #841215; }
  #chat-select .big-title {
    margin-bottom: 25px;
    text-align: center;
    color: #58595b;
    font-weight: 400;
    font-size: 28px; }
  #chat-select .small-title {
    max-width: 355px;
    margin-right: auto;
    margin-bottom: 30px;
    margin-left: auto;
    text-align: center;
    color: #2c2c2c;
    font-size: 18px;
    line-height: 28px; }
  #chat-select input[type=radio] {
    width: 21px;
    height: 21px;
    margin: 0 0 0 12px;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid #2c2c2c;
    border-radius: 25px;
    outline: none !important;
    -webkit-appearance: none;
    -moz-appearance: none; }
    #chat-select input[type=radio]:checked {
      background: #FFF url(../img/chat/radio.svg) no-repeat center center; }
  #chat-select .select-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 356px;
    margin: 10px auto 0; }
    #chat-select .select-row label {
      display: flex;
      align-items: center;
      margin-bottom: 45px;
      cursor: pointer; }
      #chat-select .select-row label img {
        display: block;
        margin-left: 17px; }
  #chat-select .step {
    display: none; }
    #chat-select .step.active {
      display: block; }
  #chat-select .step-2 .mode-row {
    display: flex;
    justify-content: space-between;
    width: 181px;
    margin: 0 auto; }
    #chat-select .step-2 .mode-row .mode {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      width: 56px;
      height: 56px;
      text-align: center;
      border: 1px solid #2c2c2c;
      border-radius: 100%;
      background-color: #2c2c2c; }
      #chat-select .step-2 .mode-row .mode .image-block {
        display: block;
        width: 23px;
        height: 23px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain; }
      #chat-select .step-2 .mode-row .mode .label {
        display: block;
        margin-top: 5px;
        color: #fffefe;
        font-size: 11px;
        line-height: 1; }
      #chat-select .step-2 .mode-row .mode.mode-1 .image-block {
        background-image: url("../img/chat/chat-1.png"); }
      #chat-select .step-2 .mode-row .mode.mode-1:hover .image-block {
        background-image: url("../img/chat/chat-2.png"); }
      #chat-select .step-2 .mode-row .mode.mode-2 .image-block {
        background-image: url("../img/chat/text-1.png"); }
      #chat-select .step-2 .mode-row .mode.mode-2:hover .image-block {
        background-image: url("../img/chat/text-2.png"); }
      #chat-select .step-2 .mode-row .mode:hover {
        background-color: #ffffff; }
        #chat-select .step-2 .mode-row .mode:hover .label {
          color: #2c2c2c; }
  #chat-select .step-3 .mode-row {
    display: flex;
    align-items: center;
    flex-direction: column; }
    #chat-select .step-3 .mode-row .branch {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 415px; }
      #chat-select .step-3 .mode-row .branch + .branch {
        margin-top: 18px; }
      #chat-select .step-3 .mode-row .branch .text {
        width: calc(100% - 162px);
        margin-right: 20px;
        text-align: right;
        color: #2c2c2c;
        font-size: 16px; }
      #chat-select .step-3 .mode-row .branch .modes {
        display: flex;
        justify-content: space-between;
        width: 142px;
        min-width: 142px; }
    #chat-select .step-3 .mode-row .mode {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 65px;
      height: 25px;
      text-align: center;
      border: 1px solid #2c2c2c;
      border-radius: 13px;
      background-color: #2c2c2c; }
      #chat-select .step-3 .mode-row .mode .image-block {
        display: block;
        width: 19px;
        height: 16px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain; }
      #chat-select .step-3 .mode-row .mode .label {
        display: block;
        margin-left: 6px;
        color: #fffefe;
        font-size: 10px;
        line-height: 1; }
      #chat-select .step-3 .mode-row .mode.mode-1 .image-block {
        background-image: url("../img/chat/chat-1.png"); }
      #chat-select .step-3 .mode-row .mode.mode-1:hover .image-block {
        width: 15px;
        background-image: url("../img/chat/chat-2.png"); }
      #chat-select .step-3 .mode-row .mode.mode-2 .image-block {
        background-image: url("../img/chat/text-1.png"); }
      #chat-select .step-3 .mode-row .mode.mode-2:hover .image-block {
        background-image: url("../img/chat/text-2.png"); }
      #chat-select .step-3 .mode-row .mode:hover {
        background-color: #ffffff; }
        #chat-select .step-3 .mode-row .mode:hover .label {
          color: #2c2c2c; }

.table-wrap {
  overflow-x: auto;
  width: 100%; }

table.variants-table {
  min-width: 100%;
  border-collapse: collapse; }
  table.variants-table tr th {
    padding: 7px 5px;
    text-align: left;
    white-space: nowrap;
    font-weight: bold;
    font-size: 13px; }
  table.variants-table tr td {
    padding: 7px 5px;
    vertical-align: middle;
    white-space: nowrap;
    font-size: 14px; }
    table.variants-table tr td:first-child {
      padding-left: 10px; }
    table.variants-table tr td:nth-child(n+4):not(:last-child) {
      text-align: center; }
  table.variants-table tr:nth-child(even) td {
    background-color: #f2f2f2; }

.models-select-list .radio-btns {
  display: flex;
  flex-wrap: wrap; }
  .models-select-list .radio-btns .radio-btn {
    flex: 1 0 220px;
    margin-top: 0 !important;
    margin-bottom: 20px; }

.models-select-list .input-block input[type=radio] {
  position: relative;
  top: -2px;
  width: 20px;
  height: 20px;
  background: #FFF; }
  .models-select-list .input-block input[type=radio]:checked {
    background: #FFF url(../img/radio-b.svg) no-repeat center center; }
    .models-select-list .input-block input[type=radio]:checked:not(:hover) {
      background: #FFF url(../img/radio-g.svg) no-repeat center center; }

.modal-block {
  display: none; }
  .modal-block .input-block > label {
    margin-bottom: 40px !important;
    font-weight: bold;
    font-size: 20px !important; }
  .modal-block.variant-select-list .form-block .input-block > label {
    width: 100%;
    text-align: center !important; }
  .modal-block.variant-select-list .form-block .input-block input[type=radio] {
    width: 20px;
    height: 20px;
    background: #FFF; }
    .modal-block.variant-select-list .form-block .input-block input[type=radio]:checked {
      background: #FFF url(../img/radio-b.svg) no-repeat center center; }
      .modal-block.variant-select-list .form-block .input-block input[type=radio]:checked:not(:hover) {
        background: #FFF url(../img/radio-g.svg) no-repeat center center; }

#login {
  display: none; }
  #login form {
    width: 290px;
    max-width: 100%; }
  #login .input-block + .input-block {
    margin-top: 10px; }

.preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); }
  .preloader .preloader-load {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
    box-sizing: border-box;
    width: 100px;
    height: 100px;
    margin: 0 auto;
    margin-top: -50px;
    margin-left: -50px;
    text-align: center; }
    .preloader .preloader-load > span {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      animation: roll 2s infinite linear;
      border: 2px solid #ed1c24;
      border-left-color: transparent !important;
      border-radius: 50%; }
      .preloader .preloader-load > span::before, .preloader .preloader-load > span::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        box-sizing: border-box;
        content: "";
        transform: translate(-50%, -50%);
        border: 2px solid #ed1c24;
        border-radius: 50%; }
      .preloader .preloader-load > span::before {
        width: 75%;
        height: 75%;
        border-right-color: transparent !important; }
      .preloader .preloader-load > span::after {
        width: 50%;
        height: 50%;
        border-bottom-color: transparent !important; }

@-webkit-keyframes roll {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

@keyframes roll {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.blue-buttons_long--big {
  display: block;
  margin-top: 10px; }
  @media (max-width: 900px) {
    .blue-buttons_long--big {
      max-width: 450px;
      margin: 30px auto 0; } }
  .blue-buttons_long--big .btn-finance-explained {
    display: block;
    width: 225px;
    height: 45px;
    box-sizing: border-box;
    line-height: 34px;
    margin-left: auto; }

.check-holyday {
  margin: 15px 0 0 10px; }
  .check-holyday .lbl-chb {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0; }
    .check-holyday .lbl-chb span {
      font-size: 15px !important;
      line-height: 28px;
      font-weight: 400 !important; }
    .check-holyday .lbl-chb .tip {
      line-height: 12px; }

.finance__block .btn-finance-explained {
  padding: 6px 11px;
  color: #fff;
  background: #000;
  font-size: 14px; }
  @media (max-width: 580px) {
    .finance__block .btn-finance-explained {
      max-width: 236px;
      height: 35px; } }

.finance__block .row {
  display: flex;
  justify-content: space-between; }
  .finance__block .row p {
    margin-top: 10px; }
  .finance__block .row .credit-small-text {
    margin-top: 10px;
    padding-left: 19px;
    opacity: 0.7;
    font-size: smaller;
    line-height: 10px;
    font-style: italic; }
  .finance__block .row ul {
    margin: 10px 0 0 19px;
    padding: 0;
    list-style-type: disc; }
  .finance__block .row .blk {
    width: 48%; }

.disabled-pointer {
  pointer-events: none;
  opacity: .5; }
  .disabled-pointer span::before {
    background-color: #a7a7a7; }

.modal-reg-login.modal-info {
  width: 100%;
  max-width: 800px;
  padding: 41px 48px 41px 48px; }
  .modal-reg-login.modal-info form {
    width: unset; }
  .modal-reg-login.modal-info .information {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px; }
  .modal-reg-login.modal-info-two-btn .buttons-row {
    display: flex;
    justify-content: space-between; }
    .modal-reg-login.modal-info-two-btn .buttons-row button {
      max-width: 45%; }

@media screen and (max-width: 560px) {
  .modal-reg-login.modal-info {
    padding: 19px 15px 18px 15px; }
    .modal-reg-login.modal-info .information {
      font-size: 15px;
      line-height: 19px; } }

.modal-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 46px;
  padding: 10px 15px;
  transition-duration: 0.2s;
  color: #fff;
  background-color: #124390;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px; }
  .modal-btn:hover {
    filter: brightness(1.2); }
  .modal-btn.modal-btn__fw {
    width: 100%; }

.modal-buttons-row-2 {
  display: flex;
  justify-content: center; }
  .modal-buttons-row-2 .modal-btn {
    max-width: calc(50% - 10px); }
    .modal-buttons-row-2 .modal-btn:first-child {
      margin-right: 20px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt30 {
  margin-top: 30px; }

.mt45 {
  margin-top: 45px; }

span.ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-e,
span.ui-accordion-header-icon.ui-icon.ui-icon-triangle-1-s {
  display: none; }

.ui-state-active {
  border-color: #ddd; }

.ui-accordion .ui-accordion-content {
  overflow: auto;
  /* padding: 1em 2.2em; */
  border-top: 0; }

.ui-slider-horizontal {
  height: 0.8em; }
  .ui-slider-horizontal.ui-corner-all, .ui-slider-horizontal.ui-corner-bottom, .ui-slider-horizontal.ui-corner-right, .ui-slider-horizontal.ui-corner-br {
    border-bottom-right-radius: 3px; }
  .ui-slider-horizontal.ui-corner-all, .ui-slider-horizontal.ui-corner-bottom, .ui-slider-horizontal.ui-corner-left, .ui-slider-horizontal.ui-corner-bl {
    border-bottom-left-radius: 3px; }
  .ui-slider-horizontal.ui-corner-all, .ui-slider-horizontal.ui-corner-top, .ui-slider-horizontal.ui-corner-right, .ui-slider-horizontal.ui-corner-tr {
    border-top-right-radius: 3px; }
  .ui-slider-horizontal.ui-corner-all, .ui-slider-horizontal.ui-corner-top, .ui-slider-horizontal.ui-corner-left, .ui-slider-horizontal.ui-corner-tl {
    border-top-left-radius: 3px; }
  .ui-slider-horizontal.ui-widget-content {
    color: #333333;
    background: #ffffff; }
  .ui-slider-horizontal.ui-widget {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em; }
  .ui-slider-horizontal.ui-slider {
    position: relative;
    text-align: left; }
  .ui-slider-horizontal .ui-slider-range-min {
    left: 0; }
  .ui-slider-horizontal .ui-slider-range {
    top: 0;
    height: 100%;
    border-radius: 3px; }
  .ui-slider-horizontal .ui-slider-range {
    position: absolute;
    z-index: 1;
    display: block;
    border: 0;
    background-position: 0 0;
    font-size: .7em; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-bottom,
  .ui-slider-horizontal .ui-corner-right,
  .ui-slider-horizontal .ui-corner-br {
    border-bottom-right-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-bottom,
  .ui-slider-horizontal .ui-corner-left,
  .ui-slider-horizontal .ui-corner-bl {
    border-bottom-left-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-top,
  .ui-slider-horizontal .ui-corner-right,
  .ui-slider-horizontal .ui-corner-tr {
    border-top-right-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-top,
  .ui-slider-horizontal .ui-corner-left,
  .ui-slider-horizontal .ui-corner-tl {
    border-top-left-radius: 3px; }
  .ui-slider-horizontal .ui-widget-header {
    color: #333333;
    border: 1px solid #dddddd;
    background: #e9e9e9;
    font-weight: bold; }
  .ui-slider-horizontal .ui-widget-content .ui-state-default {
    color: #454545;
    border: 1px solid #c5c5c5;
    background: #f6f6f6;
    font-weight: normal; }
  .ui-slider-horizontal.ui-slider .ui-slider-handle {
    position: absolute;
    z-index: 2;
    width: 1.2em;
    height: 1.2em;
    cursor: default;
    -ms-touch-action: none;
    touch-action: none; }
  .ui-slider-horizontal.ui-slider-horizontal .ui-slider-handle {
    top: -.3em;
    margin-left: -.6em; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-bottom,
  .ui-slider-horizontal .ui-corner-right,
  .ui-slider-horizontal .ui-corner-br {
    border-bottom-right-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-bottom,
  .ui-slider-horizontal .ui-corner-left,
  .ui-slider-horizontal .ui-corner-bl {
    border-bottom-left-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-top,
  .ui-slider-horizontal .ui-corner-right,
  .ui-slider-horizontal .ui-corner-tr {
    border-top-right-radius: 3px; }
  .ui-slider-horizontal .ui-corner-all,
  .ui-slider-horizontal .ui-corner-top,
  .ui-slider-horizontal .ui-corner-left,
  .ui-slider-horizontal .ui-corner-tl {
    border-top-left-radius: 3px; }

.modal-fancy {
  overflow-wrap: break-word; }

.dn {
  display: none; }

.custom_radios input[type=radio]:checked {
  background: #fff url(../img/radio.svg) no-repeat center center; }

.custom_radios input[type=radio] {
  width: 15px;
  height: 15px;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #a5a5a5;
  border-radius: 25px;
  outline: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none; }

.dn {
  display: none; }

.hb1-2:hover {
  cursor: pointer;
  transition-duration: .2s;
  filter: brightness(1.2); }

.pagi-type-wl {
  width: fit-content;
  max-width: 90%;
  margin: 30px auto 100px;
  /*Пагинация как на странице warranty-log применять вместе с классом form-block*/ }
  .pagi-type-wl .mwrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px; }
  .pagi-type-wl .show {
    display: flex;
    align-items: center;
    margin: 0 10px;
    margin-bottom: 20px;
    color: #2c2c2c;
    font-family: "Vauxhall Neue";
    font-weight: 400;
    font-size: 14px;
    line-height: 28px; }
    .pagi-type-wl .show .input-block select {
      width: 68px;
      height: 35px;
      margin-left: 10px;
      padding: 0 15px 0 10px;
      color: #2c2c2c;
      background: #fff url(../img/select_hov.svg) no-repeat;
      background-position: calc(100% - 15px) center;
      font-family: "Vauxhall Neue";
      font-weight: 400;
      font-size: 14px;
      line-height: 28px; }
  .pagi-type-wl .pages {
    display: flex;
    margin: 0 10px;
    border: 1px solid #d7d7d7; }
    .pagi-type-wl .pages a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 34px;
      transition-duration: .2s;
      color: #2c2c2c; }
      .pagi-type-wl .pages a:not(:last-child) {
        border-right: 1px solid #d7d7d7; }
      .pagi-type-wl .pages a.current {
        pointer-events: none;
        color: #fff;
        background-color: #ce171f; }
      .pagi-type-wl .pages a:hover {
        color: #fff;
        background-color: #ce171f; }
    .pagi-type-wl .pages a.ttt {
      pointer-events: none; }

.tov-el {
  display: inline-block;
  overflow: hidden;
  width: 300px;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis; }

.nowrap {
  white-space: nowrap; }

.flex-row {
  display: flex; }

.aic {
  align-items: center; }

.all__page--err_block {
  margin-bottom: 40px;
  text-align: center;
  font-size: 18px;
  line-height: 24px; }
  .all__page--err_block .inn_err_block {
    position: relative;
    padding: 30px 45px 35px;
    border: 2px solid #e6e6e6; }
  .all__page--err_block .closebtn {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 22px;
    cursor: pointer; }
    .all__page--err_block .closebtn:hover {
      transition-duration: .2s;
      filter: brightness(0.5); }
  .all__page--err_block .ah1 {
    margin-bottom: 13px;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px; }
  .all__page--err_block p {
    max-width: 600px;
    margin-right: auto;
    margin-bottom: 0;
    margin-bottom: 13px;
    margin-left: auto; }
  .all__page--err_block .tel {
    display: inline-block;
    padding-bottom: 6px;
    vertical-align: top;
    color: #2c2c2c;
    font-weight: 700;
    font-size: 22px;
    line-height: 25px; }
    .all__page--err_block .tel:before {
      display: inline-block;
      width: 19px;
      height: 18px;
      margin-top: -4px;
      margin-right: 8px;
      content: '';
      vertical-align: middle;
      background: url(../img/err.svg) no-repeat;
      background-size: auto 100%; }

.deal-log-page .deal-manager .container {
  max-width: 1700px; }

.checkbox-customized {
  /*input + label*/
  display: none; }
  .checkbox-customized + label,
  .checkbox-customized + span {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #a9a9a9;
    background-color: #ffffff; }
    .checkbox-customized + label::before,
    .checkbox-customized + span::before {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 20px;
      height: 20px;
      content: '';
      transition: opacity .1s;
      transform: translate(-50%, -50%);
      opacity: 0;
      background: url("../img/checked.svg") no-repeat center center;
      background-size: contain; }
  .checkbox-customized:checked + label::before,
  .checkbox-customized:checked + span::before {
    opacity: 1; }

.checkbox-customizedv2-wrap {
  cursor: pointer;
  display: flex; }

.checkbox-customizedv2 {
  /*input.checkbox-customizedv2 + span*/
  display: none; }
  .checkbox-customizedv2 + span {
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    display: block;
    color: #2c2c2c;
    font-family: "Vauxhall Neue";
    font-size: 15px;
    font-weight: 400;
    line-height: 30px; }
    .checkbox-customizedv2 + span::after {
      position: absolute;
      top: 3px;
      left: -1px;
      display: block;
      width: 20px;
      width: 20px;
      height: 20px;
      height: 20px;
      content: '';
      cursor: pointer;
      transition: opacity .1s;
      border: 1px solid #a9a9a9;
      background-color: #ffffff;
      background-size: contain; }
    .checkbox-customizedv2 + span::before {
      position: absolute;
      z-index: 1;
      top: 4px;
      left: 0;
      display: block;
      width: 20px;
      width: 20px;
      height: 20px;
      height: 20px;
      content: '';
      cursor: pointer;
      transition: opacity .1s;
      opacity: 0;
      background: url("../img/checked.svg") no-repeat center center;
      background-size: contain; }
  .checkbox-customizedv2:checked + span::before {
    opacity: 1; }

.redu {
  text-decoration: underline;
  color: #f01019; }

.input-block--h-35 label {
  margin-bottom: 0 !important;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px; }

.input-block--h-35 input,
.input-block--h-35 select {
  height: 35px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 15px;
  line-height: 1; }

.radios-and-hint-right {
  display: block; }
  @media (max-width: 900px) {
    .radios-and-hint-right {
      display: none; } }
  .radios-and-hint-right--mobile {
    display: none;
    width: 100%;
    max-width: 500px; }
    @media (max-width: 900px) {
      .radios-and-hint-right--mobile {
        display: block; } }

.radiogroup-v2 {
  display: flex;
  flex-wrap: wrap;
  margin: -7.5px; }
  .radiogroup-v2.radiogroup-v2--fg-lbl .radiogroup-v2__lbl {
    flex-grow: 1; }
    .radiogroup-v2.radiogroup-v2--fg-lbl .radiogroup-v2__lbl span {
      width: 100%; }
  .radiogroup-v2__lbl {
    margin: 7.5px; }
    .radiogroup-v2__lbl input {
      display: none; }
      .radiogroup-v2__lbl input:checked + span {
        height: 100%;
        pointer-events: none;
        color: #ffffff;
        border: 0;
        background-color: #ce181f; }
        .radiogroup-v2__lbl input:checked + span::before {
          border: 5px solid #ffffff;
          background-color: #ce181f; }
    .radiogroup-v2__lbl span {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      box-sizing: border-box;
      min-height: 44px;
      padding: 12px 25px 12px 12px;
      cursor: pointer;
      text-transform: uppercase;
      text-transform: uppercase;
      color: #2c2c2c;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3; }
      .radiogroup-v2__lbl span:hover {
        background-color: #ffecec; }
      .radiogroup-v2__lbl span::before {
        display: block;
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        content: '';
        border: 1px solid #a7a7a7;
        border-radius: 50%; }
  .radiogroup-v2--car {
    margin-top: 0; }
    @media (max-width: 580px) {
      .radiogroup-v2--car {
        margin-top: 7px; } }
    .radiogroup-v2--car .radiogroup-v2__lbl {
      width: calc(33.3333% - 15px); }
      @media (max-width: 580px) {
        .radiogroup-v2--car .radiogroup-v2__lbl {
          width: calc(50% - 15px); } }
      @media (max-width: 580px) {
        .radiogroup-v2--car .radiogroup-v2__lbl:nth-child(n+3) {
          width: 100%; } }
      .radiogroup-v2--car .radiogroup-v2__lbl span {
        width: 100%;
        padding: 11px;
        padding-left: 38px;
        position: relative; }
        .radiogroup-v2--car .radiogroup-v2__lbl span::before {
          position: absolute;
          left: 11px;
          top: 50%;
          transform: translateY(-50%); }

.radiogroup--info-column {
  flex-direction: column; }
  @media (max-width: 987px) {
    .radiogroup--info-column {
      width: 100%;
      margin: 20px -5px 0; } }
  .radiogroup--info-column .radiogroup__tabs, .radiogroup--info-column .radiogroup__content {
    width: 100% !important;
    box-sizing: border-box; }
  .radiogroup--info-column .radiogroup__tabs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 !important; }
  .radiogroup--info-column .radiogroup__radio-wrapper {
    width: 100%;
    flex-direction: row !important;
    margin: 0 !important; }
  .radiogroup--info-column .radiogroup__radio {
    width: calc(50% - 10px) !important;
    margin: 0 5px 14px !important; }
  .radiogroup--info-column .check-holyday {
    margin-top: 0;
    margin-bottom: 14px; }

.c-sli-v2-top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -7.5px; }
  .c-sli-v2-top > * {
    margin: 0 7.5px; }

.c-sli-v2-current-value {
  flex-grow: 1; }
  .c-sli-v2-current-value input {
    width: 180px;
    height: 35px;
    margin-bottom: 0 !important;
    color: #ce171f;
    border: 1px solid #a5a5a5;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 18px;
    line-height: 1 !important; }

.c-sli-v2-top__left-desc {
  flex-grow: 99999;
  color: #a6a6a6;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3; }

.c-sli-v2-current-residual-max {
  flex-grow: 1;
  min-width: 100px;
  text-align: right;
  color: #124390;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px; }

.c-sli-slider-v2 {
  width: 100%;
  height: 4px;
  margin-top: 20px;
  margin-bottom: 14px;
  border-radius: 2px;
  background-color: #b6c9e6 !important; }
  .c-sli-slider-v2 .ui-slider-range {
    height: 4px;
    border: 0;
    border-radius: 2px;
    background-color: #c44b51; }
  .c-sli-slider-v2 .ui-slider-handle {
    top: -8px !important;
    width: 20px !important;
    height: 20px !important;
    cursor: pointer !important;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 1px 6px rgba(18, 67, 144, 0.6); }
    .c-sli-slider-v2 .ui-slider-handle::before {
      position: absolute;
      top: 6px;
      left: 6px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      border-radius: 50%;
      background-color: #124390; }

.c-sli-v2-bottom {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3; }
  .c-sli-v2-bottom .left {
    margin-right: 10px;
    color: #ce171f; }
  .c-sli-v2-bottom .right {
    color: #124390; }

@media screen and (max-width: 1100px) {
  .build-your-deal .c-sli-slider-v2 {
    width: calc(100% - 25px);
    margin-right: auto;
    margin-left: auto; } }

.datepicker-input-u {
  box-sizing: border-box;
  padding: 13px 15px;
  padding-right: 41px !important;
  background: #fff url(../img/datepicker.png) no-repeat;
  background-position: calc(100% - 9px) center;
  background-size: auto 80%; }

.system-error {
  display: none;
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 18px;
  padding: 15px 17px;
  border: 1px solid #ce181f;
  background-color: #fff5f5; }
  .system-error .label {
    color: #d1282e;
    font-weight: bold;
    font-size: 16px; }
  .system-error .text {
    margin-top: 3px;
    color: #d1282e;
    font-weight: 400;
    font-size: 14px; }
  .system-error.show {
    display: block; }
  .system-error.system-error-green {
    border: 1px solid green;
    background-color: #e3fde3; }
    .system-error.system-error-green .label {
      color: green; }
    .system-error.system-error-green .text {
      color: green; }
  .system-error.system-error-yellow {
    border: 1px solid #e9af00;
    background-color: #fefee7; }
    .system-error.system-error-yellow .label {
      color: #e9af00; }
    .system-error.system-error-yellow .text {
      color: #e9af00; }

#sync1,
#sync3 {
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 10px; }
  #sync1:not(.owl-loaded),
  #sync3:not(.owl-loaded) {
    display: flex;
    flex-wrap: nowrap;
    opacity: 0; }

#sync2:not(.owl-loaded),
#sync4:not(.owl-loaded) {
  display: flex;
  flex-wrap: nowrap;
  opacity: 0; }

#sync2 .owl-nav,
#sync4 .owl-nav {
  display: none; }

#sync2 .car-info__slide,
#sync4 .car-info__slide {
  margin-top: 20px;
  height: 96px; }

.tabs__content.js-active > .car-info__slider {
  margin-top: 0;
  margin-left: 0;
  height: auto;
  opacity: 1;
  visibility: visible;
  position: relative; }

.tooltipster-od .tooltipster-content {
  padding: 15px; }

.tooltipster-od .tooltipster-box {
  box-shadow: none;
  background: none;
  border: none; }

.usp2-video-play-wrap {
  position: relative; }

.usp-2video-play {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center; }
  .usp-2video-play::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: url(../img/play.svg) no-repeat center center rgba(0, 0, 0, 0.5);
    background-size: 44px auto; }

.fa-asterisk::before {
  content: "\f069"; }

.ui-datepicker {
  width: 20.6em;
  padding: 0; }
  .ui-datepicker .ui-datepicker-header {
    padding: 0.5em 0;
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #f6f6f6; }
  .ui-datepicker .ui-datepicker-next,
  .ui-datepicker .ui-datepicker-prev {
    width: 1.5em;
    height: 1.5em;
    top: 9px;
    border-radius: 5px;
    background: #ce171f url(../img/controls/arrow.svg) no-repeat center;
    transition: background-color 0.3s ease; }
    .ui-datepicker .ui-datepicker-next span,
    .ui-datepicker .ui-datepicker-prev span {
      display: none; }
  .ui-datepicker .ui-datepicker-title {
    line-height: 1.8em; }
  .ui-datepicker .ui-datepicker-prev {
    left: 14px;
    transform: rotate(180deg); }
  .ui-datepicker .ui-datepicker-next {
    right: 14px; }
  .ui-datepicker select.ui-datepicker-month,
  .ui-datepicker select.ui-datepicker-year {
    width: 39%;
    height: 19px;
    border: 1px solid #a5a5a5;
    border-radius: 3px;
    appearance: none;
    background-color: #fff;
    background-image: url(../img/select_hov.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 3px) center;
    font-family: 'Vauxhall Neue', sans-serif;
    font-size: 13px;
    background-size: 9px;
    padding: 0 5px; }
    .ui-datepicker select.ui-datepicker-month::-ms-expand,
    .ui-datepicker select.ui-datepicker-year::-ms-expand {
      display: none; }
  .ui-datepicker .select.ui-datepicker-month {
    margin-right: 2px; }
  .ui-datepicker select.ui-datepicker-year {
    margin-left: 2px; }
  .ui-datepicker table {
    width: calc(100% - 1.4em);
    margin: 0 0.7em 0.9em; }
  .ui-datepicker th {
    padding: 1em 0.3em;
    font-size: 13px;
    font-weight: 400;
    color: #ce171f; }
  .ui-datepicker td {
    border-radius: 3px;
    transition: background-color 0.3s ease; }
    .ui-datepicker td span,
    .ui-datepicker td a {
      padding: 0.5em;
      color: #2c2c2c; }
    @media (min-width: 1024px) {
      .ui-datepicker td:hover {
        background-color: #f1f1f1; } }
    .ui-datepicker td:hover {
      background-color: #f1f1f1; }
    .ui-datepicker td.ui-datepicker-today {
      background-color: #f1f1f1; }
      .ui-datepicker td.ui-datepicker-today a {
        color: #2c2c2c; }

.ui-widget.ui-widget-content {
  border-radius: 5px; }
  .ui-widget.ui-widget-content .ui-state-hover {
    border: none;
    background-color: #981316; }
  .ui-widget.ui-widget-content .ui-datepicker-prev-hover {
    top: 9px;
    left: 14px; }
  .ui-widget.ui-widget-content .ui-state-default {
    border: none;
    background: none;
    text-align: center; }
  .ui-widget.ui-widget-content .ui-state-active {
    border: none;
    background: none;
    color: #454545; }

@media screen and (max-height: 800px) {
  .new-cars__title {
    height: 70px;
    line-height: 70px; }
  .new-cars__item-title--top {
    margin-bottom: 20px; } }

@media screen and (max-width: 1000px) {
  .wrapper--100vh {
    height: auto; }
  .page--100vh {
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px 0; }
  .express-store {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .express-store__title {
    font-size: 20px;
    line-height: 23px; }
  .express-store__subtitle {
    font-size: 18px;
    line-height: 21px; }
  .subfooter {
    display: none; } }

@media screen and (max-width: 900px) {
  .car-info {
    padding-top: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .car-info__left-side {
    width: 100%; }
  .car-info__options-row {
    margin-left: 0; }
  .personalise__inner {
    padding: 20px 0; }
  .new-cars__title {
    font-size: 20px; }
  .new-cars__row {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .new-cars__button {
    width: 100%;
    max-width: 250px; }
  .new-cars__item {
    width: 50%;
    padding: 10px 5px; }
  .new-cars__item--border {
    border: none; } }

@media screen and (max-width: 900px) {
  .personalise__row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .personalise__right-side {
    max-width: 450px;
    width: 100%; }
  .personalise__left-side {
    margin-right: 0;
    max-width: 450px;
    width: 100%;
    margin-bottom: 20px; }
  .personalise__left-side:after {
    display: none; }
  .personalise__button {
    float: none;
    margin: 20px auto 0; }
  .input-lane {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  .input-lane--right {
    float: none;
    margin-left: 0; }
  .input-lane__input--right {
    margin-left: 0; }
  .input-lane .input-box {
    margin-top: 5px; }
  .radiogroup--right {
    width: auto;
    float: none;
    padding-right: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; } }

@media screen and (max-width: 800px) {
  .corsa__wrapper {
    position: relative;
    height: auto !important; }
  .corsa__tabs-header {
    position: relative;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 0; }
  .corsa__tabs-body {
    padding-top: 20px; }
  .corsa__tabs-img {
    width: 100%; }
  .corsa__tabs-desc {
    font-size: 14px;
    padding: 0 10px;
    letter-spacing: -0.5px; }
  .corsa__lead {
    font-size: 18px;
    line-height: 21px; }
  .corsa__tabs-title {
    width: 50%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .corsa__tabs-title:before {
    display: none; } }

@media screen and (max-width: 700px) {
  .mobile-toggle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex !important; }
  .btns__inner {
    margin-top: 20px;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly; }
  .main-menu__container {
    padding-top: 30px; }
  .main-menu__close--top {
    background: url("../img/close-btn-2.png") center center no-repeat; }
  .main-menu__title {
    font-size: 22px;
    padding-bottom: 10px;
    max-width: 100%;
    text-align: left; }
  .main-menu__signin {
    display: block; }
  .main-menu__mobile-chat {
    display: block; }
  .footer {
    padding-top: 50px; }
  .footer__row {
    display: none; }
  .footer .footer-cols .col .soc-links {
    display: none !important; } }

@media screen and (max-width: 500px) {
  .car-info__controls--bottom {
    top: 208px; }
  #sync4 .car-info__slide, #sync2 .car-info__slide {
    margin-top: 20px; }
  .car-info__options {
    padding: 30px 20px; }
  .car-info__options-row {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2; }
  .car-info__slide {
    height: 250px; }
  .new-cars__button {
    margin-top: 10px; }
  .new-cars__img {
    height: 100px; } }

/* partials */
.header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4001;
  background-color: #fff;
  border: 1px solid #e6e6e6; }
  @media (max-width: 1200px) {
    .header {
      height: 60px; } }
  .header__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 26px;
    box-sizing: border-box;
    position: relative;
    z-index: 2; }
  .header__group {
    display: flex;
    align-items: center; }
  .header__logo {
    width: 186px;
    height: 45px;
    margin-left: 19px;
    margin-right: 40px; }
    @media (max-width: 1200px) {
      .header__logo {
        width: 152px;
        height: 37px;
        margin-right: 12px;
        margin-left: 10px; } }
    .header__logo img {
      width: 100%;
      height: 100%; }
  .header__logo-last {
    width: 80px;
    height: 65px;
    margin-left: 26px; }
    @media (max-width: 1200px) {
      .header__logo-last {
        width: 57px;
        height: 46px;
        margin-left: 10px; } }
    .header__logo-last img {
      width: 100%;
      height: 100%; }
  .header__burger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    position: relative;
    cursor: pointer;
    transition-duration: 0.4s;
    border: 1px solid #e6e6e6;
    border-bottom: none;
    background-color: #fff;
    box-sizing: border-box;
    transition-duration: 0.4s; }
    @media (max-width: 1200px) {
      .header__burger {
        width: 60px;
        height: 60px; } }
    .header__burger.open {
      background-color: #f2f2f2; }
      .header__burger.open .header__burger-wrapper .icon-left {
        transition-duration: 0.4s;
        background: transparent; }
        .header__burger.open .header__burger-wrapper .icon-left::before {
          transform: rotateZ(45deg) scaleX(1.4) translate(2px, 2px); }
        .header__burger.open .header__burger-wrapper .icon-left::after {
          transform: rotateZ(-45deg) scaleX(1.4) translate(2px, -2px); }
      .header__burger.open .header__burger-wrapper .icon-right {
        transition-duration: 0.4s;
        background: transparent; }
        .header__burger.open .header__burger-wrapper .icon-right::before {
          transform: rotateZ(-45deg) scaleX(1.4) translate(-2px, 2px); }
        .header__burger.open .header__burger-wrapper .icon-right::after {
          transform: rotateZ(45deg) scaleX(1.4) translate(-2px, -2px); }
  .header__burger-wrapper {
    width: 28px;
    height: 28px;
    position: relative; }
    .header__burger-wrapper .icon-left {
      position: absolute;
      top: 11px;
      width: 14px;
      height: 3px;
      transition-duration: 0.4s;
      background-color: #ce181f; }
      .header__burger-wrapper .icon-left::before, .header__burger-wrapper .icon-left::after {
        content: '';
        width: 14px;
        height: 3px;
        position: absolute;
        transition-duration: 0.4s;
        background-color: #ce181f; }
      .header__burger-wrapper .icon-left::before {
        top: -9px; }
      .header__burger-wrapper .icon-left::after {
        top: 9px; }
    .header__burger-wrapper .icon-right {
      position: absolute;
      top: 11px;
      left: 12px;
      width: 14px;
      height: 3px;
      transition-duration: 0.4s;
      background-color: #ce181f; }
      .header__burger-wrapper .icon-right::before, .header__burger-wrapper .icon-right::after {
        content: '';
        width: 14px;
        height: 3px;
        position: absolute;
        transition-duration: 0.4s;
        background-color: #ce181f; }
      .header__burger-wrapper .icon-right::before {
        top: -9px; }
      .header__burger-wrapper .icon-right::after {
        top: 9px; }
  .header__nav {
    display: flex;
    align-items: center;
    height: 100%; }
    @media (max-width: 1815px) {
      .header__nav {
        display: none; } }
    .header__nav > li {
      display: flex;
      align-items: center;
      height: 100%; }
      @media (min-width: 1024px) {
        .header__nav > li:hover > a {
          color: #ce181f; }
          .header__nav > li:hover > a + .header__submenu {
            opacity: 1;
            pointer-events: auto; } }
      .header__nav > li + li {
        margin-left: 32px; }
      .header__nav > li > a {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 15px;
        line-height: 33px;
        color: #2c2c2c;
        transition: color 0.3s ease; }
        .header__nav > li > a svg {
          fill: #b2b9bc;
          transform: rotate(90deg); }
  .header__submenu {
    width: 100%;
    padding: 25px 20px 45px 321px;
    background-color: #f7f7f7;
    position: absolute;
    top: 80px;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease; }
  .header__submenu-list-wrapper {
    display: flex; }
  .header__submenu-group {
    max-width: 482px;
    min-width: 228px; }
    .header__submenu-group + .header__submenu-group {
      margin-left: 30px; }
    .header__submenu-group > a {
      display: block;
      padding-bottom: 9px;
      margin-bottom: 9px;
      font-size: 18px;
      border-bottom: 1px solid #ac1919;
      color: #2c2c2c; }
  .header__submenu-list {
    display: flex;
    flex-wrap: wrap; }
    .header__submenu-list li {
      width: 100%; }
      .header__submenu-list li + li {
        margin-left: 0; }
      .header__submenu-list li a {
        font-size: 14px;
        line-height: 32px;
        text-decoration: underline;
        color: #4d4d4d; }
        @media (min-width: 1024px) {
          .header__submenu-list li a:hover {
            text-decoration: none; } }
    .header__submenu-list--wide li {
      width: 53%; }
      .header__submenu-list--wide li:nth-child(even) {
        width: 47%; }
  .header__info {
    width: 216px;
    background-repeat: no-repeat;
    background-position: 20px center;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    height: 52px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px 10px 54px;
    border-radius: 5px;
    box-sizing: border-box; }
    @media (max-width: 1200px) {
      .header__info {
        display: none; } }
    .header__info + .header__info {
      margin-left: 8px; }
    .header__info p {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 16px;
      color: #2c2c2c; }
    .header__info--mobile {
      display: none; }
      @media (max-width: 1200px) {
        .header__info--mobile {
          display: inline-flex;
          position: relative;
          left: 50%;
          transform: translateX(-50%); }
          .header__info--mobile:first-child {
            margin: 15px auto; }
          .header__info--mobile + .header__info {
            margin: 8px auto 15px; } }
  .header__links {
    display: flex;
    margin-left: 40px; }
    @media (max-width: 1023px) {
      .header__links {
        display: none; } }
  .header__link {
    display: flex;
    align-items: center; }
    .header__link + .header__link {
      margin-left: 23px; }
    .header__link svg {
      width: 20px;
      height: 20px;
      fill: #b2b9bc;
      margin-right: 5px; }
    .header__link a {
      font-size: 14px;
      line-height: 17px;
      color: #2c2c2c;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .header__link a:hover {
          text-decoration: none; } }
    .header__link--mobile {
      display: none; }
      @media (max-width: 1023px) {
        .header__link--mobile {
          display: flex;
          justify-content: space-between;
          margin-left: 23px; }
          .header__link--mobile > div {
            display: flex;
            align-items: center; }
          .header__link--mobile:first-child {
            border-top: 1px solid #e6e6e6; }
          .header__link--mobile > a {
            width: 100%;
            padding: 12.5px 20px 12.5px; }
            .header__link--mobile > a svg {
              width: 24px;
              height: 24px;
              fill: #ac1919;
              margin-right: 0; }
          .header__link--mobile .header-mobile__contact {
            display: flex;
            align-items: center; }
            .header__link--mobile .header-mobile__contact svg {
              fill: #b2b9bc;
              margin-right: 5px; }
          .header__link--mobile + .header__link--mobile {
            margin: 0; }
          .header__link--mobile a {
            text-decoration: none; } }

.header-mobile {
  position: absolute;
  top: 0;
  left: 0;
  width: 220px;
  min-height: 100vh;
  background-color: #fff;
  margin-top: 80px;
  border: 1px solid #e6e6e6;
  z-index: 201;
  transform: translateX(-100%);
  transition: transform 0.4s linear; }
  @media (max-width: 1200px) {
    .header-mobile {
      width: 255px;
      margin-top: 60px; } }
  .header-mobile--open {
    transform: translateX(0); }
  .header-mobile--open-sub {
    transform: translateX(-95%); }
  .header-mobile__layout {
    min-height: 100vh;
    background-color: #fff;
    margin-left: -2px; }
  .header-mobile__menu-link {
    border-bottom: 1px solid #e6e6e6;
    transition: background-color 0.3s ease; }
    @media (min-width: 1024px) {
      .header-mobile__menu-link:hover {
        background-color: #ac1919; }
        .header-mobile__menu-link:hover .header-mobile__submenu {
          display: block; }
        .header-mobile__menu-link:hover span {
          color: #fff; }
        .header-mobile__menu-link:hover svg {
          fill: #fff; } }
    .header-mobile__menu-link > a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12.5px 20px 12.5px 30px; }
      @media (max-width: 1023px) {
        .header-mobile__menu-link > a {
          padding: 12.5px 20px 12.5px; } }
    .header-mobile__menu-link span {
      font-size: 15px;
      line-height: 18px;
      color: #2c2c2c;
      transition: color 0.3s ease; }
    .header-mobile__menu-link svg {
      fill: #ac1919;
      transition: fill 0.3s ease; }
  .header-mobile__submenu {
    display: none;
    position: absolute;
    left: 220px;
    top: 0;
    width: 300px;
    min-height: 100vh;
    background-color: #f6f6f6;
    box-sizing: border-box;
    z-index: -1; }
    @media (max-width: 1200px) {
      .header-mobile__submenu {
        left: 255px; } }
    @media (max-width: 1023px) {
      .header-mobile__submenu {
        left: 0;
        display: block;
        width: 281px;
        transform: translateX(-101%);
        transition: transform 0.8s ease; } }
    .header-mobile__submenu--open {
      transform: translateX(91%);
      transition: transform 0.4s ease; }
    .header-mobile__submenu .accordion {
      padding: 14px 15px;
      margin-top: 0; }
      .header-mobile__submenu .accordion__hint {
        font-weight: 400;
        font-size: 12px;
        margin-left: 8px;
        color: grey; }
      .header-mobile__submenu .accordion__note {
        display: flex;
        font-size: 14px;
        line-height: 17px;
        padding: 10px;
        border: 1px solid #b72126;
        margin-bottom: 20px; }
        .header-mobile__submenu .accordion__note::before {
          content: '';
          display: block;
          max-width: 24px;
          width: 100%;
          height: 24px;
          background: url("../img/error_outline.svg") no-repeat;
          margin-right: 8px; }
  .header-mobile__submenu-list-wrapper {
    padding: 24px 40px 30px 26px; }
    .header-mobile__submenu-list-wrapper > a {
      display: block;
      font-size: 18px;
      color: #2c2c2c;
      border-bottom: 1px solid #ac1919;
      padding-bottom: 11px;
      margin-bottom: 8px; }
      .header-mobile__submenu-list-wrapper > a:not(:first-of-type) {
        margin-top: 25px; }
  .header-mobile__submenu-list li {
    padding: 8px 0; }
    .header-mobile__submenu-list li a {
      font-size: 14px;
      color: #4d4d4d; }
      @media (min-width: 1024px) {
        .header-mobile__submenu-list li a:hover {
          text-decoration: underline; } }
  .header-mobile__back {
    display: none;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding: 14px 15px;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .header-mobile__back {
        display: flex; } }
    .header-mobile__back span {
      font-size: 20px; }
    .header-mobile__back svg {
      width: 20px;
      height: 20px;
      fill: #ac1919;
      margin-right: 13px; }

.footer {
  position: relative;
  z-index: 10;
  background-color: #fff; }
  .footer__row {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: opacity 0.2s linear; }
  .footer__social-links {
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 20px; }
  .footer__link {
    width: 27px;
    height: 36px;
    background-color: #ce181f;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s; }
    @media (min-width: 1024px) {
      .footer__link:hover {
        background-color: #841215; } }
    .footer__link:last-child {
      width: 41px;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px; }
    .footer__link:first-child {
      width: 41px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px; }
  .footer .inner {
    max-width: 1067px; }
  .footer .footer-cols {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between; }
    .footer .footer-cols .col {
      width: calc((100% - 69px) / 4); }
      @media (max-width: 900px) {
        .footer .footer-cols .col {
          width: calc(50% - 13px);
          margin-bottom: 43px; } }
      @media all and (max-width: 480px) {
        .footer .footer-cols .col {
          width: 100%;
          text-align: center; } }
      .footer .footer-cols .col .logo-f a {
        display: inline-block;
        width: 160px;
        max-width: 100%; }
        .footer .footer-cols .col .logo-f a img {
          display: block;
          max-width: 100%; }
      .footer .footer-cols .col .soc-links {
        display: flex;
        align-items: flex-start;
        margin-top: 15px; }
        @media (max-width: 480px) {
          .footer .footer-cols .col .soc-links {
            justify-content: center; } }
        .footer .footer-cols .col .soc-links a {
          display: block;
          width: 26px;
          height: 26px;
          transition: transform 0.2s linear;
          border-radius: 100%;
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; }
          .footer .footer-cols .col .soc-links a + a {
            margin-left: 12px; }
          .footer .footer-cols .col .soc-links a.fb {
            background-image: url("../img/social/f-fb.png"); }
          .footer .footer-cols .col .soc-links a.gg {
            background-image: url("../img/social/f-gg.png"); }
          .footer .footer-cols .col .soc-links a.tw {
            background-image: url("../img/social/f-tw.png"); }
          @media (min-width: 1024px) {
            .footer .footer-cols .col .soc-links a:hover {
              transform: scale(1.2); } }
      .footer .footer-cols .col .text {
        max-width: 250px;
        margin-top: 19px;
        color: #4d4d4d;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px; }
        @media (max-width: 480px) {
          .footer .footer-cols .col .text {
            margin: 22px auto 0; } }
        .footer .footer-cols .col .text p + p {
          margin-top: 0.8em; }
      .footer .footer-cols .col .menu-block + .menu-block {
        margin-top: 43px; }
      .footer .footer-cols .col .menu-block .title {
        padding-bottom: 14px;
        border-bottom: 2px solid #bfbfbf;
        font-family: 'Myriad Pro', 'Vauxhall Neue', sans-serif;
        font-size: 15px;
        font-weight: 400;
        color: #2c2c2c; }
      .footer .footer-cols .col .menu-block ul.links {
        margin-top: 16px;
        list-style: none; }
        .footer .footer-cols .col .menu-block ul.links li {
          display: block; }
          .footer .footer-cols .col .menu-block ul.links li + li {
            margin-top: 13px; }
          .footer .footer-cols .col .menu-block ul.links li a {
            color: #4d4d4d;
            font-weight: 400;
            font-size: 14px; }
            @media (min-width: 1024px) {
              .footer .footer-cols .col .menu-block ul.links li a:hover {
                text-decoration: underline; } }
  .footer .footer-row-links {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 58px; }
    @media (max-width: 900px) {
      .footer .footer-row-links {
        justify-content: center; } }
    .footer .footer-row-links a {
      position: relative;
      display: inline-block;
      margin-bottom: 15px;
      text-decoration: underline;
      color: #2c2c2c;
      font-weight: 400;
      font-size: 14px; }
      @media (min-width: 1024px) {
        .footer .footer-row-links a:hover {
          text-decoration: none; } }
      .footer .footer-row-links a:not(:last-child)::after {
        content: "";
        display: block;
        width: 1px;
        height: 16px;
        background-color: #808080;
        position: absolute;
        top: 0;
        right: -8px; }
      .footer .footer-row-links a + a {
        margin-left: 16px; }
  .footer .footer-text {
    margin-top: 21px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #4d4d4d; }
  .footer .footer-copy-line {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    margin-top: 65px;
    background-color: #ececec; }
    .footer .footer-copy-line .text {
      text-align: center;
      color: #2c2c2c;
      font-weight: 400;
      font-size: 14px; }
  .footer.gray_footer {
    background: #f6f6f6; }

.modal-fancy {
  padding: 42px 89px 66px 89px; }
  @media (max-width: 560px) {
    .modal-fancy {
      padding: 19px 15px 18px 15px; } }
  .modal-fancy .fancybox-close-small {
    color: currentColor;
    padding: 5px;
    right: 4px;
    top: 7px;
    background-color: white; }
    .modal-fancy .fancybox-close-small svg path {
      fill: #ce181f; }

.modal-your-enquiry .required {
  color: #d1282e; }

.modal-your-enquiry .input-row:nth-child(1) .input-block {
  margin-right: 26px; }
  .modal-your-enquiry .input-row:nth-child(1) .input-block select {
    padding: 13px 10px; }
  .modal-your-enquiry .input-row:nth-child(1) .input-block:nth-child(1) {
    max-width: 92px; }
  .modal-your-enquiry .input-row:nth-child(1) .input-block:nth-child(3) {
    margin-right: 0; }

.modal-your-enquiry .form-block .agree-row {
  justify-content: flex-start; }

.modal-reg-login form {
  width: 362px;
  max-width: 100%; }
  .modal-reg-login form > .title {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 33px;
    margin-bottom: 27px; }
  .modal-reg-login form .input-block {
    margin-bottom: 13px; }
    .modal-reg-login form .input-block .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 8px; }
      .modal-reg-login form .input-block .title .flex-block {
        display: flex;
        justify-content: space-between; }
      .modal-reg-login form .input-block .title .resend {
        color: #ce181f;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        text-decoration: underline; }
        @media (min-width: 1024px) {
          .modal-reg-login form .input-block .title .resend:hover {
            text-decoration: none; } }
    .modal-reg-login form .input-block input {
      width: 100%;
      max-width: 362px;
      height: 44px;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-size: 16px;
      padding-left: 16px; }
    .modal-reg-login form .input-block textarea {
      width: 100%;
      max-width: 362px;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-size: 16px;
      padding-left: 16px;
      padding-top: 10px;
      padding-bottom: 10px;
      height: 120px; }
    .modal-reg-login form .input-block:last-of-type {
      margin-bottom: 35px; }
  .modal-reg-login form .btn-reg {
    height: 46px;
    width: 100%;
    background-color: #124390;
    transition-duration: 0.2s;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    text-transform: uppercase; }
    @media (min-width: 1024px) {
      .modal-reg-login form .btn-reg:hover {
        filter: brightness(1.2); } }
  .modal-reg-login form .personal {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #666666;
    text-align: center;
    transition-duration: 0.2s;
    margin-bottom: 29px; }
    .modal-reg-login form .personal a {
      text-decoration: underline;
      color: #666666; }
      @media (min-width: 1024px) {
        .modal-reg-login form .personal a:hover {
          text-decoration: none; } }
  .modal-reg-login form .alternate_sign,
  .modal-reg-login form .signin {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    color: #ce181f;
    text-decoration: underline;
    transition-duration: 0.2s;
    display: flex;
    justify-content: center;
    margin-top: 35px; }
    @media (min-width: 1024px) {
      .modal-reg-login form .alternate_sign:hover,
      .modal-reg-login form .signin:hover {
        text-decoration: none; } }

#modalSave.modal-reg-login form > .title {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto; }

#modalSave.modal-reg2 .text-top {
  width: auto;
  text-align: center; }

.modal-reg2 {
  padding: 42px 65px 60px 65px; }
  .modal-reg2 > .title {
    margin-bottom: 15px; }
  .modal-reg2 .text-top {
    margin: 0 auto;
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    width: max-content;
    margin-bottom: 35px; }
    .modal-reg2 .text-top .text-ul {
      margin-top: 5px;
      color: #2c2c2c;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      list-style: none; }
      .modal-reg2 .text-top .text-ul li {
        padding-left: 20px;
        position: relative; }
        .modal-reg2 .text-top .text-ul li::before {
          content: "";
          display: block;
          position: absolute;
          width: 5px;
          height: 5px;
          background-color: #ce181f;
          left: 4px;
          top: 10px; }
  .modal-reg2 form {
    width: 560px; }
  .modal-reg2 .grid-block {
    display: flex;
    flex-wrap: wrap;
    margin-left: -23px;
    margin-top: -30px; }
    .modal-reg2 .grid-block > * {
      flex: 1 0 250px;
      margin-left: 23px;
      margin-top: 30px;
      margin-bottom: 0; }
      @media (min-width: 546px) {
        .modal-reg2 .grid-block > * {
          max-width: calc(50% - 23px); } }
      @media (min-width: 500px) {
        .modal-reg2 .grid-block > * {
          min-width: calc(50% - 23px); } }
    .modal-reg2 .grid-block .input-block .title {
      margin-top: -10px; }
    .modal-reg2 .grid-block .personal-div {
      display: flex;
      align-items: center; }
      .modal-reg2 .grid-block .personal-div .personal {
        margin-bottom: 0; }
  .modal-reg2 .signin {
    margin-top: 45px; }

@media (max-width: 560px) {
  #modal-reg form > .title {
    margin-bottom: 5px; }
  #modal-reg form .input-block {
    margin-bottom: 8px; }
    #modal-reg form .input-block .title {
      margin-bottom: 6px; }
    #modal-reg form .input-block:last-of-type {
      margin-bottom: 12px; }
  #modal-reg form .btn-reg {
    margin-bottom: 10px; }
  #modal-reg form .personal {
    margin-bottom: 15px; } }

.modal-reservation {
  width: 689px;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0; }
  .modal-reservation .title,
  .modal-reservation .desc,
  .modal-reservation .form-block {
    padding-left: 64px;
    padding-right: 64px; }
  .modal-reservation .title {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 33px;
    color: #2c2c2c;
    text-align: center;
    margin-bottom: 12px; }
    @media (max-width: 600px) {
      .modal-reservation .title {
        padding-left: 46px;
        padding-right: 46px; } }
    @media (max-width: 450px) {
      .modal-reservation .title {
        font-size: 24px; } }
  .modal-reservation .desc {
    max-width: 100%;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 22px; }
    @media (max-width: 600px) {
      .modal-reservation .desc {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 450px) {
      .modal-reservation .desc {
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 1px; } }
  .modal-reservation .desc,
  .modal-reservation .desc a {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #2c2c2c;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 450px) {
      .modal-reservation .desc,
      .modal-reservation .desc a {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        color: #2c2c2c;
        text-align: center; } }
  .modal-reservation .modal-reservation-text {
    font-size: 12px;
    line-height: 15px; }
    .modal-reservation .modal-reservation-text a {
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .modal-reservation .modal-reservation-text a:hover {
          text-decoration: none; } }
  .modal-reservation .form-block {
    padding-top: 35px;
    position: relative; }
    @media (max-width: 600px) {
      .modal-reservation .form-block {
        padding-left: 20px;
        padding-right: 20px; } }
    @media (max-width: 450px) {
      .modal-reservation .form-block {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 15px; } }
    .modal-reservation .form-block::before {
      content: "";
      height: 1px;
      width: calc(100% + 64px);
      position: absolute;
      left: -64px;
      top: 0;
      background-color: #e6e6e6; }
    .modal-reservation .form-block .input-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px; }
      @media (max-width: 600px) {
        .modal-reservation .form-block .input-row {
          display: block;
          margin-bottom: 0; } }
      @media (max-width: 450px) {
        .modal-reservation .form-block .input-row {
          display: block;
          margin-bottom: 0; } }
      .modal-reservation .form-block .input-row .input-block {
        width: calc(50% - 11.5px); }
        @media (max-width: 600px) {
          .modal-reservation .form-block .input-row .input-block {
            width: 100%;
            margin-bottom: 10px; } }
        @media (max-width: 450px) {
          .modal-reservation .form-block .input-row .input-block {
            width: 100%;
            margin-bottom: 10px; } }
      .modal-reservation .form-block .input-row .inputbox::placeholder {
        color: #b3b3b3; }
    .modal-reservation .form-block .grey {
      margin-top: 41px;
      font-family: "Vauxhall Neue", sans-serif;
      color: #666666;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px; }
      @media (max-width: 450px) {
        .modal-reservation .form-block .grey {
          margin-top: 16px; } }
    .modal-reservation .form-block > .input-block .agree-text,
    .modal-reservation .form-block > .input-block .agree-text a {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #2c2c2c; }
    .modal-reservation .form-block > .input-block .agree-text a a {
      font-size: 12px;
      line-height: 14px;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .modal-reservation .form-block > .input-block .agree-text a a:hover {
          text-decoration: none; } }
    @media (max-width: 450px) {
      .modal-reservation .form-block .input-block .agree-text,
      .modal-reservation .form-block .input-block .agree-text a {
        font-size: 13px; } }
    .modal-reservation .form-block .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 439px;
      max-width: 100%;
      height: 46px;
      margin: 35px auto 40px;
      background-color: #124390;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
      text-transform: uppercase; }
      @media (max-width: 450px) {
        .modal-reservation .form-block .btn {
          white-space: normal;
          height: fit-content;
          margin: 12px auto 18px;
          padding: 5px 10px; } }
  .modal-reservation .cards-support {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: #edf1f3; }
    .modal-reservation .cards-support .item {
      margin: 0 14.5px; }
      @media (max-width: 450px) {
        .modal-reservation .cards-support .item {
          margin: 0 5px; } }
      .modal-reservation .cards-support .item:nth-last-child(1) {
        margin-left: 10px; }
        @media (max-width: 450px) {
          .modal-reservation .cards-support .item:nth-last-child(1) {
            margin-left: 2px; } }
      .modal-reservation .cards-support .item:nth-last-child(2) {
        margin-right: 10px; }
        @media (max-width: 450px) {
          .modal-reservation .cards-support .item:nth-last-child(2) {
            margin-right: 2px; } }

#modal-membership3 {
  padding: 0;
  width: 800px; }
  #modal-membership3 .fancybox-close-small {
    right: 6px;
    top: 5px;
    padding: 8px; }
  #modal-membership3 .fancybox-button svg path {
    fill: #fff; }
  #modal-membership3 .head {
    background-color: #ce181f;
    min-height: 100px;
    display: flex;
    justify-content: center;
    padding: 14px 50px 0; }
    @media (max-width: 600px) {
      #modal-membership3 .head {
        padding-left: 10px;
        padding-top: 10px;
        padding-right: 10px; } }
    #modal-membership3 .head .icon {
      min-width: 69px;
      min-height: 70px;
      background: url(../img/mm3.png) no-repeat center center;
      margin-right: 14px; }
      @media (max-width: 600px) {
        #modal-membership3 .head .icon {
          background-size: 100% auto; } }
    #modal-membership3 .head .text {
      width: 528px;
      max-width: 100%;
      padding-top: 11px;
      padding-bottom: 10px; }
      @media (max-width: 600px) {
        #modal-membership3 .head .text {
          padding-top: 0; } }
      #modal-membership3 .head .text .title {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 26px;
        font-weight: bold;
        line-height: 28.09px;
        color: #ffffff;
        text-transform: uppercase;
        letter-spacing: 0.7px; }
        @media (max-width: 600px) {
          #modal-membership3 .head .text .title {
            font-size: 18px;
            line-height: 1.25;
            padding-right: 40px; } }
      #modal-membership3 .head .text .bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 -5px; }
      #modal-membership3 .head .text .percent {
        display: inline-block;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 28.09px;
        color: #ffffff;
        margin: 0 5px; }
        @media (max-width: 600px) {
          #modal-membership3 .head .text .percent {
            font-size: 13px;
            line-height: 20px; } }
  #modal-membership3 .main {
    padding: 21px 94px 58px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #2c2c2c; }
    @media (max-width: 600px) {
      #modal-membership3 .main {
        padding: 13px 20px 20px;
        font-size: 13px;
        line-height: 20px; } }
    #modal-membership3 .main .title {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      color: #2c2c2c;
      letter-spacing: 0.7px;
      margin-bottom: 8px; }
      @media (max-width: 600px) {
        #modal-membership3 .main .title {
          font-size: 17px;
          line-height: 1.25; } }
    #modal-membership3 .main > ul {
      padding-bottom: 33px; }
      @media (max-width: 600px) {
        #modal-membership3 .main > ul {
          padding-bottom: 18px; } }
      #modal-membership3 .main > ul > li {
        position: relative;
        padding-left: 16px; }
        #modal-membership3 .main > ul > li::before {
          content: "";
          width: 5px;
          height: 5px;
          background-color: #ce181f;
          display: block;
          position: absolute;
          top: 11px;
          left: 2px; }
        #modal-membership3 .main > ul > li > ul {
          padding-left: 11px;
          padding-bottom: 6px; }
    #modal-membership3 .main p {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      color: #2c2c2c; }
      @media (max-width: 600px) {
        #modal-membership3 .main p {
          font-size: 13px;
          line-height: 20px; } }

.modal-map-contacts {
  padding: 0; }
  .modal-map-contacts > .title {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 30px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    color: #2c2c2c;
    padding: 37px 15px 30px;
    border-bottom: 1px solid #cccccc; }
    @media (max-width: 650px) {
      .modal-map-contacts > .title {
        font-size: 18px;
        line-height: 22px;
        padding: 12px 36px 12px;
        border-bottom: 0; } }
  .modal-map-contacts .content {
    display: flex; }
    @media (max-width: 650px) {
      .modal-map-contacts .content {
        flex-direction: column; } }
    .modal-map-contacts .content .map {
      width: 1600px;
      height: 665px;
      border-right: 1px solid #cccccc; }
      @media (max-width: 650px) {
        .modal-map-contacts .content .map {
          width: 100%;
          height: 250px;
          border-right: 0; } }
      .modal-map-contacts .content .map iframe {
        width: 100%;
        height: 100%; }
    .modal-map-contacts .content .contacts {
      cursor: auto; }
      .modal-map-contacts .content .contacts .title,
      .modal-map-contacts .content .contacts .phone,
      .modal-map-contacts .content .contacts .adress,
      .modal-map-contacts .content .contacts .accordion h3,
      .modal-map-contacts .content .contacts .accordion > div {
        width: 320px;
        padding-left: 47px;
        padding-right: 65px; }
        @media (max-width: 650px) {
          .modal-map-contacts .content .contacts .title,
          .modal-map-contacts .content .contacts .phone,
          .modal-map-contacts .content .contacts .adress,
          .modal-map-contacts .content .contacts .accordion h3,
          .modal-map-contacts .content .contacts .accordion > div {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px; } }
      .modal-map-contacts .content .contacts .title {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 24px;
        font-weight: bold;
        line-height: 33px;
        color: #2c2c2c;
        margin-top: 30px;
        margin-bottom: 27px; }
        @media (max-width: 650px) {
          .modal-map-contacts .content .contacts .title {
            margin-top: 12px;
            margin-bottom: 12px; } }
      .modal-map-contacts .content .contacts .phone {
        display: flex;
        align-items: center;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 33px;
        color: #2c2c2c; }
        .modal-map-contacts .content .contacts .phone img {
          width: 16px;
          height: 16px;
          margin-right: 12px; }
      .modal-map-contacts .content .contacts .adress {
        display: flex;
        padding-bottom: 32px;
        border-bottom: 1px solid #e6e6e6;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #4d4d4d; }
        @media (max-width: 650px) {
          .modal-map-contacts .content .contacts .adress {
            padding-bottom: 10px; } }
        .modal-map-contacts .content .contacts .adress img {
          width: 14px;
          height: 20px;
          margin-right: 13px;
          font-family: "Vauxhall Neue", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 26px;
          color: #4d4d4d; }
      .modal-map-contacts .content .contacts .accordion h3 {
        height: 51px;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none;
        border-bottom: 1px solid #e6e6e6;
        background-color: transparent;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 51px;
        color: #2c2c2c;
        cursor: pointer;
        position: relative; }
        .modal-map-contacts .content .contacts .accordion h3 .oh {
          font-family: "Vauxhall Neue", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          text-decoration: underline; }
        @media (min-width: 1024px) {
          .modal-map-contacts .content .contacts .accordion h3:hover {
            opacity: 0.8; } }
        .modal-map-contacts .content .contacts .accordion h3::after {
          content: "";
          width: 12px;
          height: 8px;
          background: url(../img/arrow-top-red.png) no-repeat center center;
          transform: rotate(-180deg);
          position: absolute;
          top: 23px;
          right: 66px;
          transition-duration: 0.2s; }
      .modal-map-contacts .content .contacts .accordion .ui-accordion-header-active {
        border-bottom: 0; }
        .modal-map-contacts .content .contacts .accordion .ui-accordion-header-active::after {
          transform: rotate(0deg); }
      .modal-map-contacts .content .contacts .accordion div {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        color: #4d4d4d; }
        .modal-map-contacts .content .contacts .accordion div .row {
          display: flex;
          justify-content: space-between; }
      .modal-map-contacts .content .contacts .accordion .ui-accordion-content {
        border: none;
        padding-bottom: 7px; }
      .modal-map-contacts .content .contacts .accordion .ui-accordion-content-active {
        border-bottom: 1px solid #e6e6e6;
        padding-bottom: 7px; }

#modal-chat {
  width: 689px;
  max-width: 94vw;
  padding: 0; }
  #modal-chat .chat-block {
    width: 100%; }
  #modal-chat .chat > .title {
    padding: 27px 40px;
    border-bottom: 1px solid #d7d7d7;
    font-size: 30px;
    line-height: 33px;
    font-weight: bold;
    color: #2c2c2c;
    text-align: center; }
    @media (max-width: 500px) {
      #modal-chat .chat > .title {
        padding: 11px 40px;
        font-size: 17px;
        line-height: 33px; } }
  #modal-chat .chat .messages-wrap {
    height: 413px;
    max-height: calc(100vh - 320px);
    padding: 11px 18px 11px 42px;
    background-color: #f6f7fd; }
    @media (max-width: 700px) {
      #modal-chat .chat .messages-wrap {
        padding: 11px 10px 11px 15px; } }
    @media (max-width: 500px) {
      #modal-chat .chat .messages-wrap {
        max-height: calc(100vh - 285px); } }
    #modal-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
      right: -12px; }
    #modal-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
      width: 2px;
      background-color: #d9d9d9;
      border-radius: 0;
      cursor: pointer; }
    #modal-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
      width: 4px;
      background-color: #d4141c;
      border-radius: 0; }
  #modal-chat .chat .messages {
    display: flex;
    flex-direction: column;
    padding: 23px 0; }
    #modal-chat .chat .messages .messages_message {
      padding-right: 30px;
      padding-bottom: 20px;
      font-size: 14px;
      width: 100%; }
      #modal-chat .chat .messages .messages_message .title {
        color: #a2a2a2;
        padding-bottom: 10px; }
        #modal-chat .chat .messages .messages_message .title span {
          font-weight: 600; }
      #modal-chat .chat .messages .messages_message .text {
        background-color: #f7f7f7;
        border: 1px solid #e6e6e6;
        padding: 16px 20px 18px 17px; }
    #modal-chat .chat .messages .messages_message-manager {
      align-self: flex-start;
      width: auto;
      padding-right: 30px; }
      @media (max-width: 500px) {
        #modal-chat .chat .messages .messages_message-manager {
          padding-right: 15px; } }
      #modal-chat .chat .messages .messages_message-manager .title span {
        color: #ce171f; }
    #modal-chat .chat .messages .messages_message-your {
      align-self: flex-end;
      padding-right: 0;
      padding-left: 30px;
      margin-left: auto;
      box-sizing: border-box; }
      @media (max-width: 500px) {
        #modal-chat .chat .messages .messages_message-your {
          padding-left: 15px; } }
      #modal-chat .chat .messages .messages_message-your .title {
        text-align: left; }
        #modal-chat .chat .messages .messages_message-your .title span {
          color: #124390; }
      #modal-chat .chat .messages .messages_message-your .text {
        background-color: #e4f2ff; }
  #modal-chat .write {
    margin-top: 17px;
    border-top: 1px solid #d7d7d7;
    padding: 5px 15px 20px; }
    @media (max-width: 500px) {
      #modal-chat .write {
        padding: 0 15px 15px; } }
    #modal-chat .write form {
      padding-top: 4px;
      font-size: 14px;
      line-height: 28px; }
      #modal-chat .write form textarea {
        width: 100%;
        height: 88px;
        border: 1px solid #a5a5a5;
        background-color: #ffffff;
        resize: none;
        padding: 16px 17px;
        overflow-y: scroll;
        /* Optional: show position indicator in red */ }
        #modal-chat .write form textarea::placeholder {
          color: #a6a6a6; }
        #modal-chat .write form textarea::-webkit-scrollbar {
          width: 0;
          /* Remove scrollbar space */
          background: transparent;
          /* Optional: just make scrollbar invisible */
          display: none; }
        #modal-chat .write form textarea::-webkit-scrollbar-thumb {
          background: #ff0000; }
      #modal-chat .write form .send-btn {
        width: 161px;
        height: 35px;
        margin-top: 15px;
        background-color: #124390;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        font-weight: bold;
        line-height: 28px;
        color: #ffffff;
        transition-duration: 0.2s; }
        @media (min-width: 1024px) {
          #modal-chat .write form .send-btn:hover {
            filter: brightness(1.2); } }

.chat-window iframe {
  max-height: calc(100% - 20px);
  box-shadow: 0 5px 29px rgba(6, 11, 9, 0.3); }

#modal-chat-2 {
  display: none;
  width: 280px;
  max-width: calc(100vw - 60px);
  padding: 0;
  border-radius: 5px 5px 0 0;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 10; }
  @media (max-width: 500px) {
    #modal-chat-2 {
      max-width: 100%;
      right: 0;
      bottom: 0; } }
  #modal-chat-2 .chat-block {
    width: 100%; }
  #modal-chat-2 .mCSB_inside > .mCSB_container {
    margin-right: 17px; }
  #modal-chat-2 .chat .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    border-radius: 5px 5px 0 0;
    background-color: #ce171f; }
    #modal-chat-2 .chat .title-row > .title {
      color: #ffffff;
      font-size: 14px;
      font-weight: bold; }
    #modal-chat-2 .chat .title-row .close-chat {
      width: 18px;
      height: 18px;
      background: url("../img/white-close.png") no-repeat center center;
      cursor: pointer; }
  #modal-chat-2 .chat .messages-wrap {
    max-height: calc(100vh - 123px);
    height: 348px;
    padding: 20px 15px;
    box-sizing: border-box;
    overflow: hidden;
    transition: height 0.1s linear, padding 0.1s linear; }
    #modal-chat-2 .chat .messages-wrap.hided {
      height: 0;
      padding-top: 0;
      padding-bottom: 0; }
    #modal-chat-2 .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
      right: -12px; }
    #modal-chat-2 .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
      width: 2px;
      background-color: #d9d9d9;
      border-radius: 0;
      cursor: pointer; }
    #modal-chat-2 .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
      width: 4px;
      background-color: #d4141c;
      border-radius: 0; }
  #modal-chat-2 .chat .messages {
    display: flex;
    flex-direction: column;
    padding: 0; }
    #modal-chat-2 .chat .messages .messages_message {
      width: 100%;
      padding-right: 20px;
      padding-bottom: 15px;
      font-size: 13px; }
      #modal-chat-2 .chat .messages .messages_message .title {
        font-size: 13px;
        color: #a2a2a2;
        padding-bottom: 10px; }
        #modal-chat-2 .chat .messages .messages_message .title span {
          font-size: 14px;
          font-weight: 600; }
      #modal-chat-2 .chat .messages .messages_message .text {
        padding: 9px 10px 9px 12px;
        border-radius: 0 10px 10px;
        background-color: #e4f2ff; }
    #modal-chat-2 .chat .messages .messages_message-manager {
      align-self: flex-start;
      box-sizing: border-box;
      padding-right: 15px; }
      #modal-chat-2 .chat .messages .messages_message-manager .title span {
        color: #ce171f; }
    #modal-chat-2 .chat .messages .messages_message-your {
      align-self: flex-end;
      padding-right: 0;
      padding-left: 15px;
      margin-left: auto;
      box-sizing: border-box; }
      #modal-chat-2 .chat .messages .messages_message-your .title {
        display: flex;
        flex-direction: row-reverse;
        text-align: left; }
        #modal-chat-2 .chat .messages .messages_message-your .title span {
          margin-left: 15px;
          color: #124390; }
      #modal-chat-2 .chat .messages .messages_message-your .text {
        border-radius: 10px 0 10px 10px;
        background-color: #3b609b;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #ffffff; }
  #modal-chat-2 .write {
    border-top: 1px solid #d7d7d7;
    padding: 13px 10px 8px 10px;
    transition: height 0.1s linear, padding 0.1s linear; }
    #modal-chat-2 .write.hided {
      height: 0;
      padding-top: 0;
      padding-bottom: 0; }
    #modal-chat-2 .write form {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      line-height: 28px; }
      #modal-chat-2 .write form textarea {
        width: calc(100% - 60px);
        height: 35px;
        border: none;
        background-color: #fff;
        resize: none;
        padding: 8px 0 0;
        overflow-y: auto;
        /* Optional: show position indicator in red */ }
        #modal-chat-2 .write form textarea::placeholder {
          color: #a6a6a6; }
        #modal-chat-2 .write form textarea::-webkit-scrollbar {
          width: 0;
          /* Remove scrollbar space */
          background: transparent;
          /* Optional: just make scrollbar invisible */
          display: none; }
        #modal-chat-2 .write form textarea::-webkit-scrollbar-thumb {
          background: #ff0000; }
      #modal-chat-2 .write form .send-btn {
        width: 30px;
        height: 28px;
        border-radius: 5px;
        background: #ce171f url(../img/send-icon.png) center center no-repeat;
        background-size: 18px 15px;
        transition: 0.3s; }
        @media (min-width: 1024px) {
          #modal-chat-2 .write form .send-btn:hover {
            background-color: #841215; } }

.ifchat-departament-choose {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 36px);
  padding: 20px 25px;
  background: #ffffff;
  position: absolute;
  top: 36px;
  opacity: 1;
  transition-duration: .5s;
  box-sizing: border-box; }
  .ifchat-departament-choose--choosed {
    opacity: 0;
    pointer-events: none !important; }
  .ifchat-departament-choose__title {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    color: #2c2c2c; }
  .ifchat-departament-choose__buttons {
    width: 100%;
    margin-top: 12px; }
    .ifchat-departament-choose__buttons .btn {
      display: block;
      -webkit-appearance: none;
      width: 100%;
      margin-bottom: 10px;
      padding-left: 56px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      height: 44px;
      text-align: left;
      border-radius: 3px; }
      .ifchat-departament-choose__buttons .btn--ifchat-select-type, .ifchat-departament-choose__buttons .btn--ifchat-select-type, .ifchat-departament-choose__buttons .btn--ifchat-select-type {
        position: relative; }
        .ifchat-departament-choose__buttons .btn--ifchat-select-type::before, .ifchat-departament-choose__buttons .btn--ifchat-select-type::before, .ifchat-departament-choose__buttons .btn--ifchat-select-type::before {
          width: 31px;
          height: 21px;
          content: '';
          display: block;
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          position: absolute;
          left: 13px;
          top: 11px; }
        @media (min-width: 1024px) {
          .ifchat-departament-choose__buttons .btn--ifchat-select-type:hover, .ifchat-departament-choose__buttons .btn--ifchat-select-type:hover, .ifchat-departament-choose__buttons .btn--ifchat-select-type:hover {
            background: #454545; } }
      .ifchat-departament-choose__buttons .btn--ifchat-sales::before {
        background-image: url(../img/iframe-chat-sales.png); }
      .ifchat-departament-choose__buttons .btn--ifchat-service::before {
        background-image: url(../img/iframe-chat-service.png); }
      .ifchat-departament-choose__buttons .btn--ifchat-parts::before {
        background-image: url(../img/enginePart.svg); }

.chat-window {
  transition: transform 0.2s; }
  .chat-window.hided {
    bottom: 37px !important;
    transform: translateY(100%) !important; }

.messages-add-manager-flex {
  display: flex;
  align-items: center;
  border-radius: 0 10px 10px;
  padding: 21px 10px 24px 14px;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7; }
  .messages-add-manager-flex img {
    width: 69px;
    height: 69px;
    border-radius: 50%;
    margin-right: 8px;
    flex-shrink: 0; }
  .messages-add-manager-flex > span {
    display: block; }

.ncc-modal-new {
  display: none;
  width: 730px;
  max-width: 100%;
  padding: 0; }
  .ncc-modal-new .ncc-modal-new-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding: 30px 45px 24px 43px; }
    @media (max-width: 600px) {
      .ncc-modal-new .ncc-modal-new-head {
        text-align: center;
        flex-direction: column;
        padding: 15px 45px 15px 20px; } }
    .ncc-modal-new .ncc-modal-new-head__text {
      font-size: 26px;
      line-height: 28.09px;
      font-weight: bold;
      color: #2c2c2c;
      text-transform: uppercase; }
      @media (max-width: 600px) {
        .ncc-modal-new .ncc-modal-new-head__text {
          font-size: 19px;
          line-height: 1.3; } }
  .ncc-modal-new .ncc-modal-new-main {
    max-width: 100%;
    max-height: 70vh;
    padding: 30px 10px 30px 43px; }
    @media (max-width: 600px) {
      .ncc-modal-new .ncc-modal-new-main {
        max-height: 85vh;
        padding: 15px 10px 20px 20px; } }
    .ncc-modal-new .ncc-modal-new-main .mCSB_dragger .mCSB_dragger_bar {
      border-radius: 0;
      opacity: 1 !important;
      background-color: #d4141c; }
    .ncc-modal-new .ncc-modal-new-main .mCSB_draggerRail {
      background-color: #d9d9d9; }
    .ncc-modal-new .ncc-modal-new-main p + ul,
    .ncc-modal-new .ncc-modal-new-main p + p,
    .ncc-modal-new .ncc-modal-new-main ul + p,
    .ncc-modal-new .ncc-modal-new-main p + h4,
    .ncc-modal-new .ncc-modal-new-main h4 + p,
    .ncc-modal-new .ncc-modal-new-main ul + h4,
    .ncc-modal-new .ncc-modal-new-main li + li {
      margin-top: 15px; }
      @media (max-width: 600px) {
        .ncc-modal-new .ncc-modal-new-main p + ul,
        .ncc-modal-new .ncc-modal-new-main p + p,
        .ncc-modal-new .ncc-modal-new-main ul + p,
        .ncc-modal-new .ncc-modal-new-main p + h4,
        .ncc-modal-new .ncc-modal-new-main h4 + p,
        .ncc-modal-new .ncc-modal-new-main ul + h4,
        .ncc-modal-new .ncc-modal-new-main li + li {
          margin-top: 10px; } }
    .ncc-modal-new .ncc-modal-new-main h4 {
      font-size: 20px;
      line-height: 33px;
      font-weight: bold;
      color: #2c2c2c; }
      @media (max-width: 600px) {
        .ncc-modal-new .ncc-modal-new-main h4 {
          font-size: 17px;
          line-height: 1.3; } }
    .ncc-modal-new .ncc-modal-new-main ul li,
    .ncc-modal-new .ncc-modal-new-main p {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #4d4d4d; }
      @media (max-width: 600px) {
        .ncc-modal-new .ncc-modal-new-main ul li,
        .ncc-modal-new .ncc-modal-new-main p {
          line-height: 22px; } }
    .ncc-modal-new .ncc-modal-new-main ul,
    .ncc-modal-new .ncc-modal-new-main p {
      margin-left: 18px; }
      @media (max-width: 420px) {
        .ncc-modal-new .ncc-modal-new-main ul,
        .ncc-modal-new .ncc-modal-new-main p {
          margin-left: 5px; } }

.modal-newCar {
  width: 730px;
  max-width: 94vw;
  padding: 0; }
  .modal-newCar .fancybox-close-small {
    right: 6px;
    top: 3px; }
  .modal-newCar .chat-block {
    width: 100%; }
  .modal-newCar .chat > .title {
    padding: 35px 40px;
    border-bottom: 1px solid #d7d7d7;
    font-size: 26px;
    line-height: 28px;
    font-weight: normal;
    color: #2c2c2c;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px; }
    @media (max-width: 830px) {
      .modal-newCar .chat > .title {
        padding: 40px 15px;
        font-size: 18px; } }
  .modal-newCar .chat .messages-wrap {
    height: 554px;
    max-height: calc(100vh - 320px);
    padding: 11px 12px 11px 107px;
    background-color: #f6f7fd; }
    @media (max-width: 830px) {
      .modal-newCar .chat .messages-wrap {
        padding: 15px; } }
    .modal-newCar .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
      right: -12px; }
    .modal-newCar .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
      width: 2px;
      background-color: #d9d9d9;
      border-radius: 0;
      cursor: pointer; }
    .modal-newCar .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
      width: 4px;
      background-color: #d4141c;
      border-radius: 0; }
  .modal-newCar .chat .messages {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    padding-right: 100px; }
    @media (max-width: 830px) {
      .modal-newCar .chat .messages {
        padding-right: 12px; } }
    .modal-newCar .chat .messages span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      color: #4c4c4c; }
    .modal-newCar .chat .messages .gearing {
      font-size: 20px;
      line-height: 33px;
      text-align: center;
      margin-bottom: 7px;
      color: #2c2c2c;
      margin-left: 25px; }
    .modal-newCar .chat .messages .send-btn {
      width: 440px;
      height: 46px;
      margin-left: 40px;
      margin-top: 15px;
      background-color: #124390;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 46px;
      color: #ffffff;
      transition-duration: .2s;
      text-transform: uppercase; }
      @media (max-width: 830px) {
        .modal-newCar .chat .messages .send-btn {
          width: 100%;
          margin: 0 auto; } }
      @media (min-width: 1024px) {
        .modal-newCar .chat .messages .send-btn:hover {
          filter: brightness(1.2); } }
  .modal-newCar .mCSB_inside > .mCSB_container {
    margin-right: 7px; }

#modal-newCar-2 {
  width: 1250px;
  max-width: 94vw;
  max-height: 94vh; }
  #modal-newCar-2 .chat {
    padding: 0 20px; }
  #modal-newCar-2 span {
    display: block; }
  #modal-newCar-2 .messages-title {
    width: 100%;
    background: #fff;
    position: absolute;
    top: 0;
    font-size: 20px;
    line-height: 33px;
    color: #d4141c; }
  #modal-newCar-2 .messages-untitle {
    font-size: 16px;
    line-height: 33px;
    color: #2c2c2c; }
  #modal-newCar-2 .messages-text {
    font-size: 13px;
    line-height: 17px;
    color: #4c4c4c;
    margin-bottom: 20px; }
  #modal-newCar-2 .messages-wrap {
    padding: 32px 0;
    height: 720px;
    background-color: #fff; }
    #modal-newCar-2 .messages-wrap .flex-container {
      display: flex;
      flex-wrap: nowrap;
      width: auto; }
  #modal-newCar-2 .mCSB_scrollTools_horizontal .mCSB_draggerRail {
    width: 100%;
    height: 2px;
    right: 0; }
  #modal-newCar-2 .mCSB_scrollTools_horizontal .mCSB_dragger_bar {
    width: 100%; }
  #modal-newCar-2 .messages-item {
    width: 250px;
    flex-shrink: 0;
    padding-right: 10px;
    border-right: 1px solid #E6E6E6;
    margin-right: 20px;
    margin-bottom: 25px;
    padding-top: 33px;
    position: relative; }
    #modal-newCar-2 .messages-item p {
      font-size: 13px;
      color: #4c4c4c;
      line-height: 17px;
      margin-bottom: 20px; }

.modal-online-payment {
  padding-top: 0 !important; }

.mod-op-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 50px 22px;
  font-size: 30px;
  font-weight: bold;
  line-height: 33px;
  color: #2c2c2c;
  text-align: center; }
  @media (max-width: 500px) {
    .mod-op-title {
      font-size: 22px;
      padding: 13px 50px 13px; } }

.mod-op-input-row-one-item {
  margin-bottom: 15px; }

.mod-op-agree-row {
  display: flex;
  justify-content: center; }

.mod-op-radios-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 -8px; }
  .mod-op-radios-row .radio-label {
    margin-bottom: 0 !important; }
  .mod-op-radios-row .radio-btn {
    display: flex;
    align-items: center;
    margin: 0 8px 15px !important; }
  .mod-op-radios-row input[type="radio"] {
    min-width: 15px; }

.modal-deposit-payment-desc,
.modal-deposit-payment-sub-title {
  padding-left: 64px;
  padding-right: 64px;
  text-align: center;
  box-sizing: border-box; }
  @media (max-width: 600px) {
    .modal-deposit-payment-desc,
    .modal-deposit-payment-sub-title {
      padding-left: 20px;
      padding-right: 20px; } }

.modal-deposit-payment-sub-title {
  max-width: 100%;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px; }
  @media (max-width: 600px) {
    .modal-deposit-payment-sub-title {
      font-size: 19px;
      line-height: 1.2; } }

.modal-deposit-payment-desc {
  padding-bottom: 37px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; }

#admin-chat {
  display: none;
  width: 320px;
  max-width: calc(100vw - 60px);
  height: 100%;
  padding: 0;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 10; }
  @media (max-width: 500px) {
    #admin-chat {
      max-width: 100%;
      right: 0;
      bottom: 0; } }
  #admin-chat .chat-block {
    width: 100%; }
  #admin-chat .mCSB_inside > .mCSB_container {
    margin-right: 17px; }
  #admin-chat .chat .title-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 15px;
    background-color: #ce171f; }
    #admin-chat .chat .title-row > .title {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff; }
    #admin-chat .chat .title-row .close-chat {
      width: 18px;
      height: 18px;
      background: url("../img/white-close.png") no-repeat center center;
      cursor: pointer; }
  #admin-chat .chat .messages-wrap {
    padding: 10px 10px 10px 24px;
    box-sizing: border-box;
    overflow: hidden;
    transition: height 0.1s linear, padding 0.1s linear; }
    #admin-chat .chat .messages-wrap.hided {
      height: 0;
      padding-top: 0;
      padding-bottom: 0; }
    #admin-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
      right: -12px; }
    #admin-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
      width: 2px;
      background-color: #d9d9d9;
      border-radius: 0;
      cursor: pointer; }
    #admin-chat .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
      width: 4px;
      background-color: #d4141c;
      border-radius: 0; }
  #admin-chat .chat .messages {
    display: flex;
    flex-direction: column;
    padding: 0; }
    #admin-chat .chat .messages .messages_message {
      width: 100%;
      padding-right: 20px;
      padding-bottom: 19px;
      font-size: 13px; }
      #admin-chat .chat .messages .messages_message .title {
        padding-bottom: 1px;
        font-size: 12px;
        color: #a2a2a2; }
        #admin-chat .chat .messages .messages_message .title span {
          padding-right: 6px;
          font-size: 13px;
          color: #ce171f;
          font-weight: bold; }
      #admin-chat .chat .messages .messages_message .text {
        font-size: 14px;
        line-height: 19px;
        padding: 9px 10px 9px 12px;
        border: 1px solid #e6e6e6;
        background-color: #e4f2ff; }
    #admin-chat .chat .messages .messages_message-user .title span {
      color: #124390; }
  #admin-chat .write {
    height: 212px;
    border-top: 1px solid #d7d7d7;
    padding: 5px 8px 16px 16px;
    transition: height 0.1s linear, padding 0.1s linear; }
    #admin-chat .write.hided {
      height: 0;
      padding-top: 0;
      padding-bottom: 0; }
    #admin-chat .write form {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 14px;
      line-height: 28px; }
      #admin-chat .write form .input-block {
        width: 100%;
        margin-bottom: 10px; }
      #admin-chat .write form .input-block select {
        width: 100%;
        padding: 6px 25px 6px 5px;
        resize: none;
        border: 1px solid #a5a5a5;
        font-size: 14px;
        -moz-appearance: none;
        appearance: none;
        background: #fff url(../img/select_hov.svg) no-repeat;
        background-position: calc(100% - 8px) center;
        box-sizing: border-box; }
      #admin-chat .write form textarea {
        width: 100%;
        height: 79px;
        border: 1px solid #a5a5a5;
        background-color: #fff;
        resize: none;
        padding: 8px;
        overflow-y: scroll;
        /* Optional: show position indicator in red */ }
        #admin-chat .write form textarea::placeholder {
          font-size: 13px;
          color: #a6a6a6; }
        #admin-chat .write form textarea::-webkit-scrollbar {
          width: 0;
          /* Remove scrollbar space */
          background: transparent;
          /* Optional: just make scrollbar invisible */
          display: none; }
        #admin-chat .write form textarea::-webkit-scrollbar-thumb {
          background: #ff0000; }
      #admin-chat .write form .send-btn {
        width: 98px;
        height: 28px;
        background: #ce171f url(../img/send-icon.png) center center no-repeat;
        background-size: 18px 15px;
        transition: background-color 0.3s ease;
        align-self: flex-start;
        background-position: calc(100% - 14px) center;
        font-size: 14px;
        color: #fff;
        text-align: left;
        padding: 0 20px;
        margin-top: 10px; }
        @media (min-width: 1024px) {
          #admin-chat .write form .send-btn:hover {
            background-color: #841215; } }

.modal {
  position: fixed;
  top: 108px;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .modal .modal-dialog {
    width: auto;
    margin: 0.5rem;
    position: relative;
    pointer-events: none; }
    @media (min-width: 576px) {
      .modal .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto; } }
    .modal .modal-dialog .modal-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: 0.3rem;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      outline: 0;
      pointer-events: auto;
      position: relative; }
    .modal .modal-dialog .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 1rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem; }
      .modal .modal-dialog .modal-header .close {
        float: right;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: #000;
        text-shadow: 0 1px 0 #fff;
        opacity: 0.5;
        padding: 1rem 1rem;
        margin: -1rem -1rem -1rem auto;
        background-color: transparent;
        border: 0;
        appearance: none; }
        @media (min-width: 1024px) {
          .modal .modal-dialog .modal-header .close:hover {
            opacity: 0.75; } }
    .modal .modal-dialog .modal-body {
      flex: 1 1 auto;
      padding: 1rem;
      position: relative; }
      .modal .modal-dialog .modal-body p {
        margin-top: 0;
        margin-bottom: 1rem; }
    .modal .modal-dialog .modal-title {
      font-size: 17px;
      line-height: 1.5;
      font-weight: 700;
      margin: 0 0 4px; }

#thankyou {
  max-width: 560px;
  width: 100%; }
  #thankyou .form-title {
    font-size: 24px;
    line-height: 1.3;
    font-weight: 700;
    color: #2c2c2c;
    margin-top: 75px;
    margin-bottom: 45px;
    text-align: center; }
  #thankyou .form-info-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #4d4d4d;
    text-align: center;
    margin-top: 48px; }
  #thankyou .btn {
    width: 266px;
    margin: 27px auto 0; }

@media (max-width: 1200px) {
  .modal-fancy-program {
    padding-top: 35px;
    padding-right: 5px;
    padding-left: 5px;
    overflow-x: hidden; } }

.c-price-menu {
  width: 841px;
  max-width: 99%;
  padding: 0; }
  .c-price-menu__title {
    padding: 27px 0 24px;
    font-size: 26px;
    line-height: 33px;
    font-weight: bold;
    color: #2c2c2c;
    text-align: center; }
  .c-price-menu__tab-controls {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #ce171f; }
  .c-price-menu .c-price-menu-tab-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 228px;
    height: 40px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #2c2c2c;
    cursor: pointer; }
    @media (min-width: 1024px) {
      .c-price-menu .c-price-menu-tab-control:hover {
        background-color: #f3f3f3; } }
    .c-price-menu .c-price-menu-tab-control.active {
      pointer-events: none;
      color: #ffffff;
      border: 1px solid transparent;
      background-color: #ce171f; }
    .c-price-menu .c-price-menu-tab-control:not(.active) {
      border: 1px solid #d9d9d9;
      border-bottom: 1px solid transparent; }
      .c-price-menu .c-price-menu-tab-control:not(.active):nth-child(1) {
        border-right: 1px solid transparent; }
      .c-price-menu .c-price-menu-tab-control:not(.active):nth-child(2) {
        border-left: 1px solid transparent; }
  .c-price-menu .c-price-menu-tab {
    display: none;
    padding: 25px 36px 57px 50px; }
    .c-price-menu .c-price-menu-tab.active {
      display: flex;
      justify-content: space-between;
      width: 100%; }
      @media (max-width: 750px) {
        .c-price-menu .c-price-menu-tab.active {
          display: block;
          padding: 15px; } }
    .c-price-menu .c-price-menu-tab__left, .c-price-menu .c-price-menu-tab__right {
      width: calc(50% - 35px / 2); }
      @media (max-width: 750px) {
        .c-price-menu .c-price-menu-tab__left, .c-price-menu .c-price-menu-tab__right {
          width: 100%; } }
    @media (max-width: 750px) {
      .c-price-menu .c-price-menu-tab__left {
        margin-right: 0;
        margin-bottom: 15px; } }
  .c-price-menu .c-price-menu-tab-title {
    font-size: 18px;
    font-weight: bold;
    color: #2c2c2c; }
  .c-price-menu .c-price-menu-tab-desc {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: #666666; }
  .c-price-menu .c-pm-price-list {
    margin-top: 13px; }
  .c-price-menu .c-pm-price-list-item {
    display: flex;
    align-items: center;
    min-height: 35px;
    padding: 5px 6px 5px 13px;
    background-color: #eef3fb;
    cursor: pointer;
    box-sizing: border-box; }
    @media (min-width: 1024px) {
      .c-price-menu .c-pm-price-list-item:hover {
        background-color: #d8e0ed; } }
    @media (max-width: 380px) {
      .c-price-menu .c-pm-price-list-item {
        flex-direction: column;
        align-items: center; } }
    .c-price-menu .c-pm-price-list-item * {
      box-sizing: border-box; }
    .c-price-menu .c-pm-price-list-item__name {
      width: calc(55% - 25px);
      padding-right: 15px; }
    .c-price-menu .c-pm-price-list-item__price {
      width: 20%;
      padding-right: 15px; }
    .c-price-menu .c-pm-price-list-item__duration {
      width: 25%;
      padding-right: 15px; }
    .c-price-menu .c-pm-price-list-item__plus {
      width: 25px;
      height: 25px;
      background-color: #ce171f;
      background-image: url(../img/white-plus.png);
      background-repeat: no-repeat;
      background-position: center center; }
    @media (max-width: 380px) {
      .c-price-menu .c-pm-price-list-item > * {
        width: 100%;
        text-align: center; } }
    @media (max-width: 380px) {
      .c-price-menu .c-pm-price-list-item > * + * {
        margin-top: 5px; } }
    .c-price-menu .c-pm-price-list-item + .c-pm-price-list-item {
      margin-top: 4px; }

.modal-caslo-remove .caslo-remove-btn {
  display: block;
  width: 14px;
  height: 18px;
  margin-right: 12px;
  background: url(../img/bin-red.png) no-repeat center center;
  background-size: contain;
  cursor: pointer; }

.modal-caslo-remove .modal-caslo-remove {
  width: 501px;
  max-width: 100%; }
  .modal-caslo-remove .modal-caslo-remove .input-block {
    width: 100%;
    max-width: 100%; }
    .modal-caslo-remove .modal-caslo-remove .input-block .select {
      width: 100%; }

.modal-caslo-remove .modal-caslo-remove-title {
  font-size: 26px;
  line-height: 33px;
  font-weight: bold;
  color: #2c2c2c;
  text-align: center;
  margin-bottom: 36px; }

.modal-caslo-remove button.modal-caslo-remove-btn {
  width: 179px;
  margin: 20px auto 0;
  background-color: #333333;
  position: relative;
  font-size: 15px;
  font-weight: 400; }
  .modal-caslo-remove button.modal-caslo-remove-btn::before {
    content: '';
    display: block;
    width: 14px;
    height: 18px;
    background: url(../img/bin.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%); }

.modal-caslo-remove .modal-caslo-add-line .input-block + .input-block {
  margin-top: 20px; }

.modal-caslo-remove button.modal-caslo-add-btn {
  width: 179px;
  margin: 29px auto 0; }

.cars_booking__modal .modal-title {
  margin-bottom: 30px;
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  color: #2c2c2c;
  text-align: center; }
  .cars_booking__modal .modal-title span {
    color: #ce181f; }

.cars_booking__modal .modal-subtitle {
  margin-bottom: 25px;
  margin-top: -25px;
  font-size: 16px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center; }
  .cars_booking__modal .modal-subtitle span {
    color: #ce181f; }

.cars_booking__modal .form-block {
  width: 382px;
  max-width: 100%; }
  .cars_booking__modal .form-block .input-block {
    margin-bottom: 20px; }
    .cars_booking__modal .form-block .input-block label:not(.checkbox-container) {
      margin-bottom: 6px; }
    .cars_booking__modal .form-block .input-block .inputbox {
      padding: 7px; }
      .cars_booking__modal .form-block .input-block .inputbox.datepicker-input {
        background-size: 25px auto; }
      .cars_booking__modal .form-block .input-block .inputbox:disabled {
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        color: #999999; }
    .cars_booking__modal .form-block .input-block select {
      padding: 8px 20px 9px 10px;
      background-image: url(../img/select_hov.svg); }
    .cars_booking__modal .form-block .input-block .description {
      font-size: 16px;
      font-weight: 400;
      color: #ce171f; }
    .cars_booking__modal .form-block .input-block .input-flex {
      display: flex; }
      .cars_booking__modal .form-block .input-block .input-flex .check-btn {
        width: 111px;
        height: 40px;
        margin-left: 10px;
        margin-top: 4px;
        flex-shrink: 0;
        font-size: 16px;
        line-height: 35px; }
  .cars_booking__modal .form-block .input-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 450px) {
      .cars_booking__modal .form-block .input-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .cars_booking__modal .form-block .input-row .input-block {
      width: 48%; }
      @media (max-width: 450px) {
        .cars_booking__modal .form-block .input-row .input-block {
          width: 100%; } }
  .cars_booking__modal .form-block .btns-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .cars_booking__modal .form-block .btns-row button {
      min-width: 179px;
      width: 179px;
      height: 40px;
      margin-top: 10px;
      font-size: 15px;
      line-height: 40px;
      font-weight: 400;
      box-sizing: border-box; }
      @media (max-width: 450px) {
        .cars_booking__modal .form-block .btns-row button {
          width: 100%; } }
      .cars_booking__modal .form-block .btns-row button img {
        margin-right: 8px; }

.cars_booking__modal#js-add-courtesy-car .btns-row {
  justify-content: space-between; }

.cars_booking__modal#js-create-booking .btns-row {
  justify-content: space-between; }

.modal-vehicle {
  max-width: 1154px;
  width: 100%;
  min-height: 875px;
  padding: 34px 48px 98px; }
  @media (max-width: 1023px) {
    .modal-vehicle {
      padding: 30px 15px 98px; } }
  @media (max-width: 767px) {
    .modal-vehicle {
      padding: 30px 15px 150px; } }
  .modal-vehicle__title {
    font-size: 26px;
    line-height: 33px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px; }
  .modal-vehicle__top {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 19px;
    margin-bottom: 25px; }
    @media (max-width: 1200px) {
      .modal-vehicle__top {
        align-items: center; } }
    @media (max-width: 767px) {
      .modal-vehicle__top {
        margin-bottom: 20px; } }
    .modal-vehicle__top .input-block {
      width: 335px;
      margin-right: 26px;
      margin-bottom: 15px; }
      @media (max-width: 1200px) {
        .modal-vehicle__top .input-block {
          width: calc(33.3333% - 20px);
          margin: 0 10px; } }
      @media (max-width: 767px) {
        .modal-vehicle__top .input-block {
          width: 100%;
          margin: 0 0 15px; } }
      .modal-vehicle__top .input-block label {
        width: 100%;
        margin-bottom: 0; }
      .modal-vehicle__top .input-block--small {
        width: 155px; }
        @media (max-width: 1200px) {
          .modal-vehicle__top .input-block--small {
            width: calc(33.3333% - 20px); } }
        @media (max-width: 767px) {
          .modal-vehicle__top .input-block--small {
            width: 100%; } }
    .modal-vehicle__top--info {
      padding-bottom: 18px;
      margin-bottom: 27px;
      justify-content: flex-start; }
  .modal-vehicle__item {
    margin-bottom: 15px; }
    .modal-vehicle__item + .modal-vehicle__item {
      margin-right: 19px; }
      @media (max-width: 1200px) {
        .modal-vehicle__item + .modal-vehicle__item {
          margin-right: 25px; } }
    .modal-vehicle__item:first-child, .modal-vehicle__item:nth-child(2) {
      margin-right: 73px; }
      @media (max-width: 1200px) {
        .modal-vehicle__item:first-child, .modal-vehicle__item:nth-child(2) {
          margin-right: 25px; } }
    .modal-vehicle__item p {
      font-size: 14px; }
      .modal-vehicle__item p:first-child {
        color: #92a3ac;
        margin-bottom: 9px; }
        @media (max-width: 1023px) {
          .modal-vehicle__item p:first-child {
            margin-bottom: 5px; } }
  .modal-vehicle__search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 155px !important;
    margin-bottom: 15px; }
    @media (max-width: 1200px) {
      .modal-vehicle__search {
        margin-top: 15px; } }
    .modal-vehicle__search:hover {
      color: #fff; }
    .modal-vehicle__search svg {
      fill: #fff;
      margin-right: 5px; }
  .modal-vehicle__subtitle {
    font-size: 20px;
    margin-bottom: 19px; }
    @media (max-width: 767px) {
      .modal-vehicle__subtitle {
        font-size: 18px;
        text-align: center; } }
  .modal-vehicle__table-body {
    height: 500px; }
  .modal-vehicle__table-wrapper {
    position: relative;
    width: 100%;
    padding-right: 31px; }
    .modal-vehicle__table-wrapper .input-block {
      display: block;
      margin-bottom: 0; }
      @media (max-width: 1200px) {
        .modal-vehicle__table-wrapper .input-block {
          width: 100%; } }
      .modal-vehicle__table-wrapper .input-block label {
        width: 100%; }
        .modal-vehicle__table-wrapper .input-block label.checkbox {
          display: flex;
          font-size: 16px;
          font-weight: 400;
          cursor: pointer; }
          .modal-vehicle__table-wrapper .input-block label.checkbox span {
            font-size: 20px;
            padding-left: 12px; }
          .modal-vehicle__table-wrapper .input-block label.checkbox input {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            max-width: 20px;
            width: 100%;
            height: 20px;
            margin: 0;
            border: 1px solid #a5a5a5;
            border-radius: 3px;
            background-color: #fff;
            cursor: pointer;
            box-sizing: border-box; }
            .modal-vehicle__table-wrapper .input-block label.checkbox input:checked {
              background-image: url(../img/checked.svg);
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 100%; }
      .modal-vehicle__table-wrapper .input-block input[type="radio"] {
        background-color: #fff;
        margin-right: 0; }
    .modal-vehicle__table-wrapper table {
      width: 100%; }
      .modal-vehicle__table-wrapper table tr {
        border-bottom: 1px solid #ebebeb; }
        .modal-vehicle__table-wrapper table tr:nth-child(odd) {
          background-color: #f7f7f7; }
      .modal-vehicle__table-wrapper table td,
      .modal-vehicle__table-wrapper table th {
        width: 100%;
        font-size: 14px;
        font-weight: 400;
        vertical-align: middle; }
        @media (min-width: 1201px) {
          .modal-vehicle__table-wrapper table td:first-child,
          .modal-vehicle__table-wrapper table th:first-child {
            width: 6%; }
          .modal-vehicle__table-wrapper table td:nth-child(2),
          .modal-vehicle__table-wrapper table th:nth-child(2) {
            width: 20.14%; }
          .modal-vehicle__table-wrapper table td:nth-child(3),
          .modal-vehicle__table-wrapper table th:nth-child(3) {
            width: 33%; }
          .modal-vehicle__table-wrapper table td:nth-child(4),
          .modal-vehicle__table-wrapper table th:nth-child(4) {
            width: 5%; }
          .modal-vehicle__table-wrapper table td:nth-child(5),
          .modal-vehicle__table-wrapper table th:nth-child(5) {
            width: 5%; }
          .modal-vehicle__table-wrapper table td:nth-child(6),
          .modal-vehicle__table-wrapper table th:nth-child(6) {
            width: 6%; }
          .modal-vehicle__table-wrapper table td:nth-child(7),
          .modal-vehicle__table-wrapper table th:nth-child(7) {
            width: 5%; }
          .modal-vehicle__table-wrapper table td:nth-child(8),
          .modal-vehicle__table-wrapper table th:nth-child(8) {
            width: 5%; }
          .modal-vehicle__table-wrapper table td:nth-child(9),
          .modal-vehicle__table-wrapper table th:nth-child(9) {
            width: 5.5%; }
          .modal-vehicle__table-wrapper table td:nth-child(10),
          .modal-vehicle__table-wrapper table th:nth-child(10) {
            width: 10.63%; } }
      .modal-vehicle__table-wrapper table td {
        padding: 15.5px 10px; }
        @media (max-width: 1200px) {
          .modal-vehicle__table-wrapper table td {
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            background-color: transparent;
            border-bottom: none;
            font-size: 14px; } }
        @media (max-width: 1023px) {
          .modal-vehicle__table-wrapper table td {
            padding: 10px; } }
        .modal-vehicle__table-wrapper table td::before {
          display: none; }
          @media (max-width: 1200px) {
            .modal-vehicle__table-wrapper table td::before {
              content: attr(aria-label);
              display: inline-block;
              max-width: 100px;
              width: 100%;
              margin-right: 20px;
              color: #999999; } }
      .modal-vehicle__table-wrapper table th {
        padding: 8px; }
        @media (max-width: 1200px) {
          .modal-vehicle__table-wrapper table th {
            display: none; } }
    .modal-vehicle__table-wrapper > table tr:nth-child(odd) {
      background-color: #fff; }
    .modal-vehicle__table-wrapper--width table td,
    .modal-vehicle__table-wrapper--width table th {
      width: 100%; }
      @media (min-width: 1201px) {
        .modal-vehicle__table-wrapper--width table td:first-child,
        .modal-vehicle__table-wrapper--width table th:first-child {
          width: 4%; }
        .modal-vehicle__table-wrapper--width table td:nth-child(2),
        .modal-vehicle__table-wrapper--width table th:nth-child(2) {
          width: 9.75%; }
        .modal-vehicle__table-wrapper--width table td:nth-child(3),
        .modal-vehicle__table-wrapper--width table th:nth-child(3) {
          width: 21.75%; }
        .modal-vehicle__table-wrapper--width table td:nth-child(4),
        .modal-vehicle__table-wrapper--width table th:nth-child(4) {
          width: 13.65%; }
        .modal-vehicle__table-wrapper--width table td:nth-child(5),
        .modal-vehicle__table-wrapper--width table th:nth-child(5) {
          width: 41.17%; }
        .modal-vehicle__table-wrapper--width table td:nth-child(6),
        .modal-vehicle__table-wrapper--width table th:nth-child(6) {
          width: 9.56%; } }
  .modal-vehicle__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 34px;
    left: 50%;
    transform: translateX(-50%); }
    @media (max-width: 767px) {
      .modal-vehicle__nav {
        flex-direction: column; } }
    .modal-vehicle__nav a,
    .modal-vehicle__nav input {
      display: block;
      width: 155px;
      height: 40px;
      border-radius: 5px;
      background-color: #ce171f;
      color: #fff;
      font-size: 15px;
      line-height: 28px;
      transition: background-color 0.3s ease, color 0.3s ease; }
      .modal-vehicle__nav a:hover,
      .modal-vehicle__nav input:hover {
        color: #fff;
        background-color: #981316; }
    .modal-vehicle__nav .modal-vehicle__back {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #4d4d4d;
      color: #fff;
      margin-right: 10px; }
      @media (max-width: 767px) {
        .modal-vehicle__nav .modal-vehicle__back {
          margin-right: 0;
          margin-bottom: 10px; } }
      .modal-vehicle__nav .modal-vehicle__back:hover {
        background-color: #333333; }
    .modal-vehicle__nav .modal-vehicle__next {
      display: flex;
      align-items: center;
      justify-content: center; }
      .modal-vehicle__nav .modal-vehicle__next--disabled {
        background-color: #d9d9d9;
        color: #999999;
        pointer-events: none; }
  .modal-vehicle .mCSB_draggerRail {
    width: 2px;
    background-color: #d9d9d9;
    border-radius: 0;
    cursor: pointer; }
  .modal-vehicle .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 4px;
    background-color: #d4141c;
    border-radius: 0; }
  .modal-vehicle .modal-close {
    position: absolute;
    top: 20px;
    right: 15px;
    cursor: pointer;
    transition: opacity 0.3s ease; }
    @media (min-width: 1024px) {
      .modal-vehicle .modal-close:hover {
        opacity: 0.75; } }

.payment {
  padding: 0;
  padding-top: 35px;
  max-width: 690px;
  width: 100%; }
  .payment__title {
    font-size: 30px;
    line-height: 33px;
    font-weight: bold;
    text-align: center;
    position: relative; }
    .payment__title::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -22px;
      left: 0;
      background-color: #e6e6e6; }
  .payment__main {
    margin-top: 58px;
    padding: 0 65px; }
    @media (max-width: 1023px) {
      .payment__main {
        padding: 0 15px; } }
  .payment__contacts {
    font-size: 16px;
    line-height: 28px;
    position: relative; }
    .payment__contacts::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -22px;
      left: 0;
      background-color: #e6e6e6; }
  .payment__collect span {
    color: #ce171f; }
  .payment__address {
    display: flex;
    font-size: 15px;
    line-height: 22px;
    margin: 12px 0; }
    @media (max-width: 1023px) {
      .payment__address {
        flex-direction: column; } }
  .payment__address-text {
    display: flex;
    align-items: center;
    margin-right: 20px; }
    .payment__address-text::before {
      content: '';
      display: block;
      width: 14px;
      height: 20px;
      background: url(../img/pin.svg) no-repeat center center;
      margin-right: 5px; }
    @media (max-width: 1023px) {
      .payment__address-text {
        margin-right: 0; } }
  .payment__address-phone {
    display: flex;
    align-items: center; }
    .payment__address-phone::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background: url(../img/phone.svg) no-repeat center center;
      margin-right: 5px; }
  .payment__mode {
    font-size: 14px;
    line-height: 26px;
    color: #666666; }
    .payment__mode span:first-child {
      margin-right: 25px; }
    @media (max-width: 1023px) {
      .payment__mode span {
        display: block; } }
  .payment form {
    margin-top: 50px; }
  .payment__inputs {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; }
  .payment .input-block {
    width: calc(50% - 30px);
    margin: 0 15px 20px; }
    @media (max-width: 1023px) {
      .payment .input-block {
        width: 100%; } }
  .payment__input-text {
    width: 100%;
    margin-bottom: 0 !important; }
  .payment .simple-input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 8px 15px;
    resize: none;
    border: 1px solid #a5a5a5;
    border-radius: 0;
    outline: 0;
    font-size: 16px;
    line-height: 1em;
    margin-top: 7px; }
  .payment__policy {
    font-size: 13px;
    line-height: 18px;
    color: #666666;
    margin: 18px 0 25px; }
  .payment__checkbox {
    display: flex;
    align-items: center;
    justify-content: center; }
    .payment__checkbox input {
      display: none; }
      .payment__checkbox input:checked + b {
        background: #fff center/100% auto url(../img/admin_checked.png) no-repeat; }
    .payment__checkbox b {
      display: block;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: 1px solid #a5a5a5;
      border-radius: 3px;
      margin-right: 10px;
      background: #fff; }
    .payment__checkbox span a {
      color: #2c2c2c;
      text-decoration: underline; }
  .payment .btn-payment {
    display: block;
    width: 440px;
    margin: 28px auto 40px;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer; }
    @media (max-width: 1023px) {
      .payment .btn-payment {
        width: 200px;
        font-size: 14px;
        line-height: 26px; } }
  .payment__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #edf1f3;
    padding: 20px 0; }
    .payment__footer img:not(:last-child) {
      margin-right: 30px; }

.modal-payreq-details__title,
.modal-payreq-notes__title {
  font-size: 17px;
  border-bottom: 1px solid #e9ecef;
  margin: 0 -15px 12px;
  padding: 0 15px 12px; }

.modal-payreq-details .input-block label,
.modal-payreq-notes .input-block label {
  font-size: 16px; }

.modal-payreq-details .input-block input,
.modal-payreq-notes .input-block input {
  border-radius: 3px; }

.modal-payreq-details .btn,
.modal-payreq-notes .btn {
  height: 40px;
  line-height: 40px;
  font-weight: 400;
  margin-top: 15px; }
  .modal-payreq-details .btn.modal-payreq-notes__add,
  .modal-payreq-notes .btn.modal-payreq-notes__add {
    max-width: 100px; }
  .modal-payreq-details .btn.modal-payreq-notes__close,
  .modal-payreq-notes .btn.modal-payreq-notes__close {
    max-width: 120px;
    margin-left: auto;
    background-color: #6c757d;
    color: #fff; }
    @media (min-width: 1024px) {
      .modal-payreq-details .btn.modal-payreq-notes__close:hover,
      .modal-payreq-notes .btn.modal-payreq-notes__close:hover {
        background-color: #5a6268; } }

.modal-payreq-details__info,
.modal-payreq-notes__info {
  margin: 20px 0; }
  .modal-payreq-details__info p,
  .modal-payreq-notes__info p {
    font-size: 16px;
    line-height: 20px; }

.modal-payreq-details__bottom,
.modal-payreq-notes__bottom {
  border-top: 1px solid #e9ecef;
  margin: 0 -15px;
  padding: 0 15px; }

.modal-payreq-details.modal-um,
.modal-payreq-notes.modal-um {
  max-width: 500px;
  width: 100%;
  padding: 40px 15px 30px; }
  .modal-payreq-details.modal-um input:disabled,
  .modal-payreq-notes.modal-um input:disabled {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    color: #999999; }
  .modal-payreq-details.modal-um .lbl-chb,
  .modal-payreq-notes.modal-um .lbl-chb {
    display: block;
    padding-bottom: 10px; }
  .modal-payreq-details.modal-um .datepicker-input,
  .modal-payreq-notes.modal-um .datepicker-input {
    background-size: auto 76% !important; }
  .modal-payreq-details.modal-um .btns-row .btn,
  .modal-payreq-notes.modal-um .btns-row .btn {
    margin-top: 0; }
  .modal-payreq-details.modal-um .modal-title,
  .modal-payreq-notes.modal-um .modal-title {
    font-size: 26px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    margin-bottom: 30px; }
  .modal-payreq-details.modal-um .form-block .input-block label:not(.checkbox-container),
  .modal-payreq-notes.modal-um .form-block .input-block label:not(.checkbox-container) {
    margin-bottom: 4px; }
  .modal-payreq-details.modal-um .input-block,
  .modal-payreq-notes.modal-um .input-block {
    margin-bottom: 20px; }
    .modal-payreq-details.modal-um .input-block input,
    .modal-payreq-details.modal-um .input-block select,
    .modal-payreq-notes.modal-um .input-block input,
    .modal-payreq-notes.modal-um .input-block select {
      height: 35px; }
    .modal-payreq-details.modal-um .input-block textarea,
    .modal-payreq-notes.modal-um .input-block textarea {
      height: 70px; }
    .modal-payreq-details.modal-um .input-block select,
    .modal-payreq-notes.modal-um .input-block select {
      line-height: 35px;
      padding-top: 0;
      padding-bottom: 0; }

.modal-payreq-new-req.modal-um {
  padding: 47px 60px 53px; }
  @media (max-width: 450px) {
    .modal-payreq-new-req.modal-um {
      padding: 40px 20px 25px; } }

.modal-payreq-new-req .form-block .input-block input[type="text"],
.modal-payreq-new-req .form-block .input-block select,
.modal-payreq-new-req .form-block .input-block textarea {
  border-radius: 3px; }

.modal-payreq-new-req .btn {
  line-height: 40px; }

.modal-payreq-new-req .payreq-file-attach input {
  display: none; }

.modal-payreq-new-req .payreq-file-attach div {
  display: flex;
  align-items: center; }

.modal-payreq-new-req .payreq-file-attach-skrepka {
  width: 11px;
  height: 22px;
  background: url(../img/attach-icon.png) no-repeat center center;
  background-size: contain; }

.modal-payreq-new-req .payreq-file-attach-text {
  font-size: 18px;
  line-height: 28px;
  font-weight: 400;
  color: #ce171f;
  text-decoration: underline;
  margin-left: 9px;
  cursor: pointer; }
  @media (min-width: 1024px) {
    .modal-payreq-new-req .payreq-file-attach-text:hover {
      text-decoration: none; } }

.modal-payreq-new-req .btns-row--jcc {
  display: flex;
  justify-content: center; }

.modal-payreq-new-req .btns-row--jcsb {
  display: flex;
  justify-content: space-between; }

.modal-payreq-new-req .add-btn {
  max-width: calc(50% - 5px);
  width: 179px;
  height: 40px;
  background-color: #124390;
  font-size: 15px;
  line-height: 40px; }

.modal-payreq-new-req .close-btn {
  max-width: calc(50% - 5px);
  width: 179px;
  height: 40px;
  font-size: 15px; }

.cars_booking__modal .modal-title {
  font-size: 26px;
  line-height: 1;
  font-weight: bold;
  text-align: center;
  color: #2c2c2c;
  margin-bottom: 30px; }
  .cars_booking__modal .modal-title span {
    color: #ce181f; }

.cars_booking__modal .modal-subtitle {
  margin-bottom: 25px;
  margin-top: -25px;
  font-size: 16px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: center; }
  .cars_booking__modal .modal-subtitle span {
    color: #ce181f; }

.cars_booking__modal .form-block {
  width: 382px;
  max-width: 100%; }
  .cars_booking__modal .form-block .input-block {
    margin-bottom: 20px; }
    .cars_booking__modal .form-block .input-block label:not(.checkbox-container) {
      margin-bottom: 6px; }
    .cars_booking__modal .form-block .input-block .inputbox {
      padding: 7px; }
      .cars_booking__modal .form-block .input-block .inputbox.datepicker-input {
        background-size: 25px auto; }
      .cars_booking__modal .form-block .input-block .inputbox:disabled {
        border: 1px solid #d9d9d9;
        background-color: #ffffff;
        color: #999999; }
    .cars_booking__modal .form-block .input-block select {
      padding: 8px 20px 9px 10px;
      background-image: url(../img/select_hov.svg); }
    .cars_booking__modal .form-block .input-block .description {
      color: #ce171f;
      font-size: 16px;
      font-weight: 400; }
    .cars_booking__modal .form-block .input-block .input-flex {
      display: flex; }
      .cars_booking__modal .form-block .input-block .input-flex .check-btn {
        flex-shrink: 0;
        width: 111px;
        height: 35px;
        margin-top: 0;
        margin-left: 10px;
        font-size: 16px;
        line-height: 35px;
        font-weight: bold; }
  .cars_booking__modal .form-block .input-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 450px) {
      .cars_booking__modal .form-block .input-row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; } }
    .cars_booking__modal .form-block .input-row .input-block {
      width: 48%; }
      @media (max-width: 450px) {
        .cars_booking__modal .form-block .input-row .input-block {
          width: 100%; } }
  .cars_booking__modal .form-block .btns-row {
    display: flex;
    justify-content: center; }
    .cars_booking__modal .form-block .btns-row button {
      min-width: 179px;
      width: 179px;
      height: 40px;
      margin-top: 10px;
      font-size: 15px;
      line-height: 40px;
      font-weight: 400;
      box-sizing: border-box; }
      @media (max-width: 450px) {
        .cars_booking__modal .form-block .btns-row button {
          width: 100%; } }
      .cars_booking__modal .form-block .btns-row button img {
        margin-right: 8px; }

.cars_booking__modal#js-add-courtesy-car .btns-row {
  justify-content: space-between; }

.cars_booking__modal#js-create-booking .btns-row {
  justify-content: space-between; }

.modal-um {
  padding: 47px 60px 53px;
  width: 501px;
  max-width: 100%; }
  @media (max-width: 450px) {
    .modal-um {
      padding: 40px 20px 25px; } }
  .modal-um input:disabled {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    color: #999999; }
  .modal-um .lbl-chb {
    padding-bottom: 10px;
    display: block; }
  .modal-um .datepicker-input {
    background-size: auto 76% !important; }
  .modal-um .btns-row .btn {
    margin-top: 0; }
  .modal-um .modal-title {
    font-size: 26px;
    font-weight: bold;
    line-height: 33px;
    text-align: center;
    margin-bottom: 30px; }
    @media (max-width: 450px) {
      .modal-um .modal-title {
        font-size: 22px;
        margin-bottom: 10px; } }
  .modal-um .form-block .input-block label:not(.checkbox-container) {
    margin-bottom: 4px; }
  .modal-um .input-block {
    margin-bottom: 20px; }
    .modal-um .input-block input,
    .modal-um .input-block select {
      height: 35px; }
    .modal-um .input-block textarea {
      height: 70px; }
    .modal-um .input-block select {
      line-height: 35px;
      padding-top: 0;
      padding-bottom: 0; }

.modal-addModule .title {
  margin-bottom: 30px;
  padding-right: 40px;
  padding-left: 40px;
  text-align: center;
  font-size: 30px;
  line-height: 33px; }
  @media (max-width: 810px) {
    .modal-addModule .title {
      font-size: 17px;
      line-height: 33px; } }
  @media (max-width: 500px) {
    .modal-addModule .title {
      font-size: 17px;
      line-height: 33px; } }

.modal-addModule label {
  position: relative;
  display: block;
  margin-bottom: 4px;
  padding: 12px 30px 12px 43px;
  background: #eef3fb;
  font-size: 18px;
  line-height: 21px; }
  .modal-addModule label input {
    position: absolute;
    top: 50%;
    left: 11px;
    width: 21px;
    height: 21px;
    margin-top: -10px;
    cursor: pointer;
    vertical-align: middle;
    border: 1px solid #a5a5a5;
    border-radius: 25px;
    outline: 0 !important;
    background-color: #fff;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .modal-addModule label input:checked {
      background: #fff url(../img/radio.svg) no-repeat center center; }
  .modal-addModule label input:checked + span:before {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    content: '';
    border: 1px solid #1077ad; }
  .modal-addModule label i {
    display: block;
    color: #808080;
    font-size: 14px;
    line-height: 14px;
    font-style: normal; }

.modal-addModule .btn {
  width: 360px;
  max-width: 100%;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  font-size: 18px; }

.main-menu__wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: -1;
  opacity: 0;
  transition: 0.3s; }
  .main-menu__wrapper--active {
    z-index: 4000;
    opacity: 1; }

.main-menu__container {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background-color: #fff;
  z-index: 5000;
  padding: 48px 20px 20px;
  transition: 0.5s; }
  .main-menu__container--hidden {
    transform: translateX(300px); }

.main-menu__close {
  height: 21px;
  width: 21px;
  background-image: url(../img/btn-close.png);
  cursor: pointer; }
  .main-menu__close--top {
    position: absolute;
    top: 28px;
    right: 15px; }

.main-menu__title {
  font-size: 18px;
  line-height: 21px;
  color: #2c2c2c;
  font-weight: bold;
  padding-bottom: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 190px;
  border-bottom: 1px solid #e1e1e1;
  margin: 0 auto;
  text-align: center; }

.main-menu__signin {
  padding: 1px 0 2px;
  border-bottom: 1px solid #e1e1e1;
  display: none; }

.main-menu__signin-link {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 400;
  line-height: 38px;
  text-decoration: underline; }

.main-menu__mobile-chat {
  display: none;
  margin-top: 20px;
  padding-top: 18px;
  border-top: 1px solid #e1e1e1; }

.main-menu .mobile-chat__link {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline; }
  .main-menu .mobile-chat__link img {
    margin-right: 7px;
    vertical-align: middle; }

.main-menu .mobile-chat__socs {
  display: flex;
  margin-top: 16px; }

.main-menu .mobile-chat__soc {
  width: 26px;
  height: 26px;
  margin-right: 11px; }

/* components */
.button {
  display: block;
  width: 249px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  box-sizing: border-box;
  transition: 0.3s; }
  .button--red {
    background-color: #ce181f;
    color: #fff; }
    @media (min-width: 1024px) {
      .button--red:hover {
        background-color: #841215;
        text-decoration: none;
        color: #fff; } }
  .button--light-red {
    background-color: #b72126; }
    @media (min-width: 1024px) {
      .button--light-red:hover {
        background-color: #841215; } }
  .button--grey {
    background-color: #58585a;
    border: 1px solid #58585a; }
    @media (min-width: 1024px) {
      .button--grey:hover {
        background-color: #ffffff;
        color: #58585a; } }
  .button--white {
    color: #2c2c2c;
    background-color: #fff; }
    @media (min-width: 1024px) {
      .button--white:hover {
        background-color: #d7d7d7; } }
  .button--bottom {
    margin-top: 52px; }

.btn {
  height: 40px;
  line-height: 40px;
  width: 220px;
  text-align: center;
  border-radius: 5px;
  transition: 0.3s; }
  .btn--blue {
    background-color: #124390 !important;
    color: #fff !important; }
    @media (min-width: 1024px) {
      .btn--blue:hover {
        background-color: #58585a !important; } }
    .btn--blue:disabled {
      background-color: #cccccc; }
  .btn--gray {
    background-color: #4d4d4d;
    color: #fff; }
    @media (min-width: 1024px) {
      .btn--gray:hover {
        background-color: #58585a; } }
    .btn--gray:disabled {
      background-color: #cccccc; }
  .btn--gray-invert {
    color: #4d4d4d;
    background-color: #fff;
    border: 1px #4d4d4d solid; }
    @media (min-width: 1024px) {
      .btn--gray-invert:hover {
        background-color: #e0e0e0; } }
    .btn--gray-invert:disabled {
      background-color: #cccccc; }
  .btn--red {
    background-color: #ce181f;
    color: #fff; }
    @media (min-width: 1024px) {
      .btn--red:hover {
        background-color: #981316; } }
    .btn--red:disabled {
      background-color: #cccccc; }
  .btn--mb {
    margin-bottom: 10px; }

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 14px; }
  .breadcrumbs li {
    display: flex;
    align-items: center;
    margin-bottom: 14px; }
    .breadcrumbs li svg {
      width: 34px;
      height: 22px; }
  .breadcrumbs__link {
    font-size: 16px;
    color: #ce171f;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .breadcrumbs__link:hover {
        text-decoration: none; } }
    .breadcrumbs__link--active {
      color: #2c2c2c;
      text-decoration: none; }

.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 900px) {
    .top-nav {
      flex-direction: column;
      align-items: flex-start; } }
  .top-nav .back {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px;
    border: 1px solid #b2b9bc;
    background-color: #fff;
    border-radius: 5px;
    font-size: 16px;
    color: #2c2c2c;
    transition: opacity 0.3s ease; }
    @media (min-width: 1024px) {
      .top-nav .back:hover {
        opacity: 0.7; } }
    @media (max-width: 900px) {
      .top-nav .back {
        order: -1; } }
    .top-nav .back svg {
      max-width: 24px;
      width: 24px;
      margin-right: 8px;
      fill: #b2b9bc;
      flex-shrink: 0; }
    .top-nav .back span {
      white-space: nowrap; }

.pagi ul {
  display: flex;
  justify-content: center; }
  .pagi ul li {
    width: 33px;
    text-align: center;
    border: 1px solid #d7d7d7;
    font-size: 16px;
    line-height: 33px; }
    .pagi ul li.control:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .pagi ul li.control:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .pagi ul li + li {
      border-left: 0; }
    .pagi ul li * {
      display: block;
      color: #2c2c2c; }
    .pagi ul li span {
      color: #fff;
      background: #ce171f; }
    .pagi ul li .pagination-link {
      color: #000;
      background-color: #fff;
      cursor: pointer; }
      @media (min-width: 1024px) {
        .pagi ul li .pagination-link:hover:not(.disabled) {
          background-color: #a5a5a5;
          text-decoration: underline; } }
      .pagi ul li .pagination-link.disabled {
        color: #d3d3d3;
        cursor: default; }
      .pagi ul li .pagination-link.current-page {
        color: #fff;
        background-color: #ce171f;
        cursor: default; }
        @media (min-width: 1024px) {
          .pagi ul li .pagination-link.current-page:hover {
            background-color: #ce171f;
            text-decoration: none; } }
    @media (min-width: 1024px) {
      .pagi ul li a:hover {
        text-decoration: underline;
        background: #a5a5a5; } }

.holyday-info__wrapper {
  padding: 23px 23px 26px 27px;
  box-shadow: -2px 1px 10px rgba(29, 30, 34, 0.18);
  border: 1px solid #d9d9d9;
  background-color: #ffffff; }

.holyday-info__title {
  font-size: 20px;
  font-weight: bold;
  line-height: 33px;
  color: #2c2c2c; }

.holyday-info__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #2c2c2c; }
  .holyday-info__text a {
    color: #ce181f; }

.progress-bar {
  position: relative;
  margin-top: 60px; }
  .progress-bar ul {
    display: flex;
    justify-content: space-between;
    position: relative; }
    .progress-bar ul li {
      width: 20%;
      font-size: 14px;
      line-height: 17px;
      color: #9da4b0; }
      @media (max-width: 768px) {
        .progress-bar ul li {
          padding-bottom: 56px; } }
      .progress-bar ul li .line-block {
        height: 3px;
        background: #e6e6e6;
        position: relative; }
        .progress-bar ul li .line-block .line-block-end-point {
          height: 100%;
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 15px; }
          .progress-bar ul li .line-block .line-block-end-point:after {
            content: '';
            width: 30px;
            height: 30px;
            border: 2px solid #e6e6e6;
            border-radius: 50%;
            background: #fff;
            box-sizing: border-box;
            position: absolute;
            right: -15px;
            top: -15px; }
            @media (max-width: 768px) {
              .progress-bar ul li .line-block .line-block-end-point:after {
                width: 16px;
                height: 16px;
                top: -8px;
                background-size: 10px auto !important; } }
      .progress-bar ul li .line-text-blue {
        display: none;
        color: #3b74ce; }
      .progress-bar ul li .line-text-red,
      .progress-bar ul li .line-text-blue {
        margin-top: 11px;
        margin-right: 30px;
        text-align: center; }
        @media (max-width: 768px) {
          .progress-bar ul li .line-text-red,
          .progress-bar ul li .line-text-blue {
            font-size: 10px;
            margin-top: 5px;
            margin-right: 18px; } }
      .progress-bar ul li .btn {
        display: none;
        width: auto;
        margin-left: 0;
        margin-right: 30px;
        margin-top: 30px; }
        @media (max-width: 768px) {
          .progress-bar ul li .btn {
            position: absolute;
            left: 0;
            right: 0;
            margin-right: 0; } }
      .progress-bar ul li.edit-block .btn {
        display: block; }
      .progress-bar ul li.edit-block .line-text-red {
        display: block;
        color: #ce181f; }
      .progress-bar ul li.edit-block .line-block {
        background: #ce181f; }
        .progress-bar ul li.edit-block .line-block .line-block-end-point:after {
          border: 1px solid #ce181f;
          box-shadow: 0 1px 5px 0 rgba(23, 54, 67, 0.35); }
        .progress-bar ul li.edit-block .line-block .line-block-end-point:before {
          content: '';
          width: 12px;
          height: 12px;
          margin-top: -7px;
          border-radius: 50%;
          background: #ce181f;
          position: absolute;
          top: 50%;
          right: -6px;
          z-index: 1; }
          @media (max-width: 768px) {
            .progress-bar ul li.edit-block .line-block .line-block-end-point:before {
              width: 6px;
              height: 6px;
              right: -10px;
              margin-top: -5px; } }
      .progress-bar ul li.saved-block .line-text-blue {
        display: block;
        color: #3b74ce; }
      .progress-bar ul li.saved-block .line-text-red {
        display: none; }
      .progress-bar ul li.saved-block .line-block {
        background: #3b74ce; }
        .progress-bar ul li.saved-block .line-block .line-block-end-point:after {
          border: 1px solid #3b74ce;
          background: #3b74ce url("../img/progress-bar-chk.svg") no-repeat center center; }
      .progress-bar ul li.apply_finance {
        display: none; }
  .progress-bar.style-finance li {
    width: calc(100% / 6); }
    .progress-bar.style-finance li.apply_finance {
      display: block; }

.imgload__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch; }

.imgload__item {
  width: 100%;
  height: max-content;
  max-width: 170px;
  margin-top: 10px;
  margin-right: 14px;
  border: 1px solid #a5a5a5;
  position: relative; }
  .imgload__item img {
    display: block;
    width: 100%; }

.imgload__del {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer; }

.booking-tip__wrapper {
  padding: 20px 0; }

.booking-tip__title {
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  margin-bottom: 20px; }

.booking-tip__text {
  font-size: 14px;
  line-height: 17px; }

.header-covid-message {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 52px;
  margin-right: 30px;
  padding: 10px 30px;
  border: 1px solid #d9d9d9;
  background-color: #fff;
  box-sizing: border-box; }
  @media (min-width: 1400px) and (max-width: 1600px) {
    .header-covid-message {
      flex-wrap: wrap;
      width: 355px;
      height: unset;
      padding: 10px;
      margin-right: 15px;
      text-align: center; } }
  @media (max-width: 1399px) {
    .header-covid-message {
      width: 100%;
      max-width: 100%;
      border-left: 0;
      border-right: 0;
      position: absolute;
      left: 0;
      bottom: -52px; } }
  @media (max-width: 520px) {
    .header-covid-message {
      flex-wrap: wrap;
      height: 76px;
      padding: 10px;
      margin-right: 15px;
      text-align: center;
      bottom: -77px; } }
  @media (max-width: 363px) {
    .header-covid-message {
      height: 86px;
      bottom: -87px; } }
  .header-covid-message__text {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 33px;
    font-weight: bold;
    color: #2c2c2c; }
    @media (min-width: 1400px) and (max-width: 1600px) {
      .header-covid-message__text {
        font-size: 16px;
        line-height: 22px; } }
    @media (max-width: 520px) {
      .header-covid-message__text {
        line-height: 1.2; }
        .header-covid-message__text::before {
          margin-right: 10px; } }
    @media (max-width: 363px) {
      .header-covid-message__text {
        position: relative;
        padding: 0 30px;
        font-size: 16px;
        line-height: 22px; }
        .header-covid-message__text::before {
          position: absolute;
          left: 0;
          top: -4px;
          margin-right: 0; } }
    .header-covid-message__text::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-image: url(../img/error_outline.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      margin-right: 14px; }
  .header-covid-message__read-more {
    margin-left: 14px;
    font-size: 18px;
    line-height: 33px;
    font-weight: 400;
    color: #b72126;
    text-decoration: underline; }
    @media (min-width: 1400px) and (max-width: 1600px) {
      .header-covid-message__read-more {
        width: 100%;
        margin-left: 0;
        font-size: 16px;
        line-height: 22px; } }
    @media (max-width: 520px) {
      .header-covid-message__read-more {
        width: 100%;
        line-height: 1;
        margin-left: 0; } }
    @media (min-width: 1024px) {
      .header-covid-message__read-more:hover {
        text-decoration: none; } }

.contacts-caption {
  width: 100%;
  position: fixed;
  top: 62px;
  left: 0;
  z-index: 20;
  box-sizing: border-box; }
  @media (min-width: 1200px) {
    .contacts-caption {
      display: none; } }
  .contacts-caption__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding-right: 25px;
    background-color: #ce171f; }
  .contacts-caption__phone {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    background-color: #b3181f;
    cursor: pointer; }
    .contacts-caption__phone svg {
      fill: #fff; }
  .contacts-caption__socials {
    display: flex;
    align-items: center; }
    .contacts-caption__socials span {
      padding-right: 10px;
      font-size: 15px;
      color: #fefefe; }
      @media (max-width: 380px) {
        .contacts-caption__socials span {
          font-size: 13px; } }
  .contacts-caption__socials-item {
    display: flex;
    align-items: center;
    height: 24px;
    padding-left: 12px;
    margin-left: 12px;
    position: relative; }
    .contacts-caption__socials-item::before {
      content: '';
      display: block;
      width: 1px;
      height: 18px;
      background-color: #d8383f;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
  .contacts-caption__toggle {
    display: none;
    width: 100%;
    height: calc(100vh - 112px);
    padding: 13px 17px;
    background-color: #fff;
    position: fixed;
    top: 112px;
    left: 0;
    overflow: auto;
    box-sizing: border-box; }
  .contacts-caption__block + .contacts-caption__block {
    margin-top: 17px;
    padding-top: 8px;
    border-top: 1px solid #e6e6e6; }
  .contacts-caption__block:last-child {
    padding-bottom: 70px; }
  .contacts-caption__office {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: #2c2c2c; }
    .contacts-caption__office svg {
      fill: #b2b9bc; }
  .contacts-caption__list {
    margin-top: 9px; }
    .contacts-caption__list li {
      display: flex;
      padding-left: 10px;
      font-size: 13px;
      line-height: 17px;
      position: relative; }
      .contacts-caption__list li::before {
        content: '';
        display: block;
        width: 3px;
        height: 3px;
        background-color: #ce171f;
        position: absolute;
        top: 8px;
        left: 0; }
      .contacts-caption__list li + li {
        margin-top: 8px; }
      .contacts-caption__list li span {
        max-width: 80px;
        width: 100%; }
      .contacts-caption__list li a {
        color: #2c2c2c;
        text-decoration: underline; }

.header-pdf {
  padding-bottom: 20px; }
  .header-pdf .header-pdf-logo-left {
    margin-right: 40px;
    margin-top: 50px;
    float: left; }
  .header-pdf .header-pdf-headline {
    width: 756px;
    margin-top: 63px;
    float: left;
    font-size: 40px;
    line-height: 1.2;
    text-align: center; }
  .header-pdf .header-pdf-logo-right {
    float: right; }

.toggle {
  position: relative;
  overflow: hidden; }
  .toggle .btn, .toggle.btn {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 0 .75rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
    font-size: 1rem;
    line-height: 2.3;
    font-weight: 400;
    color: #212529;
    text-align: center;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    vertical-align: middle;
    user-select: none; }
    @media (min-width: 1024px) {
      .toggle .btn:hover, .toggle.btn:hover {
        color: #212529;
        text-decoration: none; } }
  .toggle .btn-success, .toggle.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    @media (min-width: 1024px) {
      .toggle .btn-success:hover, .toggle.btn-success:hover {
        color: #fff;
        background-color: #218838;
        border-color: #1e7e34; } }
  .toggle .btn-danger, .toggle.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    @media (min-width: 1024px) {
      .toggle .btn-danger:hover, .toggle.btn-danger:hover {
        color: #fff;
        background-color: #c82333;
        border-color: #bd2130; } }
  .toggle .btn-light, .toggle.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    @media (min-width: 1024px) {
      .toggle .btn-light:hover, .toggle.btn-light:hover {
        color: #212529;
        background-color: #e2e6ea;
        border-color: #dae0e5; } }
  .toggle .btn-group-xs > .btn,
  .toggle .btn-xs {
    padding: 0.35rem 0.4rem 0.25rem 0.4rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 0.2rem; }
  .toggle .checkbox label .toggle,
  .toggle .checkbox-inline .toggle {
    margin-left: -1.25rem;
    margin-right: 0.35rem; }
  .toggle.btn.btn-light, .toggle.btn.btn-outline-light {
    border-color: rgba(0, 0, 0, 0.15); }
  .toggle input[type="checkbox"] {
    display: none; }
  .toggle .toggle-group {
    width: 200%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    transition: left 0.35s;
    -moz-user-select: none;
    -webkit-user-select: none; }
    .toggle .toggle-group label,
    .toggle .toggle-group span {
      cursor: pointer; }
  .toggle.off .toggle-group {
    left: -100%; }
  .toggle .toggle-on {
    margin: 0;
    border: 0;
    border-radius: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 50%; }
  .toggle .toggle-off {
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0; }
  .toggle .toggle-handle {
    width: 0;
    height: 100% !important;
    margin: 0 auto;
    padding-top: 0;
    padding-bottom: 0;
    border-width: 0 1px;
    background-color: #fff;
    position: relative; }
  .toggle.btn-outline-primary .toggle-handle {
    background-color: #007bff;
    border-color: #007bff; }
  .toggle.btn-outline-secondary .toggle-handle {
    background-color: #6c757d;
    border-color: #6c757d; }
  .toggle.btn-outline-success .toggle-handle {
    background-color: #28a745;
    border-color: #28a745; }
  .toggle.btn-outline-danger .toggle-handle {
    background-color: #dc3545;
    border-color: #dc3545; }
  .toggle.btn-outline-warning .toggle-handle {
    background-color: #ffc107;
    border-color: #ffc107; }
  .toggle.btn-outline-info .toggle-handle {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .toggle.btn-outline-light .toggle-handle {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .toggle.btn-outline-dark .toggle-handle {
    background-color: #343a40;
    border-color: #343a40; }
  @media (min-width: 1024px) {
    .toggle[class*="btn-outline"]:hover .toggle-handle {
      background-color: #f8f9fa;
      opacity: 0.5; } }
  .toggle.btn {
    min-width: 3.7rem;
    min-height: 2.15rem; }
  .toggle .toggle-on.btn {
    padding-right: 1.5rem; }
  .toggle .toggle-off.btn {
    padding-left: 1.5rem; }
  .toggle.btn-lg {
    min-width: 5rem;
    min-height: 2.815rem; }
  .toggle .toggle-on.btn-lg {
    padding-right: 2rem; }
  .toggle .toggle-off.btn-lg {
    padding-left: 2rem; }
  .toggle .toggle-handle.btn-lg {
    width: 2.5rem; }
  .toggle.btn-sm {
    min-width: 3.125rem;
    min-height: 1.938rem; }
  .toggle .toggle-on.btn-sm {
    padding-right: 1rem; }
  .toggle .toggle-off.btn-sm {
    padding-left: 1rem; }
  .toggle.btn-xs {
    min-width: 2.19rem;
    min-height: 1.375rem; }
  .toggle .toggle-on.btn-xs {
    padding-right: .8rem; }
  .toggle .toggle-off.btn-xs {
    padding-left: .8rem; }

/* blocks */
.page-main-title {
  width: 100%;
  height: 100px;
  text-align: center;
  background-color: #f6f6f6; }
  @media (max-width: 900px) {
    .page-main-title {
      height: 80px; } }
  .page-main-title .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative; }
  .page-main-title .title {
    font-size: 34px;
    line-height: 33px;
    font-weight: 400;
    color: #2c2c2c; }
    @media (max-width: 768px) {
      .page-main-title .title {
        font-size: 30px; } }
    @media (max-width: 600px) {
      .page-main-title .title {
        font-size: 24px;
        line-height: 29px; } }

.slide-title {
  width: 100%;
  height: 100px;
  text-align: center;
  background-color: #f6f6f6; }
  .slide-title .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative; }
  .slide-title .title {
    font-size: 35px;
    line-height: 33px;
    font-weight: 400;
    color: #2c2c2c; }
    @media (max-width: 768px) {
      .slide-title .title {
        font-size: 30px; } }
    @media (max-width: 600px) {
      .slide-title .title {
        font-size: 27px;
        line-height: 29px; } }
  .slide-title .step {
    width: 100px;
    text-align: center;
    font-size: 14px;
    line-height: 14px;
    font-weight: 400;
    color: #ce181f;
    position: absolute;
    bottom: 19px;
    left: calc(50% - 50px); }
    @media (max-width: 900px) {
      .slide-title .step.step-1 {
        bottom: 10px; } }

.form-block .input-block label:not(.checkbox-container) {
  display: inline-block;
  user-select: none;
  vertical-align: top;
  font-size: 14px;
  color: #2c2c2c; }
  .form-block .input-block label:not(.checkbox-container) .outer {
    white-space: nowrap; }
    @media (max-width: 600px) {
      .form-block .input-block label:not(.checkbox-container) .outer {
        white-space: initial; } }

.form-block .input-block input[type=number],
.form-block .input-block input[type=text],
.form-block .input-block input[type=email],
.form-block .input-block input[type=tel],
.form-block .input-block input[type=password],
.form-block .input-block textarea {
  width: 100%;
  height: 40px;
  margin-top: 4px;
  padding: 8px 15px;
  border: 1px solid #a5a5a5;
  border-radius: 3px;
  outline: none;
  font-size: 14px;
  line-height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box; }
  .form-block .input-block input[type=number].gb-bg,
  .form-block .input-block input[type=text].gb-bg,
  .form-block .input-block input[type=email].gb-bg,
  .form-block .input-block input[type=tel].gb-bg,
  .form-block .input-block input[type=password].gb-bg,
  .form-block .input-block textarea.gb-bg {
    padding-left: 57px;
    background: #FFF url(../img/gb.png) no-repeat top left;
    background-size: auto 100%; }
  .form-block .input-block input[type=number]::placeholder,
  .form-block .input-block input[type=text]::placeholder,
  .form-block .input-block input[type=email]::placeholder,
  .form-block .input-block input[type=tel]::placeholder,
  .form-block .input-block input[type=password]::placeholder,
  .form-block .input-block textarea::placeholder {
    opacity: 0.5;
    font-size: 15px; }
  .form-block .input-block input[type=number]:focus::placeholder,
  .form-block .input-block input[type=text]:focus::placeholder,
  .form-block .input-block input[type=email]:focus::placeholder,
  .form-block .input-block input[type=tel]:focus::placeholder,
  .form-block .input-block input[type=password]:focus::placeholder,
  .form-block .input-block textarea:focus::placeholder {
    opacity: 0; }

.form-block .input-block textarea {
  height: 120px; }

.form-block .input-block select {
  width: 100%;
  height: 40px;
  margin-top: 4px;
  padding: 8px 25px 8px 6px;
  resize: none;
  border: 1px solid #a5a5a5;
  border-radius: 3px;
  outline: none;
  background: #FFF url(../img/select_hov.svg) no-repeat;
  background-position: calc(100% - 10px) center;
  font-size: 14px;
  line-height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box; }
  .form-block .input-block select::-ms-expand {
    display: none; }

.form-block .input-block .datepicker-input {
  width: 100%;
  height: 40px;
  padding: 8px 15px;
  resize: none;
  border: 1px solid #a5a5a5;
  border-radius: 3px;
  outline: none;
  background: #FFF url(../img/datepicker.png) no-repeat;
  background-position: calc(100% - 9px) center;
  font-size: 14px;
  line-height: 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box; }

.form-block .input-block input[type=number],
.form-block .input-block input[type=text],
.form-block .input-block input[type=email],
.form-block .input-block input[type=tel],
.form-block .input-block input[type=password],
.form-block .input-block select,
.form-block .input-block textarea {
  width: 100%; }
  .form-block .input-block input[type=number].err,
  .form-block .input-block input[type=text].err,
  .form-block .input-block input[type=email].err,
  .form-block .input-block input[type=tel].err,
  .form-block .input-block input[type=password].err,
  .form-block .input-block select.err,
  .form-block .input-block textarea.err {
    transition: .2s;
    border: none;
    box-shadow: 0 0 1px 1px #d01c15; }

.form-block .input-block .radio-btn + .radio-btn {
  margin-top: 15px; }

.form-block .input-block .radio-label {
  margin-bottom: 0;
  cursor: pointer;
  vertical-align: middle; }

.form-block .input-block input[type=radio] {
  max-width: 18px;
  min-width: 18px;
  width: 100%;
  height: 18px;
  margin: 0;
  margin-right: 8px;
  cursor: pointer;
  vertical-align: middle;
  border: 1px solid #a5a5a5;
  border-radius: 25px;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none; }
  .form-block .input-block input[type=radio]:checked {
    background: #FFF url(../img/radio.svg) no-repeat center center;
    background-size: 10px; }

.form-block .input-block.err-required::after {
  position: relative;
  top: 6px;
  display: block;
  width: 100%;
  content: "This field is required";
  color: #d01c15; }

.form-block .input-block.err-format::after {
  display: block;
  width: 100%;
  position: relative;
  top: 6px;
  content: "Invalid format";
  color: #d01c15; }

.form-block button {
  width: 100%;
  max-width: 455px;
  margin-top: 40px;
  font-weight: 400;
  font-size: 15px; }

.form-block .row {
  margin-bottom: 20px; }
  .form-block .row .row-title {
    display: inline-block;
    margin-top: 16px;
    margin-bottom: 1rem;
    user-select: none;
    vertical-align: top;
    color: #2c2c2c;
    font-size: 16px; }

.form-block .agree-row {
  display: flex;
  align-items: center;
  margin-top: 18px; }
  .form-block .agree-row .checkbox-container {
    margin: 0; }
  .form-block .agree-row .agree-text {
    margin-left: 10px;
    color: #2c2c2c;
    font-size: 16px; }
    .form-block .agree-row .agree-text a {
      text-decoration: underline;
      color: #841215; }
      @media (min-width: 1024px) {
        .form-block .agree-row .agree-text a:hover {
          text-decoration: none; } }

.form-block .speco-map-row .input-block .radio-btn + .radio-btn {
  margin-top: 0; }

.form-block .speco-map-row .input-block input[type=radio] {
  margin-top: 5px; }

.accordion__desc, .accordion__tabs-title, .accordion__list-item {
  font-size: 13px;
  line-height: 16px; }

.accordion__title {
  font-size: 18px;
  font-weight: bold;
  color: #2c2c2c; }
  .accordion__title--top {
    margin-bottom: 8px; }

.accordion__hint {
  font-size: 12px;
  font-weight: normal;
  color: #808080;
  margin-left: 8px; }

.accordion__zag {
  font-size: 14px;
  line-height: 32px;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #fff;
  text-align: center;
  height: 32px;
  padding: 0 !important;
  margin-top: 6px;
  background-color: #b72126 !important;
  border: none;
  border-radius: 0;
  cursor: pointer;
  transition: 0.3s; }

.accordion__body {
  padding: 22px 0 0 !important;
  border-left: 1px solid #bfbfbf;
  border-bottom: 1px solid #bfbfbf;
  border-right: 1px solid #bfbfbf;
  box-sizing: border-box; }

.accordion__phone-link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 20px;
  font-weight: 700;
  color: #2c2c2c; }

.accordion__desc {
  padding: 0 20px;
  margin-top: 18px;
  box-sizing: border-box;
  color: #2c2c2c; }
  .accordion__desc a.onmap {
    display: block;
    font-size: 18px;
    color: #0000ee;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .accordion__desc a.onmap:hover {
        text-decoration: none; } }

.accordion__tabs {
  margin-top: 18px;
  border-top: 1px solid #bfbfbf;
  box-sizing: border-box; }

.accordion__tabs-header {
  display: flex; }

.accordion__tabs-title {
  width: 100%;
  height: 31px;
  line-height: 31px;
  font-weight: bold;
  text-align: center;
  background-color: #e6e6e6;
  border-bottom: 1px solid #bfbfbf;
  box-sizing: border-box;
  cursor: pointer; }
  .accordion__tabs-title:not(:last-child) {
    border-right: 1px solid #bfbfbf; }
  .accordion__tabs-title.js-active {
    background-color: #fff;
    border-bottom: none; }

.accordion__tabs-body {
  width: 0;
  height: 0;
  position: relative;
  overflow: hidden;
  opacity: 0;
  box-sizing: border-box;
  transition: opacity 0.3s; }
  .accordion__tabs-body.js-active {
    width: auto;
    height: auto;
    opacity: 1;
    padding: 16px; }

.accordion__list-item {
  color: #2c2c2c; }
  .accordion__list-item + .accordion__list-item {
    margin-top: 5px; }
  .accordion__list-item--right {
    float: right; }

.accordion--top {
  margin-top: 20px; }

.mobile-toggle {
  height: 52px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none; }
  .mobile-toggle:focus {
    outline: none; }
  .mobile-toggle img {
    display: block; }

.sell-car-page .booking__page--err_block {
  margin-bottom: 26px;
  font-size: 18px;
  line-height: 24px;
  text-align: center; }
  .sell-car-page .booking__page--err_block .inn_err_block {
    margin-bottom: 30px;
    text-align: center; }
  .sell-car-page .booking__page--err_block .ah1 {
    font-size: 30px;
    margin-bottom: 30px; }
  .sell-car-page .booking__page--err_block p {
    font-size: 14px;
    line-height: 1.5em;
    margin-bottom: 15px; }
  .sell-car-page .booking__page--err_block .tel {
    color: inherit;
    font-size: 19px;
    transition: color 0.3s ease; }
    @media (min-width: 1024px) {
      .sell-car-page .booking__page--err_block .tel:hover {
        color: #ce181f; } }
    .sell-car-page .booking__page--err_block .tel::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 7px;
      background: url(../img/err.svg) no-repeat; }

.sell-car-page .owl-stage-outer {
  transition: height 0.3s linear; }

.sell-car-page .slides {
  width: 100%;
  height: 100%; }
  .sell-car-page .slides:not(.owl-loaded) {
    display: flex;
    opacity: 0; }
  .sell-car-page .slides .slide {
    min-height: calc(100vh - 203px);
    padding-bottom: 80px;
    box-sizing: border-box; }
    .sell-car-page .slides .slide .btns-row {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px; }
      .sell-car-page .slides .slide .btns-row .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px; }
    .sell-car-page .slides .slide .btn-prev-slide {
      width: 200px;
      font-family: 'Vauxhall Neue', sans-serif;
      font-size: 15px;
      font-weight: 400;
      color: #fff;
      cursor: pointer; }
      .sell-car-page .slides .slide .btn-prev-slide svg {
        transform: rotate(180deg); }
    .sell-car-page .slides .slide .content .inner {
      height: 100%; }
    .sell-car-page .slides .slide .content .big-block-title {
      font-size: 30px;
      line-height: 33px;
      font-weight: 700;
      text-transform: uppercase; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide .content .big-block-title {
          font-size: 28px;
          line-height: 30px; } }
    .sell-car-page .slides .slide .content .block-title {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 29px; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide .content .block-title {
          font-size: 16px;
          margin-bottom: 30px; } }
      .sell-car-page .slides .slide .content .block-title.fz24 {
        font-size: 24px;
        line-height: 1;
        font-weight: 400;
        margin-bottom: 29px; }
    .sell-car-page .slides .slide .content .text {
      font-size: 15px;
      line-height: 26px;
      font-weight: 400; }
    .sell-car-page .slides .slide .content .sub-text {
      width: 365px;
      max-width: 365px;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: #757575; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide .content .sub-text {
          text-align: center; } }
      .sell-car-page .slides .slide .content .sub-text.center {
        text-align: center; }
    .sell-car-page .slides .slide .content .btn-next-slide {
      width: 200px;
      font-size: 15px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer; }
    .sell-car-page .slides .slide .content .private-policy {
      font-size: 13px;
      line-height: 16px;
      font-weight: bold;
      text-decoration: underline;
      color: #757575; }
      @media (min-width: 1024px) {
        .sell-car-page .slides .slide .content .private-policy:hover {
          text-decoration: none; } }
    .sell-car-page .slides .slide .rows {
      margin-top: 35px; }
      .sell-car-page .slides .slide .rows.w560 {
        width: 560px; }
        @media (max-width: 600px) {
          .sell-car-page .slides .slide .rows.w560 {
            width: 100%; } }
    .sell-car-page .slides .slide .row {
      max-width: 830px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;
      margin-top: -20px; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide .row {
          width: 100%;
          margin-left: 0;
          margin-top: -20px; } }
      .sell-car-page .slides .slide .row .input-block {
        flex: 1 0 250px;
        margin-left: 30px;
        margin-top: 20px; }
        @media (max-width: 600px) {
          .sell-car-page .slides .slide .row .input-block {
            margin-left: 0;
            width: 100%;
            min-width: 100%; } }
        .sell-car-page .slides .slide .row .input-block label {
          width: 100%; }
    .sell-car-page .slides .slide .btn-next-slide {
      margin-top: 0; }
    .sell-car-page .slides .slide .block-title {
      text-align: center; }
    .sell-car-page .slides .slide .center-block {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding-top: 50px; }
    .sell-car-page .slides .slide .private-policy {
      margin-top: 13px; }
    .sell-car-page .slides .slide .sub-text {
      max-width: 330px;
      margin-top: 16px; }
    .sell-car-page .slides .slide.slide-1 {
      padding-bottom: 0; }
      .sell-car-page .slides .slide.slide-1 .content .inner {
        max-width: 1080px; }
      .sell-car-page .slides .slide.slide-1 .content .flex-block {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        min-height: calc(100vh - 300px);
        padding-top: 90px; }
        @media (max-width: 600px) {
          .sell-car-page .slides .slide.slide-1 .content .flex-block {
            padding-top: 50px;
            flex-direction: column-reverse;
            align-items: center; } }
        .sell-car-page .slides .slide.slide-1 .content .flex-block .left-block {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          width: 48%; }
          @media (max-width: 600px) {
            .sell-car-page .slides .slide.slide-1 .content .flex-block .left-block {
              margin-top: 30px; } }
          .sell-car-page .slides .slide.slide-1 .content .flex-block .left-block img {
            display: block;
            max-width: 100%;
            max-height: 100%; }
        .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block {
          width: 46.3%;
          padding-top: 50px;
          padding-bottom: 30px; }
          @media (max-width: 600px) {
            .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block {
              width: 90%;
              text-align: center;
              padding-top: 0;
              padding-bottom: 0; } }
          .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .block-title {
            margin-top: 7px;
            text-align: left; }
            @media (max-width: 600px) {
              .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .block-title {
                text-align: center; } }
          .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .text {
            margin-top: 30px; }
          .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .btn-next-slide {
            width: 248px;
            margin-top: 41px; }
            @media (max-width: 600px) {
              .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .btn-next-slide {
                margin-left: auto;
                margin-right: auto; } }
          .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .sub-text {
            margin-top: 19px; }
            @media (max-width: 600px) {
              .sell-car-page .slides .slide.slide-1 .content .flex-block .right-block .sub-text {
                max-width: 100%;
                width: 90%; } }
    .sell-car-page .slides .slide.slide-3 .block-title {
      margin-bottom: 35px; }
      .sell-car-page .slides .slide.slide-3 .block-title img {
        margin-left: 15px; }
      .sell-car-page .slides .slide.slide-3 .block-title svg {
        fill: #1abb64;
        stroke: #1abb64;
        margin-right: 5px; }
    .sell-car-page .slides .slide.slide-3 .content .inner {
      max-width: 516px; }
      .sell-car-page .slides .slide.slide-3 .content .inner .center-block {
        justify-content: space-between;
        min-height: calc(100vh - 375px);
        box-sizing: border-box; }
        .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows {
          margin-top: 10px; }
          @media (max-width: 600px) {
            .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows {
              margin-top: 25px; } }
          .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows .input-block {
            display: flex;
            justify-content: space-between; }
            @media (max-width: 600px) {
              .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows .input-block {
                display: block; } }
            .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows .input-block label {
              flex-shrink: 0;
              width: 200px; }
            .sell-car-page .slides .slide.slide-3 .content .inner .center-block .rows .input-block input[type="text"] {
              height: 21px;
              padding: 0;
              border: 0;
              border-bottom: 1px solid #000;
              line-height: 19px; }
        .sell-car-page .slides .slide.slide-3 .content .inner .center-block > div {
          width: 100%; }
    .sell-car-page .slides .slide.slide-3 .vehicle-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 20px;
      margin-bottom: 18px; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide.slide-3 .vehicle-name {
          flex-direction: column; } }
      .sell-car-page .slides .slide.slide-3 .vehicle-name__text {
        font-size: 16px;
        height: 20px; }
        @media (max-width: 600px) {
          .sell-car-page .slides .slide.slide-3 .vehicle-name__text {
            text-align: center;
            margin-bottom: 10px; } }
      .sell-car-page .slides .slide.slide-3 .vehicle-name__link {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #ce171f;
        text-decoration: underline;
        white-space: nowrap; }
        @media (min-width: 1024px) {
          .sell-car-page .slides .slide.slide-3 .vehicle-name__link:hover {
            text-decoration: none; } }
        .sell-car-page .slides .slide.slide-3 .vehicle-name__link svg {
          fill: #ce171f;
          margin-right: 5px; }
    .sell-car-page .slides .slide.slide-3 .vehicle-info {
      margin-bottom: 18px; }
      .sell-car-page .slides .slide.slide-3 .vehicle-info table {
        width: 100%; }
        .sell-car-page .slides .slide.slide-3 .vehicle-info table tr:nth-child(odd) {
          background-color: #f7f7f7; }
        .sell-car-page .slides .slide.slide-3 .vehicle-info table td {
          width: 50%;
          padding: 9.7px 20px;
          font-size: 15px; }
          @media (max-width: 600px) {
            .sell-car-page .slides .slide.slide-3 .vehicle-info table td {
              font-size: 14px;
              padding: 8px; } }
    .sell-car-page .slides .slide.slide-4 .images-upload-block {
      max-width: 620px;
      margin: 0 auto 21px; }
      .sell-car-page .slides .slide.slide-4 .images-upload-block .label {
        font-size: 16px;
        color: #757575;
        text-align: center; }
        .sell-car-page .slides .slide.slide-4 .images-upload-block .label span {
          color: #2c2c2c; }
      .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item .title {
        font-weight: 400; }
      .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item .status {
        font-size: 12px;
        font-weight: 400; }
      .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item.uploaded {
        background-color: #ce171f; }
        .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item.uploaded .status {
          padding-right: 0;
          background: none; }
        .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item.uploaded .img-block {
          width: 36px;
          height: 27px;
          opacity: 1;
          background: url("../images/check-img.svg") center center no-repeat; }
          .sell-car-page .slides .slide.slide-4 .images-upload-block .grid-block .item.uploaded .img-block img {
            display: none; }
      .sell-car-page .slides .slide.slide-4 .images-upload-block .or-checkbox {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px; }
        .sell-car-page .slides .slide.slide-4 .images-upload-block .or-checkbox .checkbox-container {
          margin: 0 18px; }
        .sell-car-page .slides .slide.slide-4 .images-upload-block .or-checkbox .skip-text {
          color: #757575; }
    .sell-car-page .slides .slide.slide-5 .row-2 .input-block {
      width: 100%;
      max-width: 100%; }
      .sell-car-page .slides .slide.slide-5 .row-2 .input-block label span {
        color: #999; }
    .sell-car-page .slides .slide.slide-6 .finish-text {
      max-width: 800px;
      font-size: 20px;
      line-height: 33px;
      font-weight: 400;
      text-align: center;
      color: #2c2c2c; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide.slide-6 .finish-text {
          font-size: 16px;
          line-height: 26px; } }
      .sell-car-page .slides .slide.slide-6 .finish-text span {
        margin-bottom: 10px;
        font-size: 30px; }
      .sell-car-page .slides .slide.slide-6 .finish-text p {
        margin-top: 18px;
        font-size: 18px; }
    .sell-car-page .slides .slide.slide-6 .btn-go-home {
      width: 300px;
      margin-top: 56px;
      font-family: 'Vauxhall Neue', sans-serif;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer; }
      @media (max-width: 600px) {
        .sell-car-page .slides .slide.slide-6 .btn-go-home {
          margin-top: 20px; } }
    .sell-car-page .slides .slide.slide-6 .content .inner .center-block {
      box-sizing: border-box;
      min-height: calc(100vh - 375px);
      padding-top: 140px; }

.sell-car-page + .footer {
  background-color: #f6f6f6; }

.log-page + .footer {
  background-color: #f6f6f6; }

.log-page .inner {
  max-width: 1500px; }

.log-page .main-content {
  overflow: auto;
  margin-top: 60px; }
  .log-page .main-content table {
    width: 100%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    border-collapse: collapse; }
    @media (max-width: 1115px) {
      .log-page .main-content table {
        width: 100%; } }
    .log-page .main-content table tr th {
      height: 33px;
      padding: 0 6px;
      text-align: left;
      vertical-align: middle;
      background-color: #e2edf8;
      font-size: 13px;
      font-weight: bold; }
    .log-page .main-content table tr td {
      height: 33px;
      padding: 0 6px;
      vertical-align: middle;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 400;
      color: #2c2c2c; }
      .log-page .main-content table tr td .link {
        color: #2c2c2c;
        text-decoration: underline;
        transition: 0.3s; }
        @media (min-width: 1024px) {
          .log-page .main-content table tr td .link:hover {
            text-decoration: none;
            color: #841215; } }
    .log-page .main-content table tr:nth-child(even) td {
      background-color: #f2f2f2; }
  @media (max-width: 1115px) {
    .log-page .main-content .left-side,
    .log-page .main-content .right-side {
      width: 100%; } }
  .log-page .main-content .right-side table tr th {
    background-color: #a9cdf3; }

.log-page .phone-balloon,
.log-page .email-balloon {
  display: block;
  width: 42px;
  height: 100%;
  min-height: 100%;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center; }
  .log-page .phone-balloon.phone-balloon,
  .log-page .email-balloon.phone-balloon {
    background-image: url("../img/log-phone.svg"); }
  .log-page .phone-balloon.email-balloon,
  .log-page .email-balloon.email-balloon {
    background-image: url("../img/log-mail.svg"); }
  @media (min-width: 1024px) {
    .log-page .phone-balloon:hover,
    .log-page .email-balloon:hover {
      background-color: #464646; }
      .log-page .phone-balloon:hover.phone-balloon,
      .log-page .email-balloon:hover.phone-balloon {
        background-image: url("../img/log-phone-w.svg"); }
      .log-page .phone-balloon:hover.email-balloon,
      .log-page .email-balloon:hover.email-balloon {
        background-image: url("../img/log-mail-w.svg"); } }
  .log-page .phone-balloon.active,
  .log-page .email-balloon.active {
    background-color: #464646; }
    .log-page .phone-balloon.active.phone-balloon,
    .log-page .email-balloon.active.phone-balloon {
      background-image: url("../img/log-phone-w.svg"); }
    .log-page .phone-balloon.active.email-balloon,
    .log-page .email-balloon.active.email-balloon {
      background-image: url("../img/log-mail-w.svg"); }

.winter-service {
  padding-bottom: 100px;
  /* Special-offer page */
  /* Arrange-collection page */ }
  .winter-service .title_h2 {
    font-size: 18px;
    line-height: 21px; }
    .winter-service .title_h2 b {
      font-weight: 500; }
    .winter-service .title_h2 a {
      display: inline-block;
      margin-left: 10px;
      font-size: 14px;
      color: #ce171f;
      text-decoration: underline;
      vertical-align: top; }
  .winter-service .page-main-title {
    margin-bottom: 43px; }
  .winter-service .big_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 380px;
    padding: 30px 65px;
    margin-bottom: 50px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    text-transform: uppercase;
    box-sizing: border-box; }
    @media (max-width: 768px) {
      .winter-service .big_banner {
        height: 164px;
        padding: 20px; } }
    .winter-service .big_banner__border {
      border: 1px solid #cccccc; }
      @media (max-width: 768px) {
        .winter-service .big_banner__border {
          height: auto;
          background-position: left center; } }
    .winter-service .big_banner .ah2 {
      font-size: 36px;
      line-height: 46px;
      color: #2c2c2c;
      max-width: 400px;
      margin-bottom: 21px;
      font-weight: bold; }
      @media (max-width: 768px) {
        .winter-service .big_banner .ah2 {
          max-width: 230px;
          font-size: 21px;
          line-height: 31px; } }
      .winter-service .big_banner .ah2.white {
        color: #fff; }
    .winter-service .big_banner .ah3 {
      max-width: none !important;
      margin-top: 70px;
      margin-bottom: 0;
      font-size: 24px;
      line-height: 27px;
      text-align: left;
      text-transform: none; }
    .winter-service .big_banner .ah4 {
      margin-bottom: 5px;
      font-size: 27px;
      line-height: 30px;
      text-align: left;
      text-transform: none; }
    .winter-service .big_banner .button--red {
      display: block;
      width: 275px;
      font-size: 18px;
      line-height: 45px;
      text-align: center; }
  .winter-service .ah3 {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 14px;
    font-size: 24px;
    line-height: 33px;
    color: #2c2c2c;
    text-align: center; }
  .winter-service .ah4 {
    margin-bottom: 70px;
    font-size: 16px;
    line-height: 26px;
    text-align: center; }
    .winter-service .ah4 p + p {
      max-width: 755px;
      margin-left: auto;
      margin-right: auto; }
  .winter-service .second_block {
    margin-bottom: 60px;
    padding-bottom: 70px;
    overflow: hidden;
    position: relative; }
    .winter-service .second_block:before {
      content: '';
      width: 100%;
      height: 542px;
      background: #f7f7f7;
      position: absolute;
      left: 0;
      bottom: 0; }
    .winter-service .second_block .row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 21px;
      position: relative; }
      @media (max-width: 999px) {
        .winter-service .second_block .row {
          flex-direction: column;
          justify-content: center; } }
      .winter-service .second_block .row .block {
        width: 32%;
        position: relative;
        padding-bottom: 90px;
        text-align: center;
        border: 1px solid #d9d9d9;
        border-top: 6px solid #ce171f;
        background: #ffffff; }
        @media (max-width: 999px) {
          .winter-service .second_block .row .block {
            width: 100%;
            margin-bottom: 30px; } }
        .winter-service .second_block .row .block .image_block {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          flex-direction: column;
          height: 105px;
          margin-bottom: 10px; }
        .winter-service .second_block .row .block .ah3 {
          margin-bottom: 8px;
          padding-left: 20px;
          padding-right: 20px;
          font-size: 24px;
          line-height: 24px;
          font-weight: bold;
          color: #2c2c2c; }
          @media (max-width: 768px) {
            .winter-service .second_block .row .block .ah3 {
              font-size: 22px; } }
        .winter-service .second_block .row .block .price {
          font-size: 20px;
          line-height: 23px;
          color: #124390; }
        .winter-service .second_block .row .block ul {
          border-top: 1px solid #e6e6e6;
          margin-top: 20px;
          margin-bottom: -45px;
          padding: 25px 24px 1px; }
          .winter-service .second_block .row .block ul li {
            padding-left: 17px;
            margin-bottom: 10px;
            font-size: 14px;
            line-height: 22px;
            text-align: left;
            position: relative; }
            .winter-service .second_block .row .block ul li:before {
              content: '';
              width: 7px;
              height: 7px;
              background-color: #ce171f;
              position: absolute;
              left: 0;
              top: 4px; }
        .winter-service .second_block .row .block:first-child {
          padding-top: 45px; }
        .winter-service .second_block .row .block:last-child {
          padding-top: 45px; }
        .winter-service .second_block .row .block__image {
          min-height: 260px;
          align-items: flex-start;
          padding-top: 25px; }
    .winter-service .second_block .row2 {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between; }
      @media (max-width: 999px) {
        .winter-service .second_block .row2 {
          flex-direction: column;
          justify-content: center; } }
      .winter-service .second_block .row2 .block {
        width: 49%;
        height: 198px;
        border: 1px solid #d9d9d9;
        background: #fff;
        position: relative; }
        @media (max-width: 999px) {
          .winter-service .second_block .row2 .block {
            width: 100%;
            margin-bottom: 30px; } }
        @media (max-width: 768px) {
          .winter-service .second_block .row2 .block {
            height: auto; } }
        .winter-service .second_block .row2 .block .image_block {
          float: left;
          height: 198px;
          width: 237px;
          text-align: center; }
          @media (max-width: 768px) {
            .winter-service .second_block .row2 .block .image_block {
              float: none;
              width: auto;
              margin-bottom: 10px; } }
        .winter-service .second_block .row2 .block .text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 198px;
          position: relative;
          overflow: hidden; }
          @media (max-width: 768px) {
            .winter-service .second_block .row2 .block .text {
              align-items: center; } }
          .winter-service .second_block .row2 .block .text .ah3 {
            margin-left: 0;
            margin-right: 20px;
            margin-bottom: 5px;
            line-height: 24px;
            font-weight: bold;
            text-align: left; }
            @media (max-width: 768px) {
              .winter-service .second_block .row2 .block .text .ah3 {
                font-size: 22px;
                margin-right: 0;
                text-align: center;
                padding-left: 20px;
                padding-right: 20px; } }
          .winter-service .second_block .row2 .block .text .price {
            font-size: 20px;
            line-height: 23px;
            color: #124390; }
  .winter-service .reg-and-mile {
    max-width: 755px;
    margin-left: auto;
    margin-right: auto; }
    .winter-service .reg-and-mile .tel-block {
      margin-top: -25px;
      margin-bottom: 25px;
      font-size: 16px;
      line-height: 23px;
      text-align: center; }
    .winter-service .reg-and-mile .tel-block a {
      margin-left: 10px;
      text-decoration: none !important;
      font-size: 20px;
      color: inherit; }
    .winter-service .reg-and-mile .title {
      margin-bottom: 33px;
      font-family: "Vauxhall Neue";
      font-size: 24px;
      font-weight: 700;
      line-height: 33px;
      color: #2c2c2c;
      text-align: center; }
    .winter-service .reg-and-mile .ah2 {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 21px;
      font-weight: 700;
      text-align: center; }
    .winter-service .reg-and-mile .row2 {
      padding-bottom: 15px; }
    .winter-service .reg-and-mile .row2 .input-block {
      display: flex;
      justify-content: space-between;
      max-width: 927px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px; }
      @media (max-width: 768px) {
        .winter-service .reg-and-mile .row2 .input-block {
          flex-wrap: wrap; } }
      .winter-service .reg-and-mile .row2 .input-block .radio-btn {
        display: flex;
        margin: 0 35px; }
        @media (max-width: 768px) {
          .winter-service .reg-and-mile .row2 .input-block .radio-btn {
            margin-bottom: 10px; } }
        .winter-service .reg-and-mile .row2 .input-block .radio-btn input[type=radio] {
          max-width: 15px;
          min-width: 15px;
          width: 100%;
          height: 15px;
          margin: 0;
          margin-right: 8px;
          border: 1px solid #a5a5a5;
          border-radius: 25px;
          cursor: pointer;
          vertical-align: middle;
          outline: 0 !important;
          -webkit-appearance: none;
          -moz-appearance: none; }
        .winter-service .reg-and-mile .row2 .input-block .radio-btn input[type=radio]:checked {
          background: #fff url(../img/radio.svg) no-repeat center center; }
    .winter-service .reg-and-mile .row {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 768px) {
        .winter-service .reg-and-mile .row {
          display: block; } }
      .winter-service .reg-and-mile .row.border-top-row {
        padding-top: 20px;
        border-top: 1px solid #cccccc; }
      .winter-service .reg-and-mile .row.border-row {
        padding-bottom: 22px;
        border-bottom: 1px solid #cccccc; }
        .winter-service .reg-and-mile .row.border-row .input-block:not(.input-standart) {
          width: 300px;
          margin-right: 35px; }
        .winter-service .reg-and-mile .row.border-row .input-block .row {
          margin-bottom: 35px; }
          @media (max-width: 768px) {
            .winter-service .reg-and-mile .row.border-row .input-block .row {
              display: flex; } }
          .winter-service .reg-and-mile .row.border-row .input-block .row .btn {
            align-self: flex-end;
            flex-shrink: 0;
            width: 88px;
            margin-left: 10px; }
            .winter-service .reg-and-mile .row.border-row .input-block .row .btn:before {
              content: '';
              display: inline-block;
              width: 11px;
              height: 17px;
              background: url("../img/map-mark.svg") no-repeat center center;
              background-size: contain;
              margin-top: -2px;
              margin-right: 5px;
              vertical-align: middle; }
            .winter-service .reg-and-mile .row.border-row .input-block .row .btn.no-img:before {
              display: none; }
      .winter-service .reg-and-mile .row .input-block {
        flex-shrink: 0;
        width: calc(50% - 10px);
        position: relative; }
        @media (max-width: 768px) {
          .winter-service .reg-and-mile .row .input-block {
            width: 100%;
            max-width: 100%;
            margin-bottom: 20px; } }
        .winter-service .reg-and-mile .row .input-block .ah1 {
          font-size: 24px;
          line-height: 27px;
          font-weight: 700;
          margin-bottom: 25px; }
        .winter-service .reg-and-mile .row .input-block label {
          line-height: 17px; }
        .winter-service .reg-and-mile .row .input-block label:not(.checkbox-container) {
          display: inline-block;
          margin-bottom: 1rem;
          font-size: 16px;
          color: #2c2c2c;
          vertical-align: top;
          user-select: none; }
        .winter-service .reg-and-mile .row .input-block input:not([type=checkbox]):not([type=radio]) {
          width: 100%;
          padding: 12px 15px;
          border: 1px solid #a5a5a5;
          font-size: 16px;
          line-height: 1em;
          box-sizing: border-box;
          -webkit-appearance: none;
          -moz-appearance: none;
          outline: 0; }
        .winter-service .reg-and-mile .row .input-block.gb-reg-number input {
          padding-left: 57px !important; }
      .winter-service .reg-and-mile .row .form-map {
        width: 100%;
        height: 370px;
        border: 1px solid #cccccc; }
        .winter-service .reg-and-mile .row .form-map iframe {
          display: block;
          width: 100%;
          height: 100%; }
      .winter-service .reg-and-mile .row .input-block.gb-reg-number::before {
        content: '';
        display: block;
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background: url(../img/new-gb-rn.png);
        position: absolute;
        left: 0;
        top: 35px; }
    .winter-service .reg-and-mile .btn--blue {
      width: 100%;
      max-width: 365px;
      margin-top: 39px;
      margin-left: auto;
      margin-right: auto;
      font-size: 18px; }
  .winter-service .table-wrap {
    margin-bottom: 38px; }
    .winter-service .table-wrap table {
      width: 100%;
      border-bottom: 1px solid #e6e6e6; }
      .winter-service .table-wrap table tr:nth-child(even) {
        background: #f7f7f7; }
      .winter-service .table-wrap table td {
        width: 25%;
        padding: 13px 18px;
        border-top: 1px solid #e6e6e6;
        vertical-align: middle; }
        .winter-service .table-wrap table td .ah3 {
          margin-bottom: 5px;
          font-size: 13px;
          line-height: 17px;
          text-align: left;
          color: #9da4b0; }
          .winter-service .table-wrap table td .ah3.red {
            color: #ce181e; }
  .winter-service label {
    display: flex;
    font-family: "Vauxhall Neue";
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #2c2c2c; }
    .winter-service label input {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 9px;
      margin-bottom: 0;
      margin-top: 0;
      border: 1px solid #a5a5a5;
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .winter-service label input:checked {
        background-image: url(../img/checked.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%; }
  .winter-service .disabled-pointer label span.red {
    color: inherit; }
  .winter-service .input-row {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin: 19px auto 0; }
    @media (max-width: 768px) {
      .winter-service .input-row {
        display: block; } }
    .winter-service .input-row .input-block {
      max-width: 48.3%;
      width: 100%; }
      @media (max-width: 768px) {
        .winter-service .input-row .input-block {
          width: 100%;
          max-width: 100%;
          margin-bottom: 20px; } }
  .winter-service .border-block {
    margin-top: 40px;
    padding-top: 25px;
    border-top: 1px solid #d7d7d7; }
  .winter-service.special-offer .ah3 {
    max-width: 750px; }
  .winter-service.special-offer .ah4 {
    text-align: left; }
  .winter-service.special-offer .second_block {
    padding-bottom: 0; }
    .winter-service.special-offer .second_block:before {
      height: 50%;
      bottom: 25%; }
  .winter-service.special-offer .second_block .row {
    align-items: center;
    margin-bottom: 0; }
    .winter-service.special-offer .second_block .row.row-center {
      justify-content: center; }
      .winter-service.special-offer .second_block .row.row-center .block {
        margin-left: 10px;
        margin-right: 10px; }
  .winter-service.special-offer .second_block .row .block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px; }
    .winter-service.special-offer .second_block .row .block > div {
      text-align: left; }
    .winter-service.special-offer .second_block .row .block .image_block {
      justify-content: center;
      align-items: flex-end;
      flex-shrink: 0;
      width: 50%;
      padding-right: 15px;
      margin-bottom: 0;
      box-sizing: border-box; }
      @media (max-width: 999px) {
        .winter-service.special-offer .second_block .row .block .image_block {
          width: auto; } }
    .winter-service.special-offer .second_block .row .block .ah3 {
      padding-left: 0;
      text-align: left; }
    .winter-service.special-offer .second_block .row .block:nth-child(3) .image_block {
      width: 135px;
      max-width: 50%; }
    .winter-service.special-offer .second_block .row .block__image {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 20px;
      padding-right: 20px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      font-size: 20px;
      line-height: 26px;
      color: #fff;
      box-sizing: border-box; }
      .winter-service.special-offer .second_block .row .block__image .ah3 {
        text-transform: uppercase;
        color: #fff; }
  .winter-service.special-offer .reg-and-mile .title {
    font-size: 32px;
    line-height: 35px;
    text-transform: uppercase; }
  .winter-service.arrange-collection .ah1-mini-title {
    font-size: 16px; }
  .winter-service.arrange-collection .ah1-title {
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 500; }
    .winter-service.arrange-collection .ah1-title span.grey {
      display: inline-block;
      font-size: 16px;
      font-weight: 400;
      color: #737373;
      vertical-align: middle; }
    .winter-service.arrange-collection .ah1-title span.mr10 {
      margin-right: 10px; }
  .winter-service.arrange-collection .my_tabs ul {
    margin-bottom: 34px; }
    .winter-service.arrange-collection .my_tabs ul li {
      display: inline-block;
      vertical-align: top; }
      @media (max-width: 768px) {
        .winter-service.arrange-collection .my_tabs ul li {
          display: block; } }
      .winter-service.arrange-collection .my_tabs ul li + li {
        margin-left: 15px; }
        @media (max-width: 768px) {
          .winter-service.arrange-collection .my_tabs ul li + li {
            margin-top: 10px;
            margin-left: 0; } }
      .winter-service.arrange-collection .my_tabs ul li a {
        display: block;
        padding-left: 40px;
        padding-right: 20px;
        border: 1px solid #a5a5a5;
        font-size: 16px;
        line-height: 42px;
        color: #2c2c2c;
        position: relative; }
        @media (max-width: 768px) {
          .winter-service.arrange-collection .my_tabs ul li a {
            font-size: 14px; } }
        .winter-service.arrange-collection .my_tabs ul li a::before {
          content: '';
          width: 20px;
          height: 20px;
          margin-top: -10px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #a9a9a9;
          box-sizing: border-box;
          position: absolute;
          left: 11px;
          top: 50%; }
        .winter-service.arrange-collection .my_tabs ul li a.active {
          background: #ce181f;
          border-color: #ce181f;
          color: #fff; }
          .winter-service.arrange-collection .my_tabs ul li a.active::before {
            border: 5px solid #fff;
            background: #ce181f; }
  .winter-service.arrange-collection .reg-and-mile {
    max-width: 993px; }
    .winter-service.arrange-collection .reg-and-mile .row.no-flex {
      display: block; }
    .winter-service.arrange-collection .reg-and-mile .row.item {
      display: none; }
    .winter-service.arrange-collection .reg-and-mile .row.item.active {
      display: flex; }
      @media (max-width: 768px) {
        .winter-service.arrange-collection .reg-and-mile .row.item.active {
          display: block; } }
    .winter-service.arrange-collection .reg-and-mile .row.item.active.no-flex {
      display: block; }
    .winter-service.arrange-collection .reg-and-mile .row .input-block .ah1 {
      font-size: 23px;
      font-weight: 400; }
  .winter-service.arrange-collection .input-row {
    max-width: 653px;
    margin-left: 0; }
  .winter-service.arrange-collection .ah3.alLeft {
    max-width: none;
    text-align: left;
    margin-top: 40px; }
  .winter-service.arrange-collection .red_num {
    padding-left: 0;
    list-style: none;
    color: #4c4c4c;
    counter-reset: Counter; }
    .winter-service.arrange-collection .red_num li {
      margin-bottom: 12px; }
      .winter-service.arrange-collection .red_num li:before {
        counter-increment: Counter;
        content: counter(Counter) ".";
        display: inline-block;
        margin-right: 16px;
        color: #ce171f;
        font-weight: bold;
        vertical-align: top; }
  .winter-service.arrange-collection .button-width-text {
    display: flex;
    align-items: center;
    padding-top: 30px; }
    @media (max-width: 768px) {
      .winter-service.arrange-collection .button-width-text {
        align-items: flex-start;
        flex-direction: column; } }
    .winter-service.arrange-collection .button-width-text .btn--blue {
      width: 266px;
      margin-top: 0;
      margin-right: 30px;
      flex-shrink: 0; }
      @media (max-width: 768px) {
        .winter-service.arrange-collection .button-width-text .btn--blue {
          margin-right: auto;
          margin-bottom: 15px; } }
    .winter-service.arrange-collection .button-width-text .text {
      width: 100%;
      padding-right: 45px;
      font-size: 15px;
      line-height: 24px;
      box-sizing: border-box; }
      @media (max-width: 768px) {
        .winter-service.arrange-collection .button-width-text .text {
          padding-right: 0;
          text-align: center; } }

.express-store + .footer {
  margin-top: 0; }

.express-store__slide {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center; }

.express-store__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; }

.express-store__title {
  font-size: 50px;
  line-height: 52px;
  font-weight: bold;
  text-align: center;
  color: #fff; }

.express-store__subtitle {
  color: #fff;
  font-size: 24px;
  line-height: 28px;
  text-align: center; }
  .express-store__subtitle--center {
    margin: 8px 0 16px; }

.express-store__row-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.express-store__button {
  margin: 11.5px 11.5px;
  border-radius: 5px; }

.used-stock .page-main-title {
  margin-bottom: 19px; }
  @media (max-width: 999px) {
    .used-stock .page-main-title .title {
      font-size: 28px;
      line-height: 32px; } }
  @media (max-width: 768px) {
    .used-stock .page-main-title .title {
      font-size: 24px;
      line-height: 28px; } }
  @media (max-width: 500px) {
    .used-stock .page-main-title .title {
      font-size: 20px;
      line-height: 24px; } }

.used-stock .inner {
  max-width: 1320px; }

.used-stock .inner3 {
  padding-bottom: 110px; }

.used-stock .breadcrumbs {
  margin-bottom: 20px; }

.used-stock .filter_button {
  display: none; }
  @media (max-width: 999px) {
    .used-stock .filter_button {
      display: block;
      width: 270px;
      margin-bottom: 20px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      color: #fff;
      background-color: #ce181f;
      text-align: center;
      height: 46px;
      line-height: 46px; } }
  @media (max-width: 999px) {
    .used-stock .filter_button.active:before {
      content: 'CLOSE '; } }

.used-stock .flex-block {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }
  @media (max-width: 999px) {
    .used-stock .flex-block {
      display: block; } }
  .used-stock .flex-block .left_side {
    width: 290px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 45px;
    padding-top: 25px;
    border: 1px solid #d9d9d9; }
    @media (max-width: 999px) {
      .used-stock .flex-block .left_side {
        display: none;
        margin-left: auto;
        margin-right: auto; } }
    .used-stock .flex-block .left_side .ah0 {
      float: left;
      margin-bottom: 18px;
      margin-left: 25px;
      font-size: 18px;
      line-height: 19px;
      font-weight: bold;
      color: #2c2c2c; }
      .used-stock .flex-block .left_side .ah0 + .reset {
        margin-right: 25px;
        color: #ce171f;
        text-decoration: underline; }
    .used-stock .flex-block .left_side .reset {
      margin-right: 25px;
      color: #ce171f;
      text-decoration: underline;
      float: right;
      background: transparent;
      font-size: 14px;
      line-height: 19px; }
      @media (min-width: 1024px) {
        .used-stock .flex-block .left_side .reset:hover {
          text-decoration: underline; } }
    .used-stock .flex-block .left_side .alCenter {
      text-align: center; }
      .used-stock .flex-block .left_side .alCenter .reset {
        display: inline-block;
        float: none;
        vertical-align: top;
        color: #ce181f;
        text-decoration: underline;
        margin-right: 0; }
    .used-stock .flex-block .left_side .tabs {
      clear: both;
      margin-bottom: 36px;
      text-align: center;
      border-bottom: 1px solid #ce171f;
      display: flex;
      background: #f6f6f6; }
      .used-stock .flex-block .left_side .tabs a {
        display: block;
        width: auto;
        flex-grow: 1;
        white-space: nowrap;
        padding-right: 6px;
        padding-left: 6px;
        vertical-align: top;
        color: #2c2c2c;
        border: 1px solid transparent;
        border-bottom: 0;
        font-size: 13px;
        line-height: 34px; }
        @media (min-width: 1024px) {
          .used-stock .flex-block .left_side .tabs a:hover {
            border-color: #a5a5a5; } }
        .used-stock .flex-block .left_side .tabs a.active {
          color: #fff;
          background: #ce171f; }
    .used-stock .flex-block .left_side .padding {
      clear: both;
      padding-right: 25px;
      padding-bottom: 30px;
      padding-left: 25px; }
      .used-stock .flex-block .left_side .padding .ah1 {
        clear: both;
        margin-bottom: 7px;
        color: #2c2c2c;
        font-size: 14px;
        line-height: 17px; }
      .used-stock .flex-block .left_side .padding select {
        display: block;
        box-sizing: border-box;
        width: 100%;
        margin-bottom: 20px;
        padding: 10px;
        padding-right: 40px;
        resize: none;
        white-space: nowrap;
        color: #a5a5a5;
        border: 1px solid #a5a5a5;
        border-radius: 3px;
        outline: none;
        background: #FFF url(../img/select.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 14px;
        line-height: 14px;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .used-stock .flex-block .left_side .padding select::-ms-expand {
          display: none; }
        @media (min-width: 1024px) {
          .used-stock .flex-block .left_side .padding select:hover {
            color: #2c2c2c;
            border-color: #2c2c2c;
            background-image: url("../img/select_hov.svg"); } }
        .used-stock .flex-block .left_side .padding select.forsed {
          border-color: #658dcc;
          background-color: #e9f2ff; }
      .used-stock .flex-block .left_side .padding .input_block input {
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 21px;
        padding: 10px;
        color: #a5a5a5;
        border: 1px solid #a5a5a5;
        border-radius: 3px;
        outline: none;
        background: #FFF;
        font-size: 14px;
        line-height: 14px; }
        @media (min-width: 1024px) {
          .used-stock .flex-block .left_side .padding .input_block input:hover {
            color: #2c2c2c;
            border-color: #2c2c2c; } }
        .used-stock .flex-block .left_side .padding .input_block input:focus {
          color: #2c2c2c;
          border-color: #2c2c2c; }
      .used-stock .flex-block .left_side .padding .check_block {
        display: block; }
        .used-stock .flex-block .left_side .padding .check_block input {
          display: none; }
        .used-stock .flex-block .left_side .padding .check_block span {
          position: relative;
          display: inline-block;
          margin-bottom: 10px;
          padding-left: 29px;
          cursor: pointer;
          vertical-align: top;
          font-size: 13px;
          line-height: 20px; }
          @media (min-width: 1024px) {
            .used-stock .flex-block .left_side .padding .check_block span:hover {
              text-decoration: underline; } }
          .used-stock .flex-block .left_side .padding .check_block span:before {
            content: '';
            width: 18px;
            height: 18px;
            border: 1px solid #a7a7a7;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            top: -1px;
            left: 0; }
        .used-stock .flex-block .left_side .padding .check_block input:checked + span:after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #124390;
          position: absolute;
          top: 4px;
          left: 5px; }
      .used-stock .flex-block .left_side .padding .two-inputs {
        display: flex;
        justify-content: space-between; }
        .used-stock .flex-block .left_side .padding .two-inputs select:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
        .used-stock .flex-block .left_side .padding .two-inputs select:last-child {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
        .used-stock .flex-block .left_side .padding .two-inputs input {
          display: block;
          box-sizing: border-box;
          width: 50%;
          margin-bottom: 23px;
          padding: 8px 10px;
          border: 1px solid #a5a5a5;
          font-size: 14px;
          line-height: 17px; }
          .used-stock .flex-block .left_side .padding .two-inputs input + input {
            border-left: 0; }
        .used-stock .flex-block .left_side .padding .two-inputs > * {
          width: 50%;
          box-sizing: border-box; }
      .used-stock .flex-block .left_side .padding .red_button {
        display: block;
        width: 100%;
        height: 35px;
        margin-top: 30px;
        margin-bottom: 8px;
        text-align: center;
        color: #fff;
        background: #ce181f;
        font-weight: 400;
        font-size: 16px;
        line-height: 35px;
        border-radius: 5px; }
        @media (min-width: 1024px) {
          .used-stock .flex-block .left_side .padding .red_button:hover {
            background: #841215; } }
  .used-stock .flex-block .content {
    flex-basis: 100%;
    flex-grow: 0;
    width: 500px; }
    @media (max-width: 999px) {
      .used-stock .flex-block .content {
        width: auto; } }
    .used-stock .flex-block .content .top_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 35px; }
      @media (max-width: 1400px) {
        .used-stock .flex-block .content .top_row {
          flex-direction: column; } }
      @media (max-width: 768px) {
        .used-stock .flex-block .content .top_row {
          display: block;
          text-align: center; } }
      .used-stock .flex-block .content .top_row p {
        width: 29%;
        margin-right: 20px;
        font-size: 16px;
        line-height: 35px; }
        @media (max-width: 1400px) {
          .used-stock .flex-block .content .top_row p {
            width: 100%;
            text-align: center;
            margin-right: 0;
            margin-bottom: 15px; } }
        @media (max-width: 999px) {
          .used-stock .flex-block .content .top_row p {
            margin-top: 20px; } }
      @media (max-width: 768px) {
        .used-stock .flex-block .content .top_row b {
          width: auto;
          margin-bottom: 10px; } }
      .used-stock .flex-block .content .top_row .right_block {
        width: 32%;
        margin-left: 20px;
        text-align: right;
        white-space: nowrap;
        font-size: 14px;
        line-height: 35px; }
        @media (max-width: 1400px) {
          .used-stock .flex-block .content .top_row .right_block {
            width: 100%;
            margin-left: 0;
            margin-top: 15px;
            text-align: center; } }
        @media (max-width: 768px) {
          .used-stock .flex-block .content .top_row .right_block {
            width: auto;
            margin-top: 20px;
            float: none;
            text-align: center; } }
        .used-stock .flex-block .content .top_row .right_block select {
          display: inline-block;
          box-sizing: border-box;
          width: auto;
          margin-left: 8px;
          padding: 9px 40px 9px 10px;
          resize: none;
          vertical-align: top;
          white-space: nowrap;
          color: #a5a5a5;
          border: 1px solid #a5a5a5;
          border-radius: 3px;
          outline: none;
          background: #FFF url(../img/select.svg) no-repeat;
          background-position: calc(100% - 15px) center;
          font-size: 16px;
          line-height: 16px;
          -webkit-appearance: none;
          -moz-appearance: none; }
          @media (min-width: 1024px) {
            .used-stock .flex-block .content .top_row .right_block select:hover {
              color: #2c2c2c;
              border-color: #2c2c2c;
              background-image: url("../img/select_hov.svg"); } }

.used-stock .catalog {
  overflow: unset; }
  .used-stock .catalog .item {
    overflow: unset; }
    .used-stock .catalog .item > div {
      overflow: unset;
      border: 1px solid #e6e6e6; }
    .used-stock .catalog .item .birka {
      position: absolute;
      top: 0;
      right: 0;
      width: 146px;
      max-width: 100%;
      height: 28px;
      padding: 0 5px;
      background: #ce171f;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 13px;
      line-height: unset; }

.used-stock-page {
  padding-bottom: 40px; }
  .used-stock-page .relative_block {
    position: relative; }
    @media (max-width: 987px) {
      .used-stock-page .relative_block {
        display: flex;
        flex-direction: column; } }
    .used-stock-page .relative_block .gal_none {
      display: none; }
    .used-stock-page .relative_block .fancybox {
      width: 40px;
      height: 40px;
      z-index: 1;
      margin-left: -50px;
      background: url("../img/controls/3.svg") no-repeat center center rgba(128, 128, 128, 0.3);
      position: absolute;
      left: 57%;
      top: 439px; }
      @media (min-width: 988px) {
        .used-stock-page .relative_block .fancybox {
          top: 382px; } }
      @media (max-width: 987px) {
        .used-stock-page .relative_block .fancybox {
          left: 100%; } }
      @media (max-width: 500px) {
        .used-stock-page .relative_block .fancybox {
          top: 200px; } }
      @media (min-width: 1024px) {
        .used-stock-page .relative_block .fancybox:hover {
          background-color: rgba(128, 128, 128, 0.5); } }
  .used-stock-page .back_button {
    display: inline-block;
    width: 162px;
    height: 38px;
    margin-right: 10px;
    margin-top: 62px;
    margin-bottom: 37px;
    border: 1px solid #58585a;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    line-height: 36px;
    text-decoration: none !important;
    color: #2c2c2c;
    vertical-align: top;
    text-transform: uppercase;
    clear: both; }
  .used-stock-page .big-images {
    width: 57%;
    height: 488px;
    margin-right: 62px;
    position: relative;
    float: left;
    overflow: hidden; }
    @media (min-width: 988px) {
      .used-stock-page .big-images {
        height: 433px; } }
    .used-stock-page .big-images .owl-prev {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      position: absolute;
      left: 0;
      top: 50%;
      background: url("../img/controls/arrow.svg") no-repeat center center rgba(128, 128, 128, 0.3);
      transform: rotate(180deg);
      cursor: pointer; }
      .used-stock-page .big-images .owl-prev.disabled {
        opacity: 0;
        visibility: hidden; }
      @media (min-width: 1024px) {
        .used-stock-page .big-images .owl-prev:hover {
          background-color: rgba(128, 128, 128, 0.5); } }
    .used-stock-page .big-images .owl-next {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      background: url("../img/controls/arrow.svg") no-repeat center center rgba(128, 128, 128, 0.3);
      position: absolute;
      right: 0;
      top: 50%;
      cursor: pointer; }
      .used-stock-page .big-images .owl-next.disabled {
        opacity: 0;
        visibility: hidden; }
      @media (min-width: 1024px) {
        .used-stock-page .big-images .owl-next:hover {
          background-color: rgba(128, 128, 128, 0.5); } }
    .used-stock-page .big-images .item {
      height: 488px;
      position: relative;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat; }
      @media (min-width: 988px) {
        .used-stock-page .big-images .item {
          height: 433px; } }
      .used-stock-page .big-images .item span {
        position: absolute;
        left: 10px;
        bottom: 7px;
        color: #fff; }
  .used-stock-page .thumbs {
    height: 137px;
    margin-top: 33px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    overflow: hidden;
    position: relative;
    box-sizing: border-box; }
    .used-stock-page .thumbs .owl-prev {
      width: 40px;
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: url("../img/owl.png") no-repeat center center;
      transform: rotate(180deg); }
      .used-stock-page .thumbs .owl-prev.disabled {
        opacity: 0;
        visibility: hidden; }
      @media (min-width: 1024px) {
        .used-stock-page .thumbs .owl-prev:hover {
          background-color: rgba(0, 0, 0, 0.2); } }
    .used-stock-page .thumbs .owl-next {
      width: 40px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background: url("../img/owl.png") no-repeat center center;
      cursor: pointer; }
      .used-stock-page .thumbs .owl-next.disabled {
        opacity: 0;
        visibility: hidden; }
      @media (min-width: 1024px) {
        .used-stock-page .thumbs .owl-next:hover {
          background-color: rgba(0, 0, 0, 0.2); } }
    .used-stock-page .thumbs .item {
      height: 95px;
      background-size: cover;
      background-position: center center;
      position: relative;
      cursor: pointer; }
    .used-stock-page .thumbs .current .item:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      box-sizing: border-box;
      border: 1px solid #ce181f;
      z-index: 2; }
  .used-stock-page .text_block {
    display: flex;
    flex-direction: column;
    max-width: 413px; }
    @media (max-width: 987px) {
      .used-stock-page .text_block {
        flex-direction: row;
        justify-content: space-between;
        max-width: 100%;
        padding: 0; } }
    @media (max-width: 620px) {
      .used-stock-page .text_block {
        flex-direction: column; } }
    .used-stock-page .text_block .model {
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      color: #2c2c2c; }
    .used-stock-page .text_block .type {
      font-size: 16px;
      line-height: 28px;
      font-weight: 400; }
    .used-stock-page .text_block .info {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 22px;
      padding-bottom: 23px; }
      .used-stock-page .text_block .info .pay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 81px;
        padding: 18px 19px;
        background-color: #124390;
        box-sizing: border-box; }
        @media (max-width: 580px) {
          .used-stock-page .text_block .info .pay {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            box-sizing: border-box; } }
        .used-stock-page .text_block .info .pay .price {
          font-size: 23px;
          line-height: 28px;
          font-weight: 400;
          color: #ffffff; }
        .used-stock-page .text_block .info .pay .per_mount {
          font-size: 16px;
          line-height: 28px;
          font-weight: 400;
          color: #ffffff; }
        @media (min-width: 1024px) {
          .used-stock-page .text_block .info .pay:hover {
            background-color: #58585a; } }
      .used-stock-page .text_block .info .attributes {
        margin-left: 33px; }
        @media (max-width: 394px) {
          .used-stock-page .text_block .info .attributes {
            width: 100%;
            margin-left: 0;
            margin-top: 15px; } }
        .used-stock-page .text_block .info .attributes .mileage {
          margin-bottom: 7px;
          font-size: 18px;
          line-height: 28px;
          font-weight: 400; }
        .used-stock-page .text_block .info .attributes .attribute {
          font-size: 14px;
          line-height: 19px;
          font-weight: 400; }
    .used-stock-page .text_block .contacts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 45px 0 40px 0;
      margin-top: 6px;
      border-bottom: 1px solid #e6e6e6;
      border-top: 1px solid #e6e6e6; }
      @media (max-width: 620px) {
        .used-stock-page .text_block .contacts {
          padding: 25px 0 20px 0; } }
      .used-stock-page .text_block .contacts .adress,
      .used-stock-page .text_block .contacts .phone {
        font-size: 18px;
        line-height: 27px;
        font-weight: 400; }
        .used-stock-page .text_block .contacts .adress img,
        .used-stock-page .text_block .contacts .phone img {
          margin-right: 7px; }
      .used-stock-page .text_block .contacts .adress {
        margin-top: -7px;
        padding-bottom: 13px;
        color: #000; }
        @media (max-width: 500px) {
          .used-stock-page .text_block .contacts .adress {
            padding-bottom: 5px; } }
        @media (min-width: 1024px) {
          .used-stock-page .text_block .contacts .adress:hover {
            color: #ce181f; } }
      .used-stock-page .text_block .contacts .phone {
        font-weight: bold; }
    .used-stock-page .text_block .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400; }
      @media (max-width: 1118px) {
        .used-stock-page .text_block .buttons {
          flex-direction: column; } }
      @media (max-width: 987px) {
        .used-stock-page .text_block .buttons {
          justify-content: center; } }
      @media (max-width: 620px) {
        .used-stock-page .text_block .buttons {
          justify-content: space-between;
          flex-direction: row;
          flex-wrap: wrap; } }
      .used-stock-page .text_block .buttons .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 53px;
        color: #2c2c2c;
        cursor: pointer; }
        .used-stock-page .text_block .buttons .button:nth-child(2) {
          padding: 0 36px;
          border-right: 1px solid #e1e1e1;
          border-left: 1px solid #e1e1e1; }
          @media (max-width: 620px) {
            .used-stock-page .text_block .buttons .button:nth-child(2) {
              padding: 0 5px;
              border: none !important;
              flex-basis: 127px; } }
      .used-stock-page .text_block .buttons img {
        margin-right: 10px; }
  .used-stock-page .description {
    margin: 34px 16px 59px 16px; }
    @media (max-width: 620px) {
      .used-stock-page .description {
        margin: 34px 0 59px 0; } }
    .used-stock-page .description h4 {
      margin-bottom: 18px;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      color: #2c2c2c; }
    .used-stock-page .description .desc-text {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      color: #2c2c2c; }
    .used-stock-page .description .parameters {
      display: flex;
      justify-content: space-between;
      margin-top: 57px; }
      @media (max-width: 987px) {
        .used-stock-page .description .parameters {
          flex-wrap: wrap;
          width: 100%; } }
      .used-stock-page .description .parameters .col {
        flex-grow: 1; }
        .used-stock-page .description .parameters .col:nth-child(2) {
          padding: 0 61px;
          margin: 0 61px;
          border-right: 1px solid #e1e1e1;
          border-left: 1px solid #e1e1e1; }
          @media (max-width: 987px) {
            .used-stock-page .description .parameters .col:nth-child(2) {
              width: 100%;
              padding: 0 !important;
              border: none !important;
              margin: 0 !important; } }
        .used-stock-page .description .parameters .col .item {
          display: flex;
          justify-content: space-between; }
          .used-stock-page .description .parameters .col .item .left {
            font-size: 14px;
            line-height: 32px;
            font-weight: 400; }
          .used-stock-page .description .parameters .col .item .right {
            font-size: 14px;
            line-height: 32px;
            font-weight: bold; }
  @media (max-width: 987px) {
    .used-stock-page #sync1 {
      float: none;
      display: flex;
      justify-content: center;
      width: auto;
      margin-right: 0;
      order: -2; } }
  @media (max-width: 620px) {
    .used-stock-page #sync1 {
      margin-bottom: 30px; } }
  @media (max-width: 500px) {
    .used-stock-page #sync1 {
      height: 250px; } }
  @media (max-width: 987px) {
    .used-stock-page #sync2 {
      order: -1;
      margin-bottom: 30px; } }
  @media (max-width: 620px) {
    .used-stock-page #sync2 {
      display: none; } }
  @media (max-width: 500px) {
    .used-stock-page #sync1 .owl-stage .owl-item .item,
    .used-stock-page #sync2 .owl-stage .owl-item .item {
      height: 250px !important; } }
  .used-stock-page .tabs {
    max-width: 100%;
    padding: 0;
    margin: 0 auto; }
    .used-stock-page .tabs > section {
      display: none;
      padding: 15px;
      background: #fff;
      border-top: 1px solid #ddd; }
      .used-stock-page .tabs > section > p {
        margin: 0 0 5px;
        line-height: 1.5;
        color: #383838; }
    .used-stock-page .tabs > input {
      display: none;
      position: absolute; }
      .used-stock-page .tabs > input:checked + label {
        border: 1px solid #e8e8e8;
        background: #fff; }
    .used-stock-page .tabs > label {
      display: inline-block;
      margin: 0 6px -1px;
      padding: 15px 25px;
      border: 1px solid #e8e8e8;
      background-color: #e1e1e1;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold;
      color: #2c2c2c;
      text-align: center; }
      @media (max-width: 706px) {
        .used-stock-page .tabs > label {
          font-size: 14px; } }
      @media (max-width: 595px) {
        .used-stock-page .tabs > label {
          font-size: 14px;
          margin: 7px 3px -1px;
          padding: 5px 10px; } }
      @media (max-width: 400px) {
        .used-stock-page .tabs > label {
          width: 100%;
          margin: 3px 0;
          padding: 0;
          font-size: 14px; } }
      @media (min-width: 1024px) {
        .used-stock-page .tabs > label:hover {
          color: #2c2c2c;
          cursor: pointer; } }
  .used-stock-page #tab1:checked ~ #content-tab1,
  .used-stock-page #tab2:checked ~ #content-tab2,
  .used-stock-page #tab3:checked ~ #content-tab3,
  .used-stock-page #tab4:checked ~ #content-tab4 {
    display: flex; }
  .used-stock-page #content-tab1 {
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 40px; }
    .used-stock-page #content-tab1 .col {
      display: flex;
      flex-direction: column; }
      @media (max-width: 515px) {
        .used-stock-page #content-tab1 .col {
          width: 100%; } }
      .used-stock-page #content-tab1 .col .item {
        margin-bottom: 56px; }
        @media (max-width: 515px) {
          .used-stock-page #content-tab1 .col .item {
            margin-bottom: 15px !important; } }
        .used-stock-page #content-tab1 .col .item .headline {
          font-size: 14px;
          line-height: 22px;
          font-weight: bold; }
        .used-stock-page #content-tab1 .col .item ul {
          list-style: disc; }
          .used-stock-page #content-tab1 .col .item ul li {
            margin-left: 17px;
            font-size: 14px;
            line-height: 22px;
            font-weight: 400; }
  .used-stock-page #content-tab2 {
    flex-wrap: wrap;
    margin: 0 -15px 0 -15px; }
    .used-stock-page #content-tab2 .item {
      max-width: 350px;
      width: 350px;
      margin: 15px; }
      .used-stock-page #content-tab2 .item .headline {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .used-stock-page #content-tab2 .item ul li {
        display: flex;
        justify-content: space-between; }
        .used-stock-page #content-tab2 .item ul li:nth-child(odd) {
          background-color: #f2f2f2; }
        .used-stock-page #content-tab2 .item ul li .left,
        .used-stock-page #content-tab2 .item ul li .right {
          font-size: 16px;
          line-height: 29px;
          font-weight: 400; }
  @media (max-width: 580px) {
    .used-stock-page .finance__block .btn-finance-explained {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      box-sizing: border-box; } }
  .used-stock-page__fixed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 120px;
    padding: 0 170px;
    border-top: 1px solid #124390;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 2;
    box-sizing: border-box; }
    @media (max-width: 999px) {
      .used-stock-page__fixed {
        display: none; } }
    .used-stock-page__fixed .ah1 {
      margin-bottom: 3px;
      font-size: 18px;
      font-weight: 600; }
    .used-stock-page__fixed .ah2 {
      font-size: 24px;
      line-height: 27px;
      font-weight: 600; }
    .used-stock-page__fixed .used-stock-page__fixed-right {
      margin-left: 30px; }
      .used-stock-page__fixed .used-stock-page__fixed-right .btn {
        display: block;
        width: auto;
        padding-left: 20px;
        padding-right: 20px; }

.used-stock-page-2 {
  padding-bottom: 146px; }
  .used-stock-page-2 .page-main-title {
    margin-bottom: 10px; }
  .used-stock-page-2 .top-nav {
    margin: 20px 0;
    padding-left: 8px; }
  .used-stock-page-2 .relative_block {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 987px) {
      .used-stock-page-2 .relative_block .left,
      .used-stock-page-2 .relative_block .text_block {
        width: 100%;
        margin-right: 0; } }
    @media (max-width: 580px) {
      .used-stock-page-2 .relative_block .left .description {
        margin: 18px auto 20px auto; } }
    .used-stock-page-2 .relative_block > .left {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: 59%;
      margin-right: 45px;
      padding-left: 8px;
      position: relative; }
      @media (max-width: 1091px) {
        .used-stock-page-2 .relative_block > .left {
          margin-right: 40px; } }
      @media (max-width: 987px) {
        .used-stock-page-2 .relative_block > .left {
          width: 100%;
          padding-left: 0; } }
      .used-stock-page-2 .relative_block > .left .fancybox {
        width: 38px;
        height: 38px;
        top: 386px;
        left: auto;
        right: 35px;
        z-index: 2;
        background-size: 30px;
        border-radius: 5px; }
        @media (max-width: 987px) {
          .used-stock-page-2 .relative_block > .left .fancybox {
            top: 435px; } }
        @media (max-width: 500px) {
          .used-stock-page-2 .relative_block > .left .fancybox {
            top: 200px;
            right: 47px; } }
  .used-stock-page-2 .big-images {
    width: 100%; }
  .used-stock-page-2 .text_block {
    display: block;
    width: 38%;
    max-width: none; }
    @media (max-width: 987px) {
      .used-stock-page-2 .text_block {
        flex-direction: column; } }
    .used-stock-page-2 .text_block .num {
      font-size: 16px;
      color: #999999; }
      @media (max-width: 767px) {
        .used-stock-page-2 .text_block .num {
          text-align: center; } }
    .used-stock-page-2 .text_block .model {
      margin-bottom: 9px;
      line-height: 36px; }
      @media (max-width: 767px) {
        .used-stock-page-2 .text_block .model {
          font-size: 24px;
          line-height: 34px;
          text-align: center; } }
    .used-stock-page-2 .text_block .short-info {
      font-size: 16px;
      color: #4d4d4d; }
      @media (max-width: 767px) {
        .used-stock-page-2 .text_block .short-info {
          text-align: center; } }
      .used-stock-page-2 .text_block .short-info span.mileage {
        font-size: 18px;
        padding-right: 10px; }
    .used-stock-page-2 .text_block .contacts {
      flex-direction: row;
      align-items: center;
      padding: 14px 0 12px 0;
      margin-top: 20px; }
      @media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
        .used-stock-page-2 .text_block .contacts {
          flex-direction: column; } }
      .used-stock-page-2 .text_block .contacts a {
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #2c2c2c;
        transition: color 0.3s ease; }
        @media (min-width: 1024px) {
          .used-stock-page-2 .text_block .contacts a:hover {
            color: #CE171F; } }
        .used-stock-page-2 .text_block .contacts a + a {
          margin-left: 36px; }
          @media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
            .used-stock-page-2 .text_block .contacts a + a {
              margin-left: 0;
              margin-top: 20px; } }
        .used-stock-page-2 .text_block .contacts a svg {
          margin-right: 8px; }
  .used-stock-page-2 .price {
    margin-top: 29px;
    background-color: #f5f5f5;
    border: 1px solid #e6e6e6;
    border-radius: 3px; }
    .used-stock-page-2 .price__wrapper {
      display: flex;
      align-items: center;
      padding: 13px 15px 13px 38px;
      border-bottom: 1px solid #e6e6e6; }
      @media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
        .used-stock-page-2 .price__wrapper {
          flex-direction: column;
          text-align: center; } }
      .used-stock-page-2 .price__wrapper > p {
        width: 40%; }
        @media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
          .used-stock-page-2 .price__wrapper > p {
            width: 60%; } }
      .used-stock-page-2 .price__wrapper > div {
        width: 60%;
        display: flex;
        align-items: center;
        padding-left: 29px;
        margin-left: 13px;
        border-left: 1px solid #d9d9d9;
        box-sizing: border-box; }
        @media (min-width: 988px) and (max-width: 1279px), (max-width: 767px) {
          .used-stock-page-2 .price__wrapper > div {
            width: 60%;
            justify-content: center;
            padding-left: 0;
            margin-left: 0;
            border-left: none;
            border-top: 1px solid #d9d9d9;
            margin-top: 8px;
            padding-top: 8px;
            text-align: left; } }
      .used-stock-page-2 .price__wrapper p {
        font-size: 40px;
        line-height: 40px; }
        @media (min-width: 988px) and (max-width: 1279px) {
          .used-stock-page-2 .price__wrapper p {
            font-size: 36px;
            line-height: 36px; } }
        @media (max-width: 767px) {
          .used-stock-page-2 .price__wrapper p {
            font-size: 24px;
            line-height: 24px; } }
      .used-stock-page-2 .price__wrapper span {
        display: inline-block;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        padding-left: 10px; }
  .used-stock-page-2 .blue-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 21px 41px 32px; }
    @media (max-width: 517px) {
      .used-stock-page-2 .blue-buttons {
        flex-direction: column; } }
    .used-stock-page-2 .blue-buttons .blue-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 360px;
      background-color: #124390;
      padding: 8.5px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 29.14px;
      text-align: center;
      border-radius: 5px; }
      @media (max-width: 517px) {
        .used-stock-page-2 .blue-buttons .blue-button {
          width: 100%;
          margin: 10px 0; } }
      .used-stock-page-2 .blue-buttons .blue-button + .blue-button {
        margin-top: 14px; }
      @media (min-width: 1024px) {
        .used-stock-page-2 .blue-buttons .blue-button:hover {
          background-color: #58585a; } }
  .used-stock-page-2 .hr {
    height: 0;
    border-top: 1px solid #e1e1e1; }
  .used-stock-page-2 .calculator {
    margin-top: 18px; }
    .used-stock-page-2 .calculator .calc-headline-1 {
      margin-bottom: 6px;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold; }
    .used-stock-page-2 .calculator .radiogroup {
      justify-content: space-between;
      padding-top: 15px; }
      .used-stock-page-2 .calculator .radiogroup .radiogroup__radio {
        width: 49%; }
        .used-stock-page-2 .calculator .radiogroup .radiogroup__radio span {
          width: 100%; }
    .used-stock-page-2 .calculator .inputs {
      margin-bottom: 11px; }
    .used-stock-page-2 .calculator .input {
      display: block;
      width: 100%;
      height: 42px;
      margin-bottom: 20px;
      padding: 0 35px 0 15px;
      border: 1px solid #a5a5a5;
      font-size: 16px;
      line-height: 19px;
      color: #2c2c2c;
      box-sizing: border-box; }
    .used-stock-page-2 .calculator .numericupdown {
      position: relative; }
      .used-stock-page-2 .calculator .numericupdown .up {
        width: 42px;
        height: 100%;
        background: #ce181e url(../img/input-plus.png) no-repeat center center;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer; }
      .used-stock-page-2 .calculator .numericupdown .down {
        width: 42px;
        height: 100%;
        background: #ce181e url(../img/input-minus.png) no-repeat center center;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer; }
      .used-stock-page-2 .calculator .numericupdown .input {
        padding-left: 40px;
        padding-right: 40px;
        text-align: center; }
    .used-stock-page-2 .calculator .button-calc {
      width: 100%;
      margin-top: 15px;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold; }
    .used-stock-page-2 .calculator .radiogroup-v2 {
      display: flex;
      margin: 32px -4px 0; }
      .used-stock-page-2 .calculator .radiogroup-v2__lbl {
        display: flex;
        align-items: center;
        width: calc(33.3333% - 8px);
        height: 46px;
        margin: 0 4px 14px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        box-sizing: border-box; }
        @media (max-width: 1279px) {
          .used-stock-page-2 .calculator .radiogroup-v2__lbl {
            width: calc(50% - 8px); } }
        @media (max-width: 1279px) {
          .used-stock-page-2 .calculator .radiogroup-v2__lbl:nth-child(3n) {
            width: 100%; } }
        .used-stock-page-2 .calculator .radiogroup-v2__lbl span {
          width: 100%;
          border-radius: 5px; }
      .used-stock-page-2 .calculator .radiogroup-v2--car {
        margin: 32px -7.5px 0; }
        .used-stock-page-2 .calculator .radiogroup-v2--car .radiogroup-v2__lbl {
          width: calc(50% - 15px);
          margin: 0 7.5px 14px; }
    .used-stock-page-2 .calculator .check-holyday {
      margin-left: 4px;
      margin-top: 10px; }
    .used-stock-page-2 .calculator .radiogroup--info .radiogroup__radio {
      margin-bottom: 14px;
      margin-left: 0; }
    .used-stock-page-2 .calculator .personalise_remake {
      padding: 0; }
    .used-stock-page-2 .calculator .calc-headline-2 {
      margin-top: 20px;
      font-size: 18px;
      line-height: 28px;
      font-weight: bold; }
  .used-stock-page-2 .personal-table {
    margin-top: 25px; }
    @media (max-width: 987px) {
      .used-stock-page-2 .personal-table {
        margin-top: 0; } }
    .used-stock-page-2 .personal-table__title {
      height: 40px;
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
      font-weight: 700; }
    .used-stock-page-2 .personal-table__table-row:nth-child(2n+1) {
      background: #f2f2f2; }
    .used-stock-page-2 .personal-table__table-cell--w30 {
      font-weight: 400; }
  .used-stock-page-2 .blue-buttons_long {
    margin-top: 31px; }
    .used-stock-page-2 .blue-buttons_long .blue-button {
      width: 49%; }
  .used-stock-page-2 .block-wrapper + .block-wrapper {
    margin-top: 25px; }
  .used-stock-page-2 .block-title {
    margin-bottom: 15px;
    font-size: 24px; }
    @media (max-width: 987px) {
      .used-stock-page-2 .block-title {
        text-align: center; } }
  .used-stock-page-2 .block-subtitle {
    font-size: 16px;
    line-height: 26px; }
    @media (max-width: 987px) {
      .used-stock-page-2 .block-subtitle {
        text-align: center; } }
  .used-stock-page-2 .description {
    width: 100%;
    margin: 35px 0 0; }
    @media (max-width: 987px) {
      .used-stock-page-2 .description {
        max-width: 700px;
        margin: 35px auto 59px auto; } }
    @media (max-width: 580px) {
      .used-stock-page-2 .description + .finance__block {
        margin-bottom: 20px; } }
    .used-stock-page-2 .description__tabs {
      display: flex;
      border-bottom: 1px solid #ce171f; }
    .used-stock-page-2 .description__tab {
      padding: 3px 20px;
      font-size: 18px;
      line-height: 28px;
      color: #4d4d4d;
      cursor: pointer;
      transition: all 0.3s ease; }
      .used-stock-page-2 .description__tab.active {
        border-radius: 5px 5px 0 0;
        background-color: #ce171f;
        color: #fff; }
    .used-stock-page-2 .description__content {
      padding-top: 27px; }
    .used-stock-page-2 .description__content-item {
      display: none; }
    .used-stock-page-2 .description__table {
      width: 100%;
      border-collapse: collapse; }
      .used-stock-page-2 .description__table tr + tr {
        border-top: 1px solid #e6e6e6; }
      .used-stock-page-2 .description__table td {
        font-size: 18px;
        padding: 8.7px 0;
        vertical-align: middle; }
        @media (max-width: 767px) {
          .used-stock-page-2 .description__table td {
            font-size: 16px; } }
        .used-stock-page-2 .description__table td:first-child {
          padding-left: 10px; }
        .used-stock-page-2 .description__table td:last-child {
          padding-right: 14px;
          text-align: right; }
    @media (max-width: 517px) {
      .used-stock-page-2 .description .picture-parameters {
        flex-wrap: wrap;
        justify-content: center; } }
    @media (max-width: 517px) {
      .used-stock-page-2 .description .picture-parameters .item {
        margin-right: 15px; } }
    @media (max-width: 580px) {
      .used-stock-page-2 .description .features {
        display: block; } }
    @media (max-width: 580px) {
      .used-stock-page-2 .description .features .left,
      .used-stock-page-2 .description .features .right {
        width: 100% !important;
        margin-right: 0;
        padding-right: 0; } }
  .used-stock-page-2 .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      .used-stock-page-2 .list {
        margin: 0; } }
    .used-stock-page-2 .list__col {
      width: calc(50% - 20px);
      margin: 0 10px; }
      @media (max-width: 767px) {
        .used-stock-page-2 .list__col {
          width: 100%;
          margin: 0; } }
      @media (max-width: 767px) {
        .used-stock-page-2 .list__col + .list__col {
          margin-top: 49px; } }
    .used-stock-page-2 .list__block + .list__block {
      margin-top: 49px; }
    .used-stock-page-2 .list__title {
      font-size: 18px;
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 1px solid #e6e6e6; }
    .used-stock-page-2 .list ul li {
      position: relative;
      padding-left: 15px;
      font-size: 14px;
      line-height: 21px; }
      .used-stock-page-2 .list ul li + li {
        margin-top: 13px; }
      .used-stock-page-2 .list ul li::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #ce171f;
        position: absolute;
        top: 8px;
        left: 1px; }
  .used-stock-page-2 .finance__block {
    width: 143%; }
  .used-stock-page-2 .finance-calc .personalise__ext {
    margin: 0; }
  .used-stock-page-2 .finance-calc .btn-finance-explained {
    display: block;
    width: 236px;
    height: 30px;
    font-size: 14px;
    line-height: 18px;
    margin-left: auto;
    text-transform: capitalize;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .used-stock-page-2 .finance-calc .btn-finance-explained {
        margin-right: auto; } }
  .used-stock-page-2 .finance-calc .btn--build {
    width: 100%;
    max-width: 432px;
    border-radius: 5px;
    font-size: 16px;
    text-transform: uppercase;
    margin: 20px auto 0; }
  .used-stock-page-2 .vehInfo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 38px;
    margin-top: 62px;
    margin-bottom: 37px;
    border: 1px solid #58585a;
    font-size: 14px;
    line-height: 28px;
    font-weight: bold;
    color: #2c2c2c;
    text-transform: uppercase; }
  .used-stock-page-2 #sync1 {
    border-bottom: none;
    width: 96.5%;
    overflow: visible;
    padding-bottom: 20px; }
    @media (max-width: 987px) {
      .used-stock-page-2 #sync1 {
        padding-left: 15px; } }
    @media (max-width: 900px) {
      .used-stock-page-2 #sync1 {
        padding-bottom: 20px !important; } }
    @media (max-width: 767px) {
      .used-stock-page-2 #sync1 {
        width: 95%; } }
    @media (max-width: 500px) {
      .used-stock-page-2 #sync1 {
        width: 90%; } }
    .used-stock-page-2 #sync1:not(.owl-loaded) {
      display: flex;
      flex-wrap: nowrap;
      opacity: 0; }
    .used-stock-page-2 #sync1 .owl-stage-outer {
      z-index: 1; }
    .used-stock-page-2 #sync1 .owl-nav {
      width: calc(100% + 40px);
      margin: 0 -20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 987px) {
        .used-stock-page-2 #sync1 .owl-nav {
          width: calc(100% + 25px);
          margin: 0; } }
    .used-stock-page-2 #sync1 .owl-prev,
    .used-stock-page-2 #sync1 .owl-next {
      width: 20px;
      height: 36px;
      background-color: #CE171F;
      border-radius: 0 5px 5px 0;
      margin-top: -17px;
      position: absolute;
      top: 0; }
      @media (max-width: 900px) {
        .used-stock-page-2 #sync1 .owl-prev,
        .used-stock-page-2 #sync1 .owl-next {
          height: 40px;
          border-radius: 0; } }
    .used-stock-page-2 #sync1 .owl-prev {
      left: 0; }
      @media (max-width: 900px) {
        .used-stock-page-2 #sync1 .owl-prev {
          left: 0 !important; } }
    .used-stock-page-2 #sync1 .owl-next {
      right: 1px; }
  .used-stock-page-2 #sync2 {
    width: 100%;
    height: 120px;
    padding: 15px 25px;
    margin: 0 auto;
    overflow: visible; }
    .used-stock-page-2 #sync2:not(.owl-loaded) {
      display: flex;
      flex-wrap: nowrap;
      opacity: 0; }
    .used-stock-page-2 #sync2 .owl-nav {
      display: block;
      position: absolute;
      left: -15px;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% + 23px); }
      @media (max-width: 987px) {
        .used-stock-page-2 #sync2 .owl-nav {
          width: 100%;
          left: 0; } }
    .used-stock-page-2 #sync2 .owl-prev,
    .used-stock-page-2 #sync2 .owl-next {
      width: 40px;
      height: 50px;
      background: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0; }
      .used-stock-page-2 #sync2 .owl-prev svg,
      .used-stock-page-2 #sync2 .owl-next svg {
        width: 100%;
        height: 100%;
        fill: #ce171f; }
    .used-stock-page-2 #sync2 .owl-prev {
      left: 0; }
      @media (max-width: 987px) {
        .used-stock-page-2 #sync2 .owl-prev {
          left: -12px !important; } }
    .used-stock-page-2 #sync2 .owl-next {
      right: 0; }
      @media (max-width: 987px) {
        .used-stock-page-2 #sync2 .owl-next {
          right: -12px; } }
    .used-stock-page-2 #sync2 .item {
      height: 88px;
      border: 1px solid #e6e6e6;
      box-sizing: border-box; }
  @media (max-width: 517px) {
    .used-stock-page-2 #sync3 {
      height: auto !important; } }
  .used-stock-page-2 .car-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    margin-top: 15px; }
    @media (max-width: 900px) {
      .used-stock-page-2 .car-info {
        flex-direction: row; } }
    .used-stock-page-2 .car-info__option-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
      @media (max-width: 987px) {
        .used-stock-page-2 .car-info__option-item {
          width: 48%; } }
      .used-stock-page-2 .car-info__option-item + .car-info__option-item {
        margin-left: 10px; }
        @media (max-width: 987px) {
          .used-stock-page-2 .car-info__option-item + .car-info__option-item {
            margin-left: 0; } }
    .used-stock-page-2 .car-info__text {
      margin-left: 10px; }
      .used-stock-page-2 .car-info__text p:first-child {
        font-size: 13px;
        line-height: 18px;
        color: #999999; }
      .used-stock-page-2 .car-info__text p:last-child {
        font-size: 15px;
        line-height: 18px;
        color: #2c2c2c; }
  .used-stock-page-2 .birka {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    max-width: 100%;
    height: auto;
    padding: 8px;
    background: #ce171f;
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 3.5%;
    z-index: 1000;
    box-sizing: border-box; }
    @media (max-width: 987px) {
      .used-stock-page-2 .birka {
        font-size: 14px;
        line-height: 24px;
        padding: 5px;
        right: auto;
        left: 20px; } }

.catalog {
  position: relative;
  overflow: hidden; }
  .catalog .row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -14px; }
    @media (max-width: 768px) {
      .catalog .row {
        margin: 0; } }
    .catalog .row .item {
      width: calc(33.33333% - 28px);
      margin: 0 14px 25px;
      box-sizing: border-box; }
      @media (min-width: 1000px) and (max-width: 1400px) {
        .catalog .row .item {
          width: calc(50% - 28px); } }
      @media (max-width: 900px) {
        .catalog .row .item {
          width: calc(50% - 28px); } }
      @media (max-width: 768px) {
        .catalog .row .item {
          width: 100%;
          margin: 0 0 25px; } }
      .catalog .row .item > div {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        height: 100%; }
        @media (min-width: 1024px) {
          .catalog .row .item > div:hover .gray_dark {
            background: #eae9e9; } }
        .catalog .row .item > div .num {
          font-size: 14px;
          position: absolute;
          top: 6px;
          left: 21px;
          z-index: 1; }
        .catalog .row .item > div .image_block {
          position: relative;
          height: 205px;
          background-color: #eeeeee;
          background-position: center center;
          background-size: cover; }
          @media (max-width: 767px) {
            .catalog .row .item > div .image_block {
              height: 400px; } }
          @media (max-width: 500px) {
            .catalog .row .item > div .image_block {
              height: 250px; } }
        .catalog .row .item > div .gray_light {
          position: relative;
          overflow: hidden;
          box-sizing: border-box;
          padding: 12px 20px 0; }
          .catalog .row .item > div .gray_light .ah1 {
            display: block;
            overflow: hidden;
            margin-bottom: 3px;
            color: #2c2c2c;
            font-weight: bold;
            font-size: 18px;
            line-height: 20px; }
          .catalog .row .item > div .gray_light .text {
            width: 100%;
            font-size: 13px;
            line-height: 24px;
            color: #4d4d4d;
            margin-bottom: 9px; }
        .catalog .row .item > div .bottom {
          margin-top: auto; }
          .catalog .row .item > div .bottom .flex-block {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: center;
            padding: 0 20px; }
            @media (min-width: 901px) and (max-width: 1400px) {
              .catalog .row .item > div .bottom .flex-block {
                flex-direction: column;
                align-items: center; } }
            .catalog .row .item > div .bottom .flex-block .price-block {
              display: flex;
              align-items: flex-end;
              box-sizing: border-box;
              margin-bottom: 13px;
              min-height: 24px; }
              @media (min-width: 901px) and (max-width: 1400px) {
                .catalog .row .item > div .bottom .flex-block .price-block {
                  justify-content: center; } }
              .catalog .row .item > div .bottom .flex-block .price-block + .price-block {
                padding-left: 20px;
                margin-left: 20px;
                border-left: 1px solid #999999; }
                @media (min-width: 901px) and (max-width: 1400px) {
                  .catalog .row .item > div .bottom .flex-block .price-block + .price-block {
                    border-left: none;
                    padding-left: 0;
                    margin-left: 0;
                    border-top: 1px solid #999999;
                    margin-top: 10px;
                    padding-top: 10px;
                    text-align: left; } }
              .catalog .row .item > div .bottom .flex-block .price-block--vat p {
                font-size: 14px;
                line-height: 11px;
                white-space: nowrap; }
            .catalog .row .item > div .bottom .flex-block p {
              font-size: 28px;
              line-height: 24px; }
            .catalog .row .item > div .bottom .flex-block span {
              display: inline-block;
              font-size: 12px;
              line-height: 11px;
              padding-left: 5px; }
              @media (min-width: 1000px) and (max-width: 1400px) {
                .catalog .row .item > div .bottom .flex-block span {
                  text-align: left; } }
            .catalog .row .item > div .bottom .flex-block--van .price-block + .price-block {
              padding-left: 8px;
              margin-left: 8px; }
              @media (min-width: 901px) and (max-width: 1400px) {
                .catalog .row .item > div .bottom .flex-block--van .price-block + .price-block {
                  padding-left: 0;
                  margin-left: 0; } }
            .catalog .row .item > div .bottom .flex-block--van p {
              font-size: 24px; }
          .catalog .row .item > div .bottom .btn {
            width: calc(100% - 40px);
            height: 40px;
            border-radius: 5px;
            font-size: 16px;
            line-height: 40px;
            margin: 0 20px 13px; }
        .catalog .row .item > div .gray_dark {
          display: flex;
          justify-content: space-between;
          padding: 2px 14px 2px 20px;
          background-color: #f6f6f6;
          line-height: 24px;
          transition: background-color 0.3s ease;
          margin-top: auto; }
          .catalog .row .item > div .gray_dark .left {
            overflow: hidden;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #2c2c2c;
            font-size: 11px; }
            @media (min-width: 1024px) {
              .catalog .row .item > div .gray_dark .left:hover {
                text-decoration: underline; } }
            .catalog .row .item > div .gray_dark .left:before {
              display: inline-block;
              width: 9px;
              height: 12px;
              margin-top: -3px;
              margin-right: 4px;
              content: '';
              vertical-align: middle;
              background: url("../img/mark.png") no-repeat; }
          .catalog .row .item > div .gray_dark .call {
            display: flex;
            align-items: center;
            margin-left: 10px;
            font-size: 13px;
            color: #2c2c2c; }
            .catalog .row .item > div .gray_dark .call svg {
              width: 15px;
              height: 15px;
              fill: #808080;
              margin-right: 4px; }
  .catalog + .pagi {
    margin-bottom: 40px;
    padding-top: 10px; }

.manager-appraisal-page + .footer {
  background-color: #f6f6f6; }

.manager-appraisal-page .form-block {
  margin-top: 60px;
  padding-bottom: 60px; }
  .manager-appraisal-page .form-block .first-block,
  .manager-appraisal-page .form-block .second-block {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .manager-appraisal-page .form-block .first-block,
      .manager-appraisal-page .form-block .second-block {
        display: block; } }
    .manager-appraisal-page .form-block .first-block.first-block .rows,
    .manager-appraisal-page .form-block .second-block.first-block .rows {
      border-left: 5px solid #ebebeb;
      background-color: #fafafa; }
    .manager-appraisal-page .form-block .first-block.second-block,
    .manager-appraisal-page .form-block .second-block.second-block {
      margin-top: 55px; }
      .manager-appraisal-page .form-block .first-block.second-block .rows,
      .manager-appraisal-page .form-block .second-block.second-block .rows {
        border-left: 5px solid #ebebeb;
        background-color: #f3f3f3; }
    @media (max-width: 768px) {
      .manager-appraisal-page .form-block .first-block .rights,
      .manager-appraisal-page .form-block .second-block .rights {
        margin-top: 49px; } }
    .manager-appraisal-page .form-block .first-block .rights .rows + .rows,
    .manager-appraisal-page .form-block .second-block .rights .rows + .rows {
      margin-top: 49px; }
  .manager-appraisal-page .form-block .lefts,
  .manager-appraisal-page .form-block .rights {
    width: 49%; }
    @media (max-width: 768px) {
      .manager-appraisal-page .form-block .lefts,
      .manager-appraisal-page .form-block .rights {
        width: 100%; } }
  .manager-appraisal-page .form-block .rows {
    padding: 10px 15px;
    box-sizing: border-box; }
    .manager-appraisal-page .form-block .rows .item {
      display: flex;
      justify-content: space-between; }
      .manager-appraisal-page .form-block .rows .item + .item {
        margin-top: 10px; }
      .manager-appraisal-page .form-block .rows .item .label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 190px;
        min-width: 190px;
        font-size: 16px;
        font-weight: bold; }
      .manager-appraisal-page .form-block .rows .item .input-block {
        width: calc(100% - 190px); }
        .manager-appraisal-page .form-block .rows .item .input-block input {
          width: 100%;
          padding: 0 0 5px;
          border: none;
          border-bottom: 1px solid #a5a5a5;
          background-color: transparent;
          font-size: 16px; }
  .manager-appraisal-page .form-block .textarea-block {
    margin-top: 44px; }
    .manager-appraisal-page .form-block .textarea-block label {
      font-weight: bold; }
  .manager-appraisal-page .form-block .btn-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 700px) {
      .manager-appraisal-page .form-block .btn-row {
        flex-direction: column;
        align-items: center; } }
    .manager-appraisal-page .form-block .btn-row .btn--blue {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 260px;
      height: 46px;
      margin-top: 59px;
      font-family: 'Myriad Pro', 'Vauxhall Neue', sans-serif;
      font-size: 18px; }
      @media (max-width: 700px) {
        .manager-appraisal-page .form-block .btn-row .btn--blue {
          margin-top: 30px; } }
    .manager-appraisal-page .form-block .btn-row .btn-back {
      width: 190px; }
      .manager-appraisal-page .form-block .btn-row .btn-back img {
        margin-right: 17px;
        vertical-align: middle; }

.manager-appraisal-page .images-row {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-top: -10px; }
  .manager-appraisal-page .images-row .image-block {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 75px;
    border: 1px solid #a5a5a5;
    flex: 1 0 50px;
    margin-left: 10px;
    margin-top: 10px; }
    @media (min-width: 120px) {
      .manager-appraisal-page .images-row .image-block {
        max-width: calc(50% - 10px); } }
    @media (min-width: 180px) {
      .manager-appraisal-page .images-row .image-block {
        max-width: calc(33.33333% - 10px); } }
    @media (min-width: 240px) {
      .manager-appraisal-page .images-row .image-block {
        max-width: calc(25% - 10px); } }
    @media (min-width: 200px) {
      .manager-appraisal-page .images-row .image-block {
        min-width: calc(25% - 10px); } }
    .manager-appraisal-page .images-row .image-block img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain; }

.build-your-deal {
  padding-bottom: 100px; }
  @media (max-width: 614px) {
    .build-your-deal > div.inner2 > div > div > div.block.choose_block > div:nth-child(1) > button:nth-child(2) {
      width: 100%;
      margin-right: 0; }
    .build-your-deal > div.inner2 > div > div > div.block.choose_block > div:nth-child(1) > button:nth-child(3) {
      width: 100%;
      margin: 10px 0 10px 0; } }
  .build-your-deal__top-nav {
    margin: 20px 0 0; }
  .build-your-deal .page-main-title {
    height: 100px; }
  .build-your-deal .ah2 {
    margin-bottom: 20px;
    font-size: 18px;
    line-height: 21px; }
  .build-your-deal label {
    display: inline-block;
    margin-bottom: .5rem;
    cursor: pointer;
    user-select: none;
    vertical-align: top;
    font-size: 16px; }
    .build-your-deal label span {
      font-size: 14px;
      color: #666666; }
  .build-your-deal input[type=checkbox] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    border: 1px solid #A5A5A5;
    background-color: #FFF;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .build-your-deal input[type=checkbox]::-ms-check {
      display: none; }
    .build-your-deal input[type=checkbox]:checked {
      background-image: url(../img/checked.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%; }
  .build-your-deal input[type="text"] {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 7px 15px;
    border: 1px solid #A5A5A5;
    outline: none;
    font-size: 16px;
    line-height: 1em; }
    .build-your-deal input[type="text"].gb-reg-number {
      padding-left: 15px;
      text-transform: uppercase;
      background-size: contain; }
    .build-your-deal input[type="text"].datepicker-input {
      box-sizing: border-box;
      width: 100%;
      height: 40px;
      padding: 10px 15px;
      resize: none;
      border: 1px solid #a5a5a5;
      border-radius: 0;
      outline: 0;
      background: #fff url(../img/datepicker.png) no-repeat;
      background-position: calc(100% - 9px) center;
      font-size: 16px;
      line-height: 1em;
      -webkit-appearance: none;
      -moz-appearance: none; }
  .build-your-deal select {
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    margin-bottom: 8px;
    padding: 8.5px 15px;
    resize: none;
    border: 1px solid #a5a5a5;
    border-radius: 0;
    outline: 0;
    background: #fff url(../img/select_hov.svg) no-repeat;
    background-position: calc(100% - 15px) center;
    font-size: 16px;
    line-height: 1em;
    -webkit-appearance: none;
    -moz-appearance: none; }
    .build-your-deal select::-ms-expand {
      display: none; }
  .build-your-deal .btn_blue {
    width: 100%;
    background: #124390;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff; }
    @media (min-width: 1024px) {
      .build-your-deal .btn_blue:hover {
        color: #FFF;
        background: #2C2C2C; } }
  .build-your-deal .btn_red {
    text-transform: uppercase;
    color: #fff;
    background: #ce181f;
    font-weight: 600;
    font-size: 16px; }
    @media (min-width: 1024px) {
      .build-your-deal .btn_red:hover {
        background: #2c2c2c; } }
  .build-your-deal .flex-block {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding-top: 31px; }
    @media (max-width: 1100px) {
      .build-your-deal .flex-block {
        flex-direction: column; } }
    @media (max-width: 1100px) {
      .build-your-deal .flex-block h3,
      .build-your-deal .flex-block .ah2,
      .build-your-deal .flex-block .ah3 {
        text-align: center; } }
    .build-your-deal .flex-block .sidebar {
      flex-shrink: 0;
      width: 350px;
      margin-left: 40px; }
      @media (max-width: 1100px) {
        .build-your-deal .flex-block .sidebar {
          width: 100%;
          margin-top: 25px;
          margin-left: 0; } }
      .build-your-deal .flex-block .sidebar .side-wrap {
        width: inherit;
        position: sticky;
        top: 100px; }
        @media (max-width: 1100px) {
          .build-your-deal .flex-block .sidebar .side-wrap {
            position: relative;
            top: 0; } }
      .build-your-deal .flex-block .sidebar .card {
        margin-bottom: 25px;
        background-color: #f7f7f7;
        border: 1px solid #d9d9d9; }
        .build-your-deal .flex-block .sidebar .card .card-header {
          margin-bottom: 0;
          padding: 34px 35px 14px;
          border-bottom: none;
          border-radius: 0;
          background: transparent;
          font-size: 18px;
          font-weight: bold; }
        .build-your-deal .flex-block .sidebar .card .card-body {
          padding: 0 35px 35px; }
          .build-your-deal .flex-block .sidebar .card .card-body p {
            max-width: 240px;
            margin-bottom: 20px;
            font-size: 14px;
            line-height: 22px; }
        .build-your-deal .flex-block .sidebar .card table {
          width: 100%; }
          .build-your-deal .flex-block .sidebar .card table td {
            padding: 5px 0;
            font-size: 14px; }
            .build-your-deal .flex-block .sidebar .card table td:last-child {
              width: 70px;
              text-align: right;
              font-weight: 700; }
        .build-your-deal .flex-block .sidebar .card .ah1 {
          font-size: 13px;
          line-height: 16px;
          color: #757575; }
        .build-your-deal .flex-block .sidebar .card .total {
          margin-top: 5px;
          padding-top: 5px;
          border-top: 1px solid #d7d7d7; }
      .build-your-deal .flex-block .sidebar.sidebar--speco .card .total {
        margin-top: 12px;
        padding-top: 15px; }
    .build-your-deal .flex-block .content {
      width: 100%; }
      .build-your-deal .flex-block .content .title {
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 33px;
        font-weight: 400; }
      .build-your-deal .flex-block .content .block {
        border-bottom: 2px solid #e6e6e6; }
        .build-your-deal .flex-block .content .block.byd-payment {
          border-bottom: none; }
          .build-your-deal .flex-block .content .block.byd-payment .byd-payment__ch-p-m {
            margin-bottom: 6px; }
          .build-your-deal .flex-block .content .block.byd-payment .ah2 {
            margin-bottom: 13px; }
          .build-your-deal .flex-block .content .block.byd-payment .total-amount {
            font-size: 16px;
            margin-bottom: 15px; }
            .build-your-deal .flex-block .content .block.byd-payment .total-amount span {
              font-size: 24px;
              line-height: 28px;
              font-weight: bold; }
          .build-your-deal .flex-block .content .block.byd-payment .radiogroup-v2 {
            margin: 0 0 33px 0; }
            .build-your-deal .flex-block .content .block.byd-payment .radiogroup-v2 label {
              width: 150px;
              margin: 0; }
              .build-your-deal .flex-block .content .block.byd-payment .radiogroup-v2 label + label {
                margin-left: 10px; }
              .build-your-deal .flex-block .content .block.byd-payment .radiogroup-v2 label span {
                border-radius: 5px; }
          .build-your-deal .flex-block .content .block.byd-payment .calc-new__bottom {
            margin: 18px 0 0; }
          .build-your-deal .flex-block .content .block.byd-payment .calc-new .check-holyday {
            margin: 0 0 0 5px; }
        .build-your-deal .flex-block .content .block .err_block {
          margin-bottom: 30px;
          font-size: 18px;
          line-height: 24px; }
          .build-your-deal .flex-block .content .block .err_block p {
            margin-bottom: 13px; }
          .build-your-deal .flex-block .content .block .err_block .inn_err_block {
            padding: 30px 45px 35px;
            border: 2px solid #e6e6e6; }
            @media (max-width: 614px) {
              .build-your-deal .flex-block .content .block .err_block .inn_err_block {
                padding: 20px; } }
          .build-your-deal .flex-block .content .block .err_block .ah1 {
            margin-bottom: 13px;
            font-size: 30px;
            line-height: 33px;
            font-weight: bold; }
          .build-your-deal .flex-block .content .block .err_block .tel {
            display: inline-block;
            padding-bottom: 6px;
            vertical-align: top;
            font-weight: bold;
            font-size: 22px;
            line-height: 25px;
            color: #2C2C2C; }
            .build-your-deal .flex-block .content .block .err_block .tel:before {
              content: '';
              display: inline-block;
              width: 19px;
              height: 18px;
              margin-top: -4px;
              margin-right: 8px;
              vertical-align: middle;
              background: url("../img/err.svg") no-repeat;
              background-size: auto 100%; }
          .build-your-deal .flex-block .content .block .err_block .btn_red {
            width: auto;
            margin-right: 25px;
            padding-right: 42px;
            padding-left: 42px; }
            @media (max-width: 614px) {
              .build-your-deal .flex-block .content .block .err_block .btn_red {
                padding-right: 10px;
                padding-left: 10px; } }
        .build-your-deal .flex-block .content .block .titl {
          font-size: 24px;
          line-height: 57px;
          font-weight: 600;
          color: #a6a6a6;
          cursor: pointer; }
        .build-your-deal .flex-block .content .block.active {
          padding-bottom: 25px;
          margin-bottom: 25px; }
          .build-your-deal .flex-block .content .block.active .titl {
            color: #2c2c2c; }
        .build-your-deal .flex-block .content .block.choose_block {
          padding-bottom: 50px; }
          .build-your-deal .flex-block .content .block.choose_block .btn_red {
            display: inline-block;
            width: 265px;
            margin-right: 21px;
            vertical-align: top; }
          .build-your-deal .flex-block .content .block.choose_block p {
            max-width: 690px;
            margin-bottom: 25px;
            font-size: 16px;
            line-height: 24px; }
          .build-your-deal .flex-block .content .block.choose_block .car_block {
            display: flex;
            justify-content: space-between;
            margin-top: 15px; }
            @media (max-width: 614px) {
              .build-your-deal .flex-block .content .block.choose_block .car_block {
                display: block; } }
            .build-your-deal .flex-block .content .block.choose_block .car_block .image_block {
              flex-shrink: 0;
              width: 41.8%;
              background-position: center center;
              background-size: cover; }
              @media (max-width: 614px) {
                .build-your-deal .flex-block .content .block.choose_block .car_block .image_block {
                  width: 100%;
                  height: 178px;
                  margin: 0; } }
            .build-your-deal .flex-block .content .block.choose_block .car_block .text_block {
              position: relative;
              width: 100%;
              padding: 25px 25px 35px 30px;
              background: #f7f7f7; }
              @media (max-width: 614px) {
                .build-your-deal .flex-block .content .block.choose_block .car_block .text_block {
                  max-width: calc(100% - 55px); } }
              .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .change {
                position: absolute;
                top: 28px;
                right: 25px;
                cursor: pointer;
                text-decoration: underline;
                color: #ce171f; }
                .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .change:before {
                  content: '';
                  display: inline-block;
                  width: 11px;
                  height: 11px;
                  margin-right: 4px;
                  vertical-align: middle;
                  background: url("../img/red_x.svg") no-repeat center center;
                  background-size: 11px auto; }
                @media (min-width: 1024px) {
                  .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .change:hover {
                    text-decoration: none; } }
              .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .min-height {
                min-height: 105px;
                margin-right: 110px; }
                @media (max-width: 450px) {
                  .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .min-height {
                    min-height: unset;
                    margin-top: 42px;
                    margin-right: 0;
                    padding-bottom: 23px; } }
                .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .min-height .ah1 {
                  margin-bottom: 4px;
                  font-size: 18px;
                  line-height: 21px;
                  font-weight: bold; }
              .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .fsz13 {
                font-size: 13px;
                line-height: 24px; }
              .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex {
                display: flex;
                justify-content: space-between; }
                @media (max-width: 450px) {
                  .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex {
                    flex-direction: column; } }
                .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex li {
                  display: inline-block;
                  vertical-align: top; }
                  .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex li + li:before {
                    content: '';
                    display: inline-block;
                    width: 4px;
                    height: 4px;
                    margin-top: -3px;
                    margin-right: 6px;
                    margin-left: 3px;
                    vertical-align: middle;
                    border-radius: 50%;
                    background: #2c2c2c; }
                .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex .price {
                  margin-left: 30px;
                  white-space: nowrap;
                  font-weight: bold;
                  font-size: 24px; }
                  @media (max-width: 450px) {
                    .build-your-deal .flex-block .content .block.choose_block .car_block .text_block .flex .price {
                      margin-top: 10px;
                      margin-left: 0; } }
        .build-your-deal .flex-block .content .block.trade-in_block .input-block,
        .build-your-deal .flex-block .content .block.trade-in_block select {
          margin-bottom: 20px; }
        .build-your-deal .flex-block .content .block.trade-in_block .row {
          display: flex;
          justify-content: space-between; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.trade-in_block .row {
              flex-direction: column; } }
          .build-your-deal .flex-block .content .block.trade-in_block .row > div {
            flex-shrink: 0;
            width: 48%; }
            @media (max-width: 1100px) {
              .build-your-deal .flex-block .content .block.trade-in_block .row > div {
                -ms-flex-negative: 0;
                flex-shrink: 0;
                width: 100%; } }
        .build-your-deal .flex-block .content .block.trade-in_block .btn_red {
          display: inline-block;
          margin-top: 11px;
          vertical-align: top;
          border-radius: 5px; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.trade-in_block .btn_red {
              display: block;
              margin-right: auto;
              margin-left: auto; } }
        .build-your-deal .flex-block .content .block.trade-in_block .no_trade {
          display: inline-block;
          margin-top: 11px;
          margin-left: 20px;
          vertical-align: top;
          text-decoration: underline;
          color: #ce171f;
          font-size: 18px;
          line-height: 46px; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.trade-in_block .no_trade {
              display: block;
              margin-top: 0;
              margin-left: 0;
              text-align: center; } }
        .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah2 {
          margin-bottom: 10px; }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah2 a {
            margin-left: 17px;
            text-decoration: underline;
            color: #ce171f;
            font-size: 14px; }
        .build-your-deal .flex-block .content .block.trade-in_block .car_det table {
          width: 100%;
          margin-bottom: 25px;
          border-bottom: 2px solid #e6e6e6; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.trade-in_block .car_det table {
              overflow-x: scroll; } }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.trade-in_block .car_det table tr {
              display: flex;
              flex-wrap: wrap; } }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det table tr:nth-child(odd) {
            background-color: #f7f7f7; }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det table td {
            width: 25%;
            padding: 13px 18px; }
            @media (max-width: 1100px) {
              .build-your-deal .flex-block .content .block.trade-in_block .car_det table td {
                width: 50%;
                box-sizing: border-box; } }
            .build-your-deal .flex-block .content .block.trade-in_block .car_det table td .ah3 {
              margin-bottom: 5px;
              color: #9da4b0;
              font-size: 13px; }
              @media (max-width: 1100px) {
                .build-your-deal .flex-block .content .block.trade-in_block .car_det table td .ah3 {
                  text-align: left; } }
        .build-your-deal .flex-block .content .block.trade-in_block .car_det .price {
          text-align: right;
          line-height: 24px; }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det .price b {
            margin-left: 10px;
            font-size: 24px; }
        .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah3 {
          font-size: 16px; }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah3 .bold {
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0.6px; }
          .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah3.error {
            position: relative;
            margin-top: 11px;
            color: #ce171f; }
            .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah3.error::before {
              content: "";
              display: inline-block;
              width: 22px;
              height: 19px;
              margin-right: 7px;
              margin-left: 2px;
              background: url(../img/problem.png); }
            .build-your-deal .flex-block .content .block.trade-in_block .car_det .ah3.error .bold {
              margin-right: 5px;
              letter-spacing: -.4px; }
        .build-your-deal .flex-block .content .block.trade-in_block .old-car a {
          display: inline-block;
          cursor: pointer;
          text-decoration: underline;
          color: #ce171f; }
          .build-your-deal .flex-block .content .block.trade-in_block .old-car a::before {
            content: '';
            display: inline-block;
            width: 11px;
            height: 11px;
            margin-right: 4px;
            margin-left: 7px;
            vertical-align: middle;
            background: url(../img/red_x.svg) no-repeat center center;
            background-size: 11px auto; }
        .build-your-deal .flex-block .content .block.care-package_block .table-striped {
          width: 100%;
          margin-top: 15px;
          font-size: 16px; }
          .build-your-deal .flex-block .content .block.care-package_block .table-striped tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, 0.05); }
          .build-your-deal .flex-block .content .block.care-package_block .table-striped tr td {
            padding: 0.5rem;
            vertical-align: middle; }
            .build-your-deal .flex-block .content .block.care-package_block .table-striped tr td:first-child {
              width: 30px; }
            .build-your-deal .flex-block .content .block.care-package_block .table-striped tr td:last-child {
              width: 64px; }
          .build-your-deal .flex-block .content .block.care-package_block .table-striped tr.not {
            pointer-events: none;
            opacity: 0.4; }
        .build-your-deal .flex-block .content .block.payment_block .ah3 {
          font-size: 16px; }
        .build-your-deal .flex-block .content .block.payment_block .price {
          margin-top: -10px;
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 24px; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.payment_block .price {
              width: fit-content;
              margin-right: auto;
              margin-left: auto; } }
          .build-your-deal .flex-block .content .block.payment_block .price b {
            margin-left: 10px;
            font-size: 24px; }
          .build-your-deal .flex-block .content .block.payment_block .price .red {
            margin-left: 10px; }
            @media (max-width: 1100px) {
              .build-your-deal .flex-block .content .block.payment_block .price .red {
                display: block; } }
        .build-your-deal .flex-block .content .block.payment_block .personalise__button {
          margin-top: 17px; }
        .build-your-deal .flex-block .content .block.payment_block .personal-table__table .personal-table__table-row:nth-child(odd) {
          background: #f2f2f2; }
        .build-your-deal .flex-block .content .block.payment_block .personalise__left-side {
          width: 52%;
          margin-right: 40px; }
          @media (max-width: 1100px) {
            .build-your-deal .flex-block .content .block.payment_block .personalise__left-side {
              width: 100%;
              max-width: 100%;
              margin-right: 0; } }
        @media (max-width: 1100px) {
          .build-your-deal .flex-block .content .block.payment_block .personalise__right-side {
            max-width: 100%; } }
        .build-your-deal .flex-block .content .block.payment_block .buttons_block {
          text-align: center; }
          .build-your-deal .flex-block .content .block.payment_block .buttons_block .btn {
            display: inline-block;
            box-sizing: border-box;
            width: 266px;
            margin: 0 10px;
            vertical-align: top; }
            @media (max-width: 614px) {
              .build-your-deal .flex-block .content .block.payment_block .buttons_block .btn.btn_blue {
                margin-top: 10px; } }
          .build-your-deal .flex-block .content .block.payment_block .buttons_block .btn:not(.btn_blue) {
            border: 1px solid #58585a;
            background: transparent;
            font-weight: bold;
            font-size: 18px; }
            @media (min-width: 1024px) {
              .build-your-deal .flex-block .content .block.payment_block .buttons_block .btn:not(.btn_blue):hover {
                color: #fff;
                background: #2c2c2c; } }
        @media (max-width: 1100px) {
          .build-your-deal .flex-block .content .block.payment_block .personalise__row {
            display: block;
            margin-bottom: 20px; } }
        @media (max-width: 1100px) {
          .build-your-deal .flex-block .content .block.payment_block .radiogroup--notop {
            margin-right: auto;
            margin-left: auto; } }
      @media (max-width: 1100px) {
        .build-your-deal .flex-block .content .custom-slider {
          max-width: calc(100% - 40px);
          margin: 0 auto; } }
      @media (max-width: 1100px) {
        .build-your-deal .flex-block .content .custom-slider .right {
          margin-left: 10px;
          text-align: right; } }
  .build-your-deal #slider-val {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px; }
    .build-your-deal #slider-val .left {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
      color: #ce171f; }
    .build-your-deal #slider-val .right {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 22px;
      line-height: 28px;
      font-weight: bold;
      color: #124390; }
  .build-your-deal #slider {
    height: 4px;
    background-color: #85aded; }
    .build-your-deal #slider .ui-slider-handle {
      top: calc(50% - 15px);
      width: 30px;
      height: 30px;
      border: 1px solid #124390;
      border-radius: 50%;
      background-color: #ffffff; }
      .build-your-deal #slider .ui-slider-handle::before {
        content: "";
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #124390;
        position: absolute;
        top: calc(50% - 6px);
        left: calc(50% - 6px); }
    .build-your-deal #slider .ui-slider-range {
      border-radius: 2px;
      background-color: #ce171f; }
  .build-your-deal #slider-desc {
    display: flex;
    justify-content: space-between;
    margin-top: 17px; }
    .build-your-deal #slider-desc .left,
    .build-your-deal #slider-desc .right {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      color: #2c2c2c; }
  .build-your-deal .radiogroup--notop {
    max-width: 348px;
    margin-top: 0;
    margin-bottom: 30px; }
  .build-your-deal .personalise__right-side--fin {
    max-width: 225px;
    margin-left: 30px; }
    @media (max-width: 900px) {
      .build-your-deal .personalise__right-side--fin {
        max-width: 100%;
        margin-left: 0; } }

.byd--speco {
  padding-bottom: 0; }
  .byd--speco label span {
    color: #2c2c2c; }

.byd-payment {
  color: #2c2c2c; }
  .byd-payment__ch-p-m {
    font-size: 18px;
    line-height: 28px;
    font-weight: 400; }
  .byd-payment__amount {
    margin-top: 10px;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400; }
    .byd-payment__amount span {
      margin-left: 10px;
      font-size: 24px;
      line-height: 28px;
      font-weight: bold; }
  .byd-payment > .radiogroup-v2 {
    margin-top: 5px; }
    @media (max-width: 770px) {
      .byd-payment > .radiogroup-v2 {
        margin: 0 auto;
        align-items: center;
        flex-direction: column; } }
    @media (max-width: 770px) {
      .byd-payment > .radiogroup-v2 label + label {
        margin-left: 0 !important;
        margin-top: 10px !important; } }
  .byd-payment__part-title {
    width: 100%;
    margin-top: 35px;
    border-bottom: 1px solid #e6e6e6;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #2c2c2c; }

.byd-payment-pyf {
  display: flex;
  margin-top: 30px; }
  @media (max-width: 770px) {
    .byd-payment-pyf {
      display: block; } }

.byd-payment-pyf-table {
  flex-grow: 1; }

.byd-paymet-pyf-table-row {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 0 12px;
  color: #2c2c2c;
  font-weight: 400;
  font-size: 15px;
  line-height: 33px; }
  @media (max-width: 450px) {
    .byd-paymet-pyf-table-row {
      font-size: 14px;
      line-height: 24px;
      padding: 0 15px; } }
  @media (max-width: 380px) {
    .byd-paymet-pyf-table-row {
      display: block;
      text-align: center; } }
  .byd-paymet-pyf-table-row:nth-child(2n - 1) {
    background-color: #f7f7f7; }
  .byd-paymet-pyf-table-row__left {
    margin-right: 15px; }
    @media (max-width: 380px) {
      .byd-paymet-pyf-table-row__left {
        margin-right: 0; } }
  @media (max-width: 380px) {
    .byd-paymet-pyf-table-row__right {
      font-weight: 600; } }

.byd-payment-pyf-form {
  box-sizing: border-box;
  width: 330px;
  padding: 33px 35px 38px 34px;
  border: 1px solid #e6e6e6; }
  @media (max-width: 770px) {
    .byd-payment-pyf-form {
      margin: 0 auto;
      width: 550px;
      max-width: 100%;
      margin-top: 20px; } }
  @media (max-width: 450px) {
    .byd-payment-pyf-form {
      padding: 15px 15px; } }
  .byd-payment-pyf-form__total {
    margin-bottom: 11px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #2c2c2c; }
    .byd-payment-pyf-form__total span {
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: #ce181f; }
  .byd-payment-pyf-form .flex-row {
    margin-top: 13px; }

.simple-hint {
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 12px;
  cursor: pointer;
  background: url(../img/help-icon-gray.png) no-repeat center center;
  background-size: contain; }

.byd-depostion-right {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  margin-top: -7px; }
  .byd-depostion-right .input-lane__input--right {
    width: 100px !important;
    padding: 6px 7px !important;
    border: 1px solid #a5a5a5 !important;
    margin-bottom: 0 !important; }
    .byd-depostion-right .input-lane__input--right:focus {
      padding: 6px 7px !important;
      height: auto !important;
      line-height: 23px !important; }
  .byd-depostion-right .input-lane__label {
    margin-right: 5px;
    font-size: 12px;
    letter-spacing: -0.4px; }

#outstanding + .ah2 {
  margin-top: 24px; }

.label-before-c-sli-v2 {
  color: #2c2c2c;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px; }

.news-car-page .banner_month {
  position: relative;
  margin-bottom: 70px; }
  .news-car-page .banner_month:after {
    content: '';
    height: 100px;
    margin-top: -50px;
    background: #eef3fb;
    position: absolute;
    top: 50%;
    right: 0;
    left: 0; }
    @media (max-width: 768px) {
      .news-car-page .banner_month:after {
        height: 240px;
        margin-top: -120px; } }
  .news-car-page .banner_month .text {
    padding-right: 60px;
    padding-left: 25px;
    font-size: 15px;
    color: #2c2c2c; }
    @media (max-width: 768px) {
      .news-car-page .banner_month .text {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px; } }
    .news-car-page .banner_month .text .ah1 {
      margin-bottom: 5px;
      font-size: 24px;
      line-height: 27px;
      font-weight: bold;
      text-transform: uppercase; }
  .news-car-page .banner_month .btn {
    width: 208px;
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 768px) {
      .news-car-page .banner_month .btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px; } }
    .news-car-page .banner_month .btn:after {
      content: '';
      display: inline-block;
      width: 9px;
      height: 14px;
      margin-left: 20px;
      vertical-align: middle;
      background: url("../img/read-more.svg") no-repeat; }
  .news-car-page .banner_month .inner {
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 768px) {
      .news-car-page .banner_month .inner {
        display: block;
        text-align: center; } }
    .news-car-page .banner_month .inner > * {
      position: relative;
      z-index: 1; }

.news-car-page .main-content {
  margin-top: 67px;
  margin-bottom: 83px; }
  .news-car-page .main-content .inner {
    max-width: 950px; }
  .news-car-page .main-content .inner2 {
    max-width: 1140px; }

.news-car-page .cars-grid {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-top: -24px; }
  .news-car-page .cars-grid .item {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 0 266px;
    margin-left: 24px;
    margin-top: 24px; }
    @media (min-width: 580px) {
      .news-car-page .cars-grid .item {
        max-width: calc(50% - 24px); } }
    @media (min-width: 870px) {
      .news-car-page .cars-grid .item {
        max-width: calc(33.33333% - 24px); } }
    @media (min-width: 798px) {
      .news-car-page .cars-grid .item {
        min-width: calc(33.33333% - 24px); } }
    @media (max-width: 500px) {
      .news-car-page .cars-grid .item {
        flex: 1 0 300px; } }
    @media (min-width: 1024px) {
      .news-car-page .cars-grid .item:hover .label {
        color: #ffffff;
        background-color: #124390; } }
    .news-car-page .cars-grid .item:focus {
      outline: none; }
    .news-car-page .cars-grid .item .image-block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 144px; }
      .news-car-page .cars-grid .item .image-block img {
        display: block;
        max-width: 100%;
        max-height: 100%; }
    .news-car-page .cars-grid .item .label {
      display: inline-block;
      margin-top: 10px;
      padding: 7px 25px 8px;
      text-align: center;
      border-radius: 50px;
      font-size: 21px;
      font-weight: 400;
      color: #2c2c2c; }
    @media (max-width: 500px) {
      .news-car-page .cars-grid .item .tripple {
        font-size: 10px; } }
    @media (max-width: 500px) {
      .news-car-page .cars-grid .item .tripple b {
        font-size: 20px; } }
  .news-car-page .cars-grid .item-2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    transition: .3s transform, .3s box-shadow;
    border: 0;
    flex: 1 0 363px;
    margin-left: 25px;
    margin-top: 25px; }
    @media (min-width: 776px) {
      .news-car-page .cars-grid .item-2 {
        max-width: calc(50% - 25px); } }
    @media (min-width: 1164px) {
      .news-car-page .cars-grid .item-2 {
        max-width: calc(33.33333% - 25px); } }
    @media (min-width: 1089px) {
      .news-car-page .cars-grid .item-2 {
        min-width: calc(33.33333% - 25px); } }
    .news-car-page .cars-grid .item-2 .image-block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 182px;
      margin-bottom: 4px; }
      .news-car-page .cars-grid .item-2 .image-block:after {
        content: '';
        width: 100%;
        height: 100px;
        background-color: #eef3fb;
        position: absolute;
        top: 50px;
        left: 0;
        transition: .3s background-color; }
      .news-car-page .cars-grid .item-2 .image-block img {
        display: block;
        max-width: 90%;
        max-height: 90%;
        position: relative;
        z-index: 1; }
    .news-car-page .cars-grid .item-2 .label {
      display: inline-block;
      margin-top: 0;
      padding: 7px 25px 8px;
      transition: .3s color;
      border-radius: 50px;
      font-size: 24px;
      font-weight: 600;
      color: #2c2c2c;
      text-align: center; }
      .news-car-page .cars-grid .item-2 .label.label_big {
        text-transform: uppercase;
        line-height: 28px; }
    .news-car-page .cars-grid .item-2 p {
      font-size: 14px;
      color: #2c2c2c; }
    .news-car-page .cars-grid .item-2:focus {
      outline: none; }
    @media (min-width: 1024px) {
      .news-car-page .cars-grid .item-2:hover {
        transform: translate3d(0, -20px, 0px);
        box-shadow: 0 3px 24px 0 rgba(0, 0, 0, 0.18); }
        .news-car-page .cars-grid .item-2:hover .image-block:after {
          background-color: #ce171f; }
        .news-car-page .cars-grid .item-2:hover .label {
          color: #ce171f;
          background: transparent; } }
    .news-car-page .cars-grid .item-2 .tripple {
      display: flex;
      justify-content: space-between;
      width: 90%;
      padding-top: 20px;
      padding-bottom: 20px;
      font-size: 12px;
      line-height: 17px;
      color: #2c2c2c;
      text-align: center;
      text-transform: uppercase; }
      .news-car-page .cars-grid .item-2 .tripple b {
        display: block;
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 24px; }
      .news-car-page .cars-grid .item-2 .tripple > span {
        width: 30%;
        box-sizing: border-box; }
      .news-car-page .cars-grid .item-2 .tripple > span:nth-child(2) {
        width: 40%;
        border-right: 1px solid #e6e6e6;
        border-left: 1px solid #e6e6e6; }

.news-car-page.new-van .ah2 {
  margin-bottom: 45px;
  font-size: 23px;
  line-height: 33px;
  text-align: center; }

.news-car-page.new-van .block + .block {
  margin-top: 58px;
  padding-top: 45px;
  border-top: 1px solid #e6e6e6; }

.new-cars-page .active-model-year {
  margin-bottom: 25px;
  padding-bottom: 30px;
  border-bottom: 2px solid #e6e6e6; }
  .new-cars-page .active-model-year .ah2 {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 23px;
    font-weight: bold; }
  .new-cars-page .active-model-year label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px; }
  .new-cars-page .active-model-year select {
    display: inline-block;
    width: 287px;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 6px 15px;
    vertical-align: top;
    border: 1px solid #a5a5a5;
    border-radius: 0;
    outline: 0;
    background: #fff url(../img/select_bg.svg) no-repeat;
    background-position: calc(100% - 15px) center;
    font-size: 16px;
    line-height: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box; }
    .new-cars-page .active-model-year select::-ms-expand {
      display: none; }
  .new-cars-page .active-model-year .button {
    display: inline-block;
    width: 85px;
    height: 30px;
    margin-right: 0;
    margin-left: 10px;
    background: #124390;
    font-size: 13px;
    line-height: 30px;
    text-transform: none;
    vertical-align: top;
    position: relative; }
    @media (max-width: 500px) {
      .new-cars-page .active-model-year .button {
        display: block;
        margin-left: 0; } }
    .new-cars-page .active-model-year .button:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-top: -2px;
      margin-right: 8px;
      vertical-align: middle;
      background: url(../img/save.svg) no-repeat; }
    @media (min-width: 1024px) {
      .new-cars-page .active-model-year .button:hover {
        background: #2c2c2c; } }

.new-cars-page .overflow_color_blocks {
  overflow: auto;
  margin-bottom: 50px; }

.new-cars-page .color_blocks_add {
  margin-bottom: 35px;
  padding-bottom: 10px;
  border-bottom: 2px solid #e6e6e6; }
  .new-cars-page .color_blocks_add .ah2 {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px; }
  .new-cars-page .color_blocks_add .row {
    display: flex;
    margin-bottom: 30px; }
    @media (max-width: 768px) {
      .new-cars-page .color_blocks_add .row {
        display: block; } }
    .new-cars-page .color_blocks_add .row > div:first-child {
      flex-shrink: 0;
      width: 290px;
      margin-right: 35px; }
      @media (max-width: 768px) {
        .new-cars-page .color_blocks_add .row > div:first-child {
          width: auto;
          margin-bottom: 20px;
          margin-right: 0; } }
    .new-cars-page .color_blocks_add .row > div:last-child {
      width: 100%; }
    .new-cars-page .color_blocks_add .row label {
      display: block;
      margin-bottom: 5px;
      font-size: 16px; }
    .new-cars-page .color_blocks_add .row input[type=text] {
      width: 100%;
      max-width: 380px;
      padding: 10px 15px;
      border: 1px solid #a5a5a5;
      font-size: 16px;
      line-height: 19px;
      box-sizing: border-box; }
    .new-cars-page .color_blocks_add .row .image_block {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 215px;
      margin-bottom: 21px;
      border: 1px solid #e6e6e6; }
      .new-cars-page .color_blocks_add .row .image_block img {
        max-width: 90%;
        max-height: 90%; }
    .new-cars-page .color_blocks_add .row input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin-top: 5px;
      margin-left: 0;
      cursor: pointer;
      border: 1px solid #a5a5a5;
      background-color: #fff;
      font-size: 14px;
      line-height: 17px;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .new-cars-page .color_blocks_add .row input[type="checkbox"]:checked {
        background-image: url(../img/checked.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%; }
    .new-cars-page .color_blocks_add .row .button {
      position: relative;
      width: 140px;
      height: 30px;
      margin-right: 0;
      text-transform: none;
      font-size: 13px;
      line-height: 30px; }
      .new-cars-page .color_blocks_add .row .button input[type="file"] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        opacity: 0; }
      .new-cars-page .color_blocks_add .row .button--red:before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-top: -2px;
        margin-right: 8px;
        vertical-align: middle;
        background: url("../img/change.svg") no-repeat; }
      .new-cars-page .color_blocks_add .row .button--blue {
        background: #124390; }
        @media (min-width: 1024px) {
          .new-cars-page .color_blocks_add .row .button--blue:hover {
            background: #2c2c2c; } }
        .new-cars-page .color_blocks_add .row .button--blue:before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-top: -2px;
          margin-right: 8px;
          vertical-align: middle;
          background: url("../img/save.svg") no-repeat; }
    .new-cars-page .color_blocks_add .row table {
      width: 100%;
      margin-bottom: 20px; }
      .new-cars-page .color_blocks_add .row table input[type="text"] {
        padding-top: 5px;
        padding-bottom: 5px; }
      .new-cars-page .color_blocks_add .row table tr:nth-child(even) {
        background: #f2f2f2; }
      .new-cars-page .color_blocks_add .row table td {
        padding: 15px 15px 15px 8px; }
        .new-cars-page .color_blocks_add .row table td:first-child {
          width: 10%; }
        .new-cars-page .color_blocks_add .row table td:nth-child(2) {
          width: 30%; }
          .new-cars-page .color_blocks_add .row table td:nth-child(2) input {
            max-width: 115px; }
        .new-cars-page .color_blocks_add .row table td:nth-child(3) input {
          max-width: 195px; }
      .new-cars-page .color_blocks_add .row table th {
        padding: 0 8px 8px;
        text-align: left;
        font-size: 16px; }
        @media (max-width: 768px) {
          .new-cars-page .color_blocks_add .row table th {
            font-size: 13px; } }

.new-cars-page .color_blocks {
  overflow-x: auto;
  padding-bottom: 10px;
  border-bottom: 2px solid #e6e6e6; }
  .new-cars-page .color_blocks .ah2 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 23px;
    font-weight: bold; }
  .new-cars-page .color_blocks table {
    width: 100%; }
    .new-cars-page .color_blocks table input {
      width: 100% !important; }
    .new-cars-page .color_blocks table tr:nth-child(even) {
      background-color: #f2f2f2; }
    .new-cars-page .color_blocks table tr:last-child {
      background-color: #fff; }
      .new-cars-page .color_blocks table tr:last-child td {
        padding-top: 16px;
        padding-bottom: 16px; }
    .new-cars-page .color_blocks table th {
      padding: 9px 13px;
      text-align: left; }
    .new-cars-page .color_blocks table tr td:last-child {
      width: 200px; }
    .new-cars-page .color_blocks table tr td:nth-child(3) {
      padding-right: 30px; }
    .new-cars-page .color_blocks table td {
      box-sizing: border-box;
      height: 66px;
      padding: 7px 13px;
      vertical-align: middle; }
      .new-cars-page .color_blocks table td:nth-child(3) {
        min-width: 163px; }
      .new-cars-page .color_blocks table td:nth-child(4) {
        min-width: 108px; }
      .new-cars-page .color_blocks table td:nth-child(5) {
        min-width: 110px; }
      .new-cars-page .color_blocks table td:nth-child(6) {
        width: 69px; }
      .new-cars-page .color_blocks table td .image_block {
        width: 69px;
        height: 52px;
        border: 1px solid #e6e6e6;
        background-position: center center;
        background-size: cover;
        position: relative; }
        .new-cars-page .color_blocks table td .image_block .del {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: url("../img/red_x.svg") no-repeat center center #ccc;
          background-size: 8px auto;
          position: absolute;
          top: 2px;
          right: 2px; }
      .new-cars-page .color_blocks table td a {
        cursor: pointer;
        color: #f01019; }
      .new-cars-page .color_blocks table td .remove:before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-top: -2px;
        margin-right: 5px;
        margin-left: 22px;
        vertical-align: middle;
        background: url("../img/red_x.svg") no-repeat; }
      .new-cars-page .color_blocks table td .add_new_color {
        display: block;
        width: 155px;
        cursor: pointer;
        transition: .3s background-color;
        text-align: center;
        color: #fff;
        background: #124390;
        line-height: 30px; }
        @media (min-width: 1024px) {
          .new-cars-page .color_blocks table td .add_new_color:hover {
            background-color: #58585a; } }
      .new-cars-page .color_blocks table td input[type="file"] {
        width: 70px;
        height: 30px;
        position: relative; }
        .new-cars-page .color_blocks table td input[type="file"]:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          content: 'Upload';
          cursor: pointer;
          transition: .3s background-color;
          text-align: center;
          color: #fff;
          background: #026fa8;
          line-height: 30px; }
        @media (min-width: 1024px) {
          .new-cars-page .color_blocks table td input[type="file"]:hover:before {
            background-color: #58585a; } }
      .new-cars-page .color_blocks table td input[type="text"] {
        width: 94px;
        padding: 5px;
        border: 1px solid #a5a5a5;
        box-sizing: border-box; }
      .new-cars-page .color_blocks table td select {
        display: block;
        width: 133px;
        padding: 5px 15px;
        border: 1px solid #a5a5a5;
        border-radius: 0;
        outline: 0;
        background: #fff url(../img/select_bg.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 16px;
        line-height: 1em;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-sizing: border-box; }
        .new-cars-page .color_blocks table td select::-ms-expand {
          display: none; }

.new-cars-page + .footer {
  background-color: #f6f6f6; }

.new-cars-page .form-block .input-block input[type=radio] {
  width: 20px;
  height: 20px;
  background: #FFF; }
  .new-cars-page .form-block .input-block input[type=radio]:checked {
    background: #FFF url(../img/radio-b.svg) no-repeat center center; }
    .new-cars-page .form-block .input-block input[type=radio]:checked:not(:hover) {
      background: #FFF url(../img/radio-g.svg) no-repeat center center; }

.new-cars-page .form-block .input-block > label {
  margin-bottom: 2em;
  font-weight: bold;
  font-size: 20px; }

.new-cars-page .no-select {
  color: #adadad; }
  .new-cars-page .no-select label {
    color: #adadad; }

.new-cars-page form {
  padding-top: 60px;
  padding-bottom: 60px; }

.new-cars-page .btn-next {
  width: 136px; }
  .new-cars-page .btn-next img {
    margin-left: 5px;
    vertical-align: middle; }

.new-cars-page .step-1-2 .step-2 {
  display: none;
  margin-top: 70px; }

.new-cars-page .step-3 {
  display: none; }
  .new-cars-page .step-3 .selected-table {
    margin-top: 35px; }
    .new-cars-page .step-3 .selected-table .variants-table .selected-variant td {
      background: transparent; }
      .new-cars-page .step-3 .selected-table .variants-table .selected-variant td:first-child {
        padding-left: 0; }
  .new-cars-page .step-3 .inputs_block {
    margin-top: 70px; }
    .new-cars-page .step-3 .inputs_block .images-upload-block {
      margin-top: 0;
      margin-bottom: 10px; }
    .new-cars-page .step-3 .inputs_block .checkbox-row {
      display: flex;
      align-items: center; }
      .new-cars-page .step-3 .inputs_block .checkbox-row .checkbox-container {
        width: auto;
        min-width: 20px; }
      .new-cars-page .step-3 .inputs_block .checkbox-row .input-block {
        margin-left: 30px; }
        .new-cars-page .step-3 .inputs_block .checkbox-row .input-block input[type="number"] {
          width: 65px;
          margin-right: 8px; }
    .new-cars-page .step-3 .inputs_block .ah1 {
      margin-bottom: 7px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: #2c2c2c; }
    .new-cars-page .step-3 .inputs_block .flex-w25 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .new-cars-page .step-3 .inputs_block .flex-w25 > * {
        width: 24%; }
      @media (max-width: 600px) {
        .new-cars-page .step-3 .inputs_block .flex-w25 > div {
          width: 48%; } }
    .new-cars-page .step-3 .inputs_block .flex-w50 {
      display: flex;
      justify-content: space-between;
      width: 49%; }
      @media (max-width: 600px) {
        .new-cars-page .step-3 .inputs_block .flex-w50 {
          width: auto; } }
      .new-cars-page .step-3 .inputs_block .flex-w50 > * {
        width: 48%; }
    .new-cars-page .step-3 .inputs_block hr {
      margin-bottom: 25px; }
    .new-cars-page .step-3 .inputs_block textarea {
      height: 150px;
      margin-bottom: 25px;
      padding: 12px 15px;
      border: 1px solid #a5a5a5;
      outline: 0;
      font-size: 16px;
      line-height: 1em;
      box-sizing: border-box; }
      .new-cars-page .step-3 .inputs_block textarea.red {
        border-color: red; }
    .new-cars-page .step-3 .inputs_block input {
      width: 100%;
      margin-bottom: 25px;
      padding: 12px 15px;
      border: 1px solid #a5a5a5;
      outline: 0;
      font-size: 16px;
      line-height: 1em;
      box-sizing: border-box; }
      .new-cars-page .step-3 .inputs_block input.red {
        border-color: red; }
    .new-cars-page .step-3 .inputs_block .checkbox-container {
      margin-bottom: 20px;
      padding-left: 30px; }
  .new-cars-page .step-3 .key-spec-flex {
    display: flex;
    margin-top: 30px; }
    @media (max-width: 768px) {
      .new-cars-page .step-3 .key-spec-flex {
        display: block; } }
    .new-cars-page .step-3 .key-spec-flex #specification_text {
      width: 500px;
      height: 600px;
      padding: 12px 15px;
      resize: vertical;
      border: 1px solid #a5a5a5;
      outline: 0;
      font-size: 16px;
      line-height: 1em;
      box-sizing: border-box; }
      @media (max-width: 768px) {
        .new-cars-page .step-3 .key-spec-flex #specification_text {
          width: 100%; } }
    .new-cars-page .step-3 .key-spec-flex #specification_btn {
      width: 70px;
      min-width: 70px;
      height: 100px;
      margin: 95px 15px 0;
      cursor: pointer;
      border: none;
      background: url(../img/arrow-doble.png) center center no-repeat;
      background-size: cover; }
      @media (max-width: 768px) {
        .new-cars-page .step-3 .key-spec-flex #specification_btn {
          margin: 20px auto;
          width: 100px;
          height: 70px;
          background-image: url("../img/arrow-doble2.png"); } }
      .new-cars-page .step-3 .key-spec-flex #specification_btn:focus {
        outline: 0; }
    @media (max-width: 768px) {
      .new-cars-page .step-3 .key-spec-flex .key-spec table {
        max-width: 100%; } }
  .new-cars-page .step-3 .key-spec {
    display: none; }
    .new-cars-page .step-3 .key-spec table {
      box-sizing: border-box;
      width: 100%;
      max-width: 550px; }
      .new-cars-page .step-3 .key-spec table tr th {
        padding: 10px;
        text-align: left;
        background-color: #f2f2f2;
        font-weight: bold;
        font-size: 13px; }
        .new-cars-page .step-3 .key-spec table tr th:first-child {
          width: 70px; }
      .new-cars-page .step-3 .key-spec table tr td {
        padding: 15px 10px 10px;
        font-size: 14px;
        line-height: 24px; }
        .new-cars-page .step-3 .key-spec table tr td .checkbox-container .checkbox-mark:after {
          border-color: #444444; }
        .new-cars-page .step-3 .key-spec table tr td:last-child {
          text-align: center; }
      .new-cars-page .step-3 .key-spec table tr.checked td {
        text-decoration: underline;
        font-weight: bold; }
  .new-cars-page .step-3 .images-upload-block-title {
    margin-top: 70px; }
  .new-cars-page .step-3 .images-upload-block {
    margin-top: 24px; }
    .new-cars-page .step-3 .images-upload-block .label {
      font-size: 13px;
      line-height: 28px; }
    .new-cars-page .step-3 .images-upload-block .grid-block .item {
      min-width: 140px;
      max-width: 140px; }
      .new-cars-page .step-3 .images-upload-block .grid-block .item:nth-child(1) {
        margin-right: 50px; }
  .new-cars-page .step-3 .textarea-block {
    margin-top: 40px; }
    .new-cars-page .step-3 .textarea-block label {
      max-width: 420px;
      font-size: 16px;
      line-height: 24px; }
  .new-cars-page .step-3 .btns-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    max-width: 397px;
    margin-top: 40px;
    margin-right: 0;
    margin-left: 0; }
    .new-cars-page .step-3 .btns-row button {
      margin-top: 20px; }
    .new-cars-page .step-3 .btns-row .btn-save {
      width: 190px; }
      @media (max-width: 400px) {
        .new-cars-page .step-3 .btns-row .btn-save {
          width: 100%; } }
      .new-cars-page .step-3 .btns-row .btn-save img {
        margin-left: 17px;
        vertical-align: middle; }
    .new-cars-page .step-3 .btns-row .btn-prev {
      width: 136px; }
      @media (max-width: 400px) {
        .new-cars-page .step-3 .btns-row .btn-prev {
          width: 100%; } }
      .new-cars-page .step-3 .btns-row .btn-prev img {
        margin-right: 17px;
        vertical-align: middle; }

.exclusive {
  max-width: 801px;
  border-top: 4px solid #ce171f;
  padding: 45px 30px;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1024px) {
    .exclusive {
      padding: 43px 60px 48px 78px; } }
  .exclusive::before {
    content: '';
    display: block;
    width: 100%;
    height: 356px;
    background-color: #2a59a4;
    transform: skewY(3deg);
    position: absolute;
    left: 0;
    bottom: -22px; }
  .exclusive__content {
    position: relative;
    z-index: 1; }
  .exclusive__date {
    font-size: 24px;
    color: #124390;
    margin-bottom: 6px; }
  .exclusive__title {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px; }
    @media (min-width: 1024px) {
      .exclusive__title {
        font-size: 40px;
        line-height: 48px; } }
  .exclusive__subtitle {
    font-size: 24px;
    line-height: 27px;
    color: #ce171f;
    text-transform: uppercase;
    margin-bottom: 25px; }
  .exclusive img {
    width: 113%;
    height: 100%;
    margin-left: -80px; }
  .exclusive__bottom-note {
    font-size: 18px;
    line-height: 26px;
    color: #fff;
    text-align: center;
    margin: 9px 0 20px; }
    .exclusive__bottom-note br {
      display: none; }
      @media (min-width: 1024px) {
        .exclusive__bottom-note br {
          display: block; } }
  .exclusive__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 46px;
    margin: 0 auto;
    border: 1px solid #fff;
    background-color: #2a59a4;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    transition: all 0.3s ease; }
    @media (min-width: 1024px) {
      .exclusive__btn {
        width: 266px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .exclusive__btn:hover {
      background-color: #fff;
      color: #2a59a4; } }
  .exclusive .fancybox-close-small {
    width: 48px;
    height: 48px;
    top: 4px;
    right: 4px;
    color: #ce181f;
    opacity: 1;
    transition: opacity 0.3s ease; }
    @media (min-width: 1024px) {
      .exclusive .fancybox-close-small:hover {
        opacity: 0.7; } }

.exclusive-2 {
  padding: 0;
  position: relative;
  max-width: 802px;
  width: 100%;
  overflow: hidden; }
  .exclusive-2 img {
    width: 100%;
    object-fit: cover;
    margin-bottom: -4px; }
  .exclusive-2 .btn, .exclusive-2__note, .exclusive-2__bottom-text {
    position: absolute; }
  .exclusive-2 .btn, .exclusive-2__bottom-text {
    left: 50%;
    transform: translateX(-50%); }
  .exclusive-2__note {
    text-align: right;
    font-size: 19px;
    line-height: 27.26px;
    font-weight: 700;
    top: 195px;
    right: 47px; }
    @media (max-width: 767px) {
      .exclusive-2__note {
        top: 25vw;
        right: 6vw;
        font-size: 3vw;
        line-height: 3.2vw; } }
  .exclusive-2 .btn {
    width: 266px;
    border: 1px solid #ffffff;
    font-size: 18px;
    text-transform: uppercase;
    bottom: 64px;
    cursor: pointer; }
    @media (max-width: 767px) {
      .exclusive-2 .btn {
        width: 46vw;
        font-size: 4vw;
        bottom: 9vw;
        height: 8vw;
        line-height: 7.9vw; } }
  .exclusive-2__bottom-text {
    font-size: 14px;
    bottom: 32px; }
    @media (max-width: 767px) {
      .exclusive-2__bottom-text {
        font-size: 3vw;
        bottom: 3vw; } }
  .exclusive-2 .fancybox-close-small {
    width: 48px;
    height: 48px;
    top: 8px;
    right: 4px;
    color: #ce181f;
    opacity: 1;
    transition: opacity 0.3s ease; }
    @media (max-width: 600px) {
      .exclusive-2 .fancybox-close-small {
        width: 12vw;
        height: 12vw;
        top: -3vw;
        right: -3vw; } }

@media (min-width: 1024px) and (min-width: 1024px) {
  .exclusive-2 .fancybox-close-small:hover {
    opacity: 0.7; } }

.new-car-config {
  padding-bottom: 40px; }
  .new-car-config #sync2 {
    width: 93%;
    margin: 0 auto; }
    @media (max-width: 767px) {
      .new-car-config #sync2 {
        width: 85%; } }
    @media (max-width: 550px) {
      .new-car-config #sync2 {
        width: 78%; } }
    .new-car-config #sync2 .owl-nav {
      display: block;
      width: calc(100% + 80px);
      margin: 0 -40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 900px) {
        .new-car-config #sync2 .owl-nav {
          width: calc(100% + 60px);
          margin: 0 -30px; } }
    .new-car-config #sync2 .owl-prev,
    .new-car-config #sync2 .owl-next {
      background: none;
      width: 40px;
      height: 50px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin: 0; }
      .new-car-config #sync2 .owl-prev svg,
      .new-car-config #sync2 .owl-next svg {
        width: 100%;
        height: 100%;
        fill: #ce171f; }
    .new-car-config #sync2 .owl-prev {
      left: 0; }
      @media (max-width: 900px) {
        .new-car-config #sync2 .owl-prev {
          left: -12px !important; } }
    .new-car-config #sync2 .owl-next {
      right: 0; }
      @media (max-width: 900px) {
        .new-car-config #sync2 .owl-next {
          right: -12px; } }
  .new-car-config .flex-block .content .block {
    border-bottom: 0;
    padding-bottom: 20px; }
  .new-car-config .picture-parameters {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 31px; }
  .new-car-config .card {
    margin-bottom: 20px;
    margin-top: 31px;
    position: sticky;
    z-index: 2;
    top: 100px; }
    .new-car-config .card .ah1 {
      font-size: 13px;
      line-height: 16px;
      color: #757575; }
    .new-car-config .card table {
      width: 100%;
      margin-bottom: 10px; }
      .new-car-config .card table td {
        font-size: 14px;
        padding: 5px 0; }
        .new-car-config .card table td:last-child {
          width: 70px;
          font-weight: 700;
          text-align: right; }
    .new-car-config .card .total {
      border-top: 1px solid #d7d7d7;
      margin-top: 5px;
      padding-top: 5px; }
  .new-car-config .card-inner {
    border: 1px solid #d9d9d9;
    background-color: #f6f6f6; }
  .new-car-config .card-header {
    background: 0 0;
    border-radius: 0;
    border-bottom: none;
    padding: 34px 35px 14px;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px; }
  .new-car-config .card-body {
    padding: 0 35px 35px; }
    .new-car-config .card-body p {
      font-size: 14px;
      line-height: 22px;
      max-width: 240px;
      margin-bottom: 20px; }
  .new-car-config .button-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px; }
    .new-car-config .button-block .btn {
      background: #124390;
      text-transform: uppercase;
      font-weight: 400;
      width: 100%;
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      border-radius: 5px; }
      .new-car-config .button-block .btn:nth-child(2), .new-car-config .button-block .btn:nth-child(3) {
        width: calc(50% - 5px); }
      @media (min-width: 1024px) {
        .new-car-config .button-block .btn:hover {
          background: #2c2c2c;
          color: #fff; } }
  .new-car-config .relative_block {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #e1e1e1;
    position: relative; }
    @media (max-width: 999px) {
      .new-car-config .relative_block {
        flex-direction: column-reverse; } }
    .new-car-config .relative_block .left {
      width: 66%;
      position: relative; }
      @media (max-width: 999px) {
        .new-car-config .relative_block .left {
          width: auto; } }
      .new-car-config .relative_block .left .gal_none {
        display: none; }
      .new-car-config .relative_block .left .fancybox {
        width: 40px;
        height: 40px;
        border-radius: 3px;
        position: absolute;
        right: 50px;
        top: 500px;
        z-index: 1;
        background: url(../img/controls/3.svg) no-repeat center center rgba(0, 0, 0, 0.3); }
        @media (min-width: 1024px) {
          .new-car-config .relative_block .left .fancybox:hover {
            background-color: rgba(0, 0, 0, 0.2); } }
        @media (max-width: 999px) {
          .new-car-config .relative_block .left .fancybox {
            top: 400px; } }
        @media (max-width: 500px) {
          .new-car-config .relative_block .left .fancybox {
            top: 200px; } }
      .new-car-config .relative_block .left .big-images {
        height: 567px;
        margin-right: 41px;
        border: none !important;
        position: relative;
        overflow: hidden; }
        @media (max-width: 999px) {
          .new-car-config .relative_block .left .big-images {
            height: 450px; } }
        @media (max-width: 500px) {
          .new-car-config .relative_block .left .big-images {
            height: 250px;
            margin-right: 0; } }
        .new-car-config .relative_block .left .big-images .owl-prev {
          display: none; }
        .new-car-config .relative_block .left .big-images .owl-next {
          display: none; }
        .new-car-config .relative_block .left .big-images .item {
          height: 567px;
          position: relative;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center; }
          @media (max-width: 999px) {
            .new-car-config .relative_block .left .big-images .item {
              height: 450px; } }
          @media (max-width: 500px) {
            .new-car-config .relative_block .left .big-images .item {
              height: 250px; } }
      .new-car-config .relative_block .left > h4 {
        margin-top: 40px;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: bold;
        color: #2c2c2c; }
        @media (max-width: 500px) {
          .new-car-config .relative_block .left > h4 {
            margin-top: 20px; } }
      .new-car-config .relative_block .left .thumbs {
        position: relative;
        box-sizing: border-box; }
        .new-car-config .relative_block .left .thumbs .item {
          width: 69px;
          height: 67px;
          background-size: cover;
          background-position: center center;
          position: relative;
          cursor: pointer; }
          @media (max-width: 500px) {
            .new-car-config .relative_block .left .thumbs .item {
              width: 40px;
              height: 40px; } }
          .new-car-config .relative_block .left .thumbs .item:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            box-sizing: border-box;
            border: 2px solid #ffffff;
            opacity: 0.3; }
          .new-car-config .relative_block .left .thumbs .item:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.1;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            box-sizing: border-box;
            clip-path: polygon(100% 0, 0% 100%, 100% 100%); }
        .new-car-config .relative_block .left .thumbs .current .item:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          box-sizing: border-box;
          border: 2px solid #ce181f; }
      .new-car-config .relative_block .left .description {
        margin: 34px 16px 0 0;
        border-top: 1px solid #e1e1e1;
        padding-top: 35px; }
        @media (max-width: 999px) {
          .new-car-config .relative_block .left .description {
            display: none; } }
        .new-car-config .relative_block .left .description h4 {
          margin-bottom: 28px;
          font-size: 24px;
          font-weight: bold;
          color: #2c2c2c; }
        .new-car-config .relative_block .left .description .picture-parameters .row {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap; }
        .new-car-config .relative_block .left .description .picture-parameters .item {
          width: 90px;
          margin-bottom: 37px;
          text-align: center; }
          .new-car-config .relative_block .left .description .picture-parameters .item .text {
            margin-bottom: 15px; }
      .new-car-config .relative_block .left .card,
      .new-car-config .relative_block .left .btn_red-mobile,
      .new-car-config .relative_block .left .button-block {
        display: none; }
      @media (max-width: 999px) {
        .new-car-config .relative_block .left .button-block {
          display: flex;
          margin-bottom: 15px; } }
      @media (max-width: 999px) {
        .new-car-config .relative_block .left .card {
          display: block;
          position: static; } }
      @media (max-width: 999px) {
        .new-car-config .relative_block .left .btn_red-mobile {
          display: block;
          width: 100%;
          background: #ce171f;
          margin-bottom: 10px;
          border-radius: 5px;
          font-size: 18px;
          font-weight: 600;
          color: #fff; } }
    .new-car-config .relative_block .text_block {
      width: 30.5%;
      margin-top: 11px; }
      @media (max-width: 999px) {
        .new-car-config .relative_block .text_block {
          width: auto; } }
      @media (max-width: 999px) {
        .new-car-config .relative_block .text_block .card {
          display: none; } }
      .new-car-config .relative_block .text_block .model {
        font-size: 28px;
        font-weight: bold;
        color: #2c2c2c;
        text-transform: uppercase; }
      .new-car-config .relative_block .text_block .price {
        margin-top: 13px;
        font-size: 24px;
        font-weight: 400;
        color: #2c2c2c; }
        .new-car-config .relative_block .text_block .price span {
          font-size: 14px;
          font-weight: 400;
          color: #2c2c2c;
          text-transform: uppercase; }
      .new-car-config .relative_block .text_block .hr {
        height: 0;
        margin: 12px 0;
        border-top: 1px solid #e1e1e1; }
      .new-car-config .relative_block .text_block .config .flex-row {
        display: flex;
        justify-content: space-between; }
        .new-car-config .relative_block .text_block .config .flex-row .config-input {
          max-width: 49%;
          width: 100%; }
      .new-car-config .relative_block .text_block .config .config-input {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1; }
        .new-car-config .relative_block .text_block .config .config-input input[type="radio"] {
          display: none; }
        .new-car-config .relative_block .text_block .config .config-input .button {
          width: auto;
          flex-grow: 1;
          border-radius: 5px; }
        .new-car-config .relative_block .text_block .config .config-input .hit {
          display: block;
          width: 20px;
          height: 20px;
          flex-shrink: 0;
          margin-left: 9px;
          background: url("../img/help-icon.png") center center no-repeat;
          background-size: 100% 100%;
          cursor: pointer; }
      @media (max-width: 999px) {
        .new-car-config .relative_block .text_block .moblie-left {
          text-align: center; } }
    @media (max-width: 999px) {
      .new-car-config .relative_block .right {
        width: auto; } }
    .new-car-config .relative_block .right .card {
      margin-top: 50px;
      margin-bottom: 15px;
      background-color: #f6f6f6; }
      @media (max-width: 999px) {
        .new-car-config .relative_block .right .card {
          margin-top: 0; } }
      .new-car-config .relative_block .right .card .card-header {
        padding: 34px 35px 14px;
        margin-bottom: 0;
        background: 0 0;
        border-radius: 0;
        border-bottom: none;
        font-size: 18px;
        font-weight: 700; }
      .new-car-config .relative_block .right .card .card-body {
        padding: 0 35px 35px; }
      .new-car-config .relative_block .right .card .ah1 {
        padding-top: 5px; }
      .new-car-config .relative_block .right .card table {
        width: 100%; }
        .new-car-config .relative_block .right .card table td {
          padding: 7px 0; }
        .new-car-config .relative_block .right .card table tr td:last-child {
          width: 70px;
          font-weight: 700;
          text-align: right; }
      .new-car-config .relative_block .right .card .ah1 {
        padding-top: 5px;
        font-size: 13px;
        line-height: 16px;
        color: #757575; }
      .new-car-config .relative_block .right .card .total {
        border-top: 1px solid #d7d7d7;
        margin-top: 5px;
        padding-top: 5px; }
  .new-car-config .custom-slider .slider-desc .left,
  .new-car-config .custom-slider .slider-desc .right {
    margin-bottom: 30px;
    color: #808080; }
  .new-car-config .build-your-deal {
    padding-bottom: 0; }
    .new-car-config .build-your-deal .flex-block {
      padding-top: 20px; }
  .new-car-config .personalise {
    border-top: none; }
    @media (max-width: 900px) {
      .new-car-config .personalise__row .personalise__left-side {
        margin-right: 0;
        margin-top: 20px; } }
    .new-car-config .personalise .radiogroup--info {
      margin-top: 0; }
  .new-car-config .button-calc {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 46px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 17px;
    padding: 0 10px;
    margin-top: 18px; }
    .new-car-config .button-calc svg {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px; }
    @media (max-width: 999px) {
      .new-car-config .button-calc {
        margin-bottom: 25px; } }
  .new-car-config .car-info {
    flex-direction: column;
    margin-top: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #d9d9d9; }
    @media (max-width: 900px) {
      .new-car-config .car-info {
        padding-bottom: 20px; } }
    .new-car-config .car-info__flex {
      width: 100%;
      flex-wrap: wrap; }
    .new-car-config .car-info__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: #2c2c2c;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px; }
      @media (max-width: 900px) {
        .new-car-config .car-info__title {
          font-size: 20px;
          margin-bottom: 0; } }
    .new-car-config .car-info__flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px; }
    .new-car-config .car-info__option-item {
      display: flex;
      align-items: center;
      width: 25%;
      margin-bottom: 28px; }
      @media (max-width: 900px) {
        .new-car-config .car-info__option-item {
          width: 48%; } }
    .new-car-config .car-info__text {
      margin-left: 10px; }
      .new-car-config .car-info__text p:first-child {
        font-size: 13px;
        line-height: 18px;
        color: #999999; }
      .new-car-config .car-info__text p:last-child {
        font-size: 15px;
        line-height: 18px;
        color: #2c2c2c; }
  .new-car-config .toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 31px;
    height: 31px;
    border-radius: 5px;
    background-color: #ce171f;
    margin-right: 18px;
    cursor: pointer; }
    .new-car-config .toggle-btn.active svg {
      transform: rotate(180deg); }
    .new-car-config .toggle-btn svg {
      fill: #fff;
      transition: transform 0.3s ease; }
  .new-car-config .radiogroup-v2 {
    flex-direction: column;
    margin: 22px 0 0; }
    .new-car-config .radiogroup-v2 .radiogroup-v2__lbl {
      width: 100%;
      margin: 0 0 15px; }
      .new-car-config .radiogroup-v2 .radiogroup-v2__lbl span {
        border-radius: 5px; }
  .new-car-config .check-holyday {
    margin: 0; }
    .new-car-config .check-holyday .checkbox-customizedv2 + span::after {
      border-radius: 3px; }
  .new-car-config .personalise__ext {
    margin-top: 30px; }
    .new-car-config .personalise__ext .btn {
      display: block;
      height: 30px;
      font-size: 14px;
      line-height: 16px;
      box-sizing: border-box;
      border-radius: 5px; }
      @media (max-width: 999px) {
        .new-car-config .personalise__ext .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 46px; } }
    .new-car-config .personalise__ext .finance__block .row {
      flex-direction: column; }
      .new-car-config .personalise__ext .finance__block .row .blk {
        width: 100%; }
  .new-car-config .ncc-blck {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding-top: 23px;
    padding-bottom: 23px;
    margin-bottom: 28px; }
    @media (max-width: 900px) {
      .new-car-config .ncc-blck .personalise__row {
        flex-direction: column-reverse; } }
    @media (max-width: 900px) {
      .new-car-config .ncc-blck .personalise__row .personalise__left-side {
        margin-right: 0;
        margin-top: 20px;
        width: 100%; } }
    @media (max-width: 900px) {
      .new-car-config .ncc-blck .personalise__row .radios-and-hint-right {
        justify-content: center; } }
  .new-car-config .ncc-title {
    font-size: 24px !important;
    line-height: 28px;
    font-weight: 400 !important;
    color: #2c2c2c; }
    @media (max-width: 900px) {
      .new-car-config .ncc-title {
        text-align: center;
        display: block; } }
  .new-car-config .ncc-item {
    font-size: 18px;
    line-height: 28px; }
    @media (max-width: 900px) {
      .new-car-config .ncc-item {
        text-align: center;
        display: block; } }

.ncc-r-total {
  width: 100%;
  font-size: 16px;
  line-height: 42px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: right; }
  .ncc-r-total span {
    font-size: 18px;
    margin-left: 19px;
    padding-right: 15px; }

.ncc-total-pers {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 10px; }
  .ncc-total-pers span {
    color: #ce181f;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    margin-left: 10px; }

.btn-sve-ncc {
  background-color: #124390;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  width: 350px;
  max-width: 100%;
  height: 46px; }
  .btn-sve-ncc:hover {
    background: #2c2c2c; }

.config-headline {
  color: #2c2c2c;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  margin-bottom: -12px; }

.config-input--radio {
  margin: 14px 0; }
  .config-input--radio input + label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-size: 16px; }
  .config-input--radio input:not(:checked) + label {
    box-sizing: border-box;
    border: 1px solid #ce181f;
    color: #2c2c2c;
    background: #ffffff;
    cursor: pointer; }
    .config-input--radio input:not(:checked) + label:hover {
      background-color: #ef767b; }
  .config-input--radio input:checked + label {
    pointer-events: none; }

form.config {
  position: relative; }

.hit-desc-config {
  margin-top: 30px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e6e6e6;
  padding: 31px 36px 44px 29px;
  background: #fff;
  z-index: 1; }
  .hit-desc-config.active {
    display: block; }
  .hit-desc-config__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #2c2c2c;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
    margin-bottom: 5px; }
  .hit-desc-config__text {
    color: #4d4d4d;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px; }
  .hit-desc-config__text-item {
    display: block;
    padding-left: 17px;
    position: relative;
    line-height: 22px;
    font-size: 14px; }
    .hit-desc-config__text-item:before {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      background-image: url("../img/red_square.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      position: absolute;
      left: 0;
      top: 8px; }
  .hit-desc-config__compare {
    color: #ce181f;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    text-decoration: underline;
    line-height: 1.3; }
    .hit-desc-config__compare:hover {
      text-decoration: none; }

.title-cpb {
  color: #2c2c2c;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px; }

.save-config-btn {
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  height: 46px;
  background-color: #124390;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  cursor: pointer; }
  .save-config-btn:hover {
    opacity: .8; }

.relative_block:last-child {
  border-bottom: none; }

.corsa__wrapper {
  height: 241px;
  background-color: #f0f0f0; }

.corsa__tabs-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%; }

.corsa__tabs-title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  position: relative; }
  .corsa__tabs-title.js-active .corsa__tabs-desc {
    color: #fff;
    background-color: #b72126; }
  .corsa__tabs-title.js-active:before {
    content: '';
    width: 49px;
    height: 19px;
    margin-left: -24.5px;
    background-image: url(../img/corsa-tabs-icon.png);
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 200; }

.corsa__tabs-desc {
  display: inline-block;
  padding: 0 13px;
  height: 31px;
  border-radius: 15.5px;
  border: 1px solid rgba(206, 24, 31, 0);
  font-size: 18px;
  line-height: 31px;
  font-weight: bold;
  color: #2c2c2c;
  transition: 0.3s;
  box-sizing: border-box; }
  @media (min-width: 1024px) {
    .corsa__tabs-desc:hover {
      border-color: #b72126; } }

.corsa__tabs-img {
  height: 123px;
  display: block;
  margin-bottom: 11px;
  width: 240px; }
  @media (min-width: 1024px) {
    .corsa__tabs-img:hover + .corsa__tabs-desc {
      border-color: #b72126; } }
  .corsa__tabs-img span {
    display: block;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; }

.corsa__tabs-body {
  height: 0;
  padding-top: 65px;
  opacity: 0;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  transition: opacity 0.3s; }
  .corsa__tabs-body.js-active {
    height: auto;
    width: auto;
    opacity: 1; }

.corsa__lead {
  margin-left: auto;
  margin-right: auto;
  font-size: 23px;
  line-height: 36px;
  color: #2c2c2c;
  text-align: center;
  text-transform: uppercase; }

.corsa__slider {
  position: relative; }

.corsa #sync2,
.corsa #sync4 {
  width: unset;
  padding-right: calc(18% + 10px);
  margin-right: 19%; }
  .corsa #sync2 .owl-nav .owl-prev,
  .corsa #sync4 .owl-nav .owl-prev {
    display: none; }
  .corsa #sync2 .owl-nav .owl-next,
  .corsa #sync4 .owl-nav .owl-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21%;
    height: calc(100% - 20px);
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 40px;
    bottom: 0;
    margin-right: 7px;
    background: 0 0;
    background: #e5e5e5;
    text-decoration: none;
    color: #37383a;
    padding: 10px;
    box-sizing: border-box;
    font-weight: 700;
    user-select: none; }
  .corsa #sync2 .owl-item,
  .corsa #sync4 .owl-item {
    cursor: pointer; }
    .corsa #sync2 .owl-item.active.current .car-info__img::before,
    .corsa #sync4 .owl-item.active.current .car-info__img::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border: 3px solid #ce181f;
      z-index: 1;
      box-sizing: border-box; }
  .corsa #sync2.no-video,
  .corsa #sync4.no-video {
    margin-right: 0; }
    .corsa #sync2.no-video .owl-nav .owl-next,
    .corsa #sync4.no-video .owl-nav .owl-next {
      width: 18%;
      margin-right: 0; }

.corsa .car-info__options {
  position: relative; }
  .corsa .car-info__options .video-play {
    width: 18%;
    height: 96px;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: -118px;
    right: 0; }
    .corsa .car-info__options .video-play:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background: url("../img/play.svg") no-repeat center center rgba(0, 0, 0, 0.5);
      background-size: 44px auto; }

@media (max-width: 540px) {
  .custom-slider {
    max-width: calc(100% - 34px);
    margin: 0 auto; } }

.custom-slider .slider-val {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; }
  .custom-slider .slider-val .right input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 150px;
    height: unset;
    border: 0;
    padding: 0;
    background: none;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    color: #ce171f;
    text-align: right;
    text-decoration: underline;
    cursor: pointer; }
    @media (max-width: 540px) {
      .custom-slider .slider-val .right input {
        width: 100px; } }
    .custom-slider .slider-val .right input:focus {
      width: 100px;
      height: 24px;
      padding-left: 8px;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      color: #2c2c2c;
      text-align-last: left;
      text-decoration: none;
      cursor: text; }

.custom-slider .slider {
  height: 4px;
  background-color: #85aded;
  position: relative;
  border-radius: 2px; }
  .custom-slider .slider .ui-slider-handle {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    border: 1px solid #124390;
    background-color: #ffffff;
    border-radius: 50%;
    top: calc(50% - 15px);
    margin-left: -15px;
    cursor: pointer; }
    .custom-slider .slider .ui-slider-handle::before {
      content: "";
      width: 12px;
      height: 12px;
      background-color: #124390;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px); }
  .custom-slider .slider .ui-slider-range {
    height: 100%;
    border-radius: 2px;
    background-color: #ce171f; }

.custom-slider .slider-desc {
  display: flex;
  justify-content: space-between;
  margin-top: 17px; }
  .custom-slider .slider-desc .left,
  .custom-slider .slider-desc .right {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #2c2c2c; }

.corsa-remake {
  padding-bottom: 25px; }
  .corsa-remake__flex {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 40px; }
    .corsa-remake__flex--no-border {
      border: 0; }
  .corsa-remake__left-side {
    width: 65.8%;
    flex-shrink: 0;
    margin-right: 40px; }
    .corsa-remake__left-side .corsa__lead-block {
      margin-bottom: 16px; }
    .corsa-remake__left-side .corsa__lead {
      text-align: left; }
      .corsa-remake__left-side .corsa__lead.lead-text-1 {
        line-height: initial;
        font-size: 24px; }
      .corsa-remake__left-side .corsa__lead.lead-text-2 {
        text-transform: initial;
        color: #da5157; }
      .corsa-remake__left-side .corsa__lead.lead-text-3 {
        text-transform: initial;
        line-height: initial;
        font-size: 16px;
        color: #2c2c2c;
        margin-top: 3px; }
    .corsa-remake__left-side .car-info__left-side {
      width: 100%;
      margin-right: 0; }
    .corsa-remake__left-side .car-info__slider .owl-prev,
    .corsa-remake__left-side .car-info__slider .owl-next {
      width: 20px; }
    .corsa-remake__left-side .video-play {
      position: absolute;
      right: 0;
      width: 18%;
      height: 96px;
      bottom: 0;
      background-size: cover;
      background-position: center center; }
      .corsa-remake__left-side .video-play:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: url("../img/play.svg") no-repeat center center rgba(0, 0, 0, 0.5);
        background-size: 44px auto; }
  .corsa-remake__right-side {
    flex-grow: 1; }
    .corsa-remake__right-side .button-calc {
      height: 60px;
      width: 100%;
      line-height: 60px; }
      .corsa-remake__right-side .button-calc svg {
        width: 20px;
        height: 23px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px; }
      .corsa-remake__right-side .button-calc span {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase; }
    .corsa-remake__right-side .car-info__options {
      margin-top: 30px;
      border: none;
      padding: 0; }
    .corsa-remake__right-side .car-info__title {
      color: #2c2c2c;
      font-size: 20px;
      font-weight: 400;
      padding-bottom: 12px;
      border-bottom: 1px solid #d9d9d9;
      margin-bottom: 33px; }
    .corsa-remake__right-side .car-info__options-row {
      columns: 2; }
    .corsa-remake__right-side .car-info__option {
      display: flex;
      align-items: center;
      margin-bottom: 50px; }
    .corsa-remake__right-side .car-info__option-icon {
      width: 45px;
      margin: 0 14px 0 0; }
      .corsa-remake__right-side .car-info__option-icon img {
        max-width: 100%; }
    .corsa-remake__right-side .car-info__option-title {
      color: #2c2c2c;
      font-size: 15px;
      font-weight: 400; }
    .corsa-remake__right-side .btn--blue {
      width: 100%; }
      .corsa-remake__right-side .btn--blue.btn--mb {
        margin-bottom: 17px; }
  .corsa-remake .corsa__wrapper {
    min-height: 220px;
    height: auto;
    padding: 20px 0;
    box-sizing: border-box; }
  .corsa-remake .corsa__tabs-title {
    margin-bottom: 10px; }
  .corsa-remake .corsa__tabs-desc {
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 400; }
  .corsa-remake .corsa__tabs-header {
    flex-wrap: wrap; }
  .corsa-remake .corsa__tabs-img {
    width: 220px;
    height: 115px;
    margin-bottom: 10px; }
  .corsa-remake .car-info {
    margin-top: 30px;
    display: block;
    padding: 0; }
    .corsa-remake .car-info__title {
      display: flex;
      justify-content: space-between;
      color: #2c2c2c;
      font-size: 24px;
      font-weight: 400;
      line-height: 28px; }
    .corsa-remake .car-info__items {
      column-count: 2;
      column-gap: 50px; }
    .corsa-remake .car-info__item {
      padding-left: 13px;
      line-height: 20px;
      font-size: 15px; }
      .corsa-remake .car-info__item::before {
        content: '';
        top: 6px;
        left: 0;
        height: 5px;
        width: 5px;
        background-color: #ce171f;
        border-radius: 0; }
    .corsa-remake .car-info__toggle {
      display: none;
      align-items: center;
      justify-content: center;
      width: 31px;
      height: 31px;
      border-radius: 5px;
      background-color: #ce171f;
      margin-left: 11px;
      position: relative; }
      .corsa-remake .car-info__toggle svg {
        fill: #ffffff;
        transition: transform 0.2s ease; }
      .corsa-remake .car-info__toggle.active svg {
        transform: rotate(180deg); }
    .corsa-remake .car-info__hide {
      display: none;
      text-align: center; }
      .corsa-remake .car-info__hide .car-info__toggle {
        margin: 0 auto 15px; }
    .corsa-remake .car-info--mobile {
      display: none; }
  .corsa-remake #sync2 .owl-nav .owl-next,
  .corsa-remake #sync4 .owl-nav .owl-next {
    border: 1px solid #d9d9d9;
    background-color: #f7f7f7;
    color: #131313;
    font-size: 14px;
    font-weight: 400; }

.personalise_remake {
  background: none;
  border-top: 2px solid #e1e1e1; }
  .personalise_remake .personalise__inner {
    padding: 35px 0 0;
    margin-bottom: 30px; }
  .personalise_remake .personalise__title {
    text-align: left;
    color: #2c2c2c;
    font-size: 30px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px; }
  .personalise_remake .personal-table__title {
    color: #2c2c2c;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 18px;
    border: none; }
  .personalise_remake .personal-table__table-row:nth-child(2n+1) {
    background-color: #f7f7f7; }
  .personalise_remake .personal-table__table-cell {
    font-size: 15px; }
    @media (max-width: 450px) {
      .personalise_remake .personal-table__table-cell {
        font-size: 12px; } }
  .personalise_remake .personalise__zag {
    color: #2c2c2c;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 15px;
    border: none; }
  .personalise_remake .personalise__total {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .personalise_remake .personalise__total .input-lane__title {
      margin-right: 10px;
      margin-bottom: 0; }
    .personalise_remake .personalise__total .personalise__value {
      color: #ce181f;
      font-size: 18px;
      font-weight: 400; }
  .personalise_remake .input-lane + .input-lane {
    margin-top: 30px; }
  .personalise_remake .input-lane__title {
    font-size: 15px; }
  .personalise_remake .custom-slider .slider-val .right input {
    font-size: 16px; }
  .personalise_remake .custom-slider .slider-desc .left,
  .personalise_remake .custom-slider .slider-desc .right {
    color: #808080;
    font-size: 14px;
    font-weight: 400; }
  .personalise_remake .custom-slider .slider .ui-slider-handle {
    box-sizing: border-box; }
  .personalise_remake .radiogroup--right {
    padding-top: 25px;
    align-items: center;
    justify-content: flex-start;
    border-top: 2px solid #e6e6e6; }
    .personalise_remake .radiogroup--right .radiogroup__radio {
      width: 33.3333%; }
      .personalise_remake .radiogroup--right .radiogroup__radio + .radiogroup__radio {
        margin-left: 14px; }
      .personalise_remake .radiogroup--right .radiogroup__radio span {
        font-size: 15px;
        font-weight: 400;
        text-transform: uppercase;
        height: 38px;
        line-height: 38px;
        border: 1px solid #a5a5a5;
        min-width: 101px;
        box-sizing: border-box;
        padding-left: 41px;
        padding-right: 28px;
        position: relative; }
        .personalise_remake .radiogroup--right .radiogroup__radio span:hover {
          border-color: #ce181f; }
        .personalise_remake .radiogroup--right .radiogroup__radio span::before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          border: 1px solid #a5a5a5;
          background-color: #ffffff;
          box-sizing: border-box;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          margin-top: -10px;
          left: 22%;
          margin-left: -10px; }
        .personalise_remake .radiogroup--right .radiogroup__radio span::after {
          content: "";
          display: none;
          width: 10px;
          height: 10px;
          background-color: #ce181f;
          box-sizing: border-box;
          border-radius: 100%;
          position: absolute;
          top: 50%;
          margin-top: -5px;
          left: 22%;
          margin-left: -5px; }
      .personalise_remake .radiogroup--right .radiogroup__radio input:checked + span {
        border-color: #ce181f; }
        .personalise_remake .radiogroup--right .radiogroup__radio input:checked + span::before {
          border-color: #ce181f; }
        .personalise_remake .radiogroup--right .radiogroup__radio input:checked + span::after {
          display: block; }

.tip {
  margin-left: 12px;
  cursor: pointer; }
  .tip:hover svg path.fill {
    fill: #ce181f; }

.corsa__btns_remake {
  background: none !important;
  padding-bottom: 40px !important; }

@media screen and (max-width: 900px) {
  #sync1,
  #sync3 {
    padding-bottom: 56px !important; }
  .car-info__slider {
    overflow: visible; }
    .car-info__slider .owl-prev {
      left: -19px !important; }
    .car-info__slider .owl-next {
      right: -19px; }
  .corsa-remake__left-side .car-info__left-side {
    overflow: visible; }
  .corsa-remake__left-side .car-info__controls--bottom,
  .corsa-remake__left-side .car-info__slider-control {
    top: auto !important;
    bottom: 118px; }
  .corsa-remake__left-side .car-info__controls--bottom {
    padding-right: 0; }
  .corsa-remake__left-side .car-info__slider-control {
    left: 0; }
  .personalise_remake {
    border-top: 0; }
  .corsa__tabs-body {
    padding: 0 13px; }
  .corsa-remake {
    padding-bottom: 20px; }
    .corsa-remake .corsa__wrapper {
      min-height: 0; }
    .corsa-remake .corsa__tabs-header {
      padding: 0; }
    .corsa-remake .corsa__tabs-title {
      width: 50%;
      box-sizing: border-box; }
    .corsa-remake .corsa__tabs-img {
      display: none; }
    .corsa-remake .corsa__tabs-desc {
      border: 1px solid #cccccc;
      width: 98%;
      padding: 0 8px;
      font-size: 13px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center; }
    .corsa-remake__flex {
      display: block;
      border-bottom: none;
      padding-bottom: 20px; }
    .corsa-remake__left-side {
      width: 100%;
      margin-right: 0; }
      .corsa-remake__left-side .corsa__lead-block {
        margin-bottom: 35px; }
      .corsa-remake__left-side .corsa__lead {
        text-align: center; }
        .corsa-remake__left-side .corsa__lead.lead-text-1 {
          font-size: 24px; }
        .corsa-remake__left-side .corsa__lead.lead-text-2 {
          font-size: 20px;
          line-height: 26px;
          margin-top: 10px; }
        .corsa-remake__left-side .corsa__lead.lead-text-3 {
          font-size: 16px;
          margin-top: 10px; }
      .corsa-remake__left-side .car-info__slide {
        height: 290px; }
      .corsa-remake__left-side .video-play {
        width: 25%;
        height: 90px; }
      .corsa-remake__left-side .car-info__slider-control,
      .corsa-remake__left-side .car-info__controls--bottom {
        top: 240px; }
    .corsa-remake__right-side {
      padding: 0;
      margin-top: 20px; }
      .corsa-remake__right-side .car-info__options {
        margin-top: 30px;
        border: none;
        padding: 0; }
        .corsa-remake__right-side .car-info__options .car-info__title {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .corsa-remake__right-side .car-info__options .car-info__options-row {
          padding: 0 30px; }
    .corsa-remake #sync2,
    .corsa-remake #sync4 {
      padding-right: calc(25% + 10px);
      margin-right: 25%; }
      .corsa-remake #sync2 .car-info__slide,
      .corsa-remake #sync4 .car-info__slide {
        height: 90px; }
      .corsa-remake #sync2 .owl-nav .owl-next,
      .corsa-remake #sync4 .owl-nav .owl-next {
        width: 32%; }
      .corsa-remake #sync2.no-video,
      .corsa-remake #sync4.no-video {
        margin-right: 0; }
        .corsa-remake #sync2.no-video .owl-nav .owl-next,
        .corsa-remake #sync4.no-video .owl-nav .owl-next {
          width: 25%; }
    .corsa-remake .car-info__toggle {
      display: inline-flex; }
    .corsa-remake .car-info__hide {
      display: block; }
    .corsa-remake .car-info {
      margin-top: 20px; } }
    @media screen and (max-width: 900px) and (max-width: 900px) {
      .corsa-remake .car-info {
        display: none; } }

@media screen and (max-width: 900px) {
      .corsa-remake .car-info .car-info__title {
        text-align: center; }
      .corsa-remake .car-info .car-info__items {
        column-count: 1;
        column-gap: 0;
        display: none; }
      .corsa-remake .car-info--mobile {
        margin-top: 0;
        padding: 20px 8px;
        border-top: 1px solid #d9d9d9; }
        .corsa-remake .car-info--mobile:last-child {
          border-bottom: 1px solid #d9d9d9; } }
      @media screen and (max-width: 900px) and (max-width: 900px) {
        .corsa-remake .car-info--mobile {
          display: block; } }

@media screen and (max-width: 900px) {
    .corsa-remake--mob-accordions {
      padding: 0 13px;
      margin-bottom: 92px; }
  .personalise_remake {
    padding-left: 13px;
    padding-right: 13px; }
    .personalise_remake .personalise__row {
      flex-direction: column-reverse; }
    .personalise_remake .personalise__title {
      text-align: center;
      font-size: 24px; }
    .personalise_remake .personal-table__title {
      text-align: center; }
    .personalise_remake .personalise__zag {
      text-align: center; }
    .personalise_remake .personalise__left-side {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0;
      margin-top: 36px; }
    .personalise_remake .radiogroup--right {
      justify-content: center; }
  .corsa__btns_remake {
    padding-left: 13px;
    padding-right: 13px; }
    .corsa__btns_remake .btn {
      width: 100%; }
  .corsa-remake__right-side .button-calc {
    display: none; } }

@media screen and (max-width: 540px) {
  .personalise_remake .personalise__total {
    max-width: calc(100% - 34px);
    margin-left: auto;
    margin-right: auto; }
  .personalise_remake .personal-table__table {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px; }
  .personalise_remake .personal-table__table-cell--w70 {
    padding-left: 20px; }
  .personalise_remake .personal-table__table-cell--w30 {
    padding-right: 20px; } }

.new-corsa-heading-block {
  color: #2c2c2c;
  margin-bottom: 23px; }
  .new-corsa-heading-block__title {
    font-size: 35px;
    font-weight: bold;
    line-height: 36px;
    text-transform: uppercase; }
  .new-corsa-heading-block__subtitle {
    font-size: 20px;
    font-weight: 400;
    line-height: 26px; }

.new-corsa-lb-credit-block {
  display: flex;
  padding: 9px 2.390438247%;
  background-color: #454545; }

.new-corsa-lb-credit-item {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  position: relative; }
  .new-corsa-lb-credit-item:not(:last-child)::after {
    content: '';
    display: block;
    height: 100%;
    width: 1px;
    right: -0.5px;
    top: 0;
    position: absolute;
    background-color: #999999; }
  .new-corsa-lb-credit-item__top {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    height: 13px;
    margin-bottom: 10px; }
  .new-corsa-lb-credit-item__bottom {
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    text-transform: uppercase; }
    .new-corsa-lb-credit-item__bottom span {
      font-size: 40px;
      line-height: 28px;
      margin-right: 7px; }

.advance-cash-price {
  border: 1px solid #e6e6e6;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2c2c2c;
  padding: 22px 20px 18px;
  width: 100%;
  box-sizing: border-box;
  min-height: 81px; }
  .advance-cash-price__price {
    font-size: 40px;
    font-weight: 400;
    line-height: 28px;
    margin-right: 11px; }
  .advance-cash-price__text {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase; }

@media screen and (max-width: 960px) {
  .new-corsa-lb-credit-item__bottom span {
    white-space: nowrap;
    font-size: 36px; }
  .new-corsa-heading-block__title {
    font-size: 30px;
    line-height: 1.2;
    margin-top: 25px;
    text-align: center; }
  .new-corsa-heading-block__subtitle {
    font-size: 18px;
    line-height: 26px;
    margin-top: 8px;
    text-align: center; } }

@media screen and (max-width: 610px) {
  .new-corsa-heading-block__title {
    font-size: 25px; }
  .new-corsa-lb-credit-item__top {
    font-size: 12px; }
  .new-corsa-lb-credit-item__bottom span {
    font-size: 28px;
    margin-right: 0;
    display: block; }
  .new-corsa-lb-credit-item-content {
    text-align: center; }
  .new-corsa-lb-credit-item__bottom {
    font-size: 13px; }
  .new-corsa-heading-block {
    text-align: center; } }

@media screen and (max-width: 400px) {
  .new-corsa-heading-block__title {
    font-size: 23px; }
  .advance-cash-price {
    padding: 10px; }
  .advance-cash-price__price {
    font-size: 35px; } }

.cr-car-videos-wrap {
  padding-bottom: 30px;
  padding-top: 30px;
  border-bottom: 1px solid #e1e1e1; }

.cr-car-videos-title {
  display: block;
  color: #2c2c2c;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px; }

@media screen and (max-width: 900px) {
  .cr-car-videos-title {
    text-align: center; } }

.cr-car-videos {
  margin-top: 17px; }
  .cr-car-videos:not(.owl-loaded) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap; }
    .cr-car-videos:not(.owl-loaded) .cr-car-videos-item {
      width: calc(33.3333% - 22.3px / 3);
      margin-bottom: 11px; }
      .cr-car-videos:not(.owl-loaded) .cr-car-videos-item:not(:nth-child(3n)) {
        margin-right: 11px; }
  .cr-car-videos .owl-dots {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 5px; }
    .cr-car-videos .owl-dots .owl-dot.active,
    .cr-car-videos .owl-dots .owl-dot:hover {
      background-color: #1d1d1b;
      border: 1px solid #1d1d1b; }
    .cr-car-videos .owl-dots .owl-dot {
      margin: 0 4px;
      background: #fff;
      border: 1px solid #bfbfbf;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      font-size: 0;
      line-height: 0;
      outline: 0;
      padding: 5px;
      transition-duration: .2s; }

.cr-yt-video-wrap {
  width: 100%;
  position: relative; }
  .cr-yt-video-wrap iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }
    .cr-yt-video-wrap iframe + .cr-yt-video-preview {
      display: none; }
  .cr-yt-video-wrap::before {
    content: '';
    display: block;
    padding-top: 48.25%; }

.cr-yt-video-preview {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer; }
  .cr-yt-video-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center; }
  .cr-yt-video-preview:hover .ytp-large-play-button-bg {
    fill: #f00;
    fill-opacity: 1; }

.cr-yt-vide-btn {
  border: none;
  background-color: transparent;
  padding: 0;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 68px;
  height: 48px;
  margin-left: -34px;
  margin-top: -24px; }
  .cr-yt-vide-btn svg {
    display: block;
    pointer-events: none; }
  .cr-yt-vide-btn .ytp-large-play-button-bg {
    transition: all 0.1s cubic-bezier(0.4, 0, 1, 1); }

.van-offer .corsa__tabs-title {
  margin-bottom: 0; }
  @media (max-width: 900px) {
    .van-offer .corsa__tabs-title {
      width: 100%; } }
  .van-offer .corsa__tabs-title.js-active .corsa__tabs-desc {
    background-color: transparent;
    border: 1px solid #ac1919;
    color: #2c2c2c; }
    @media (max-width: 900px) {
      .van-offer .corsa__tabs-title.js-active .corsa__tabs-desc {
        background-color: #b72126;
        color: #fff; } }

.van-offer .corsa__tabs-header {
  display: block;
  position: relative; }
  @media (max-width: 900px) {
    .van-offer .corsa__tabs-header {
      margin-top: 7px; } }
  @media (max-width: 900px) {
    .van-offer .corsa__tabs-header .owl-stage {
      left: -30px; } }
  .van-offer .corsa__tabs-header:not(.owl-loaded) {
    display: flex;
    flex-wrap: nowrap;
    opacity: 0; }
  .van-offer .corsa__tabs-header .owl-nav {
    width: calc(100% + 80px);
    margin: 0 -40px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }
    @media (max-width: 1400px) {
      .van-offer .corsa__tabs-header .owl-nav {
        width: 100%;
        margin: 0; } }
  .van-offer .corsa__tabs-header .owl-prev,
  .van-offer .corsa__tabs-header .owl-next {
    background: none;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0; }
    .van-offer .corsa__tabs-header .owl-prev svg,
    .van-offer .corsa__tabs-header .owl-next svg {
      width: 100%;
      height: 100%;
      fill: #ce171f; }
  .van-offer .corsa__tabs-header .owl-prev {
    left: 0; }
    @media (max-width: 1400px) {
      .van-offer .corsa__tabs-header .owl-prev {
        left: -20px; } }
  .van-offer .corsa__tabs-header .owl-next {
    right: 0; }
    @media (max-width: 1400px) {
      .van-offer .corsa__tabs-header .owl-next {
        right: -20px; } }

.van-offer .corsa__tabs-desc {
  font-size: 14px;
  line-height: 28px;
  height: 28px;
  padding: 0 25px;
  color: #2c2c2c; }
  @media (max-width: 900px) {
    .van-offer .corsa__tabs-desc {
      font-size: 13px;
      line-height: 15px;
      height: 40px;
      border-radius: 20px;
      padding: 0 15px; } }

.van-offer .corsa__tabs-img {
  margin-bottom: 3px; }

.van-offer .corsa__wrapper {
  min-height: auto;
  padding: 10px 0;
  position: relative; }
  @media (max-width: 900px) {
    .van-offer .corsa__wrapper {
      min-height: 80px; }
      .van-offer .corsa__wrapper::after {
        content: '';
        display: block;
        width: 60px;
        height: 100%;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, whitesmoke 100%);
        position: absolute;
        top: 0;
        right: 0;
        pointer-events: none; } }

.van-offer .corsa__tabs-body {
  padding-top: 0; }

.van-offer .corsa .new-corsa-heading-block {
  margin-bottom: 33px; }
  @media (max-width: 900px) {
    .van-offer .corsa .new-corsa-heading-block {
      margin-bottom: 9px; } }
  .van-offer .corsa .new-corsa-heading-block__title {
    font-size: 28px;
    line-height: 36px; }
    @media (max-width: 900px) {
      .van-offer .corsa .new-corsa-heading-block__title {
        font-size: 24px;
        line-height: 30px;
        margin-top: 0; } }
  .van-offer .corsa .new-corsa-heading-block__subtitle-red {
    font-size: 18px;
    line-height: 26px;
    color: #CE171F; }
    @media (max-width: 900px) {
      .van-offer .corsa .new-corsa-heading-block__subtitle-red {
        display: none; } }
    @media (max-width: 960px) {
      .van-offer .corsa .new-corsa-heading-block__subtitle-red {
        text-align: center; } }
    .van-offer .corsa .new-corsa-heading-block__subtitle-red--mobile {
      display: none; }
      @media (max-width: 900px) {
        .van-offer .corsa .new-corsa-heading-block__subtitle-red--mobile {
          display: block;
          font-size: 16px;
          line-height: 24px;
          color: #2c2c2c;
          text-align: left;
          margin: 30px 0 10px; } }
  .van-offer .corsa .new-corsa-heading-block__subtitle {
    font-size: 14px;
    line-height: 26px; }
    @media (max-width: 900px) {
      .van-offer .corsa .new-corsa-heading-block__subtitle {
        display: none; } }
    .van-offer .corsa .new-corsa-heading-block__subtitle--mobile {
      display: none; }
      @media (max-width: 900px) {
        .van-offer .corsa .new-corsa-heading-block__subtitle--mobile {
          display: block;
          font-size: 13px;
          line-height: 22px;
          color: #4c4c4c;
          text-align: left; } }

.van-offer .corsa .advance-cash-price {
  display: block;
  padding: 28px 25px 34px;
  margin-bottom: 30px; }
  @media (max-width: 900px) {
    .van-offer .corsa .advance-cash-price {
      display: none;
      margin: 18px -6% 0;
      width: 112%;
      padding: 12px 42px 24px; } }
  .van-offer .corsa .advance-cash-price--mobile {
    display: none; }
    @media (max-width: 900px) {
      .van-offer .corsa .advance-cash-price--mobile {
        display: block; } }
  @media (max-width: 900px) {
    .van-offer .corsa .advance-cash-price__price {
      font-size: 30px; } }
  @media (max-width: 900px) {
    .van-offer .corsa .advance-cash-price__text {
      font-size: 14px;
      line-height: 18px; }
      .van-offer .corsa .advance-cash-price__text br {
        display: none; } }
  .van-offer .corsa .advance-cash-price__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    margin: 0 12px 20px;
    padding-bottom: 15px; }
    @media (max-width: 900px) {
      .van-offer .corsa .advance-cash-price__top {
        margin: 0 0 9px;
        padding-bottom: 9px; } }
  .van-offer .corsa .advance-cash-price__bottom {
    display: flex;
    margin: 0 12px; }
  .van-offer .corsa .advance-cash-price__item {
    width: 33.3333%; }
    .van-offer .corsa .advance-cash-price__item + .advance-cash-price__item {
      border-left: 1px solid #e6e3e3;
      margin-left: 12px;
      padding-left: 12px; }
    .van-offer .corsa .advance-cash-price__item p:first-child {
      font-size: 30px;
      line-height: 28px; }
      @media (max-width: 900px) {
        .van-offer .corsa .advance-cash-price__item p:first-child {
          font-size: 24px; } }
    .van-offer .corsa .advance-cash-price__item p:last-child {
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase; }
      @media (max-width: 900px) {
        .van-offer .corsa .advance-cash-price__item p:last-child {
          font-size: 12px;
          line-height: 18px; } }
  .van-offer .corsa .advance-cash-price .button-calc {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    line-height: 17px;
    padding: 0 10px; }
    @media (max-width: 900px) {
      .van-offer .corsa .advance-cash-price .button-calc {
        width: 100%;
        margin-top: 14px; } }
    .van-offer .corsa .advance-cash-price .button-calc svg {
      margin-right: 10px; }
      @media (max-width: 900px) {
        .van-offer .corsa .advance-cash-price .button-calc svg {
          width: 20px;
          height: 23px; } }
    @media (max-width: 900px) {
      .van-offer .corsa .advance-cash-price .button-calc span {
        font-size: 16px;
        text-transform: none; } }

.van-offer .corsa #slider1,
.van-offer .corsa #slider2 {
  border-bottom: none;
  width: 97.5%;
  overflow: visible; }
  .van-offer .corsa #slider1 .car-info__slide,
  .van-offer .corsa #slider2 .car-info__slide {
    height: 493px; }
    @media (max-width: 900px) {
      .van-offer .corsa #slider1 .car-info__slide,
      .van-offer .corsa #slider2 .car-info__slide {
        height: 231px; } }
  .van-offer .corsa #slider1 .car-info__img,
  .van-offer .corsa #slider2 .car-info__img {
    margin-right: 1px; }
  .van-offer .corsa #slider1:not(.owl-loaded),
  .van-offer .corsa #slider2:not(.owl-loaded) {
    display: flex;
    flex-wrap: nowrap;
    opacity: 0; }
  .van-offer .corsa #slider1 .owl-stage-outer,
  .van-offer .corsa #slider2 .owl-stage-outer {
    z-index: 1; }
  .van-offer .corsa #slider1 .owl-nav,
  .van-offer .corsa #slider2 .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% + 40px);
    margin: 0 -20px; }
  .van-offer .corsa #slider1 .owl-prev,
  .van-offer .corsa #slider1 .owl-next,
  .van-offer .corsa #slider2 .owl-prev,
  .van-offer .corsa #slider2 .owl-next {
    width: 20px;
    height: 36px;
    background-color: #CE171F;
    border-radius: 0 5px 5px 0;
    margin-top: -17px;
    position: absolute;
    top: 0; }
    @media (max-width: 900px) {
      .van-offer .corsa #slider1 .owl-prev,
      .van-offer .corsa #slider1 .owl-next,
      .van-offer .corsa #slider2 .owl-prev,
      .van-offer .corsa #slider2 .owl-next {
        height: 40px;
        border-radius: 0; } }
  .van-offer .corsa #slider1 .owl-prev,
  .van-offer .corsa #slider2 .owl-prev {
    left: 0; }
    @media (max-width: 900px) {
      .van-offer .corsa #slider1 .owl-prev,
      .van-offer .corsa #slider2 .owl-prev {
        left: 0 !important; } }
  .van-offer .corsa #slider1 .owl-next,
  .van-offer .corsa #slider2 .owl-next {
    right: 1px; }

.van-offer .corsa #thumb1,
.van-offer .corsa #thumb2 {
  width: 90%;
  margin: 76px auto 0;
  overflow: visible; }
  @media (max-width: 900px) {
    .van-offer .corsa #thumb1,
    .van-offer .corsa #thumb2 {
      margin: 66px auto 0; } }
  .van-offer .corsa #thumb1 .car-info__slide,
  .van-offer .corsa #thumb2 .car-info__slide {
    border: 1px solid #e6e6e6;
    height: 98px;
    box-sizing: border-box; }
    @media (max-width: 900px) {
      .van-offer .corsa #thumb1 .car-info__slide,
      .van-offer .corsa #thumb2 .car-info__slide {
        height: 78px; } }
  .van-offer .corsa #thumb1:not(.owl-loaded),
  .van-offer .corsa #thumb2:not(.owl-loaded) {
    display: flex;
    flex-wrap: nowrap;
    opacity: 0; }
  .van-offer .corsa #thumb1 .owl-item.current .car-info__slide,
  .van-offer .corsa #thumb2 .owl-item.current .car-info__slide {
    border-color: #ce171f; }
  .van-offer .corsa #thumb1 .owl-nav,
  .van-offer .corsa #thumb2 .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% + 80px);
    margin: 0 -40px; }
    @media (max-width: 900px) {
      .van-offer .corsa #thumb1 .owl-nav,
      .van-offer .corsa #thumb2 .owl-nav {
        width: calc(100% + 60px);
        margin: 0 -30px; } }
  .van-offer .corsa #thumb1 .owl-prev,
  .van-offer .corsa #thumb1 .owl-next,
  .van-offer .corsa #thumb2 .owl-prev,
  .van-offer .corsa #thumb2 .owl-next {
    background: none;
    width: 40px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0; }
    .van-offer .corsa #thumb1 .owl-prev svg,
    .van-offer .corsa #thumb1 .owl-next svg,
    .van-offer .corsa #thumb2 .owl-prev svg,
    .van-offer .corsa #thumb2 .owl-next svg {
      width: 100%;
      height: 100%;
      fill: #ce171f; }
  .van-offer .corsa #thumb1 .owl-prev,
  .van-offer .corsa #thumb2 .owl-prev {
    left: 0; }
    @media (max-width: 900px) {
      .van-offer .corsa #thumb1 .owl-prev,
      .van-offer .corsa #thumb2 .owl-prev {
        left: -12px !important; } }
  .van-offer .corsa #thumb1 .owl-next,
  .van-offer .corsa #thumb2 .owl-next {
    right: 0; }
    @media (max-width: 900px) {
      .van-offer .corsa #thumb1 .owl-next,
      .van-offer .corsa #thumb2 .owl-next {
        right: -12px; } }

.van-offer .corsa .car-info {
  margin-top: 20px;
  padding-bottom: 14px; }
  @media (max-width: 900px) {
    .van-offer .corsa .car-info {
      margin-top: 0;
      padding-bottom: 20px; } }
  .van-offer .corsa .car-info__title {
    margin-bottom: 20px; }
    @media (max-width: 900px) {
      .van-offer .corsa .car-info__title {
        font-size: 20px;
        margin-bottom: 0; } }
  .van-offer .corsa .car-info__flex {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .van-offer .corsa .car-info__options-row {
    display: flex;
    flex-wrap: wrap; }
  .van-offer .corsa .car-info__option-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; }
    @media (max-width: 900px) {
      .van-offer .corsa .car-info__option-item {
        width: 48%; } }
    .van-offer .corsa .car-info__option-item + .car-info__option-item {
      margin-left: 10px; }
      @media (max-width: 900px) {
        .van-offer .corsa .car-info__option-item + .car-info__option-item {
          margin-left: 0; } }
  .van-offer .corsa .car-info__text {
    margin-left: 10px; }
    .van-offer .corsa .car-info__text p:first-child {
      font-size: 13px;
      line-height: 18px;
      color: #999999; }
    .van-offer .corsa .car-info__text p:last-child {
      font-size: 15px;
      line-height: 18px;
      color: #2c2c2c; }
  .van-offer .corsa .car-info__left-side {
    overflow: visible; }
    @media (max-width: 900px) {
      .van-offer .corsa .car-info__left-side {
        padding-bottom: 14px;
        margin-bottom: 30px;
        border-bottom: 1px solid #e1e1e1; } }
  .van-offer .corsa .car-info__control {
    border-radius: 5px; }
  .van-offer .corsa .car-info__controls {
    position: absolute;
    top: 493px;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e1e1e1; }
    @media (max-width: 900px) {
      .van-offer .corsa .car-info__controls {
        top: 231px;
        padding-top: 5px; } }
  .van-offer .corsa .car-info .js-expand {
    margin-top: 15px; }

.van-offer .personalise {
  padding-bottom: 28px; }
  @media (max-width: 900px) {
    .van-offer .personalise {
      border-top: 1px solid #e1e1e1; } }
  @media (max-width: 900px) {
    .van-offer .personalise__inner {
      padding: 19px 0 0; } }
  .van-offer .personalise__inner .personalise__row {
    margin-bottom: 0 !important; }
  @media (max-width: 900px) {
    .van-offer .personalise .finance-calc__title {
      text-align: center; } }
  .van-offer .personalise .finance__block .row {
    display: block; }
    .van-offer .personalise .finance__block .row .blk {
      width: 100%; }
  .van-offer .personalise .radiogroup-v2 {
    margin: 7.5px 0;
    flex-direction: column; }
    .van-offer .personalise .radiogroup-v2__lbl {
      width: 100%;
      margin: 7.5px 0; }
      .van-offer .personalise .radiogroup-v2__lbl span {
        border-radius: 5px; }
  .van-offer .personalise .check-holyday {
    margin-left: 0; }
  .van-offer .personalise__ext {
    margin: 22px 0 0; }
    .van-offer .personalise__ext .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 236px;
      line-height: 0;
      box-sizing: border-box;
      height: 30px;
      border-radius: 5px; }
      @media (max-width: 900px) {
        .van-offer .personalise__ext .btn {
          height: 35px; } }
  .van-offer .personalise__row {
    margin-bottom: 0 !important; }

.van-offer .cr-car-videos-wrap {
  padding-top: 53px;
  padding-bottom: 9px;
  border-bottom: none;
  border-top: 2px solid #e1e1e1; }
  @media (max-width: 900px) {
    .van-offer .cr-car-videos-wrap {
      display: none; } }
  @media (max-width: 900px) {
    .van-offer .cr-car-videos-wrap .cr-car-videos-title {
      text-align: left; } }
  .van-offer .cr-car-videos-wrap .cr-car-videos {
    margin-top: 12px; }
    .van-offer .cr-car-videos-wrap .cr-car-videos .owl-dots {
      justify-content: flex-start; }
    .van-offer .cr-car-videos-wrap .cr-car-videos .owl-dot.active, .van-offer .cr-car-videos-wrap .cr-car-videos .owl-dot:hover {
      background-color: #ce171f;
      border-color: #ce171f; }
  .van-offer .cr-car-videos-wrap .cr-yt-video-wrap::before {
    padding-top: 56%; }
    @media (max-width: 900px) {
      .van-offer .cr-car-videos-wrap .cr-yt-video-wrap::before {
        padding-top: 54%; } }
  .van-offer .cr-car-videos-wrap--mobile {
    display: none; }
    @media (max-width: 900px) {
      .van-offer .cr-car-videos-wrap--mobile {
        display: block;
        border-top: none;
        padding-top: 14px;
        padding-left: 13px;
        margin-bottom: 60px; } }

.van-offer .corsa-remake__flex {
  padding-bottom: 15px; }

@media (min-width: 901px) and (max-width: 1200px) {
  .van-offer .corsa-remake__left-side {
    width: 60%;
    margin-right: 20px; } }

.van-offer .corsa-remake__right-side .button-calc {
  height: 46px;
  line-height: 46px;
  margin-top: 18px;
  border-radius: 5px; }
  .van-offer .corsa-remake__right-side .button-calc span {
    font-size: 16px; }

.van-offer .corsa-remake__right-side .btn--blue {
  height: auto;
  line-height: 28px;
  border-radius: 5px;
  padding: 9px; }
  .van-offer .corsa-remake__right-side .btn--blue + .btn--blue {
    margin-top: 15px; }
  .van-offer .corsa-remake__right-side .btn--blue p {
    font-size: 14px;
    line-height: 16px;
    color: #a7c1ea;
    text-transform: none; }

@media (max-width: 900px) {
  .van-offer .corsa-remake--mob-accordions {
    margin-bottom: 20px; } }

.car-info {
  display: flex;
  align-items: flex-start;
  padding-bottom: 26px;
  padding-top: 55px; }
  .car-info__left-side {
    width: 65.8%;
    flex-shrink: 0;
    margin-right: 40px;
    position: relative;
    overflow: hidden; }
  .car-info__right-side {
    position: relative; }
  .car-info__slider {
    width: 100%;
    height: 343px;
    visibility: hidden;
    overflow: hidden;
    opacity: 0;
    position: absolute; }
    .car-info__slider .js-slider-1 {
      margin-bottom: 50px; }
    .car-info__slider .owl-next {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      background-image: url(../img/controls/arrow.svg);
      background-color: rgba(128, 128, 128, 0.5);
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      right: 0;
      cursor: pointer;
      transition: 0.3s; }
    .car-info__slider .owl-prev {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      background-image: url(../img/controls/arrow.svg);
      background-color: rgba(128, 128, 128, 0.5);
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      left: 0;
      cursor: pointer;
      transform: rotate(180deg);
      transition: 0.3s; }
  @media (max-width: 500px) {
    .car-info__slider-control {
      top: 208px; } }
  .car-info__slide {
    height: 570px;
    position: relative; }
  .car-info__content.js-active > .car-info__slider {
    margin-top: 0;
    margin-left: 0;
    height: auto;
    opacity: 1;
    visibility: visible;
    position: relative; }
  .car-info__img {
    position: relative;
    height: 100%; }
    .car-info__img > div {
      display: block;
      position: relative;
      height: 100%;
      width: 100%;
      background: no-repeat center / cover; }
  .car-info__controls {
    display: flex; }
    .car-info__controls--bottom {
      width: 100%;
      padding-right: 10px;
      position: absolute;
      left: 0;
      top: 520px;
      z-index: 98;
      box-sizing: border-box; }
  .car-info__control {
    height: 40px;
    background: rgba(0, 0, 0, 0.3) no-repeat center; }
    .car-info__control.js-active {
      background-color: #ce171f; }
    .car-info__control--small {
      display: none;
      width: 40px; }
      .car-info__control--small.active {
        display: block; }
    .car-info__control--big {
      width: 40px; }
    .car-info__control--margin-auto {
      margin-left: auto; }
    .car-info__control--margin-right {
      margin-right: 12px; }
  .car-info__title {
    font-size: 18px;
    line-height: 21px;
    color: #2c2c2c;
    font-weight: bold; }
    .car-info__title--top {
      margin-bottom: 30px; }
    .car-info__title--top-tiny {
      margin-bottom: 30px;
      font-size: 24px;
      line-height: 27px; }
    .car-info__title--margin-left {
      font-size: 24px;
      line-height: 27px;
      padding-bottom: 24px;
      margin-bottom: 27px;
      border-bottom: 2px solid #eeeeee; }
  .car-info__item {
    padding-left: 11px;
    margin-bottom: 15px;
    position: relative;
    font-size: 16px;
    line-height: 18px;
    color: #2c2c2c;
    box-sizing: border-box; }
    .car-info__item:before {
      content: '';
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: #212121;
      position: absolute;
      top: 6px;
      left: 0; }
  .car-info__options {
    padding: 30px 0 0 0;
    margin-top: 20px;
    border-top: 2px solid #eeeeee;
    box-sizing: border-box; }
  .car-info__options-row {
    -webkit-columns: 4;
    -moz-columns: 4;
    columns: 4; }
  .car-info__option {
    margin-bottom: 5px; }
  .car-info__option-title {
    margin-bottom: 5px;
    font-size: 15px;
    line-height: 18px;
    font-weight: bold;
    color: #2c2c2c;
    text-align: center; }
  .car-info__option-icon {
    width: 60px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto;
    height: 30px;
    margin-bottom: 25px;
    vertical-align: middle; }
    .car-info__option-icon img {
      display: inline-block;
      vertical-align: middle;
      margin-left: auto;
      margin-right: auto; }
    .car-info__option-icon span {
      display: block;
      width: 100%;
      height: 100%;
      background: no-repeat center; }

.finance-calc__title {
  margin-bottom: 18px;
  font-size: 24px !important;
  line-height: 28px;
  font-weight: 400 !important;
  color: #2c2c2c; }

.finance-calc .custom-slider + .custom-slider {
  margin-top: 30px; }

.finance-calc .custom-slider .left,
.finance-calc .custom-slider .right {
  margin-bottom: 0 !important; }

.finance-calc .personalise__left-side {
  margin-bottom: 0; }

@media (max-width: 900px) {
  .finance-calc .personalise__right-side {
    margin-top: 20px; } }

.finance-calc .personalise__left-side, .finance-calc .personalise__right-side {
  max-width: 100%; }

.finance-calc .personalise__note {
  margin-bottom: 10px;
  font-size: 16px; }

.finance-calc .personalise__ext {
  margin: 20px 0; }

.finance-calc .input-block label {
  display: inline-block;
  width: 100%;
  margin-bottom: .5rem;
  cursor: pointer;
  user-select: none;
  vertical-align: top;
  font-size: 16px; }
  .finance-calc .input-block label input,
  .finance-calc .input-block label select {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 5px;
    padding: 7px 15px;
    border: 1px solid #a5a5a5;
    outline: 0;
    font-size: 16px;
    line-height: 1em;
    box-sizing: border-box; }
  .finance-calc .input-block label select {
    padding: 8.5px 15px;
    resize: none;
    outline: 0;
    background: #fff url(../img/select_hov.svg) no-repeat;
    background-position: calc(100% - 15px) center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; }

.finance-calc .personal-table__table-row:nth-child(2n-1) {
  background-color: #f7f7f7; }

.finance-calc .radiogroup--info {
  display: block; }
  .finance-calc .radiogroup--info .radiogroup__tabs-wrapper {
    display: flex;
    align-items: flex-start !important;
    border-top: none !important;
    margin-top: 20px; }
    @media (min-width: 901px) {
      .finance-calc .radiogroup--info .radiogroup__tabs-wrapper {
        margin: 20px -5px 0; } }
  .finance-calc .radiogroup--info .radiogroup__tabs {
    width: 100%; }
    @media (min-width: 901px) {
      .finance-calc .radiogroup--info .radiogroup__tabs {
        width: calc(42% - 10px);
        margin: 0 5px; } }
    .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio {
      display: inline-block;
      width: 100% !important;
      position: relative;
      margin: 0 !important; }
      .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio:not(:last-of-type) {
        margin-bottom: 14px !important; }
      .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio input:checked + span {
        border-color: #ce181f; }
        .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio input:checked + span::before {
          border: 5px solid #fff;
          background-color: #ce181f; }
      .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio span {
        display: flex;
        align-items: center;
        width: auto !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-transform: none;
        padding: 0 31px 0 40px;
        text-align: left;
        border: 1px solid #a7a7a7; }
        @media (max-width: 540px) {
          .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio span {
            font-size: 14px;
            line-height: 18px; } }
        .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio span::before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          margin-right: 12px;
          border: 1px solid #a7a7a7;
          border-radius: 50%;
          box-sizing: border-box;
          position: absolute;
          left: 11px;
          top: 50%;
          transform: translateY(-50%); }
      .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio .tip {
        display: inline-block;
        margin-left: 5px;
        margin-top: 5px;
        position: absolute;
        top: 0;
        right: 10px; }
        @media (min-width: 1024px) {
          .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio .tip:hover svg path.fill {
            fill: #a0a3a9; } }
        @media (min-width: 901px) {
          .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio .tip {
            display: none; } }
        .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio .tip svg {
          position: relative;
          top: 6px; }
        .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio .tip svg path.fill {
          fill: #a0a3a9; }
      .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio--sm {
        width: calc(50% - 11px) !important; }
        @media (min-width: 901px) {
          .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio--sm {
            width: 100% !important;
            margin: 0;
            margin-bottom: 14px; } }
        @media (max-width: 900px) {
          .finance-calc .radiogroup--info .radiogroup__tabs .radiogroup__radio--sm:first-child {
            margin-right: 16px !important; } }
  .finance-calc .radiogroup--info .radiogroup__content {
    display: none;
    width: 100%;
    padding: 25px;
    background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
    color: #2c2c2c;
    box-sizing: border-box; }
    @media (min-width: 901px) {
      .finance-calc .radiogroup--info .radiogroup__content {
        display: block;
        width: calc(58% - 10px);
        margin: 0 5px; } }
  .finance-calc .radiogroup--info .radiogroup__content-item {
    height: 166px;
    overflow: hidden; }
    @media (min-width: 901px) {
      .finance-calc .radiogroup--info .radiogroup__content-item {
        display: none; } }
    .finance-calc .radiogroup--info .radiogroup__content-item .mCSB_dragger_bar {
      background-color: #c4c4c4; }
    .finance-calc .radiogroup--info .radiogroup__content-item .mCSB_draggerRail {
      background-color: #e3e3e3; }
    .finance-calc .radiogroup--info .radiogroup__content-item--active {
      display: block; }
  .finance-calc .radiogroup--info .radiogroup__content-title {
    margin: 7px 0;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase; }
  .finance-calc .radiogroup--info .radiogroup__content-text {
    font-size: 14px;
    line-height: 22px; }
    .finance-calc .radiogroup--info .radiogroup__content-text p {
      margin-top: 17px; }

.finance-calc .radiogroup-wrap {
  display: none; }
  @media (min-width: 901px) {
    .finance-calc .radiogroup-wrap {
      display: block; } }
  .finance-calc .radiogroup-wrap--mobile {
    display: block;
    width: 100%; }
    @media (min-width: 901px) {
      .finance-calc .radiogroup-wrap--mobile {
        display: none; } }

.finance-calc .radiogroup-v2 {
  margin-top: 20px; }

@media screen and (max-width: 900px) {
  .finance-calc .custom-slider {
    max-width: calc(100% - 34px);
    margin: 0 auto; } }

.finance-calc .custom-slider .slider-val {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; }
  .finance-calc .custom-slider .slider-val .right input {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 150px;
    height: unset;
    padding: 0;
    border: 0;
    background: none;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    color: #ce171f;
    text-align: right;
    text-decoration: underline;
    cursor: pointer; }
    .finance-calc .custom-slider .slider-val .right input:focus {
      width: 100px;
      padding-left: 8px;
      height: 24px;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #2c2c2c;
      text-align-last: left;
      text-decoration: none;
      cursor: text; }
    @media screen and (max-width: 540px) {
      .finance-calc .custom-slider .slider-val .right input {
        width: 100px; } }

.finance-calc .custom-slider .slider {
  height: 4px;
  background-color: #85aded;
  position: relative;
  border-radius: 2px; }
  .finance-calc .custom-slider .slider .ui-slider-handle {
    display: block;
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border: 1px solid #124390;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 15px);
    cursor: pointer; }
    .finance-calc .custom-slider .slider .ui-slider-handle::before {
      content: "";
      width: 12px;
      height: 12px;
      background-color: #124390;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px); }
  .finance-calc .custom-slider .slider .ui-slider-range {
    height: 100%;
    border-radius: 2px;
    background-color: #ce171f; }

.finance-calc .custom-slider .slider-desc {
  display: flex;
  justify-content: space-between;
  margin-top: 17px; }
  .finance-calc .custom-slider .slider-desc .left,
  .finance-calc .custom-slider .slider-desc .right {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #2c2c2c; }

@media (min-width: 988px) {
  .finance-calc--small .radiogroup__tabs-wrapper {
    flex-direction: column; }
  .finance-calc--small .radiogroup__tabs, .finance-calc--small .radiogroup__content {
    width: 100% !important; }
  .finance-calc--small .radiogroup__tabs {
    padding-bottom: 14px; }
  .finance-calc--small .radiogroup-v2__lbl {
    width: 100%; } }

.finance-calc--small .personalise__row {
  flex-direction: column-reverse; }

.finance-calc--small .personalise__left-side {
  width: 100%;
  margin-right: 0;
  margin-bottom: 20px; }

.finance-calc--small .personalise__ext .row {
  flex-direction: column; }

.finance-calc--small .personalise__ext .blk {
  width: 100%; }

.personalise {
  background: none; }
  .personalise__inner {
    padding: 35px 0 90px;
    box-sizing: border-box; }
  .personalise__row {
    display: flex;
    margin-bottom: 10px !important; }
  .personalise__title {
    margin-bottom: 35px;
    font-size: 24px;
    line-height: 29px;
    font-weight: bold;
    text-align: center; }
  .personalise__left-side {
    width: 50%;
    flex-shrink: 0;
    margin-right: 40px;
    margin-bottom: 20px;
    position: relative;
    box-sizing: border-box; }
  .personalise__right-side {
    width: 100%; }
  .personalise__button {
    width: 100%;
    margin-top: 33px;
    font-size: 16px;
    font-weight: 700; }
  .personalise__zag {
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 2px solid #e4e4e4;
    font-size: 18px;
    line-height: 21px; }
  .personalise .input-block label {
    width: 100%; }
  .personalise .input-block input,
  .personalise .input-block select {
    margin-top: 5px; }
  .personalise .input-block select {
    background: #fff url(../img/select_hov.svg) no-repeat;
    background-position: calc(100% - 15px) center; }

.personal-table__title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #e4e4e4;
  font-size: 18px;
  line-height: 21px; }

.personal-table__table {
  display: table;
  width: 100%; }

.personal-table__table-row {
  display: table-row;
  width: 100%; }
  .personal-table__table-row:nth-child(2n+1) {
    background-color: #fff; }

.personal-table__table-cell {
  display: table-cell;
  height: 32px;
  font-size: 16px;
  line-height: 19px;
  color: #2c2c2c;
  text-align: left;
  vertical-align: middle;
  box-sizing: border-box; }
  @media (max-width: 450px) {
    .personal-table__table-cell {
      font-size: 12px; } }
  .personal-table__table-cell__w30 {
    width: 30%; }
  .personal-table__table-cell--w30 {
    padding-right: 10px;
    font-weight: 400;
    text-align: right; }
  .personal-table__table-cell--w70 {
    width: 70%;
    padding-left: 11px; }

.calc-new {
  max-width: 724px;
  width: 100%;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
  padding: 27px 46px 27px 28px;
  box-sizing: border-box; }
  @media (max-width: 1100px) {
    .calc-new {
      padding: 27px 37px 0 28px;
      margin: 0 auto; } }
  .calc-new__bottom {
    display: flex;
    margin: 34px 0 0;
    position: relative; }
    @media (max-width: 1100px) {
      .calc-new__bottom {
        flex-direction: column; } }
  .calc-new__block {
    width: 105%;
    max-width: 310px; }
    @media (max-width: 1100px) {
      .calc-new__block {
        width: 100%;
        max-width: 100%;
        margin: 0; } }
  .calc-new__ext {
    margin-top: 15px; }
    @media (max-width: 1100px) {
      .calc-new__ext {
        display: none; } }
    .calc-new__ext .finance__block .row {
      flex-direction: column;
      margin-top: 15px; }
      .calc-new__ext .finance__block .row .blk {
        width: 100%; }
    .calc-new__ext .btn-finance-explained {
      display: block;
      width: 236px;
      height: 30px;
      font-size: 14px;
      line-height: 19px;
      text-transform: none;
      box-sizing: border-box; }
  .calc-new .calc-new-result {
    width: 375px;
    margin-left: 37px;
    margin-right: -75px;
    margin-bottom: -61px;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    padding: 28px 36px 22px 39px;
    box-sizing: border-box; }
    @media (max-width: 1100px) {
      .calc-new .calc-new-result {
        max-width: calc(100% + 67px);
        width: calc(100% + 67px);
        margin: 0 0 0 -29px; } }
    .calc-new .calc-new-result__title {
      font-size: 20px;
      margin-bottom: 7px; }
    .calc-new .calc-new-result__subtitle {
      font-size: 15px;
      color: #9da4b0;
      margin-bottom: 13px; }
    .calc-new .calc-new-result__sum {
      padding-bottom: 20px;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 12px; }
      .calc-new .calc-new-result__sum p:first-child {
        font-size: 15px;
        margin: 0 0 9px; }
      .calc-new .calc-new-result__sum p:last-child {
        font-size: 26px;
        margin: 0; }
        .calc-new .calc-new-result__sum p:last-child span {
          font-size: 16px;
          padding-left: 14px; }
    .calc-new .calc-new-result table {
      width: 100%; }
      .calc-new .calc-new-result table td {
        padding: 8px 0; }
        .calc-new .calc-new-result table td:first-child {
          width: 73%;
          font-size: 14px;
          color: #9da4b0; }
        .calc-new .calc-new-result table td:last-child {
          width: 27%;
          font-size: 14px;
          text-align: right; }
  .calc-new .input-block {
    margin-bottom: 23px; }
    .calc-new .input-block p {
      width: 100%;
      font-size: 16px;
      margin: 0 5px 12px; }
    .calc-new .input-block label {
      width: 100%;
      margin-bottom: 0; }
    .calc-new .input-block input,
    .calc-new .input-block select {
      margin-top: 4px;
      margin-bottom: 0;
      border-radius: 3px; }
    .calc-new .input-block select {
      background: #fff url(../img/select_hov.svg) no-repeat;
      background-position: calc(100% - 15px) center; }
    .calc-new .input-block--radio {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px; }
      .calc-new .input-block--radio .radio-label {
        width: calc(20% - 10px);
        margin: 0 5px 10px;
        height: 41px; }
        @media (max-width: 1100px) {
          .calc-new .input-block--radio .radio-label {
            width: calc(33.3333% - 10px); } }
        @media (max-width: 400px) {
          .calc-new .input-block--radio .radio-label {
            width: calc(50% - 10px); } }
        .calc-new .input-block--radio .radio-label span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding: 15px;
          border: 1px solid #d9d9d9;
          background-color: #fff;
          border-radius: 3px;
          color: #2c2c2c;
          box-sizing: border-box; }
          @media (max-width: 1100px) {
            .calc-new .input-block--radio .radio-label span {
              padding: 10px;
              text-align: center; } }
        .calc-new .input-block--radio .radio-label input {
          display: none; }
          .calc-new .input-block--radio .radio-label input:checked + span {
            border-color: #ce181f;
            background-color: #ce181f;
            color: #fff; }
    @media (max-width: 1100px) {
      .calc-new .input-block--big {
        display: none; } }
    .calc-new .input-block--big .radio-label {
      width: calc(50% - 10px);
      margin-bottom: 10px; }
      .calc-new .input-block--big .radio-label--big {
        width: 100%; }
    .calc-new .input-block--mobile {
      display: none; }
      @media (max-width: 1100px) {
        .calc-new .input-block--mobile {
          display: flex;
          margin-bottom: 30px; } }
  .calc-new--mobile {
    display: none;
    border: none;
    background-color: #fff;
    padding: 20px 0 0; }
    @media (max-width: 1100px) {
      .calc-new--mobile {
        display: block; } }
    .calc-new--mobile .calc-new__ext {
      display: none;
      margin-top: 0;
      margin-bottom: 20px; }
      @media (max-width: 1100px) {
        .calc-new--mobile .calc-new__ext {
          display: block; } }
    .calc-new--mobile .btn {
      width: 100%; }

.bqr-iwt-item__pic--parts-request img {
  display: block;
  height: 24px;
  width: 28px;
  object-fit: contain;
  object-position: center center; }

.bqr-main-flex-right-desc--gray {
  font-family: "Myriad Pro", sans-serif;
  color: #4d4d4d; }

.form-block button.bqr-form-btn-look-up--pr, .btn.btn-submit-bqr--pr {
  font-weight: 400; }

.parts-req-upload-photos {
  display: block;
  width: 150px;
  height: 35px;
  line-height: 35px;
  font-size: 13px;
  margin-top: 17px;
  cursor: pointer; }
  @media (max-width: 900px) {
    .parts-req-upload-photos {
      margin-left: auto;
      margin-right: auto; } }
  .parts-req-upload-photos:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    background: url(../img/img.svg) no-repeat center center;
    margin-right: 9px;
    margin-top: -2px; }

@media (max-width: 900px) {
  .bqr-page .inner2 {
    width: 90%; } }

.bqr-page .full-width-banner {
  margin-top: 15px;
  margin-bottom: 45px; }
  @media (max-width: 900px) {
    .bqr-page .full-width-banner {
      margin-top: 5px;
      margin-bottom: 12px;
      min-height: 162px; } }
  .bqr-page .full-width-banner img {
    min-height: 162px;
    object-fit: cover;
    object-position: right; }

.bqr-page .bqr-main-flex__mobile {
  display: none; }
  @media (max-width: 900px) {
    .bqr-page .bqr-main-flex__mobile {
      display: block;
      margin-top: 55px; }
      .bqr-page .bqr-main-flex__mobile .bqr-main-flex__h2 {
        font-size: 20px; }
      .bqr-page .bqr-main-flex__mobile .call-us-on-btn {
        margin-top: 0; } }

.bqr-page .bqr-main-flex__desk {
  margin-top: 28px; }
  @media (max-width: 900px) {
    .bqr-page .bqr-main-flex__desk {
      display: none; } }

.bqr-page .bqr-main-flex__h2 {
  line-height: 30px; }
  @media (max-width: 900px) {
    .bqr-page .bqr-main-flex__h2 {
      font-size: 18px;
      line-height: 24px;
      text-align: center; } }
  .bqr-page .bqr-main-flex__h2 + * {
    margin-top: 10px; }
    @media (max-width: 900px) {
      .bqr-page .bqr-main-flex__h2 + * {
        text-align: center; } }
  @media (max-width: 900px) {
    .bqr-page .bqr-main-flex__h2--list {
      display: block !important; } }
  .bqr-page .bqr-main-flex__h2--top span {
    display: none; }
    @media (max-width: 900px) {
      .bqr-page .bqr-main-flex__h2--top span {
        display: inline; } }
  .bqr-page .bqr-main-flex__h2--top br {
    display: none; }
    @media (max-width: 900px) {
      .bqr-page .bqr-main-flex__h2--top br {
        display: block; } }
  .bqr-page .bqr-main-flex__h2--red {
    text-transform: uppercase; }
    @media (max-width: 900px) {
      .bqr-page .bqr-main-flex__h2--red {
        font-size: 13px;
        text-transform: none;
        color: #ce1820; }
        .bqr-page .bqr-main-flex__h2--red + p {
          display: none; } }
    .bqr-page .bqr-main-flex__h2--red span {
      display: none; }
      @media (max-width: 900px) {
        .bqr-page .bqr-main-flex__h2--red span {
          display: inline; } }

.bqr-page .bqr-main-flex-left p + .bqr-main-flex__h2 {
  margin-top: 40px; }
  @media (max-width: 900px) {
    .bqr-page .bqr-main-flex-left p + .bqr-main-flex__h2 {
      margin-top: 9px; } }

@media (max-width: 900px) {
  .bqr-page .bqr-main-flex-right {
    margin-top: 19px; } }

@media (max-width: 900px) {
  .bqr-page .bqr-main-flex-right .bqr-main-flex__h2,
  .bqr-page .bqr-main-flex-right .bqr-main-flex-right-desc {
    display: none; } }

.bqr-page .bqr-icons-with-text {
  margin-top: 26px; }
  @media (max-width: 900px) {
    .bqr-page .bqr-icons-with-text {
      text-align: left;
      margin-bottom: 35px; } }

.bqr-page .bqr-iwt-item__text {
  font-size: 15px;
  line-height: 20px; }

.bqr-page .call-us-on-btn {
  padding: 14px 10px;
  color: #2c2c2c; }
  @media (max-width: 500px) {
    .bqr-page .call-us-on-btn__number {
      font-size: 20px; } }

.bqr-page .new-quote-request.bqr-wrap {
  margin-top: 21px; }

.bqr-page .new-quote-request .input-block {
  margin-bottom: 18px; }
  .bqr-page .new-quote-request .input-block input,
  .bqr-page .new-quote-request .input-block select,
  .bqr-page .new-quote-request .input-block textarea {
    border-radius: 3px; }
  .bqr-page .new-quote-request .input-block textarea {
    height: 130px; }
    @media (max-width: 900px) {
      .bqr-page .new-quote-request .input-block textarea {
        height: 150px; } }
  .bqr-page .new-quote-request .input-block label {
    width: 100%; }

.bqr-page .new-quote-request .input-row--lookup {
  align-items: flex-end; }
  @media (max-width: 900px) {
    .bqr-page .new-quote-request .input-row--lookup {
      display: flex !important; } }

.bqr-page .new-quote-request .bqr-form-btn-look-up {
  height: 40px;
  font-size: 15px;
  line-height: 40px;
  border-radius: 5px;
  margin-bottom: 0;
  margin-left: 0;
  max-width: 192px; }
  @media (max-width: 900px) {
    .bqr-page .new-quote-request .bqr-form-btn-look-up {
      max-width: 151px; } }

.bqr-page .new-quote-request .bqr-images-block {
  margin-top: 24px; }
  @media (max-width: 900px) {
    .bqr-page .new-quote-request .bqr-images-block {
      text-align: center;
      margin-top: 18px; } }
  .bqr-page .new-quote-request .bqr-images-block p {
    font-size: 16px;
    line-height: 24px; }

.bqr-page .new-quote-request .parts-req-upload-photos {
  width: 180px;
  height: 40px;
  border-radius: 5px;
  font-size: 15px;
  line-height: 40px;
  margin-top: 9px; }

.bqr-page .new-quote-request .bqr-form-bottom {
  display: flex;
  align-items: center;
  margin-top: 30px; }
  @media (max-width: 900px) {
    .bqr-page .new-quote-request .bqr-form-bottom {
      flex-direction: column;
      margin-top: 21px; } }

.bqr-page .new-quote-request .btn-submit-bqr {
  width: 266px;
  height: 40px;
  margin-top: 0;
  margin-right: 20px;
  border-radius: 5px;
  font-size: 16px;
  line-height: 40px;
  font-weight: bold;
  box-sizing: border-box; }
  @media (max-width: 900px) {
    .bqr-page .new-quote-request .btn-submit-bqr {
      max-width: 342px;
      width: 100%;
      order: 1;
      margin-top: 10px;
      margin-right: 0; } }

.bqr-page .new-quote-request .bqr-form-privacy-policy {
  margin: 0; }

@media (max-width: 900px) {
  .bqr-page .up-file {
    display: flex;
    flex-direction: column;
    align-items: center; } }

.bqr-page .up-file input {
  display: none; }

.bqr-page .up-file__list {
  margin-top: 10px; }

.bqr-page .up-file__list-item {
  display: none;
  align-items: center; }
  .bqr-page .up-file__list-item + .up-file__list-item {
    margin-top: 8px; }

.bqr-page .up-file__name {
  font-size: 14px;
  text-decoration: underline;
  color: #CE171F; }

.bqr-page .up-file .btn-remove {
  width: 14px;
  height: 14px;
  background: url(../img/close-btn-2.png) no-repeat center center;
  background-size: contain;
  margin-left: 10px;
  cursor: pointer; }
  .bqr-page .up-file .btn-remove::before {
    display: none; }

.new-quote-request {
  padding-bottom: 50px; }
  .new-quote-request .inner {
    max-width: 560px; }
  .new-quote-request .page-main-title {
    margin-bottom: 75px; }
  .new-quote-request .ah1 {
    font-size: 24px;
    line-height: 27px;
    font-weight: bold;
    margin-bottom: 25px; }
    @media (max-width: 768px) {
      .new-quote-request .ah1 {
        text-align: center; } }
  .new-quote-request .number-line {
    font-size: 16px;
    margin-bottom: 40px; }
    @media (max-width: 768px) {
      .new-quote-request .number-line {
        text-align: center; } }
  .new-quote-request .input-block {
    margin-bottom: 25px; }
  .new-quote-request .input-row {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 768px) {
      .new-quote-request .input-row {
        display: block; } }
    .new-quote-request .input-row .input-block {
      width: calc(50% - 10px); }
      @media (max-width: 768px) {
        .new-quote-request .input-row .input-block {
          width: auto; } }
    .new-quote-request .input-row .gb-reg-number {
      position: relative;
      background: transparent; }
      .new-quote-request .input-row .gb-reg-number input {
        padding-left: 65px; }
    .new-quote-request .input-row .gb-reg-number:before {
      content: '';
      display: block;
      width: 40px;
      height: 40px;
      background: url(../img/new-gb-rn.png);
      position: absolute;
      left: 0;
      top: 4px;
      z-index: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px; }
  .new-quote-request .images-block {
    font-size: 16px; }
    @media (max-width: 768px) {
      .new-quote-request .images-block {
        text-align: center; } }
    .new-quote-request .images-block .ah2 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px; }
      @media (max-width: 768px) {
        .new-quote-request .images-block .ah2 {
          text-align: center; } }
    .new-quote-request .images-block .button--red {
      display: block;
      width: 150px;
      height: 35px;
      line-height: 35px;
      font-size: 13px;
      margin-top: 17px; }
      @media (max-width: 768px) {
        .new-quote-request .images-block .button--red {
          margin-left: auto;
          margin-right: auto; } }
      .new-quote-request .images-block .button--red:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 18px;
        background: url("../img/img.svg") no-repeat center center;
        margin-right: 9px;
        margin-top: -2px; }
      .new-quote-request .images-block .button--red.make-photo {
        display: none;
        margin-bottom: 17px; }
        @media (max-width: 1000px) {
          .new-quote-request .images-block .button--red.make-photo {
            display: block; } }
        .new-quote-request .images-block .button--red.make-photo::before {
          background: url("../img/make-photo.svg") no-repeat center center; }
  .new-quote-request .thumbs {
    margin: -10.5px; }
    .new-quote-request .thumbs .flex-block {
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px; }
      @media (max-width: 380px) {
        .new-quote-request .thumbs .flex-block {
          margin: 25px 10px 0; } }
    .new-quote-request .thumbs .thumb {
      width: 172px;
      height: 131px;
      margin: 10.5px;
      border: 1px solid #e6e6e6;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: 100% auto !important;
      position: relative;
      box-sizing: border-box; }
      @media (max-width: 380px) {
        .new-quote-request .thumbs .thumb {
          width: calc(50% - 10px);
          margin: 5px; } }
      .new-quote-request .thumbs .thumb .selection {
        width: 15px;
        height: 15px;
        border-radius: 2px;
        background: url("../img/red_x.svg") no-repeat center center;
        background-size: 10px auto;
        background-color: rgba(255, 255, 255, 0.5);
        position: absolute;
        right: 11.5px;
        top: 10.5px;
        cursor: pointer; }
    .new-quote-request .thumbs .upload-more {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 172px;
      height: 131px;
      margin: 10.5px;
      border: 1px solid #e6e6e6;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      background-size: 100% auto !important;
      position: relative;
      box-sizing: border-box; }
      @media (max-width: 380px) {
        .new-quote-request .thumbs .upload-more {
          width: calc(50% - 10px);
          margin: 5px; } }
      .new-quote-request .thumbs .upload-more input {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer; }
      .new-quote-request .thumbs .upload-more p {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 13px;
        line-height: 25px;
        font-weight: 400;
        color: #026fa8; }
  .new-quote-request .btn--blue {
    display: block;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer; }
  .new-quote-request .lookup {
    margin-bottom: 25px; }
    .new-quote-request .lookup .btn_red {
      display: inline-block;
      width: 220px;
      margin-top: 0;
      background: #ce181f;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      vertical-align: top; }
      @media (max-width: 1000px) {
        .new-quote-request .lookup .btn_red {
          display: block;
          margin-right: auto;
          margin-left: auto; } }
      .new-quote-request .lookup .btn_red:hover {
        background: #2c2c2c; }
    .new-quote-request .lookup .no_trade {
      display: inline-block;
      margin-left: 20px;
      vertical-align: top;
      font-size: 18px;
      line-height: 46px;
      color: #ce171f;
      text-decoration: underline; }
      @media (max-width: 1000px) {
        .new-quote-request .lookup .no_trade {
          display: block;
          margin-top: 0;
          margin-left: 0;
          text-align: center; } }
  .new-quote-request .car_det .ah2 {
    margin-bottom: 10px; }
    @media (max-width: 1000px) {
      .new-quote-request .car_det .ah2 {
        text-align: center; } }
    .new-quote-request .car_det .ah2 a {
      margin-left: 17px;
      font-size: 14px;
      color: #ce171f;
      text-decoration: underline; }
  .new-quote-request .car_det table {
    width: 100%;
    margin-bottom: 25px;
    border-bottom: 2px solid #e6e6e6; }
    .new-quote-request .car_det table tr:nth-child(odd) {
      background: #f2f2f2; }
    .new-quote-request .car_det table td {
      width: 25%;
      padding: 13px 18px; }
      .new-quote-request .car_det table td .ah3 {
        margin-bottom: 5px;
        font-size: 13px;
        color: #757575; }

.bqr-page {
  padding-bottom: 50px; }
  .bqr-page .form-after-submit {
    width: 460px;
    max-width: 100%;
    text-align: left;
    margin: 0 auto; }
    .bqr-page .form-after-submit .form-title {
      line-height: 1.6;
      margin-bottom: 1em;
      font-weight: 400; }

.full-width-banner {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 60px; }
  .full-width-banner img {
    width: 100%;
    max-width: 100%;
    height: auto; }

.bqr-main-flex {
  display: flex;
  justify-content: space-between; }

.bqr-main-flex-left {
  width: calc(50% - 25px); }
  .bqr-main-flex-left p {
    color: #4d4d4d;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px; }
    .bqr-main-flex-left p + p {
      margin-top: 20px; }
    .bqr-main-flex-left p + .bqr-main-flex__h2 {
      margin-top: 35px; }
  .bqr-main-flex-left .bqr-main-flex__h2 + * {
    margin-top: 30px; }

.bqr-main-flex__h2 {
  font-weight: 400;
  font-size: 24px;
  line-height: 33px; }

.bqr-iwt-item {
  display: flex;
  align-items: center; }
  .bqr-iwt-item__pic {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    min-width: 37px;
    height: 37px;
    margin-right: 11px;
    border: 1px solid #ce171f;
    border-radius: 50%;
    background-color: #ce1820; }
  .bqr-iwt-item__text {
    color: #4d4d4d;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px; }
  .bqr-iwt-item + .bqr-iwt-item {
    margin-top: 19px; }

.call-us-on-btn {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;
  padding: 18px 10px;
  cursor: pointer;
  transition-duration: .2s;
  border: 1px solid #e6e6e6;
  background-color: #ffffff; }
  .call-us-on-btn:hover {
    background-color: #f6f6f6; }
  .call-us-on-btn__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.1; }
  .call-us-on-btn__icon {
    margin: 0 11px; }
  .call-us-on-btn__number {
    font-weight: 400;
    font-size: 22px;
    line-height: 1.1; }

.bqr-main-flex-right {
  width: calc(50% - 25px); }

.bqr-main-flex-right-desc {
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; }

.new-quote-request.bqr-wrap {
  margin-top: 25px; }

.form-block.bqr-form .bqr-your-vehicle {
  margin-top: -10px;
  margin-bottom: 25px;
  color: #808080;
  font-family: "Vauxhall Neue";
  font-weight: 400;
  font-size: 16px;
  line-height: 28px; }
  .form-block.bqr-form .bqr-your-vehicle span {
    margin-left: 8px;
    color: #2c2c2c; }

.form-block button.bqr-form-btn-look-up {
  margin-top: 0;
  color: #ffffff;
  background-color: #ce1820; }
  .form-block button.bqr-form-btn-look-up:hover {
    background-color: #58585a; }

.bqr-wrap .input-row--lookup .input-block {
  width: calc(100% - 192px - 20px); }
  .bqr-wrap .input-row--lookup .input-block:nth-child(2) {
    width: 192px; }

.bqr-images-block .ah2 {
  color: #2c2c2c;
  font-weight: bold;
  font-size: 18px;
  line-height: 28px; }

.bqr-images-block p {
  color: #4d4d4d; }

.bqr-images-block .thumbs .flex-block .thumb,
.bqr-images-block .thumbs .flex-block .upload-more {
  width: calc(33% - 20px);
  margin: 0 10px 20px; }

.bqr-form-privacy-policy {
  margin-top: 29px;
  margin-bottom: -20px;
  font-family: "Myriad Pro", sans-serif;
  color: #4d4d4d;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px; }
  .bqr-form-privacy-policy a {
    font-family: "Myriad Pro", sans-serif;
    text-decoration: underline;
    color: #4d4d4d; }
    .bqr-form-privacy-policy a:hover {
      text-decoration: none; }

.btn.btn-submit-bqr {
  margin-left: 0;
  font-weight: bold;
  font-size: 18px; }

@media screen and (max-width: 900px) {
  .bqr-main-flex {
    align-items: center;
    flex-direction: column; }
  .bqr-main-flex-left {
    width: 100%; }
  .bqr-main-flex-right {
    width: 100%;
    margin-top: 30px; }
  .bqr-wrap .input-row--lookup .input-block {
    width: 100%;
    margin-bottom: 10px; }
  .bqr-wrap .input-row--lookup .input-block:nth-child(2) {
    width: 100%; }
    .bqr-wrap .input-row--lookup .input-block:nth-child(2) label {
      display: none; }
    .bqr-wrap .input-row--lookup .input-block:nth-child(2) button {
      max-width: 100%; }
  .input-row--lookup {
    display: block !important; }
  .btn-submit-bqr {
    width: 100%;
    box-sizing: border-box; }
  .bqr-wrap {
    padding-bottom: 0; } }

@media screen and (max-width: 500px) {
  .bqr-images-block .thumbs .flex-block .thumb,
  .bqr-images-block .thumbs .flex-block .upload-more {
    width: calc(50% - 20px); }
  .bqr-iwt-item + .bqr-iwt-item {
    margin-top: 9px; }
  .bqr-main-flex-left p + .bqr-main-flex__h2 {
    margin-top: 20px; }
  .bqr-main-flex-left p + p {
    margin-top: 10px; }
  .bqr-main-flex-left p {
    font-size: 14px;
    line-height: 1.3; }
  .bqr-main-flex__h2 {
    font-size: 21px;
    line-height: 1.3; }
  .bqr-main-flex-left .bqr-main-flex__h2 + * {
    margin-top: 20px; }
  .full-width-banner {
    margin-bottom: 35px; } }

.booking {
  padding-bottom: 100px; }
  .booking .input-block .radio-label {
    display: flex !important; }
    @media (max-width: 767px) {
      .booking .input-block .radio-label {
        width: 100%; } }
  .booking .input-block label.checkbox {
    display: inline-flex;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer; }
    .booking .input-block label.checkbox span {
      font-size: 20px;
      padding-left: 12px; }
    .booking .input-block label.checkbox input {
      max-width: 20px;
      width: 100%;
      height: 20px;
      margin: 0;
      border: 1px solid #a5a5a5;
      border-radius: 3px;
      background-color: #fff;
      cursor: pointer;
      box-sizing: border-box;
      appearance: none; }
      .booking .input-block label.checkbox input:checked {
        background-image: url(../img/checked.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%; }
  .booking .tip {
    margin-left: 0;
    transition: fill 0.3s ease; }
    @media (min-width: 1024px) {
      .booking .tip:hover {
        fill: #ce171f; } }
  @media (max-width: 767px) {
    .booking__container {
      width: 89%; } }
  @media (max-width: 767px) {
    .booking__breadcrumbs {
      margin-top: 8px; } }
  .booking__booking-banner {
    margin: 13px 0 32px; }
    @media (max-width: 767px) {
      .booking__booking-banner {
        margin: 9px 0 19px; } }
  .booking__intermediate-text {
    font-size: 18px;
    line-height: 29px;
    text-align: center; }
    @media (max-width: 767px) {
      .booking__intermediate-text {
        font-size: 14px;
        line-height: 23px; } }
  .booking__advantages {
    margin: 42px 0 54px;
    position: relative; }
    @media (max-width: 767px) {
      .booking__advantages {
        margin: 46px 0 35px; } }
    .booking__advantages::before {
      content: '';
      display: block;
      width: 100%;
      height: 90px;
      background-color: #f6f6f6;
      position: absolute;
      top: 31px;
      left: 0; }
  .booking__flex-container {
    display: flex;
    justify-content: center; }
    @media (max-width: 767px) {
      .booking__flex-container {
        flex-direction: column; } }
  .booking__advantages-item {
    max-width: 310px;
    width: 100%; }
    @media (max-width: 767px) {
      .booking__advantages-item {
        max-width: 100%; } }
    .booking__advantages-item:not(:last-child) {
      margin-right: 30px; }
      @media (max-width: 767px) {
        .booking__advantages-item:not(:last-child) {
          margin-right: 0;
          margin-bottom: -1px; } }
    @media (max-width: 767px) {
      .booking__advantages-item:last-child .advantage-card__inner {
        padding-bottom: 55px; } }
  .booking__main-title {
    font-size: 30px;
    margin-bottom: 26px;
    text-align: center; }
    @media (max-width: 767px) {
      .booking__main-title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 14px; } }
  .booking__phone {
    font-size: 15px;
    text-align: center;
    margin-bottom: 45px; }
    @media (max-width: 767px) {
      .booking__phone {
        margin-bottom: 35px; } }
    @media (min-width: 1024px) {
      .booking__phone:hover a {
        color: #ce171f; } }
    .booking__phone img {
      padding: 0 8px; }
    .booking__phone a {
      font-size: 20px;
      color: #2c2c2c;
      transition: color 0.3s ease; }
  .booking__first {
    max-width: 991px;
    margin: 0 auto; }
  .booking__first-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -12px; }
    @media (max-width: 767px) {
      .booking__first-wrapper {
        margin: 0; } }
    .booking__first-wrapper .input-block {
      width: calc(33.3333% - 24px);
      margin: 0 12px; }
      @media (max-width: 767px) {
        .booking__first-wrapper .input-block {
          width: 100%;
          margin: 0 0 23px; } }
      .booking__first-wrapper .input-block label {
        width: 100%; }
        @media (max-width: 1023px) {
          .booking__first-wrapper .input-block label {
            font-size: 12px !important; } }
        @media (max-width: 767px) {
          .booking__first-wrapper .input-block label {
            font-size: 14px !important; } }
  .booking__main {
    display: flex; }
  .booking__left {
    max-width: 750px;
    width: 100%; }
    @media (max-width: 1100px) {
      .booking__left {
        margin: 0 auto; } }
  .booking__step + .booking__step {
    border-top: 1px solid #e6e6e6; }
  .booking__step--2 {
    margin-top: 37px; }
    @media (max-width: 767px) {
      .booking__step--2 {
        margin-top: 56px; } }
  .booking__step--3 {
    margin-top: 39px; }
    @media (max-width: 767px) {
      .booking__step--3 {
        margin-top: 50px; } }
  .booking__title {
    font-size: 20px;
    margin-bottom: 21px; }
    @media (max-width: 767px) {
      .booking__title {
        margin-bottom: 17px; } }
    .booking__title--toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 18px 15px 0;
      margin-bottom: 0;
      border-bottom: 1px solid #e6e6e6;
      cursor: pointer; }
      @media (max-width: 767px) {
        .booking__title--toggle {
          padding: 20px 0; } }
    .booking__title--details {
      margin-bottom: 32px; }
      @media (max-width: 767px) {
        .booking__title--details {
          margin-bottom: 20px; } }
    .booking__title--address {
      margin-bottom: 26px; }
      @media (max-width: 767px) {
        .booking__title--address {
          margin-bottom: 23px; } }
  .booking__toggle-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    background-color: #ce171f;
    cursor: pointer; }
    .booking__toggle-btn.rotate svg {
      transform: rotate(270deg); }
    .booking__toggle-btn svg {
      transform: rotate(90deg);
      transition: transform 0.3s ease; }
  .booking__block + .booking__block {
    border-top: 1px solid #e6e6e6; }
  .booking__block--branch {
    padding-top: 28px;
    margin-top: 31px; }
    @media (max-width: 767px) {
      .booking__block--branch {
        padding-top: 30px;
        margin-top: 22px; } }
  .booking__block--service {
    padding-top: 31px;
    margin-top: 26px; }
    @media (max-width: 767px) {
      .booking__block--service {
        padding-top: 30px;
        margin-top: 41px; } }
    .booking__block--service .input-block--service-type {
      margin: 16px 0 12px; }
      .booking__block--service .input-block--service-type.inactive select {
        opacity: 0.5;
        background-color: #f7f7f7;
        pointer-events: none; }
      .booking__block--service .input-block--service-type select {
        height: 35px; }
    .booking__block--service .input-block--mot {
      margin-top: 41px;
      margin-bottom: 18px; }
      @media (max-width: 767px) {
        .booking__block--service .input-block--mot {
          margin-top: 32px; } }
    .booking__block--service .input-block--radio {
      margin-bottom: 18px; }
      .booking__block--service .input-block--radio label {
        font-size: 16px !important; }
      .booking__block--service .input-block--radio input {
        margin-right: 4px !important; }
    .booking__block--service .input-block--tooltip {
      display: flex; }
      .booking__block--service .input-block--tooltip .radio-label {
        flex-basis: 55%;
        align-items: center; }
        @media (max-width: 767px) {
          .booking__block--service .input-block--tooltip .radio-label {
            flex-basis: 67%;
            max-width: 67%;
            line-height: 21px; } }
      .booking__block--service .input-block--tooltip svg {
        margin-right: 7px; }
        @media (max-width: 767px) {
          .booking__block--service .input-block--tooltip svg {
            margin-right: 0; } }
  .booking__block--table-1 .booking__table-wrapper {
    margin-bottom: 37px; }
    @media (max-width: 767px) {
      .booking__block--table-1 .booking__table-wrapper {
        margin-bottom: 32px; } }
  .booking__block--table-2 {
    border-top: 0; }
  .booking__block--customer {
    padding-top: 39px; }
    @media (max-width: 767px) {
      .booking__block--customer {
        padding-top: 51px; } }
    .booking__block--customer.without-table {
      padding-top: 0; }
    .booking__block--customer .input-block--checkbox {
      margin: 38px 0 21px; }
      @media (max-width: 767px) {
        .booking__block--customer .input-block--checkbox {
          margin: 46px 0 21px; } }
      .booking__block--customer .input-block--checkbox label.checkbox span {
        font-size: 15px;
        line-height: 24px;
        padding-left: 8px;
        margin-top: -3px; }
    .booking__block--customer .input-block--textarea label {
      width: 100%; }
    .booking__block--customer .input-block--textarea textarea {
      height: 131px;
      padding: 13px 15px; }
      @media (max-width: 767px) {
        .booking__block--customer .input-block--textarea textarea {
          height: 181px; } }
  .booking__block--personal {
    padding-top: 39px; }
    @media (max-width: 767px) {
      .booking__block--personal {
        padding-top: 29px; } }
    .booking__block--personal .input-block {
      margin-bottom: 20px; }
      .booking__block--personal .input-block label {
        width: 100%; }
  .booking__branch {
    margin: 26px -12px 0; }
    @media (max-width: 767px) {
      .booking__branch {
        margin: 0; } }
  @media (min-width: 1024px) {
    .booking__includes:hover span {
      text-decoration: none; } }
  .booking__includes span {
    font-size: 14px;
    color: #ce171f;
    padding-left: 2px;
    text-decoration: underline; }
  .booking__includes-tip {
    display: inline-flex;
    align-items: center;
    cursor: pointer; }
  .booking__table {
    width: 100%; }
    .booking__table tr:nth-child(odd) {
      background-color: #f2f2f2; }
    .booking__table td {
      font-size: 15px;
      vertical-align: middle;
      padding: 7px 10px; }
      @media (max-width: 767px) {
        .booking__table td {
          font-size: 14px;
          padding: 12px 6px; } }
      .booking__table td .radio-label input {
        margin-right: 0 !important; }
      .booking__table td span.blue {
        color: #124390; }
      .booking__table td.price {
        font-size: 16px;
        text-align: right; }
        @media (max-width: 767px) {
          .booking__table td.price {
            font-size: 15px;
            padding-left: 0;
            padding-right: 0; } }
      .booking__table td:first-child {
        width: 73%; }
        @media (max-width: 767px) {
          .booking__table td:first-child {
            width: 56%; } }
      .booking__table td:nth-child(2) {
        width: 14%; }
        @media (max-width: 767px) {
          .booking__table td:nth-child(2) {
            width: 24%; } }
      .booking__table td:nth-child(3) {
        width: 6%; }
        @media (max-width: 767px) {
          .booking__table td:nth-child(3) {
            width: 10%; } }
      .booking__table td:last-child {
        width: 7%; }
        @media (max-width: 767px) {
          .booking__table td:last-child {
            width: 10%; } }
    .booking__table--font td:first-child {
      font-size: 15px; }
  .booking .form-block .booking__submit, .booking .form-block .booking__continue {
    max-width: 241px;
    width: 100%;
    margin-top: 27px; }
    @media (max-width: 767px) {
      .booking .form-block .booking__submit, .booking .form-block .booking__continue {
        max-width: 345px;
        margin: 18px auto 0; } }
  .booking .form-block .booking__continue--first {
    max-width: 318px;
    height: 46px;
    margin: 40px auto 0; }
    @media (max-width: 767px) {
      .booking .form-block .booking__continue--first {
        max-width: 344px;
        margin: 17px auto 0; } }
  .booking .booking-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 350px;
    padding: 42px 50px 50px;
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box; }
    @media (max-width: 767px) {
      .booking .booking-banner {
        height: 162px;
        padding: 22px 18px 10px; } }
    .booking .booking-banner__title {
      font-size: 32px;
      line-height: 39px;
      font-weight: bold;
      margin-bottom: 12px;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .booking .booking-banner__title {
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 8px; } }
    .booking .booking-banner__subtitle {
      font-size: 24px;
      color: #fffefe;
      margin-bottom: 22px; }
      @media (max-width: 767px) {
        .booking .booking-banner__subtitle {
          font-size: 16px; } }
    .booking .booking-banner__btn {
      display: block;
      width: 192px; }
      @media (max-width: 767px) {
        .booking .booking-banner__btn {
          display: none; } }
      .booking .booking-banner__btn--mobile {
        display: none; }
        @media (max-width: 767px) {
          .booking .booking-banner__btn--mobile {
            display: block;
            margin: 18px auto 0; } }
  .booking .advantage-card {
    position: relative; }
    .booking .advantage-card::before {
      content: '';
      display: block;
      width: 77%;
      height: 5%;
      border-radius: 5px;
      background-color: #ce171f;
      position: absolute;
      top: -3px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1; }
      @media (max-width: 767px) {
        .booking .advantage-card::before {
          width: 93%; } }
    .booking .advantage-card__inner {
      padding: 33px 25px 56px 35px;
      border: 1px solid #d9d9d9;
      border-radius: 5px;
      background-color: #ffffff; }
      @media (max-width: 767px) {
        .booking .advantage-card__inner {
          padding: 33px 65px 37px 73px;
          margin: 0 -2%;
          border-radius: 0;
          border-left: 0;
          border-right: 0; } }
    .booking .advantage-card__top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e6e6e6;
      padding-left: 8px;
      padding-bottom: 20px;
      margin-bottom: 18px; }
      .booking .advantage-card__top img {
        margin-right: 13px; }
    .booking .advantage-card__title p {
      font-size: 22px;
      line-height: 22px;
      margin-bottom: 5px; }
    .booking .advantage-card__title span {
      font-size: 15px;
      color: #124390; }
    .booking .advantage-card__list li {
      font-size: 14px;
      padding-left: 22px;
      position: relative; }
      .booking .advantage-card__list li::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #ce171f;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 7px; }
      .booking .advantage-card__list li span {
        color: #ce171f; }
      .booking .advantage-card__list li + li {
        margin-top: 11px; }
      .booking .advantage-card__list li br {
        display: none; }
        @media (max-width: 767px) {
          .booking .advantage-card__list li br {
            display: block; } }
  .booking .booking-details {
    margin-bottom: 17px; }
    .booking .booking-details__title {
      font-size: 18px;
      margin-bottom: 10px;
      margin-top: 10px; }
      @media (max-width: 767px) {
        .booking .booking-details__title {
          font-size: 15px;
          margin-bottom: 16px; } }
      .booking .booking-details__title span {
        font-size: 15px;
        padding-left: 3px; }
        @media (max-width: 767px) {
          .booking .booking-details__title span {
            display: block;
            padding-left: 0;
            margin-top: 8px; } }
    .booking .booking-details__wrapper {
      display: flex;
      flex-wrap: wrap; }
    @media (max-width: 767px) {
      .booking .booking-details__item {
        width: 38%;
        margin-bottom: 12px; } }
    .booking .booking-details__item:not(:last-child) {
      border-right: 1px solid #e6e6e6;
      padding-right: 30px;
      margin-right: 30px; }
      @media (max-width: 767px) {
        .booking .booking-details__item:not(:last-child) {
          border-right: 0;
          padding-right: 0;
          margin-right: 0; } }
    @media (max-width: 767px) {
      .booking .booking-details__item:nth-child(odd) {
        border-right: 1px solid #e6e6e6;
        padding-right: 5px;
        margin-right: 30px; } }
    .booking .booking-details__item p:first-child {
      font-size: 12px;
      color: #9da4b0;
      margin-bottom: 2px; }
    .booking .booking-details__item p:last-child {
      font-size: 15px; }
    .booking .booking-details .no_vehicle {
      display: inline;
      margin-bottom: 0; }
      .booking .booking-details .no_vehicle input {
        width: calc(100% - 105px);
        min-width: 20px;
        height: 40px;
        margin-top: 5px;
        margin-right: 9px;
        margin-bottom: 0;
        padding: 8px 15px;
        border: 1px solid #a5a5a5;
        background-color: #fff;
        border-radius: 3px;
        outline: 0;
        font-size: 14px;
        line-height: 1em;
        appearance: none;
        box-sizing: border-box; }
  .booking .booking-branch {
    display: flex; }
    @media (max-width: 767px) {
      .booking .booking-branch {
        flex-direction: column; } }
    .booking .booking-branch__col {
      width: calc(50% - 24px);
      margin: 0 12px; }
      @media (max-width: 767px) {
        .booking .booking-branch__col {
          width: 100%;
          margin: 0; } }
      @media (max-width: 767px) {
        .booking .booking-branch__col:first-child {
          order: 1;
          margin-top: 13px; } }
      .booking .booking-branch__col .input-block--radio {
        display: flex;
        flex-wrap: wrap;
        padding-top: 22px; }
        @media (max-width: 767px) {
          .booking .booking-branch__col .input-block--radio {
            padding-top: 8px; } }
        .booking .booking-branch__col .input-block--radio .radio-label {
          width: 48%;
          margin-bottom: 18px; }
          @media (max-width: 767px) {
            .booking .booking-branch__col .input-block--radio .radio-label {
              width: 50%; } }
    .booking .booking-branch__buttons {
      display: flex;
      align-items: center;
      margin-top: 20px; }
      .booking .booking-branch__buttons a {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 173px;
        width: 100%;
        box-sizing: border-box; }
        @media (max-width: 767px) {
          .booking .booking-branch__buttons a {
            max-width: 162px; } }
    .booking .booking-branch__direction {
      background-color: #fff;
      color: #ce171f;
      border: 1px solid #ce171f;
      margin-left: 16px; }
      @media (min-width: 1024px) {
        .booking .booking-branch__direction:hover {
          opacity: 0.75; } }
  .booking .booking-personal {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0 -10px 13px; }
    @media (max-width: 767px) {
      .booking .booking-personal {
        margin: 0 0 18px; } }
    .booking .booking-personal .input-block {
      width: calc(50% - 20px);
      margin: 0 10px 23px; }
      @media (max-width: 767px) {
        .booking .booking-personal .input-block {
          width: 100%;
          margin: 0 0 21.5px; } }
      @media (max-width: 767px) {
        .booking .booking-personal .input-block--row {
          width: 68%; } }
    .booking .booking-personal .btn--lookup {
      width: 100px;
      margin: 25px 0 23px 10px; }
      @media (max-width: 767px) {
        .booking .booking-personal .btn--lookup {
          width: 29%;
          margin: 21px 0 0 3%; } }
  .booking .sidebar {
    flex-shrink: 0;
    width: 350px;
    margin-left: 40px; }
    @media (max-width: 1100px) {
      .booking .sidebar {
        display: none; } }
    .booking .sidebar .side-wrap {
      position: sticky;
      top: 100px;
      width: inherit; }
    .booking .sidebar .card {
      margin-bottom: 25px;
      background-color: #f7f7f7;
      border: 1px solid #d9d9d9;
      border-radius: 0.25rem; }
      .booking .sidebar .card .card-header {
        margin-bottom: 0;
        padding: 34px 35px 17px;
        border-bottom: none;
        border-radius: 0;
        background: transparent;
        font-weight: bold;
        font-size: 18px; }
        .booking .sidebar .card .card-header p {
          font-size: 14px;
          line-height: 22px;
          margin-top: 12px; }
      .booking .sidebar .card .card-body {
        padding: 0 35px 29px; }
        .booking .sidebar .card .card-body__block + .card-body__block {
          margin-top: 20px; }
        .booking .sidebar .card .card-body__block--total td:first-child {
          font-size: 15px;
          color: #222222; }
        .booking .sidebar .card .card-body p {
          max-width: 240px;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 22px; }
      .booking .sidebar .card table {
        width: 100%; }
        .booking .sidebar .card table td {
          padding: 10px 0;
          font-size: 14px; }
          .booking .sidebar .card table td:first-child {
            color: #595959; }
          .booking .sidebar .card table td:last-child {
            width: 70px;
            text-align: right;
            font-weight: 700; }
      .booking .sidebar .card .ah1 {
        font-size: 15px;
        line-height: 16px;
        color: #9da4b0;
        margin-bottom: 5px; }
      .booking .sidebar .card .total {
        margin-top: 14px;
        padding-top: 0;
        border-top: 1px solid #d7d7d7; }
  @media (max-width: 767px) {
    .booking--v2 .booking__flex-container {
      width: 90%; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__intermediate-text {
      text-align: left; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__advantages {
      margin: 35px 0 35px; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__advantages-item:not(:last-child) {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__advantages-item:nth-child(2) {
      order: -1; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__main-title {
      text-align: left; } }
  @media (max-width: 767px) {
    .booking--v2 .booking__phone {
      text-align: left; } }
  @media (max-width: 767px) {
    .booking--v2 .booking-banner {
      background-image: none !important;
      color: #4d4d4d;
      height: auto;
      padding: 0;
      margin: 16px 0 8px; } }
  @media (max-width: 767px) {
    .booking--v2 .booking-banner__title {
      font-size: 24px;
      line-height: 33px;
      font-weight: 400;
      text-transform: none; } }
  @media (max-width: 767px) {
    .booking--v2 .booking-banner__btn--mobile {
      display: block;
      margin: 25px 6px 0; } }
  @media (max-width: 767px) {
    .booking--v2 .advantage-card::before {
      display: none; } }
  @media (max-width: 767px) {
    .booking--v2 .advantage-card__inner {
      padding: 33px 25px 37px 25px;
      margin: 0;
      border-radius: 5px;
      border: 1px solid #d9d9d9; } }

.booking-1 .page-main-title {
  margin-bottom: 0; }
  @media (min-width: 1000px) {
    .booking-1 .page-main-title {
      margin-bottom: 43px; } }

.booking-1 .inner2 {
  width: 100%;
  padding: 0 15px; }

.booking-1 .inner2--first {
  padding: 0; }
  .booking-1 .inner2--first > .ah3 {
    padding: 0 15px;
    margin: 0 auto 20px; }
  .booking-1 .inner2--first > .ah4 {
    font-size: 18px;
    padding: 0 15px;
    margin: 0 auto 40px;
    text-align: center; }
    @media (min-width: 1000px) {
      .booking-1 .inner2--first > .ah4 {
        margin: 0;
        margin-bottom: 70px;
        text-align: left; } }

.booking-1 .big_banner {
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin-bottom: 40px; }

.booking-1 .big_banner__bg picture img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.booking-1 .big_banner__content {
  position: relative; }
  @media (min-width: 1000px) {
    .booking-1 .big_banner__content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between; } }
  @media (min-width: 1000px) {
    .booking-1 .big_banner__content .ah-wrap {
      padding: 0 0 30px 65px; } }
  .booking-1 .big_banner__content .ah2,
  .booking-1 .big_banner__content .ah3,
  .booking-1 .big_banner__content .ah4 {
    text-align: center; }
    @media (min-width: 1000px) {
      .booking-1 .big_banner__content .ah2,
      .booking-1 .big_banner__content .ah3,
      .booking-1 .big_banner__content .ah4 {
        text-align: left; } }
  .booking-1 .big_banner__content .ah2-outer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    transform-origin: top right;
    background-color: #333333; }
    @media (min-width: 1000px) {
      .booking-1 .big_banner__content .ah2-outer {
        display: none; } }
  .booking-1 .big_banner__content .ah2 {
    font-size: 25px;
    line-height: 28px;
    position: relative;
    padding: 30px 30px 50px 30px;
    margin-bottom: 0;
    max-width: 100%; }
    @media (min-width: 1000px) {
      .booking-1 .big_banner__content .ah2 {
        padding: 30px 0 0 65px;
        max-width: 400px; } }
  .booking-1 .big_banner__content .ah2 span {
    position: relative;
    z-index: 1; }
  .booking-1 .big_banner__content .ah2-outer {
    transform: skew(0, 3deg);
    border-top: 12px solid #ce171f; }
  .booking-1 .big_banner__content .ah3 {
    margin-top: 10px; }
    @media (min-width: 1000px) {
      .booking-1 .big_banner__content .ah3 {
        margin-top: 70px; } }
  .booking-1 .big_banner__content .button--red {
    margin: 15px auto 30px; }
    @media (min-width: 1000px) {
      .booking-1 .big_banner__content .button--red {
        margin: 0;
        margin-top: 15px; } }

.booking-1 .second_block {
  margin-bottom: 40px; }
  .booking-1 .second_block::before {
    display: none; }
    @media (min-width: 1000px) {
      .booking-1 .second_block::before {
        display: block; } }
  .booking-1 .second_block .row {
    margin-right: 0;
    margin-left: 0; }
  .booking-1 .second_block .block {
    align-items: flex-start !important;
    border-top: 1px solid #d9d9d9 !important;
    border-left: 6px solid #ce171f !important;
    padding: 15px 25px 25px 25px !important;
    margin-bottom: 15px !important; }
    @media (min-width: 1000px) {
      .booking-1 .second_block .block {
        margin-bottom: 30px; } }

.booking-1 .block {
  flex-direction: column; }

.booking-1 .block__top {
  display: flex;
  justify-content: center;
  align-items: center; }

.booking-1 .image_block {
  width: auto !important; }

.booking-1 .block__bottom {
  width: 100%;
  border-top: none !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: left; }
  .booking-1 .block__bottom::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 15px 0; }
  .booking-1 .block__bottom li {
    padding-left: 17px;
    position: relative; }
    .booking-1 .block__bottom li::before {
      content: '';
      display: block;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 7px;
      left: 0;
      background-color: #ce171f; }
    .booking-1 .block__bottom li span {
      color: #ce171f; }

.booking-1 .ah3--middle {
  margin: 30px 0 0 0 !important;
  padding: 0 !important;
  text-align: center !important; }

.booking-1 .tel-block {
  margin-bottom: 10px !important; }

.booking-1 .title {
  font-size: 24px;
  line-height: 33px; }
  @media (min-width: 1000px) {
    .booking-1 .title {
      font-size: 32px;
      line-height: 35px; } }

.booking-1 .subtitle {
  font-size: 16px;
  line-height: 26px;
  text-align: center; }

.booking-1 .reg-and-mile {
  text-align: center; }

.booking-1 .btn--lookup {
  margin: 20px auto !important; }

.booking-1 .booking-form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-top: 30px; }
  @media (min-width: 1000px) {
    .booking-1 .booking-form {
      margin: 0 -20px; } }
  .booking-1 .booking-form::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 0; }
  .booking-1 .booking-form .radio-label {
    display: flex; }
    .booking-1 .booking-form .radio-label input[type='radio'] {
      margin: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #a5a5a5;
      border-radius: 25px;
      cursor: pointer;
      margin-top: 3px;
      margin-right: 10px; }
      .booking-1 .booking-form .radio-label input[type='radio']:checked {
        background: #fff url(../img/radio.svg) no-repeat center center;
        background-size: 10px; }
    .booking-1 .booking-form .radio-label span span {
      font-size: 13px;
      line-height: 16px;
      color: #757575; }
  .booking-1 .booking-form .checkbox-label {
    display: flex;
    align-items: center; }
    .booking-1 .booking-form .checkbox-label img {
      width: 18px;
      height: 18px;
      cursor: pointer; }
  .booking-1 .booking-form label {
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 28px; }
  .booking-1 .booking-form input[type='text'],
  .booking-1 .booking-form textarea {
    outline: 0;
    border: 1px solid #a5a5a5;
    font-size: 16px;
    padding: 12px 15px;
    line-height: 1em;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    cursor: auto;
    margin: 0;
    margin-top: 1rem; }
  .booking-1 .booking-form textarea {
    height: 120px;
    resize: none; }
  .booking-1 .booking-form .input-date {
    background: #fff url(../img/datepicker.png) no-repeat;
    background-position: calc(100% - 9px) center; }
  .booking-1 .booking-form select {
    box-sizing: border-box;
    width: 100%;
    padding: 9.5px 15px;
    resize: none;
    appearance: none;
    border: 1px solid #a5a5a5;
    border-radius: 0;
    outline: 0;
    background-position: calc(100% - 15px) center;
    font-size: 16px;
    margin-top: 1rem; }
  .booking-1 .booking-form .tooltip-icon {
    margin-left: 10px; }
    .booking-1 .booking-form .tooltip-icon:hover .tooltip-window {
      display: block; }
  .booking-1 .booking-form .tooltip-window {
    display: none;
    width: 85%;
    position: absolute;
    bottom: 90%;
    left: 0;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 10px rgba(29, 30, 34, 0.18);
    z-index: 5;
    padding: 30px; }
  .booking-1 .booking-form .tooltip__title {
    font-size: 20px;
    line-height: 33px;
    font-weight: bold; }
  .booking-1 .booking-form .tooltip__text {
    font-size: 14px;
    line-height: 22px; }
  .booking-1 .booking-form__table-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 11px 10px;
    position: relative; }
    .booking-1 .booking-form__table-row:nth-child(even) {
      background-color: #f7f7f7; }
  .booking-1 .booking-form__price {
    font-size: 16px;
    line-height: 28px; }
  .booking-1 .booking-form__row-input {
    display: flex;
    flex-wrap: wrap; }
    .booking-1 .booking-form__row-input label,
    .booking-1 .booking-form__row-input .select-label {
      width: 100%; }
    .booking-1 .booking-form__row-input .select-label {
      margin-bottom: 0; }
    @media (min-width: 1000px) {
      .booking-1 .booking-form__row-input--narrow {
        margin: 0 -10px; } }
    .booking-1 .booking-form__row-input--narrow label,
    .booking-1 .booking-form__row-input--narrow .input-select {
      width: 100%;
      margin-bottom: 25px; }
      @media (min-width: 1000px) {
        .booking-1 .booking-form__row-input--narrow label,
        .booking-1 .booking-form__row-input--narrow .input-select {
          width: calc(50% - 20px);
          margin: 0 10px 25px; } }
  .booking-1 .booking-form__reg-number {
    position: relative; }
    .booking-1 .booking-form__reg-number::before {
      content: "";
      display: block;
      background: url(../img/new-gb-rn.png);
      width: 43px;
      height: 43px;
      min-width: 42px;
      min-height: 42px;
      position: absolute;
      left: 1px;
      bottom: 1px; }
    .booking-1 .booking-form__reg-number input[type='text'] {
      padding-left: 57px; }
  .booking-1 .booking-form__table-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px; }
  .booking-1 .booking-form__left {
    width: 100%; }
    @media (min-width: 1000px) {
      .booking-1 .booking-form__left {
        width: calc(50% - 40px);
        margin: 0 20px; } }
    .booking-1 .booking-form__left .booking-form__table-item::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #d7d7d7; }
    .booking-1 .booking-form__left .booking-form__table-title {
      margin-top: 40px; }
      .booking-1 .booking-form__left .booking-form__table-title:first-child {
        margin-top: 30px; }
  .booking-1 .booking-form__right {
    width: 100%;
    text-align: center; }
    @media (min-width: 1000px) {
      .booking-1 .booking-form__right {
        width: calc(50% - 40px);
        margin: 0 20px;
        text-align: left; } }
    .booking-1 .booking-form__right .booking-form__table-title {
      margin-top: 30px; }
    .booking-1 .booking-form__right .booking-form__row-input {
      margin-bottom: 25px; }
      .booking-1 .booking-form__right .booking-form__row-input--narrow {
        margin-bottom: 0; }

.speco-nfpr-content {
  width: 243px;
  max-width: 100%;
  padding-bottom: 20px; }

.speco-nfpr-pic {
  display: flex;
  justify-content: center;
  padding: 25px 0 25px; }

.speco-nfpr-title {
  text-align: center !important; }

.speco-nfpr-ul {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.speco-nfpr-block {
  display: flex;
  justify-content: center;
  margin-top: 0;
  padding: 0 20px;
  box-sizing: border-box; }
  @media (min-width: 1000px) {
    .speco-nfpr-block {
      margin-top: 0; } }

.speco-sidebar-info {
  margin-bottom: 20px; }

.speco-ah1 {
  font-weight: 400 !important; }

.speco-map-red {
  font-size: 16px !important;
  line-height: 28px !important;
  font-weight: 400 !important;
  color: #ce171f !important; }

.speco-accordeon {
  margin-bottom: 39px; }
  .speco-accordeon-heading {
    display: flex;
    align-items: center;
    border: 1px solid #d7d7d7;
    background-color: #f7f7f7;
    padding: 17px 10px 15px;
    cursor: pointer; }
    .speco-accordeon-heading__name {
      display: block;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      color: #2c2c2c;
      text-decoration: underline; }
    .speco-accordeon-heading__show-btn {
      margin-left: 9px;
      display: flex;
      align-items: center; }
      .speco-accordeon-heading__show-btn::before {
        content: 'Show';
        display: block;
        min-width: 42px;
        margin-right: 5px;
        font-size: 15px;
        font-weight: 400;
        color: #ce171f;
        text-decoration: underline;
        transition-duration: .2s; }
      .speco-accordeon-heading__show-btn::after {
        content: '';
        display: block;
        width: 12px;
        height: 8px;
        background: url(../img/arrow-top-red.png) no-repeat center center;
        transition-duration: .2s;
        transform: rotate(180deg); }
  .speco-accordeon-body {
    display: none;
    border-bottom: 1px solid #d7d7d7; }
  .speco-accordeon-item--chb {
    padding: 9px 10px; }
    .speco-accordeon-item--chb span {
      font-size: 16px !important;
      line-height: 28px !important;
      font-weight: 400;
      color: #2c2c2c; }
    .speco-accordeon-item--chb:nth-child(2n) {
      background-color: #f7f7f7; }
  .speco-accordeon-chb {
    display: flex;
    align-items: center;
    margin-bottom: 0 !important; }
  .speco-accordeon-chb-name {
    flex-grow: 1;
    margin-right: 10px; }
  .speco-accordeon--open .speco-accordeon-heading__show-btn::before {
    content: 'Hide'; }
  .speco-accordeon--open .speco-accordeon-heading__show-btn::after {
    transform: rotate(0); }

.speco-calendar {
  display: flex;
  width: 100%;
  max-width: 100%; }
  .speco-calendar-wrap {
    margin-top: 30px; }
  .speco-calendar-title {
    margin-bottom: 5px;
    color: #2c2c2c;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px; }
    .speco-calendar-title .red {
      font-weight: bold; }
  .speco-calendar-left, .speco-calendar-right {
    width: 44px;
    padding-top: 56px; }
    @media (max-width: 550px) {
      .speco-calendar-left, .speco-calendar-right {
        width: 28px; } }
  .speco-calendar-left {
    margin-right: 10px; }
    @media (max-width: 550px) {
      .speco-calendar-left {
        margin-right: 5px; } }
  .speco-calendar-right {
    margin-left: 10px; }
    @media (max-width: 550px) {
      .speco-calendar-right {
        margin-left: 5px; } }
  .speco-calendar-main {
    display: block;
    width: calc(100% - 54px * 2);
    position: relative; }
    @media (max-width: 550px) {
      .speco-calendar-main {
        width: calc(100% - 33px * 2); } }
  .speco-calendar-nav-container {
    width: 100%;
    height: 1px;
    position: relative; }
    .speco-calendar-nav-container .owl-prev,
    .speco-calendar-nav-container .owl-next {
      width: 44px;
      height: 46px;
      border: 1px solid #ce181f;
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #ffffff;
      background-size: 10px auto;
      position: absolute;
      cursor: pointer;
      box-sizing: border-box;
      transition-duration: .2s; }
      @media (max-width: 550px) {
        .speco-calendar-nav-container .owl-prev,
        .speco-calendar-nav-container .owl-next {
          width: 28px; } }
      .speco-calendar-nav-container .owl-prev span,
      .speco-calendar-nav-container .owl-next span {
        display: none; }
      .speco-calendar-nav-container .owl-prev.disabled,
      .speco-calendar-nav-container .owl-next.disabled {
        opacity: 0.5;
        pointer-events: none; }
      @media (min-width: 1024px) {
        .speco-calendar-nav-container .owl-prev:hover,
        .speco-calendar-nav-container .owl-next:hover {
          opacity: .8; } }
    .speco-calendar-nav-container .owl-prev {
      background-image: url("../../img/calendar-arrow.svg");
      margin: 0;
      top: 0;
      left: 0; }
    .speco-calendar-nav-container .owl-next {
      margin: 0;
      top: 0;
      right: 0;
      background-image: url("../../img/calendar-arrow.svg");
      transform: rotate(180deg); }

.speco-cal-side-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 46px;
  margin-bottom: 10px;
  background-color: #ce181f;
  font-size: 16px;
  line-height: 28px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase; }
  @media (max-width: 550px) {
    .speco-cal-side-block {
      width: 28px;
      font-size: 12px; } }

.speco-cal-date, .speco-cal-item__am, .speco-cal-item__pm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  height: 46px;
  margin-bottom: 10px;
  background-color: #f2f2f2; }

.speco-cal-date {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px; }
  .speco-cal-date__day {
    display: block;
    text-align: center; }
  .speco-cal-date__digits {
    display: block;
    text-align: center; }

.speco-cal-item--empty {
  background-color: #d9d9d9; }

.speco-customer-service {
  border-bottom: 1px solid #d7d7d7; }

.speco-cs-item {
  display: flex;
  padding: 10px 9px; }
  .speco-cs-item:nth-child(2n-1) {
    background-color: #f7f7f7; }
  .speco-cs-item input[type="radio"] {
    width: 20px;
    height: 20px;
    border-radius: 50%; }
    .speco-cs-item input[type="radio"]:checked {
      background: #fff url(../img/radio.svg) no-repeat center center;
      background-size: 10px 10px; }

.speco-cs-text {
  flex-grow: 1;
  margin-right: 10px; }
  .speco-cs-text__name {
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #2c2c2c; }
  .speco-cs-text__desc {
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    color: #757575; }

.speco-cs-price {
  margin-top: 4px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #2c2c2c; }

.speco-cs-title {
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  color: #2c2c2c; }

.speco-book-btn {
  width: 319px;
  margin: 0 auto;
  max-width: 100%; }

.online-payment .page-main-title {
  margin-bottom: 20px !important; }

.online-payment .payment {
  max-width: 755px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 40px;
  box-sizing: border-box;
  overflow: hidden; }
  @media (min-width: 768px) {
    .online-payment .payment {
      padding: 0 0 86px; } }
  .online-payment .payment__title {
    font-size: 30px;
    line-height: 33px;
    font-weight: bold;
    text-align: center;
    position: relative; }
    .online-payment .payment__title::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -22px;
      left: 0;
      background-color: #e6e6e6; }
  .online-payment .payment .form-block {
    margin-top: 15px; }
    @media (min-width: 1024px) {
      .online-payment .payment .form-block {
        margin-top: 43px; } }
  .online-payment .payment__main {
    margin: 0;
    padding: 0; }
  .online-payment .payment__contacts {
    font-size: 16px;
    line-height: 28px;
    position: relative; }
    .online-payment .payment__contacts::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -22px;
      left: 0;
      background-color: #e6e6e6; }
  .online-payment .payment__collect span {
    color: #ce171f; }
  .online-payment .payment__address {
    display: flex;
    font-size: 15px;
    line-height: 22px;
    margin: 12px 0; }
    @media (max-width: 1023px) {
      .online-payment .payment__address {
        flex-direction: column; } }
  .online-payment .payment__address-text {
    display: flex;
    align-items: center;
    margin-right: 20px; }
    .online-payment .payment__address-text::before {
      content: '';
      display: block;
      width: 14px;
      height: 20px;
      background: url(../img/pin.svg) no-repeat center center;
      margin-right: 5px; }
    @media (max-width: 1023px) {
      .online-payment .payment__address-text {
        margin-right: 0; } }
  .online-payment .payment__address-phone {
    display: flex;
    align-items: center; }
    .online-payment .payment__address-phone::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background: url(../img/phone.svg) no-repeat center center;
      margin-right: 5px; }
  .online-payment .payment__mode {
    font-size: 14px;
    line-height: 26px;
    color: #666666; }
    .online-payment .payment__mode span:first-child {
      margin-right: 25px; }
    @media (max-width: 1023px) {
      .online-payment .payment__mode span {
        display: block; } }
  .online-payment .payment form {
    margin-top: 50px; }
  .online-payment .payment__block:not(:last-of-type) {
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
    margin-bottom: 30px; }
  .online-payment .payment__block:nth-child(2) {
    padding-bottom: 2px;
    margin-bottom: 35px; }
  .online-payment .payment__branch {
    margin-top: 38px; }
  .online-payment .payment__block-title {
    font-size: 24px;
    text-align: center; }
  .online-payment .payment__branch {
    font-size: 16px;
    text-align: center; }
  .online-payment .payment__checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 22px;
    cursor: pointer; }
    .online-payment .payment__checkbox input {
      display: none; }
      .online-payment .payment__checkbox input:checked + b {
        background: #fff center/100% auto url(../img/admin_checked.png) no-repeat; }
    .online-payment .payment__checkbox b {
      display: block;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #a5a5a5;
      border-radius: 3px;
      margin-right: 10px;
      background: #fff;
      box-sizing: border-box; }
    .online-payment .payment__checkbox span a {
      color: #2c2c2c;
      text-decoration: underline; }
  .online-payment .payment .radio-label {
    margin-bottom: 20px; }
    .online-payment .payment .radio-label:not(:last-child) {
      margin-right: 38px; }
  .online-payment .payment .input-block {
    width: 100%;
    margin: 0 15px 20px; }
    @media (min-width: 768px) {
      .online-payment .payment .input-block {
        width: calc(50% - 20px);
        margin: 0 10px 20px; } }
    .online-payment .payment .input-block input {
      font-size: 14px; }
      .online-payment .payment .input-block input::placeholder {
        font-size: 14px; }
      .online-payment .payment .input-block input:focus::placeholder {
        opacity: 0; }
    .online-payment .payment .input-block input[type='text'],
    .online-payment .payment .input-block select {
      margin-top: 6px;
      box-sizing: border-box; }
    .online-payment .payment .input-block input[type='radio'] {
      width: 18px;
      height: 18px; }
      .online-payment .payment .input-block input[type='radio']:checked {
        background-size: 10px; }
    .online-payment .payment .input-block--branch {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 26px 0 0; }
      @media (min-width: 768px) {
        .online-payment .payment .input-block--branch {
          margin: 20px 0 0; } }
    .online-payment .payment .input-block--lookup {
      align-self: flex-end; }
  .online-payment .payment__input-text {
    width: 100%;
    margin-bottom: 0 !important; }
  .online-payment .payment__inputs {
    display: flex;
    flex-wrap: wrap;
    margin: 22px -15px 0;
    align-items: flex-start; }
    @media (min-width: 768px) {
      .online-payment .payment__inputs {
        margin: 16px -9px 0; } }
    .online-payment .payment__inputs:nth-child(2) {
      margin-top: 33px;
      margin-bottom: 18px; }
    .online-payment .payment__inputs--w33 .input-block {
      width: 100%; }
      @media (min-width: 768px) {
        .online-payment .payment__inputs--w33 .input-block {
          width: calc(33.3333% - 20px);
          margin: 0 10px 20px; } }
  .online-payment .payment__lookup {
    width: 100px;
    margin-top: 0; }
  .online-payment .payment .btn-payment {
    display: block;
    width: 100%;
    height: 46px;
    margin: 39px auto 29px;
    border-radius: 0;
    font-size: 15px;
    line-height: 26px;
    font-weight: bold;
    text-transform: uppercase;
    box-sizing: border-box;
    cursor: pointer; }
    @media (min-width: 768px) {
      .online-payment .payment .btn-payment {
        width: 438px; } }
    @media (max-width: 1023px) {
      .online-payment .payment .btn-payment {
        width: 100%;
        font-size: 15px;
        line-height: 26px; } }
  .online-payment .payment__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    background-color: #fff; }
    .online-payment .payment__footer img:not(:last-child) {
      margin-right: 30px; }

.virus-info-page .virus-video-block {
  max-width: 557px;
  width: 100%;
  padding-top: 49px;
  margin-left: 23px;
  flex-shrink: 0; }
  .virus-info-page .virus-video-block .virus-ib-title {
    margin-bottom: 15px; }
  .virus-info-page .virus-video-block .virus-ib--text {
    margin-top: 0;
    margin-bottom: 15px; }
  .virus-info-page .virus-video-block .video {
    position: relative;
    height: 308px; }
    @media (max-width: 610px) {
      .virus-info-page .virus-video-block .video {
        height: 50vw; } }
    .virus-info-page .virus-video-block .video video,
    .virus-info-page .virus-video-block .video iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1; }
    .virus-info-page .virus-video-block .video__prewiew {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2; }
      .virus-info-page .virus-video-block .video__prewiew:before {
        content: '';
        display: block;
        width: 77px;
        height: 50px;
        background: center/cover url(../images/btn__play.png) no-repeat;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 10;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer; }
      .virus-info-page .virus-video-block .video__prewiew--hidden {
        display: none; }
      .virus-info-page .virus-video-block .video__prewiew img {
        display: block;
        width: 100%; }

.virus-info-page .inner2 {
  max-width: 1140px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }
  @media screen and (max-width: 1000px) {
    .virus-info-page .inner2 {
      flex-direction: column; }
      .virus-info-page .inner2 .virus-info-block {
        padding-bottom: 20px; }
      .virus-info-page .inner2 .virus-video-block {
        margin-left: 0;
        padding-bottom: 20px; } }

.virus-info-page .virus-info-block {
  max-width: 100%;
  width: 100%;
  padding-right: 18px;
  padding-top: 49px;
  padding-bottom: 98px;
  margin: 0 auto;
  box-sizing: border-box; }

.virus-info-page .virus-ib-title {
  font-size: 24px;
  font-weight: 400;
  line-height: 33px; }

.virus-info-page .virus-ib-call {
  padding: 22px 0 22px;
  margin: 16px 0;
  border-bottom: 1px solid #e6e6e6; }
  .virus-info-page .virus-ib-call:last-child {
    margin-bottom: 0;
    margin-top: 0; }

.virus-info-page .virus-ib-call-top {
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400; }

.virus-info-page .virus-ib-call-bottom {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px; }
  .virus-info-page .virus-ib-call-bottom__phone {
    display: flex;
    align-items: center;
    margin-right: 6px;
    font-size: 22px;
    line-height: 24px;
    font-weight: 400;
    color: #2c2c2c; }
    .virus-info-page .virus-ib-call-bottom__phone::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-right: 7px;
      background-image: url(../img/call-red.svg); }
  .virus-info-page .virus-ib-call-bottom__link {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #d7444a;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .virus-info-page .virus-ib-call-bottom__link:hover {
        text-decoration: none; } }

.virus-info-page .virus-ib--text {
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  margin-top: 27px; }
  .virus-info-page .virus-ib--text a {
    transition-duration: 0.2s;
    color: #ce181f;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .virus-info-page .virus-ib--text a:hover {
        text-decoration: none; } }

.privacy-statement {
  padding-bottom: 50px; }
  .privacy-statement .inner {
    margin-bottom: 100px; }
  .privacy-statement .page-main-title {
    margin-bottom: 30px; }
  .privacy-statement .ah1 {
    margin-bottom: 5px;
    font-size: 22px;
    line-height: 25px;
    font-weight: bold; }
  .privacy-statement .ah2 {
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: bold; }
  .privacy-statement a {
    text-decoration: underline;
    color: #b72126; }
    @media (min-width: 1024px) {
      .privacy-statement a:hover {
        text-decoration: none; } }
  .privacy-statement .underline {
    text-decoration: underline; }
  .privacy-statement ul {
    list-style-position: inside;
    list-style-type: disc; }

.thanks-f-p-page {
  padding-bottom: 190px; }
  .thanks-f-p-page .line1 {
    margin-top: 72px;
    margin-left: auto;
    margin-right: auto;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: #2c2c2c;
    text-align: center; }
    @media (max-width: 450px) {
      .thanks-f-p-page .line1 {
        margin-top: 20px; } }
  .thanks-f-p-page .line2 {
    margin-top: 13px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2c2c2c;
    text-align: center; }
    @media (max-width: 450px) {
      .thanks-f-p-page .line2 {
        font-size: 14px;
        margin-top: 0; } }
    .thanks-f-p-page .line2 .bold {
      font-weight: bold; }
    .thanks-f-p-page .line2 .red {
      color: #ce171f; }
  .thanks-f-p-page .line3 {
    width: 700px;
    max-width: 100%;
    margin: 13px auto 0;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #2c2c2c;
    text-align: center; }
    @media (max-width: 450px) {
      .thanks-f-p-page .line3 {
        font-size: 14px;
        margin-top: 0; } }
  .thanks-f-p-page .line4 {
    margin-top: 48px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    color: #2c2c2c; }
    @media (max-width: 450px) {
      .thanks-f-p-page .line4 {
        margin-top: 20px; } }
  .thanks-f-p-page .form-block .input-block.branch {
    width: 558px;
    max-width: 100%;
    margin: 13px auto 0; }
    .thanks-f-p-page .form-block .input-block.branch .radios {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap; }
      @media (max-width: 450px) {
        .thanks-f-p-page .form-block .input-block.branch .radios {
          display: block; } }
    .thanks-f-p-page .form-block .input-block.branch .radio-btn {
      max-width: 33%;
      display: flex;
      align-items: center;
      margin-bottom: 10px; }
      @media (max-width: 450px) {
        .thanks-f-p-page .form-block .input-block.branch .radio-btn {
          max-width: 100%; } }
      .thanks-f-p-page .form-block .input-block.branch .radio-btn .radio-label {
        margin-bottom: 0; }
      .thanks-f-p-page .form-block .input-block.branch .radio-btn + .radio-btn {
        margin-top: 0; }
      .thanks-f-p-page .form-block .input-block.branch .radio-btn input[type=radio] {
        width: 20px;
        height: 20px; }
        .thanks-f-p-page .form-block .input-block.branch .radio-btn input[type=radio]:checked {
          background-size: 10px 10px; }
  .thanks-f-p-page .form-block .input-row {
    width: 558px;
    max-width: 100%;
    margin: 19px auto 0;
    display: flex;
    justify-content: space-between; }
    @media (max-width: 450px) {
      .thanks-f-p-page .form-block .input-row {
        display: block; } }
    .thanks-f-p-page .form-block .input-row .input-block {
      max-width: 48.3%;
      width: 100%; }
      @media (max-width: 450px) {
        .thanks-f-p-page .form-block .input-row .input-block {
          max-width: 100%;
          margin-bottom: 15px; } }
  .thanks-f-p-page .form-block input[type="submit"] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 266px;
    max-width: 100%;
    height: 46px;
    margin: 35px auto 0;
    background-color: #124390;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase; }
  .thanks-f-p-page .form-block .note {
    margin-top: 65px;
    margin-bottom: -10px;
    font-size: 14px;
    line-height: 17px;
    text-align: center; }
  .thanks-f-p-page__no-email .line3 {
    width: 560px; }
    .thanks-f-p-page__no-email .line3 a {
      color: #d1252c;
      text-decoration: underline; }
  .thanks-f-p-page__no-email .winter-service .reg-and-mile {
    max-width: 520px;
    margin-top: 10px; }
    .thanks-f-p-page__no-email .winter-service .reg-and-mile input[type="email"] {
      margin-bottom: 20px; }
  .thanks-f-p-page__no-email .form-block .input-block label:not(.checkbox-container) {
    display: flex; }
  .thanks-f-p-page__no-email .form-block input[type=submit] {
    background-color: #d1252c; }
  .thanks-f-p-page .thfp-v2-map-block.winter-service {
    padding-top: 38px;
    padding-bottom: 38px; }
  .thanks-f-p-page--confirmation .line1 {
    margin-top: 59px;
    font-weight: 400; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .line1 {
        font-size: 22px;
        line-height: 26px;
        margin-top: 30px; } }
    @media (max-width: 450px) {
      .thanks-f-p-page--confirmation .line1 {
        font-size: 20px;
        line-height: 24px; } }
  .thanks-f-p-page--confirmation .thanks-f-p-page__inner {
    max-width: 850px;
    padding-bottom: 102px; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .thanks-f-p-page__inner {
        padding-bottom: 50px; } }
  .thanks-f-p-page--confirmation .thfp-v2-map-block.winter-service {
    padding-bottom: 9px; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .thfp-v2-map-block.winter-service {
        padding-top: 23px; } }
  .thanks-f-p-page--confirmation .form-block .input-row {
    width: 100%; }
  .thanks-f-p-page--confirmation .form-block .note {
    font-size: 15px;
    line-height: 18px;
    margin-top: 42px;
    margin-bottom: 0; }
  .thanks-f-p-page--confirmation .form-block input[type="submit"] {
    width: 300px;
    height: 45px;
    margin-top: 17px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
    box-sizing: border-box; }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row.border-row .input-block:not(.input-standart) {
    padding-bottom: 22px; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row.border-row .input-block:not(.input-standart) {
        margin: 0 auto; } }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row.border-top-row {
    padding-top: 23px;
    padding-bottom: 37px; }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row.item.active {
    align-items: center; }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block .ah1 {
    margin-bottom: 22px; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block .ah1 {
        text-align: center; } }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block .radio-btn + .radio-btn {
    margin-top: 25px; }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block label:not(.checkbox-container) {
    font-size: 14px;
    margin-bottom: 3px; }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block label.radios-title {
    font-size: 16px;
    margin-bottom: 26px; }
    @media (max-width: 768px) {
      .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block label.radios-title {
        text-align: center; } }
  .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block label.radio-label {
    font-size: 16px; }
    .thanks-f-p-page--confirmation .winter-service .reg-and-mile .row .input-block label.radio-label span {
      color: #ce171f; }

@media (max-width: 650px) {
  .modal-map-contacts.mmc-contact-page:last-child {
    margin-bottom: 30px; } }

.modal-map-contacts.mmc-contact-page:last-child .content {
  border-bottom: 0; }

.modal-map-contacts.mmc-contact-page > .title {
  font-family: "Vauxhall Neue", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #2c2c2c;
  text-align: left;
  border: none;
  padding-left: 0; }

.modal-map-contacts.mmc-contact-page .content {
  padding-bottom: 61px;
  border-bottom: 1px solid #ccc; }
  @media (max-width: 650px) {
    .modal-map-contacts.mmc-contact-page .content {
      padding-bottom: 0; } }
  .modal-map-contacts.mmc-contact-page .content .contacts {
    width: 308px;
    min-width: 308px;
    margin-right: 44px;
    box-sizing: border-box; }
    @media (max-width: 650px) {
      .modal-map-contacts.mmc-contact-page .content .contacts {
        width: 100%;
        min-width: unset;
        margin-right: 0; } }
    .modal-map-contacts.mmc-contact-page .content .contacts .accordion h3,
    .modal-map-contacts.mmc-contact-page .content .contacts .accordion > div,
    .modal-map-contacts.mmc-contact-page .content .contacts .adress,
    .modal-map-contacts.mmc-contact-page .content .contacts .phone,
    .modal-map-contacts.mmc-contact-page .content .contacts .title {
      width: 100%;
      padding-left: 0;
      padding-right: 30px;
      box-sizing: border-box; }
    .modal-map-contacts.mmc-contact-page .content .contacts .adress {
      padding-right: 0;
      padding-bottom: 0;
      border-bottom: 0; }
    .modal-map-contacts.mmc-contact-page .content .contacts .enquiry {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: 15px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 15px;
      line-height: 22px;
      color: #ce171f;
      text-decoration: underline; }
      .modal-map-contacts.mmc-contact-page .content .contacts .enquiry img {
        width: 20px;
        margin-right: 8px;
        flex-shrink: 0; }
    .modal-map-contacts.mmc-contact-page .content .contacts .accordion h3 {
      padding: 0; }
    .modal-map-contacts.mmc-contact-page .content .contacts .accordion > div {
      padding: 0 19px 7px 0; }
      @media (max-width: 650px) {
        .modal-map-contacts.mmc-contact-page .content .contacts .accordion > div {
          padding-right: 0; } }
    .modal-map-contacts.mmc-contact-page .content .contacts h3:last-of-type {
      border: none; }
    .modal-map-contacts.mmc-contact-page .content .contacts h3::after {
      right: 0; }
    .modal-map-contacts.mmc-contact-page .content .contacts div:last-of-type {
      border: none; }
  .modal-map-contacts.mmc-contact-page .content .map {
    width: calc(100% - 308px - 44px);
    height: 420px;
    border: none; }
    @media (max-width: 650px) {
      .modal-map-contacts.mmc-contact-page .content .map {
        width: 100%;
        height: 302px; } }

.modal-map-contacts.mmc-contact-page .accordion-one-item h3::after {
  display: none; }

.commercial-vehicle .inner {
  max-width: 1140px; }

.commercial-vehicle .dbr {
  display: none; }
  @media (min-width: 768px) {
    .commercial-vehicle .dbr {
      display: block; } }

@media (min-width: 768px) {
  .commercial-vehicle .mbr {
    display: none; } }

.commercial-vehicle .content--banner .inner {
  margin: 0;
  width: 100%; }
  @media (min-width: 1024px) {
    .commercial-vehicle .content--banner .inner {
      margin: 0 auto;
      width: 96%; } }

.commercial-vehicle .content--line .inner::after {
  content: '';
  display: block;
  width: calc(100% - 33px);
  height: 1px;
  background-color: #e6e6e6;
  margin: 48px auto 60px; }
  @media (min-width: 768px) {
    .commercial-vehicle .content--line .inner::after {
      width: 100%;
      height: 2px;
      margin: 76px auto 55px; } }

.commercial-vehicle .content:last-child {
  margin-bottom: 56px; }

.commercial-vehicle .banner-block {
  width: 100%;
  height: 100%;
  margin-top: 6px; }
  @media (min-width: 768px) {
    .commercial-vehicle .banner-block {
      margin-top: 41px; } }
  .commercial-vehicle .banner-block img {
    width: 100%;
    height: 100%; }

.commercial-vehicle .description {
  position: relative;
  margin-top: 39px;
  text-align: center; }
  @media (min-width: 768px) {
    .commercial-vehicle .description {
      margin-top: 31px; } }
  .commercial-vehicle .description p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px; }
  .commercial-vehicle .description > p {
    font-size: 15px; }
    @media (min-width: 768px) {
      .commercial-vehicle .description > p {
        font-size: 16px; } }
  .commercial-vehicle .description .heading {
    font-size: 23px;
    line-height: 33px;
    letter-spacing: 0.5px;
    font-weight: bold; }
    @media (min-width: 768px) {
      .commercial-vehicle .description .heading {
        font-size: 24px; } }
  .commercial-vehicle .description .icon-block {
    margin-top: 36px; }
    @media (max-width: 767px) {
      .commercial-vehicle .description .icon-block {
        font-size: 37px; } }
    .commercial-vehicle .description .icon-block .min-heading {
      font-size: 18px;
      line-height: 33px; }
    .commercial-vehicle .description .icon-block .item-block {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      margin-top: 24px; }
    .commercial-vehicle .description .icon-block .item {
      max-width: 280px; }
      .commercial-vehicle .description .icon-block .item + .item {
        margin-left: 20px; }
      .commercial-vehicle .description .icon-block .item .icon {
        height: 79px;
        background-repeat: no-repeat;
        background-position: center; }
      .commercial-vehicle .description .icon-block .item p {
        line-height: 22px; }
      .commercial-vehicle .description .icon-block .item:nth-child(1) .icon {
        background-image: url("../images/delivery-mileage/icon-1.png"); }
      .commercial-vehicle .description .icon-block .item:nth-child(2) .icon {
        background-image: url("../images/delivery-mileage/icon-2.png"); }
      .commercial-vehicle .description .icon-block .item:nth-child(3) .icon {
        background-image: url("../images/delivery-mileage/icon-3.png"); }
      .commercial-vehicle .description .icon-block .item:nth-child(4) .icon {
        background-image: url("../images/delivery-mileage/icon-4.png"); }
    @media (max-width: 767px) {
      .commercial-vehicle .description .icon-block .item-block {
        margin-top: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .commercial-vehicle .description .icon-block .item {
        display: flex;
        width: 100%;
        margin-top: 34px !important; }
        .commercial-vehicle .description .icon-block .item + .item {
          margin-left: 0; }
        .commercial-vehicle .description .icon-block .item .icon {
          width: 100%;
          max-width: 82px; }
        .commercial-vehicle .description .icon-block .item p {
          margin-top: 0;
          text-align: left;
          padding: 0 0 0 27px !important; } }

.commercial-vehicle .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 18px; }
  @media (min-width: 768px) {
    .commercial-vehicle .products {
      margin: 0 -15px;
      padding: 0; } }

.commercial-vehicle .product {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    .commercial-vehicle .product {
      width: calc(50% - 30px);
      margin: 0 15px 30px; } }
  @media (min-width: 1024px) {
    .commercial-vehicle .product {
      width: calc(33.3333% - 30px); } }
  .commercial-vehicle .product::before {
    content: '';
    display: block;
    width: 102%;
    height: 195px;
    background-color: #eef3fb;
    transform: skewY(6deg);
    position: absolute;
    top: -42px;
    left: 0; }
  .commercial-vehicle .product__img {
    height: 200px;
    position: relative;
    padding: 22px 24px 0 12px; }
    .commercial-vehicle .product__img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .commercial-vehicle .product__info {
    padding: 0 20px;
    margin: 18px 0 21px;
    text-align: center; }
  .commercial-vehicle .product__color {
    font-size: 14px;
    color: #666666; }
  .commercial-vehicle .product__name {
    font-size: 18px;
    text-transform: uppercase;
    margin: 9px 0 5px; }
  .commercial-vehicle .product__specification {
    font-size: 16px; }
  .commercial-vehicle .product__bottom {
    padding: 0 50px 42px;
    text-align: center;
    margin-top: auto; }
  .commercial-vehicle .product__from-price {
    font-size: 16px;
    color: #666666; }
  .commercial-vehicle .product__price {
    font-size: 24px;
    line-height: 27px;
    color: #ce171f;
    margin-bottom: 17px; }
    .commercial-vehicle .product__price span {
      font-size: 16px; }
  .commercial-vehicle .product__btn {
    font-size: 18px;
    width: 100%;
    height: 46px;
    margin: 0 auto;
    border: 1px solid #124390;
    background-color: #fff;
    color: #124390;
    transition: background-color 0.3s ease, color 0.3s ease; }
    @media (min-width: 1024px) {
      .commercial-vehicle .product__btn:hover {
        background-color: #124390;
        color: #fff; } }
    @media (min-width: 1200px) {
      .commercial-vehicle .product__btn {
        width: 250px; } }
    .commercial-vehicle .product__btn:not(:first-child) {
      margin-top: 10px; }

.enqiry .slide-title {
  height: 100px;
  background-color: #f0f0f0; }

.enqiry .form-your-enqiry .form-block .row-1 {
  display: flex;
  flex-wrap: wrap;
  margin-left: -30px;
  margin-top: -20px; }
  .enqiry .form-your-enqiry .form-block .row-1 .input-block {
    flex: 2 0 290px;
    margin-top: 20px;
    margin-left: 30px; }
    .enqiry .form-your-enqiry .form-block .row-1 .input-block:nth-child(3n-2) {
      flex: 1 0 150px; }

.enqiry .form-your-enqiry .form-block .row-2 .part-branch .radio-label {
  position: relative;
  top: 4px; }

.enqiry .form-your-enqiry .form-block .row-4 .row-title {
  font-size: 14px;
  line-height: 20px; }

.enqiry .form-your-enqiry .form-block .row-4 .input-block {
  display: flex;
  flex-wrap: wrap; }
  .enqiry .form-your-enqiry .form-block .row-4 .input-block .radio-btn {
    margin: 0 35px 15px 0; }

.enqiry .form-your-enqiry .form-block button {
  font-size: 18px;
  font-weight: 700;
  width: 300px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto; }

.enqiry .form-your-enqiry .form-block .required-sign {
  font-size: 9px;
  color: #ce181e;
  position: relative;
  top: -6px; }

.enqiry .form-after-submit .form-title {
  text-align: center; }

.enqiry .form-after-submit .btn {
  width: 300px;
  max-width: unset;
  margin: auto;
  font-size: 18px;
  font-weight: 700; }

.reservation-policy .inner {
  margin-bottom: 100px; }

.reservation-policy .line1 {
  margin-top: 36px; }

.reservation-policy .line2 a {
  font-weight: 400;
  line-height: 33px;
  color: #b72126;
  text-decoration: underline; }
  @media (min-width: 1024px) {
    .reservation-policy .line2 a:hover {
      text-decoration: none; } }

.reservation-policy .line-title {
  font-size: 18px;
  font-weight: 800; }

.reservation-policy .reservation-list li {
  font-size: 16px;
  line-height: 24px;
  color: #2c2c2c;
  text-align: justify;
  margin: 15px 0;
  list-style-type: none; }
  @media (max-width: 450px) {
    .reservation-policy .reservation-list li {
      font-size: 14px; } }
  .reservation-policy .reservation-list li::before {
    content: "•";
    padding-right: 10px;
    font-size: 22px;
    color: red;
    vertical-align: top; }

.terms-conditions .inner {
  margin-bottom: 100px; }

.terms-conditions .line1 {
  margin-top: 36px; }

.terms-conditions .line2 {
  margin-top: 25px; }
  .terms-conditions .line2 a {
    font-weight: 400;
    line-height: 33px;
    color: #b72126;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .terms-conditions .line2 a:hover {
        text-decoration: none; } }

.marketing-consent .container {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box; }
  @media (min-width: 576px) {
    .marketing-consent .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .marketing-consent .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .marketing-consent .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .marketing-consent .container {
      max-width: 1140px; } }

.marketing-consent .row {
  display: flex;
  flex-wrap: wrap; }

.marketing-consent .col {
  flex-grow: 1;
  max-width: 100%; }

.marketing-consent .col-sm-6 {
  position: relative;
  width: 100%; }
  @media (min-width: 576px) {
    .marketing-consent .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; } }

.marketing-consent h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 29px;
  line-height: 1.2;
  font-weight: bold;
  text-transform: uppercase; }

.marketing-consent h3 {
  margin: 20px 0 20px;
  font-size: 20px;
  font-weight: 400; }

.marketing-consent .ah3 {
  margin: 20px 0 20px;
  font-size: 20px;
  line-height: normal;
  font-weight: 400; }

.marketing-consent .red_line {
  margin: 10px 0;
  border-bottom: 1px solid #ce181e; }

.marketing-consent .frbadge {
  text-align: right; }

.marketing-consent img {
  vertical-align: middle;
  max-width: 100%; }

.marketing-consent .consent-bordered {
  border: 1px solid #ddd;
  box-sizing: border-box; }
  .marketing-consent .consent-bordered .col {
    padding: 0 15px; }
    .marketing-consent .consent-bordered .col.consent-header {
      background-color: #eaeaea;
      padding: 1rem 15px; }
      .marketing-consent .consent-bordered .col.consent-header p {
        margin: 0; }

.marketing-consent .consent-bordered-bottom {
  border-bottom: 1px solid #ddd; }

.marketing-consent p {
  margin: 1rem 0; }

.quote-request .content {
  width: 95%;
  max-width: 846px;
  margin: 0 auto; }

.quote-request .oqr .red {
  color: #ce171f; }

.quote-request .oqr .oqr-block + .oqr-block {
  border-top: 1px solid #e6e6e6; }

.quote-request .oqr .oqr-block--accordion {
  padding: 69px 0 30px; }
  @media (max-width: 900px) {
    .quote-request .oqr .oqr-block--accordion {
      padding: 30px 0; } }

.quote-request .oqr .oqr-block--options {
  padding-top: 15px; }

.quote-request .oqr .oqr-block--exchange {
  padding: 52px 0 84px; }
  @media (max-width: 900px) {
    .quote-request .oqr .oqr-block--exchange {
      padding: 30px 0; } }
  .quote-request .oqr .oqr-block--exchange .oqr__inputs {
    margin: 14px -12px 0; }
    @media (max-width: 900px) {
      .quote-request .oqr .oqr-block--exchange .oqr__inputs {
        margin: 14px 0 0; } }
    .quote-request .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper {
      display: flex;
      align-items: center;
      margin-top: 23px; }
      .quote-request .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper .btn {
        max-width: 351px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        margin-top: 0;
        margin-right: 24px; }
        @media (max-width: 600px) {
          .quote-request .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper .btn {
            font-size: 15px; } }
      .quote-request .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper p {
        font-size: 14px;
        color: #666666; }

.quote-request .oqr .oqr-block--payment {
  padding: 48px 0; }
  @media (max-width: 900px) {
    .quote-request .oqr .oqr-block--payment {
      padding: 30px 0; } }
  .quote-request .oqr .oqr-block--payment .oqr__inputs {
    margin: 0 -12px; }
    @media (max-width: 900px) {
      .quote-request .oqr .oqr-block--payment .oqr__inputs {
        margin: 0; } }

.quote-request .oqr .oqr-block--contacts {
  padding: 40px 0 36px; }
  @media (max-width: 900px) {
    .quote-request .oqr .oqr-block--contacts {
      padding: 30px 0; } }
  .quote-request .oqr .oqr-block--contacts .oqr__inputs {
    margin: 25px -12px 35px; }
    @media (max-width: 900px) {
      .quote-request .oqr .oqr-block--contacts .oqr__inputs {
        margin: 25px 0 35px; } }

.quote-request .oqr__step {
  font-size: 18px;
  line-height: 22px;
  color: #ce171f; }

.quote-request .oqr__title {
  font-size: 24px;
  line-height: 28px; }

.quote-request .oqr .oqr-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 40px;
  position: relative;
  box-sizing: border-box; }
  .quote-request .oqr .oqr-label + label {
    margin-top: 9px; }
  .quote-request .oqr .oqr-label input {
    display: none; }
    .quote-request .oqr .oqr-label input:checked + span {
      color: #fff; }
      .quote-request .oqr .oqr-label input:checked + span::before {
        background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3e%3cpath fill='%23ce171f' d='M6.992.062h.015a6.93 6.93 0 0 1 6.93 6.93v.015a6.93 6.93 0 0 1-6.93 6.93h-.015a6.93 6.93 0 0 1-6.93-6.93v-.015a6.93 6.93 0 0 1 6.93-6.93z'/%3e%3c/svg%3e") no-repeat center;
        background-size: 70%;
        border-color: #fff; }
  .quote-request .oqr .oqr-label span {
    user-select: none;
    font-size: 16px;
    line-height: 28px; }
    .quote-request .oqr .oqr-label span::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #a9a9a9;
      margin-right: 10px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 10px; }

.quote-request .oqr__accordion {
  display: flex;
  margin: 27px -20px 0; }
  @media (max-width: 900px) {
    .quote-request .oqr__accordion {
      flex-direction: column;
      margin: 27px 0 0; } }

.quote-request .oqr__content, .quote-request .oqr__tab {
  margin: 0 20px; }
  @media (max-width: 900px) {
    .quote-request .oqr__content, .quote-request .oqr__tab {
      margin: 0; } }

.quote-request .oqr__content {
  width: calc(65% - 40px);
  position: relative; }
  @media (max-width: 900px) {
    .quote-request .oqr__content {
      width: 100%;
      padding-top: 60px; } }
  .quote-request .oqr__content::before {
    content: '';
    display: block;
    width: 430px;
    height: 430px;
    background-color: #eef3fb;
    border-radius: 50%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 900px) {
      .quote-request .oqr__content::before {
        top: 0;
        transform: translate(-50%, 0); } }
    @media (max-width: 600px) {
      .quote-request .oqr__content::before {
        width: 300px;
        height: 300px; } }

.quote-request .oqr__content-item {
  display: none;
  max-width: 520px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 900px) {
    .quote-request .oqr__content-item {
      max-width: 420px;
      top: calc(50% + 60px);
      transform: translate(-50%, calc(-50% - 30px)); } }
  @media (max-width: 600px) {
    .quote-request .oqr__content-item {
      max-width: 300px; } }
  .quote-request .oqr__content-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.quote-request .oqr__content-selected {
  max-width: 150px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0; }
  @media (max-width: 900px) {
    .quote-request .oqr__content-selected {
      max-width: 300px;
      text-align: center;
      top: 7px;
      right: auto;
      left: 50%;
      transform: translateX(-50%); } }
  .quote-request .oqr__content-selected p {
    color: #ce171f;
    font-size: 14px; }
    .quote-request .oqr__content-selected p.selected-car {
      color: #2c2c2c;
      font-size: 24px;
      line-height: 28px; }

.quote-request .oqr-active {
  background-color: #ce171f !important;
  border-color: #ce171f !important; }

.quote-request .oqr__tab {
  width: calc(35% - 40px); }
  @media (max-width: 900px) {
    .quote-request .oqr__tab {
      width: 100%; } }

.quote-request .oqr__inputs {
  display: flex;
  align-items: flex-end; }
  @media (max-width: 900px) {
    .quote-request .oqr__inputs {
      flex-direction: column; } }
  .quote-request .oqr__inputs .input-block {
    width: calc(33.3333% - 24px);
    margin: 0 12px;
    position: relative; }
    .quote-request .oqr__inputs .input-block.err-required::after, .quote-request .oqr__inputs .input-block.err-format::after {
      position: absolute;
      top: auto;
      bottom: -20px; }
    @media (max-width: 900px) {
      .quote-request .oqr__inputs .input-block {
        width: 100%;
        margin: 0; } }
    @media (max-width: 900px) {
      .quote-request .oqr__inputs .input-block + .input-block {
        margin-top: 22px; } }
    .quote-request .oqr__inputs .input-block.gb-reg-number::before {
      content: "";
      background: url(../img/new-gb-rn.png);
      width: 43px;
      height: 43px;
      min-width: 42px;
      min-height: 42px;
      position: absolute;
      left: 1px;
      top: 25px;
      display: block; }
    .quote-request .oqr__inputs .input-block.gb-reg-number input {
      padding-left: 55px; }
    .quote-request .oqr__inputs .input-block label {
      width: 100%;
      margin-bottom: 0; }
    .quote-request .oqr__inputs .input-block input,
    .quote-request .oqr__inputs .input-block select {
      margin-top: 7px; }
    .quote-request .oqr__inputs .input-block select {
      background: #fff url(../img/select_hov.svg) no-repeat;
      background-position: calc(100% - 15px) center; }
    .quote-request .oqr__inputs .input-block span {
      font-size: 13px;
      color: #999999; }
      .quote-request .oqr__inputs .input-block span.red {
        color: #ce171f; }

.quote-request .oqr .input-block--branch {
  display: flex; }
  @media (max-width: 600px) {
    .quote-request .oqr .input-block--branch {
      flex-direction: column;
      justify-content: center; } }
  .quote-request .oqr .input-block--branch .radio-btn + .radio-btn {
    margin-top: 0;
    margin-left: 50px; }
    @media (max-width: 600px) {
      .quote-request .oqr .input-block--branch .radio-btn + .radio-btn {
        margin-left: 0; } }

.quote-request .oqr__method-wrap {
  display: flex;
  margin-top: 20px; }
  .quote-request .oqr__method-wrap .oqr-label {
    max-width: 187px;
    width: 100%; }
    .quote-request .oqr__method-wrap .oqr-label + .oqr-label {
      margin-left: 9px;
      margin-top: 0; }

.quote-request .oqr__finance-options {
  font-size: 18px;
  font-weight: bold;
  margin: 45px 0 26px; }

.quote-request .oqr__branch {
  font-size: 16px;
  margin-bottom: 20px; }

.quote-request .oqr__submit {
  width: 266px;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 100px; }

.quote-request .oqr-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 19px; }
  @media (max-width: 900px) {
    .quote-request .oqr-options {
      flex-direction: column;
      align-items: flex-start; } }
  .quote-request .oqr-options__group {
    margin-bottom: 26px; }
    @media (max-width: 600px) {
      .quote-request .oqr-options__group {
        width: 100%; } }
  .quote-request .oqr-options__type {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 3px; }
  .quote-request .oqr-options__inputs {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 600px) {
      .quote-request .oqr-options__inputs label {
        width: 50%; } }
    .quote-request .oqr-options__inputs label:not(:last-child) {
      margin-right: 53px; }
      @media (max-width: 600px) {
        .quote-request .oqr-options__inputs label:not(:last-child) {
          margin-right: 0; } }

.appointment .page-main-title {
  height: 100px; }
  .appointment .page-main-title .title {
    font-size: 34px;
    line-height: 33px; }

.appointment .ah1 {
  font-size: 24px;
  line-height: 28px;
  margin-top: 40px; }

.appointment .inner2 {
  width: 850px; }
  @media (max-width: 900px) {
    .appointment .inner2 {
      width: 96%; } }

.appointment .reg-and-mile .personal-info {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding-bottom: 25px; }
  @media (max-width: 500px) {
    .appointment .reg-and-mile .personal-info {
      margin: 0; } }
  .appointment .reg-and-mile .personal-info .input-block {
    width: calc(33.3333% - 24px);
    margin: 0 12px 15px; }
    @media (max-width: 900px) {
      .appointment .reg-and-mile .personal-info .input-block {
        width: calc(50% - 24px); } }
    @media (max-width: 500px) {
      .appointment .reg-and-mile .personal-info .input-block {
        width: 100%;
        margin: 0 0 15px; } }
    .appointment .reg-and-mile .personal-info .input-block label:not(.checkbox-container) {
      width: 100%;
      font-size: 16px;
      margin-bottom: 0; }
      .appointment .reg-and-mile .personal-info .input-block label:not(.checkbox-container) .red {
        color: #ce171f; }
    .appointment .reg-and-mile .personal-info .input-block input,
    .appointment .reg-and-mile .personal-info .input-block select {
      height: 40px;
      border-radius: 3px; }

.appointment .reg-and-mile .btn--blue {
  max-width: 266px;
  border-radius: 5px;
  font-weight: bold; }

.appointment .reg-and-mile .notice {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-top: 17px; }

.appointment .reg-and-mile .notice-2 {
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  margin-top: 33px; }

.appointment .reg-and-mile .notice-3 {
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin-top: 15px; }

.appointment .reg-and-mile .row.border-row {
  padding-bottom: 34px; }

.appointment .reg-and-mile .row.border-top-row {
  padding-top: 24px; }

.appointment .reg-and-mile .row > .input-block {
  padding-top: 25px; }
  @media (max-width: 768px) {
    .appointment .reg-and-mile .row > .input-block {
      margin-bottom: 0; } }
  .appointment .reg-and-mile .row > .input-block > label {
    margin-bottom: 0 !important; }
  .appointment .reg-and-mile .row > .input-block .ah1 {
    margin-top: 0; }
  .appointment .reg-and-mile .row > .input-block .row {
    align-items: flex-end; }
    .appointment .reg-and-mile .row > .input-block .row .input-block {
      width: 67%;
      margin-right: 0;
      padding-top: 0; }
      .appointment .reg-and-mile .row > .input-block .row .input-block label {
        margin-bottom: 0;
        font-size: 14px; }
    .appointment .reg-and-mile .row > .input-block .row .btn {
      width: 30% !important;
      height: 40px;
      border-radius: 5px;
      font-size: 15px;
      line-height: 41px; }
  .appointment .reg-and-mile .row > .input-block .radio-label-outer {
    margin-bottom: 27px !important; }
  .appointment .reg-and-mile .row > .input-block .radio-btn + .radio-btn {
    margin-top: 25px; }
  .appointment .reg-and-mile .row > .input-block .radio-btn label {
    margin-bottom: 0;
    margin-top: 2px; }

.appointment .reg-and-mile .row .inputbox {
  height: 40px;
  border-radius: 3px; }

@media (max-width: 900px) {
  .appointment .reg-and-mile .row .form-map {
    margin-top: 35px; } }

.appointment .reg-and-mile .btn--step-3 {
  margin: 50px auto 0; }

.appointment .reg-and-mile.form-block {
  max-width: none; }

.appointment .button_block {
  text-align: center;
  margin-top: 29px; }
  .appointment .button_block.hide {
    display: none; }
  .appointment .button_block .btn {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    width: 100%;
    max-width: 266px;
    border-radius: 5px; }
    @media screen and (max-width: 900px) {
      .appointment .button_block .btn {
        display: block;
        margin: 0 auto; } }
    .appointment .button_block .btn.btn--blue {
      margin-right: 20px; }
      @media screen and (max-width: 900px) {
        .appointment .button_block .btn.btn--blue {
          margin-right: auto;
          margin-bottom: 20px; } }
    .appointment .button_block .btn.btn--gray {
      border: 1px solid #124390;
      background-color: #fff;
      color: #124390; }
      .appointment .button_block .btn.btn--gray:hover {
        background-color: #124390;
        color: #fff; }

.appointment .slice_block {
  display: none; }
  .appointment .slice_block .h2-left {
    font-size: 22px;
    line-height: 33px;
    text-align: center;
    margin-top: 20px; }
  .appointment .slice_block.active {
    display: block; }
  .appointment .slice_block a.red {
    display: inline-block;
    font-size: 16px;
    text-decoration: underline;
    line-height: 33px;
    text-align: center;
    display: block; }
  .appointment .slice_block .gqc-exchange-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 15px -12px 13px; }
    @media (max-width: 900px) {
      .appointment .slice_block .gqc-exchange-row {
        align-items: center;
        margin: 15px 0 13px; } }
    @media (max-width: 500px) {
      .appointment .slice_block .gqc-exchange-row {
        margin: 15px 0 13px; } }
    .appointment .slice_block .gqc-exchange-row .input-block {
      width: calc(33.3333% - 24px);
      margin: 0 12px 15px; }
      @media (max-width: 900px) {
        .appointment .slice_block .gqc-exchange-row .input-block {
          width: 100%;
          margin: 0 0 15px; } }
      @media (max-width: 500px) {
        .appointment .slice_block .gqc-exchange-row .input-block {
          width: 100%;
          margin: 0 0 15px; } }
      .appointment .slice_block .gqc-exchange-row .input-block p {
        font-size: 13px;
        color: #999999; }
      .appointment .slice_block .gqc-exchange-row .input-block label {
        width: 100%;
        font-size: 14px; }
      .appointment .slice_block .gqc-exchange-row .input-block input {
        border-radius: 3px;
        height: 40px; }
      @media (max-width: 600px) {
        .appointment .slice_block .gqc-exchange-row .input-block--branch .radio-btn {
          padding: 10px 10px 10px 0; } }
  .appointment .slice_block .gqc-exchange-btn-row .btn {
    width: 351px;
    height: 40px;
    border-radius: 5px;
    font-size: 16px;
    line-height: 37px;
    margin: 0 auto;
    cursor: default; }
    @media (max-width: 500px) {
      .appointment .slice_block .gqc-exchange-btn-row .btn {
        width: 100%; } }
  .appointment .slice_block .gqc-exchange-btn-row__grey {
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #666666; }

.appointment.quote-request {
  margin-top: 34px; }
  .appointment.quote-request .content {
    width: 100%;
    max-width: 100%;
    margin: 0; }
  .appointment.quote-request .oqr__step {
    display: none; }
  .appointment.quote-request .oqr__title {
    font-size: 22px;
    text-align: center;
    margin-top: 20px; }
  .appointment.quote-request .oqr-options__inputs label:not(:last-child) {
    margin-right: 27px; }
  .appointment.quote-request #lookUpSection {
    margin-top: 15px; }
    .appointment.quote-request #lookUpSection .ah2 {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 21px; }
      .appointment.quote-request #lookUpSection .ah2 a {
        margin-left: 17px;
        font-size: 14px;
        color: #ce171f;
        text-decoration: underline; }
  .appointment.quote-request .err_block {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 24px; }
    .appointment.quote-request .err_block .inn_err_block {
      padding: 30px 45px 35px;
      border: 2px solid #e6e6e6; }
    .appointment.quote-request .err_block .ah1 {
      font-size: 24px;
      line-height: 28px;
      font-weight: 700;
      margin-top: 40px;
      margin-bottom: 13px; }
    .appointment.quote-request .err_block p {
      margin-bottom: 13px; }
    .appointment.quote-request .err_block .tel {
      display: inline-block;
      padding-bottom: 6px;
      font-size: 22px;
      line-height: 25px;
      font-weight: 700;
      color: #2c2c2c;
      vertical-align: top; }
      .appointment.quote-request .err_block .tel::before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 18px;
        margin-top: -4px;
        margin-right: 8px;
        vertical-align: middle;
        background: url(../img/err.svg) no-repeat;
        background-size: auto 100%; }
    .appointment.quote-request .err_block .btn_red {
      display: inline-block;
      width: auto;
      margin-top: 11px;
      margin-right: 25px;
      padding-right: 42px;
      padding-left: 42px;
      background: #ce181f;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      vertical-align: top; }
  .appointment.quote-request .oqr__method-wrap {
    justify-content: center; }
    .appointment.quote-request .oqr__method-wrap .oqr-label {
      max-width: 150px; }
  .appointment.quote-request .oqr__finance-options {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin: 31px 0 20px; }
  .appointment.quote-request .oqr__inputs .input-block label {
    font-size: 14px; }
  .appointment.quote-request .oqr__inputs .input-block input,
  .appointment.quote-request .oqr__inputs .input-block select {
    height: 40px;
    border-radius: 3px; }
  .appointment.quote-request .oqr .oqr-block {
    width: 100%; }
    .appointment.quote-request .oqr .oqr-block--accordion {
      padding: 45px 0 39px; }
    .appointment.quote-request .oqr .oqr-block--options {
      padding-top: 17px; }
      .appointment.quote-request .oqr .oqr-block--options .checkbox-customizedv2 + span::after {
        border-radius: 3px; }
    .appointment.quote-request .oqr .oqr-block--payment {
      border-bottom: 1px solid #cccccc; }
    .appointment.quote-request .oqr .oqr-block--exchange {
      padding-top: 0; }

.appointment .form-after-submit .form-title,
.appointment .form-after-submit-home .form-title {
  text-align: center; }

.appointment .form-after-submit .btn,
.appointment .form-after-submit-home .btn {
  width: 300px;
  max-width: unset;
  margin: auto;
  font-size: 18px;
  font-weight: 700; }

.refund-form {
  margin-bottom: 110px; }
  .refund-form .inner {
    max-width: 560px; }
  .refund-form .form-block .form-title {
    margin-top: 75px;
    margin-bottom: 45px;
    font-size: 24px;
    line-height: 1.3;
    font-weight: bold;
    color: #2c2c2c; }
  .refund-form .form-block .input-block {
    margin-bottom: 26px; }
    .refund-form .form-block .input-block input::placeholder {
      color: #a6a6a6; }
  .refund-form .form-block .radiogroup-v2 {
    margin-bottom: 23px; }
    .refund-form .form-block .radiogroup-v2 .radiogroup-v2__lbl {
      min-width: 194px; }
      .refund-form .form-block .radiogroup-v2 .radiogroup-v2__lbl span {
        text-transform: none; }
  .refund-form .form-block .row + .row {
    margin-top: 10px;
    padding-top: 25px;
    border-top: 1px solid #e6e6e6; }
  .refund-form .form-block .row .row-title {
    font-size: 18px;
    font-weight: bold;
    color: #2c2c2c;
    margin-right: 0; }
  .refund-form .form-block .row .row-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #4d4d4d;
    margin-bottom: 20px; }
  .refund-form .form-block .row .flex-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .refund-form .form-block .row .flex-block .input-block {
      width: calc(50% - 10px); }
      @media (max-width: 400px) {
        .refund-form .form-block .row .flex-block .input-block {
          width: 100%; } }
  .refund-form .form-block .form-info-text {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #4d4d4d;
    text-align: center;
    margin-top: 48px; }
  .refund-form .form-block .btn {
    width: 266px;
    margin: 27px auto 0; }
  .refund-form .radio-tab:not(.active) {
    display: none; }

.ancl-page {
  padding-bottom: 100px; }
  .ancl-page .full-width-banner-ancl {
    margin-top: 50px;
    margin-bottom: 50px; }
    @media (max-width: 580px) {
      .ancl-page .full-width-banner-ancl {
        position: relative; } }
    @media (max-width: 580px) {
      .ancl-page .full-width-banner-ancl::before {
        content: '';
        display: block;
        padding-top: 100%; } }
    .ancl-page .full-width-banner-ancl img {
      width: 100%;
      height: 405px;
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover; object-position: center center;'; }
      @media (max-width: 580px) {
        .ancl-page .full-width-banner-ancl img {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0; } }
  @media (max-width: 580px) {
    .ancl-page .full-width-banner-ancl-wrap {
      width: 100%;
      margin: 0; } }
  .ancl-page .fwb-ancl-i-mob {
    display: none; }
    @media (max-width: 580px) {
      .ancl-page .fwb-ancl-i-mob {
        display: block; } }
  @media (max-width: 580px) {
    .ancl-page .fwb-ancl-i-desk {
      display: none; } }
  .ancl-page .ancl-dear-customer {
    width: 100%;
    margin-bottom: 50px;
    text-align: center; }
    @media (max-width: 700px) {
      .ancl-page .ancl-dear-customer {
        margin-bottom: 50px; } }
    .ancl-page .ancl-dear-customer p {
      color: #2c2c2c;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.3; }
      @media (max-width: 700px) {
        .ancl-page .ancl-dear-customer p {
          font-size: 14px;
          line-height: 25px; } }
      .ancl-page .ancl-dear-customer p + h2 {
        margin-top: 15px; }
        @media (max-width: 700px) {
          .ancl-page .ancl-dear-customer p + h2 {
            margin-top: 10px; } }
      .ancl-page .ancl-dear-customer p + p {
        margin-top: 24px; }
        @media (max-width: 700px) {
          .ancl-page .ancl-dear-customer p + p {
            margin-top: 20px; } }
    .ancl-page .ancl-dear-customer .h2 {
      text-transform: uppercase;
      color: #2c2c2c;
      font-weight: bold;
      font-size: 28px;
      line-height: 1.3; }
      @media (max-width: 700px) {
        .ancl-page .ancl-dear-customer .h2 {
          font-size: 20px; } }
      .ancl-page .ancl-dear-customer .h2 + p {
        margin-top: 25px; }
    .ancl-page .ancl-dear-customer__row {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 700px) {
        .ancl-page .ancl-dear-customer__row {
          display: block; } }
    .ancl-page .ancl-dear-customer__image {
      width: 65%;
      margin-left: 30px;
      flex-shrink: 0; }
      @media (max-width: 700px) {
        .ancl-page .ancl-dear-customer__image {
          width: auto;
          margin-left: 0;
          margin-bottom: 50px; } }
      .ancl-page .ancl-dear-customer__image iframe {
        display: block;
        width: 100%;
        height: 415px; }
        @media (max-width: 700px) {
          .ancl-page .ancl-dear-customer__image iframe {
            height: 250px; } }
        @media (max-width: 580px) {
          .ancl-page .ancl-dear-customer__image iframe {
            height: 200px; } }
  .ancl-page .ancl-form {
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.07); }
    @media (max-width: 700px) {
      .ancl-page .ancl-form {
        border: 0;
        box-shadow: none; } }
    .ancl-page .ancl-form__title {
      padding: 18px 15px;
      border-bottom: 1px solid #d9d9d9;
      background-color: #ce171f;
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      box-sizing: border-box; }
      @media (max-width: 700px) {
        .ancl-page .ancl-form__title {
          width: 100%;
          border-bottom: 0;
          background-color: #ce171f;
          font-size: 20px;
          position: absolute;
          left: 0; } }
    .ancl-page .ancl-form__content {
      width: 765px;
      max-width: calc(100% - 30px);
      margin: 0 auto;
      padding: 29px 0 58px; }
      @media (max-width: 700px) {
        .ancl-page .ancl-form__content {
          padding: 100px 0 58px; } }
      .ancl-page .ancl-form__content .ah2 {
        font-size: 16px;
        text-align: center; }
  .ancl-page .ancl-form-row-1 {
    margin-top: 18px;
    margin-bottom: 18px; }
    .ancl-page .ancl-form-row-1 .input-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 500px;
      max-width: 100%;
      margin: 0 auto; }
      @media (max-width: 700px) {
        .ancl-page .ancl-form-row-1 .input-block {
          flex-direction: column; } }
    .ancl-page .ancl-form-row-1 .radio-btn {
      display: flex;
      align-items: center;
      margin: 0 10px !important; }
      @media (max-width: 700px) {
        .ancl-page .ancl-form-row-1 .radio-btn {
          display: flex;
          align-items: center;
          width: 100%;
          box-sizing: border-box; } }
      @media (max-width: 700px) {
        .ancl-page .ancl-form-row-1 .radio-btn + .radio-btn {
          margin-top: 10px !important; } }
      @media (max-width: 700px) {
        .ancl-page .ancl-form-row-1 .radio-btn input {
          display: none; }
          .ancl-page .ancl-form-row-1 .radio-btn input:checked + .radio-label {
            background-color: #124390;
            color: #ffffff; }
            .ancl-page .ancl-form-row-1 .radio-btn input:checked + .radio-label::before {
              background: #fff url(../img/radio.svg) no-repeat center center; } }
      .ancl-page .ancl-form-row-1 .radio-btn .radio-label {
        margin: 0; }
        @media (max-width: 700px) {
          .ancl-page .ancl-form-row-1 .radio-btn .radio-label {
            display: flex !important;
            align-items: center;
            width: 100%;
            border: 1px solid #cccccc;
            padding: 10px 12px;
            margin: 0 !important; } }
        @media (max-width: 700px) {
          .ancl-page .ancl-form-row-1 .radio-btn .radio-label::before {
            content: '';
            display: block;
            width: 15px;
            height: 15px;
            margin: 0;
            margin-right: 8px;
            border: 1px solid #a5a5a5;
            border-radius: 25px;
            cursor: pointer;
            vertical-align: middle;
            outline: 0 !important; } }
  .ancl-page .ancl-form-row-2 {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 700px) {
      .ancl-page .ancl-form-row-2 {
        margin-top: 35px; } }
    .ancl-page .ancl-form-row-2 .input-block {
      width: calc(33.33333% - 8px); }
      @media (max-width: 700px) {
        .ancl-page .ancl-form-row-2 .input-block {
          width: 100%; } }
  .ancl-page .ancl-form-row-3 {
    display: flex;
    justify-content: space-between;
    margin-top: 25px; }
    .ancl-page .ancl-form-row-3 .input-block {
      width: calc(50% - 12px); }
  @media (max-width: 700px) {
    .ancl-page .ancl-form-row-2,
    .ancl-page .ancl-form-row-3 {
      display: block; } }
  @media (max-width: 700px) {
    .ancl-page .ancl-form-row-2 .input-block,
    .ancl-page .ancl-form-row-3 .input-block {
      width: 100%; } }
  @media (max-width: 700px) {
    .ancl-page .ancl-form-row-2 .input-block:not(:first-child),
    .ancl-page .ancl-form-row-3 .input-block:not(:first-child) {
      margin-top: 19px; } }
  .ancl-page .ancl-form-btn {
    width: 266px !important;
    margin: 40px auto 0; }
    @media (max-width: 700px) {
      .ancl-page .ancl-form-btn {
        width: 100% !important;
        max-width: 100% !important; } }

@media (max-width: 1009px) {
  .vehicle-info-page {
    padding-top: 30px; } }

.vehicle-info-page__inner {
  padding: 100px 0; }
  @media (max-width: 1009px) {
    .vehicle-info-page__inner {
      padding: 20px 0 50px; } }

.vehicle-info-page .top .left {
  font-size: 34px;
  float: left; }

.vehicle-info-page .top .right {
  width: 300px;
  max-width: 100%;
  text-align: right;
  float: right; }
  @media (max-width: 800px) {
    .vehicle-info-page .top .right {
      margin: 20px auto 0;
      max-width: 100%; } }
  @media (max-width: 800px) {
    .vehicle-info-page .top .right .price-new {
      text-align: center; } }

@media (max-width: 800px) {
  .vehicle-info-page .top .left,
  .vehicle-info-page .top .right {
    float: none;
    text-align: center; } }

.vehicle-info-page .top .model {
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600; }
  @media (max-width: 800px) {
    .vehicle-info-page .top .model {
      font-size: 30px; } }

.vehicle-info-page .top .type {
  font-size: 28px;
  line-height: 1.1;
  font-weight: 400; }
  @media (max-width: 800px) {
    .vehicle-info-page .top .type {
      font-size: 23px; } }

.vehicle-info-page .top .price {
  display: block;
  padding: 17px 52px 17px 52px;
  background: #ef1923;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase; }
  @media (max-width: 800px) {
    .vehicle-info-page .top .price {
      display: inline-block;
      padding: 10px;
      text-align: center; } }
  .vehicle-info-page .top .price span {
    font-size: 36px;
    line-height: 28px;
    font-weight: bold; }

.vehicle-info-page .main {
  border-top: 1px solid #ce171f;
  border-bottom: 1px solid #ce171f;
  margin-top: 29px; }
  .vehicle-info-page .main > .right {
    width: 50%;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 15px;
    box-sizing: border-box;
    float: right; }
    @media (max-width: 1009px) {
      .vehicle-info-page .main > .right {
        width: 100%;
        display: block;
        padding-left: 0;
        padding-right: 0; } }
    @media (min-width: 1010px) {
      .vehicle-info-page .main > .right {
        float: left; } }
    .vehicle-info-page .main > .right ul {
      list-style: none !important;
      padding-left: 0; }
      @media (min-width: 1010px) {
        .vehicle-info-page .main > .right ul:not(:first-child) {
          margin-top: 15px; } }
      .vehicle-info-page .main > .right ul li {
        font-size: 18px;
        line-height: 36px;
        border-bottom: 1px solid #e6e6e6; }
        @media (max-width: 550px) {
          .vehicle-info-page .main > .right ul li {
            font-size: 14px;
            line-height: 1.5; } }
        .vehicle-info-page .main > .right ul li:last-child {
          border-bottom: 0; }
        .vehicle-info-page .main > .right ul li .left {
          display: inline-block; }
        .vehicle-info-page .main > .right ul li .right {
          display: inline-block;
          padding-left: 10px;
          float: right; }
    @media (min-width: 1010px) {
      .vehicle-info-page .main > .right p:not(:first-child) {
        margin-top: 15px; } }
    @media (min-width: 1010px) {
      .vehicle-info-page .main > .right .star {
        display: block;
        margin-top: 15px;
        font-size: 11px;
        font-style: italic;
        line-height: 13px; } }
  .vehicle-info-page .main > .left {
    width: 50%;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 15px;
    box-sizing: border-box;
    float: left; }
    @media (max-width: 1009px) {
      .vehicle-info-page .main > .left {
        width: 100%;
        display: block;
        padding-left: 0;
        padding-right: 0;
        text-align: center; } }
    @media (min-width: 1010px) {
      .vehicle-info-page .main > .left {
        float: left; } }
    .vehicle-info-page .main > .left img {
      max-width: 100%;
      height: auto; }
      @media (min-width: 1010px) {
        .vehicle-info-page .main > .left img {
          width: 100%;
          height: auto; } }
    .vehicle-info-page .main > .left .title {
      text-align: center;
      width: 160px;
      height: 36px;
      border: 2px solid #bfdeff;
      background: #000;
      font-size: 13px;
      line-height: 36px;
      font-weight: 600;
      color: #fff;
      box-sizing: border-box; }
    .vehicle-info-page .main > .left p:not(:first-child) {
      margin-top: 15px; }
    .vehicle-info-page .main > .left ul {
      list-style: disc;
      padding-left: 20px; }
      .vehicle-info-page .main > .left ul:not(:first-child) {
        margin-top: 15px; }
      .vehicle-info-page .main > .left ul .star {
        display: block;
        margin-top: 15px;
        font-size: 11px;
        line-height: 13px;
        font-style: italic; }
      @media (min-width: 1010px) {
        .vehicle-info-page .main > .left ul li .left {
          display: inline-block; } }
      @media (min-width: 1010px) {
        .vehicle-info-page .main > .left ul li .right {
          display: inline-block;
          float: right; } }

.vehicle-info-page .features .col {
  max-width: 33.33332%;
  width: 33.33332%;
  float: left; }
  @media (max-width: 800px) {
    .vehicle-info-page .features .col {
      width: 49.8%;
      max-width: 49.8%; } }
  @media (max-width: 550px) {
    .vehicle-info-page .features .col {
      width: 100%;
      max-width: 100%; } }
  @media (min-width: 1010px) {
    .vehicle-info-page .features .col {
      float: left; } }

.vehicle-info-page .features .item:not(.first-child) {
  margin-top: 20px; }

.vehicle-info-page .features .item .title {
  padding: 7px;
  margin-left: 18px;
  margin-right: 5px;
  margin-bottom: 7px;
  border-bottom: 2px solid #e6e6e6;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  color: #2c2c2c; }
  @media (max-width: 550px) {
    .vehicle-info-page .features .item .title {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 7px;
      font-size: 16px;
      line-height: 25px;
      font-weight: 700; } }

.vehicle-info-page .features .item ul {
  list-style: none; }
  .vehicle-info-page .features .item ul li {
    min-height: 22px;
    margin-left: 32px;
    margin-right: 5px;
    font-size: 15px;
    line-height: 32px;
    font-weight: 400;
    position: relative;
    box-sizing: border-box; }
    @media (max-width: 550px) {
      .vehicle-info-page .features .item ul li {
        margin-left: 21px;
        line-height: 26px; } }
    .vehicle-info-page .features .item ul li::before {
      content: "▪";
      display: inline-block;
      width: 1em;
      margin-left: -1em;
      font-weight: bold;
      color: #ce171f; }

.vehicle-info-page .sub-footer {
  margin-top: 20px;
  border-top: 1px solid #b3b3b3; }
  .vehicle-info-page .sub-footer .cols .col {
    width: 33.333333333333333%;
    padding-top: 10px;
    box-sizing: border-box;
    float: left; }
    .vehicle-info-page .sub-footer .cols .col p {
      font-size: 13px;
      line-height: 18.98px;
      font-weight: 400;
      color: #46494c; }
      .vehicle-info-page .sub-footer .cols .col p b {
        font-size: 15px;
        line-height: 10.85px;
        font-weight: 700;
        color: #46494c; }
    .vehicle-info-page .sub-footer .cols .col:not(:last-child) {
      width: 33.33332%;
      padding-right: 10px; }
      @media (max-width: 800px) {
        .vehicle-info-page .sub-footer .cols .col:not(:last-child) {
          width: 49.9%; } }
      @media (max-width: 550px) {
        .vehicle-info-page .sub-footer .cols .col:not(:last-child) {
          width: 100%; } }
    .vehicle-info-page .sub-footer .cols .col:last-child {
      text-align: right; }
  .vehicle-info-page .sub-footer .desc {
    padding-top: 20px;
    font-size: 12px;
    line-height: 17.18px;
    font-weight: 400;
    color: #46494c; }

.warranty-log .reg-war-block {
  width: 648px;
  max-width: 100%;
  margin: 48px auto 42px;
  border: 1px solid #a5a5a5; }
  .warranty-log .reg-war-block .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 48px 20px 45px;
    border-bottom: 1px solid #a5a5a5;
    cursor: pointer;
    transition-duration: 0.2s; }
    @media (max-width: 500px) {
      .warranty-log .reg-war-block .head {
        padding: 10px; } }
    .warranty-log .reg-war-block .head.closed {
      border-bottom: 0; }
      .warranty-log .reg-war-block .head.closed .arrow {
        transform: rotate(-180deg); }
    .warranty-log .reg-war-block .head .title {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 24px;
      line-height: 28px;
      font-weight: 400;
      color: #2c2c2c; }
      @media (max-width: 500px) {
        .warranty-log .reg-war-block .head .title {
          font-size: 18px;
          line-height: 20px; } }
    .warranty-log .reg-war-block .head .hide {
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 33px;
      font-weight: 400;
      color: #ce171f;
      text-decoration: underline; }
      .warranty-log .reg-war-block .head .hide .arrow {
        width: 12px;
        height: 8px;
        margin-left: 5px;
        transition-duration: 0.2s;
        background: url("../img/arrow-top-red.png") center center; }
  .warranty-log .reg-war-block .content {
    padding: 27px 43px 38px 43px; }
    @media (max-width: 500px) {
      .warranty-log .reg-war-block .content {
        padding: 10px; } }
    .warranty-log .reg-war-block .content .branch {
      margin-bottom: 28px; }
      .warranty-log .reg-war-block .content .branch p {
        margin-bottom: 8px;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #2c2c2c; }
      .warranty-log .reg-war-block .content .branch .custom_radios {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 -5px; }
      .warranty-log .reg-war-block .content .branch label {
        display: flex;
        align-items: center;
        margin: 5px;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        color: #2c2c2c;
        cursor: pointer; }
        .warranty-log .reg-war-block .content .branch label input {
          display: flex; }
    .warranty-log .reg-war-block .content .input-block {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px; }
      @media (max-width: 500px) {
        .warranty-log .reg-war-block .content .input-block {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 5px; } }
      .warranty-log .reg-war-block .content .input-block input {
        width: calc(100% - 151px);
        height: 40px; }
        @media (max-width: 500px) {
          .warranty-log .reg-war-block .content .input-block input {
            width: 100%; } }
      .warranty-log .reg-war-block .content .input-block select {
        width: calc(100% - 151px); }
        @media (max-width: 500px) {
          .warranty-log .reg-war-block .content .input-block select {
            width: 100%; } }
        .warranty-log .reg-war-block .content .input-block select .placeholder {
          color: #adadad; }
      .warranty-log .reg-war-block .content .input-block label {
        width: 151px;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 18px;
        line-height: 28px;
        font-weight: 400;
        color: #2c2c2c; }
        @media (max-width: 500px) {
          .warranty-log .reg-war-block .content .input-block label {
            margin-bottom: 0; } }
      .warranty-log .reg-war-block .content .input-block.err-required::after {
        width: 100%;
        text-align: right; }
    .warranty-log .reg-war-block .content .buttons {
      display: flex;
      padding-top: 10px;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
      color: #ffffff; }
      .warranty-log .reg-war-block .content .buttons div {
        display: flex;
        align-items: center;
        justify-content: center;
        transition-duration: 0.2s;
        cursor: pointer; }
      .warranty-log .reg-war-block .content .buttons .submit {
        width: 242px;
        height: 40px;
        margin-right: 10px;
        background-color: #ce171f; }
      .warranty-log .reg-war-block .content .buttons .cancel {
        width: 143px;
        height: 40px;
        background-color: #58585a; }

.warranty-log .log .table-wrap {
  overflow-x: auto; }

.warranty-log .log .filters {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -20px;
  margin-bottom: 25px; }
  .warranty-log .log .filters > div {
    margin: 0 20px; }
  .warranty-log .log .filters .input-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px; }
    .warranty-log .log .filters .input-block label {
      display: flex;
      align-items: center;
      margin-bottom: 0; }
    .warranty-log .log .filters .input-block select {
      width: 230px;
      margin-left: 15px;
      background: #fff url(../img/select_hov.svg) no-repeat;
      background-position: calc(100% - 15px) center; }
  .warranty-log .log .filters .apply {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 40px;
    margin-bottom: 15px;
    background-color: #ce171f;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 15px;
    line-height: 28px;
    color: #ffffff;
    cursor: pointer; }
  .warranty-log .log .filters .reset {
    margin-bottom: 15px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
    color: #ce171f;
    text-decoration: underline;
    cursor: pointer; }

.warranty-log .log .table {
  width: 1858px;
  margin: 0 auto;
  border: 1px solid #e6e6e6; }
  @media (max-width: 500px) {
    .warranty-log .log .table {
      width: 1633px; } }
  .warranty-log .log .table .row {
    display: flex;
    margin-bottom: 0; }
    .warranty-log .log .table .row .col:nth-child(1) {
      width: calc(58px - 26px); }
    .warranty-log .log .table .row .col:nth-child(2) {
      width: calc(122px - 26px); }
    .warranty-log .log .table .row .col:nth-child(3) {
      width: calc(149px - 26px); }
    .warranty-log .log .table .row .col:nth-child(4) {
      width: calc(153px - 26px); }
    .warranty-log .log .table .row .col:nth-child(5) {
      width: calc(144px - 26px); }
    .warranty-log .log .table .row .col:nth-child(6) {
      width: calc(158px - 26px); }
      @media (max-width: 500px) {
        .warranty-log .log .table .row .col:nth-child(6) {
          width: calc(258px); } }
    .warranty-log .log .table .row .col:nth-child(7) {
      width: calc(154px - 26px); }
    .warranty-log .log .table .row .col:nth-child(8) {
      width: calc(203px - 26px); }
    .warranty-log .log .table .row .col:nth-child(9) {
      width: calc(118px - 26px); }
      @media (max-width: 500px) {
        .warranty-log .log .table .row .col:nth-child(9) {
          width: calc(218px); } }
    .warranty-log .log .table .row .col:nth-child(10) {
      width: calc(125px - 26px); }
    .warranty-log .log .table .row .col:nth-child(11) {
      width: calc(115px - 26px); }
    .warranty-log .log .table .row .col:nth-child(12) {
      width: calc(112px - 26px); }
    .warranty-log .log .table .row .col:nth-child(13) {
      width: calc(107px - 26px); }
    .warranty-log .log .table .row .col:nth-child(14) {
      width: calc(139px - 26px); }
  .warranty-log .log .table .headline {
    height: 70px;
    background-color: #ce171f; }
    .warranty-log .log .table .headline .col {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 13px;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: #ffffff; }
      @media (max-width: 500px) {
        .warranty-log .log .table .headline .col {
          padding: 10px 5px;
          font-size: 13px; } }
  .warranty-log .log .table .content .col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 25px 15px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400; }
    @media (max-width: 500px) {
      .warranty-log .log .table .content .col {
        padding: 10px 5px;
        font-size: 12px;
        line-height: 17px; } }
    .warranty-log .log .table .content .col:nth-child(even) {
      background-color: #f5f5f5; }
    .warranty-log .log .table .content .col .refs {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .warranty-log .log .table .content .col .refs a {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #ce171f;
        text-decoration: underline; }
        .warranty-log .log .table .content .col .refs a:not(:last-child) {
          margin-right: 15px; }
    .warranty-log .log .table .content .col.col-select {
      padding-top: 20px; }
      @media (max-width: 500px) {
        .warranty-log .log .table .content .col.col-select {
          padding-top: 7px; } }
      .warranty-log .log .table .content .col.col-select select {
        font-size: 14px;
        padding: 0 10px 0 15px; }
      .warranty-log .log .table .content .col.col-select:nth-child(2) select {
        width: 93px;
        height: 30px; }
      .warranty-log .log .table .content .col.col-select:nth-child(8) select {
        width: 174px;
        height: 30px; }
      .warranty-log .log .table .content .col.col-select:nth-child(14) select {
        width: 110px;
        height: 30px; }

.warranty-log.deals-log .page-main-title {
  margin-bottom: 50px; }

.warranty-log.deals-log .log .table {
  width: 1640px;
  display: table; }
  .warranty-log.deals-log .log .table .row {
    display: table-row; }
    .warranty-log.deals-log .log .table .row .col:nth-child(1) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(2) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(3) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(4) {
      width: auto;
      box-sizing: border-box;
      padding-right: 5px; }
    .warranty-log.deals-log .log .table .row .col:nth-child(5) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(6) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(7) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(8) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(9) {
      width: auto;
      box-sizing: border-box;
      align-items: center;
      text-align: center; }
    .warranty-log.deals-log .log .table .row .col:nth-child(10) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(11) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(13) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col:nth-child(14) {
      width: auto;
      box-sizing: border-box; }
    .warranty-log.deals-log .log .table .row .col.left_bg {
      border-left: 1px solid #a91830; }
    .warranty-log.deals-log .log .table .row .col.right_bg {
      width: auto;
      background: #a91830;
      border-right: 1px solid #a91830;
      box-sizing: border-box; }
  .warranty-log.deals-log .log .table .col {
    display: table-cell; }
  .warranty-log.deals-log .log .table .headline .col {
    justify-content: flex-start;
    vertical-align: middle; }
    .warranty-log.deals-log .log .table .headline .col.center_bg {
      background: #a91830; }
    .warranty-log.deals-log .log .table .headline .col.left_bg {
      background: #a91830; }
    .warranty-log.deals-log .log .table .headline .col:first-child {
      justify-content: center;
      text-align: center; }
    .warranty-log.deals-log .log .table .headline .col:nth-child(9) {
      justify-content: center;
      text-align: center; }
  .warranty-log.deals-log .log .table .content {
    border-top: 1px solid #d9d9d9; }
    .warranty-log.deals-log .log .table .content .col {
      justify-content: flex-start;
      flex-direction: column; }
      .warranty-log.deals-log .log .table .content .col:first-child {
        justify-content: center;
        flex-direction: row;
        text-align: center; }
      .warranty-log.deals-log .log .table .content .col.blue_bg {
        background: #eef5fe; }
      .warranty-log.deals-log .log .table .content .col p + p {
        margin-top: 10px; }

.warranty-log.reservation-log .log .table {
  display: table;
  width: 1580px; }
  .warranty-log.reservation-log .log .table .headline,
  .warranty-log.reservation-log .log .table .row {
    display: table-row; }
    .warranty-log.reservation-log .log .table .headline .col,
    .warranty-log.reservation-log .log .table .row .col {
      display: table-cell;
      width: auto !important;
      vertical-align: middle; }
      .warranty-log.reservation-log .log .table .headline .col .name-before,
      .warranty-log.reservation-log .log .table .row .col .name-before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 14px;
        margin-top: -2px;
        margin-right: 3px;
        background: url("../img/name.png") no-repeat;
        vertical-align: middle;
        transition-duration: 0.2s; }
        @media (min-width: 1024px) {
          .warranty-log.reservation-log .log .table .headline .col .name-before:hover,
          .warranty-log.reservation-log .log .table .row .col .name-before:hover {
            opacity: 0.8; } }
      .warranty-log.reservation-log .log .table .headline .col .button--red,
      .warranty-log.reservation-log .log .table .row .col .button--red {
        display: block;
        width: 110px;
        height: 30px;
        font-size: 16px;
        line-height: 30px; }
    .warranty-log.reservation-log .log .table .headline .col:first-child,
    .warranty-log.reservation-log .log .table .row .col:first-child {
      text-align: center; }

.warranty-log.reservation-log .filters {
  display: block;
  max-width: 96%;
  width: 1580px;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto; }
  .warranty-log.reservation-log .filters .btns {
    display: flex;
    flex-wrap: wrap;
    margin: 0; }
    .warranty-log.reservation-log .filters .btns .btn {
      display: block;
      min-width: 180px;
      height: 40px;
      padding: 0 7px;
      margin-right: 20px;
      margin-bottom: 30px;
      font-size: 15px;
      line-height: 40px;
      box-sizing: border-box;
      user-select: none; }
      @media (max-width: 500px) {
        .warranty-log.reservation-log .filters .btns .btn {
          width: 100%;
          margin-right: 0; } }
  .warranty-log.reservation-log .filters .blue--btn {
    display: block;
    width: 180px;
    height: 40px;
    margin-bottom: 30px;
    background: #124390;
    font-size: 15px;
    line-height: 40px; }
    @media (max-width: 768px) {
      .warranty-log.reservation-log .filters .blue--btn {
        margin-left: auto;
        margin-right: auto; } }
    .warranty-log.reservation-log .filters .blue--btn:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-right: 11px;
      background: url("../img/plus.svg") no-repeat center center;
      vertical-align: middle; }
  .warranty-log.reservation-log .filters .input-block {
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 auto 30px; }
    @media (max-width: 768px) {
      .warranty-log.reservation-log .filters .input-block {
        display: block; } }
    .warranty-log.reservation-log .filters .input-block .nowrap {
      margin-right: 15px;
      margin-bottom: 20px; }
      @media (max-width: 768px) {
        .warranty-log.reservation-log .filters .input-block .nowrap {
          margin-right: 0;
          text-align: center; } }
      @media (max-width: 590px) {
        .warranty-log.reservation-log .filters .input-block .nowrap {
          display: block;
          margin-bottom: 5px; } }
      @media (max-width: 768px) {
        .warranty-log.reservation-log .filters .input-block .nowrap label {
          width: 100%; } }
      @media (max-width: 590px) {
        .warranty-log.reservation-log .filters .input-block .nowrap > * {
          display: block;
          width: 250px !important;
          max-width: 100%;
          margin-left: auto !important;
          margin-right: auto !important; } }
    .warranty-log.reservation-log .filters .input-block label {
      font-size: 18px; }
    .warranty-log.reservation-log .filters .input-block input[type="text"] {
      width: 160px;
      margin-left: 7px;
      margin-right: 7px;
      margin-top: 0; }
    .warranty-log.reservation-log .filters .input-block:not(.flex-block) label {
      display: inline-block;
      line-height: 45px;
      vertical-align: top; }
      @media (max-width: 768px) {
        .warranty-log.reservation-log .filters .input-block:not(.flex-block) label {
          display: block; } }
    .warranty-log.reservation-log .filters .input-block.flex-block {
      align-items: center; }
      @media (max-width: 768px) {
        .warranty-log.reservation-log .filters .input-block.flex-block {
          margin-right: 0;
          text-align: center; } }
      @media (max-width: 768px) {
        .warranty-log.reservation-log .filters .input-block.flex-block label:not(.checkbox) {
          display: block;
          text-align: center; } }
      .warranty-log.reservation-log .filters .input-block.flex-block select {
        margin-right: 25px; }
        @media (max-width: 768px) {
          .warranty-log.reservation-log .filters .input-block.flex-block select {
            margin-left: 0;
            margin-top: 15px; } }
        @media (max-width: 590px) {
          .warranty-log.reservation-log .filters .input-block.flex-block select {
            margin-right: 0; } }
      .warranty-log.reservation-log .filters .input-block.flex-block .checkbox {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: #2c2c2c;
        margin-right: 25px; }
        @media (max-width: 768px) {
          .warranty-log.reservation-log .filters .input-block.flex-block .checkbox {
            display: flex;
            justify-content: center;
            margin-right: 0;
            margin-top: 15px; } }
        .warranty-log.reservation-log .filters .input-block.flex-block .checkbox input {
          min-width: 20px;
          width: 20px;
          height: 20px;
          margin-right: 9px;
          margin-bottom: 0;
          margin-top: 0;
          border: 1px solid #a5a5a5;
          background-color: #fff;
          cursor: pointer;
          appearance: none; }
          .warranty-log.reservation-log .filters .input-block.flex-block .checkbox input:checked {
            background-image: url(../img/checked.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%; }
  .warranty-log.reservation-log .filters .hb1-2 {
    width: 227px;
    height: 40px;
    margin-bottom: 0;
    border-radius: 5px;
    line-height: 45px;
    text-align: center; }
    @media (max-width: 768px) {
      .warranty-log.reservation-log .filters .hb1-2 {
        margin-left: auto;
        margin-right: auto; } }
    @media (max-width: 590px) {
      .warranty-log.reservation-log .filters .hb1-2 {
        margin-top: 15px; } }

.about {
  padding: 15px 0 100px; }
  .about__banner {
    height: 450px;
    margin-bottom: 42px; }
    @media (max-width: 767px) {
      .about__banner {
        height: 300px; } }
    .about__banner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  .about__block + .about__block {
    padding-top: 43px;
    margin-top: 47px;
    border-top: 1px solid #e6e6e6; }
    @media (max-width: 767px) {
      .about__block + .about__block {
        padding-top: 25px;
        margin-top: 25px; } }
  .about__title {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 23px; }
    @media (max-width: 767px) {
      .about__title {
        font-size: 26px;
        line-height: 30px; } }
  .about__subtitle {
    font-size: 20px;
    line-height: 32px;
    color: #4d4d4d;
    margin-bottom: 28px; }
    @media (max-width: 767px) {
      .about__subtitle {
        font-size: 18px;
        line-height: 22px; } }
  .about__text {
    font-size: 15px;
    line-height: 24px;
    color: #4d4d4d; }
    @media (max-width: 767px) {
      .about__text {
        font-size: 14px;
        line-height: 17px; } }
    .about__text p + p {
      margin-top: 23px; }
      @media (max-width: 767px) {
        .about__text p + p {
          margin-top: 15px; } }
    .about__text a {
      color: #ce171f;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .about__text a:hover {
          text-decoration: none; } }
    @media (max-width: 1200px) {
      .about__text br {
        display: none; } }
  .about__wrapper {
    display: flex;
    margin: 56px -25px 61px; }
    @media (max-width: 767px) {
      .about__wrapper {
        flex-direction: column;
        margin: 40px 0 61px; } }
  .about__col {
    width: calc(48% - 50px);
    margin: 0 25px; }
    @media (max-width: 767px) {
      .about__col {
        width: 100%;
        margin: 0; } }
    .about__col:last-child {
      width: calc(50% - 50px); }
      @media (max-width: 767px) {
        .about__col:last-child {
          width: 100%;
          margin-top: 30px; } }
  .about__welcome {
    font-size: 24px;
    line-height: 28px;
    color: #000;
    margin-top: 45px; }
    @media (max-width: 1023px) {
      .about__welcome {
        text-align: center; } }
  .about .gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;'; }
  .about .gallery__block {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 1023px) {
      .about .gallery__block {
        flex-direction: column; } }
    .about .gallery__block + .gallery__block {
      margin-top: 61px; }
      @media (max-width: 767px) {
        .about .gallery__block + .gallery__block {
          margin-top: 35px; } }
    .about .gallery__block--reverse .gallery__left {
      order: 1; }
      @media (max-width: 1023px) {
        .about .gallery__block--reverse .gallery__left {
          order: 0; } }
    .about .gallery__block--reverse .gallery__title {
      text-align: right; }
      @media (max-width: 1023px) {
        .about .gallery__block--reverse .gallery__title {
          text-align: center; } }
    .about .gallery__block--reverse .owl-nav {
      display: flex;
      justify-content: flex-end; }
  .about .gallery__title {
    font-size: 30px;
    margin-bottom: 36px;
    padding-top: 4px; }
    @media (max-width: 1023px) {
      .about .gallery__title {
        text-align: center; } }
    @media (max-width: 767px) {
      .about .gallery__title {
        font-size: 26px; } }
  .about .gallery__left {
    width: 34.7%;
    height: 263px; }
    @media (max-width: 1023px) {
      .about .gallery__left {
        width: 100%; } }
  .about .gallery__right {
    width: 64%; }
    @media (max-width: 1023px) {
      .about .gallery__right {
        width: 100%;
        margin-top: 15px; } }
  .about .gallery__slider:not(.owl-loaded) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    opacity: 0; }
  .about .gallery__slide {
    display: block;
    height: 159px; }
    @media (max-width: 450px) {
      .about .gallery__slide {
        height: 130px; } }
    .about .gallery__slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  .about .gallery .owl-nav {
    display: flex;
    align-items: center;
    margin-top: 12px; }
    @media (max-width: 1023px) {
      .about .gallery .owl-nav {
        justify-content: center; } }
    .about .gallery .owl-nav .owl-prev {
      margin-right: 10px; }
  .about .gallery__nav {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #ce171f;
    transition: opacity 0.3s ease; }
    @media (min-width: 1024px) {
      .about .gallery__nav:hover {
        opacity: 0.8; } }
    .about .gallery__nav--prev svg {
      transform: rotate(180deg); }

.about-detail__title {
  font-size: 20px;
  margin-bottom: 36px; }
  @media (max-width: 767px) {
    .about-detail__title {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 24px; } }

.about-detail__text {
  font-size: 15px;
  line-height: 24px;
  color: #4d4d4d;
  margin-bottom: 18px; }
  @media (max-width: 767px) {
    .about-detail__text {
      font-size: 14px;
      line-height: 17px; } }
  .about-detail__text p + p {
    margin-top: 24px; }
    @media (max-width: 767px) {
      .about-detail__text p + p {
        margin-top: 15px; } }

.about-detail__link {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  color: #ce181f;
  text-decoration: underline; }
  @media (min-width: 1024px) {
    .about-detail__link:hover {
      text-decoration: none; }
      .about-detail__link:hover svg {
        transform: translateX(3px); } }
  .about-detail__link svg {
    margin-left: 5px;
    fill: #ce181f;
    transition: transform 0.3s ease; }

.automotive-compliance .au-co-first .title {
  margin-top: 90px;
  margin-bottom: 27px;
  font-size: 20px;
  font-weight: bold;
  text-align: center; }
  @media (max-width: 500px) {
    .automotive-compliance .au-co-first .title {
      margin-top: 30px;
      line-height: 1.3; } }

.automotive-compliance .au-co-first .button--red {
  display: block;
  width: 355px;
  margin-left: auto;
  margin-right: auto;
  font-size: 18px; }
  @media (max-width: 500px) {
    .automotive-compliance .au-co-first .button--red {
      max-width: 100%; } }

.automotive-compliance .au-co-second {
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 55px; }
  .automotive-compliance .au-co-second .title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 37px; }
    @media (max-width: 500px) {
      .automotive-compliance .au-co-second .title {
        margin-top: 30px;
        line-height: 1.3; } }
  .automotive-compliance .au-co-second .row {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 500px) {
      .automotive-compliance .au-co-second .row {
        display: block;
        width: calc(100% - 30px);
        margin: 0 auto; } }
  .automotive-compliance .au-co-second .block {
    width: 48%; }
    @media (max-width: 500px) {
      .automotive-compliance .au-co-second .block {
        width: 100%; } }
    .automotive-compliance .au-co-second .block label {
      display: block;
      margin-bottom: 3px;
      font-size: 16px;
      line-height: 20px; }
    .automotive-compliance .au-co-second .block input {
      width: 100%;
      padding: 12px 15px;
      margin-bottom: 15px;
      border: 1px solid #a5a5a5;
      font-size: 16px;
      line-height: 1em;
      outline: 0;
      box-sizing: border-box;
      appearance: none; }
    .automotive-compliance .au-co-second .block select {
      width: 100%;
      padding: 13px 15px;
      margin-bottom: 15px;
      border: 1px solid #a5a5a5;
      border-radius: 0;
      font-size: 16px;
      line-height: 1em;
      background: #fff url(../img/select_bg.svg) no-repeat;
      background-position: calc(100% - 15px) center;
      outline: 0;
      box-sizing: border-box;
      resize: none;
      appearance: none; }
  .automotive-compliance .au-co-second hr {
    display: block;
    height: 1px;
    margin-bottom: 15px;
    border: 0;
    background: #e6e6e6; }
  .automotive-compliance .au-co-second .button--red {
    display: block;
    width: 355px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
    font-size: 18px; }
    @media (max-width: 500px) {
      .automotive-compliance .au-co-second .button--red {
        max-width: 100%; } }

.automotive-compliance .au-co-inner {
  display: flex;
  width: 960px;
  max-width: calc(100% - 30px);
  margin: 61px auto 144px; }
  @media (max-width: 1000px) {
    .automotive-compliance .au-co-inner {
      flex-direction: column;
      margin: 30px auto 40px; } }

.automotive-compliance .au-co-sidebar {
  width: 303px;
  max-width: 100%;
  margin-right: 45px; }
  @media (max-width: 1000px) {
    .automotive-compliance .au-co-sidebar {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; } }

.automotive-compliance .au-co-s-title {
  font-family: "Vauxhall Neue", sans-serif;
  font-size: 18px;
  line-height: 33px;
  font-weight: bold;
  color: #2c2c2c; }

.automotive-compliance .au-co-s-info {
  border: 1px solid #e6e6e6;
  box-sizing: border-box; }
  .automotive-compliance .au-co-s-info__item {
    padding: 13px 9px 17px; }
    @media (max-width: 1000px) {
      .automotive-compliance .au-co-s-info__item {
        padding: 10px 8px 10px; } }
    .automotive-compliance .au-co-s-info__item:nth-child(2n-1) {
      background-color: #f7f7f7; }
    .automotive-compliance .au-co-s-info__item--two-cols {
      display: flex; }
      @media (max-width: 500px) {
        .automotive-compliance .au-co-s-info__item--two-cols {
          display: block; } }
      .automotive-compliance .au-co-s-info__item--two-cols .col {
        width: 50%; }
        @media (max-width: 500px) {
          .automotive-compliance .au-co-s-info__item--two-cols .col {
            width: 100%; } }
        @media (max-width: 500px) {
          .automotive-compliance .au-co-s-info__item--two-cols .col:not(:last-child) {
            margin-bottom: 10px; } }
    .automotive-compliance .au-co-s-info__item .name {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 13px;
      line-height: 1.3;
      font-weight: 400;
      color: #666666; }
    .automotive-compliance .au-co-s-info__item .value {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 1.3;
      font-weight: 400;
      color: #2c2c2c; }
    .automotive-compliance .au-co-s-info__item .red {
      color: #ce171f; }
    .automotive-compliance .au-co-s-info__item .underline {
      text-decoration: underline; }
    .automotive-compliance .au-co-s-info__item .ttu {
      text-transform: uppercase; }

.automotive-compliance .au-co-content {
  display: block;
  width: calc(100% - 45px - 303px); }
  @media (max-width: 1000px) {
    .automotive-compliance .au-co-content {
      display: block;
      width: 100%; } }
  .automotive-compliance .au-co-content .au-co-block {
    width: 100%; }
    .automotive-compliance .au-co-content .au-co-block:not(:first-child) {
      padding-top: 19px; }
      @media (max-width: 700px) {
        .automotive-compliance .au-co-content .au-co-block:not(:first-child) {
          padding-top: 10px; } }
    .automotive-compliance .au-co-content .au-co-block:not(:last-child) {
      padding-bottom: 25px;
      border-bottom: 1px solid #e6e6e6; }
      @media (max-width: 700px) {
        .automotive-compliance .au-co-content .au-co-block:not(:last-child) {
          padding-bottom: 8px; } }
    .automotive-compliance .au-co-content .au-co-block__title {
      margin-top: 14px;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 20px;
      line-height: 1.3;
      font-weight: bold;
      color: #2c2c2c; }
      @media (max-width: 700px) {
        .automotive-compliance .au-co-content .au-co-block__title {
          font-size: 18px;
          margin-bottom: 10px; } }

.automotive-compliance .au-co-block__button, .automotive-compliance .au-co-next {
  display: block;
  width: 170px;
  height: 35px;
  margin-top: 6px;
  font-size: 15px;
  line-height: 35px; }

.automotive-compliance .au-co-next {
  width: 97px;
  margin-top: 20px; }
  .automotive-compliance .au-co-next.inb {
    display: inline-block;
    vertical-align: top;
    margin-right: 6px; }
  .automotive-compliance .au-co-next__gray {
    background: #333333; }

.automotive-compliance .btn-ecd {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 216px;
  max-width: 100%;
  height: 35px;
  padding: 11px 0 12px;
  background-color: #d1252c;
  font-family: "Vauxhall Neue", sans-serif;
  font-size: 15px;
  line-height: 1.3;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  box-sizing: border-box; }
  .automotive-compliance .btn-ecd::after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url("../img/controls/arrow.svg") no-repeat center center;
    background-size: contain;
    transform: rotate(90deg); }

.automotive-compliance .btns-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px; }

.automotive-compliance .btn-after-pic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px 5px;
  font-family: "Vauxhall Neue", sans-serif;
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer; }
  @media (min-width: 1024px) {
    .automotive-compliance .btn-after-pic:hover {
      opacity: 0.8; } }
  .automotive-compliance .btn-after-pic::after {
    content: '';
    display: block;
    margin-left: 10px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain; }
  .automotive-compliance .btn-after-pic--sos {
    width: 178px;
    height: 35px;
    background-color: #d1252c; }
    .automotive-compliance .btn-after-pic--sos::after {
      width: 19px;
      height: 17px;
      background-image: url("../img/desktop.png"); }
  .automotive-compliance .btn-after-pic--mail {
    width: 167px;
    height: 35px;
    background-color: #124390; }
    .automotive-compliance .btn-after-pic--mail::after {
      width: 17px;
      height: 13px;
      background-image: url("../img/mail.png"); }

.automotive-compliance .au-co-s-question-number {
  margin-bottom: 2px;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 400;
  color: #999999; }

.automotive-compliance .au-co-s-question-title {
  font-size: 18px;
  line-height: 1.3;
  font-weight: 400;
  color: #2c2c2c; }
  @media (max-width: 700px) {
    .automotive-compliance .au-co-s-question-title {
      font-size: 14px; } }

.automotive-compliance .au-co-s-radio-group {
  margin-top: 6px; }
  .automotive-compliance .au-co-s-radio-group .aucos-radio-item {
    display: block;
    width: 100%;
    margin-bottom: 0; }
    .automotive-compliance .au-co-s-radio-group .aucos-radio-item span {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 9px;
      padding-left: 39px;
      transition: background-color .2s;
      font-size: 14px;
      line-height: 1.3;
      font-weight: 400;
      color: #2c2c2c;
      box-sizing: border-box;
      position: relative; }
      @media (min-width: 1024px) {
        .automotive-compliance .au-co-s-radio-group .aucos-radio-item span:hover {
          background-color: #eef6fb; } }
      .automotive-compliance .au-co-s-radio-group .aucos-radio-item span::before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid #a5a5a5;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 9px;
        transform: translateY(-50%); }
      .automotive-compliance .au-co-s-radio-group .aucos-radio-item span::after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #124390;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        transition: opacity 0.3s ease; }
    .automotive-compliance .au-co-s-radio-group .aucos-radio-item input {
      display: none; }
      .automotive-compliance .au-co-s-radio-group .aucos-radio-item input:checked + span {
        background-color: #e0f2fe; }
        .automotive-compliance .au-co-s-radio-group .aucos-radio-item input:checked + span::after {
          opacity: 1; }

.automotive-compliance .au-co-text-inp {
  width: 100%;
  height: 32px;
  margin-top: 6px;
  padding-left: 10px;
  border: 0;
  background-color: #e0f2fe;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #2c2c2c;
  outline: 0;
  box-sizing: border-box; }
  .automotive-compliance .au-co-text-inp.not {
    max-width: 280px;
    background: transparent;
    border: 1px solid #a5a5a5; }

.automotive-compliance .au-co-form-bottom-btns {
  margin: 40px -8px 0; }
  @media (max-width: 700px) {
    .automotive-compliance .au-co-form-bottom-btns {
      justify-content: center; } }
  .automotive-compliance .au-co-form-bottom-btns > * {
    margin: 0 8px 8px;
    cursor: pointer; }
    @media (min-width: 1024px) {
      .automotive-compliance .au-co-form-bottom-btns > *:hover {
        opacity: 0.8; } }
  .automotive-compliance .au-co-form-bottom-btns .au-co-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 129px;
    height: 35px;
    background-color: #d1252c;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 15px;
    line-height: 28px;
    font-weight: 400;
    color: #ffffff; }
  .automotive-compliance .au-co-form-bottom-btns .au-co-cds-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #d1252c;
    text-decoration: underline; }

.body-shop-diary .bsd-table {
  display: flex;
  align-items: flex-start;
  width: 1620px;
  max-width: calc(100% - 40px);
  margin: 0 auto;
  margin-bottom: 30px; }
  .body-shop-diary .bsd-table--bodyshop-diary .bsd-table-right-wrap {
    max-width: calc(100% - 113px); }
    @media (max-width: 600px) {
      .body-shop-diary .bsd-table--bodyshop-diary .bsd-table-right-wrap {
        max-width: calc(100% - 100px); } }
  .body-shop-diary .bsd-table--bodyshop-diary .bsd-table-cell--left {
    width: 113px; }
    @media (max-width: 600px) {
      .body-shop-diary .bsd-table--bodyshop-diary .bsd-table-cell--left {
        width: 100px; } }
  .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-right-wrap {
    max-width: calc(100% - 402px); }
    @media (max-width: 600px) {
      .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-right-wrap {
        max-width: calc(100% - 100px); } }
  @media (max-width: 600px) {
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-cell--left {
      width: 100px; } }
  .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left {
    flex-grow: 1;
    text-align: left; }
    @media (max-width: 600px) {
      .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left {
        overflow: hidden;
        overflow-x: scroll; } }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-cell--left {
      justify-content: flex-start; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(1) {
      width: 100%; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(1) .bsd-table-cell--left {
      width: 100%; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(2) .bsd-table-cell--left {
      width: 100px; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(3) .bsd-table-cell--left {
      width: 66px; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(4) .bsd-table-cell--left {
      width: 101px; }
    .body-shop-diary .bsd-table--courtsecy-cars-booking .bsd-table-left .bsd-table-col:nth-child(2n) {
      background-color: #f7f7f7; }

.body-shop-diary .bsd-table-left {
  display: flex; }

.body-shop-diary .bsd-table-right {
  display: flex; }
  .body-shop-diary .bsd-table-right .bsd-table-col:last-child .bsd-table-cell:first-child {
    border-right: 1px solid #ce171f; }

.body-shop-diary .bsd-table-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 51px;
  min-width: 51px;
  height: 51px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-top: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #2c2c2c;
  text-align: center;
  box-sizing: border-box; }
  .body-shop-diary .bsd-table-cell--yellow {
    background-color: #fff5c5; }
  .body-shop-diary .bsd-table-cell--orange {
    background-color: #ffbd7d; }
  .body-shop-diary .bsd-table-cell--red {
    background-color: #ff4c4c; }
  .body-shop-diary .bsd-table-cell--left:first-child {
    background-color: #ce171f;
    border-left: 1px solid #ce171f;
    color: #ffffff; }
  .body-shop-diary .bsd-table-cell--clickable {
    cursor: pointer;
    transition-duration: .2s; }
    @media (min-width: 1024px) {
      .body-shop-diary .bsd-table-cell--clickable:hover {
        opacity: 0.7; } }
  .body-shop-diary .bsd-table-cell:first-child {
    border-top: 1px solid #ce171f;
    border-bottom: 1px solid #ce171f; }

.body-shop-diary .ccb-btns-row {
  display: flex;
  flex-wrap: wrap;
  width: 1620px;
  max-width: calc(100% - 40px);
  margin: 50px auto 10px; }
  .body-shop-diary .ccb-btns-row .btn {
    width: auto;
    padding-left: 12px;
    padding-right: 18px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    user-select: none; }
    @media (max-width: 450px) {
      .body-shop-diary .ccb-btns-row .btn {
        width: 100%;
        box-sizing: border-box; } }
    .body-shop-diary .ccb-btns-row .btn img {
      margin-right: 8px;
      margin-top: 2px; }

.body-shop-diary .datepickers-container {
  z-index: 9999999 !important; }

.body-shop-diary #js-delete-user .btns-row img {
  vertical-align: middle;
  margin-right: 10px; }

.body-shop-diary #js-delete-user .btns-row .btn {
  width: 179px;
  margin: 0 6px; }

.body-shop-diary .mCS-my-theme1.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  border-radius: 2px;
  border: 1px solid #cccccc;
  background-color: #ffffff; }

.body-shop-diary .mCS-my-theme1.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  top: -2px; }

.body-shop-diary .mCS-my-theme1.mCSB_scrollTools .mCSB_draggerRail {
  height: 9px;
  border-radius: 0;
  background-color: #cccccc; }

.body-shop-diary .mCS-my-theme1.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 31px; }

.body-shop-diary .mCS-my-theme1.mCSB_scrollTools {
  opacity: 1; }

.valuation-requests .content {
  width: calc(100% - 60px);
  max-width: 1320px;
  margin: 0 auto;
  padding-bottom: 100px; }
  @media (max-width: 1020px) {
    .valuation-requests .content {
      width: 100%;
      max-width: calc(100% - 60px); } }
  @media (max-width: 700px) {
    .valuation-requests .content {
      width: 100%;
      max-width: calc(100% - 60px); } }
  @media (max-width: 650px) {
    .valuation-requests .content {
      width: 100%;
      max-width: calc(100% - 60px);
      padding-bottom: 50px; } }
  .valuation-requests .content .flex-table {
    width: 100%;
    max-width: 100%;
    margin-top: 52px; }
    .valuation-requests .content .flex-table .row {
      display: flex;
      align-items: center;
      height: 46px;
      font-size: 16px;
      line-height: 23px; }
      @media (max-width: 700px) {
        .valuation-requests .content .flex-table .row {
          height: 38px;
          line-height: 22px; } }
      @media (max-width: 650px) {
        .valuation-requests .content .flex-table .row {
          display: flex;
          flex-direction: column;
          height: unset;
          margin-bottom: 35px;
          background-color: #f2f2f2;
          line-height: 22px; } }
      .valuation-requests .content .flex-table .row:nth-child(even) {
        background-color: #f2f2f2; }
      @media (max-width: 1020px) {
        .valuation-requests .content .flex-table .row .field {
          font-size: 14px; } }
      @media (max-width: 700px) {
        .valuation-requests .content .flex-table .row .field {
          font-size: 12px; } }
      @media (max-width: 650px) {
        .valuation-requests .content .flex-table .row .field {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: unset;
          font-size: 16px;
          line-height: 22px; } }
      @media (max-width: 650px) {
        .valuation-requests .content .flex-table .row .field:not(:last-child) {
          border-bottom: 1px solid #999999; } }
      .valuation-requests .content .flex-table .row .field.date {
        width: calc(22.727% - 41px);
        padding-left: 41px; }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row .field.date {
            width: calc(22.727% - 18px);
            padding-left: 18px; } }
      .valuation-requests .content .flex-table .row .field.time {
        width: 17.272%; }
        @media (max-width: 1020px) {
          .valuation-requests .content .flex-table .row .field.time {
            width: 9.272%; } }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row .field.time {
            width: 11.272%; } }
      .valuation-requests .content .flex-table .row .field.model {
        width: 31.06%; }
        @media (max-width: 1020px) {
          .valuation-requests .content .flex-table .row .field.model {
            width: 35.06%; } }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row .field.model {
            width: 36.06%; } }
      .valuation-requests .content .flex-table .row .field.status {
        width: 19.924%; }
        @media (max-width: 1020px) {
          .valuation-requests .content .flex-table .row .field.status {
            width: 19.924%; } }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row .field.status {
            width: 16.924%; } }
      .valuation-requests .content .flex-table .row .field.open {
        width: 9.015%;
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 16px;
        line-height: 23px;
        font-weight: 400;
        color: #2c2c2c; }
        @media (max-width: 1020px) {
          .valuation-requests .content .flex-table .row .field.open {
            width: 13.015%; } }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row .field.open {
            width: 13.015%; } }
        @media (max-width: 650px) {
          .valuation-requests .content .flex-table .row .field.open {
            justify-content: center; } }
        .valuation-requests .content .flex-table .row .field.open a {
          display: flex;
          align-items: center;
          font-family: "Vauxhall Neue", sans-serif;
          font-size: 16px;
          line-height: 46px;
          font-weight: 400;
          color: #2c2c2c;
          text-decoration: underline;
          transition-duration: 0.2s; }
          @media (max-width: 1020px) {
            .valuation-requests .content .flex-table .row .field.open a {
              font-size: 14px; } }
          @media (max-width: 700px) {
            .valuation-requests .content .flex-table .row .field.open a {
              font-size: 12px; } }
          @media (max-width: 650px) {
            .valuation-requests .content .flex-table .row .field.open a {
              font-size: 19px; } }
          @media (min-width: 1024px) {
            .valuation-requests .content .flex-table .row .field.open a:hover {
              filter: opacity(0.5); } }
          .valuation-requests .content .flex-table .row .field.open a img {
            margin-left: 6px; }
      @media (max-width: 650px) {
        .valuation-requests .content .flex-table .row .field.date, .valuation-requests .content .flex-table .row .field.time, .valuation-requests .content .flex-table .row .field.model, .valuation-requests .content .flex-table .row .field.status, .valuation-requests .content .flex-table .row .field.open {
          width: calc(100% - 30px);
          padding: 10px 0;
          margin: 0 15px; } }
      @media (max-width: 650px) {
        .valuation-requests .content .flex-table .row .field .bold {
          font-weight: 600;
          margin-right: 15px; } }
      .valuation-requests .content .flex-table .row .completed {
        color: #999999; }
      .valuation-requests .content .flex-table .row.headline {
        font-family: "Vauxhall Neue", sans-serif;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #2c2c2c; }
        @media (max-width: 1020px) {
          .valuation-requests .content .flex-table .row.headline {
            font-size: 16px; } }
        @media (max-width: 700px) {
          .valuation-requests .content .flex-table .row.headline {
            font-size: 14px; } }
        @media (max-width: 650px) {
          .valuation-requests .content .flex-table .row.headline {
            display: none; } }
  .valuation-requests .content .bottom {
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 650px) {
      .valuation-requests .content .bottom {
        justify-content: center;
        margin-top: -10px; } }
    .valuation-requests .content .bottom .comeback {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 160px;
      height: 36px;
      margin-top: 38px;
      border: 1px solid #58585a;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      color: #2c2c2c;
      text-transform: uppercase;
      transition-duration: 0.2s; }
      @media (min-width: 1024px) {
        .valuation-requests .content .bottom .comeback:hover {
          background: #2c2c2c;
          color: #fff; } }

.body-shop-calculation .table .row {
  display: flex;
  padding: 12px 14px 12px 6px; }
  .body-shop-calculation .table .row:nth-child(odd) {
    background-color: #f2f2f2; }

.body-shop-calculation .flex-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1158px;
  margin: 0 auto;
  margin-top: 50px; }
  @media (max-width: 1200px) {
    .body-shop-calculation .flex-block {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: calc(100% - 30px);
      margin: 0 auto;
      margin-top: 33px; } }
  .body-shop-calculation .flex-block > .left {
    width: 300px; }
    @media (max-width: 1200px) {
      .body-shop-calculation .flex-block > .left {
        width: 100%; } }
    .body-shop-calculation .flex-block > .left .button-block {
      margin-top: 22px; }
      .body-shop-calculation .flex-block > .left .button-block .button--red {
        display: block;
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 35px;
        font-weight: 400;
        text-align: center; }
      .body-shop-calculation .flex-block > .left .button-block .button--kma-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 35px;
        background-color: #124390;
        border-radius: 5px;
        font-size: 15px;
        line-height: 35px;
        font-weight: 400;
        text-align: center;
        text-transform: none; }
        .body-shop-calculation .flex-block > .left .button-block .button--kma-blue:before {
          content: '';
          display: block;
          width: 17px;
          height: 15px;
          margin-right: 3px;
          background-image: url("../img/customer.svg");
          background-size: contain; }
        @media (min-width: 1024px) {
          .body-shop-calculation .flex-block > .left .button-block .button--kma-blue:hover {
            background-color: #58585a; } }
    .body-shop-calculation .flex-block > .left > .title {
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 33px;
      font-weight: bold;
      letter-spacing: 0.3px; }
    .body-shop-calculation .flex-block > .left .block {
      margin-bottom: 19px;
      border: 1px solid #E6E6E6; }
      .body-shop-calculation .flex-block > .left .block > .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .body-shop-calculation .flex-block > .left .block .table-vehicle {
        margin-top: 1px; }
        .body-shop-calculation .flex-block > .left .block .table-vehicle .row {
          justify-content: space-between; }
          .body-shop-calculation .flex-block > .left .block .table-vehicle .row .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .body-shop-calculation .flex-block > .left .block .table-vehicle .row .left .type {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 13px;
              line-height: 10px;
              font-weight: 400;
              color: #757575; }
            .body-shop-calculation .flex-block > .left .block .table-vehicle .row .left .name {
              line-height: 18px;
              text-decoration: underline; }
          .body-shop-calculation .flex-block > .left .block .table-vehicle .row .right {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 32px; }
            @media (max-width: 1200px) {
              .body-shop-calculation .flex-block > .left .block .table-vehicle .row .right {
                justify-content: flex-start; } }
            .body-shop-calculation .flex-block > .left .block .table-vehicle .row .right .price {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 16px;
              font-weight: bold;
              color: #2c2c2c; }
      .body-shop-calculation .flex-block > .left .block .table-trade .row .left {
        width: 100%; }
      .body-shop-calculation .flex-block > .left .block .table-trade .row .parameter {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #757575; }
      .body-shop-calculation .flex-block > .left .block .table-trade .row .value {
        font-size: 16px;
        line-height: 17px; }
        .body-shop-calculation .flex-block > .left .block .table-trade .row .value span:nth-child(1) {
          margin-right: 8px; }
        .body-shop-calculation .flex-block > .left .block .table-trade .row .value.description {
          font-size: 14px;
          line-height: 24px; }
        .body-shop-calculation .flex-block > .left .block .table-trade .row .value.active {
          color: #ce171f;
          text-decoration: underline; }
        .body-shop-calculation .flex-block > .left .block .table-trade .row .value .images-block {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 6px; }
          .body-shop-calculation .flex-block > .left .block .table-trade .row .value .images-block > div {
            width: 49%;
            height: 102px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-bottom: 8px; }
            .body-shop-calculation .flex-block > .left .block .table-trade .row .value .images-block > div img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
      .body-shop-calculation .flex-block > .left .block .table-trade .row.summary .left {
        width: 100%; }
      .body-shop-calculation .flex-block > .left .block .table-other .left {
        flex-grow: 1; }
  .body-shop-calculation .flex-block > .right {
    width: calc(100% - 344px);
    padding-right: 12px;
    margin-left: 15px;
    box-sizing: border-box; }
    @media (max-width: 1200px) {
      .body-shop-calculation .flex-block > .right {
        width: 100%;
        margin-left: 0; } }
    @media (max-width: 760px) {
      .body-shop-calculation .flex-block > .right {
        padding-right: 0; } }
    .body-shop-calculation .flex-block > .right .section {
      margin-bottom: 23px;
      padding-bottom: 20px; }
      @media (max-width: 550px) {
        .body-shop-calculation .flex-block > .right .section .table-si {
          overflow-x: auto; }
          .body-shop-calculation .flex-block > .right .section .table-si .row {
            width: fit-content; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:not(:last-child) {
              margin-right: 0;
              padding-right: 10px; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:nth-child(1) {
              min-width: 150px; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:nth-child(2) {
              min-width: 100px; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:nth-child(3) {
              min-width: 100px; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:nth-child(4) {
              min-width: 100px; }
            .body-shop-calculation .flex-block > .right .section .table-si .row .col:nth-child(5) {
              min-width: 100px; } }
      .body-shop-calculation .flex-block > .right .section:nth-child(1) {
        padding-bottom: 0; }
        @media (max-width: 810px) {
          .body-shop-calculation .flex-block > .right .section:nth-child(1) {
            overflow: auto; } }
      .body-shop-calculation .flex-block > .right .section:nth-child(2) {
        margin-bottom: 30px;
        padding-bottom: 30px; }
      .body-shop-calculation .flex-block > .right .section:nth-child(3) {
        margin-bottom: 34px;
        padding-bottom: 30px; }
      .body-shop-calculation .flex-block > .right .section:not(:last-child) {
        border-bottom: 1px solid #e6e6e6; }
      .body-shop-calculation .flex-block > .right .section .table-list-el-module {
        width: 99%;
        min-width: 768px;
        margin-bottom: 5px; }
        @media (max-width: 810px) {
          .body-shop-calculation .flex-block > .right .section .table-list-el-module {
            overflow-x: scroll; } }
        @media (max-width: 810px) {
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr {
            display: table-row; } }
        .body-shop-calculation .flex-block > .right .section .table-list-el-module tr:nth-child(even) {
          background: #f2f2f2; }
        .body-shop-calculation .flex-block > .right .section .table-list-el-module tr.add-new-row span {
          font-size: 16px; }
        .body-shop-calculation .flex-block > .right .section .table-list-el-module tr th {
          padding: 13px 8px;
          padding-top: 2px;
          font-size: 16px;
          text-align: left;
          letter-spacing: 0.5px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr th:nth-child(3) {
            padding-left: 20px; }
        .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td {
          height: 64px;
          padding: 16px 11px;
          vertical-align: middle;
          box-sizing: border-box; }
          @media (max-width: 810px) {
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td {
              width: auto !important;
              padding: 16px 11px !important; } }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(1) {
            width: 213px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(2) {
            width: 133px;
            padding: 0; }
            @media (max-width: 810px) {
              .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(2) {
                display: table-cell !important;
                padding: 16px 11px !important; } }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(3) {
            width: 70px;
            padding-left: 20px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(4) {
            padding: 0;
            padding-left: 10px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(5) {
            width: 70px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(6) {
            padding-left: 7px; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(7) {
            padding-right: 0;
            padding-left: 20px; }
            @media (max-width: 810px) {
              .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(7) {
                padding-right: 5px;
                padding-left: 5px; } }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td a.button--red {
            display: block;
            width: 133px;
            height: 30px;
            font-size: 13px;
            line-height: 30px;
            font-weight: 400;
            text-align: center; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 156px;
            height: 30px;
            background-color: #124390;
            border-radius: 5px;
            font-size: 13px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;
            text-transform: none;
            box-sizing: border-box; }
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue:before {
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              margin-right: 12px;
              background: url(../img/white-plus.png) no-repeat center center;
              background-size: contain; }
            @media (min-width: 1024px) {
              .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue:hover {
                background-color: #58585a; } }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td select {
            width: 62px;
            padding: 8px 15px 7px 12px;
            border: 1px solid #a5a5a5;
            border-radius: 3px;
            outline: 0;
            background: #fff url(../img/select_hov.svg) no-repeat;
            background-position: calc(100% - 15px) center;
            font-size: 14px;
            line-height: 1em;
            box-sizing: border-box;
            appearance: none;
            resize: none; }
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td select::-ms-expand {
              display: none; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td input {
            border-radius: 3px; }
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td input.detail {
              width: 200px;
              padding: 6px 11px;
              margin-bottom: 0;
              border: 1px solid #a5a5a5;
              outline: 0;
              font-size: 14px;
              line-height: 1em;
              appearance: none;
              box-sizing: border-box; }
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td input.hours {
              width: 60px;
              padding: 6px 10px;
              margin-bottom: 0;
              border: 1px solid #a5a5a5;
              outline: 0;
              font-size: 14px;
              line-height: 1em;
              appearance: none;
              box-sizing: border-box; }
            .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td input.price {
              width: 70px;
              padding: 6px 9px;
              margin-bottom: 0;
              border: 1px solid #a5a5a5;
              outline: 0;
              font-size: 14px;
              line-height: 1em;
              appearance: none;
              box-sizing: border-box; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td span.bold {
            font-weight: bold; }
          .body-shop-calculation .flex-block > .right .section .table-list-el-module tr td .close-button {
            display: block;
            width: 13px;
            height: 13px;
            background: url(../img/red_x.svg) no-repeat;
            background-size: 100% auto; }
      .body-shop-calculation .flex-block > .right .section > .title {
        font-size: 20px;
        line-height: 33px;
        font-weight: 400;
        letter-spacing: 1px; }
      .body-shop-calculation .flex-block > .right .section .offered-price label {
        font-size: 16px; }
      .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px; }
        @media (max-width: 550px) {
          .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap {
            flex-wrap: wrap; } }
        .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap input {
          width: 362px;
          height: 44px;
          padding-left: 19px;
          border: 1px solid #a5a5a5;
          background-color: #ffffff;
          font-size: 16px; }
          @media (max-width: 550px) {
            .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap input {
              width: 100%;
              padding-left: 10px; } }
        .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap span {
          margin-left: 29px; }
          @media (max-width: 550px) {
            .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap span {
              margin-top: 10px; } }
          .body-shop-calculation .flex-block > .right .section .offered-price .flex-wrap span b {
            font-size: 18px; }
      .body-shop-calculation .flex-block > .right .section .offered-price .price {
        margin-top: 28px;
        font-size: 18px; }
        .body-shop-calculation .flex-block > .right .section .offered-price .price .red {
          margin-left: 11px;
          font-size: 24px;
          font-weight: bold;
          color: #ce171f; }
      .body-shop-calculation .flex-block > .right .section .table-added {
        margin-top: 15px; }
        .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox] {
          display: none; }
        .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox] + label::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          margin: -5px 5px 0 0;
          border: 1px solid #a9a9a9;
          background-color: #ffffff;
          line-height: 22px;
          text-align: center;
          vertical-align: middle;
          transition: color 0.3s ease; }
        .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox]:checked + label::before {
          background: url(../img/checked.svg) center; }
        .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label::before {
          border: 1px solid #d9d9d9; }
        .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span {
          margin-right: 10px; }
          .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span,
          .body-shop-calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span + span + span {
            color: #999999; }
        .body-shop-calculation .flex-block > .right .section .table-added .row label {
          cursor: pointer; }
          .body-shop-calculation .flex-block > .right .section .table-added .row label + span {
            flex-grow: 1; }
      .body-shop-calculation .flex-block > .right .section .modal-openers {
        display: flex;
        width: 348px;
        margin-top: 14px;
        margin-bottom: 25px; }
        @media (max-width: 550px) {
          .body-shop-calculation .flex-block > .right .section .modal-openers {
            display: block;
            width: 100%; } }
        .body-shop-calculation .flex-block > .right .section .modal-openers .btn-pcp {
          margin-right: 19px; }
          @media (max-width: 550px) {
            .body-shop-calculation .flex-block > .right .section .modal-openers .btn-pcp {
              margin-right: 0;
              margin-bottom: 10px;
              margin-left: 0; } }
        .body-shop-calculation .flex-block > .right .section .modal-openers .btn-pcp,
        .body-shop-calculation .flex-block > .right .section .modal-openers .btn-hp {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 165px;
          height: 44px;
          border: 1px solid #ce181f;
          font-size: 16px;
          font-weight: bold;
          color: #2c2c2c;
          box-sizing: border-box; }
          @media (min-width: 1024px) {
            .body-shop-calculation .flex-block > .right .section .modal-openers .btn-pcp:hover,
            .body-shop-calculation .flex-block > .right .section .modal-openers .btn-hp:hover {
              color: #ffffff;
              border: 0;
              background-color: #ce181f; } }
          @media (max-width: 550px) {
            .body-shop-calculation .flex-block > .right .section .modal-openers .btn-pcp,
            .body-shop-calculation .flex-block > .right .section .modal-openers .btn-hp {
              width: 100%; } }
      .body-shop-calculation .flex-block > .right .section .table-summary {
        margin-bottom: 31px;
        padding-bottom: 0;
        border-bottom: 1px solid #f2f2f2; }
        .body-shop-calculation .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(even) {
          background-color: unset; }
        .body-shop-calculation .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(odd) {
          background-color: #f2f2f2; }
        .body-shop-calculation .flex-block > .right .section .table-summary .row {
          align-items: center; }
          .body-shop-calculation .flex-block > .right .section .table-summary .row .col:not(:last-child) {
            margin-right: 10px; }
          .body-shop-calculation .flex-block > .right .section .table-summary .row .col:nth-child(1) {
            width: calc(55.692% - 10px); }
          .body-shop-calculation .flex-block > .right .section .table-summary .row .col:nth-child(2) {
            width: calc(24.005% - 10px); }
          .body-shop-calculation .flex-block > .right .section .table-summary .row .col:nth-child(3) {
            width: calc(20.301%); }
      .body-shop-calculation .flex-block > .right .section .input-block label {
        display: block;
        margin-bottom: 16px;
        font-size: 16px; }
      .body-shop-calculation .flex-block > .right .section .input-block textarea {
        width: 100%;
        height: 100px;
        padding: 12px 15px;
        border: 1px solid #a5a5a5;
        border-radius: 3px;
        outline: 0;
        font-size: 16px;
        line-height: 1em;
        box-sizing: border-box; }
      .body-shop-calculation .flex-block > .right .section.btns-section {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 200px; }
      .body-shop-calculation .flex-block > .right .section .save-button {
        display: block;
        width: 160px;
        height: 35px;
        margin-right: 30px;
        font-size: 13px;
        line-height: 35px; }
        .body-shop-calculation .flex-block > .right .section .save-button:before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-top: -3px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/save.svg) no-repeat; }
      .body-shop-calculation .flex-block > .right .section .back-button {
        display: block;
        width: 100px;
        height: 35px;
        background-color: #ffffff;
        border: 2px solid #000;
        font-size: 13px;
        line-height: 35px;
        color: #000;
        box-sizing: border-box; }
        @media (min-width: 1024px) {
          .body-shop-calculation .flex-block > .right .section .back-button:hover {
            color: #fff;
            background: black; } }
      .body-shop-calculation .flex-block > .right .section .wip-block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 21px; }
        .body-shop-calculation .flex-block > .right .section .wip-block span {
          font-size: 16px;
          font-weight: bold; }
        .body-shop-calculation .flex-block > .right .section .wip-block a {
          display: block;
          width: 133px;
          height: 30px;
          margin-left: 30px;
          font-size: 13px;
          line-height: 30px;
          font-weight: 400;
          text-align: center; }
      .body-shop-calculation .flex-block > .right .section .Due-block {
        display: flex;
        flex-wrap: wrap; }
        @media (max-width: 760px) {
          .body-shop-calculation .flex-block > .right .section .Due-block {
            flex-direction: column; } }
        .body-shop-calculation .flex-block > .right .section .Due-block .block label {
          display: block;
          margin-bottom: 3px;
          font-size: 15px; }
        .body-shop-calculation .flex-block > .right .section .Due-block .block > input {
          width: 194px;
          padding: 6px 11px;
          margin-bottom: 0;
          border: 1px solid #a5a5a5;
          border-radius: 3px;
          outline: 0;
          font-size: 14px;
          line-height: 1em;
          box-sizing: border-box; }
          @media (max-width: 760px) {
            .body-shop-calculation .flex-block > .right .section .Due-block .block > input {
              width: 100%; } }
        .body-shop-calculation .flex-block > .right .section .Due-block .block select {
          width: 194px;
          margin-bottom: 0;
          padding: 7px 11px;
          border: 1px solid #a5a5a5;
          border-radius: 3px;
          outline: 0;
          background: #fff url(../img/select_hov.svg) no-repeat;
          background-position: calc(100% - 15px) center;
          font-size: 14px;
          line-height: 1em;
          box-sizing: border-box;
          resize: none;
          appearance: none; }
          @media (max-width: 760px) {
            .body-shop-calculation .flex-block > .right .section .Due-block .block select {
              width: 100%; } }
          .body-shop-calculation .flex-block > .right .section .Due-block .block select::-ms-expand {
            display: none; }
        .body-shop-calculation .flex-block > .right .section .Due-block > * + * {
          margin-left: 8px; }
          @media (max-width: 760px) {
            .body-shop-calculation .flex-block > .right .section .Due-block > * + * {
              margin-top: 15px;
              margin-left: 0; } }
      .body-shop-calculation .flex-block > .right .section .status-block label {
        display: block;
        margin-bottom: 3px;
        font-size: 15px; }
      .body-shop-calculation .flex-block > .right .section .status-block select {
        width: 194px;
        padding: 7px 11px;
        margin-bottom: 0;
        border: 1px solid #a5a5a5;
        border-radius: 0;
        outline: 0;
        background: #fff url(../img/select_hov.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 14px;
        line-height: 1em;
        box-sizing: border-box;
        resize: none;
        appearance: none; }
      @media (max-width: 550px) {
        .body-shop-calculation .flex-block > .right .section .radiogroup--right .radiogroup__radio span {
          width: 100%; } }
    .body-shop-calculation .flex-block > .right .btn-block {
      display: flex;
      width: fit-content;
      padding-top: 20px;
      margin: 0 auto; }
      .body-shop-calculation .flex-block > .right .btn-block .cbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 267px;
        height: 46px;
        background-color: #124390;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #ffffff;
        transition-duration: 0.2s; }
        @media (min-width: 1024px) {
          .body-shop-calculation .flex-block > .right .btn-block .cbtn:hover {
            filter: brightness(1.1); } }
        .body-shop-calculation .flex-block > .right .btn-block .cbtn:nth-child(1) {
          margin-right: 24px; }

.body-shop-calculation .table-si .row {
  display: flex;
  padding: 12px 14px 12px 9px; }
  .body-shop-calculation .table-si .row:nth-child(even) {
    background-color: #f2f2f2; }
  .body-shop-calculation .table-si .row .col {
    font-size: 16px; }
    .body-shop-calculation .table-si .row .col:not(:last-child) {
      margin-right: 10px; }
    .body-shop-calculation .table-si .row .col:nth-child(1) {
      width: calc(33.821% - 10px);
      font-size: 18px;
      font-weight: bold; }
    .body-shop-calculation .table-si .row .col:nth-child(2) {
      width: calc(18.242% - 10px); }
    .body-shop-calculation .table-si .row .col:nth-child(3) {
      width: calc(18.375% - 10px); }
    .body-shop-calculation .table-si .row .col:nth-child(4) {
      width: calc(15.446% - 10px); }
    .body-shop-calculation .table-si .row .col:nth-child(5) {
      width: calc(14.114%); }

.body-shop-calculation .table-si .row.headline .col {
  font-weight: 400; }

@media (max-width: 760px) {
  .body-shop-calculation .table-si.table-programm {
    overflow-x: scroll; } }

.body-shop-calculation .table-si.table-programm .row {
  align-items: center;
  min-height: 65px -24px; }
  @media (max-width: 760px) {
    .body-shop-calculation .table-si.table-programm .row {
      width: fit-content; } }
  .body-shop-calculation .table-si.table-programm .row .col:not(:last-child) {
    margin-right: 10px; }
  .body-shop-calculation .table-si.table-programm .row .col:nth-child(1) {
    width: 43px;
    font-size: 18px;
    font-weight: bold; }
    @media (max-width: 760px) {
      .body-shop-calculation .table-si.table-programm .row .col:nth-child(1) {
        width: 30px;
        min-width: 30px; } }
  .body-shop-calculation .table-si.table-programm .row .col:nth-child(2) {
    width: calc(11.675% - 10px - 14.333px); }
    @media (max-width: 760px) {
      .body-shop-calculation .table-si.table-programm .row .col:nth-child(2) {
        width: 50px;
        min-width: 50px; } }
  .body-shop-calculation .table-si.table-programm .row .col:nth-child(3) {
    width: calc(47.664% - 10px - 14.333px); }
    @media (max-width: 760px) {
      .body-shop-calculation .table-si.table-programm .row .col:nth-child(3) {
        width: 150px;
        min-width: 150px; } }
  .body-shop-calculation .table-si.table-programm .row .col:nth-child(4) {
    width: calc(35.439% - 10px - 14.333px); }
    @media (max-width: 760px) {
      .body-shop-calculation .table-si.table-programm .row .col:nth-child(4) {
        width: 150px;
        min-width: 150px; } }
  .body-shop-calculation .table-si.table-programm .row .col input {
    display: none; }
    .body-shop-calculation .table-si.table-programm .row .col input + label {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #a5a5a5;
      border-radius: 50%;
      background-color: #ffffff;
      cursor: pointer;
      position: relative; }
      .body-shop-calculation .table-si.table-programm .row .col input + label::before {
        content: '';
        width: 10px;
        height: 10px;
        opacity: 0;
        border-radius: 50%;
        background-color: #124390;
        position: absolute;
        top: 4px;
        left: 4px;
        transition-duration: 0.2s; }
    .body-shop-calculation .table-si.table-programm .row .col input:checked + label::before {
      opacity: 1; }

.calculation .table .row {
  display: flex;
  padding: 12px 14px 12px 6px; }
  .calculation .table .row:nth-child(odd) {
    background-color: #f2f2f2; }

.calculation .flex-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1158px;
  margin: 0 auto;
  margin-top: 50px; }
  @media (max-width: 1200px) {
    .calculation .flex-block {
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      max-width: calc(100% - 30px);
      margin: 0 auto;
      margin-top: 33px; } }
  .calculation .flex-block > .left {
    width: 300px; }
    @media (max-width: 1200px) {
      .calculation .flex-block > .left {
        width: 100%; } }
    .calculation .flex-block > .left .button-block {
      margin-top: 22px; }
      .calculation .flex-block > .left .button-block .button--red {
        display: block;
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 35px;
        font-weight: 400;
        text-align: center; }
      .calculation .flex-block > .left .button-block .button--kma-blue {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 35px;
        background-color: #124390;
        font-size: 15px;
        line-height: 35px;
        font-weight: 400;
        text-align: center;
        text-transform: none; }
        .calculation .flex-block > .left .button-block .button--kma-blue:before {
          content: '';
          width: 17px;
          height: 15px;
          margin-right: 3px;
          background-image: url("../img/customer.svg"); }
        @media (min-width: 1024px) {
          .calculation .flex-block > .left .button-block .button--kma-blue:hover {
            background-color: #58585a; } }
    .calculation .flex-block > .left > .title {
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 33px;
      font-weight: bold;
      letter-spacing: 0.3px; }
    .calculation .flex-block > .left .block {
      margin-bottom: 19px;
      border: 1px solid #E6E6E6; }
      .calculation .flex-block > .left .block > .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .calculation .flex-block > .left .block .table-vehicle {
        margin-top: 1px; }
        .calculation .flex-block > .left .block .table-vehicle .row {
          justify-content: space-between; }
          .calculation .flex-block > .left .block .table-vehicle .row .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .calculation .flex-block > .left .block .table-vehicle .row .left .type {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 13px;
              line-height: 10px;
              font-weight: 400;
              color: #757575; }
            .calculation .flex-block > .left .block .table-vehicle .row .left .name {
              line-height: 18px;
              text-decoration: underline; }
          .calculation .flex-block > .left .block .table-vehicle .row .right {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 32px; }
            @media (max-width: 1200px) {
              .calculation .flex-block > .left .block .table-vehicle .row .right {
                justify-content: flex-start; } }
            .calculation .flex-block > .left .block .table-vehicle .row .right .price {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 16px;
              font-weight: bold;
              color: #2c2c2c; }
      .calculation .flex-block > .left .block .table-trade .row .left {
        width: 100%; }
      .calculation .flex-block > .left .block .table-trade .row .parameter {
        margin-bottom: 0;
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #757575; }
      .calculation .flex-block > .left .block .table-trade .row .value {
        font-size: 16px;
        line-height: 17px; }
        .calculation .flex-block > .left .block .table-trade .row .value span:nth-child(1) {
          margin-right: 8px; }
        .calculation .flex-block > .left .block .table-trade .row .value.description {
          font-size: 14px;
          line-height: 24px; }
        .calculation .flex-block > .left .block .table-trade .row .value.active {
          text-decoration: underline;
          color: #ce171f; }
        .calculation .flex-block > .left .block .table-trade .row .value .images-block {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 6px; }
          .calculation .flex-block > .left .block .table-trade .row .value .images-block > div {
            width: 49%;
            height: 102px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            margin-bottom: 8px; }
            .calculation .flex-block > .left .block .table-trade .row .value .images-block > div img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
      .calculation .flex-block > .left .block .table-trade .row.summary .left {
        width: 100%; }
      .calculation .flex-block > .left .block .table-other .left {
        flex-grow: 1; }
  .calculation .flex-block > .right {
    width: calc(100% - 344px);
    margin-left: 15px;
    padding-right: 12px;
    box-sizing: border-box; }
    @media (max-width: 1200px) {
      .calculation .flex-block > .right {
        width: 100%;
        margin-left: 0; } }
    @media (max-width: 760px) {
      .calculation .flex-block > .right {
        padding-right: 0; } }
    .calculation .flex-block > .right .btn-block {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      padding-top: 20px; }
      .calculation .flex-block > .right .btn-block .cbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 267px;
        height: 46px;
        background-color: #124390;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #ffffff;
        transition-duration: .2s; }
        .calculation .flex-block > .right .btn-block .cbtn:nth-child(1) {
          margin-right: 24px; }
        @media (min-width: 1024px) {
          .calculation .flex-block > .right .btn-block .cbtn:hover {
            filter: brightness(1.1); } }
    .calculation .flex-block > .right .section {
      margin-bottom: 23px;
      padding-bottom: 20px; }
      .calculation .flex-block > .right .section:nth-child(1) {
        padding-bottom: 0; }
        @media (max-width: 810px) {
          .calculation .flex-block > .right .section:nth-child(1) {
            overflow: auto; } }
      .calculation .flex-block > .right .section:nth-child(2) {
        margin-bottom: 30px;
        padding-bottom: 30px; }
      .calculation .flex-block > .right .section:nth-child(3) {
        margin-bottom: 34px;
        padding-bottom: 30px; }
      .calculation .flex-block > .right .section .table-list-el-module {
        width: 99%;
        min-width: 768px;
        margin-bottom: 5px; }
        @media (max-width: 810px) {
          .calculation .flex-block > .right .section .table-list-el-module {
            overflow-x: scroll; } }
        @media (max-width: 810px) {
          .calculation .flex-block > .right .section .table-list-el-module tr {
            display: table-row; } }
        .calculation .flex-block > .right .section .table-list-el-module tr:nth-child(even) {
          background: #f2f2f2; }
        .calculation .flex-block > .right .section .table-list-el-module tr.add-new-row span {
          font-size: 16px; }
        .calculation .flex-block > .right .section .table-list-el-module tr th {
          padding: 13px 8px;
          padding-top: 2px;
          font-size: 16px;
          text-align: left;
          letter-spacing: 0.5px; }
          .calculation .flex-block > .right .section .table-list-el-module tr th:nth-child(3) {
            padding-left: 20px; }
        .calculation .flex-block > .right .section .table-list-el-module tr td {
          height: 64px;
          padding: 16px 11px;
          vertical-align: middle;
          box-sizing: border-box; }
          @media (max-width: 810px) {
            .calculation .flex-block > .right .section .table-list-el-module tr td {
              width: auto !important;
              padding: 16px 11px !important; } }
          .calculation .flex-block > .right .section .table-list-el-module tr td a.button--red {
            display: block;
            width: 133px;
            height: 30px;
            font-size: 13px;
            line-height: 30px;
            font-weight: 400;
            text-align: center; }
          .calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 156px;
            height: 30px;
            background-color: #124390;
            font-size: 13px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;
            text-transform: none;
            box-sizing: border-box; }
            .calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue:before {
              content: '';
              display: block;
              width: 12px;
              height: 12px;
              margin-right: 12px;
              background: url(../img/white-plus.png) no-repeat center center;
              background-size: contain; }
            @media (min-width: 1024px) {
              .calculation .flex-block > .right .section .table-list-el-module tr td a.button--kma-blue:hover {
                background-color: #58585a; } }
          .calculation .flex-block > .right .section .table-list-el-module tr td select {
            width: 62px;
            padding: 8px 15px 7px 12px;
            border: 1px solid #a5a5a5;
            border-radius: 0;
            outline: 0;
            background: #fff url(../img/select_bg.svg) no-repeat;
            background-position: calc(100% - 10px) center;
            font-size: 14px;
            line-height: 1em;
            -webkit-appearance: none;
            resize: none;
            box-sizing: border-box; }
            .calculation .flex-block > .right .section .table-list-el-module tr td select::-ms-expand {
              display: none; }
          .calculation .flex-block > .right .section .table-list-el-module tr td input.detail {
            width: 200px;
            margin-bottom: 0;
            padding: 6px 11px;
            border: 1px solid #a5a5a5;
            outline: 0;
            font-size: 14px;
            line-height: 1em;
            -webkit-appearance: none;
            box-sizing: border-box; }
          .calculation .flex-block > .right .section .table-list-el-module tr td span.bold {
            font-weight: bold; }
          .calculation .flex-block > .right .section .table-list-el-module tr td input.hours {
            width: 60px;
            margin-bottom: 0;
            padding: 6px 10px;
            border: 1px solid #a5a5a5;
            outline: 0;
            font-size: 14px;
            line-height: 1em;
            -webkit-appearance: none;
            box-sizing: border-box; }
          .calculation .flex-block > .right .section .table-list-el-module tr td input.price {
            width: 70px;
            margin-bottom: 0;
            padding: 6px 9px;
            border: 1px solid #a5a5a5;
            outline: 0;
            font-size: 14px;
            line-height: 1em;
            -webkit-appearance: none;
            box-sizing: border-box; }
          .calculation .flex-block > .right .section .table-list-el-module tr td .close-button {
            display: block;
            width: 13px;
            height: 13px;
            background: url(../img/red_x.svg) no-repeat;
            background-size: 100% auto; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(1) {
            width: 213px; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(2) {
            width: 133px;
            padding: 0; }
            @media (max-width: 810px) {
              .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(2) {
                display: table-cell !important;
                padding: 16px 11px !important; } }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(3) {
            width: 70px;
            padding-left: 20px; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(4) {
            padding: 0;
            padding-left: 10px; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(5) {
            width: 70px; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(6) {
            padding-left: 7px; }
          .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(7) {
            padding-right: 0;
            padding-left: 20px; }
            @media (max-width: 810px) {
              .calculation .flex-block > .right .section .table-list-el-module tr td:nth-child(7) {
                padding-right: 5px;
                padding-left: 5px; } }
      @media (max-width: 550px) {
        .calculation .flex-block > .right .section .table-si {
          overflow-x: auto; } }
      @media (max-width: 550px) {
        .calculation .flex-block > .right .section .table-si .row {
          width: fit-content; }
          .calculation .flex-block > .right .section .table-si .row:not(:last-child) {
            margin-right: 0;
            padding-right: 10px; }
          .calculation .flex-block > .right .section .table-si .row:nth-child(1) {
            min-width: 150px; }
          .calculation .flex-block > .right .section .table-si .row:nth-child(2) {
            min-width: 100px; }
          .calculation .flex-block > .right .section .table-si .row:nth-child(3) {
            min-width: 100px; }
          .calculation .flex-block > .right .section .table-si .row:nth-child(4) {
            min-width: 100px; }
          .calculation .flex-block > .right .section .table-si .row:nth-child(5) {
            min-width: 100px; } }
      .calculation .flex-block > .right .section:not(:last-child) {
        border-bottom: 1px solid #e6e6e6; }
      .calculation .flex-block > .right .section > .title {
        font-size: 20px;
        line-height: 33px;
        font-weight: 400;
        letter-spacing: 1px; }
      .calculation .flex-block > .right .section .offered-price label {
        font-size: 16px; }
      .calculation .flex-block > .right .section .offered-price .flex-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px; }
        @media (max-width: 550px) {
          .calculation .flex-block > .right .section .offered-price .flex-wrap {
            flex-wrap: wrap; } }
        .calculation .flex-block > .right .section .offered-price .flex-wrap input {
          width: 362px;
          height: 44px;
          padding-left: 19px;
          border: 1px solid #a5a5a5;
          background-color: #ffffff;
          font-size: 16px; }
          @media (max-width: 550px) {
            .calculation .flex-block > .right .section .offered-price .flex-wrap input {
              width: 100%;
              padding-left: 10px; } }
        .calculation .flex-block > .right .section .offered-price .flex-wrap span {
          margin-left: 29px; }
          @media (max-width: 550px) {
            .calculation .flex-block > .right .section .offered-price .flex-wrap span {
              margin-top: 10px; } }
          .calculation .flex-block > .right .section .offered-price .flex-wrap span b {
            font-size: 18px; }
      .calculation .flex-block > .right .section .offered-price .price {
        margin-top: 28px;
        font-size: 18px; }
        .calculation .flex-block > .right .section .offered-price .price .red {
          margin-left: 11px;
          font-size: 24px;
          font-weight: bold;
          color: #ce171f; }
      .calculation .flex-block > .right .section .table-added {
        margin-top: 15px; }
        .calculation .flex-block > .right .section .table-added .row input[type=checkbox] {
          display: none; }
          .calculation .flex-block > .right .section .table-added .row input[type=checkbox] + label::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: -5px 5px 0 0;
            border: 1px solid #a9a9a9;
            background-color: #ffffff;
            line-height: 22px;
            text-align: center;
            vertical-align: middle;
            transition: color ease .3s; }
          .calculation .flex-block > .right .section .table-added .row input[type=checkbox]:checked + label::before {
            background: url(../img/checked.svg) center; }
          .calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label::before {
            border: 1px solid #d9d9d9; }
          .calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span {
            margin-right: 10px; }
            .calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span,
            .calculation .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span + span + span {
              color: #999999; }
        .calculation .flex-block > .right .section .table-added .row label {
          cursor: pointer; }
          .calculation .flex-block > .right .section .table-added .row label + span {
            flex-grow: 1; }
      .calculation .flex-block > .right .section .modal-openers {
        display: flex;
        width: 348px;
        margin-top: 14px;
        margin-bottom: 25px; }
        @media (max-width: 550px) {
          .calculation .flex-block > .right .section .modal-openers {
            display: block;
            width: 100%; } }
        .calculation .flex-block > .right .section .modal-openers .btn-pcp {
          margin-right: 19px; }
          @media (max-width: 550px) {
            .calculation .flex-block > .right .section .modal-openers .btn-pcp {
              margin-right: 0;
              margin-bottom: 10px;
              margin-left: 0; } }
        .calculation .flex-block > .right .section .modal-openers .btn-pcp,
        .calculation .flex-block > .right .section .modal-openers .btn-hp {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 165px;
          height: 44px;
          border: 1px solid #ce181f;
          font-size: 16px;
          font-weight: bold;
          color: #2c2c2c;
          box-sizing: border-box; }
          @media (max-width: 550px) {
            .calculation .flex-block > .right .section .modal-openers .btn-pcp,
            .calculation .flex-block > .right .section .modal-openers .btn-hp {
              width: 100%; } }
          @media (min-width: 1024px) {
            .calculation .flex-block > .right .section .modal-openers .btn-pcp:hover,
            .calculation .flex-block > .right .section .modal-openers .btn-hp:hover {
              border: 0;
              background-color: #ce181f;
              color: #ffffff; } }
      .calculation .flex-block > .right .section .table-summary {
        margin-bottom: 31px;
        padding-bottom: 0;
        border-bottom: 1px solid #f2f2f2; }
        .calculation .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(even) {
          background-color: unset; }
        .calculation .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(odd) {
          background-color: #f2f2f2; }
        .calculation .flex-block > .right .section .table-summary .row {
          align-items: center; }
          .calculation .flex-block > .right .section .table-summary .row .col:not(:last-child) {
            margin-right: 10px; }
          .calculation .flex-block > .right .section .table-summary .row .col:nth-child(1) {
            width: calc(55.6927297668038% - 10px); }
          .calculation .flex-block > .right .section .table-summary .row .col:nth-child(2) {
            width: calc(24.0054869684499% - 10px); }
          .calculation .flex-block > .right .section .table-summary .row .col:nth-child(3) {
            width: calc(20.3017832647462%); }
      .calculation .flex-block > .right .section .input-block label {
        display: block;
        margin-bottom: 16px;
        font-size: 16px; }
      .calculation .flex-block > .right .section .input-block textarea {
        width: 100%;
        height: 100px;
        padding: 12px 15px;
        border: 1px solid #a5a5a5;
        outline: 0;
        font-size: 16px;
        line-height: 1em;
        box-sizing: border-box; }
      .calculation .flex-block > .right .section.btns-section {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 200px; }
      .calculation .flex-block > .right .section .save-button {
        display: block;
        width: 160px;
        height: 35px;
        font-size: 13px;
        line-height: 35px;
        margin-right: 30px; }
        .calculation .flex-block > .right .section .save-button:before {
          content: '';
          display: inline-block;
          width: 18px;
          height: 18px;
          margin-top: -3px;
          margin-right: 10px;
          vertical-align: middle;
          background: url(../img/save.svg) no-repeat; }
      .calculation .flex-block > .right .section .back-button {
        display: block;
        width: 100px;
        height: 35px;
        background: #ffffff;
        border: 2px solid black;
        font-size: 13px;
        line-height: 35px;
        color: black;
        box-sizing: border-box; }
        @media (min-width: 1024px) {
          .calculation .flex-block > .right .section .back-button:hover {
            color: #FFFFFF;
            background: black; } }
      .calculation .flex-block > .right .section .wip-block {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 21px; }
        .calculation .flex-block > .right .section .wip-block span {
          font-size: 16px;
          font-weight: bold; }
        .calculation .flex-block > .right .section .wip-block a {
          display: block;
          width: 133px;
          height: 30px;
          margin-left: 30px;
          font-size: 13px;
          line-height: 30px;
          font-weight: 400;
          text-align: center; }
      .calculation .flex-block > .right .section .Due-block {
        display: flex;
        flex-wrap: wrap; }
        @media (max-width: 760px) {
          .calculation .flex-block > .right .section .Due-block {
            flex-direction: column; } }
        .calculation .flex-block > .right .section .Due-block .block label {
          display: block;
          margin-bottom: 3px;
          font-size: 15px; }
        .calculation .flex-block > .right .section .Due-block .block > input {
          width: 194px;
          margin-bottom: 0;
          padding: 6px 11px;
          border: 1px solid #a5a5a5;
          outline: 0;
          font-size: 14px;
          line-height: 1em;
          box-sizing: border-box; }
          @media (max-width: 760px) {
            .calculation .flex-block > .right .section .Due-block .block > input {
              width: 100%; } }
        .calculation .flex-block > .right .section .Due-block .block select {
          box-sizing: border-box;
          width: 194px;
          margin-bottom: 0;
          padding: 7px 11px;
          border: 1px solid #a5a5a5;
          border-radius: 0;
          outline: 0;
          background: #fff url(../img/select_bg.svg) no-repeat;
          background-position: calc(100% - 15px) center;
          font-size: 14px;
          line-height: 1em;
          resize: none;
          -webkit-appearance: none; }
          @media (max-width: 760px) {
            .calculation .flex-block > .right .section .Due-block .block select {
              width: 100%; } }
          .calculation .flex-block > .right .section .Due-block .block select::-ms-expand {
            display: none; }
        .calculation .flex-block > .right .section .Due-block > * + * {
          margin-left: 8px; }
          @media (max-width: 760px) {
            .calculation .flex-block > .right .section .Due-block > * + * {
              margin-top: 15px;
              margin-left: 0; } }
      .calculation .flex-block > .right .section .status-block label {
        display: block;
        margin-bottom: 3px;
        font-size: 15px; }
      .calculation .flex-block > .right .section .status-block select {
        width: 194px;
        margin-bottom: 0;
        padding: 7px 11px;
        border: 1px solid #a5a5a5;
        border-radius: 0;
        outline: 0;
        background: #fff url(../img/select_bg.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 14px;
        line-height: 1em;
        resize: none;
        -webkit-appearance: none;
        box-sizing: border-box; }
      @media (max-width: 550px) {
        .calculation .flex-block > .right .section .radiogroup--right .radiogroup__radio span {
          width: 100%; } }

.cashflow-log .caslo-table-wrap {
  width: 1610px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 80px;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap; }

.cashflow-log .caslo-table {
  display: inline-block;
  position: relative; }

.cashflow-log .caslo-table-row {
  display: flex;
  margin-right: 25px;
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;
  box-sizing: border-box; }
  .cashflow-log .caslo-table-row:last-child .caslo-table-cell:first-child {
    border-bottom: 1px solid #ce171f; }
  @media (min-width: 1024px) {
    .cashflow-log .caslo-table-row:not(.caslo-table-row--heading):hover {
      background-color: #f7f7f7; }
      .cashflow-log .caslo-table-row:not(.caslo-table-row--heading):hover .caslo-table-cell:nth-child(2n) {
        background-color: #ededed; }
      .cashflow-log .caslo-table-row:not(.caslo-table-row--heading):hover .caslo-table-cell.caslo-table-cell--current-day {
        background-color: #c1e0ec; } }
  .cashflow-log .caslo-table-row--heading {
    width: 1584px;
    border: 1px solid #ce171f;
    border-left: 0;
    z-index: 3;
    position: static; }
  .cashflow-log .caslo-table-row--big-letters {
    height: 46px; }
    .cashflow-log .caslo-table-row--big-letters .caslo-table-cell:first-child {
      font-size: 20px; }
  .cashflow-log .caslo-table-row--gray-bg {
    background-color: #56697b; }
    .cashflow-log .caslo-table-row--gray-bg .caslo-table-cell {
      color: #ffffff !important;
      border-color: rgba(0, 0, 0, 0) !important;
      background-color: #56697b !important; }
      .cashflow-log .caslo-table-row--gray-bg .caslo-table-cell:not(.caslo-table-cell--edit-mode) input {
        color: #ffffff !important; }
  .cashflow-log .caslo-table-row--red-bg {
    background-color: #ce171f; }
    .cashflow-log .caslo-table-row--red-bg .caslo-table-cell {
      color: #ffffff !important;
      border-color: rgba(0, 0, 0, 0) !important;
      background-color: #ce171f !important; }
      .cashflow-log .caslo-table-row--red-bg .caslo-table-cell:not(.caslo-table-cell--edit-mode) input {
        color: #ffffff !important; }

.cashflow-log .caslo-table-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;
  font-family: "Vauxhall Neue", sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-transform: uppercase;
  color: #2c2c2c; }

.cashflow-log .caslo-table-cell {
  position: relative;
  min-height: 33px; }
  .cashflow-log .caslo-table-cell:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 492px;
    min-width: 492px;
    padding: 0 10px 0 16px;
    border-right: 1px solid #ce171f;
    border-left: 1px solid #ce171f;
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #2c2c2c; }
  .cashflow-log .caslo-table-cell:not(:first-child) {
    width: 118px;
    min-width: 118px; }
    .cashflow-log .caslo-table-cell:not(:first-child):not(.caslo-table-cell--heading) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
      font-weight: 400;
      text-align: center;
      color: #2c2c2c; }
  .cashflow-log .caslo-table-cell--heading {
    min-height: 70px; }
    .cashflow-log .caslo-table-cell--heading:not(:first-child) {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center; }
  .cashflow-log .caslo-table-cell:nth-child(2n) {
    background-color: #f7f7f7; }
  .cashflow-log .caslo-table-cell.caslo-table-cell--current-day {
    border-right: 1px solid #dadada;
    border-left: 1px solid #dadada;
    background-color: #e3f7ff; }
    .cashflow-log .caslo-table-cell.caslo-table-cell--current-day:nth-child(2) {
      border-left: 0; }
  .cashflow-log .caslo-table-cell--negative input {
    color: #ce171f !important; }
  .cashflow-log .caslo-table-cell input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    max-width: 110px;
    height: 33px;
    margin: 0 auto;
    border: none;
    background: none;
    font-size: 14px;
    line-height: 1px;
    font-weight: 400;
    color: #2c2c2c;
    text-align: center;
    box-sizing: border-box;
    pointer-events: none; }
  .cashflow-log .caslo-table-cell--edit-mode input {
    border: 1px solid #a5a5a5;
    background-color: #ffffff;
    pointer-events: auto; }

.cashflow-log .drill-down {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 21px;
  margin-right: 10%;
  padding: 0 13px;
  border-radius: 3px;
  background-color: #56697b;
  cursor: pointer;
  transition-duration: .2s; }
  @media (min-width: 1024px) {
    .cashflow-log .drill-down:hover {
      opacity: 0.8; } }
  .cashflow-log .drill-down::before {
    content: '';
    display: block;
    width: 16px;
    height: 4px;
    margin-right: 7px;
    background: url(../img/more-horiz.svg) no-repeat center center;
    background-size: contain; }
  .cashflow-log .drill-down::after {
    content: 'Drill Down';
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 13px;
    line-height: 28px;
    font-weight: 400;
    color: #ffffff; }

.cashflow-log .caslo-tc-date {
  margin-top: 8px;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #666666; }

.cashflow-log .caslo-tc-date-number {
  font-size: 16px;
  color: #ce171f; }

.cashflow-log .caslo-tc-day {
  margin-top: 4px;
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  color: #2c2c2c; }

.cashflow-log .caslo-tc-edit-btn {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  text-align: center;
  cursor: pointer;
  transition-duration: 0.2s; }
  @media (min-width: 1024px) {
    .cashflow-log .caslo-tc-edit-btn:hover {
      opacity: 0.7; } }
  .cashflow-log .caslo-tc-edit-btn::before {
    content: '';
    display: block;
    width: 25px;
    height: 13px;
    background: url(../img/edit-blue.svg) no-repeat center center;
    background-size: contain; }
  .cashflow-log .caslo-tc-edit-btn::after {
    content: 'Save changes';
    display: none;
    width: 100%;
    font-size: 13px;
    line-height: 19.59px;
    font-weight: 400;
    text-decoration: underline;
    color: #007aff; }
  .cashflow-log .caslo-tc-edit-btn--active::after {
    display: block; }
  .cashflow-log .caslo-tc-edit-btn--active::before {
    display: none; }

.cashflow-log .caslo-table-prev-page,
.cashflow-log .caslo-table-next-page {
  position: absolute;
  top: 50%;
  left: 0;
  width: 26px;
  height: 41px;
  transform: translate(-100%, -50%) rotate(-180deg);
  border: 1px solid #bfbfbf;
  border-left: 0;
  background: url(../img/kar-red.svg) no-repeat center center;
  background-size: 30px;
  cursor: pointer;
  transition-duration: 0.2s; }
  @media (min-width: 1024px) {
    .cashflow-log .caslo-table-prev-page:hover,
    .cashflow-log .caslo-table-next-page:hover {
      background-color: #ff000d1e; } }

.cashflow-log .caslo-table-next-page {
  right: 0;
  left: auto;
  transform: translate(100%, -50%) rotate(0deg); }

.cashflow-log .caslo-table-row--fixed {
  position: fixed !important;
  top: 0; }
  .cashflow-log .caslo-table-row--fixed + .caslo-table-row {
    margin-top: 72px; }

.cashflow-log .caslo-table-row--heading-fix-left-big {
  left: calc((100% - 1584px - 26px) / 2) !important; }

.cashflow-log .caslo-table-row:not(.caslo-table-row--fixed) {
  left: unset !important; }

.cashflow-log .cashflow-controls {
  width: 1609px !important; }
  .cashflow-log .cashflow-controls > .input-block {
    justify-content: flex-end !important; }
    @media (max-width: 768px) {
      .cashflow-log .cashflow-controls > .input-block {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important; } }
    .cashflow-log .cashflow-controls > .input-block * {
      order: -1; }
  @media (max-width: 768px) {
    .cashflow-log .cashflow-controls .cfl-btn-add-new-line-wrap {
      width: 100%;
      order: 10; } }

.cashflow-log .cfl-btn-add-new-line-wrap {
  flex-grow: 1; }
  @media (max-width: 768px) {
    .cashflow-log .cfl-btn-add-new-line-wrap {
      width: 100%; } }

.cashflow-log .cfl-btn-add-new-line {
  width: 168px;
  margin-right: 15px;
  position: relative; }
  @media (max-width: 768px) {
    .cashflow-log .cfl-btn-add-new-line {
      width: 100%;
      box-sizing: border-box; } }
  .cashflow-log .cfl-btn-add-new-line::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url(../img/plus.svg) no-repeat center center;
    background-size: contain;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%); }

.cashflow-log .caslo-remove-btn {
  display: block;
  width: 14px;
  height: 18px;
  margin-right: 12px;
  background: url(../img/bin-red.png) no-repeat center center;
  background-size: contain;
  cursor: pointer; }

.modal-caslo-remove {
  width: 501px;
  max-width: 100%; }
  .modal-caslo-remove .input-block {
    width: 100%;
    max-width: 100%; }
    .modal-caslo-remove .input-block .select {
      width: 100%; }

.modal-caslo-remove-title {
  color: #2c2c2c;
  font-size: 26px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
  margin-bottom: 36px; }

button.modal-caslo-remove-btn {
  width: 179px;
  margin: 20px auto 0;
  background-color: #333333;
  position: relative;
  font-size: 15px;
  font-weight: 400; }
  button.modal-caslo-remove-btn::before {
    content: '';
    display: block;
    width: 14px;
    height: 18px;
    background: url(../img/bin.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%); }

.modal-caslo-add-line .input-block + .input-block {
  margin-top: 20px; }

button.modal-caslo-add-btn {
  width: 179px;
  margin: 29px auto 0; }

.check-list {
  padding-bottom: 0 !important; }
  .check-list .inner-clu {
    width: 1370px;
    margin: 0 auto; }
  .check-list .logo__img {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  .check-list .clearfix {
    display: table;
    clear: both; }
  .check-list .inner-clu {
    width: 1370px;
    margin: 0 auto; }
  .check-list .clu-fw-bbg {
    width: 100%;
    border-bottom: 1px solid #ababae;
    line-height: 1; }
  .check-list .clu-redhead {
    display: inline-block;
    width: 516px;
    height: 48px;
    background: #bb292c;
    padding-left: 27px;
    margin: 0;
    padding-top: 12px;
    font-size: 23px;
    line-height: 1;
    color: #ffffff;
    box-sizing: border-box;
    float: left; }
  .check-list .clu-redhead-traingle {
    display: inline-block;
    width: 68px;
    height: 48px;
    margin: 0;
    line-height: 1;
    outline: none;
    float: left; }
  .check-list .clu-table {
    width: 100%; }
  .check-list .clu-table-row {
    width: 100%;
    font-size: 22px;
    line-height: 1.2; }
  .check-list .clu-table-row {
    width: 100%; }
  .check-list .clu-cell__left {
    width: 47.4452554745%;
    padding-top: 15px; }
  .check-list .clu-cell__right {
    width: 47.4452554745%;
    padding-top: 15px; }
  .check-list .clu-input-box {
    width: 100%;
    margin-bottom: 25px;
    box-sizing: border-box; }
  .check-list .clu-input-box--pl {
    padding-left: 27px; }
  .check-list .clu-input-box .label {
    margin-top: 5px;
    font-size: 16px; }
  .check-list .clu-input-box input {
    width: 100%;
    height: 25px;
    border-bottom: 2px solid #a7a7a9;
    font-size: 22px;
    line-height: 25px; }
  .check-list .clu-redhead-block {
    height: 48px; }
  .check-list .clu-cell-main {
    width: 558px;
    padding-left: 27px;
    padding-top: 15px; }
  .check-list .clu-checkbox-block {
    cursor: pointer;
    margin-bottom: 8px !important; }
  .check-list .clu-checkbox-block input {
    display: none; }
  .check-list .clu-checkbox-block input:checked + .clu-s-chb,
  .check-list .clu-checkbox-block input:checked + input + clu-s-chb {
    background-image: url(img/checked.svg); }
  .check-list .clu-s-chb {
    display: inline-block;
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 2px solid #a9a9a9;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 95%;
    margin-right: 13px;
    box-sizing: border-box; }
  .check-list .clu-chb-text {
    font-size: 20px;
    line-height: 1;
    margin-top: 3px; }
  .check-list .clu-after-table {
    height: 240px;
    padding-left: 27px; }
  .check-list .clu-small-grey {
    font-size: 18px;
    line-height: 1.5;
    color: #6a6a6a; }
  .check-list .clu-small-grey {
    display: block; }
  .check-list .clu-small-grey__star {
    display: inline-block;
    min-width: 38px;
    padding-top: 17px;
    margin-left: 13px;
    text-align: right;
    float: left; }
  .check-list .clu-small-grey__text {
    display: block;
    width: 1200px;
    float: left; }
  .check-list .clu-bottom {
    font-size: 22px;
    margin-top: 10px;
    padding-bottom: 10px; }
  .check-list .clu-bottom__item-1 {
    float: left; }
  .check-list .clu-bottom__item-2 {
    float: left; }
  .check-list .clu-bottom__item-3 {
    float: right; }
  .check-list .check-list-used-page {
    padding-bottom: 0 !important; }
  .check-list .winter-service label {
    display: flex;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #2c2c2c;
    margin-bottom: 19px; }

.credit-check {
  padding-bottom: 100px; }
  .credit-check .credit-check-top-section {
    width: 849px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 0 0 45px;
    border-bottom: 1px solid #d7d7d7; }
  .credit-check .credit-check-top {
    display: flex;
    width: 849px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    background-color: #f7f7f7; }
    @media (max-width: 975px) {
      .credit-check .credit-check-top {
        display: block; } }
    .credit-check .credit-check-top__car-pic {
      align-self: flex-start;
      width: 312px;
      min-width: 312px;
      max-width: 100%;
      position: relative; }
      @media (max-width: 975px) {
        .credit-check .credit-check-top__car-pic {
          margin: 0 auto;
          width: 400px;
          max-width: 100%; } }
      @media (max-width: 500px) {
        .credit-check .credit-check-top__car-pic {
          max-width: unset; } }
      .credit-check .credit-check-top__car-pic::before {
        content: '';
        display: block;
        padding-top: calc(205% / 312 * 100); }
      .credit-check .credit-check-top__car-pic img {
        max-width: 100%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        font-family: 'object-fit: cover; object-position: center center;'; }
  .credit-check .bold {
    font-weight: bold; }
  .credit-check .red {
    color: #d22320; }
  .credit-check .yellow {
    color: #e59730; }
  .credit-check .green {
    color: #21c679; }
  .credit-check .finace-check {
    max-width: 607px;
    padding-top: 40px;
    margin-left: auto;
    margin-right: auto; }
    .credit-check .finace-check .ah1 {
      font-size: 24px;
      line-height: 27px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 18px; }
    .credit-check .finace-check .ah2 {
      font-size: 16px;
      text-align: center;
      margin-bottom: 34px; }
    .credit-check .finace-check .table-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px; }
      .credit-check .finace-check .table-header .table-header-text {
        font-size: 22px;
        line-height: 25px;
        margin-left: 25px; }
      .credit-check .finace-check .table-header .text-editor {
        line-height: 25px;
        color: #ce171f;
        text-decoration: underline;
        margin-left: 30px;
        margin-right: 25px; }
        .credit-check .finace-check .table-header .text-editor::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-top: -2px;
          margin-right: 4px;
          background: url("../img/edit.svg") no-repeat;
          background-size: contain;
          vertical-align: middle; }
    .credit-check .finace-check table {
      width: 100%;
      margin-bottom: 35px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 15px;
      line-height: 18px; }
      @media (max-width: 700px) {
        .credit-check .finace-check table {
          font-size: 14px; } }
      @media (max-width: 500px) {
        .credit-check .finace-check table {
          font-size: 12px; } }
      .credit-check .finace-check table tr:nth-child(odd) td {
        background: #f7f7f7; }
      .credit-check .finace-check table td {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 7px;
        padding-bottom: 7px; }
      .credit-check .finace-check table tr td:last-child {
        width: 50%;
        text-align: right;
        box-sizing: border-box; }
    .credit-check .finace-check .agree label {
      display: flex;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #2c2c2c;
      margin-bottom: 30px; }
      .credit-check .finace-check .agree label input {
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-right: 9px;
        margin-bottom: 0;
        margin-top: 0;
        border: 1px solid #a5a5a5;
        background-color: #fff;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .credit-check .finace-check .agree label input:checked {
          background-image: url(../img/checked.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%; }
  .credit-check .attention {
    border: 1px solid #d7d7d7;
    display: flex;
    margin-bottom: 35px; }
    .credit-check .attention .img {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      min-height: 108px;
      width: 108px;
      border-right: 1px solid #d7d7d7; }
      .credit-check .attention .img img {
        max-width: 60px; }
    .credit-check .attention .text {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 25px;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.02em; }
  .credit-check .input-row {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    margin: 19px auto 0; }
    @media (max-width: 500px) {
      .credit-check .input-row {
        display: block;
        margin-top: 0; } }
    .credit-check .input-row .input-block {
      max-width: 48.3%;
      width: 100%; }
      @media (max-width: 500px) {
        .credit-check .input-row .input-block {
          margin-top: 20px;
          max-width: none; } }
      .credit-check .input-row .input-block label {
        display: block !important;
        margin-bottom: 1rem;
        vertical-align: top;
        font-size: 16px;
        color: #2c2c2c;
        user-select: none; }
      .credit-check .input-row .input-block select {
        width: 100%;
        padding: 13px 15px;
        border: 1px solid #a5a5a5;
        border-radius: 0;
        outline: 0;
        background: #fff url(../img/select_bg.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        font-size: 16px;
        line-height: 1em;
        -webkit-appearance: none;
        -moz-appearance: none;
        resize: none;
        box-sizing: border-box; }
      .credit-check .input-row .input-block input {
        padding: 12px 15px;
        margin-right: 15px;
        border: 1px solid #a5a5a5;
        outline: 0;
        font-size: 16px;
        line-height: 1em;
        -webkit-appearance: none;
        -moz-appearance: none;
        box-sizing: border-box; }
        @media (max-width: 500px) {
          .credit-check .input-row .input-block input {
            display: block;
            margin-left: 0 !important;
            margin-top: 10px;
            margin-bottom: 10px; } }
        .credit-check .input-row .input-block input.ml35 {
          margin-left: 30px; }
      .credit-check .input-row .input-block .btn {
        display: inline-block;
        vertical-align: middle;
        margin-top: 31px; }
        @media (max-width: 500px) {
          .credit-check .input-row .input-block .btn {
            margin-top: 5px; } }
      .credit-check .input-row .input-block.many-input input {
        width: 120px; }
    .credit-check .input-row.double_row .input-block {
      max-width: none; }
    .credit-check .input-row.border {
      padding-bottom: 30px;
      border-bottom: 1px solid  #d7d7d7; }
    @media (max-width: 700px) {
      .credit-check .input-row.third-input-row {
        display: block;
        margin-top: 0; } }
    .credit-check .input-row.third-input-row .input-block {
      max-width: 31%; }
      @media (max-width: 700px) {
        .credit-check .input-row.third-input-row .input-block {
          max-width: none;
          margin-top: 20px; } }
  .credit-check .buttons {
    text-align: center; }
    .credit-check .buttons > * {
      display: inline-block;
      width: 265px;
      margin-top: 20px;
      margin-left: 12px;
      margin-right: 12px;
      font-size: 18px;
      vertical-align: middle; }
    .credit-check .buttons .btn--white {
      border: 1px solid #58585a;
      color: #2c2c2c;
      box-sizing: border-box;
      transition: 0.3s all; }
      @media (min-width: 1024px) {
        .credit-check .buttons .btn--white:hover {
          color: #fff;
          background: #58585a; } }
  .credit-check .next-block {
    max-width: 845px;
    padding-top: 30px;
    margin-left: auto;
    margin-right: auto; }
    .credit-check .next-block .buttons {
      padding-top: 40px; }
  .credit-check .third-block {
    max-width: 845px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30px; }
    .credit-check .third-block .input-row .input-block .btn--red {
      width: 150px;
      margin-right: 35px;
      background: #ce171f;
      font-size: 18px;
      transition: 0.3s background; }
      @media (min-width: 1024px) {
        .credit-check .third-block .input-row .input-block .btn--red:hover {
          background: #58585a; } }
    .credit-check .third-block .input-row .input-block .enter-address {
      width: auto;
      text-decoration: underline;
      color: #2c2c2c; }
    .credit-check .third-block .buttons {
      padding-top: 60px; }
  .credit-check .first-block .crch-return-later {
    text-align: center;
    padding-left: 60px;
    padding-right: 60px; }
  .credit-check .crch-t-info {
    display: flex;
    flex-direction: column;
    min-height: 205px;
    width: 100%;
    padding: 26px 33px 24px 31px;
    box-sizing: border-box; }
    @media (max-width: 500px) {
      .credit-check .crch-t-info {
        width: 100%;
        min-height: unset;
        padding: 10px; } }
  .credit-check .crch-t-info-top {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
    padding-bottom: 15px; }
    @media (max-width: 500px) {
      .credit-check .crch-t-info-top {
        display: block; } }
    .credit-check .crch-t-info-top__left, .credit-check .crch-t-info-top__right {
      max-width: calc(50% - 5px); }
      @media (max-width: 500px) {
        .credit-check .crch-t-info-top__left, .credit-check .crch-t-info-top__right {
          max-width: 100%; } }
  .credit-check .crch-t-info-top-text-style-1 {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    color: #2c2c2c; }
  .credit-check .crch-t-info-top-text-style-2 {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    color: #2c2c2c; }
  .credit-check .crch-t-info-top-text-style-3 {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #2c2c2c; }
  .credit-check .crch-t-info-bottom {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 13px;
    line-height: 28px;
    font-weight: 400;
    color: #2c2c2c; }
  .credit-check .credit-check-inputs-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px; }
    @media (max-width: 850px) {
      .credit-check .credit-check-inputs-row {
        display: block; } }
    .credit-check .credit-check-inputs-row .input-lane {
      width: calc(33% - 19px * 2 / 3); }
      @media (max-width: 850px) {
        .credit-check .credit-check-inputs-row .input-lane {
          width: 400px;
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
          box-sizing: border-box; } }
      @media (max-width: 500px) {
        .credit-check .credit-check-inputs-row .input-lane {
          width: 100%; } }
      .credit-check .credit-check-inputs-row .input-lane + .input-lane {
        margin-top: 0; }
        @media (max-width: 850px) {
          .credit-check .credit-check-inputs-row .input-lane + .input-lane {
            margin-top: 10px; } }
      .credit-check .credit-check-inputs-row .input-lane__title {
        display: block;
        vertical-align: top;
        font-size: 16px;
        line-height: 19px;
        color: #2c2c2c;
        margin-bottom: 8px; }
      .credit-check .credit-check-inputs-row .input-lane__input {
        display: block;
        width: 100%;
        height: 42px;
        padding: 0 35px 0 15px;
        border: 1px solid #a5a5a5;
        font-size: 16px;
        line-height: 19px;
        color: #2c2c2c;
        box-sizing: border-box; }
      .credit-check .credit-check-inputs-row .input-lane .input-box {
        width: 100%;
        position: relative; }
        .credit-check .credit-check-inputs-row .input-lane .input-box .plus {
          width: 42px;
          height: 100%;
          background: #ce181e url(../img/input-plus.png) no-repeat center center;
          position: absolute;
          top: 0;
          right: 0;
          cursor: pointer; }
          .credit-check .credit-check-inputs-row .input-lane .input-box .plus + .input-lane__input {
            text-align: center;
            padding-left: 40px;
            padding-right: 40px; }
        .credit-check .credit-check-inputs-row .input-lane .input-box .minus {
          width: 42px;
          height: 100%;
          background: #ce181e url(../img/input-minus.png) no-repeat center center;
          position: absolute;
          top: 0;
          left: 0;
          cursor: pointer; }
  .credit-check .btn--credit-check-top {
    margin: 0 auto;
    margin-top: 25px;
    font-size: 18px; }
  .credit-check .ryg-scale {
    width: 236px;
    max-width: 100%;
    background: url(../img/crche-shkala.png) no-repeat top center;
    background-size: 100% auto;
    color: #fff;
    position: relative; }
    .credit-check .ryg-scale::before {
      content: '';
      display: block;
      padding-top: calc(215% / 236 * 100); }
    .credit-check .ryg-scale__arrow {
      position: absolute;
      left: 44%;
      top: 21%;
      transition-duration: 0.2s;
      transform-origin: 50% calc(100% - 20px); }
      .credit-check .ryg-scale__arrow img {
        max-width: 100%;
        height: auto; }
    .credit-check .ryg-scale__text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 270px;
      height: 60px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      border: 1px solid #d9d9d9;
      background-color: #ffffff;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 24px;
      font-weight: bold;
      line-height: 26px;
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      top: 100%;
      transform: translate(-50%, -100%);
      box-sizing: border-box; }
    .credit-check .ryg-scale--r {
      color: #d22320; }
      .credit-check .ryg-scale--r .ryg-scale__arrow {
        transform: rotate(-90deg); }
    .credit-check .ryg-scale--y {
      color: #e59730; }
    .credit-check .ryg-scale--g {
      color: #21c679; }
      .credit-check .ryg-scale--g .ryg-scale__arrow {
        transform: rotate(90deg); }
  .credit-check .lender-help {
    display: flex;
    align-items: center;
    width: 849px;
    max-width: 100%;
    margin: 46px auto 87px; }
    @media (max-width: 700px) {
      .credit-check .lender-help {
        flex-direction: column;
        align-items: center; } }
    .credit-check .lender-help__left {
      color: #2c2c2c;
      margin-right: 54px; }
      .credit-check .lender-help__left h4 {
        font-size: 24px;
        font-weight: 400;
        line-height: 1.3; }
        .credit-check .lender-help__left h4 + p {
          margin-top: 25px; }
      .credit-check .lender-help__left p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3; }
        .credit-check .lender-help__left p + p {
          margin-top: 25px; }
    .credit-check .lender-help__right {
      padding-right: 17px; }
      @media (max-width: 700px) {
        .credit-check .lender-help__right {
          margin-top: 10px; } }
  .credit-check .checked-quotes-title {
    margin-bottom: 25px;
    font-size: 24px;
    line-height: 26px;
    font-weight: 400;
    color: #2c2c2c;
    text-align: center;
    text-transform: uppercase; }
  .credit-check .checked-quote {
    display: flex; }
    @media (max-width: 700px) {
      .credit-check .checked-quote {
        display: block;
        padding: 15px; } }
    .credit-check .checked-quote:nth-child(odd) {
      background-color: #f5f5f5; }
    .credit-check .checked-quote:last-child {
      border-bottom: 1px solid #d7d7d7; }
  .credit-check .checked-quote-info {
    padding: 24px 0 28px 22px; }
    @media (max-width: 700px) {
      .credit-check .checked-quote-info {
        padding: 0;
        padding-bottom: 15px; } }
    .credit-check .checked-quote-info__top {
      display: flex;
      justify-content: space-between;
      padding-right: 20px;
      margin: -5px; }
      @media (max-width: 975px) {
        .credit-check .checked-quote-info__top {
          justify-content: flex-start;
          flex-wrap: wrap; } }
      @media (max-width: 700px) {
        .credit-check .checked-quote-info__top {
          padding-right: 0;
          margin: -10px; } }
  .credit-check .checked-quote-info-part {
    display: block;
    margin: 5px; }
    @media (max-width: 700px) {
      .credit-check .checked-quote-info-part {
        margin: 10px; } }
    .credit-check .checked-quote-info-part__name {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 13px;
      line-height: 1.3;
      font-weight: 400;
      color: #757575; }
    .credit-check .checked-quote-info-part__value {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.3;
      color: #2c2c2c;
      text-transform: uppercase;
      margin-top: 5px; }
  .credit-check .checked-quote-info-bottom {
    margin-top: 20px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 13px;
    line-height: 22px;
    font-weight: 400;
    color: #757575; }
    .credit-check .checked-quote-info-bottom span {
      font-weight: bold;
      color: #2c2c2c;
      text-transform: uppercase; }
  .credit-check .cq-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
    height: 32px;
    margin-top: 24px;
    margin-right: 20px;
    font-size: 14px;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer; }
    @media (max-width: 700px) {
      .credit-check .cq-btn {
        width: 300px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto; } }
  .credit-check .btn-cq-save {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 46px;
    margin: 0 auto;
    margin-top: 22px;
    background-color: #ce171f;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 18px;
    line-height: 1;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 0.2s; }
    @media (min-width: 1024px) {
      .credit-check .btn-cq-save:hover {
        background-color: #a01218; } }
  .credit-check .crch-return-later {
    width: 847px;
    max-width: 100%;
    margin: 59px auto 0;
    padding: 47px 114px 62px;
    border: 1px solid #d7d7d7;
    box-sizing: border-box; }
    @media (max-width: 700px) {
      .credit-check .crch-return-later {
        padding: 30px 30px !important; } }
    @media (max-width: 500px) {
      .credit-check .crch-return-later {
        padding: 15px !important; } }
    .credit-check .crch-return-later h4 {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
      color: #2c2c2c;
      text-align: center; }
      .credit-check .crch-return-later h4 + p {
        margin-top: 38px; }
        @media (max-width: 500px) {
          .credit-check .crch-return-later h4 + p {
            margin-top: 15px; } }
    .credit-check .crch-return-later p {
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #2c2c2c; }
      .credit-check .crch-return-later p + .btn {
        margin-top: 44px; }
        @media (max-width: 500px) {
          .credit-check .crch-return-later p + .btn {
            margin-top: 15px; } }
      .credit-check .crch-return-later p + p {
        margin-top: 31px; }
        @media (max-width: 500px) {
          .credit-check .crch-return-later p + p {
            margin-top: 10px; } }
    .credit-check .crch-return-later .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 459px;
      min-height: 46px;
      height: unset;
      margin: 0 auto;
      padding: 16px 15px;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.3;
      cursor: pointer;
      box-sizing: border-box; }
      @media (max-width: 500px) {
        .credit-check .crch-return-later .btn {
          padding: 10px !important;
          font-size: 16px; } }
      .credit-check .crch-return-later .btn.button--white {
        padding: 15px 14px;
        border: 1px solid #58585a; }
      .credit-check .crch-return-later .btn + .btn {
        margin-top: 20px; }
    .credit-check .crch-return-later .agree label {
      display: flex;
      justify-content: center;
      font-family: "Vauxhall Neue", sans-serif;
      font-size: 16px;
      line-height: 26px;
      font-weight: 400;
      color: #2c2c2c;
      margin-bottom: 30px;
      margin-top: 30px; }
      .credit-check .crch-return-later .agree label input {
        min-width: 20px;
        width: 20px;
        height: 20px;
        border: 1px solid #a5a5a5;
        background-color: #fff;
        margin-right: 9px;
        margin-bottom: 0;
        margin-top: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer; }
        .credit-check .crch-return-later .agree label input:checked {
          background-image: url(../img/checked.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%; }

.sales-manager .table .row {
  display: flex;
  padding: 12px 14px 12px 9px; }
  .sales-manager .table .row:nth-child(odd) {
    background-color: #f2f2f2; }

.sales-manager .flex-block {
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  margin-top: 33px; }
  @media (max-width: 1200px) {
    .sales-manager .flex-block {
      flex-direction: column;
      justify-content: flex-start;
      max-width: calc(100% - 30px);
      width: 100%;
      margin: 0 auto;
      margin-top: 33px; } }
  .sales-manager .flex-block > .left {
    width: 349px; }
    @media (max-width: 1200px) {
      .sales-manager .flex-block > .left {
        width: 100%; } }
    .sales-manager .flex-block > .left > .title {
      font-size: 25px;
      line-height: 33px;
      font-weight: bold;
      margin-bottom: 4px; }
    .sales-manager .flex-block > .left .block {
      margin-bottom: 19px; }
      .sales-manager .flex-block > .left .block > .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .sales-manager .flex-block > .left .block .table-vehicle {
        margin-top: 1px; }
        .sales-manager .flex-block > .left .block .table-vehicle .row {
          justify-content: space-between; }
          .sales-manager .flex-block > .left .block .table-vehicle .row .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .sales-manager .flex-block > .left .block .table-vehicle .row .left .type {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 13px;
              line-height: 10px;
              font-weight: 400;
              color: #757575; }
            .sales-manager .flex-block > .left .block .table-vehicle .row .left .name {
              line-height: 18px;
              text-decoration: underline; }
          .sales-manager .flex-block > .left .block .table-vehicle .row .right {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 32px; }
            @media (max-width: 1200px) {
              .sales-manager .flex-block > .left .block .table-vehicle .row .right {
                justify-content: flex-start; } }
            .sales-manager .flex-block > .left .block .table-vehicle .row .right .price {
              font-family: "Vauxhall Neue", sans-serif;
              font-size: 16px;
              font-weight: bold;
              color: #2c2c2c; }
      .sales-manager .flex-block > .left .block .table-trade .row .left,
      .sales-manager .flex-block > .left .block .table-trade .row .right {
        width: 50%; }
      .sales-manager .flex-block > .left .block .table-trade .row .parameter {
        font-size: 13px;
        line-height: 15px;
        font-weight: 400;
        color: #757575;
        margin-bottom: 10px; }
      .sales-manager .flex-block > .left .block .table-trade .row .value {
        line-height: 11px;
        text-transform: uppercase; }
      .sales-manager .flex-block > .left .block .table-trade .row.summary .left {
        width: 100%; }
      .sales-manager .flex-block > .left .block .table-other .left {
        flex-grow: 1; }
  .sales-manager .flex-block > .right {
    width: calc(100% - 389px);
    margin-left: 15px; }
    @media (max-width: 1200px) {
      .sales-manager .flex-block > .right {
        width: 100%;
        margin-left: 0; } }
    .sales-manager .flex-block > .right .section {
      padding-bottom: 28px;
      margin-bottom: 29px; }
      .sales-manager .flex-block > .right .section:not(:last-child) {
        border-bottom: 1px solid #e6e6e6; }
      .sales-manager .flex-block > .right .section > .title {
        font-size: 24px;
        line-height: 33px;
        font-weight: bold; }
      .sales-manager .flex-block > .right .section .offered-price .estimated-profit {
        display: block;
        width: 50%; }
      .sales-manager .flex-block > .right .section .offered-price label {
        font-size: 16px; }
      .sales-manager .flex-block > .right .section .offered-price .flex-wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-top: 15px; }
        @media (max-width: 550px) {
          .sales-manager .flex-block > .right .section .offered-price .flex-wrap {
            flex-wrap: wrap; } }
        .sales-manager .flex-block > .right .section .offered-price .flex-wrap input {
          width: 362px;
          height: 44px;
          border: 1px solid #a5a5a5;
          background-color: #ffffff;
          font-size: 16px;
          padding-left: 19px; }
          @media (max-width: 550px) {
            .sales-manager .flex-block > .right .section .offered-price .flex-wrap input {
              width: 100%;
              padding-left: 10px; } }
        .sales-manager .flex-block > .right .section .offered-price .flex-wrap span {
          margin-left: 29px; }
          @media (max-width: 550px) {
            .sales-manager .flex-block > .right .section .offered-price .flex-wrap span {
              margin-top: 10px; } }
          .sales-manager .flex-block > .right .section .offered-price .flex-wrap span b {
            font-size: 18px; }
      .sales-manager .flex-block > .right .section .offered-price .price {
        margin-top: 28px;
        font-size: 18px; }
        .sales-manager .flex-block > .right .section .offered-price .price .red {
          margin-left: 11px;
          font-size: 24px;
          font-weight: bold;
          color: #ce171f; }
      .sales-manager .flex-block > .right .section .table-added {
        margin-top: 15px; }
        .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox] {
          display: none; }
          .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox] + label::before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 20px;
            margin: -5px 5px 0 0;
            border: 1px solid #a9a9a9;
            background-color: #ffffff;
            line-height: 22px;
            text-align: center;
            vertical-align: middle;
            transition: color 0.3s ease; }
          .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox]:checked + label::before {
            background: url(../img/checked.svg) center; }
          .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label::before {
            border: 1px solid #d9d9d9; }
          .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span {
            margin-right: 10px; }
            .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span,
            .sales-manager .flex-block > .right .section .table-added .row input[type=checkbox][disabled] + label + span + span + span {
              color: #999999; }
        .sales-manager .flex-block > .right .section .table-added .row label {
          cursor: pointer; }
          .sales-manager .flex-block > .right .section .table-added .row label + span {
            flex-grow: 1; }
      .sales-manager .flex-block > .right .section .modal-openers {
        display: flex;
        width: 348px;
        margin-bottom: 25px;
        margin-top: 14px; }
        @media (max-width: 550px) {
          .sales-manager .flex-block > .right .section .modal-openers {
            display: block;
            width: 100%; } }
        .sales-manager .flex-block > .right .section .modal-openers .btn-pcp {
          margin-right: 19px; }
          @media (max-width: 550px) {
            .sales-manager .flex-block > .right .section .modal-openers .btn-pcp {
              margin-right: 0;
              margin-bottom: 10px;
              margin-left: 0; } }
        .sales-manager .flex-block > .right .section .modal-openers .btn-pcp,
        .sales-manager .flex-block > .right .section .modal-openers .btn-hp {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 165px;
          height: 44px;
          border: 1px solid #ce181f;
          box-sizing: border-box;
          font-size: 16px;
          font-weight: bold;
          color: #2c2c2c; }
          @media (max-width: 550px) {
            .sales-manager .flex-block > .right .section .modal-openers .btn-pcp,
            .sales-manager .flex-block > .right .section .modal-openers .btn-hp {
              width: 100%; } }
          @media (min-width: 1024px) {
            .sales-manager .flex-block > .right .section .modal-openers .btn-pcp:hover,
            .sales-manager .flex-block > .right .section .modal-openers .btn-hp:hover {
              border: 0;
              background-color: #ce181f;
              color: #ffffff; } }
      .sales-manager .flex-block > .right .section .table-summary {
        margin-bottom: 31px;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 0; }
        .sales-manager .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(even) {
          background-color: unset; }
        .sales-manager .flex-block > .right .section .table-summary.tab-bg-odd .row:nth-child(odd) {
          background-color: #f2f2f2; }
        .sales-manager .flex-block > .right .section .table-summary .row {
          align-items: center; }
          .sales-manager .flex-block > .right .section .table-summary .row .col:not(:last-child) {
            margin-right: 10px; }
          .sales-manager .flex-block > .right .section .table-summary .row .col:nth-child(1) {
            width: calc(55.6927297668038% - 10px); }
          .sales-manager .flex-block > .right .section .table-summary .row .col:nth-child(2) {
            width: calc(24.0054869684499% - 10px); }
          .sales-manager .flex-block > .right .section .table-summary .row .col:nth-child(3) {
            width: calc(20.3017832647462%); }
    .sales-manager .flex-block > .right .btn-block {
      display: flex;
      width: fit-content;
      margin: 0 auto;
      padding-top: 20px; }
      .sales-manager .flex-block > .right .btn-block .cbtn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 267px;
        height: 46px;
        background-color: #124390;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #ffffff;
        transition-duration: 0.2s; }
        .sales-manager .flex-block > .right .btn-block .cbtn:nth-child(1) {
          margin-right: 24px; }
        @media (min-width: 1024px) {
          .sales-manager .flex-block > .right .btn-block .cbtn:hover {
            filter: brightness(1.1); } }

.deal-setup #saveLeadIdBtn,
.deal-setup #saveEnquiryIdBtn {
  max-width: 94px;
  width: 100%;
  height: 35px;
  font-size: 15px;
  font-weight: 400;
  background-color: #124390;
  border-radius: 5px; }

.deal-setup .rotate {
  transform: rotate(180deg); }

.deal-setup .table_with_button {
  display: flex;
  align-items: center; }

.deal-setup .wcl-custom-input {
  width: 100%;
  height: 35px;
  margin-right: 10px;
  border-radius: 3px;
  border: 1px solid #a5a5a5;
  background-color: #fff;
  box-sizing: border-box; }

.deal-setup .sales-manager .table .row .right a {
  color: #298efd; }

.deal-setup .sales-manager .section .btn {
  line-height: 18px; }

.deal-setup .sales-manager .flex-block {
  margin-bottom: 26px; }
  @media (max-width: 1200px) {
    .deal-setup .sales-manager .flex-block > .left {
      width: 100% !important;
      max-width: 520px;
      margin: 0 auto; } }
  .deal-setup .sales-manager .flex-block > .left .block > .title {
    font-size: 18px;
    font-weight: 400; }
  .deal-setup .sales-manager .flex-block > .left .block .table-other .left {
    width: 37%;
    flex-grow: unset; }
  .deal-setup .sales-manager .flex-block > .left .block .table-other .right .input-block {
    display: flex;
    align-items: center;
    margin-bottom: 0; }
  .deal-setup .sales-manager .flex-block > .left .block--table {
    border: 1px solid #e6e6e6;
    background-color: #f7f7f7; }
    .deal-setup .sales-manager .flex-block > .left .block--table .title {
      padding: 9px; }
    .deal-setup .sales-manager .flex-block > .left .block--table .ds-inc-mis-edit-btn {
      display: inline-block;
      width: 13px;
      height: 13px;
      margin: 0 5px;
      background: url(../img/edit-btn.png) no-repeat center center;
      background-size: contain;
      cursor: pointer;
      transition-duration: 0.2s; }
      @media (min-width: 1024px) {
        .deal-setup .sales-manager .flex-block > .left .block--table .ds-inc-mis-edit-btn:hover {
          opacity: 0.8; } }
    .deal-setup .sales-manager .flex-block > .left .block--table .table {
      margin-bottom: 0; }
      .deal-setup .sales-manager .flex-block > .left .block--table .table .row {
        justify-content: space-between;
        flex-wrap: nowrap; }
        .deal-setup .sales-manager .flex-block > .left .block--table .table .row + .row {
          border-top: 1px solid #e6e6e6; }
        .deal-setup .sales-manager .flex-block > .left .block--table .table .row:nth-child(odd) {
          background-color: #f7f7f7;
          align-items: center; }
      .deal-setup .sales-manager .flex-block > .left .block--table .table .left,
      .deal-setup .sales-manager .flex-block > .left .block--table .table .right {
        display: block;
        height: auto; }
      .deal-setup .sales-manager .flex-block > .left .block--table .table .left {
        color: #666666; }
      .deal-setup .sales-manager .flex-block > .left .block--table .table .right {
        text-align: right; }
        @media (max-width: 1200px) {
          .deal-setup .sales-manager .flex-block > .left .block--table .table .right {
            width: auto; } }
      .deal-setup .sales-manager .flex-block > .left .block--table .table .btn-red {
        max-width: 135px;
        width: 100%;
        height: 35px;
        line-height: 35px;
        border-radius: 5px; }
  .deal-setup .sales-manager .flex-block .right .section:not(:last-child) {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 28px; }
  .deal-setup .sales-manager .flex-block .right .section .table-si .row .col {
    font-size: 14px;
    padding: 0;
    margin: 0;
    flex-basis: unset; }
    .deal-setup .sales-manager .flex-block .right .section .table-si .row .col:nth-child(1) {
      width: 35%; }
    .deal-setup .sales-manager .flex-block .right .section .table-si .row .col:nth-child(2) {
      width: 19%; }
    .deal-setup .sales-manager .flex-block .right .section .table-si .row .col:nth-child(3) {
      width: 18%; }
    .deal-setup .sales-manager .flex-block .right .section .table-si .row .col:nth-child(4) {
      width: 16%; }
    .deal-setup .sales-manager .flex-block .right .section .table-si .row .col:nth-child(5) {
      width: 12%; }
  @media (max-width: 1024px) {
    .deal-setup .sales-manager .flex-block .right .section .table-si--mobile {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      box-sizing: border-box; } }
  @media (max-width: 550px) {
    .deal-setup .sales-manager .flex-block .right .section .table-si--mobile {
      width: 100%; } }
  @media (max-width: 1024px) {
    .deal-setup .sales-manager .flex-block .right .section .table-si--mobile .row.headline {
      display: none; } }
  @media (max-width: 1024px) {
    .deal-setup .sales-manager .flex-block .right .section .table-si--mobile .row .col {
      width: 100% !important;
      display: flex;
      padding: 8px 0; } }
  .deal-setup .sales-manager .flex-block .right .section .table-si--mobile .row .col::before {
    content: attr(aria-label);
    display: none;
    max-width: 100px;
    width: 100%;
    margin-right: 20px;
    color: #999999; }
    @media (max-width: 1024px) {
      .deal-setup .sales-manager .flex-block .right .section .table-si--mobile .row .col::before {
        display: inline-block; } }
  .deal-setup .sales-manager .flex-block .right .section .subtitle {
    font-size: 18px;
    margin-top: 30px; }
  .deal-setup .sales-manager .flex-block .right .section .table-added {
    width: 100%;
    padding: 12px 0;
    box-sizing: border-box; }
    .deal-setup .sales-manager .flex-block .right .section .table-added .row label {
      margin: 0; }
  .deal-setup .sales-manager .flex-block .right .section .modal-openers {
    width: auto;
    align-items: center; }
    .deal-setup .sales-manager .flex-block .right .section .modal-openers .btn-hp,
    .deal-setup .sales-manager .flex-block .right .section .modal-openers .btn-pcp {
      height: 35px;
      border-radius: 5px;
      color: #ce171f;
      background-color: #fff;
      font-weight: 400;
      transition: all 0.3s ease; }
      @media (min-width: 1024px) {
        .deal-setup .sales-manager .flex-block .right .section .modal-openers .btn-hp:hover,
        .deal-setup .sales-manager .flex-block .right .section .modal-openers .btn-pcp:hover {
          color: #fff;
          background-color: #ce171f; } }
    .deal-setup .sales-manager .flex-block .right .section .modal-openers .btn-red {
      width: 190px;
      height: 35px;
      font-weight: 400;
      border-radius: 5px; }
    .deal-setup .sales-manager .flex-block .right .section .modal-openers .applied {
      font-size: 14px; }
      .deal-setup .sales-manager .flex-block .right .section .modal-openers .applied span {
        color: #ce171f; }
  .deal-setup .sales-manager .flex-block .right .section .offered-price {
    margin-top: 13px;
    margin-bottom: 54px; }
    .deal-setup .sales-manager .flex-block .right .section .offered-price label {
      font-size: 14px;
      margin-bottom: 4px; }
    .deal-setup .sales-manager .flex-block .right .section .offered-price .flex-wrap {
      margin-top: 0; }
      .deal-setup .sales-manager .flex-block .right .section .offered-price .flex-wrap input {
        height: 31px;
        border-radius: 3px;
        font-size: 14px; }
    .deal-setup .sales-manager .flex-block .right .section .offered-price .estimated-profit {
      padding: 2.5% 8%;
      font-size: 14px;
      float: right; }
      .deal-setup .sales-manager .flex-block .right .section .offered-price .estimated-profit p + p {
        margin-top: 10px; }
    .deal-setup .sales-manager .flex-block .right .section .offered-price .price {
      font-size: 14px;
      margin-top: 15px; }
      .deal-setup .sales-manager .flex-block .right .section .offered-price .price .red {
        font-size: 16px; }

.deal-setup .estimated-profit span + span {
  margin-top: 7px; }

.deal-setup .table.table-other .row {
  min-height: 42px;
  align-items: center;
  padding: 3px 14px 3px 9px;
  box-sizing: border-box; }

.deal-setup .table.table-other select {
  margin: 0;
  padding: 9px 20px 9px 10px;
  border-radius: 3px; }
  .deal-setup .table.table-other select::-ms-expand {
    display: none; }

.deal-setup .row {
  margin-left: 0;
  margin-right: 0;
  align-items: flex-end; }

.deal-setup .block--documents {
  border-top: 1px solid #e6e6e6;
  margin-top: 30px;
  padding-top: 20px; }
  .deal-setup .block--documents .title {
    font-size: 24px !important;
    font-weight: 400 !important;
    margin-bottom: 20px; }
  .deal-setup .block--documents .file {
    display: none;
    margin-top: 15px; }
  .deal-setup .block--documents .file-name {
    display: block;
    width: 100%;
    font-size: 16px;
    text-decoration: underline;
    color: #ce181f; }
    .deal-setup .block--documents .file-name + .file-name {
      margin-top: 10px; }
  .deal-setup .block--documents .pdf-open-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 184px;
    height: 35px;
    margin-bottom: 35px;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #ce181f;
    margin-top: 30px;
    border-radius: 5px; }
    .deal-setup .block--documents .pdf-open-popup:hover {
      color: #fff;
      text-decoration: none; }

.deal-setup .status-control {
  display: flex; }
  @media (max-width: 767px) {
    .deal-setup .status-control {
      flex-wrap: wrap;
      align-items: center;
      margin: 0 -5px; } }
  @media (max-width: 500px) {
    .deal-setup .status-control {
      margin: 0; } }
  .deal-setup .status-control .btn-calms {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 165px;
    height: 40px;
    margin-right: 15px;
    background-color: #ff832f;
    font-size: 16px;
    font-weight: 400;
    color: #fefefe;
    box-sizing: border-box;
    position: relative;
    border-radius: 5px; }
    @media (max-width: 767px) {
      .deal-setup .status-control .btn-calms {
        width: calc(50% - 10px);
        margin: 0 5px 10px; } }
    @media (max-width: 500px) {
      .deal-setup .status-control .btn-calms {
        width: 100%;
        margin: 0 0 10px; } }
    @media (min-width: 1024px) {
      .deal-setup .status-control .btn-calms:hover {
        background-color: #e0752c;
        text-decoration: none; } }
    .deal-setup .status-control .btn-calms svg {
      transition: transform 0.3s ease; }
      .deal-setup .status-control .btn-calms svg:first-child {
        margin-right: 5px; }
    .deal-setup .status-control .btn-calms figure {
      max-width: 235px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      transform: translateY(113%);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.16);
      border-radius: 5px 0 0;
      border: 1px solid #d9d9d9;
      background-color: #ffffff; }
      @media (max-width: 767px) {
        .deal-setup .status-control .btn-calms figure {
          width: 100%; } }
      .deal-setup .status-control .btn-calms figure a {
        display: block;
        padding: 13px 24px;
        font-size: 16px;
        color: #2c2c2c;
        margin-right: 0;
        transition: all 0.3s ease; }
        .deal-setup .status-control .btn-calms figure a + a {
          border-top: 1px solid #d9d9d9; }
        .deal-setup .status-control .btn-calms figure a:hover {
          text-decoration: none;
          background-color: rgba(217, 217, 217, 0.5); }
    .deal-setup .status-control .btn-calms--final {
      width: 200px; }
      @media (max-width: 500px) {
        .deal-setup .status-control .btn-calms--final {
          width: 100%; } }
  .deal-setup .status-control #delete-deal-btn {
    background-color: #4d4d4d;
    margin-right: 0;
    margin-left: auto; }
    @media (max-width: 767px) {
      .deal-setup .status-control #delete-deal-btn {
        margin: 0 5px 10px; } }
    @media (max-width: 500px) {
      .deal-setup .status-control #delete-deal-btn {
        margin: 0 0 10px; } }

.deal-setup .section-button {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 11px 0;
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
  cursor: pointer; }

.deal-setup .btn-caret {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 1;
  max-width: 30px;
  width: 100%;
  height: 30px;
  margin-left: 11px;
  border-radius: 5px;
  background-color: #ce171f; }
  .deal-setup .btn-caret::after {
    content: '';
    width: 100%;
    height: 100%;
    background: url(../img/controls/arrow.svg) no-repeat center center;
    transform: rotate(90deg);
    transition: transform 0.3s ease; }
  .deal-setup .btn-caret.btn-up {
    transform: none; }
    .deal-setup .btn-caret.btn-up::after {
      transform: rotate(270deg); }

.deal-setup .section-button .title {
  font-size: 22px;
  font-weight: 400;
  margin-left: 0; }

.deal-setup .table-si .row {
  display: flex;
  flex-wrap: wrap;
  padding: 12px 14px 12px 9px; }
  .deal-setup .table-si .row:nth-child(even) {
    background-color: #f2f2f2; }
  .deal-setup .table-si .row:nth-last-child(1) {
    color: #ce171f; }
  .deal-setup .table-si .row .col {
    font-size: 16px; }
    .deal-setup .table-si .row .col:not(:last-child) {
      margin-right: 10px; }
    .deal-setup .table-si .row .col:nth-child(1) {
      width: calc(33.8215712383489% - 10px);
      font-weight: bold;
      font-size: 18px; }
    .deal-setup .table-si .row .col:nth-child(2) {
      width: calc(18.2423435419441% - 10px); }
    .deal-setup .table-si .row .col:nth-child(3) {
      width: calc(18.375499334221% - 10px); }
    .deal-setup .table-si .row .col:nth-child(4) {
      width: calc(15.4460719041278% - 10px); }
    .deal-setup .table-si .row .col:nth-child(5) {
      width: calc(14.1145139813582%); }

.deal-setup .table-si .row.headline .col {
  font-weight: 400; }

@media (max-width: 760px) {
  .deal-setup .table-si.table-programm {
    overflow-x: scroll; } }

.deal-setup .table-si.table-programm .row {
  align-items: center;
  min-height: 65px -24px; }
  @media (max-width: 760px) {
    .deal-setup .table-si.table-programm .row {
      width: fit-content; } }
  .deal-setup .table-si.table-programm .row .col:not(:last-child) {
    margin-right: 10px; }
  .deal-setup .table-si.table-programm .row .col:nth-child(1) {
    width: 43px;
    font-weight: bold;
    font-size: 18px; }
    @media (max-width: 760px) {
      .deal-setup .table-si.table-programm .row .col:nth-child(1) {
        width: 30px;
        min-width: 30px; } }
  .deal-setup .table-si.table-programm .row .col:nth-child(2) {
    width: calc(11.6758241758242% - 10px - 14.3333333333333px); }
    @media (max-width: 760px) {
      .deal-setup .table-si.table-programm .row .col:nth-child(2) {
        width: 50px;
        min-width: 50px; } }
  .deal-setup .table-si.table-programm .row .col:nth-child(3) {
    width: calc(47.6648351648352% - 10px - 14.3333333333333px); }
    @media (max-width: 760px) {
      .deal-setup .table-si.table-programm .row .col:nth-child(3) {
        width: 150px;
        min-width: 150px; } }
  .deal-setup .table-si.table-programm .row .col:nth-child(4) {
    width: calc(35.4395604395604% - 10px - 14.3333333333333px); }
    @media (max-width: 760px) {
      .deal-setup .table-si.table-programm .row .col:nth-child(4) {
        width: 150px;
        min-width: 150px; } }
  .deal-setup .table-si.table-programm .row .col input {
    display: none; }
    .deal-setup .table-si.table-programm .row .col input + label {
      display: inline-block;
      width: 20px;
      height: 20px;
      border: 1px solid #a5a5a5;
      border-radius: 50%;
      background-color: #ffffff;
      cursor: pointer;
      position: relative; }
      .deal-setup .table-si.table-programm .row .col input + label::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #124390;
        position: absolute;
        top: 4px;
        left: 4px;
        transition-duration: 0.2s;
        opacity: 0; }
    .deal-setup .table-si.table-programm .row .col input:checked + label::before {
      opacity: 1; }

@media (max-width: 500px) {
  .deal-setup .ds-inc-mis {
    width: 600px; } }

@media (max-width: 500px) {
  .deal-setup .ds-table-wrap {
    max-height: 100%;
    height: auto;
    overflow-x: scroll; } }

.deal-setup .ds-inc-mis-row {
  display: flex;
  align-items: center;
  padding: 15px 16px 15px; }
  .deal-setup .ds-inc-mis-row:nth-child(2n - 1):not(:first-child) {
    background-color: #fff; }
  .deal-setup .ds-inc-mis-row:nth-child(even) {
    background-color: #f2f2f2; }
  .deal-setup .ds-inc-mis-row.active {
    padding-top: 8px;
    padding-bottom: 7px; }
    .deal-setup .ds-inc-mis-row.active .ds-inc-mis-edit-btn {
      background-image: url(../img/save_red.svg); }
  .deal-setup .ds-inc-mis-row .ds-inc-mis-col--head {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400; }
    .deal-setup .ds-inc-mis-row .ds-inc-mis-col--head:nth-child(1) {
      width: calc(100% - 68px / 3 - 20px + 34px); }
    .deal-setup .ds-inc-mis-row .ds-inc-mis-col--head:nth-child(2) {
      width: 0; }

.deal-setup .ds-inc-mis-col:nth-child(1) {
  width: 34px; }

.deal-setup .ds-inc-mis-col:nth-child(2) {
  width: calc(60% - 68px / 3 - 20px);
  margin-right: 10px; }

.deal-setup .ds-inc-mis-col:nth-child(3) {
  width: calc(20% - 68px / 3);
  margin-right: 10px; }

.deal-setup .ds-inc-mis-col:nth-child(4) {
  width: calc(20% - 68px / 3); }

.deal-setup .ds-inc-mis-col:nth-child(5) {
  width: 34px; }

.deal-setup .ds-inc-mis--without-edit-col .ds-inc-mis-col:nth-child(2) {
  width: calc(60% - 68px / 3 - 20px + 34px);
  margin-right: 10px; }

.deal-setup .ds-inc-mis--without-edit-col .ds-inc-mis-col--head:nth-child(1) {
  width: calc(60% - 68px / 3 - 20px + 68px); }

.deal-setup .ds-inc-mis--without-edit-col .ds-inc-mis-col--head:nth-child(2) {
  width: 0; }

.deal-setup .ds-inc-mis-remove-btn {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: url(../img/cancel-btn.png) no-repeat center center;
  background-size: contain;
  cursor: pointer;
  transition-duration: 0.2s; }
  @media (min-width: 1024px) {
    .deal-setup .ds-inc-mis-remove-btn:hover {
      opacity: 0.8; } }

.deal-setup .ds-inc-mis-edit-btn {
  width: 19px;
  height: 19px;
  background: url(../img/edit-btn.png) no-repeat center center;
  background-size: contain;
  margin-left: 15px;
  cursor: pointer;
  transition-duration: .2s; }
  @media (min-width: 1024px) {
    .deal-setup .ds-inc-mis-edit-btn:hover {
      opacity: 0.8; } }

.deal-setup .ds-inc-mis-input {
  width: 116px;
  margin-bottom: 0;
  padding: 8px 9px;
  border: 1px solid #a5a5a5;
  outline: 0;
  font-size: 14px;
  line-height: 1em;
  -webkit-appearance: none;
  box-sizing: border-box; }

.deal-setup .deal-set-radiogroup {
  margin-top: 20px; }
  .deal-setup .deal-set-radiogroup .radiogroup__radio {
    max-width: calc(50% - 10px); }
  .deal-setup .deal-set-radiogroup .radiogroup__radio span {
    width: 145px;
    max-width: 100%;
    font-size: 16px;
    font-weight: 400; }

.deal-setup .ds-fin-prog-alert {
  margin-top: 15px;
  font-size: 16px;
  line-height: 1.2;
  font-weight: 400;
  color: #ce181f; }

.deal-setup .ds-submit-btn {
  font-size: 18px;
  font-weight: bold;
  margin-top: 19px; }
  @media (max-width: 500px) {
    .deal-setup .ds-submit-btn {
      width: 100%; } }

.popup-file {
  max-width: 551px;
  width: 100%; }
  .popup-file__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px; }
  .popup-file .form-block .input-block + .input-block {
    margin-top: 20px; }
  .popup-file .form-block #up-file {
    display: none; }
  .popup-file .form-block .file-name {
    display: block;
    width: 100%;
    font-size: 16px;
    text-decoration: underline;
    color: #ce181f;
    text-align: center; }
    .popup-file .form-block .file-name + .file-name {
      margin-top: 10px; }
  .popup-file .form-block label {
    margin-bottom: 0 !important; }
  .popup-file .form-block input,
  .popup-file .form-block select {
    padding: 5px 10px !important;
    height: 35px;
    margin-top: 5px; }
  .popup-file .form-block select {
    background-image: url(../img/select_hov.svg); }
  .popup-file .form-block .btn--upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 34px;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    margin-bottom: 20px !important;
    border-radius: 0;
    transition: all 0.3s ease; }
    @media (min-width: 768px) {
      .popup-file .form-block .btn--upload:hover {
        background-color: #fff;
        color: #6c757d; } }
  .popup-file .form-block .selected {
    font-size: 14px;
    text-align: center; }
  .popup-file__flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px; }
    .popup-file__flex .btn {
      max-width: 181px;
      width: 100%;
      height: 40px;
      font-size: 15px;
      line-height: 30px;
      font-weight: 400;
      border-radius: 0;
      margin-top: 0;
      transition: all 0.3s ease; }
      .popup-file__flex .btn--ok {
        background-color: #ce171f;
        border: 1px solid #ce171f;
        margin-right: 20px;
        color: #fff; }
        @media (min-width: 768px) {
          .popup-file__flex .btn--ok:hover {
            background-color: #fff;
            color: #ce171f; } }
      .popup-file__flex .btn--cancel {
        background-color: #fff;
        color: #124390;
        border: 1px solid #124390; }
        @media (min-width: 768px) {
          .popup-file__flex .btn--cancel:hover {
            background-color: #124390;
            color: #fff; } }

.delivery-mileage .inner {
  max-width: 1140px; }

.delivery-mileage .dbr {
  display: none; }
  @media (min-width: 768px) {
    .delivery-mileage .dbr {
      display: block; } }

@media (min-width: 768px) {
  .delivery-mileage .mbr {
    display: none; } }

.delivery-mileage .content--banner .inner {
  margin: 0; }
  @media (min-width: 1024px) {
    .delivery-mileage .content--banner .inner {
      margin: 0 auto; } }

.delivery-mileage .content--line .inner::after {
  content: '';
  display: block;
  width: calc(100% - 33px);
  height: 1px;
  background-color: #e6e6e6;
  margin: 48px auto 60px; }
  @media (min-width: 768px) {
    .delivery-mileage .content--line .inner::after {
      width: 100%;
      height: 2px;
      margin: 76px auto 55px; } }

.delivery-mileage .content:last-child {
  margin-bottom: 56px; }

.delivery-mileage .banner-block {
  width: 100%;
  height: 100%;
  margin-top: 6px; }
  @media (min-width: 768px) {
    .delivery-mileage .banner-block {
      margin-top: 41px; } }
  .delivery-mileage .banner-block img {
    width: 100%;
    height: 100%; }

.delivery-mileage .description {
  position: relative;
  margin-top: 39px;
  text-align: center; }
  @media (min-width: 768px) {
    .delivery-mileage .description {
      margin-top: 31px; } }
  .delivery-mileage .description p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 10px; }
  .delivery-mileage .description > p {
    font-size: 15px; }
    @media (min-width: 768px) {
      .delivery-mileage .description > p {
        font-size: 16px; } }
  .delivery-mileage .description .heading {
    font-size: 23px;
    line-height: 33px;
    letter-spacing: 0.5px;
    font-weight: bold; }
    @media (min-width: 768px) {
      .delivery-mileage .description .heading {
        font-size: 24px; } }
  .delivery-mileage .description .icon-block {
    margin-top: 36px; }
    @media (max-width: 767px) {
      .delivery-mileage .description .icon-block {
        font-size: 37px; } }
    .delivery-mileage .description .icon-block .min-heading {
      font-size: 18px;
      line-height: 33px; }
    .delivery-mileage .description .icon-block .item-block {
      margin-top: 24px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap; }
    .delivery-mileage .description .icon-block .item {
      max-width: 280px; }
      .delivery-mileage .description .icon-block .item + .item {
        margin-left: 20px; }
      .delivery-mileage .description .icon-block .item .icon {
        height: 79px;
        background-repeat: no-repeat;
        background-position: center; }
      .delivery-mileage .description .icon-block .item p {
        line-height: 22px; }
      .delivery-mileage .description .icon-block .item:nth-child(1) .icon {
        background-image: url("../images/delivery-mileage/icon-1.png"); }
      .delivery-mileage .description .icon-block .item:nth-child(2) .icon {
        background-image: url("../images/delivery-mileage/icon-2.png"); }
      .delivery-mileage .description .icon-block .item:nth-child(3) .icon {
        background-image: url("../images/delivery-mileage/icon-3.png"); }
      .delivery-mileage .description .icon-block .item:nth-child(4) .icon {
        background-image: url("../images/delivery-mileage/icon-4.png"); }
    @media (max-width: 767px) {
      .delivery-mileage .description .icon-block .item-block {
        margin-top: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
      .delivery-mileage .description .icon-block .item {
        display: flex;
        width: 100%;
        margin-top: 34px !important; }
        .delivery-mileage .description .icon-block .item + .item {
          margin-left: 0; }
        .delivery-mileage .description .icon-block .item .icon {
          width: 100%;
          max-width: 82px; }
        .delivery-mileage .description .icon-block .item p {
          margin-top: 0;
          text-align: left;
          padding: 0 0 0 27px !important; } }

.delivery-mileage .products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 18px; }
  @media (min-width: 768px) {
    .delivery-mileage .products {
      margin: 0 -15px;
      padding: 0; } }

.delivery-mileage .product {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    .delivery-mileage .product {
      width: calc(50% - 30px);
      margin: 0 15px 30px; } }
  @media (min-width: 1024px) {
    .delivery-mileage .product {
      width: calc(33.3333% - 30px); } }
  .delivery-mileage .product::before {
    content: '';
    display: block;
    width: 102%;
    height: 195px;
    background-color: #eef3fb;
    transform: skewY(6deg);
    position: absolute;
    top: -42px;
    left: 0; }
  .delivery-mileage .product__img {
    height: 200px;
    position: relative;
    padding: 22px 24px 0 12px; }
    .delivery-mileage .product__img img {
      width: 100%;
      height: 100%;
      object-fit: contain; }
  .delivery-mileage .product__info {
    padding: 0 20px;
    margin: 18px 0 21px;
    text-align: center; }
  .delivery-mileage .product__color {
    font-size: 14px;
    color: #666666; }
  .delivery-mileage .product__name {
    font-size: 18px;
    text-transform: uppercase;
    margin: 9px 0 5px; }
  .delivery-mileage .product__specification {
    font-size: 16px; }
  .delivery-mileage .product__bottom {
    padding: 0 50px 42px;
    text-align: center;
    margin-top: auto; }
  .delivery-mileage .product__from-price {
    font-size: 16px;
    color: #666666; }
  .delivery-mileage .product__price {
    font-size: 24px;
    line-height: 27px;
    color: #ce171f;
    margin-bottom: 17px; }
    .delivery-mileage .product__price span {
      font-size: 16px; }
  .delivery-mileage .product__btn {
    font-size: 18px;
    width: 100%;
    height: 46px;
    margin: 0 auto;
    border: 1px solid #124390;
    background-color: #fff;
    color: #124390;
    transition: background-color 0.3s ease, color 0.3s ease; }
    @media (min-width: 768px) {
      .delivery-mileage .product__btn:hover {
        background-color: #124390;
        color: #fff; } }
    @media (min-width: 1024px) {
      .delivery-mileage .product__btn {
        width: 250px; } }
    .delivery-mileage .product__btn:not(:first-child) {
      margin-top: 10px; }

.drilldown-log .main-flex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 1526px;
  margin: 0 auto;
  padding-top: 44px;
  padding-bottom: 100px; }
  @media (max-width: 700px) {
    .drilldown-log .main-flex {
      display: block;
      width: 100%;
      padding: 35px 15px 50px;
      box-sizing: border-box; } }

.drilldown-log .logs-menu {
  width: 217px;
  padding-left: 46px;
  margin-right: 40px;
  flex-shrink: 0; }
  @media (max-width: 700px) {
    .drilldown-log .logs-menu {
      width: 100%;
      margin: 0;
      padding: 0; } }
  .drilldown-log .logs-menu .label {
    font-size: 20px;
    font-weight: 400;
    color: #2c2c2c;
    position: relative;
    padding-bottom: 12px; }
    .drilldown-log .logs-menu .label::after {
      content: '';
      display: block;
      width: 90%;
      height: 1px;
      background-color: #ce171f;
      position: absolute;
      bottom: 0;
      left: 0; }
      @media (max-width: 700px) {
        .drilldown-log .logs-menu .label::after {
          width: 100%; } }
  .drilldown-log .logs-menu .menu-list {
    list-style: none;
    padding: 0;
    margin: 0; }
    .drilldown-log .logs-menu .menu-list li {
      margin-top: 15px; }
      .drilldown-log .logs-menu .menu-list li a {
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #2c2c2c;
        list-style: none; }
        .drilldown-log .logs-menu .menu-list li a.active {
          color: #ce171f; }
        @media (min-width: 1024px) {
          .drilldown-log .logs-menu .menu-list li a:hover {
            color: #ce171f; } }

.drilldown-log .log {
  width: auto;
  max-width: calc(100% - 303px);
  flex-grow: 1; }
  @media (max-width: 700px) {
    .drilldown-log .log {
      margin-top: 30px;
      max-width: 100%; } }
  .drilldown-log .log .filters {
    justify-content: flex-start;
    margin: 0 0 25px; }
    .drilldown-log .log .filters .input-block {
      margin: 0; }
      .drilldown-log .log .filters .input-block label {
        display: inline-block;
        line-height: 40px;
        vertical-align: top; }
      .drilldown-log .log .filters .input-block input {
        width: 200px;
        margin-left: 10px;
        padding: 9px 15px 10px; }
    .drilldown-log .log .filters .apply {
      width: 120px;
      margin: 0;
      margin-left: 15px; }
  .drilldown-log .log .filters {
    justify-content: center; }
  @media (max-width: 700px) {
    .drilldown-log .log .table-wrap {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px; } }
  .drilldown-log .log .table {
    display: table;
    width: 100%;
    box-sizing: border-box; }
    .drilldown-log .log .table .headline {
      height: 50px; }
    .drilldown-log .log .table .row {
      display: table-row; }
      .drilldown-log .log .table .row .col {
        display: table-cell;
        width: auto !important;
        padding: 9px 12px;
        vertical-align: middle; }
        .drilldown-log .log .table .row .col .name-before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 14px;
          margin-top: -2px;
          margin-right: 3px;
          vertical-align: middle;
          background: url(../img/name.png) no-repeat;
          transition-duration: 0.2s; }
        .drilldown-log .log .table .row .col .name {
          white-space: nowrap; }
        .drilldown-log .log .table .row .col .datepicker-input {
          width: 160px;
          padding: 9px 15px 10px;
          border: 1px solid #a5a5a5;
          border-radius: 0;
          outline: 0;
          background: #fff url(../img/datepicker.png) no-repeat;
          background-position: calc(100% - 9px) center;
          font-size: 16px;
          line-height: 1em;
          resize: none;
          box-sizing: border-box; }
      .drilldown-log .log .table .row.content .col {
        border-bottom: 1px solid #e6e6e6; }
  .drilldown-log .log .btns-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (max-width: 700px) {
      .drilldown-log .log .btns-row {
        display: flex;
        justify-content: center;
        margin-top: 21px; }
        .drilldown-log .log .btns-row .save-btn {
          width: 195px;
          margin: 0;
          background-color: #124390;
          font-size: 16px;
          font-weight: bold;
          color: #ffffff; }
          .drilldown-log .log .btns-row .save-btn:before {
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            margin-top: -5px;
            vertical-align: middle;
            background: url(../img/save-img.png) no-repeat center center; } }
    @media (max-width: 700px) and (min-width: 1024px) {
      .drilldown-log .log .btns-row .save-btn:hover {
        background-color: #122d5b; } }

.event-offer .eop-promotion-pic {
  width: 100%;
  margin-bottom: 45px; }
  @media (max-width: 970px) {
    .event-offer .eop-promotion-pic {
      margin-bottom: 25px; } }
  @media (max-width: 620px) {
    .event-offer .eop-promotion-pic {
      margin-bottom: 22px; } }
  .event-offer .eop-promotion-pic img {
    width: 100%;
    max-width: 100%;
    max-height: auto; }

.event-offer .eop-dear-customer__line1 {
  font-size: 18px;
  line-height: 33px;
  font-weight: 400; }
  @media (max-width: 970px) {
    .event-offer .eop-dear-customer__line1 {
      font-size: 15px; } }
  @media (max-width: 620px) {
    .event-offer .eop-dear-customer__line1 {
      font-size: 14px; } }

.event-offer .eop-dear-customer__line-2 {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  text-transform: uppercase;
  margin-bottom: 4px; }
  @media (max-width: 970px) {
    .event-offer .eop-dear-customer__line-2 {
      font-size: 22px;
      line-height: 150%;
      margin-bottom: 8px; } }
  @media (max-width: 620px) {
    .event-offer .eop-dear-customer__line-2 {
      font-size: 20px;
      line-height: 150%;
      margin-bottom: 8px; } }

.event-offer .eop-dear-customer__description {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px; }
  @media (max-width: 970px) {
    .event-offer .eop-dear-customer__description {
      font-size: 15px;
      line-height: 150%; } }
  @media (max-width: 620px) {
    .event-offer .eop-dear-customer__description {
      font-size: 14px;
      line-height: 150%; } }

.event-offer .eop-dear-customer__line-3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 33px;
  margin-top: 30px; }
  @media (max-width: 970px) {
    .event-offer .eop-dear-customer__line-3 {
      font-size: 18px;
      line-height: 150%;
      margin-top: 15px; } }
  @media (max-width: 620px) {
    .event-offer .eop-dear-customer__line-3 {
      font-size: 14px;
      line-height: 150%;
      margin-top: 15px; } }

.event-offer .eop-cards-container {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 50px; }
  @media (max-width: 850px) {
    .event-offer .eop-cards-container {
      flex-wrap: wrap; } }
  @media (max-width: 580px) {
    .event-offer .eop-cards-container {
      justify-content: center;
      margin-bottom: 20px; } }

@media (min-width: 851px) {
  .event-offer .eop-cards-container--fourth {
    margin-bottom: -30px; } }

.event-offer .eop-cards-container--fourth .eop-card {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0; }
  @media (min-width: 851px) {
    .event-offer .eop-cards-container--fourth .eop-card {
      width: calc(25% - 37px * 2 / 3); } }
  .event-offer .eop-cards-container--fourth .eop-card__bg {
    display: none; }

.event-offer .eop-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -13px; }
  @media (max-width: 620px) {
    .event-offer .eop-cards {
      margin: 0; } }

.event-offer .eop-card-item {
  width: calc(33.3333% - 26px);
  margin: 0 13px 26px;
  border: 1px solid #e6e6e6;
  box-sizing: border-box; }

@media (min-width: 1024px) and (min-width: 1024px) {
  .event-offer .eop-card-item:hover .eop-card-item__bottom {
    background-color: #eae9e9; } }
  @media (max-width: 850px) {
    .event-offer .eop-card-item {
      width: calc(50% - 26px); } }
  @media (max-width: 620px) {
    .event-offer .eop-card-item {
      width: 100%;
      margin: 0 0 20px; } }
  .event-offer .eop-card-item__pic {
    width: 100%;
    height: 239px;
    padding: 10px;
    box-sizing: border-box; }
    .event-offer .eop-card-item__pic img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      font-family: 'object-fit: contain;'; }
  .event-offer .eop-card-item__title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin: 19px 0; }
  .event-offer .eop-card-item__bottom {
    padding: 15px;
    background-color: #f6f6f6;
    transition: background-color 0.3s ease; }
    .event-offer .eop-card-item__bottom .btn {
      max-width: 260px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      font-size: 16px; }

.event-offer .eop-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(33.33333% - 37px * 2 / 3);
  margin: 18.5px 0;
  padding: 15px 35px 30px;
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
  text-align: center;
  position: relative;
  z-index: 1;
  box-sizing: border-box; }
  @media (max-width: 850px) {
    .event-offer .eop-card {
      width: calc(50% - 37px / 2); } }
  @media (max-width: 580px) {
    .event-offer .eop-card {
      width: 100%;
      max-width: 400px;
      margin: 8px 0; } }
  .event-offer .eop-card__pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1; }
    .event-offer .eop-card__pic img {
      width: 100%;
      max-width: 271px; }
  .event-offer .eop-card .eop-card__bg {
    width: 100%;
    height: 30%;
    background-color: #eef3fb;
    position: absolute;
    top: 20%;
    left: 0;
    z-index: -1; }
  .event-offer .eop-card__title {
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
    color: #2c2c2c; }
    @media (max-width: 620px) {
      .event-offer .eop-card__title {
        font-size: 20px; } }
  .event-offer .eop-card__desc {
    font-size: 12px;
    line-height: 28px;
    font-weight: 400;
    text-transform: uppercase; }
    .event-offer .eop-card__desc span {
      font-size: 20px;
      color: #ce171f; }

.event-offer .eop-text p {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  margin-bottom: 33px; }
  @media (max-width: 970px) {
    .event-offer .eop-text p {
      font-size: 15px;
      line-height: 150%;
      font-weight: 400;
      margin-bottom: 20px; } }
  @media (max-width: 620px) {
    .event-offer .eop-text p {
      font-size: 14px;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 15px; } }

.event-offer .eop-text ul {
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  margin-bottom: 45px; }
  @media (max-width: 970px) {
    .event-offer .eop-text ul {
      margin-bottom: 25px; } }
  @media (max-width: 620px) {
    .event-offer .eop-text ul {
      margin-bottom: 15px; } }
  .event-offer .eop-text ul li {
    margin-bottom: 10px;
    padding-left: 18px;
    position: relative; }
    @media (max-width: 970px) {
      .event-offer .eop-text ul li {
        margin-bottom: 4px; } }
    @media (max-width: 620px) {
      .event-offer .eop-text ul li {
        margin-bottom: 4px; } }
    .event-offer .eop-text ul li::before {
      content: '';
      display: inline-block;
      width: 5px;
      height: 5px;
      background-color: #ce171f;
      position: absolute;
      left: 4px;
      top: 8px; }

.event-offer .eop-text h3 {
  font-size: 20px;
  line-height: 33px;
  font-weight: bold;
  margin-bottom: 20px; }
  @media (max-width: 970px) {
    .event-offer .eop-text h3 {
      font-size: 17px;
      line-height: 150%;
      margin-bottom: 10px; } }
  @media (max-width: 620px) {
    .event-offer .eop-text h3 {
      font-size: 16px;
      line-height: 150%;
      margin-bottom: 10px; } }
  .event-offer .eop-text h3 + .eop-red-par {
    margin-top: -20px; }
    @media (max-width: 970px) {
      .event-offer .eop-text h3 + .eop-red-par {
        margin-top: 0; } }

.event-offer .eop-red-par {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-transform: uppercase;
  color: #ce171f; }

.event-offer .eop-call-to-us {
  width: 100%;
  padding: 30px 20px;
  margin: 30px 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  box-sizing: border-box; }
  .event-offer .eop-call-to-us p span {
    font-size: 22px;
    font-weight: 400;
    line-height: 24px; }
    .event-offer .eop-call-to-us p span::before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      margin: 0 10px;
      background: url(../img/err.svg) no-repeat center center;
      background-size: contain; }

.event-offer .form-block--eop {
  width: 756px;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 100px; }
  .event-offer .form-block--eop .red {
    color: #ce181f;
    font-weight: bold; }
  .event-offer .form-block--eop .title {
    font-size: 20px;
    line-height: 33px;
    font-weight: bold;
    text-align: center; }
  .event-offer .form-block--eop .ah2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 5px; }
  .event-offer .form-block--eop .row2 {
    display: flex; }
    .event-offer .form-block--eop .row2 .input-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 490px;
      max-width: 100%;
      margin: 0 auto; }
      @media (max-width: 620px) {
        .event-offer .form-block--eop .row2 .input-block {
          flex-direction: column; } }
      .event-offer .form-block--eop .row2 .input-block .radio-btn {
        display: flex;
        align-items: center; }
        @media (max-width: 620px) {
          .event-offer .form-block--eop .row2 .input-block .radio-btn {
            margin-bottom: 10px; } }
      .event-offer .form-block--eop .row2 .input-block .radio-label {
        margin-bottom: 0; }
      .event-offer .form-block--eop .row2 .input-block .radio-btn + .radio-btn {
        margin-top: unset; }
  .event-offer .form-block--eop .row {
    display: flex;
    justify-content: space-between;
    margin-top: 30px; }
    @media (max-width: 620px) {
      .event-offer .form-block--eop .row {
        display: block;
        margin-top: 5px; } }
    .event-offer .form-block--eop .row .input-block {
      width: calc(33.3333333% - 16px * 2 / 3); }
      @media (max-width: 620px) {
        .event-offer .form-block--eop .row .input-block {
          width: 100%;
          margin-bottom: 10px; } }
  .event-offer .form-block--eop .btn {
    width: 266px;
    height: 46px;
    margin: 0 auto;
    margin-top: 40px; }

.event-offer .form-after-submit {
  text-align: center; }
  .event-offer .form-after-submit .btn {
    margin-left: auto;
    margin-right: auto; }

.exclusive-event .inner--main {
  max-width: 1140px;
  margin: 20px auto 45px; }
  @media (min-width: 1024px) {
    .exclusive-event .inner--main {
      flex-direction: row;
      margin: 39px auto 118px; } }
  .exclusive-event .inner--main .title {
    text-transform: uppercase; }

.exclusive-event__banner img {
  width: 100%;
  height: 100%; }

.exclusive-event__wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: 18px; }
  @media (min-width: 1024px) {
    .exclusive-event__wrapper {
      flex-direction: row;
      margin: 18px -20px 0; } }

.exclusive-event__video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.4%; }
  @media (min-width: 1024px) {
    .exclusive-event__video {
      width: calc(68% - 40px);
      margin: 0 20px;
      padding-bottom: 36.4%; } }
  .exclusive-event__video iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0; }

.exclusive-event__video-preview {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer; }
  .exclusive-event__video-preview img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.exclusive-event__content {
  width: 100%;
  margin-top: 35px; }
  @media (min-width: 1024px) {
    .exclusive-event__content {
      width: calc(32% - 40px);
      margin: 0 20px; } }

.exclusive-event__content-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  margin-top: -7px;
  margin-bottom: 15px; }

.exclusive-event__content-text {
  font-size: 16px;
  line-height: 24px; }
  .exclusive-event__content-text p + p {
    padding-top: 23px; }

.exclusive-event__links {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 53px; }
  @media (min-width: 1024px) {
    .exclusive-event__links {
      flex-direction: row; } }
  .exclusive-event__links a {
    font-size: 18px;
    color: #ce171f;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 30px; }
    @media (min-width: 1024px) {
      .exclusive-event__links a {
        margin-bottom: 0; } }
    .exclusive-event__links a:hover {
      text-decoration: none; }
    @media (min-width: 1024px) {
      .exclusive-event__links a + a {
        margin-left: 95px; } }

.export-to-feeds .form-block .input-block .radio-btn + .radio-btn {
  margin-top: 0; }

.export-to-feeds .form-block .input-block select {
  max-width: 100%;
  width: 300px;
  margin-bottom: 15px; }
  @media (max-width: 500px) {
    .export-to-feeds .form-block .input-block select {
      width: 100%; } }

.export-to-feeds .form-title {
  font-size: 24px;
  line-height: 1.3; }
  @media (max-width: 500px) {
    .export-to-feeds .form-title {
      font-size: 20px;
      margin-bottom: 10px; } }

.export-to-feeds .radio-btn {
  display: flex; }

.export-to-feeds .extf-btn-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .export-to-feeds .extf-btn-row .btn {
    width: calc(50% - 15px);
    max-width: 100% !important; }
    @media (max-width: 500px) {
      .export-to-feeds .extf-btn-row .btn {
        width: 100%;
        margin-top: 10px; } }

.export-to-feeds .extf-gforce {
  margin-top: 50px; }
  @media (max-width: 500px) {
    .export-to-feeds .extf-gforce {
      margin-top: 30px; } }

.manager-contact {
  margin-bottom: 59px;
  /* global-manager-contacts */ }
  .manager-contact .inner {
    max-width: 1280px; }
  .manager-contact .profile-block {
    margin-top: 56px; }
    .manager-contact .profile-block > div {
      line-height: 33px;
      color: #2c2c2c; }
    .manager-contact .profile-block .customer {
      font-size: 20px; }
      .manager-contact .profile-block .customer a {
        color: #ce171f;
        font-size: 24px; }
    .manager-contact .profile-block .phone {
      font-size: 16px; }
      .manager-contact .profile-block .phone a {
        font-size: 18px;
        color: #2c2c2c;
        margin-left: 15px;
        font-weight: 600; }
    .manager-contact .profile-block .mail {
      font-size: 16px; }
      .manager-contact .profile-block .mail a {
        font-size: 18px;
        color: #2c2c2c;
        margin-left: 22px;
        font-weight: 600; }
  .manager-contact .tabs {
    display: flex;
    flex-wrap: wrap;
    min-width: 290px;
    padding: 0;
    margin: 0 auto;
    margin-top: 29px; }
    .manager-contact .tabs > section {
      display: none;
      background: #fff; }
    .manager-contact .tabs > input {
      display: none;
      position: absolute; }
      .manager-contact .tabs > input:checked + label {
        background-color: #CE171F;
        color: #fff;
        border-color: #CE171F; }
    .manager-contact .tabs > label {
      display: block;
      min-width: 150px;
      margin: 0;
      border: 1px solid #D9D9D9;
      background: transparent;
      font-size: 16px;
      line-height: 38px;
      font-weight: bold;
      text-align: center;
      color: #2c2c2c;
      box-sizing: border-box; }
      @media (max-width: 700px) {
        .manager-contact .tabs > label {
          width: 100%;
          margin-bottom: 5px; } }
    .manager-contact .tabs > .line {
      width: 100%;
      height: 1px;
      background-color: #CE171F; }
      @media (max-width: 700px) {
        .manager-contact .tabs > .line {
          display: none; } }
  .manager-contact #tab1:checked ~ #content-tab1,
  .manager-contact #tab2:checked ~ #content-tab2,
  .manager-contact #tab3:checked ~ #content-tab3,
  .manager-contact #tab4:checked ~ #content-tab4 {
    display: flex; }
  .manager-contact .control-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 29px 0 20px; }
    @media (max-width: 800px) {
      .manager-contact .control-block {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 !important; } }
    .manager-contact .control-block > div {
      margin-right: 30px; }
      @media (max-width: 800px) {
        .manager-contact .control-block > div {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          box-sizing: border-box; } }
      .manager-contact .control-block > div:first-child {
        margin-right: auto; }
        @media (max-width: 800px) {
          .manager-contact .control-block > div:first-child {
            margin-right: 0; } }
      .manager-contact .control-block > div:last-child {
        margin-right: 0; }
    .manager-contact .control-block .new-message-button,
    .manager-contact .control-block .new-sms-button {
      padding: 5px 34px 5px 15px;
      background-color: #124390;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      text-align: center;
      cursor: pointer; }
      .manager-contact .control-block .new-message-button::before,
      .manager-contact .control-block .new-sms-button::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 16px;
        margin-right: 15px;
        background-image: url(../img/message-icon-2.png);
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: middle; }
    .manager-contact .control-block .search {
      display: flex;
      align-items: center;
      font-size: 18px; }
      @media (max-width: 800px) {
        .manager-contact .control-block .search {
          justify-content: space-between; } }
      .manager-contact .control-block .search select {
        width: 180px;
        padding: 10px 15px 12px 15px;
        margin-left: 13px;
        outline: 0;
        border: 1px solid #a5a5a5;
        border-radius: 0;
        font-size: 16px;
        line-height: 1em;
        background: #fff url(../img/select_bg.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        box-sizing: border-box;
        resize: none;
        appearance: none; }
    .manager-contact .control-block .filter-button a {
      display: block;
      padding: 0 41px 0 42px;
      background-color: #CE171F;
      font-size: 16px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      cursor: pointer; }
    @media (max-width: 800px) {
      .manager-contact .control-block .reset-button {
        width: auto;
        text-align: center; } }
    .manager-contact .control-block .reset-button a {
      display: block;
      font-size: 16px;
      line-height: 28px;
      color: #ce171f;
      position: relative; }
      .manager-contact .control-block .reset-button a::before {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #ce171f;
        position: absolute;
        bottom: 2px;
        left: 0; }
  .manager-contact .table {
    border: 1px solid #E6E6E6;
    box-sizing: border-box; }
    .manager-contact .table tbody {
      width: 100%; }
  .manager-contact .new-message-button {
    transition: 0.3s; }
    @media (min-width: 1024px) {
      .manager-contact .new-message-button:hover {
        filter: brightness(1.2); } }
  .manager-contact .new-sms-button {
    transition: 0.3s; }
    @media (min-width: 1024px) {
      .manager-contact .new-sms-button:hover {
        filter: brightness(1.2); } }
  .manager-contact #content-tab3 .phone {
    transition: 0.3s; }
    @media (min-width: 1024px) {
      .manager-contact #content-tab3 .phone:hover {
        filter: brightness(1.2); } }
  .manager-contact .send {
    transition: 0.3s; }
    @media (min-width: 1024px) {
      .manager-contact .send:hover {
        filter: brightness(1.2); } }
  .manager-contact .filter-button a {
    transition: 0s; }
    @media (min-width: 1024px) {
      .manager-contact .filter-button a:hover {
        background-color: #841215; } }
  .manager-contact #content-tab1 {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
    .manager-contact #content-tab1 .new-message-block {
      width: 100%; }
      .manager-contact #content-tab1 .new-message-block .receiver {
        max-width: 412px;
        width: 98%;
        margin-top: 20px; }
        @media (max-width: 800px) {
          .manager-contact #content-tab1 .new-message-block .receiver {
            width: 100%;
            max-width: none; } }
      .manager-contact #content-tab1 .new-message-block .subject,
      .manager-contact #content-tab1 .new-message-block .text-message {
        margin-top: 10px; }
      .manager-contact #content-tab1 .new-message-block label,
      .manager-contact #content-tab1 .new-message-block input,
      .manager-contact #content-tab1 .new-message-block textarea {
        display: block;
        font-size: 16px;
        line-height: 28px; }
      .manager-contact #content-tab1 .new-message-block input,
      .manager-contact #content-tab1 .new-message-block textarea {
        width: 100%;
        padding: 6px 16px;
        border: 1px solid #A5A5A5;
        box-sizing: border-box; }
      .manager-contact #content-tab1 .new-message-block textarea {
        min-height: 500px; }
        @media (max-width: 800px) {
          .manager-contact #content-tab1 .new-message-block textarea {
            min-height: 120px; } }
      .manager-contact #content-tab1 .new-message-block .load-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 430px;
        margin-top: 19px; }
        @media (max-width: 800px) {
          .manager-contact #content-tab1 .new-message-block .load-block {
            flex-wrap: wrap;
            max-width: none;
            width: 100%; } }
        .manager-contact #content-tab1 .new-message-block .load-block .file-name {
          color: #4c4c4c;
          margin-right: auto; }
        .manager-contact #content-tab1 .new-message-block .load-block .close {
          width: 16px;
          height: 16px;
          margin-left: 25px;
          margin-bottom: -10px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(../img/load-close.png);
          cursor: pointer; }
        .manager-contact #content-tab1 .new-message-block .load-block .load {
          width: 100%;
          max-width: 388px;
          height: 3px;
          background-color: #CCCCCC; }
          @media (max-width: 800px) {
            .manager-contact #content-tab1 .new-message-block .load-block .load {
              max-width: none;
              width: 100%;
              margin-top: 10px; } }
          .manager-contact #content-tab1 .new-message-block .load-block .load span {
            display: block;
            height: 3px;
            position: absolute;
            left: 0;
            top: 0; }
      .manager-contact #content-tab1 .new-message-block .file-block {
        margin-bottom: 60px; }
        @media (max-width: 800px) {
          .manager-contact #content-tab1 .new-message-block .file-block {
            margin-bottom: 0; } }
        .manager-contact #content-tab1 .new-message-block .file-block .attach-button {
          display: block;
          margin-top: 20px;
          margin-bottom: 20px;
          background: transparent;
          color: #ce171f;
          position: relative; }
          .manager-contact #content-tab1 .new-message-block .file-block .attach-button::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            width: 11px;
            height: 22px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url(../img/attach-icon.png);
            margin-right: 10px; }
          .manager-contact #content-tab1 .new-message-block .file-block .attach-button::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 4px;
            background-color: #CE171F;
            width: calc(100% - 21px);
            height: 1px;
            margin-left: 21px; }
        .manager-contact #content-tab1 .new-message-block .file-block .send {
          max-width: 242px;
          width: 98%;
          background-color: #124390;
          text-align: center;
          color: #fff;
          font-size: 16px;
          line-height: 40px; }
          @media (max-width: 800px) {
            .manager-contact #content-tab1 .new-message-block .file-block .send {
              width: 100%;
              max-width: none; } }
    .manager-contact #content-tab1 .new-message-block:not(.active) {
      display: none; }
    .manager-contact #content-tab1 .new-message-button.active {
      visibility: hidden; }
    .manager-contact #content-tab1 .table {
      width: 100%;
      position: relative; }
      @media (max-width: 900px) {
        .manager-contact #content-tab1 .table {
          display: flex;
          flex-wrap: wrap; } }
      .manager-contact #content-tab1 .table tr {
        font-size: 14px;
        position: relative;
        cursor: pointer; }
        @media (max-width: 900px) {
          .manager-contact #content-tab1 .table tr {
            display: flex;
            flex-direction: column;
            width: 100%; } }
        .manager-contact #content-tab1 .table tr td {
          height: 65px;
          padding: 0 10px;
          padding-top: 16px; }
          @media (max-width: 900px) {
            .manager-contact #content-tab1 .table tr td {
              height: auto; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab1 .table tr td:first-child {
              padding-top: 15px !important; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab1 .table tr td:last-child {
              padding-bottom: 15px !important; } }
        .manager-contact #content-tab1 .table tr:first-child {
          min-height: 40px;
          justify-items: center;
          background-color: #CE171F !important;
          font-size: 16px;
          color: #fff; }
          @media (max-width: 900px) {
            .manager-contact #content-tab1 .table tr:first-child {
              display: none; } }
          .manager-contact #content-tab1 .table tr:first-child td,
          .manager-contact #content-tab1 .table tr:first-child th {
            height: 40px;
            padding: 0 10px;
            padding-top: 11px;
            white-space: nowrap; }
            .manager-contact #content-tab1 .table tr:first-child td:last-child::after,
            .manager-contact #content-tab1 .table tr:first-child th:last-child::after {
              display: none; }
        .manager-contact #content-tab1 .table tr:nth-child(4n) {
          background-color: #E8F6FF; }
        .manager-contact #content-tab1 .table tr:nth-child(4n+1) {
          background-color: #E8F6FF; }
        .manager-contact #content-tab1 .table tr .table-item {
          box-sizing: border-box;
          text-align: left; }
          @media (max-width: 900px) {
            .manager-contact #content-tab1 .table tr .table-item {
              width: 100% !important;
              padding: 2px 10px !important; } }
          .manager-contact #content-tab1 .table tr .table-item a {
            color: #2c2c2c; }
          .manager-contact #content-tab1 .table tr .table-item:nth-child(1) {
            padding-left: 22px; }
          .manager-contact #content-tab1 .table tr .table-item:last-child {
            padding-right: 30px;
            position: relative; }
            .manager-contact #content-tab1 .table tr .table-item:last-child a {
              display: block;
              visibility: hidden; }
            .manager-contact #content-tab1 .table tr .table-item:last-child:after {
              content: '';
              display: block;
              width: 13px;
              height: 8px;
              margin-top: 21px;
              position: absolute;
              right: 14px;
              top: -1px;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url(../img/accordion-arrow.svg);
              transform: rotate(180deg);
              cursor: pointer; }
          .manager-contact #content-tab1 .table tr .table-item.date::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 5px;
            margin-right: 8px;
            margin-left: -4px;
            vertical-align: middle;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../img/input-plus.png); }
      .manager-contact #content-tab1 .table .hide-block {
        position: relative;
        display: none; }
        .manager-contact #content-tab1 .table .hide-block td {
          padding: 16px 10px;
          position: relative; }
          .manager-contact #content-tab1 .table .hide-block td .line {
            width: 100%;
            height: 1px;
            background-color: #C5D8E5; }
          .manager-contact #content-tab1 .table .hide-block td p {
            line-height: 20px;
            margin-top: 27px;
            padding-right: 16%; }
            .manager-contact #content-tab1 .table .hide-block td p:first-child {
              margin-top: 16px; }
          .manager-contact #content-tab1 .table .hide-block td a {
            color: #2c2c2c;
            display: inline-block;
            position: relative; }
            .manager-contact #content-tab1 .table .hide-block td a.file:after {
              content: '';
              width: 100%;
              position: absolute;
              height: 1px;
              background-color: #2c2c2c;
              left: 0;
              bottom: 0; }
          .manager-contact #content-tab1 .table .hide-block td .text {
            display: flex;
            flex-direction: column;
            max-width: 268px;
            padding: 25px 0;
            margin-top: 18px;
            border-top: 1px dashed #5B5F61;
            line-height: 20px; }
          .manager-contact #content-tab1 .table .hide-block td .reply {
            display: inline-block;
            font-size: 18px;
            line-height: 28px;
            color: #ce171f;
            position: relative; }
            .manager-contact #content-tab1 .table .hide-block td .reply::after {
              content: '';
              display: block;
              width: 100%;
              height: 1px;
              background-color: #CE171F;
              position: absolute;
              left: 0;
              bottom: 4px; }
          .manager-contact #content-tab1 .table .hide-block td .hide-button {
            position: absolute;
            right: 35px;
            bottom: 35px;
            cursor: pointer; }
            .manager-contact #content-tab1 .table .hide-block td .hide-button::before {
              content: '';
              display: block;
              width: 100%;
              height: 1px;
              background-color: #2c2c2c;
              position: absolute;
              bottom: 0;
              left: 0; }
            .manager-contact #content-tab1 .table .hide-block td .hide-button::after {
              content: '';
              display: block;
              width: 13px;
              height: 8px;
              position: absolute;
              right: -22px;
              bottom: 5px;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url(../img/accordion-arrow.svg);
              transform: rotate(0deg);
              cursor: pointer; }
    @media (max-width: 1221px) {
      .manager-contact #content-tab1 .table-email-gms .table {
        display: flex;
        flex-wrap: wrap; }
        .manager-contact #content-tab1 .table-email-gms .table tr {
          flex-direction: column;
          width: 100%;
          display: flex; }
          .manager-contact #content-tab1 .table-email-gms .table tr:first-child {
            display: none; }
          .manager-contact #content-tab1 .table-email-gms .table tr .table-item {
            width: 100% !important;
            padding: 2px 10px !important; }
        .manager-contact #content-tab1 .table-email-gms .table .table tr td:first-child {
          padding-top: 15px !important; }
        .manager-contact #content-tab1 .table-email-gms .table .table tr td:last-child {
          padding-bottom: 15px !important; } }
    @media (max-width: 1221px) {
      .manager-contact #content-tab1 .table-email-gms .table tr .table-item:last-child::after {
        top: -15px; } }
    .manager-contact #content-tab1 .table-email-gms .table tr.hide-block td {
      width: 100%; }
    .manager-contact #content-tab1 .table-email-gms .table tr td {
      padding-bottom: 8px; }
      @media (max-width: 1221px) {
        .manager-contact #content-tab1 .table-email-gms .table tr td {
          height: auto; } }
  .manager-contact .accordion.active + .hide-block {
    display: table-row !important; }
  .manager-contact .accordion.active .table-item:last-child::after {
    transform: rotate(0deg) !important; }
  .manager-contact .accordion.active .table-item:last-child a {
    visibility: visible !important; }
  .manager-contact #content-tab2 {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
    @media (max-width: 800px) {
      .manager-contact #content-tab2 .new-sms .file-block {
        margin-bottom: 0; } }
    .manager-contact #content-tab2 .new-sms-block {
      width: 100%; }
      .manager-contact #content-tab2 .new-sms-block .receiver {
        max-width: 412px;
        width: 98%;
        margin-top: 20px; }
        @media (max-width: 800px) {
          .manager-contact #content-tab2 .new-sms-block .receiver {
            width: 100%;
            max-width: none; } }
      .manager-contact #content-tab2 .new-sms-block .text-message {
        margin-top: 10px; }
      .manager-contact #content-tab2 .new-sms-block span {
        color: #999999; }
      .manager-contact #content-tab2 .new-sms-block label,
      .manager-contact #content-tab2 .new-sms-block input,
      .manager-contact #content-tab2 .new-sms-block textarea {
        display: block;
        font-size: 16px;
        line-height: 28px; }
      .manager-contact #content-tab2 .new-sms-block input,
      .manager-contact #content-tab2 .new-sms-block textarea {
        width: 100%;
        padding: 6px 16px;
        border: 1px solid #A5A5A5;
        box-sizing: border-box; }
      .manager-contact #content-tab2 .new-sms-block textarea {
        min-height: 124px; }
      .manager-contact #content-tab2 .new-sms-block .send {
        max-width: 242px;
        width: 98%;
        margin-top: 25px;
        background-color: #124390;
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        color: #fff; }
        @media (max-width: 800px) {
          .manager-contact #content-tab2 .new-sms-block .send {
            width: 100%;
            max-width: none; } }
    .manager-contact #content-tab2 .new-sms-button::before {
      width: 19px;
      background-image: url(../img/sms-icon.png); }
    .manager-contact #content-tab2 .new-sms-block:not(.active) {
      display: none; }
    .manager-contact #content-tab2 .new-sms-button.active {
      visibility: hidden; }
    .manager-contact #content-tab2 .table {
      width: 100%; }
      @media (max-width: 900px) {
        .manager-contact #content-tab2 .table {
          display: flex;
          flex-wrap: wrap; } }
      .manager-contact #content-tab2 .table tr {
        font-size: 14px;
        cursor: pointer;
        text-align: center; }
        @media (max-width: 900px) {
          .manager-contact #content-tab2 .table tr {
            display: flex;
            flex-direction: column;
            width: 100%;
            border-bottom: 1px solid #D9D9D9; } }
        .manager-contact #content-tab2 .table tr td {
          height: 65px;
          padding: 0 10px;
          padding-top: 16px; }
          @media (max-width: 900px) {
            .manager-contact #content-tab2 .table tr td {
              height: auto; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab2 .table tr td:first-child {
              padding-top: 15px !important; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab2 .table tr td:last-child {
              padding-bottom: 15px !important; } }
        @media (min-width: 1024px) {
          .manager-contact #content-tab2 .table tr:hover {
            background-color: #E8F6FF; } }
        .manager-contact #content-tab2 .table tr:first-child {
          justify-items: center;
          min-height: 40px;
          background-color: #CE171F !important;
          font-size: 16px;
          color: #fff; }
          @media (max-width: 900px) {
            .manager-contact #content-tab2 .table tr:first-child {
              display: none; } }
          .manager-contact #content-tab2 .table tr:first-child td,
          .manager-contact #content-tab2 .table tr:first-child th {
            height: 40px;
            padding: 0 10px;
            padding-top: 11px;
            white-space: nowrap; }
        .manager-contact #content-tab2 .table tr .table-item {
          box-sizing: border-box;
          text-align: left; }
          @media (max-width: 900px) {
            .manager-contact #content-tab2 .table tr .table-item {
              width: 100% !important;
              padding: 2px 10px !important; } }
          .manager-contact #content-tab2 .table tr .table-item:nth-child(1) {
            padding-left: 22px; }
          .manager-contact #content-tab2 .table tr .table-item:last-child {
            padding-right: 22px; }
          .manager-contact #content-tab2 .table tr .table-item.date:before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 5px;
            margin-right: 8px;
            margin-left: -4px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../img/input-plus.png);
            vertical-align: middle; }
    @media (max-width: 900px) {
      .manager-contact #content-tab2 .control-block {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 !important; }
        .manager-contact #content-tab2 .control-block > div {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          box-sizing: border-box; }
          .manager-contact #content-tab2 .control-block > div:first-child {
            margin-right: 0; }
        .manager-contact #content-tab2 .control-block .search {
          justify-content: space-between; }
        .manager-contact #content-tab2 .control-block .reset-button {
          text-align: center;
          width: auto; } }
  .manager-contact #content-tab3 {
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%; }
    .manager-contact #content-tab3 .phone {
      padding: 5px 34px 5px 15px;
      margin-right: auto;
      background-color: #124390;
      font-size: 16px;
      line-height: 28px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box; }
      @media (max-width: 900px) {
        .manager-contact #content-tab3 .phone {
          width: 100%;
          margin-bottom: 10px; } }
      .manager-contact #content-tab3 .phone::before {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 15px;
        background-image: url(../img/phone-icon-2.png);
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: middle; }
    .manager-contact #content-tab3 .table {
      width: 100%; }
      @media (max-width: 900px) {
        .manager-contact #content-tab3 .table {
          display: flex;
          flex-wrap: wrap; } }
      .manager-contact #content-tab3 .table tr {
        font-size: 14px;
        position: relative;
        cursor: pointer; }
        @media (max-width: 900px) {
          .manager-contact #content-tab3 .table tr {
            display: flex;
            flex-direction: column;
            width: 100%; } }
        .manager-contact #content-tab3 .table tr td {
          height: 65px;
          padding: 0 10px;
          padding-top: 16px; }
          @media (max-width: 900px) {
            .manager-contact #content-tab3 .table tr td {
              height: auto; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab3 .table tr td:first-child {
              padding-top: 15px !important; } }
          @media (max-width: 900px) {
            .manager-contact #content-tab3 .table tr td:last-child {
              padding-bottom: 15px !important; } }
        @media (min-width: 1024px) {
          .manager-contact #content-tab3 .table tr:hover {
            background-color: #E8F6FF; } }
        .manager-contact #content-tab3 .table tr:first-child {
          justify-items: center;
          min-height: 40px;
          background-color: #CE171F !important;
          font-size: 16px;
          color: #fff; }
          @media (max-width: 900px) {
            .manager-contact #content-tab3 .table tr:first-child {
              display: none; } }
          .manager-contact #content-tab3 .table tr:first-child td,
          .manager-contact #content-tab3 .table tr:first-child th {
            height: 40px;
            padding: 0 10px;
            padding-top: 11px;
            white-space: nowrap; }
        .manager-contact #content-tab3 .table tr .table-item {
          box-sizing: border-box;
          text-align: left; }
          @media (max-width: 900px) {
            .manager-contact #content-tab3 .table tr .table-item {
              width: 100% !important;
              padding: 2px 10px !important; } }
          .manager-contact #content-tab3 .table tr .table-item:nth-child(1) {
            padding-left: 22px; }
          .manager-contact #content-tab3 .table tr .table-item:last-child {
            padding-right: 22px; }
          .manager-contact #content-tab3 .table tr .table-item.date::before {
            content: '';
            display: inline-block;
            width: 10px;
            height: 5px;
            margin-right: 8px;
            margin-left: -4px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url(../img/input-plus.png);
            vertical-align: middle; }
    @media (max-width: 900px) {
      .manager-contact #content-tab3 .control-block {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 !important; }
        .manager-contact #content-tab3 .control-block > div {
          width: 100%;
          margin-right: 0;
          margin-bottom: 10px;
          box-sizing: border-box; }
          .manager-contact #content-tab3 .control-block > div:first-child {
            margin-right: 0; }
        .manager-contact #content-tab3 .control-block .search {
          justify-content: space-between; }
        .manager-contact #content-tab3 .control-block .reset-button {
          text-align: center;
          width: auto; } }
  .manager-contact #content-tab4 {
    width: 100%; }
    .manager-contact #content-tab4 .chat-block {
      width: 100%; }
    .manager-contact #content-tab4 .chat {
      padding: 27px 24px 36px 26px;
      margin-top: 30px;
      border: 1px solid #d7d7d7;
      background-color: transparent; }
      .manager-contact #content-tab4 .chat > .title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .manager-contact #content-tab4 .chat .messages-wrap {
        max-height: 333px; }
        .manager-contact #content-tab4 .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
          right: -12px; }
        .manager-contact #content-tab4 .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
          width: 2px;
          background-color: #d9d9d9;
          border-radius: 0;
          cursor: pointer; }
        .manager-contact #content-tab4 .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
          width: 4px;
          background-color: #d4141c;
          border-radius: 0; }
      .manager-contact #content-tab4 .chat .messages {
        display: flex;
        flex-direction: column; }
        .manager-contact #content-tab4 .chat .messages .messages_message {
          min-width: 1070px;
          width: fit-content;
          padding-left: 50px;
          padding-bottom: 20px;
          font-size: 14px; }
          @media (max-width: 1200px) {
            .manager-contact #content-tab4 .chat .messages .messages_message {
              min-width: auto; } }
          @media (max-width: 700px) {
            .manager-contact #content-tab4 .chat .messages .messages_message {
              padding-left: 15px; } }
          .manager-contact #content-tab4 .chat .messages .messages_message .title {
            color: #a2a2a2;
            padding-bottom: 10px; }
            .manager-contact #content-tab4 .chat .messages .messages_message .title span {
              font-weight: 600; }
          .manager-contact #content-tab4 .chat .messages .messages_message .text {
            padding: 16px 20px 18px 17px;
            background-color: #F7F7F7;
            border: 1px solid #E6E6E6; }
        .manager-contact #content-tab4 .chat .messages .messages_message-manager {
          align-self: flex-start; }
          .manager-contact #content-tab4 .chat .messages .messages_message-manager .title span {
            color: #ce171f; }
        .manager-contact #content-tab4 .chat .messages .messages_message-your {
          align-self: flex-start;
          min-width: 1070px;
          padding-left: 0; }
          .manager-contact #content-tab4 .chat .messages .messages_message-your .title {
            text-align: left; }
            .manager-contact #content-tab4 .chat .messages .messages_message-your .title span {
              color: #124390; }
          .manager-contact #content-tab4 .chat .messages .messages_message-your .text {
            background-color: #E4F2FF; }
      .manager-contact #content-tab4 .chat .write {
        margin-top: 17px;
        border-top: 1px solid #d7d7d7; }
        .manager-contact #content-tab4 .chat .write form {
          padding-top: 4px;
          font-size: 14px;
          line-height: 28px; }
          .manager-contact #content-tab4 .chat .write form textarea {
            width: 100%;
            height: 88px;
            padding: 16px 17px;
            border: 1px solid #a5a5a5;
            background-color: #ffffff;
            resize: none;
            overflow-y: auto;
            /* Optional: show position indicator in red */ }
            .manager-contact #content-tab4 .chat .write form textarea::placeholder {
              color: #a6a6a6; }
            .manager-contact #content-tab4 .chat .write form textarea::-webkit-scrollbar {
              width: 0;
              /* Remove scrollbar space */
              background: transparent;
              /* Optional: just make scrollbar invisible */
              display: none; }
            .manager-contact #content-tab4 .chat .write form textarea::-webkit-scrollbar-thumb {
              background: #FF0000; }
          .manager-contact #content-tab4 .chat .write form .send-btn {
            width: 161px;
            height: 35px;
            background-color: #124390;
            font-family: "Vauxhall Neue", sans-serif;
            font-size: 16px;
            line-height: 28px;
            font-weight: bold;
            color: #ffffff;
            transition-duration: 0.2s;
            margin-top: 15px; }
            @media (min-width: 1024px) {
              .manager-contact #content-tab4 .chat .write form .send-btn:hover {
                filter: brightness(1.2); } }
  .manager-contact .gmc-email {
    display: flex; }
  .manager-contact .sidebar-email-gms {
    width: 241px; }
    @media (max-width: 750px) {
      .manager-contact .sidebar-email-gms {
        width: 100%; } }
    .manager-contact .sidebar-email-gms__types {
      margin-top: 30px; }
    .manager-contact .sidebar-email-gms__type {
      width: 100%;
      height: 35px;
      border: 1px solid #d9d9d9;
      color: #2c2c2c;
      font-size: 16px;
      line-height: 33px;
      font-weight: 400;
      text-align: center;
      transition-duration: 0.2s;
      box-sizing: border-box;
      cursor: pointer; }
      .manager-contact .sidebar-email-gms__type:not(:last-child) {
        margin-bottom: 22px; }
      .manager-contact .sidebar-email-gms__type.active {
        background-color: #ce171f;
        border: 1px solid #ce171f;
        color: #ffffff;
        pointer-events: none; }
      @media (min-width: 1024px) {
        .manager-contact .sidebar-email-gms__type:hover {
          background-color: #ce171f;
          border: 1px solid #ce171f;
          color: #ffffff; } }
    .manager-contact .sidebar-email-gms__filters {
      margin-top: 30px; }
      .manager-contact .sidebar-email-gms__filters .title {
        font-size: 18px;
        line-height: 28px;
        font-weight: bold;
        color: #2c2c2c; }
      .manager-contact .sidebar-email-gms__filters label {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        color: #2c2c2c; }
        .manager-contact .sidebar-email-gms__filters label input {
          display: none; }
          .manager-contact .sidebar-email-gms__filters label input + span {
            display: flex;
            align-items: center;
            position: relative; }
            .manager-contact .sidebar-email-gms__filters label input + span::before {
              content: '';
              display: block;
              width: 20px;
              height: 20px;
              border: 1px solid #a5a5a5;
              background-color: #ffffff;
              border-radius: 50%;
              margin-right: 10px; }
            .manager-contact .sidebar-email-gms__filters label input + span::after {
              content: '';
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #000;
              position: absolute;
              left: 6px;
              z-index: 2;
              opacity: 0;
              transition-duration: 0.2s; }
          .manager-contact .sidebar-email-gms__filters label input:checked + span::after {
            opacity: 1; }
    .manager-contact .sidebar-email-gms__input-box {
      margin-top: 10px; }
      .manager-contact .sidebar-email-gms__input-box .title {
        font-size: 14px;
        line-height: 28px;
        font-weight: 400;
        color: #2c2c2c; }
      .manager-contact .sidebar-email-gms__input-box > input {
        display: block;
        width: 100%;
        height: 30px;
        padding: 0 13px 0 18px;
        border: 1px solid #a5a5a5;
        background-color: #ffffff;
        box-sizing: border-box; }
      .manager-contact .sidebar-email-gms__input-box .bottom {
        display: flex; }
        .manager-contact .sidebar-email-gms__input-box .bottom .undeliter {
          width: 22px;
          font-size: 14px;
          line-height: 28px;
          font-weight: 400;
          text-align: center;
          color: #adadad; }
        .manager-contact .sidebar-email-gms__input-box .bottom input {
          width: calc(50% - 11px);
          height: 30px;
          padding: 0 13px 0 18px;
          border: 1px solid #a5a5a5;
          background-color: #ffffff;
          font-size: 14px;
          line-height: 28px;
          font-weight: 400; }
          .manager-contact .sidebar-email-gms__input-box .bottom input::placeholder {
            color: #adadad; }
    .manager-contact .sidebar-email-gms__apply {
      display: block;
      width: 100%;
      height: 40px;
      margin-top: 44px;
      background-color: #ce171f;
      font-size: 16px;
      line-height: 40px;
      text-align: center;
      font-weight: bold;
      color: #ffffff;
      transition-duration: 0.2s; }
      @media (min-width: 1024px) {
        .manager-contact .sidebar-email-gms__apply:hover {
          opacity: 0.8; } }
    .manager-contact .sidebar-email-gms__reset {
      display: block;
      width: 100%;
      margin-top: 10px;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      color: #ce171f;
      text-decoration: underline;
      text-align: center;
      transition-duration: 0.2s; }
      @media (min-width: 1024px) {
        .manager-contact .sidebar-email-gms__reset:hover {
          opacity: 0.8; } }
  .manager-contact .search-line {
    display: flex;
    margin-top: 30px;
    margin-bottom: 20px; }
    .manager-contact .search-line__search {
      width: calc(100% - 15px - 112px);
      height: 40px;
      margin-right: 14px;
      padding: 0 15px;
      border: 1px solid #a5a5a5;
      background-color: #ffffff;
      font-size: 16px;
      line-height: 28px;
      font-weight: 400;
      box-sizing: border-box; }
      .manager-contact .search-line__search::placeholder {
        color: #a6a6a6; }
    .manager-contact .search-line__search-btn {
      width: 112px;
      height: 40px;
      background-color: #ce171f;
      font-size: 16px;
      line-height: 40px;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      transition-duration: 0.2s;
      cursor: pointer; }
      @media (min-width: 1024px) {
        .manager-contact .search-line__search-btn:hover {
          opacity: 0.8; } }
      .manager-contact .search-line__search-btn::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background: url("../img/white-lupa.png") no-repeat center center;
        background-size: contain;
        position: relative;
        top: 3px;
        margin-right: 9px; }
  .manager-contact .content-email-gms {
    width: calc(100% - 284px);
    margin-left: 42px; }
    @media (max-width: 750px) {
      .manager-contact .content-email-gms {
        width: 100%;
        margin-left: 0; } }
  .manager-contact .table-email-gms {
    max-width: 100%; }
    .manager-contact .table-email-gms .tov-el {
      max-width: 244px; }
      @media (max-width: 1337px) {
        .manager-contact .table-email-gms .tov-el {
          max-width: 182px; } }
      @media (max-width: 1271px) {
        .manager-contact .table-email-gms .tov-el {
          max-width: 140px; } }
  .manager-contact .is-new {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box; }
    .manager-contact .is-new::before {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      background-color: #ce171f;
      border-radius: 50%; }

.invoice-page .page-title {
  padding: 61px 0 47px;
  text-align: center; }

.invoice-page .title {
  font-size: 35px;
  line-height: 33px;
  margin-bottom: 14px; }

.invoice-page .subtitle {
  font-size: 30px;
  line-height: 33px; }

.invoice-page .invoice {
  width: 100%;
  max-width: 936px;
  margin: 0 auto;
  padding: 0 15px 70px;
  box-sizing: border-box; }
  @media (min-width: 950px) {
    .invoice-page .invoice {
      padding: 0 0 170px; } }
  .invoice-page .invoice__flex {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .invoice-page .invoice__flex {
        margin: 0 -17.5px; } }
  .invoice-page .invoice__col {
    width: 100%;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .invoice-page .invoice__col {
        width: calc(50% - 35px);
        margin: 0 17.5px; } }
  .invoice-page .invoice__table-name {
    font-size: 24px; }
    .invoice-page .invoice__table-name span {
      font-size: 18px;
      color: #757575; }
  .invoice-page .invoice__reset {
    display: inline-block;
    font-size: 14px;
    color: #ce171f;
    text-decoration: underline;
    background-color: #fff;
    margin-left: 5px; }
  .invoice-page .invoice__table table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6e6e6; }
    .invoice-page .invoice__table table tr:nth-child(odd) {
      background-color: #f2f2f2; }
    .invoice-page .invoice__table table td {
      padding: 13px 22px; }
  .invoice-page .invoice__table--vehicle-details {
    margin-bottom: 29px; }
    .invoice-page .invoice__table--vehicle-details .invoice__table-name {
      margin-bottom: 30px; }
    .invoice-page .invoice__table--vehicle-details table td:first-child {
      width: 68%; }
    .invoice-page .invoice__table--vehicle-details table td:last-child {
      width: 32%; }
    .invoice-page .invoice__table--vehicle-details table td p:first-child {
      font-size: 13px;
      line-height: 16px;
      color: #757575;
      margin-bottom: 6px; }
    .invoice-page .invoice__table--vehicle-details table td p:last-child {
      font-size: 14px;
      line-height: 16px; }
  .invoice-page .invoice__table--service-details .invoice__table-name {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px; }
  .invoice-page .invoice__table--service-details table {
    border-bottom: none; }
    .invoice-page .invoice__table--service-details table tr:last-child {
      background-color: #fff;
      border-top: 1px solid #e6e6e6; }
      .invoice-page .invoice__table--service-details table tr:last-child td {
        font-size: 18px;
        text-align: right; }
        .invoice-page .invoice__table--service-details table tr:last-child td span {
          font-size: 20px; }
    .invoice-page .invoice__table--service-details table td {
      font-size: 16px;
      padding: 16.5px 22px; }
      .invoice-page .invoice__table--service-details table td:first-child {
        width: 90%; }
      .invoice-page .invoice__table--service-details table td:last-child {
        width: 10%;
        text-align: right; }
  .invoice-page .invoice__table--new-invoice .invoice__table-name:nth-child(2) {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 12px; }
  .invoice-page .invoice__table--new-invoice table {
    border-bottom: none; }
    .invoice-page .invoice__table--new-invoice table thead tr:nth-child(odd) {
      background-color: #fff; }
    .invoice-page .invoice__table--new-invoice table thead td {
      font-size: 13px;
      color: #757575;
      padding: 7px 10px; }
    .invoice-page .invoice__table--new-invoice table tbody tr:last-child {
      border-top: 1px solid #e6e6e6; }
    .invoice-page .invoice__table--new-invoice table td {
      padding: 11px 10px; }
      .invoice-page .invoice__table--new-invoice table td:first-child {
        width: 25%; }
      .invoice-page .invoice__table--new-invoice table td:nth-child(2) {
        width: 56%; }
      .invoice-page .invoice__table--new-invoice table td:last-child {
        width: 19%;
        text-align: right; }
  .invoice-page .invoice__table--history {
    border-top: 1px solid #e6e6e6;
    margin-top: 41px;
    padding-top: 34px; }
    .invoice-page .invoice__table--history .invoice__table-name {
      font-size: 20px;
      text-align: center;
      margin-bottom: 18px; }
    .invoice-page .invoice__table--history table {
      border: 1px solid #e6e6e6; }
      .invoice-page .invoice__table--history table thead {
        display: none; }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table thead {
            display: table-header-group; } }
        .invoice-page .invoice__table--history table thead tr {
          border-bottom: 1px solid #e6e6e6; }
      .invoice-page .invoice__table--history table tbody tr {
        display: block;
        width: 100%;
        box-sizing: border-box;
        padding: 13px 0; }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table tbody tr {
            display: table-row;
            padding: 0; } }
      .invoice-page .invoice__table--history table tr + tr {
        border-top: 1px solid #e6e6e6; }
      .invoice-page .invoice__table--history table tr:nth-child(odd) {
        background-color: #fff; }
      .invoice-page .invoice__table--history table tr:nth-child(even) {
        background-color: #f7f7f7; }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table tr:nth-child(even) {
            background-color: #fff; } }
      .invoice-page .invoice__table--history table th {
        font-weight: 400;
        text-align: left; }
      .invoice-page .invoice__table--history table td,
      .invoice-page .invoice__table--history table th {
        width: 100%;
        font-size: 16px;
        padding: 25.5px 16px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        background-color: transparent;
        border-bottom: none;
        box-sizing: border-box; }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table td,
          .invoice-page .invoice__table--history table th {
            display: table-cell;
            margin: -15px 0; } }
        .invoice-page .invoice__table--history table td::before,
        .invoice-page .invoice__table--history table th::before {
          content: attr(aria-label);
          display: inline-block;
          max-width: 100px;
          width: 100%;
          margin-right: 20px;
          color: #999999; }
          @media (min-width: 768px) {
            .invoice-page .invoice__table--history table td::before,
            .invoice-page .invoice__table--history table th::before {
              display: none; } }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table td:nth-child(even),
          .invoice-page .invoice__table--history table th:nth-child(even) {
            background-color: #f7f7f7; } }
        .invoice-page .invoice__table--history table td:nth-child(3) p + p,
        .invoice-page .invoice__table--history table th:nth-child(3) p + p {
          margin-top: 9px; }
        @media (min-width: 768px) {
          .invoice-page .invoice__table--history table td:first-child,
          .invoice-page .invoice__table--history table th:first-child {
            width: 15%; }
          .invoice-page .invoice__table--history table td:nth-child(2),
          .invoice-page .invoice__table--history table th:nth-child(2) {
            width: 12%; }
          .invoice-page .invoice__table--history table td:nth-child(3),
          .invoice-page .invoice__table--history table th:nth-child(3) {
            width: 58%; }
          .invoice-page .invoice__table--history table td:last-child,
          .invoice-page .invoice__table--history table th:last-child {
            width: 15%; } }
  .invoice-page .invoice .form-block {
    margin-top: 9px; }
    .invoice-page .invoice .form-block .input-block label {
      width: 100%; }
    .invoice-page .invoice .form-block .input-block input {
      height: 35px;
      margin-top: 4px;
      font-size: 16px;
      padding: 6px 8px; }
    .invoice-page .invoice .form-block .btn {
      width: 168px;
      height: 35px;
      margin-top: 7px;
      font-size: 14px;
      line-height: 35px;
      font-weight: 400; }

.lockdown-event-page .page-title {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px 0;
  height: 132px;
  box-sizing: border-box; }

.lockdown-event-page .page-title-inner {
  width: 100%;
  max-width: 608px;
  margin: 0 auto; }

.lockdown-event-page .title {
  font-size: 35px;
  line-height: 33px; }

.lockdown-event-page .ld-event {
  padding: 30px 20px 50px;
  max-width: 1141px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box; }
  @media (min-width: 1200px) {
    .lockdown-event-page .ld-event {
      padding: 38px 0 100px; } }
  .lockdown-event-page .ld-event > img {
    width: 100%; }
  .lockdown-event-page .ld-event__title {
    margin-top: 39px;
    margin-bottom: 25px; }
    .lockdown-event-page .ld-event__title p:first-child {
      font-size: 24px;
      line-height: 32px;
      font-weight: bold;
      letter-spacing: 0.2px; }
      @media (max-width: 767px) {
        .lockdown-event-page .ld-event__title p:first-child {
          font-size: 22px;
          line-height: 28px;
          margin-bottom: 8px; } }
    .lockdown-event-page .ld-event__title p:last-child {
      font-size: 18px;
      line-height: 24px;
      color: #ce171f; }
      @media (max-width: 767px) {
        .lockdown-event-page .ld-event__title p:last-child {
          font-size: 16px;
          line-height: 20px; } }
  .lockdown-event-page .ld-event__text {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 34px; }
    @media (max-width: 767px) {
      .lockdown-event-page .ld-event__text {
        font-size: 14px;
        line-height: 20px; } }
  .lockdown-event-page .ld-event__btns {
    max-width: 1002px;
    margin: 0 auto; }
  .lockdown-event-page .ld-event__btns-flex {
    display: flex;
    margin: 0 -10px; }
    @media (max-width: 767px) {
      .lockdown-event-page .ld-event__btns-flex {
        flex-direction: column;
        margin: 0; } }
    .lockdown-event-page .ld-event__btns-flex button {
      width: calc(33.3333% - 20px);
      margin: 0 10px;
      border-radius: 5px;
      font-size: 18px; }
      @media (max-width: 767px) {
        .lockdown-event-page .ld-event__btns-flex button {
          width: 100%;
          margin: 0 auto 10px; } }
      @media (max-width: 999px) {
        .lockdown-event-page .ld-event__btns-flex button {
          height: 40px;
          font-size: 14px;
          line-height: 40px; } }

.motability-page__inner {
  max-width: 1140px; }

.motability-page .motability {
  padding: 14px 0 95px;
  color: #2c2c2c; }
  .motability-page .motability__banner {
    width: 100%;
    height: 400px;
    margin-bottom: 50px; }
    @media (max-width: 767px) {
      .motability-page .motability__banner {
        height: 300px; } }
    .motability-page .motability__banner img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  .motability-page .motability__title {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 21px; }
    @media (max-width: 767px) {
      .motability-page .motability__title {
        text-align: center; } }
  .motability-page .motability__subtitle {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 12px; }
    @media (max-width: 767px) {
      .motability-page .motability__subtitle {
        text-align: center; } }
  .motability-page .motability__text {
    font-size: 15px;
    line-height: 24px;
    color: #4d4d4d; }
    @media (max-width: 767px) {
      .motability-page .motability__text {
        font-size: 14px;
        line-height: 22px; } }
    .motability-page .motability__text p + p {
      margin-top: 24px; }
      @media (max-width: 767px) {
        .motability-page .motability__text p + p {
          margin-top: 16px; } }
    .motability-page .motability__text--contacts {
      color: #2c2c2c; }
      .motability-page .motability__text--contacts p {
        margin-bottom: 12px; }
      .motability-page .motability__text--contacts a {
        color: #2c2c2c;
        transition: color 0.3s ease; }
        @media (min-width: 1024px) {
          .motability-page .motability__text--contacts a:hover {
            color: #ce181f; } }
  .motability-page .motability__content {
    margin-bottom: 58px; }
    @media (max-width: 767px) {
      .motability-page .motability__content {
        margin-bottom: 40px; } }
  .motability-page .motability__content-wrapper {
    display: flex;
    margin: 0 -28px 40px; }
    @media (max-width: 767px) {
      .motability-page .motability__content-wrapper {
        flex-direction: column;
        margin: 0 0 30px; } }
  .motability-page .motability__col {
    width: calc(50% - 56px);
    margin: 0 28px; }
    @media (max-width: 767px) {
      .motability-page .motability__col {
        width: 100%;
        margin: 0; } }
    @media (max-width: 767px) {
      .motability-page .motability__col:last-child {
        margin-top: 30px; } }
  .motability-page .motability__make {
    height: 40px;
    margin-top: 21px;
    font-size: 14px;
    line-height: 40px; }
  .motability-page .motability__video {
    margin-top: 7px; }
    .motability-page .motability__video::before {
      padding-top: 59%; }
  .motability-page .motability__tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ce171f;
    margin-bottom: 35px; }
    @media (max-width: 767px) {
      .motability-page .motability__tabs {
        flex-direction: column;
        border-bottom: none;
        margin-bottom: 20px; } }
  .motability-page .motability__tab {
    padding: 8px 38px;
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-radius: 5px 5px 0 0;
    font-size: 18px;
    color: #4d4d4d;
    cursor: pointer;
    transition: all 0.3s ease;
    box-sizing: border-box;
    position: relative; }
    @media (max-width: 767px) {
      .motability-page .motability__tab {
        width: 100%;
        padding: 6px 15px;
        border-radius: 5px;
        font-size: 15px;
        text-align: center; } }
    .motability-page .motability__tab + .motability__tab::before {
      content: '';
      display: block;
      width: 1px;
      height: 44%;
      background-color: #e6e6e6;
      border-radius: 1px 1px 0 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 767px) {
        .motability-page .motability__tab + .motability__tab::before {
          display: none; } }
    .motability-page .motability__tab.active {
      background-color: #ce171f;
      color: #fff; }
      .motability-page .motability__tab.active::before {
        display: none; }
      .motability-page .motability__tab.active + .motability__tab::before {
        display: none; }
  .motability-page .motability__content-item {
    display: none; }
  .motability-page .motability__tabs-content-wrapper {
    display: flex;
    margin: 0 -26px; }
    @media (max-width: 767px) {
      .motability-page .motability__tabs-content-wrapper {
        flex-direction: column;
        margin: 0; } }
  .motability-page .motability__tabs-content-col {
    width: calc(50% - 52px);
    margin: 0 26px; }
    @media (max-width: 767px) {
      .motability-page .motability__tabs-content-col {
        width: 100%;
        margin: 0; } }
    @media (max-width: 767px) {
      .motability-page .motability__tabs-content-col:last-child {
        margin-top: 20px; } }
  .motability-page .motability__list {
    margin-top: 31px; }
    @media (max-width: 767px) {
      .motability-page .motability__list {
        margin-top: 15px; } }
    .motability-page .motability__list li {
      font-size: 14px;
      line-height: 18px;
      color: #4d4d4d;
      padding-left: 14px;
      position: relative; }
      .motability-page .motability__list li + li {
        margin-top: 14px; }
      .motability-page .motability__list li::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #ce171f;
        position: absolute;
        top: 6px;
        left: 1px; }
  .motability-page .motability__text-block + .motability__text-block {
    margin-top: 24px; }
  .motability-page .motability__phone {
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
    .motability-page .motability__phone + .motability__phone {
      margin-top: 6px; }
    .motability-page .motability__phone a {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      font-size: 15px;
      color: #2c2c2c;
      transition: color 0.3s ease; }
      @media (max-width: 767px) {
        .motability-page .motability__phone a {
          font-size: 14px; } }
      @media (min-width: 1024px) {
        .motability-page .motability__phone a:hover {
          color: #ce181f; } }
    .motability-page .motability__phone svg {
      width: 16px;
      height: 16px;
      fill: #ce181f;
      margin-right: 5px; }

.my-enquiries-page .inner {
  max-width: 1340px; }

.my-enquiries-page .flex-block {
  display: flex;
  margin-top: 39px;
  margin-bottom: 79px; }
  @media (max-width: 1080px) {
    .my-enquiries-page .flex-block {
      flex-direction: column; } }
  .my-enquiries-page .flex-block .left {
    width: 100%; }
    .my-enquiries-page .flex-block .left > .title {
      font-size: 24px;
      line-height: 33px;
      font-weight: bold;
      margin-bottom: 10px; }
    .my-enquiries-page .flex-block .left .filter {
      display: flex; }
      .my-enquiries-page .flex-block .left .filter p {
        font-size: 18px;
        line-height: 28px;
        font-weight: 400; }
      .my-enquiries-page .flex-block .left .filter .select {
        display: block;
        width: 232px;
        height: 35px;
        margin-left: 16px;
        margin-bottom: 21px;
        padding: 10px;
        padding-right: 40px;
        border-radius: 0;
        border: 1px solid #a5a5a5;
        background: #fff url(../img/select.svg) no-repeat;
        background-position: calc(100% - 15px) center;
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: 0;
        font-size: 14px;
        line-height: 14px;
        white-space: nowrap;
        color: #a5a5a5;
        position: relative;
        cursor: pointer;
        box-sizing: border-box; }
        @media (min-width: 1024px) {
          .my-enquiries-page .flex-block .left .filter .select:hover {
            border: 1px solid #a5a5a5;
            background: #fff url(../img/select_hov.svg) no-repeat;
            background-position: calc(100% - 15px) center;
            color: #2c2c2c; } }
    .my-enquiries-page .flex-block .left .table-block {
      min-width: 100%; }
      @media (max-width: 620px) {
        .my-enquiries-page .flex-block .left .table-block {
          overflow-x: scroll; } }
    .my-enquiries-page .flex-block .left .table {
      display: table;
      min-width: 100%;
      margin-bottom: 30px;
      border-bottom: 2px solid #e6e6e6; }
      .my-enquiries-page .flex-block .left .table .row {
        display: table-row;
        padding: 17px 11px 17px 15px;
        font-size: 14px; }
        .my-enquiries-page .flex-block .left .table .row:not(.headline):nth-child(even) > div {
          background-color: #f2f2f2; }
        .my-enquiries-page .flex-block .left .table .row.headline {
          font-size: 16px;
          font-weight: bold; }
      .my-enquiries-page .flex-block .left .table .col {
        display: table-cell;
        padding-top: 17px;
        padding-bottom: 17px;
        vertical-align: top; }
        .my-enquiries-page .flex-block .left .table .col:first-child {
          padding-left: 15px; }
        .my-enquiries-page .flex-block .left .table .col:last-child {
          padding-right: 11px;
          vertical-align: middle; }
        .my-enquiries-page .flex-block .left .table .col:not(:last-child) {
          padding-right: 10px; }
        .my-enquiries-page .flex-block .left .table .col .arrow {
          display: block;
          width: 16px;
          height: 13px;
          padding-left: 10px;
          background: url(../img/red_arrow.png) center no-repeat;
          cursor: pointer; }
    .my-enquiries-page .flex-block .left .back {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 38px;
      border: 1px solid #58585a;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      text-transform: uppercase;
      color: #2c2c2c;
      cursor: pointer;
      transition-duration: 0.2s; }
      @media (min-width: 1024px) {
        .my-enquiries-page .flex-block .left .back:hover {
          color: #ffffff;
          background: #2c2c2c; } }
  .my-enquiries-page .flex-block .right {
    width: 349px;
    margin-left: 41px;
    flex-shrink: 0; }
    @media (max-width: 1080px) {
      .my-enquiries-page .flex-block .right {
        width: 100%;
        margin-left: 0;
        margin-top: 36px; } }
    .my-enquiries-page .flex-block .right .chat {
      background-color: #f2f2f2;
      padding: 27px 24px 36px 26px; }
      @media (max-width: 620px) {
        .my-enquiries-page .flex-block .right .chat {
          padding: 12px 5px 36px 5px; } }
      .my-enquiries-page .flex-block .right .chat > .title {
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #d7d7d7;
        font-size: 18px;
        line-height: 28px;
        font-weight: bold; }
      .my-enquiries-page .flex-block .right .chat .messages-wrap {
        max-height: 333px; }
        .my-enquiries-page .flex-block .right .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerContainer {
          right: -12px; }
        .my-enquiries-page .flex-block .right .chat .messages-wrap .mCSB_scrollTools .mCSB_draggerRail {
          width: 2px;
          background-color: #d9d9d9;
          border-radius: 0;
          cursor: pointer; }
        .my-enquiries-page .flex-block .right .chat .messages-wrap .mCSB_scrollTools .mCSB_dragger_bar {
          width: 4px;
          background-color: #d4141c;
          border-radius: 0; }
      .my-enquiries-page .flex-block .right .chat .messages {
        display: flex;
        flex-direction: column; }
        .my-enquiries-page .flex-block .right .chat .messages .messages_message {
          max-width: 254px;
          width: fit-content;
          padding-bottom: 20px;
          font-size: 14px; }
          @media (max-width: 1080px) {
            .my-enquiries-page .flex-block .right .chat .messages .messages_message {
              width: fit-content;
              max-width: 70%; } }
          @media (max-width: 620px) {
            .my-enquiries-page .flex-block .right .chat .messages .messages_message {
              width: fit-content;
              max-width: 95%; } }
          .my-enquiries-page .flex-block .right .chat .messages .messages_message .title {
            color: #a2a2a2;
            padding-bottom: 10px; }
            .my-enquiries-page .flex-block .right .chat .messages .messages_message .title span {
              font-weight: 600; }
          .my-enquiries-page .flex-block .right .chat .messages .messages_message .text {
            background-color: #ffffff;
            padding: 16px 20px 18px 17px; }
        .my-enquiries-page .flex-block .right .chat .messages .messages_message-manager {
          align-self: flex-start; }
          .my-enquiries-page .flex-block .right .chat .messages .messages_message-manager .title span {
            color: #ce171f; }
        .my-enquiries-page .flex-block .right .chat .messages .messages_message-your {
          align-self: flex-end; }
          .my-enquiries-page .flex-block .right .chat .messages .messages_message-your .title {
            text-align: right; }
            .my-enquiries-page .flex-block .right .chat .messages .messages_message-your .title span {
              color: #124390; }
      .my-enquiries-page .flex-block .right .chat .write {
        margin-top: 17px;
        border-top: 1px solid #d7d7d7; }
        .my-enquiries-page .flex-block .right .chat .write form {
          padding-top: 4px;
          font-size: 14px;
          line-height: 28px; }
          .my-enquiries-page .flex-block .right .chat .write form textarea {
            width: 298px;
            height: 88px;
            padding: 16px 17px;
            border: 1px solid #a5a5a5;
            background-color: #ffffff;
            resize: none;
            overflow-y: scroll;
            /* Optional: show position indicator in red */ }
            @media (max-width: 1080px) {
              .my-enquiries-page .flex-block .right .chat .write form textarea {
                width: 100%; } }
            .my-enquiries-page .flex-block .right .chat .write form textarea::placeholder {
              color: #a6a6a6; }
            .my-enquiries-page .flex-block .right .chat .write form textarea::-webkit-scrollbar {
              width: 0;
              /* Remove scrollbar space */
              background: transparent;
              /* Optional: just make scrollbar invisible */
              display: none; }
            .my-enquiries-page .flex-block .right .chat .write form textarea::-webkit-scrollbar-thumb {
              background: #FF0000; }
          .my-enquiries-page .flex-block .right .chat .write form .send-btn {
            width: 161px;
            height: 35px;
            background-color: #124390;
            font-family: "Vauxhall Neue", sans-serif;
            font-size: 16px;
            line-height: 28px;
            font-weight: bold;
            color: #ffffff;
            transition-duration: 0.2s;
            margin-top: 15px; }
            @media (max-width: 1080px) {
              .my-enquiries-page .flex-block .right .chat .write form .send-btn {
                width: 100%; } }
            @media (min-width: 1024px) {
              .my-enquiries-page .flex-block .right .chat .write form .send-btn:hover {
                filter: brightness(1.2); } }

.car-handover {
  padding-bottom: 80px; }
  .car-handover .chp-inner {
    width: 96%;
    max-width: 608px;
    margin: 0 auto; }
  .car-handover .chp-h2 {
    margin-top: 80px;
    font-size: 24px;
    line-height: 33px;
    font-weight: bold;
    text-align: center;
    color: #2c2c2c; }
  .car-handover .chp-form {
    margin-top: 40px; }
  .car-handover .chp-block-title {
    width: 100%;
    padding: 9px 25px 8px;
    background-color: #ce171f;
    font-size: 18px;
    line-height: 1.3;
    font-weight: bold;
    color: #ffffff;
    box-sizing: border-box; }
  .car-handover .chp-block-content {
    width: 100%;
    padding: 25px 25px 40px;
    box-sizing: border-box; }
  .car-handover .checkbox-customizedv2-wrap .checkbox-customizedv2 + input + span {
    display: block;
    padding-left: 30px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 15px;
    line-height: 30px;
    font-weight: 400;
    color: #2c2c2c;
    cursor: pointer;
    position: relative; }
    .car-handover .checkbox-customizedv2-wrap .checkbox-customizedv2 + input + span::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #a9a9a9;
      background-color: #fff;
      background-size: contain;
      position: absolute;
      top: 3px;
      left: -1px;
      cursor: pointer;
      transition: opacity 0.1s; }
    .car-handover .checkbox-customizedv2-wrap .checkbox-customizedv2 + input + span::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      opacity: 0;
      background: url(../img/checked.svg) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 4px;
      left: 0;
      z-index: 1;
      cursor: pointer;
      transition: opacity 0.1s; }
  .car-handover .checkbox-customizedv2-wrap .checkbox-customizedv2:checked + input + span::before {
    opacity: 1; }
  .car-handover .chp-input-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -25px; }
    .car-handover .chp-input-row .input-block {
      width: calc(50% - 11px);
      margin-bottom: 25px; }
      @media (max-width: 550px) {
        .car-handover .chp-input-row .input-block {
          width: 100%; } }
  .car-handover .chp-checkbox-row {
    margin-bottom: -25px;
    columns: 2;
    column-gap: 22px; }
    @media (max-width: 550px) {
      .car-handover .chp-checkbox-row {
        columns: 1; } }
  .car-handover .chp-last-block {
    padding-top: 30px;
    border-top: 1px solid #e6e6e6; }
    .car-handover .chp-last-block .input-block {
      margin-bottom: 20px; }
  .car-handover .chp-new-quote-request {
    padding-bottom: 50px; }
  .car-handover .new-quote-request.chp-new-quote-request .thumbs .thumb,
  .car-handover .new-quote-request.chp-new-quote-request .thumbs .upload-more {
    width: 188px;
    height: 132px; }
  .car-handover .chp-images-block p {
    color: #4d4d4d; }
  .car-handover .chp-retailer__r {
    font-size: 13px;
    line-height: 28px;
    font-weight: 400;
    color: #808080; }
  .car-handover .chp-retailer-p {
    font-size: 14px;
    line-height: 1.2;
    color: #2c2c2c; }
    .car-handover .chp-retailer-p span {
      padding-right: 15px; }
  .car-handover .btn-chp-signature {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 266px;
    margin: 35px auto 0 !important;
    font-weight: bold;
    font-size: 18px;
    box-sizing: border-box; }
  .car-handover .chp-custom-radios {
    display: block; }
    .car-handover .chp-custom-radios label {
      display: flex;
      margin-top: 25px;
      cursor: pointer; }
      .car-handover .chp-custom-radios label input {
        min-width: 15px; }

.vehicle-pre-delivery-checklist {
  padding-bottom: 50px; }
  .vehicle-pre-delivery-checklist input[type=checkbox] {
    width: 20px;
    height: 20px;
    border: 1px solid #a5a5a5;
    background-color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none; }
  .vehicle-pre-delivery-checklist input[type=checkbox]:checked {
    background-image: url(../img/checked.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%; }
  .vehicle-pre-delivery-checklist input[type=radio] {
    width: 20px;
    height: 20px;
    border: 1px solid #a5a5a5;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none; }
  .vehicle-pre-delivery-checklist input[type=radio]:checked::before {
    content: '';
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px; }
  .vehicle-pre-delivery-checklist .block {
    padding-top: 40px;
    overflow: auto; }
    .vehicle-pre-delivery-checklist .block table {
      width: 100%;
      min-width: 750px;
      border: 1px solid #000; }
      .vehicle-pre-delivery-checklist .block table th {
        padding: 15px 5px 5px 5px;
        vertical-align: bottom;
        text-align: left;
        background: #c00000;
        color: #fff;
        border: 0.5px solid #000;
        font-size: 110%; }
      .vehicle-pre-delivery-checklist .block table th:first-child {
        width: 45%; }
      .vehicle-pre-delivery-checklist .block table td {
        position: relative;
        vertical-align: bottom;
        text-align: left;
        border: 0.5px solid #000;
        padding: 15px 5px 5px 5px;
        font-weight: 400; }
        .vehicle-pre-delivery-checklist .block table td textarea {
          width: 100%;
          height: 100%;
          padding: 10px;
          background: transparent;
          position: absolute;
          left: 0;
          top: 0;
          box-sizing: border-box; }

.online-quote-request-2 .content {
  width: 95%;
  max-width: 846px;
  margin: 0 auto; }

.online-quote-request-2 .oqr .red {
  color: #ce171f; }

.online-quote-request-2 .oqr .oqr-block + .oqr-block {
  border-top: 1px solid #e6e6e6; }

.online-quote-request-2 .oqr .oqr-block--accordion {
  padding: 69px 0 30px; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr .oqr-block--accordion {
      padding: 30px 0; } }

.online-quote-request-2 .oqr .oqr-block--options {
  padding-top: 15px; }

.online-quote-request-2 .oqr .oqr-block--exchange {
  padding: 52px 0 84px; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr .oqr-block--exchange {
      padding: 30px 0; } }
  .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs {
    margin: 14px -12px 0; }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs {
        margin: 14px 0 0; } }
    .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper {
      display: flex;
      align-items: center;
      margin-top: 23px; }
      .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper .btn {
        max-width: 351px;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        margin-top: 0;
        margin-right: 24px; }
        @media (max-width: 600px) {
          .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper .btn {
            font-size: 15px; } }
      .online-quote-request-2 .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper p {
        font-size: 14px;
        color: #666666; }

.online-quote-request-2 .oqr .oqr-block--payment {
  padding: 48px 0; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr .oqr-block--payment {
      padding: 30px 0; } }
  .online-quote-request-2 .oqr .oqr-block--payment .oqr__inputs {
    margin: 0 -12px; }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr .oqr-block--payment .oqr__inputs {
        margin: 0; } }

.online-quote-request-2 .oqr .oqr-block--contacts {
  padding: 40px 0 36px; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr .oqr-block--contacts {
      padding: 30px 0; } }
  .online-quote-request-2 .oqr .oqr-block--contacts .oqr__inputs {
    margin: 25px -12px 35px; }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr .oqr-block--contacts .oqr__inputs {
        margin: 25px 0 35px; } }

.online-quote-request-2 .oqr__step {
  font-size: 18px;
  line-height: 22px;
  color: #ce171f; }

.online-quote-request-2 .oqr__title {
  font-size: 24px;
  line-height: 28px; }

.online-quote-request-2 .oqr .oqr-label {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #e6e6e6;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 40px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s ease; }
  @media (min-width: 1024px) {
    .online-quote-request-2 .oqr .oqr-label:hover {
      border-color: #ce181f; } }
  .online-quote-request-2 .oqr .oqr-label + label {
    margin-top: 9px; }
  .online-quote-request-2 .oqr .oqr-label input {
    display: none; }
    .online-quote-request-2 .oqr .oqr-label input:checked + span {
      color: #fff; }
      .online-quote-request-2 .oqr .oqr-label input:checked + span::before {
        background: #fff url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3e%3cpath fill='%23ce171f' d='M6.992.062h.015a6.93 6.93 0 0 1 6.93 6.93v.015a6.93 6.93 0 0 1-6.93 6.93h-.015a6.93 6.93 0 0 1-6.93-6.93v-.015a6.93 6.93 0 0 1 6.93-6.93z'/%3e%3c/svg%3e") no-repeat center;
        background-size: 70%;
        border-color: #fff; }
  .online-quote-request-2 .oqr .oqr-label span {
    user-select: none;
    font-size: 16px;
    line-height: 28px; }
    .online-quote-request-2 .oqr .oqr-label span::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid #a9a9a9;
      margin-right: 10px;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 10px; }

.online-quote-request-2 .oqr__accordion {
  display: flex;
  margin: 27px -20px 0; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__accordion {
      flex-direction: column;
      margin: 27px 0 0; } }

.online-quote-request-2 .oqr__content, .online-quote-request-2 .oqr__tab {
  margin: 0 20px; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__content, .online-quote-request-2 .oqr__tab {
      margin: 0; } }

.online-quote-request-2 .oqr__content {
  width: calc(65% - 40px);
  position: relative; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__content {
      width: 100%;
      padding-top: 60px; } }
  .online-quote-request-2 .oqr__content::before {
    content: '';
    display: block;
    width: 430px;
    height: 430px;
    background-color: #eef3fb;
    border-radius: 50%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr__content::before {
        top: 0;
        transform: translate(-50%, 0); } }
    @media (max-width: 600px) {
      .online-quote-request-2 .oqr__content::before {
        width: 300px;
        height: 300px; } }

.online-quote-request-2 .oqr__content-item {
  display: none;
  max-width: 520px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__content-item {
      max-width: 420px;
      top: calc(50% + 60px);
      transform: translate(-50%, calc(-50% - 30px)); } }
  @media (max-width: 600px) {
    .online-quote-request-2 .oqr__content-item {
      max-width: 300px; } }
  .online-quote-request-2 .oqr__content-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.online-quote-request-2 .oqr__content-selected {
  max-width: 150px;
  text-align: right;
  position: absolute;
  top: 0;
  right: 0; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__content-selected {
      max-width: 300px;
      text-align: center;
      top: 7px;
      right: auto;
      left: 50%;
      transform: translateX(-50%); } }
  .online-quote-request-2 .oqr__content-selected p {
    color: #ce171f;
    font-size: 14px; }
    .online-quote-request-2 .oqr__content-selected p.selected-car {
      color: #2c2c2c;
      font-size: 24px;
      line-height: 28px; }

.online-quote-request-2 .oqr-active {
  background-color: #ce171f !important;
  border-color: #ce171f !important; }

.online-quote-request-2 .oqr__tab {
  width: calc(35% - 40px); }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__tab {
      width: 100%; } }

.online-quote-request-2 .oqr__inputs {
  display: flex;
  align-items: flex-end; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr__inputs {
      flex-direction: column; } }
  .online-quote-request-2 .oqr__inputs .input-block {
    width: calc(33.3333% - 24px);
    margin: 0 12px;
    position: relative; }
    .online-quote-request-2 .oqr__inputs .input-block.err-required::after, .online-quote-request-2 .oqr__inputs .input-block.err-format::after {
      position: absolute;
      top: auto;
      bottom: -20px; }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr__inputs .input-block {
        width: 100%;
        margin: 0; } }
    @media (max-width: 900px) {
      .online-quote-request-2 .oqr__inputs .input-block + .input-block {
        margin-top: 22px; } }
    .online-quote-request-2 .oqr__inputs .input-block.gb-reg-number::before {
      content: "";
      background: url(../img/new-gb-rn.png);
      width: 43px;
      height: 43px;
      min-width: 42px;
      min-height: 42px;
      position: absolute;
      left: 1px;
      top: 25px;
      display: block; }
    .online-quote-request-2 .oqr__inputs .input-block.gb-reg-number input {
      padding-left: 55px; }
    .online-quote-request-2 .oqr__inputs .input-block label {
      width: 100%;
      margin-bottom: 0; }
    .online-quote-request-2 .oqr__inputs .input-block input,
    .online-quote-request-2 .oqr__inputs .input-block select {
      margin-top: 7px; }
    .online-quote-request-2 .oqr__inputs .input-block select {
      background: #fff url(../img/select_hov.svg) no-repeat;
      background-position: calc(100% - 15px) center; }
    .online-quote-request-2 .oqr__inputs .input-block span {
      font-size: 13px;
      color: #999999; }
      .online-quote-request-2 .oqr__inputs .input-block span.red {
        color: #ce171f; }

.online-quote-request-2 .oqr .input-block--branch {
  display: flex; }
  @media (max-width: 600px) {
    .online-quote-request-2 .oqr .input-block--branch {
      flex-direction: column;
      justify-content: center; } }
  .online-quote-request-2 .oqr .input-block--branch .radio-btn + .radio-btn {
    margin-top: 0;
    margin-left: 50px; }
    @media (max-width: 600px) {
      .online-quote-request-2 .oqr .input-block--branch .radio-btn + .radio-btn {
        margin-left: 0; } }

.online-quote-request-2 .oqr__method-wrap {
  display: flex;
  margin-top: 20px; }
  .online-quote-request-2 .oqr__method-wrap .oqr-label {
    max-width: 187px;
    width: 100%; }
    .online-quote-request-2 .oqr__method-wrap .oqr-label + .oqr-label {
      margin-left: 9px;
      margin-top: 0; }

.online-quote-request-2 .oqr__finance-options {
  font-size: 18px;
  font-weight: bold;
  margin: 45px 0 26px; }

.online-quote-request-2 .oqr__branch {
  font-size: 16px;
  margin-bottom: 20px; }

.online-quote-request-2 .oqr__submit {
  width: 266px;
  font-size: 18px;
  font-weight: bold;
  margin: 0 auto 100px; }

.online-quote-request-2 .oqr-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 19px; }
  @media (max-width: 900px) {
    .online-quote-request-2 .oqr-options {
      flex-direction: column;
      align-items: flex-start; } }
  .online-quote-request-2 .oqr-options__group {
    margin-bottom: 26px; }
    @media (max-width: 600px) {
      .online-quote-request-2 .oqr-options__group {
        width: 100%; } }
  .online-quote-request-2 .oqr-options__type {
    font-size: 18px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 3px; }
  .online-quote-request-2 .oqr-options__inputs {
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 600px) {
      .online-quote-request-2 .oqr-options__inputs label {
        width: 50%; } }
    .online-quote-request-2 .oqr-options__inputs label:not(:last-child) {
      margin-right: 53px; }
      @media (max-width: 600px) {
        .online-quote-request-2 .oqr-options__inputs label:not(:last-child) {
          margin-right: 0; } }

.page-404 .page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f6f6;
  width: 100%;
  padding: 33px 10px;
  box-sizing: border-box; }

.page-404 .title {
  font-size: 35px;
  line-height: 33px; }
  @media (max-width: 1023px) {
    .page-404 .title {
      font-size: 30px; } }
  @media (max-width: 767px) {
    .page-404 .title {
      font-size: 24px;
      line-height: 29px; } }

.page-404 .error {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  padding: 78px 0 164px; }
  @media (max-width: 1023px) {
    .page-404 .error {
      flex-direction: column;
      padding: 78px 0; } }
  .page-404 .error__pic {
    max-width: 619px;
    width: 100%; }
    @media (max-width: 1023px) {
      .page-404 .error__pic {
        margin-bottom: 25px; } }
    .page-404 .error__pic img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      font-family: 'object-fit: cover;'; }
  @media (max-width: 1023px) {
    .page-404 .error__content {
      text-align: center; } }
  .page-404 .error__title {
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 17px; }
    @media (max-width: 767px) {
      .page-404 .error__title {
        font-size: 24px; } }
  .page-404 .error__text {
    font-size: 16px;
    line-height: 26px;
    color: #666666;
    margin-bottom: 13px; }
    @media (max-width: 767px) {
      .page-404 .error__text {
        font-size: 14px; } }
  .page-404 .error a {
    font-size: 16px;
    color: #ce171f;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .page-404 .error a:hover {
        text-decoration: none; } }
    @media (max-width: 767px) {
      .page-404 .error a {
        font-size: 14px; } }

.part-order-enquiry .content {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto; }
  .part-order-enquiry .content .flex-table {
    margin-top: 30px;
    width: 100%;
    max-width: 100%; }
    .part-order-enquiry .content .flex-table .row {
      display: flex;
      align-items: center;
      height: 46px;
      color: #2c2c2c;
      font-family: "Vauxhall Neue";
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      background-color: #fff; }
      .part-order-enquiry .content .flex-table .row:nth-child(odd) {
        background-color: #f2f2f2; }
      .part-order-enquiry .content .flex-table .row .completed {
        color: #999999; }
      .part-order-enquiry .content .flex-table .row.headline {
        color: #2c2c2c;
        font-family: "Vauxhall Neue";
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
        background-color: #fff; }
  .part-order-enquiry .content-bg .flex-table .row:nth-child(odd) {
    background-color: #fff; }
  .part-order-enquiry .content-bg .flex-table .row:nth-child(even) {
    background-color: #f7f7f7; }
  .part-order-enquiry .content-bg .flex-table .row.headline {
    background-color: #f7f7f7; }

.part-order-enquiry .subtitle {
  margin-top: 43px;
  text-align: center; }
  @media (max-width: 650px) {
    .part-order-enquiry .subtitle {
      margin-top: 23px;
      padding: 0 10px; } }

.part-order-enquiry .subtitle-thanks {
  font-size: 24px;
  line-height: 33px; }
  @media (max-width: 650px) {
    .part-order-enquiry .subtitle-thanks {
      font-size: 20px; } }

.part-order-enquiry .subtitle-select {
  font-size: 18px;
  line-height: 33px;
  text-align: center; }
  .part-order-enquiry .subtitle-select a {
    color: #3b74ce; }
  @media (max-width: 650px) {
    .part-order-enquiry .subtitle-select {
      font-size: 16px; } }

.part-order-enquiry .table-wrapper {
  overflow: auto; }

.part-order-enquiry .flex-table {
  border-bottom: 2px solid #e6e6e6; }

.part-order-enquiry th {
  text-align: left; }

.part-order-enquiry .headline .field {
  font-size: 13px;
  line-height: 16px;
  color: #757575;
  font-weight: 400; }

.part-order-enquiry tbody .field.discount {
  color: #ce181f; }

.part-order-enquiry .row .field.num {
  width: 50px;
  padding-left: 12px; }

.part-order-enquiry .row .field.description {
  width: 362px; }

.part-order-enquiry .row .field.price {
  width: 94px; }

.part-order-enquiry .row .field.discount {
  width: 94px; }

.part-order-enquiry .row .field.selling-price {
  width: 105px; }

.part-order-enquiry .row .field.vat {
  width: 95px; }

.part-order-enquiry .row .field.stock {
  width: 168px; }
  .part-order-enquiry .row .field.stock .in-stock {
    color: #3b74ce; }
  .part-order-enquiry .row .field.stock span {
    font-size: 14px;
    line-height: 42px;
    color: #a6a6a6; }

.part-order-enquiry .row .field.button {
  width: 146px; }

.part-order-enquiry .btn-add,
.part-order-enquiry .btn-added {
  position: relative;
  padding: 10px;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  box-sizing: border-box; }
  .part-order-enquiry .btn-add::before,
  .part-order-enquiry .btn-added::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    background: url(../img/plus.svg) no-repeat center center;
    background-size: contain;
    margin-right: 5px; }

.part-order-enquiry .btn-added {
  display: none; }
  .part-order-enquiry .btn-added:hover {
    background-color: #124390 !important;
    color: #fff !important;
    cursor: auto; }
  .part-order-enquiry .btn-added::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url(../img/checked-white.svg) no-repeat center center;
    background-size: contain;
    margin-right: 5px; }

.part-order-enquiry .total {
  text-align: center;
  margin-top: 46px; }
  @media (max-width: 650px) {
    .part-order-enquiry .total {
      margin-top: 26px; } }

.part-order-enquiry .total-vat,
.part-order-enquiry .total-price {
  display: inline-block;
  font-size: 16px;
  line-height: 42px; }
  .part-order-enquiry .total-vat span,
  .part-order-enquiry .total-price span {
    font-size: 20px;
    font-weight: bold; }

.part-order-enquiry .total-price {
  margin-left: 20px;
  color: #ce181f; }

.part-order-enquiry .btn-continue {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  margin: 25px auto 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px; }
  .part-order-enquiry .btn-continue::after {
    content: '';
    display: block;
    width: 25px;
    height: 25px;
    background: url(../img/arrow-white.svg) no-repeat center center;
    background-size: contain;
    margin-left: 10px; }
  @media (max-width: 650px) {
    .part-order-enquiry .btn-continue {
      width: 250px;
      margin-bottom: 40px; } }

.part-order-cart .content .flex-table {
  margin-top: 15px; }

.part-order-cart .content-bg {
  background-color: #f7f7f7;
  padding-bottom: 45px; }
  .part-order-cart .content-bg .table-bottom {
    margin-bottom: 0; }

.part-order-cart .subtitle-select {
  margin-top: 26px; }

.part-order-cart .row .field.description {
  width: 515px; }

.part-order-cart .row .field.vat {
  width: 78px; }

.part-order-cart .row .field.stock {
  width: 88px; }

.part-order-cart .row .field.button {
  width: 90px; }

.part-order-cart .row .btn-remove,
.part-order-cart .row .btn-add {
  color: #b1b1b1;
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer; }
  .part-order-cart .row .btn-remove::before,
  .part-order-cart .row .btn-add::before {
    content: '';
    display: block;
    width: 18px;
    height: 18px;
    background: url(../img/close.svg) no-repeat center center;
    background-size: contain;
    margin-right: 5px; }

.part-order-cart .row .btn-add {
  color: #ce181f;
  display: none;
  width: auto;
  padding: 0; }
  .part-order-cart .row .btn-add::before {
    width: 10px;
    height: 10px;
    background: url(../img/plus-red.svg) no-repeat center center; }

.part-order-cart .table-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 45px; }
  @media (max-width: 650px) {
    .part-order-cart .table-bottom {
      flex-direction: column; } }

.part-order-cart .total {
  margin-top: 0; }

.part-order-cart .order-nav {
  display: flex; }

.part-order-cart .order-nav-btn {
  height: 35px;
  width: 174px;
  line-height: 32px; }
  .part-order-cart .order-nav-btn--check {
    display: none;
    align-items: center;
    justify-content: center; }
    .part-order-cart .order-nav-btn--check:hover {
      background-color: #124390 !important;
      color: #fff !important;
      cursor: auto; }
    .part-order-cart .order-nav-btn--check::before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      background: url(../img/checked-white.svg) no-repeat center center;
      background-size: contain;
      margin-right: 5px; }

.part-order-cart .btn-cancel {
  width: 85px;
  margin-left: 10px;
  border: 1px solid #514c4c;
  background-color: #fff; }
  .part-order-cart .btn-cancel:hover {
    background-color: #514c4c;
    color: #fff; }

.part-order-cart .confirm-wrapper {
  margin: 34px 0 110px;
  text-align: center; }
  @media (max-width: 650px) {
    .part-order-cart .confirm-wrapper {
      margin: 30px 0 40px; } }

.part-order-cart .confirm-text {
  font-size: 16px;
  line-height: 42px; }
  .part-order-cart .confirm-text span {
    font-size: 20px; }

.part-order-cart .confirm-btn {
  width: 350px;
  margin: 6px auto 0;
  background-color: #124390;
  font-size: 18px;
  font-weight: bold;
  line-height: 28px;
  color: #fff; }
  @media (max-width: 650px) {
    .part-order-cart .confirm-btn {
      width: 250px; } }
  .part-order-cart .confirm-btn--disabled {
    background-color: #cdcdcd;
    color: #a6a6a6; }
  .part-order-cart .confirm-btn--icon {
    display: flex;
    align-items: center;
    justify-content: center; }
    .part-order-cart .confirm-btn--icon::before {
      content: '';
      display: block;
      width: 25px;
      height: 25px;
      background: url(../img/arrow-triangle.svg) no-repeat center center;
      background-size: contain;
      margin-right: 8px; }

.part-order-cart .btn-back {
  font-size: 15px;
  line-height: 42px;
  text-decoration: underline;
  cursor: pointer; }

.payment-request {
  padding-bottom: 160px; }
  .payment-request .payreq-btn-invoce-details {
    width: 132px; }
  .payment-request .payreq-btn-remove {
    width: 89px; }
  .payment-request .payreq-pdf-open {
    display: block;
    width: 30px;
    height: 27px;
    background: url(../img/pdf.png) no-repeat center center;
    background-size: contain;
    cursor: pointer;
    transition-duration: 0.2s; }
    @media (min-width: 1024px) {
      .payment-request .payreq-pdf-open:hover {
        opacity: 0.8; } }
  .payment-request .payreq-toggler-chb-wrap {
    width: 100%; }
  .payment-request .payreq-toggler.toggle {
    border-radius: 0;
    border-color: #cccccc; }
    .payment-request .payreq-toggler.toggle.btn-payreq-toggler-on {
      border-color: #1abb64; }
  .payment-request .payreq-toggler .toggle-on {
    background-color: #1abb64;
    color: #ffffff !important;
    border-radius: 0; }
    @media (min-width: 1024px) {
      .payment-request .payreq-toggler .toggle-on:hover {
        opacity: 0.8; } }
  .payment-request .payreq-toggler .toggle-off {
    border-radius: 0; }
  .payment-request .payreq-toggler .toggle-handle.btn.btn-light {
    background: #ffffff;
    border-radius: 0;
    font-size: 14px; }
    @media (min-width: 1024px) {
      .payment-request .payreq-toggler .toggle-handle.btn.btn-light:hover {
        transition-duration: 0.2s;
        opacity: .8; } }
  .payment-request .payreq-color-select {
    transition-duration: 0.2s;
    background: #fff url(../img/select_hov.svg) no-repeat !important;
    background-position: calc(100% - 15px) center !important;
    line-height: 18px !important;
    cursor: pointer; }
  .payment-request .payreq-color-select--yellow {
    border: 1px solid #dfcd26 !important;
    background-color: #f5efb8 !important; }
  .payment-request .payreq-color-select--green {
    border: 1px solid #1abb64 !important;
    background-color: #b8f5d4 !important; }
  .payment-request .payreq-color-select--red {
    border: 1px solid #ff8188 !important;
    background-color: #ffdbdd !important; }
  .payment-request .payreq-filters + .doubleScroll-scroll-wrapper {
    margin-left: 117px;
    margin-right: 117px; }
  .payment-request .pr-btn-new {
    display: block;
    max-width: 100%;
    height: 40px;
    padding: 0 7px;
    margin-bottom: 30px;
    margin-right: 20px;
    font-size: 15px;
    line-height: 40px;
    box-sizing: border-box;
    user-select: none; }
    .payment-request .pr-btn-new:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-top: -2px;
      margin-right: 11px;
      background: url(../img/plus.svg) no-repeat center center;
      vertical-align: middle; }
  .payment-request .payreq-filters-bottom {
    display: flex;
    flex-wrap: wrap; }
  .payment-request .payreq-filters-bottom .nowrap {
    display: flex;
    align-items: center;
    padding-right: 40px;
    margin-bottom: 20px; }
    .payment-request .payreq-filters-bottom .nowrap label {
      display: flex;
      align-items: center;
      padding-right: 11px;
      margin-bottom: 0 !important;
      line-height: 1.2; }
    .payment-request .payreq-filters-bottom .nowrap select {
      width: 230px; }
  .payment-request .page-main-title {
    height: 115px;
    background-color: #fff;
    text-align: left; }
    .payment-request .page-main-title .inner {
      justify-content: flex-start;
      max-width: 1765px; }
  .payment-request .form-block {
    max-width: 1765px;
    width: 100%;
    margin: 0 auto; }
  .payment-request .payreq-filters {
    padding: 0; }
  .payment-request .payreq-filters-line {
    width: 92%;
    height: 1px;
    background-color: #e6e6e6;
    margin: 30px auto; }
  .payment-request .payreq-filters-top {
    display: flex;
    align-items: center; }
    @media (max-width: 1780px) {
      .payment-request .payreq-filters-top {
        padding: 0 10px; } }
    .payment-request .payreq-filters-top .btn {
      width: 235px;
      margin: 0; }
      .payment-request .payreq-filters-top .btn.pr-btn-manage {
        width: 200px;
        height: 40px;
        line-height: 40px;
        margin-left: 15px; }
        .payment-request .payreq-filters-top .btn.pr-btn-manage::before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-top: -2px;
          margin-right: 11px;
          background: url("../img/list-2.svg") no-repeat center center; }
  .payment-request .payreq-filters-bottom {
    align-items: flex-end;
    max-width: 80%; }
    @media (max-width: 1399px) {
      .payment-request .payreq-filters-bottom {
        justify-content: center;
        max-width: 100%; } }
    .payment-request .payreq-filters-bottom .input-block {
      width: 254px;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 20px;
      margin-bottom: 29px;
      border-radius: 3px; }
      @media (max-width: 1780px) {
        .payment-request .payreq-filters-bottom .input-block {
          padding: 0 10px; } }
      @media (max-width: 1399px) {
        .payment-request .payreq-filters-bottom .input-block {
          margin: 0 10px 29px;
          padding: 0; } }
      .payment-request .payreq-filters-bottom .input-block input,
      .payment-request .payreq-filters-bottom .input-block select {
        border-radius: 3px; }
      .payment-request .payreq-filters-bottom .input-block label:not(.checkbox-container) {
        font-size: 17px; }
      .payment-request .payreq-filters-bottom .input-block--date {
        width: 160px;
        margin-right: 31px;
        position: relative; }
        @media (max-width: 1399px) {
          .payment-request .payreq-filters-bottom .input-block--date {
            margin-right: 10px; } }
        .payment-request .payreq-filters-bottom .input-block--date:first-child {
          margin-right: 24px; }
          @media (max-width: 1399px) {
            .payment-request .payreq-filters-bottom .input-block--date:first-child {
              margin-right: 10px; } }
          .payment-request .payreq-filters-bottom .input-block--date:first-child::before {
            content: '-';
            display: inline-block;
            position: absolute;
            bottom: 10px;
            right: -15px; }
            @media (max-width: 1399px) {
              .payment-request .payreq-filters-bottom .input-block--date:first-child::before {
                right: -13px; } }
            @media (max-width: 359px) {
              .payment-request .payreq-filters-bottom .input-block--date:first-child::before {
                display: none; } }
  .payment-request .payreq-total {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 29px;
    padding: 0 10px; }
    @media (max-width: 1399px) {
      .payment-request .payreq-total {
        text-align: center; } }
  .payment-request .payreq-table {
    border: 1px solid #d9d9d9;
    border-bottom: 0;
    display: inline-block;
    flex-direction: column;
    min-width: 100%; }
  .payment-request .payreq-table-wrap {
    margin-left: 0;
    margin-right: 0;
    position: relative;
    overflow-y: visible;
    box-sizing: border-box; }
  .payment-request .payreq-table-col-wrapper {
    display: flex;
    align-items: center;
    min-height: 32px;
    padding-top: 14px; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-col-wrapper {
        padding-top: 0;
        align-items: flex-start; } }
  .payment-request .payreq-table-row {
    display: flex;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #2c2c2c; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-row {
        flex-direction: column;
        background-color: #fff;
        border-bottom: 1px solid #d9d9d9; } }
    .payment-request .payreq-table-row:first-child {
      background-color: #ce171f; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-row:nth-child(2n) {
        background-color: #f7f7f7; } }
  .payment-request .payreq-table-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 82px;
    padding: 23px 15px 26px;
    box-sizing: border-box; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-col::before {
        content: attr(aria-label);
        display: inline-block;
        max-width: 100px;
        width: 100%;
        margin-right: 20px;
        color: #999999; } }
    .payment-request .payreq-table-col.payreq-table-col--top {
      align-items: center !important;
      min-height: 56px;
      padding: 19px 20px;
      font-size: 16px;
      color: #ffffff; }
      @media (max-width: 1399px) {
        .payment-request .payreq-table-col.payreq-table-col--top {
          display: none; } }
    .payment-request .payreq-table-col:not(.payreq-table-col--top) {
      border-bottom: 1px solid #d9d9d9; }
    .payment-request .payreq-table-col:nth-child(2n):not(.payreq-table-col--top) {
      background-color: #f7f7f7; }
      @media (max-width: 1399px) {
        .payment-request .payreq-table-col:nth-child(2n):not(.payreq-table-col--top) {
          background-color: transparent; } }
    .payment-request .payreq-table-col-1 {
      width: 4.3059%;
      max-width: 4.3059%;
      min-width: 4.3059%; }
    .payment-request .payreq-table-col-2 {
      width: 8.9518%;
      max-width: 8.9518%;
      min-width: 8.9518%; }
    .payment-request .payreq-table-col-3 {
      width: 5.4957%;
      max-width: 5.4957%;
      min-width: 5.4957%; }
    .payment-request .payreq-table-col-4 {
      width: 8.8385%;
      max-width: 8.8385%;
      min-width: 8.8385%; }
    .payment-request .payreq-table-col-5 {
      width: 10.0849%;
      max-width: 10.0849%;
      min-width: 10.0849%; }
    .payment-request .payreq-table-col-6 {
      width: 7.6487%;
      max-width: 7.6487%;
      min-width: 7.6487%; }
    .payment-request .payreq-table-col-7 {
      width: 6.5722%;
      max-width: 6.5722%;
      min-width: 6.5722%;
      text-align: left; }
      .payment-request .payreq-table-col-7:not(.payreq-table-col--top),
      .payment-request .payreq-table-col-7:not(.payreq-table-col--top) a {
        color: #2C2C2C; }
    .payment-request .payreq-table-col-8 {
      width: 4.8725%;
      max-width: 4.8725%;
      min-width: 4.8725%; }
    .payment-request .payreq-table-col-9 {
      width: 43.2298%;
      max-width: 43.2298%;
      min-width: 43.2298%; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-col {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        background-color: transparent;
        border-bottom: none; } }
    .payment-request .payreq-table-col > a,
    .payment-request .payreq-table-col .payreq-toggler {
      margin-top: 20px; }
    .payment-request .payreq-table-col > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 30px; }
      .payment-request .payreq-table-col > a.payreq-file {
        font-size: 13px;
        color: #ce171f;
        text-decoration: underline;
        margin: 20px 20px 0 0; }
        @media (min-width: 1024px) {
          .payment-request .payreq-table-col > a.payreq-file:hover {
            text-decoration: none; } }
      .payment-request .payreq-table-col > a.payreq-btn-remove {
        width: 24px; }
        .payment-request .payreq-table-col > a.payreq-btn-remove svg {
          transition: opacity 0.3s ease; }
          @media (min-width: 1024px) {
            .payment-request .payreq-table-col > a.payreq-btn-remove svg:hover {
              opacity: 0.5; } }
    .payment-request .payreq-table-col .payreq-toggler {
      border-radius: 15px !important;
      box-sizing: border-box !important;
      margin-right: 40px; }
      .payment-request .payreq-table-col .payreq-toggler .btn.btn-payreq-toggler-on {
        text-align: left; }
      .payment-request .payreq-table-col .payreq-toggler .btn.btn-payreq-toggler-off {
        text-align: right;
        color: #999999; }
      .payment-request .payreq-table-col .payreq-toggler .toggle-handle.btn.btn-light {
        padding: 0;
        line-height: 2;
        border-radius: 50%;
        width: 22px;
        height: 22px !important;
        border: 1px solid #a5a5a5;
        position: absolute;
        left: 52%;
        top: 50%;
        transform: translateY(-50%);
        transition: all 0.3s ease;
        box-sizing: border-box; }
        .payment-request .payreq-table-col .payreq-toggler .toggle-handle.btn.btn-light.move {
          left: 37%;
          border-color: transparent; }
    @media (max-width: 1399px) {
      .payment-request .payreq-table-col .input-block {
        margin-bottom: 10px; } }
    .payment-request .payreq-table-col .input-block .select-title {
      font-size: 13px;
      line-height: 16px;
      color: #999999; }
    .payment-request .payreq-table-col .input-block select {
      width: 100px;
      height: 30px;
      margin-right: 20px;
      border-radius: 3px;
      font-size: 14px;
      line-height: 13px !important;
      padding: 5px 22px 5px 10px;
      background-position: calc(100% - 8px) center !important;
      text-overflow: ellipsis; }
    .payment-request .payreq-table-col .input-block .info {
      font-size: 13px;
      line-height: 16px;
      margin-top: 5px; }
    .payment-request .payreq-table-col--multiple {
      flex-wrap: wrap; }
      @media (max-width: 1023px) {
        .payment-request .payreq-table-col--multiple {
          width: 85%;
          max-width: 85%;
          min-width: 85%; } }
      @media (max-width: 767px) {
        .payment-request .payreq-table-col--multiple {
          width: 60%;
          max-width: 60%;
          min-width: 60%; } }

.print-page__container {
  max-width: 800px;
  padding: 58px 0 92px;
  margin: 0 auto; }
  @media (max-width: 880px) {
    .print-page__container {
      padding: 30px 10px; } }

.print-page__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 14px;
  border-bottom: 1px solid #e6e6e6; }
  @media (max-width: 767px) {
    .print-page__top {
      flex-direction: column; } }

.print-page__top-group {
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .print-page__top-group {
      flex-direction: column; } }
  @media (max-width: 450px) {
    .print-page__top-group {
      flex-direction: column; } }

.print-page__top-item {
  font-size: 14px;
  color: #999999; }
  .print-page__top-item:not(:last-child) {
    margin-right: 32px; }
    @media (max-width: 767px) {
      .print-page__top-item:not(:last-child) {
        margin-right: 0; } }
  @media (max-width: 767px) {
    .print-page__top-item {
      margin-bottom: 10px; } }
  .print-page__top-item span {
    padding-left: 7px;
    font-size: 16px;
    color: #2c2c2c; }

.print-page__print-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 38px;
  font-size: 14px;
  line-height: 38px; }
  .print-page__print-btn svg {
    margin-right: 11px; }

.print-page .print-detail__title {
  font-size: 18px;
  margin-bottom: 15px; }
  .print-page .print-detail__title span {
    font-size: 16px; }

.print-page .print-detail__table {
  margin-bottom: 32px; }

.print-page .print-detail__row {
  display: flex;
  flex-wrap: wrap; }
  .print-page .print-detail__row:nth-child(odd) {
    background-color: #f7f7f7; }

.print-page .print-detail__item {
  padding: 7px 0 13px 0; }
  @media (max-width: 767px) {
    .print-page .print-detail__item {
      width: auto !important;
      padding: 7px 15px 13px; } }
  .print-page .print-detail__item:first-child {
    width: 26.7%; }
  .print-page .print-detail__item:nth-child(2) {
    width: 22%; }
  .print-page .print-detail__item:nth-child(3) {
    width: 13%; }
  .print-page .print-detail__item:nth-child(4) {
    width: 14.7%; }
  .print-page .print-detail__item:nth-child(5) {
    width: 13%; }
  .print-page .print-detail__item:nth-child(6) {
    width: 10.6%; }
  .print-page .print-detail__item p:first-child {
    font-size: 12px;
    color: #9da4b0; }
  .print-page .print-detail__item p:last-child {
    font-size: 15px; }

.print-page__condition {
  font-size: 16px;
  margin-bottom: 17px; }

.print-page__wrapper {
  display: flex;
  align-items: flex-start;
  margin: 0 -28px 24px -10px; }
  @media (max-width: 767px) {
    .print-page__wrapper {
      flex-direction: column;
      margin: 0 0 24px; } }

.print-page__condition-table {
  width: calc(50% - 30px);
  margin-right: 15px; }
  @media (max-width: 767px) {
    .print-page__condition-table {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; } }
  .print-page__condition-table tr:nth-child(odd) {
    background-color: #f7f7f7; }
  .print-page__condition-table td {
    font-size: 14px;
    padding: 10px; }

.print-page__total-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(50% - 30px);
  padding: 41px 10px;
  margin-left: 15px;
  border: 1px solid #e6e6e6;
  background-color: #f7f7f7;
  font-size: 14px;
  line-height: 24px;
  color: #666666;
  text-align: center;
  box-sizing: border-box; }
  @media (max-width: 767px) {
    .print-page__total-price {
      width: 100%;
      padding: 20px 10px;
      margin: 0; } }
  .print-page__total-price p {
    font-size: 28px;
    color: #ce171f;
    margin-top: 7px; }
    @media (max-width: 767px) {
      .print-page__total-price p {
        font-size: 24px; } }

.print-page .print-note__title {
  font-size: 16px;
  margin-bottom: 10px; }

.print-page .print-note__block {
  padding: 18px 33px 22px;
  background-color: #eef3fb;
  border: 1px solid #3b74ce; }

.print-page .print-note__info {
  display: flex;
  align-items: center;
  margin-bottom: 12px; }
  @media (max-width: 450px) {
    .print-page .print-note__info {
      flex-direction: column; } }
  .print-page .print-note__info svg {
    max-width: 24px;
    width: 100%;
    fill: #3b74ce;
    margin-right: 2px; }

.print-page .print-note__name {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  margin-right: 14px; }
  @media (max-width: 450px) {
    .print-page .print-note__name {
      margin-bottom: 10px; } }

.print-page .print-note__date {
  font-size: 14px;
  color: #808080; }

.print-page .print-note__text {
  font-size: 14px;
  line-height: 24px;
  color: #4d4d4d; }

.print-page__finish-text {
  margin: 33px 0 35px;
  font-size: 15px;
  line-height: 26px; }
  @media (max-width: 767px) {
    .print-page__finish-text {
      font-size: 14px;
      line-height: 23px;
      margin: 15px 0; } }
  @media (max-width: 767px) {
    .print-page__finish-text br {
      display: none; } }
  .print-page__finish-text--center {
    text-align: center;
    margin: 0; }

.print-page__button {
  width: auto;
  height: 40px;
  margin: 12px auto 13px;
  padding: 0 17px;
  font-size: 15px;
  line-height: 40px; }
  @media (max-width: 767px) {
    .print-page__button {
      height: auto;
      padding: 8px 17px;
      font-size: 14px;
      line-height: 18px; } }

.quote-page .page-title {
  padding: 61px 0 32px;
  text-align: center; }

.quote-page .title {
  font-size: 35px;
  line-height: 33px;
  margin-bottom: 14px; }

.quote-page .subtitle {
  font-size: 30px;
  line-height: 33px; }

.quote-page .form-block {
  overflow: hidden; }

.quote-page .form-block.lookup {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 15px 50px; }
  @media (min-width: 768px) {
    .quote-page .form-block.lookup {
      padding: 0 0 100px; } }
  .quote-page .form-block.lookup .input-block {
    max-width: 347px;
    width: 100%;
    margin: 0 10px 0 0; }
  .quote-page .form-block.lookup .btn {
    width: 94px;
    height: 35px;
    font-size: 15px;
    line-height: 35px;
    font-weight: 400;
    margin: 0; }

.quote-page .form-block .input-block {
  margin-bottom: 17px; }
  .quote-page .form-block .input-block label {
    width: 100%;
    margin-bottom: 0; }
    .quote-page .form-block .input-block label span {
      color: #ce171f; }
  .quote-page .form-block .input-block input[type="text"],
  .quote-page .form-block .input-block select {
    height: 35px;
    font-size: 14px;
    margin-top: 4px;
    padding: 12px 9px; }
  .quote-page .form-block .input-block select {
    padding: 5px;
    background-image: url(../img/select_hov.svg); }
  .quote-page .form-block .input-block .datepicker-input {
    background: #fff url(../img/datepicker.png) no-repeat;
    background-position: calc(100% - 5px) center;
    background-size: 26px;
    resize: none; }
  .quote-page .form-block .input-block--checkbox {
    margin-bottom: 0; }
    .quote-page .form-block .input-block--checkbox label {
      display: flex;
      align-items: center; }
      .quote-page .form-block .input-block--checkbox label span {
        color: #2c2c2c; }
      .quote-page .form-block .input-block--checkbox label input {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
        cursor: pointer;
        border: 1px solid #a5a5a5;
        background-color: #fff;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none; }
        .quote-page .form-block .input-block--checkbox label input:checked {
          background-image: url(../img/checked.svg);
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 100%; }

.quote-page .quote {
  width: 100%;
  max-width: 936px;
  margin: 0 auto;
  padding: 0 15px 70px;
  box-sizing: border-box; }
  @media (min-width: 950px) {
    .quote-page .quote {
      padding: 0 0 170px; } }
  .quote-page .quote__vehicle {
    font-size: 24px;
    text-align: center; }
    .quote-page .quote__vehicle span {
      font-size: 18px;
      color: #757575; }
  .quote-page .quote__reset {
    font-size: 14px;
    font-weight: 400;
    color: #ce171f;
    text-decoration: underline;
    background-color: #fff;
    margin: 4px auto 0; }
  .quote-page .quote__details {
    margin-top: 10px;
    margin-bottom: 20px; }
  .quote-page .quote__detail {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6; }
    .quote-page .quote__detail:first-child {
      background-color: #f2f2f2;
      border-bottom: none; }
      @media (min-width: 768px) {
        .quote-page .quote__detail:first-child .quote__detail-item:first-child {
          width: 34.324%; }
        .quote-page .quote__detail:first-child .quote__detail-item:nth-child(2) {
          width: 23.504%; }
        .quote-page .quote__detail:first-child .quote__detail-item:nth-child(3) {
          width: 20.94%; }
        .quote-page .quote__detail:first-child .quote__detail-item:last-child {
          width: 21.22%; } }
    .quote-page .quote__detail:nth-child(2) .quote__detail-item {
      padding: 9px 17px; }
      @media (min-width: 768px) {
        .quote-page .quote__detail:nth-child(2) .quote__detail-item:first-child {
          width: 34.4%; }
        .quote-page .quote__detail:nth-child(2) .quote__detail-item:nth-child(2) {
          width: 65.6%; } }
    @media (min-width: 768px) {
      .quote-page .quote__detail:last-child .quote__detail-item:first-child {
        width: 22%; }
      .quote-page .quote__detail:last-child .quote__detail-item:nth-child(2) {
        width: 20.8%; }
      .quote-page .quote__detail:last-child .quote__detail-item:nth-child(3) {
        width: 20.4%; }
      .quote-page .quote__detail:last-child .quote__detail-item:nth-child(4) {
        width: 18.2%; }
      .quote-page .quote__detail:last-child .quote__detail-item:last-child {
        width: 18.6%; } }
    .quote-page .quote__detail:last-child .quote__detail-item p:first-child {
      margin-bottom: 3px; }
    .quote-page .quote__detail:last-child .quote__detail-item p:last-child {
      font-size: 16px; }
  .quote-page .quote__detail-item {
    width: 50%;
    padding: 13px 17px 0;
    box-sizing: border-box;
    margin-bottom: 17px; }
    .quote-page .quote__detail-item p:first-child {
      font-size: 13px;
      color: #757575;
      margin-bottom: 5px; }
    .quote-page .quote__detail-item p:last-child {
      font-size: 14px; }
  .quote-page .quote__flex {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; }
    @media (min-width: 768px) {
      .quote-page .quote__flex {
        flex-direction: row;
        margin: 0 -21px; } }
  .quote-page .quote__col {
    width: 100%; }
    @media (min-width: 768px) {
      .quote-page .quote__col {
        width: calc(50% - 42px);
        margin: 0 21px; } }
  .quote-page .quote .summary {
    width: 100%;
    text-align: center;
    font-size: 20px;
    margin: 15px 0 13px; }
  .quote-page .quote__flex-inner {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .quote-page .quote__flex-inner {
        flex-direction: row;
        margin: 0 -10px; } }
    .quote-page .quote__flex-inner .input-block {
      width: 100%;
      margin-bottom: 17px; }
      @media (min-width: 768px) {
        .quote-page .quote__flex-inner .input-block {
          width: calc(50% - 20px);
          margin: 0 10px 17px; } }
  .quote-page .quote__block-title {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 12px; }
    .quote-page .quote__block-title--small {
      font-size: 18px;
      margin-bottom: 6px; }
  .quote-page .quote__table table {
    width: 100%;
    border-collapse: collapse; }
    .quote-page .quote__table table tr:nth-child(odd) {
      background-color: #f7f7f7; }
    .quote-page .quote__table table tr:last-child {
      border-top: 1px solid #e6e6e6;
      text-align: right;
      background-color: #fff; }
      .quote-page .quote__table table tr:last-child span {
        font-size: 18px; }
    .quote-page .quote__table table td {
      padding: 16px 15px;
      font-size: 16px; }
      .quote-page .quote__table table td:first-child {
        width: 90%; }
      .quote-page .quote__table table td:last-child {
        width: 10%;
        text-align: right; }
  .quote-page .quote__table--add-on .quote__block-title {
    margin-bottom: 5px; }
  .quote-page .quote__table--add-on table tr:last-child td {
    padding-top: 25px; }
  .quote-page .quote__table--add-on table td {
    padding: 15px 13px; }
  .quote-page .quote__result {
    display: flex;
    justify-content: center;
    margin-top: 19px;
    padding-top: 27px;
    text-align: center;
    border-top: 1px solid #d9d9d9; }
    .quote-page .quote__result .total {
      font-size: 18px;
      margin-right: 21px; }
      .quote-page .quote__result .total span {
        font-size: 20px; }
    .quote-page .quote__result .per-month {
      font-size: 15px; }
      .quote-page .quote__result .per-month span {
        font-size: 16px; }
  .quote-page .quote__nav-wrapper {
    width: 100%;
    max-width: 751px;
    margin: 0 auto; }
  .quote-page .quote__nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 19px -23px 0;
    padding: 0 10px; }
    @media (min-width: 768px) {
      .quote-page .quote__nav {
        flex-direction: row;
        padding: 0; } }
  .quote-page .quote__nav-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 16px; }
    @media (min-width: 768px) {
      .quote-page .quote__nav-col {
        width: calc(50% - 46px);
        margin: 0 23px; } }
    .quote-page .quote__nav-col .btn {
      width: calc(50% - 20px);
      height: 35px;
      font-size: 14px;
      line-height: 35px;
      font-weight: 400;
      margin: 0 10px; }
      @media (min-width: 768px) {
        .quote-page .quote__nav-col .btn {
          width: 168px;
          margin: 0 4px; } }
      .quote-page .quote__nav-col .btn:not(:last-child) {
        margin-bottom: 16px; }
        @media (min-width: 768px) {
          .quote-page .quote__nav-col .btn:not(:last-child) {
            margin-bottom: 0; } }
      .quote-page .quote__nav-col .btn--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ce1820;
        background-color: #fff;
        color: #ce1820;
        transition: all 0.3s ease; }
        .quote-page .quote__nav-col .btn--icon:hover {
          background-color: #fff;
          color: #981316;
          border-color: #981316; }
          .quote-page .quote__nav-col .btn--icon:hover svg {
            fill: #981316; }
        .quote-page .quote__nav-col .btn--icon svg {
          fill: #ce1820;
          margin-right: 10px; }

.quote-page .quote-customer {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin: 50px 0 41px;
  padding: 40px 0 21px; }
  .quote-page .quote-customer + .quote-customer {
    border-top: none;
    margin-top: 0;
    padding-top: 0; }
  .quote-page .quote-customer .input-block {
    margin-bottom: 19px; }
  .quote-page .quote-customer__container {
    width: 100%;
    max-width: 751px;
    margin: 0 auto; }
  .quote-page .quote-customer__title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 33px; }
  .quote-page .quote-customer__flex {
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .quote-page .quote-customer__flex {
        flex-direction: row;
        margin: 0 -12px; } }
  .quote-page .quote-customer__flex-inner {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 768px) {
      .quote-page .quote-customer__flex-inner {
        margin: 0 -5px;
        align-items: flex-end; } }
    .quote-page .quote-customer__flex-inner .input-block {
      width: 100%;
      margin-bottom: 19px; }
      @media (min-width: 768px) {
        .quote-page .quote-customer__flex-inner .input-block {
          width: calc(71% - 10px);
          margin: 0 5px 32px; } }
    .quote-page .quote-customer__flex-inner .btn {
      width: 100%;
      font-size: 14px;
      line-height: 35px;
      font-weight: 400;
      height: 35px;
      margin: 0 0 19px; }
      @media (min-width: 768px) {
        .quote-page .quote-customer__flex-inner .btn {
          width: calc(29% - 10px);
          margin: 0 5px 32px; } }
  .quote-page .quote-customer__col {
    width: 100%; }
    @media (min-width: 768px) {
      .quote-page .quote-customer__col {
        width: calc(50% - 24px);
        margin: 0 12px; } }
  .quote-page .quote-customer__sms {
    display: flex;
    justify-content: center;
    margin-top: 40px; }
    .quote-page .quote-customer__sms .input-block {
      margin-bottom: 0;
      margin-right: 7px; }
      .quote-page .quote-customer__sms .input-block input[type="text"] {
        margin-top: 0; }
        .quote-page .quote-customer__sms .input-block input[type="text"]::placeholder {
          font-size: 14px;
          color: #999999; }
        .quote-page .quote-customer__sms .input-block input[type="text"]:focus::placeholder {
          opacity: 0; }
    .quote-page .quote-customer__sms .btn--sign {
      width: 168px;
      height: 35px;
      margin-top: 0;
      font-size: 14px;
      line-height: 35px;
      font-weight: 400; }
  .quote-page .quote-customer__signed {
    font-size: 16px;
    color: #ce1820;
    margin-top: 50px;
    text-align: center; }
  .quote-page .quote-customer--address .quote-customer__title {
    margin-bottom: 25px; }
  @media (min-width: 768px) {
    .quote-page .quote-customer--address .quote-customer__flex-inner {
      margin: 0 -10px; } }
  .quote-page .quote-customer--address .quote-customer__flex-inner .input-block {
    width: 100%;
    margin-bottom: 19px; }
    @media (min-width: 768px) {
      .quote-page .quote-customer--address .quote-customer__flex-inner .input-block {
        width: calc(84% - 20px);
        margin: 0 10px 25px; } }
  .quote-page .quote-customer--address .quote-customer__flex-inner .btn {
    width: 100%;
    margin-bottom: 19px; }
    @media (min-width: 768px) {
      .quote-page .quote-customer--address .quote-customer__flex-inner .btn {
        width: calc(16% - 20px);
        margin: 0 10px 25px; } }
  .quote-page .quote-customer--payment {
    padding-bottom: 40px;
    margin-bottom: 31px; }

.thanks-credit {
  max-width: 562px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 100px;
  box-sizing: border-box; }
  @media (max-width: 609px) {
    .thanks-credit {
      padding: 30px 20px; } }
  .thanks-credit__title {
    font-size: 24px;
    line-height: 33px;
    margin-bottom: 16px; }
    @media (max-width: 609px) {
      .thanks-credit__title {
        font-size: 20px;
        line-height: 28px; } }
  .thanks-credit__list-title {
    font-size: 18px;
    line-height: 33px;
    margin-bottom: 5px; }
    .thanks-credit__list-title span {
      font-size: 14px;
      line-height: 24px;
      color: #999999; }
  .thanks-credit__row {
    font-size: 15px;
    padding: 10px 20px;
    margin: 0 -20px; }
    @media (max-width: 609px) {
      .thanks-credit__row {
        padding: 10px;
        margin: 0; } }
    .thanks-credit__row span:not(:last-child) {
      margin-right: 7px; }
    .thanks-credit__row:nth-child(odd) {
      background-color: #f7f7f7; }
  .thanks-credit__block {
    border-top: 1px solid #e5e5e5;
    margin: 15px -20px 0;
    padding: 22px 20px 0; }
    @media (max-width: 609px) {
      .thanks-credit__block {
        margin: 15px 0 0;
        padding: 22px 0 0; } }
    .thanks-credit__block .btn {
      width: 150px;
      height: 40px;
      margin-top: 0;
      margin-right: 18px;
      font-size: 14px;
      line-height: 40px;
      font-weight: 400; }
      @media (max-width: 609px) {
        .thanks-credit__block .btn {
          margin-right: 0;
          margin-bottom: 10px; } }
      .thanks-credit__block .btn--get-code {
        width: 200px;
        margin-top: 20px; }
        @media (max-width: 609px) {
          .thanks-credit__block .btn--get-code {
            margin: 15px auto 0; } }
    .thanks-credit__block--code {
      padding-top: 24px;
      margin-top: 27px; }
  .thanks-credit__text {
    font-size: 15px;
    line-height: 26px; }
    @media (max-width: 609px) {
      .thanks-credit__text br {
        display: none; } }
    .thanks-credit__text--small {
      font-size: 14px;
      line-height: 23px; }
  .thanks-credit__file {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 14px;
    color: #ce171f;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .thanks-credit__file:hover {
        text-decoration: none; } }
    .thanks-credit__file svg {
      width: 100%;
      max-width: 19px;
      fill: #ce171f;
      margin-right: 5px; }
  .thanks-credit__form {
    display: flex;
    align-items: center;
    margin-top: 21px; }
    @media (max-width: 609px) {
      .thanks-credit__form {
        flex-direction: column; } }
    .thanks-credit__form .input-block {
      margin-right: 10px; }
      @media (max-width: 609px) {
        .thanks-credit__form .input-block {
          margin-right: 0;
          margin-bottom: 10px; } }
      .thanks-credit__form .input-block input[type="text"] {
        width: 230px;
        margin: 0;
        border-radius: 3px; }
        .thanks-credit__form .input-block input[type="text"]::placeholder {
          font-size: 14px; }
  .thanks-credit__new-code {
    font-size: 14px;
    color: #ce171f;
    text-decoration: underline; }
    @media (min-width: 1024px) {
      .thanks-credit__new-code:hover {
        text-decoration: none; } }

.users-management .filters {
  margin-bottom: 5px !important; }
  .users-management .filters .blue--btn:before {
    width: 22px !important;
    height: 16px !important;
    background: url("../img/add-user.png") center center no-repeat !important; }
  .users-management .filters .input-block {
    display: flex;
    justify-content: space-between !important;
    margin-bottom: 0 !important; }
    .users-management .filters .input-block .row-bnts-filter {
      display: flex; }
      .users-management .filters .input-block .row-bnts-filter .reset {
        width: auto;
        margin-left: 18px; }

.users-management .log .table .row .col {
  border-bottom: #d9d9d9 1px solid;
  padding: 15px !important; }
  .users-management .log .table .row .col.btns-col {
    width: 210px !important; }
  .users-management .log .table .row .col .btns-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px; }
  .users-management .log .table .row .col .btn {
    margin: 0 !important;
    width: auto !important; }
    .users-management .log .table .row .col .btn.edit-btn {
      display: block;
      vertical-align: middle;
      width: 30px !important; }
      .users-management .log .table .row .col .btn.edit-btn::before {
        content: '';
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 0;
        margin-bottom: 0;
        background: url("../img/edit.png") center center no-repeat;
        background-size: contain;
        vertical-align: middle; }
    .users-management .log .table .row .col .btn.button--delete {
      display: block;
      width: 30px !important;
      font-size: 14px;
      font-weight: 400;
      color: #ce171f;
      text-decoration: underline;
      vertical-align: middle; }
      .users-management .log .table .row .col .btn.button--delete::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-right: 0;
        margin-bottom: 0;
        background: url("../img/delete.png") center center no-repeat;
        background-size: contain;
        vertical-align: middle; }
    .users-management .log .table .row .col .btn.button--block {
      display: block;
      height: 25px;
      font-size: 14px;
      line-height: initial;
      font-weight: 400;
      color: #ce171f;
      text-decoration: underline; }
    .users-management .log .table .row .col .btn.button--unblock, .users-management .log .table .row .col .btn.button--change {
      color: #ce171f;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
      display: block;
      height: 25px;
      line-height: initial; }
      @media (min-width: 1024px) {
        .users-management .log .table .row .col .btn.button--unblock:hover, .users-management .log .table .row .col .btn.button--change:hover {
          text-decoration: none; } }
    .users-management .log .table .row .col .btn.reset-btn {
      display: block;
      height: 25px;
      font-size: 14px;
      line-height: initial;
      color: #ce171f; }

.users-management .btn-details-um {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  height: 30px;
  padding: 6px 11px 7px 9px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-align: right;
  transition-duration: 0.2s;
  box-sizing: border-box; }
  .users-management .btn-details-um::before {
    content: '';
    display: block;
    width: 22px;
    height: 19px; }
  .users-management .btn-details-um:not(.btn-details-um--alert) {
    color: #ce171f;
    background-color: #f7f7f7;
    border: 1px solid #ce171f; }
    .users-management .btn-details-um:not(.btn-details-um--alert)::before {
      background: url(../img/htr.png) no-repeat center center;
      background-size: contain; }
    @media (min-width: 1024px) {
      .users-management .btn-details-um:not(.btn-details-um--alert):hover {
        background-color: #ffcbce; } }
  .users-management .btn-details-um--alert {
    background-color: #ce171f;
    color: #ffffff; }
    .users-management .btn-details-um--alert::before {
      background: url(../img/problem-white.png) no-repeat center center;
      background-size: contain; }
    @media (min-width: 1024px) {
      .users-management .btn-details-um--alert:hover {
        background-color: #841215; } }

.video-page {
  margin-bottom: 38px;
  padding-bottom: 0; }
  @media (max-width: 660px) {
    .video-page .page-main-title .title {
      font-size: 24px; } }
  .video-page .inner2 {
    max-width: 1162px; }
    @media (max-width: 660px) {
      .video-page .inner2 {
        width: 100%; } }
  .video-page .relative_block {
    position: relative;
    display: flex;
    margin-top: 60px; }
    @media (max-width: 1100px) {
      .video-page .relative_block {
        flex-direction: column; }
        .video-page .relative_block .left {
          width: 100%;
          margin: 0; } }
    @media (max-width: 660px) {
      .video-page .relative_block {
        margin-top: 37px; } }
    .video-page .relative_block > .left {
      flex-shrink: 0;
      width: 66%;
      margin-right: 3%; }
      @media (max-width: 1100px) {
        .video-page .relative_block > .left {
          width: 100%;
          margin: 0; } }
    .video-page .relative_block .personal-table__title {
      margin-bottom: 7px;
      border-bottom: 0;
      font-size: 21px; }
    .video-page .relative_block .personal-table__table-row:nth-child(2n+1) {
      background-color: #F2F2F2; }
    .video-page .relative_block .personal-table__table-row .personal-table__table-cell {
      height: 41px; }
      .video-page .relative_block .personal-table__table-row .personal-table__table-cell--w30 {
        padding-right: 21px; }
    .video-page .relative_block .personalise__left-side {
      width: 99%; }
    .video-page .relative_block .Choose-colors {
      margin-top: 10px;
      letter-spacing: 0.7px;
      font-size: 18px;
      line-height: 28px; }
  .video-page .text_block {
    display: flex;
    flex-direction: column;
    max-width: 435px;
    width: 34%;
    color: #2c2c2c; }
    @media (max-width: 1100px) {
      .video-page .text_block {
        width: 100%; } }
    @media (max-width: 660px) {
      .video-page .text_block {
        padding: 0 20px; } }
    .video-page .text_block .ah2 {
      font-size: 23px;
      line-height: 26px;
      margin-top: 16px; }
      @media (max-width: 660px) {
        .video-page .text_block .ah2 {
          margin-top: 60px; } }
    .video-page .text_block .ah3 {
      font-size: 15px;
      line-height: 23px;
      margin-top: 22px; }
    .video-page .text_block .description {
      margin-top: 20px; }
      .video-page .text_block .description li {
        display: flex;
        margin-bottom: 12px;
        font-size: 17px;
        line-height: 22px; }
        .video-page .text_block .description li::before {
          content: '';
          display: block;
          width: 5px;
          height: 5px;
          flex-shrink: 0;
          margin-right: 11px;
          margin-top: 10px;
          background-color: #ce171f; }
  .video-page .video_block {
    position: relative;
    width: 100%;
    height: 429px; }
    @media (max-width: 660px) {
      .video-page .video_block {
        height: 217px; } }
    .video-page .video_block iframe {
      width: 100%;
      height: 100%;
      z-index: 1; }
    .video-page .video_block img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1; }
  .video-page .video_like-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 15px; }
    @media (max-width: 1180px) {
      .video-page .video_like-block {
        justify-content: center; } }
    @media (max-width: 660px) {
      .video-page .video_like-block {
        padding: 0 20px; } }
    .video-page .video_like-block p {
      font-size: 19px;
      color: #2c2c2c; }
      @media (max-width: 1180px) {
        .video-page .video_like-block p {
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          width: 100%;
          margin-bottom: 18px; } }
      @media (max-width: 660px) {
        .video-page .video_like-block p {
          padding: 0 20px; } }
      @media (max-width: 330px) {
        .video-page .video_like-block p {
          padding: 0; } }
    .video-page .video_like-block a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 141px;
      height: 46px;
      font-size: 18px;
      line-height: 46px; }
      @media (max-width: 660px) {
        .video-page .video_like-block a {
          width: calc(50% - 5px); } }
      .video-page .video_like-block a::before {
        content: '';
        display: block;
        width: 20px;
        height: 18px; }
      .video-page .video_like-block a.like_button {
        background-color: #124390;
        transition: 0.3s; }
        @media (max-width: 1180px) {
          .video-page .video_like-block a.like_button {
            margin-right: 10px; } }
        @media (max-width: 660px) {
          .video-page .video_like-block a.like_button {
            margin-right: 5px; } }
        @media (min-width: 1024px) {
          .video-page .video_like-block a.like_button:hover {
            background-color: #58585a; } }
        .video-page .video_like-block a.like_button::before {
          margin-right: 6px;
          margin-top: -5px;
          background: center/cover url(./../img/like-punch.svg) no-repeat; }
      .video-page .video_like-block a.dislike_button {
        background-color: #666666; }
        @media (max-width: 660px) {
          .video-page .video_like-block a.dislike_button {
            margin-left: 5px; } }
        .video-page .video_like-block a.dislike_button::before {
          margin-right: 10px;
          margin-top: 5px;
          background: center/cover url(./../img/dislike-punch.svg) no-repeat; }
  .video-page .bottom_block {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 0;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    margin-top: 60px; }
    @media (max-width: 660px) {
      .video-page .bottom_block {
        flex-direction: column;
        margin-top: 20px;
        padding: 20px; } }
    .video-page .bottom_block .call_block {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #2c2c2c;
      margin-right: 51px; }
      .video-page .bottom_block .call_block span {
        font-size: 16px; }
      .video-page .bottom_block .call_block a {
        font-size: 22px;
        color: #2c2c2c;
        display: flex;
        justify-content: center;
        align-items: center; }
        .video-page .bottom_block .call_block a::before {
          content: '';
          flex-shrink: 0;
          display: block;
          width: 18px;
          height: 18px;
          margin-left: 11px;
          margin-right: 12px;
          background: center/cover url(./../img/call-red.svg) no-repeat; }
      @media (max-width: 660px) {
        .video-page .bottom_block .call_block {
          display: none; } }
    .video-page .bottom_block .mail_button {
      width: 265px;
      height: 40px;
      font-size: 18px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ce181f;
      margin-right: 10px;
      transition: 0.3s; }
      .video-page .bottom_block .mail_button::before {
        content: '';
        display: block;
        width: 20px;
        height: 16px;
        margin-right: 11px;
        margin-left: -20px;
        background: center/cover url(./../img/mail.svg) no-repeat; }
      @media (max-width: 660px) {
        .video-page .bottom_block .mail_button {
          margin-right: 0;
          margin-top: 10px;
          width: 100%;
          height: 46px; }
          .video-page .bottom_block .mail_button::before {
            margin-left: 0; } }
      @media (min-width: 1024px) {
        .video-page .bottom_block .mail_button:hover {
          background-color: #841215; } }
    .video-page .bottom_block .mobile_tel-button {
      display: none;
      transition: 0.3s; }
      @media (max-width: 660px) {
        .video-page .bottom_block .mobile_tel-button {
          width: 100%;
          height: 46px;
          font-size: 18px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ce181f;
          margin-right: 0; }
          .video-page .bottom_block .mobile_tel-button::before {
            content: '';
            display: block;
            width: 18px;
            height: 18px;
            margin-right: 11px;
            margin-left: 0;
            background: center/cover url(./../img/call-w.svg) no-repeat; } }
      @media (min-width: 1024px) {
        .video-page .bottom_block .mobile_tel-button:hover {
          background-color: #841215; } }

@media screen and (max-width: 660px) {
  .vidms-page .inner2 {
    max-width: 96%; }
  .vidms-page .bottom_block {
    padding-left: 0;
    padding-right: 0; }
  .vidms-page .text_block {
    padding-left: 0;
    padding-right: 0; } }

@media (max-width: 1100px) {
  .vidms-page .text_block {
    margin-top: 30px;
    max-width: 100%; } }

.vidms-page .relative_block.vidms-relative-block {
  margin-top: 28px; }

.vidms-page .vidms-top-code {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #666666;
  margin-top: 50px; }

.vidms-page .vidms-title {
  font-size: 26px;
  line-height: 28px;
  font-weight: bold;
  color: #2c2c2c; }

.vidms-page .vidms-chars-with-pics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px; }
  @media (max-width: 580px) {
    .vidms-page .vidms-chars-with-pics {
      flex-wrap: wrap; } }

.vidms-page .vidms-cwp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3px; }
  @media (max-width: 580px) {
    .vidms-page .vidms-cwp {
      width: calc(33.333% - 10px);
      margin-bottom: 15px; } }
  .vidms-page .vidms-cwp__text {
    font-size: 14px;
    line-height: 1.2;
    font-weight: bold;
    color: #2c2c2c;
    text-align: center;
    flex-grow: 1; }
  .vidms-page .vidms-cwp__pic {
    width: 32px;
    height: 32px;
    margin-top: 10px; }
    .vidms-page .vidms-cwp__pic img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
      font-family: 'object-fit: contain; object-position: center center;'; }

.vidms-page .vidms-buttons-row-after-video {
  display: flex;
  justify-content: space-between;
  margin-top: 30px; }
  @media (max-width: 798px) {
    .vidms-page .vidms-buttons-row-after-video {
      flex-direction: column;
      align-items: center; }
      .vidms-page .vidms-buttons-row-after-video .btn {
        width: 400px;
        max-width: 100%; }
        .vidms-page .vidms-buttons-row-after-video .btn + .btn {
          margin-top: 10px; } }

.vidms-page .vidms--btn-price {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80px;
  margin-bottom: 36px;
  padding: 27px 15px;
  font-size: 30px;
  line-height: 1.3;
  font-weight: 400;
  color: #ffffff;
  box-sizing: border-box; }

.vidms-page .vidms-right-block-title {
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  color: #2c2c2c; }

.vidms-page .vidms-right-block-text {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #2c2c2c;
  margin-top: 19px; }

.vidms-page .cr-yt-video-wrap--vidms.cr-yt-video-wrap::before {
  padding-top: 56.55%; }

.video-appointment__inner {
  max-width: 995px;
  margin: 31px auto 100px;
  padding: 0 20px;
  width: 100%;
  box-sizing: border-box; }
  @media (min-width: 768px) {
    .video-appointment__inner {
      margin: 71px auto 100px; } }
  @media (min-width: 1024px) {
    .video-appointment__inner {
      padding: 0; } }

.video-appointment .form-block .input-block.err-required::after {
  position: absolute;
  bottom: -18px;
  top: auto; }

.video-appointment .row-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 768px) {
    .video-appointment .row-wrapper {
      margin: 0 -10px; } }
  .video-appointment .row-wrapper .input-block {
    width: 100%;
    margin-bottom: 23px;
    position: relative; }
    @media (min-width: 768px) {
      .video-appointment .row-wrapper .input-block {
        width: calc(33.3333% - 20px);
        margin: 0 10px 23px; } }
    .video-appointment .row-wrapper .input-block label {
      width: 100%;
      margin-bottom: 0; }
      .video-appointment .row-wrapper .input-block label input,
      .video-appointment .row-wrapper .input-block label select {
        margin-top: 11px; }

.video-appointment .row {
  margin-bottom: 26px; }
  @media (min-width: 768px) {
    .video-appointment .row {
      margin-bottom: 20px; } }
  .video-appointment .row .row-title {
    margin-top: 38px;
    margin-bottom: 25px;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    width: 100%; }
    @media (min-width: 768px) {
      .video-appointment .row .row-title {
        margin-bottom: 14px; } }

.video-appointment .flex-block {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 768px) {
    .video-appointment .flex-block {
      flex-direction: row; } }
  .video-appointment .flex-block .input-block {
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .video-appointment .flex-block .input-block {
        width: 318px; } }
    .video-appointment .flex-block .input-block:nth-child(2) {
      margin-top: 25px; }
      @media (min-width: 768px) {
        .video-appointment .flex-block .input-block:nth-child(2) {
          margin-top: 0;
          margin-left: 21px; } }
    .video-appointment .flex-block .input-block label {
      width: 100%;
      margin-bottom: 0; }
      .video-appointment .flex-block .input-block label span {
        color: #ce181f; }
      .video-appointment .flex-block .input-block label input {
        margin-top: 12px; }

.video-appointment .application {
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  padding: 35px 0 29px;
  margin: 0 -20px; }
  @media (min-width: 768px) {
    .video-appointment .application {
      padding: 40px 0 55px;
      margin: 0; } }
  .video-appointment .application__title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 25px; }
    @media (min-width: 768px) {
      .video-appointment .application__title {
        margin-bottom: 34px; } }
    @media (min-width: 768px) {
      .video-appointment .application__title br {
        display: none; } }
  .video-appointment .application__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -10px; }
  .video-appointment .application__radio {
    display: flex;
    align-items: center;
    width: calc(100% - 60px);
    height: 100px;
    margin-bottom: 20px;
    padding: 16px 20px 20px;
    border: 1px solid #a5a5a5;
    background-color: #ffffff;
    box-sizing: border-box; }
    @media (min-width: 768px) {
      .video-appointment .application__radio {
        flex-direction: column;
        justify-content: space-between;
        width: 200px;
        height: auto;
        margin: 0 10px;
        padding: 16px 40px 20px; } }
    .video-appointment .application__radio > img {
      display: none; }
      @media (min-width: 768px) {
        .video-appointment .application__radio > img {
          display: block; } }
    .video-appointment .application__radio input {
      display: none; }
      .video-appointment .application__radio input:checked + .circle::before {
        border-color: #dd2036; }
      .video-appointment .application__radio input:checked + .circle::after {
        display: block; }
    .video-appointment .application__radio .circle {
      display: flex;
      align-items: center;
      font-size: 16px;
      position: relative;
      margin-left: 30px; }
      @media (min-width: 768px) {
        .video-appointment .application__radio .circle {
          margin-top: 10px; } }
      .video-appointment .application__radio .circle::before, .video-appointment .application__radio .circle::after {
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        border: 1px solid #a7a7a7;
        border-radius: 50%;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: -30px;
        transform: translateY(-50%); }
        @media (min-width: 768px) {
          .video-appointment .application__radio .circle::before, .video-appointment .application__radio .circle::after {
            width: 20px;
            height: 20px; } }
      .video-appointment .application__radio .circle::after {
        display: none;
        width: 16px;
        height: 16px;
        background-color: #dd2036;
        border: none;
        left: -22px; }
        @media (min-width: 768px) {
          .video-appointment .application__radio .circle::after {
            width: 12px;
            height: 12px;
            left: -25px; } }
      .video-appointment .application__radio .circle img {
        max-width: 65px;
        margin: 0 28px; }
        @media (min-width: 768px) {
          .video-appointment .application__radio .circle img {
            max-width: 100%;
            display: none; } }
    .video-appointment .application__radio--red {
      border-color: #dd2036; }

.video-appointment .btn {
  width: 100%;
  max-width: 100%;
  margin: 30px auto 0; }
  @media (min-width: 768px) {
    .video-appointment .btn {
      width: 266px; } }

.video-page-editing {
  padding-bottom: 100px; }
  .video-page-editing .page-main-title {
    margin-bottom: 32px; }
  .video-page-editing .button_block {
    text-align: right; }
    @media (max-width: 999px) {
      .video-page-editing .button_block {
        display: none;
        text-align: center; } }
    .video-page-editing .button_block a {
      display: inline-block;
      vertical-align: top;
      font-size: 13px; }
      @media (max-width: 999px) {
        .video-page-editing .button_block a {
          margin-right: 7px;
          margin-bottom: 10px;
          margin-left: 7px; } }
      .video-page-editing .button_block a.btn--blue::before {
        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        margin-top: -3px;
        margin-right: 10px;
        vertical-align: middle;
        background: url("../img/save.svg") no-repeat; }
      .video-page-editing .button_block a.btn--gray {
        margin-left: 7px;
        background: #4d4d4d; }
        @media (min-width: 1024px) {
          .video-page-editing .button_block a.btn--gray:hover {
            opacity: 0.9; } }
    .video-page-editing .button_block.button_block2 {
      text-align: center; }
      @media (max-width: 999px) {
        .video-page-editing .button_block.button_block2 {
          display: block; } }
  .video-page-editing .ah2 {
    margin-bottom: 18px;
    font-size: 22px;
    line-height: 25px;
    font-weight: bold; }
    .video-page-editing .ah2.second {
      margin-top: 20px; }
  .video-page-editing .ah3 {
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold; }
  .video-page-editing .ah4 {
    margin-bottom: 3px;
    font-size: 16px; }
    .video-page-editing .ah4 span {
      color: #a6a6a6;
      font-size: 13px; }
  .video-page-editing input[type="text"],
  .video-page-editing textarea {
    width: 100%;
    margin-bottom: 17px;
    padding: 8px 15px;
    border: 1px solid #a5a5a5;
    outline: 0;
    font-size: 16px;
    line-height: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    box-sizing: border-box; }
  .video-page-editing textarea {
    height: 138px;
    margin-bottom: 29px; }
  .video-page-editing .flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    padding-bottom: 31px;
    border-bottom: 1px solid #e6e6e6; }
    @media (max-width: 999px) {
      .video-page-editing .flex {
        display: block; } }
    .video-page-editing .flex > div {
      width: 100%; }
    .video-page-editing .flex .block {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 248px;
      border: 1px solid #a5a5a5;
      background: #eef3fb; }
      .video-page-editing .flex .block span {
        color: #026fa8;
        font-size: 18px; }
        .video-page-editing .flex .block span::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          margin-top: -2px;
          margin-right: 8px;
          vertical-align: middle;
          background: url("../img/file.svg") no-repeat; }
      .video-page-editing .flex .block .img {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: cover;
        position: absolute; }
      .video-page-editing .flex .block input {
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        position: absolute; }
    .video-page-editing .flex .left_block {
      flex-shrink: 0;
      width: 65%;
      margin-right: 23px; }
      @media (max-width: 999px) {
        .video-page-editing .flex .left_block {
          width: auto;
          margin-right: 0;
          margin-bottom: 25px; } }
  .video-page-editing .check_block {
    display: flex;
    margin-bottom: 15px;
    font-family: "Vauxhall Neue", sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    color: #2c2c2c; }
    .video-page-editing .check_block input {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-top: 0;
      margin-right: 9px;
      margin-bottom: 0;
      margin-left: 0;
      cursor: pointer;
      border: 1px solid #a5a5a5;
      background-color: #fff;
      -webkit-appearance: none;
      -moz-appearance: none; }
      .video-page-editing .check_block input:checked {
        background-image: url(../img/checked.svg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%; }
    .video-page-editing .check_block i {
      font-size: 13px;
      font-style: normal;
      color: #a6a6a6; }
  .video-page-editing hr {
    height: 1px;
    margin-bottom: 30px;
    border: 0;
    background: #e6e6e6; }
  .video-page-editing .add-module {
    display: block;
    margin-bottom: 30px;
    background: #eef3fb;
    font-size: 13px;
    line-height: 49px;
    text-align: center;
    color: #026fa8; }
    .video-page-editing .add-module::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 8px;
      vertical-align: middle;
      background: url("../img/file.svg") no-repeat; }
  .video-page-editing .a_block {
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e6e6e6; }
    .video-page-editing .a_block > .btn-vc-module-add-btn {
      margin-top: 20px; }
    .video-page-editing .a_block .top_buttons {
      float: right;
      margin-bottom: 8px; }
      @media (max-width: 350px) {
        .video-page-editing .a_block .top_buttons {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center; } }
      .video-page-editing .a_block .top_buttons a {
        display: inline-block;
        margin-left: 5px;
        padding: 0 10px;
        vertical-align: top;
        font-size: 13px;
        line-height: 28px;
        transition: opacity 0.2s; }
        @media (max-width: 810px) {
          .video-page-editing .a_block .top_buttons a {
            margin-bottom: 10px; } }
        @media (max-width: 350px) {
          .video-page-editing .a_block .top_buttons a {
            width: 100%;
            margin-bottom: 10px;
            margin-left: 0;
            box-sizing: border-box; } }
        @media (min-width: 1024px) {
          .video-page-editing .a_block .top_buttons a:hover {
            opacity: 0.8; } }
        .video-page-editing .a_block .top_buttons a.red {
          color: #ce171f;
          border: 1px solid #ce171f; }
          .video-page-editing .a_block .top_buttons a.red::before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-top: -2px;
            margin-right: 6px;
            vertical-align: middle;
            background: url("../img/close-btn-2.png") no-repeat;
            background-size: 100% auto; }
        .video-page-editing .a_block .top_buttons a.blue {
          color: #1077ad;
          border: 1px solid #1077ad;
          background: #eef3fb; }
          .video-page-editing .a_block .top_buttons a.blue::before {
            content: '';
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-top: -2px;
            margin-right: 6px;
            vertical-align: middle;
            background: url("../img/insert.svg") no-repeat;
            background-size: 100% auto; }
    .video-page-editing .a_block .title {
      float: left;
      font-weight: bold;
      font-size: 18px;
      line-height: 30px; }
      @media (max-width: 810px) {
        .video-page-editing .a_block .title {
          line-height: 1.3;
          margin-bottom: 10px; } }
    .video-page-editing .a_block .oveflow-vc-modue-table {
      overflow-x: auto; }
      .video-page-editing .a_block .oveflow-vc-modue-table table {
        min-width: 768px;
        margin-bottom: 5px; }
    .video-page-editing .a_block table {
      width: 100%; }
      .video-page-editing .a_block table tr {
        display: table-row !important; }
      .video-page-editing .a_block table tr:nth-child(even) {
        background: #f2f2f2; }
      .video-page-editing .a_block table td {
        width: auto !important;
        height: 70px;
        padding: 7px 10px;
        vertical-align: middle;
        box-sizing: border-box; }
        .video-page-editing .a_block table td input {
          width: 100%;
          padding: 8px 15px;
          margin-bottom: 0;
          border: 1px solid #a5a5a5;
          outline: 0;
          font-size: 16px;
          line-height: 1em;
          -webkit-appearance: none;
          box-sizing: border-box; }
          .video-page-editing .a_block table td input[type="number"] {
            padding-right: 0; }
      .video-page-editing .a_block table th {
        padding: 6px 10px;
        font-size: 16px;
        text-align: left; }
    .video-page-editing .a_block tr td:first-child {
      width: calc(100% - 116px) !important; }
    .video-page-editing .a_block tr td:nth-child(2) {
      width: 116px !important; }
    .video-page-editing .a_block tr td:nth-child(3) {
      width: 150px !important; }
    .video-page-editing .a_block tr td:nth-child(5) {
      text-align: right; }
    .video-page-editing .a_block tr td:nth-child(6) {
      width: 100px !important;
      text-align: right; }
    .video-page-editing .a_block .image_block {
      width: 67px;
      height: 50px;
      background-size: cover;
      background-position: center center;
      border: 1px solid #e6e6e6;
      position: relative; }
      .video-page-editing .a_block .image_block .del {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        right: 2px;
        top: 2px;
        background: url(../img/red_x.svg) no-repeat center center #ccc;
        background-size: 8px auto; }
    .video-page-editing .a_block .dell {
      margin-left: 15px;
      font-size: 14px;
      line-height: 37px;
      text-decoration: underline;
      color: #f01019; }
      .video-page-editing .a_block .dell::before {
        content: '';
        display: inline-block;
        width: 13px;
        height: 13px;
        margin-top: -2px;
        margin-right: 6px;
        vertical-align: middle;
        background: url("../img/close-btn-2.png") no-repeat;
        background-size: 100% auto; }
    .video-page-editing .a_block .upload {
      width: 70px;
      height: 30px;
      position: relative;
      border: 0;
      font-size: 14px; }
      .video-page-editing .a_block .upload:before {
        content: 'Upload';
        width: 100%;
        height: 100%;
        background: #026fa8;
        color: #fff;
        text-align: center;
        line-height: 30px;
        position: absolute;
        left: 0;
        top: 0;
        cursor: pointer;
        transition: 0.3s background-color; }
      @media (min-width: 1024px) {
        .video-page-editing .a_block .upload:hover::before {
          background-color: #58585a; } }
    .video-page-editing .a_block .save {
      font-size: 14px;
      line-height: 37px;
      text-decoration: underline;
      color: #f01019; }
    .video-page-editing .a_block select {
      margin-bottom: 0; }
  .video-page-editing select {
    width: 100%;
    max-width: 180px;
    margin-bottom: 15px;
    padding: 9px 28px 10px 15px;
    border: 1px solid #a5a5a5;
    border-radius: 0;
    outline: 0;
    background: #fff url(../img/select_bg.svg) no-repeat;
    background-position: calc(100% - 15px) center;
    font-size: 16px;
    line-height: 1em;
    -webkit-appearance: none;
    -moz-appearance: none;
    resize: none;
    box-sizing: border-box; }
    .video-page-editing select::-ms-expand {
      display: none; }
  .video-page-editing .vpe-datas-row {
    display: flex;
    margin: 0 -10px; }
    @media (max-width: 620px) {
      .video-page-editing .vpe-datas-row {
        flex-direction: column; } }
    .video-page-editing .vpe-datas-row label {
      line-height: 45px;
      margin-right: 15px; }
      @media (max-width: 620px) {
        .video-page-editing .vpe-datas-row label {
          line-height: 24px; } }
    .video-page-editing .vpe-datas-row .input-block {
      white-space: nowrap;
      display: flex;
      margin: 0 10px; }
      @media (max-width: 620px) {
        .video-page-editing .vpe-datas-row .input-block {
          justify-content: space-between; } }
      @media (max-width: 620px) {
        .video-page-editing .vpe-datas-row .input-block {
          flex-direction: column; } }
      @media (max-width: 620px) {
        .video-page-editing .vpe-datas-row .input-block input {
          max-width: 250px; } }
      @media (max-width: 620px) {
        .video-page-editing .vpe-datas-row .input-block input {
          max-width: 100%; } }
  .video-page-editing .btn-vc-module-add-btn {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    height: 30px;
    color: #ffffff;
    background-color: #124390;
    font-weight: 400;
    font-size: 13px;
    line-height: 64px;
    transition-duration: .2s;
    cursor: pointer; }
    @media (max-width: 350px) {
      .video-page-editing .btn-vc-module-add-btn {
        width: 100%;
        height: 45px;
        margin-left: 0; } }
    .video-page-editing .btn-vc-module-add-btn::before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      margin-right: 12px;
      background: url("../img/white-plus.png"); }
    @media (min-width: 1024px) {
      .video-page-editing .btn-vc-module-add-btn:hover {
        opacity: 0.8; } }

.your-order-page .page-title {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px 0;
  height: 132px;
  box-sizing: border-box; }

.your-order-page .page-title-inner {
  width: 100%;
  max-width: 609px;
  margin: 0 auto; }

.your-order-page .title {
  font-size: 35px;
  line-height: 33px; }

.your-order-page .your-order {
  max-width: 609px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  text-align: center; }
  @media (min-width: 609px) {
    .your-order-page .your-order {
      padding: 72px 0 100px; } }
  .your-order-page .your-order .incorrect {
    color: #ce171f; }
  .your-order-page .your-order__title {
    font-size: 20px;
    line-height: 27px;
    font-weight: bold;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .your-order-page .your-order__title {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 39px; } }
    .your-order-page .your-order__title br {
      display: none; }
      @media (min-width: 768px) {
        .your-order-page .your-order__title br {
          display: block; } }
  .your-order-page .your-order__block + .your-order__block {
    margin-top: 17px;
    padding-top: 24px;
    border-top: 1px solid #e6e6e6; }
    .your-order-page .your-order__block + .your-order__block--contacts {
      margin-top: 32px;
      padding-top: 33px; }
    .your-order-page .your-order__block + .your-order__block--sms {
      margin-top: 40px;
      padding-top: 34px; }
  .your-order-page .your-order__number {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 11px; }
    .your-order-page .your-order__number span {
      color: #ce171f; }
  .your-order-page .your-order__info {
    display: flex;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    text-align: left;
    margin: 0 -20px 11px; }
    @media (min-width: 609px) {
      .your-order-page .your-order__info {
        margin: 0 0 11px; } }
  .your-order-page .your-order__info-item {
    padding: 15px 25px;
    box-sizing: border-box; }
    @media (min-width: 768px) {
      .your-order-page .your-order__info-item:first-child {
        width: 24%; }
      .your-order-page .your-order__info-item:nth-child(2) {
        width: 54%; }
      .your-order-page .your-order__info-item:last-child {
        width: 22%; } }
    .your-order-page .your-order__info-item p:first-child {
      font-size: 13px;
      color: #757575;
      margin-bottom: 4px; }
    .your-order-page .your-order__info-item p:last-child {
      font-size: 14px; }
  .your-order-page .your-order__offered-price {
    font-size: 15px; }
  .your-order-page .your-order__terms {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 9px; }
    .your-order-page .your-order__terms a {
      font-weight: bold;
      color: #2c2c2c;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .your-order-page .your-order__terms a:hover {
          text-decoration: none; } }
    .your-order-page .your-order__terms br {
      display: none; }
      @media (min-width: 768px) {
        .your-order-page .your-order__terms br {
          display: block; } }
  .your-order-page .your-order__order {
    display: flex;
    align-items: center;
    justify-content: center; }
    .your-order-page .your-order__order svg {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      fill: #ce171f; }
    .your-order-page .your-order__order a {
      font-size: 15px;
      color: #ce171f;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .your-order-page .your-order__order a:hover {
          text-decoration: none; } }
  .your-order-page .your-order__contacts > a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #2c2c2c;
    margin-bottom: 6px; }
    .your-order-page .your-order__contacts > a svg {
      fill: #ce181f; }
  .your-order-page .your-order__contacts p {
    font-size: 15px; }
    .your-order-page .your-order__contacts p:first-of-type {
      margin-bottom: 8px; }
    .your-order-page .your-order__contacts p:last-of-type a {
      color: #d7444a;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .your-order-page .your-order__contacts p:last-of-type a:hover {
          text-decoration: none; } }
  .your-order-page .your-order__sms {
    font-size: 15px;
    line-height: 23px; }
    .your-order-page .your-order__sms a {
      color: #ce171f;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .your-order-page .your-order__sms a:hover {
          text-decoration: none; } }
  .your-order-page .your-order .form-block {
    margin: 25px 0 11px; }
    .your-order-page .your-order .form-block > p {
      font-size: 16px;
      margin-bottom: 10px; }
    .your-order-page .your-order .form-block .input-block {
      max-width: 200px;
      width: 100%;
      margin-right: 10px; }
    .your-order-page .your-order .form-block input {
      margin-top: 0; }
      .your-order-page .your-order .form-block input::placeholder {
        font-size: 16px;
        color: #999999; }
      .your-order-page .your-order .form-block input:focus::placeholder {
        opacity: 0; }
    .your-order-page .your-order .form-block .btn {
      max-width: 108px;
      margin-top: 0;
      background-color: #ce171f;
      font-size: 16px;
      font-weight: bold; }
  .your-order-page .your-order__sms-flex {
    display: flex;
    max-width: 320px;
    margin: 0 auto; }
  .your-order-page .your-order__btn {
    width: 266px;
    font-size: 18px;
    font-weight: bold;
    margin: 16px auto 0; }
  .your-order-page .your-order__send-again {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #ce171f; }
    .your-order-page .your-order__send-again svg {
      fill: #ce171f; }

.your-order-sorry .page-title {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px 0;
  height: 132px;
  box-sizing: border-box; }

.your-order-sorry .page-title-inner {
  width: 100%;
  max-width: 609px;
  margin: 0 auto; }

.your-order-sorry .title {
  font-size: 35px;
  line-height: 33px; }

.your-order-sorry .your-order {
  max-width: 609px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  text-align: center; }
  @media (min-width: 609px) {
    .your-order-sorry .your-order {
      padding: 85px 0; } }
  @media (min-width: 1024px) {
    .your-order-sorry .your-order {
      padding: 140px 0 358px; } }
  .your-order-sorry .your-order__sorry {
    margin-bottom: 38px; }
    .your-order-sorry .your-order__sorry p:first-child {
      font-size: 30px;
      line-height: 32px;
      font-weight: bold;
      margin-bottom: 23px; }
    .your-order-sorry .your-order__sorry p:last-child {
      font-size: 18px;
      line-height: 20px; }
  .your-order-sorry .your-order__contacts > a {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #2c2c2c;
    margin-bottom: 6px; }
    .your-order-sorry .your-order__contacts > a svg {
      fill: #ce181f; }
  .your-order-sorry .your-order__contacts p {
    font-size: 15px; }
    .your-order-sorry .your-order__contacts p:first-of-type {
      margin-bottom: 8px; }
    .your-order-sorry .your-order__contacts p:last-of-type a {
      color: #d7444a;
      text-decoration: underline; }
      @media (min-width: 1024px) {
        .your-order-sorry .your-order__contacts p:last-of-type a:hover {
          text-decoration: none; } }

.your-order-thanks .page-title {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  text-align: center;
  padding: 10px 0;
  height: 132px;
  box-sizing: border-box; }

.your-order-thanks .page-title-inner {
  width: 100%;
  max-width: 609px;
  margin: 0 auto; }

.your-order-thanks .title {
  font-size: 35px;
  line-height: 33px; }

.your-order-thanks .your-order {
  max-width: 609px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;
  text-align: center; }
  @media (min-width: 609px) {
    .your-order-thanks .your-order {
      padding: 85px 0; } }
  @media (min-width: 1024px) {
    .your-order-thanks .your-order {
      padding: 153px 0 438px; } }
  .your-order-thanks .your-order__thanks p:first-child {
    font-size: 30px;
    line-height: 32px;
    font-weight: bold;
    margin-bottom: 14px; }
  .your-order-thanks .your-order__thanks p:last-child {
    font-size: 18px;
    line-height: 20px; }

.men-from-factory__container {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 130px;
  box-sizing: border-box; }
  @media (max-width: 1200px) {
    .men-from-factory__container {
      padding: 50px 15px 70px; } }
  @media (max-width: 900px) {
    .men-from-factory__container {
      padding: 0 15px 70px; } }

.men-from-factory__banner {
  width: 100%; }
  .men-from-factory__banner img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    font-family: 'object-fit: contain;'; }

.men-from-factory .mff-description {
  padding-bottom: 32px;
  margin-bottom: 52px;
  border-bottom: 1px solid #e6e6e6; }
  @media (max-width: 900px) {
    .men-from-factory .mff-description {
      padding-bottom: 25px;
      margin-bottom: 25px; } }
  .men-from-factory .mff-description__title {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    margin-top: 50px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description__title {
        font-size: 24px;
        line-height: 28px;
        margin-top: 30px; } }
  .men-from-factory .mff-description__subtitle {
    font-size: 22px;
    line-height: 32px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description__subtitle {
        font-size: 20px;
        line-height: 24px; } }
  .men-from-factory .mff-description ul {
    margin-top: 18px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description ul {
        margin-top: 10px; } }
    .men-from-factory .mff-description ul li {
      font-size: 15px;
      line-height: 26px;
      padding-left: 14px;
      position: relative; }
      @media (max-width: 900px) {
        .men-from-factory .mff-description ul li {
          font-size: 14px;
          line-height: 17px; } }
      .men-from-factory .mff-description ul li + li {
        margin-top: 8px; }
      .men-from-factory .mff-description ul li::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: #ce171f;
        position: absolute;
        top: 10px;
        left: 2px; }
  .men-from-factory .mff-description__row {
    display: flex;
    justify-content: space-between;
    margin-top: 27px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description__row {
        flex-direction: column; } }
    .men-from-factory .mff-description__row .mff-description__text {
      width: 45%; }
      @media (max-width: 900px) {
        .men-from-factory .mff-description__row .mff-description__text {
          width: 100%; } }
      .men-from-factory .mff-description__row .mff-description__text:last-child {
        width: 48.6%; }
        @media (max-width: 900px) {
          .men-from-factory .mff-description__row .mff-description__text:last-child {
            width: 100%;
            margin-top: 20px; } }
  .men-from-factory .mff-description__text {
    font-size: 15px;
    line-height: 26px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description__text {
        font-size: 14px;
        line-height: 17px; } }
    @media (max-width: 900px) {
      .men-from-factory .mff-description__text br {
        display: none; } }
    .men-from-factory .mff-description__text p + p {
      margin-top: 26px; }
      @media (max-width: 900px) {
        .men-from-factory .mff-description__text p + p {
          margin-top: 15px; } }
  .men-from-factory .mff-description--center {
    padding-bottom: 50px;
    margin-bottom: 52px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-description--center {
        padding-bottom: 25px;
        margin-bottom: 25px; } }
    .men-from-factory .mff-description--center .mff-description__title, .men-from-factory .mff-description--center .mff-description__text {
      text-align: center; }
    .men-from-factory .mff-description--center .mff-description__title {
      margin-bottom: 18px; }

.men-from-factory .mff-registration {
  max-width: 850px;
  width: 100%;
  margin: 0 auto; }
  .men-from-factory .mff-registration__title {
    font-size: 24px;
    line-height: 28px;
    text-align: center; }
    @media (max-width: 900px) {
      .men-from-factory .mff-registration__title {
        font-size: 22px;
        line-height: 26px; } }
  .men-from-factory .mff-registration__subtitle {
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin-top: 12px; }
  .men-from-factory .mff-registration .input-block {
    margin-top: 37px; }
    @media (max-width: 900px) {
      .men-from-factory .mff-registration .input-block {
        margin-top: 20px; } }
    .men-from-factory .mff-registration .input-block p {
      width: 100%;
      font-size: 15px;
      line-height: 18px;
      margin-bottom: 16px; }
    .men-from-factory .mff-registration .input-block--branch .radio-label {
      display: inline-flex;
      align-items: center;
      font-size: 15px;
      margin-bottom: 15px; }
      .men-from-factory .mff-registration .input-block--branch .radio-label:not(:last-child) {
        margin-right: 53px; }
        @media (max-width: 900px) {
          .men-from-factory .mff-registration .input-block--branch .radio-label:not(:last-child) {
            margin-right: 15px; } }
    .men-from-factory .mff-registration .input-block--date {
      display: flex;
      flex-wrap: wrap;
      margin: 37px -6px 0; }
      .men-from-factory .mff-registration .input-block--date p {
        margin: 0 6px 16px; }
      .men-from-factory .mff-registration .input-block--date .radio-date {
        width: 158px;
        margin: 0 6px;
        margin-bottom: 15px;
        cursor: pointer; }
        @media (max-width: 767px) {
          .men-from-factory .mff-registration .input-block--date .radio-date {
            width: calc(50% - 12px); } }
        .men-from-factory .mff-registration .input-block--date .radio-date > span {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-radius: 5px;
          padding: 7px 5px;
          border: 1px solid #d9d9d9;
          background-color: #fff;
          font-size: 14px;
          color: #999999;
          text-align: center;
          box-sizing: border-box;
          transition: border-color 0.3s ease; }
          @media (min-width: 1024px) {
            .men-from-factory .mff-registration .input-block--date .radio-date > span:hover {
              border-color: #ce181f; } }
          .men-from-factory .mff-registration .input-block--date .radio-date > span .day {
            display: block;
            font-size: 15px;
            color: #2c2c2c;
            text-align: center; }
        .men-from-factory .mff-registration .input-block--date .radio-date input {
          display: none; }
          .men-from-factory .mff-registration .input-block--date .radio-date input:checked + span {
            border-color: #ce181f;
            background-color: #ce181f;
            color: #fff; }
            .men-from-factory .mff-registration .input-block--date .radio-date input:checked + span .day {
              font-size: 15px;
              color: #fff; }
    .men-from-factory .mff-registration .input-block--time {
      margin: 23px -6px 0; }
      .men-from-factory .mff-registration .input-block--time .radio-date {
        width: calc(16.6666% - 12px); }
        @media (max-width: 900px) {
          .men-from-factory .mff-registration .input-block--time .radio-date {
            width: calc(25% - 12px); } }
        @media (max-width: 767px) {
          .men-from-factory .mff-registration .input-block--time .radio-date {
            width: calc(50% - 12px); } }
        .men-from-factory .mff-registration .input-block--time .radio-date > span {
          height: 40px;
          padding: 0 5px;
          font-size: 15px;
          color: #2c2c2c; }
          .men-from-factory .mff-registration .input-block--time .radio-date > span span {
            display: block;
            font-size: 13px; }
        .men-from-factory .mff-registration .input-block--time .radio-date--disabled > span {
          border: 1px solid #d9d9d9;
          background-color: #f2f2f2;
          color: #bfbfbf;
          cursor: default; }
          @media (min-width: 1024px) {
            .men-from-factory .mff-registration .input-block--time .radio-date--disabled > span:hover {
              border-color: #d9d9d9; } }
  .men-from-factory .mff-registration__row {
    display: flex;
    margin: 35px -10px 0; }
    @media (max-width: 900px) {
      .men-from-factory .mff-registration__row {
        flex-direction: column;
        margin: 20px 0 0; } }
    .men-from-factory .mff-registration__row .input-block {
      width: calc(33.3333% - 20px);
      margin: 0 10px 15px; }
      @media (max-width: 900px) {
        .men-from-factory .mff-registration__row .input-block {
          width: 100%;
          margin: 0 0 15px; } }
      .men-from-factory .mff-registration__row .input-block label {
        width: 100%; }
  .men-from-factory .mff-registration button[type='submit'] {
    width: 320px;
    height: 46px;
    margin: 37px auto 0; }
    @media (max-width: 900px) {
      .men-from-factory .mff-registration button[type='submit'] {
        margin: 15px auto 0; } }
    @media (max-width: 767px) {
      .men-from-factory .mff-registration button[type='submit'] {
        width: 100%; } }
  .men-from-factory .mff-registration__nav {
    border-top: 1px solid #e6e6e6;
    margin-top: 23px;
    padding-top: 34px; }
  .men-from-factory .mff-registration__nav-title {
    font-size: 24px;
    line-height: 28px;
    text-align: center; }
    @media (max-width: 900px) {
      .men-from-factory .mff-registration__nav-title {
        font-size: 22px;
        line-height: 26px; } }
  .men-from-factory .mff-registration__nav-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px; }
    @media (max-width: 767px) {
      .men-from-factory .mff-registration__nav-buttons {
        flex-direction: column; } }
    .men-from-factory .mff-registration__nav-buttons button,
    .men-from-factory .mff-registration__nav-buttons button[type="submit"] {
      max-width: 320px;
      width: 100%;
      height: 46px;
      margin: 0;
      font-size: 16px; }
      .men-from-factory .mff-registration__nav-buttons button:first-child,
      .men-from-factory .mff-registration__nav-buttons button[type="submit"]:first-child {
        margin-right: 20px; }
        @media (max-width: 767px) {
          .men-from-factory .mff-registration__nav-buttons button:first-child,
          .men-from-factory .mff-registration__nav-buttons button[type="submit"]:first-child {
            margin-right: 0;
            margin-bottom: 15px; } }

.men-from-factory__choise .oqr__title {
  font-size: 22px; }

.men-from-factory__choise .oqr__inputs {
  align-items: flex-start; }
  .men-from-factory__choise .oqr__inputs .input-block {
    margin: 0 12px; }
    @media (max-width: 900px) {
      .men-from-factory__choise .oqr__inputs .input-block {
        margin: 0; } }
    .men-from-factory__choise .oqr__inputs .input-block span {
      line-height: 20px; }

.men-from-factory__choise .oqr .oqr-block--exchange {
  padding: 28px 0 40px; }
  .men-from-factory__choise .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper {
    margin-top: 10px; }
    .men-from-factory__choise .oqr .oqr-block--exchange .oqr__inputs + .oqr__btn-wrapper .btn {
      max-width: 265px;
      font-size: 16px; }

.men-from-factory__choise .oqr .oqr-block--accordion {
  padding: 30px 0 30px; }

.men-from-factory__choise .oqr .oqr-block--payment {
  padding: 35px 0 12px; }
  .men-from-factory__choise .oqr .oqr-block--payment .oqr__method-wrap {
    margin-top: 16px; }
  .men-from-factory__choise .oqr .oqr-block--payment .oqr__finance-options {
    margin: 38px 0 26px; }

.men-from-factory__step--2 .mff-registration .input-block--branch {
  margin-top: 29px; }

.men-from-factory__step--3 .mff-registration .input-block--branch {
  margin-top: 29px; }

.service-plan-contract__container {
  padding-top: 50px;
  padding-bottom: 50px; }

.service-plan-contract__flex {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 900px) {
    .service-plan-contract__flex {
      display: block; } }
  .service-plan-contract__flex + .service-plan-contract__flex {
    margin-top: 40px; }

.service-plan-contract__left {
  width: calc(100% - 389px); }
  @media (max-width: 900px) {
    .service-plan-contract__left {
      width: 100%; } }

.service-plan-contract__right {
  width: 359px; }
  @media (max-width: 900px) {
    .service-plan-contract__right {
      width: 100%;
      margin-top: 40px; } }

.service-plan-contract__title {
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 30px; }
  @media (max-width: 767px) {
    .service-plan-contract__title {
      font-size: 25px;
      line-height: 30px; } }
  .service-plan-contract__title span {
    font-size: 15px;
    color: #9da4b0;
    margin-left: 5px;
    display: inline-block; }

.service-plan-contract__text {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px; }
  @media (max-width: 767px) {
    .service-plan-contract__text {
      font-size: 14px;
      line-height: 17px; } }
  .service-plan-contract__text strong {
    font-weight: 700; }
  .service-plan-contract__text span {
    color: #ce181f; }
  .service-plan-contract__text > p + p {
    margin-top: 23px; }
    @media (max-width: 767px) {
      .service-plan-contract__text > p + p {
        margin-top: 15px; } }

.service-plan-contract__main {
  padding-bottom: 30px;
  margin-bottom: 23px;
  border-bottom: 1px solid #e6e6e6; }

.service-plan-contract__block + .service-plan-contract__block {
  margin-top: 30px; }

.service-plan-contract__subtitle {
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 18px; }
  @media (max-width: 767px) {
    .service-plan-contract__subtitle {
      font-size: 19px;
      line-height: 25px; } }

.service-plan-contract__list-item + .service-plan-contract__list-item {
  margin-top: 18px; }

.service-plan-contract__list-title {
  font-size: 14px;
  line-height: 16px;
  color: #9da4b0; }
  @media (max-width: 767px) {
    .service-plan-contract__list-title {
      font-size: 13px;
      line-height: 15px; } }

.service-plan-contract__list-text {
  font-size: 18px;
  line-height: 28px; }
  @media (max-width: 767px) {
    .service-plan-contract__list-text {
      font-size: 15px;
      line-height: 20px; } }

.service-plan-contract table {
  border-collapse: collapse;
  width: calc(100% + 8px);
  margin-left: -8px; }
  .service-plan-contract table thead tr th {
    font-size: 14px;
    line-height: 15px;
    padding: 0 8px 8px;
    color: #9da4b0;
    font-weight: 400;
    text-align: left; }
  .service-plan-contract table tbody tr td {
    padding: 12px 8px;
    font-size: 15px;
    line-height: 16px;
    text-align: left; }
    @media (max-width: 767px) {
      .service-plan-contract table tbody tr td {
        font-size: 14px;
        line-height: 15px; } }
  .service-plan-contract table tbody tr:nth-child(odd) td {
    background-color: #f7f7f7; }
  .service-plan-contract table.service-plan-contract__price tbody tr td:last-child {
    text-align: right; }

.service-plan-contract__check {
  padding-left: 24px;
  position: relative; }
  .service-plan-contract__check::before {
    content: "";
    display: block;
    width: 14px;
    height: 10px;
    background: url("../img/checked.svg") center center no-repeat;
    background-size: contain;
    position: absolute;
    left: 0;
    top: 2px; }

.service-plan-contract__download {
  color: #124390;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 0 31px;
  background: transparent;
  border: 1px solid #124390;
  width: auto;
  max-width: 100%;
  font-size: 14px;
  margin-top: 30px; }
  .service-plan-contract__download svg {
    margin-right: 10px; }
  .service-plan-contract__download:hover {
    background: #124390;
    color: #ffffff; }

.service-plan-contract__btn {
  width: auto;
  font-size: 14px;
  padding: 0 30px;
  min-width: 150px;
  max-width: 100%; }

.service-plan-contract__code {
  display: none;
  border-top: 1px solid #e6e6e6;
  padding-top: 23px; }
  .service-plan-contract__code-text {
    font-size: 14px;
    line-height: 23px; }
    @media (max-width: 767px) {
      .service-plan-contract__code-text {
        line-height: 17px; } }
  .service-plan-contract__code-row {
    display: flex;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap; }
  .service-plan-contract__code-form {
    display: flex; }
    .service-plan-contract__code-form button {
      font-size: 14px;
      width: auto;
      margin-top: 0;
      margin-left: 10px; }
  .service-plan-contract__code-input {
    margin-top: 0; }
    .service-plan-contract__code-input input {
      margin-top: 0 !important;
      font-size: 14px; }
      .service-plan-contract__code-input input::placeholder {
        font-size: 14px; }
  .service-plan-contract__code-new {
    color: #ce171f;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    display: inline-block; }
    .service-plan-contract__code-new:hover {
      text-decoration: none; }
