.finance-calc {
	&__title {
		margin-bottom: 18px;
		font-size: 24px !important;
		line-height: 28px;
		font-weight: 400 !important;
		color: #2c2c2c;
	}

	.custom-slider {
		+ .custom-slider {
			margin-top: 30px;
		}

		.left,
		.right {
			margin-bottom: 0 !important;
		}
	}

	.personalise {
		&__left-side {
			margin-bottom: 0;
		}

		&__right-side {
			@media (max-width: 900px) {
				margin-top: 20px;
			}
		}

		&__left-side,
		&__right-side {
			max-width: 100%;
		}

		&__note {
			margin-bottom: 10px;
			font-size: 16px;
		}

		&__ext {
			margin: 20px 0;
		}
	}

	.input-block label {
		display: inline-block;
		width: 100%;
		margin-bottom: .5rem;
		cursor: pointer;
		user-select: none;
		vertical-align: top;
		font-size: 16px;

		input,
		select {
			width: 100%;
			margin-bottom: 8px;
			margin-top: 5px;
			padding: 7px 15px;
			border: 1px solid #a5a5a5;
			outline: 0;
			font-size: 16px;
			line-height: 1em;
			box-sizing: border-box;
		}

		select {
			padding: 8.5px 15px;
			resize: none;
			outline: 0;
			background: #fff url(../img/select_hov.svg) no-repeat;
			background-position: calc(100% - 15px) center;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
		}
	}

	.personal-table {
		&__table-row:nth-child(2n-1) {
			background-color: #f7f7f7;
		}
	}

	.radiogroup--info {
		display: block;

		.radiogroup {
			&__tabs-wrapper {
				display: flex;
				align-items: flex-start !important;
				border-top: none !important;
				margin-top: 20px;

				@media (min-width: 901px) {
					margin: 20px -5px 0;
				}
			}

			&__tabs {
				width: 100%;

				@media (min-width: 901px) {
					width: calc(42% - 10px);
					margin: 0 5px;
				}

				.radiogroup__radio {
					display: inline-block;
					width: 100% !important;
					position: relative;
					margin: 0 !important;

					&:not(:last-of-type) {
						margin-bottom: 14px !important;
					}

					input:checked + span {
						border-color: #ce181f;

						&::before {
							border: 5px solid #fff;
							background-color: #ce181f;
						}
					}

					span {
						display: flex;
						align-items: center;
						width: auto !important;
						font-size: 16px;
						font-weight: 400;
						line-height: 22px;
						text-transform: none;
						padding: 0 31px 0 40px;
						text-align: left;
						border: 1px solid #a7a7a7;

						@media (max-width:540px) {
							font-size: 14px;
							line-height: 18px;
						}

						&::before {
							content: '';
							display: block;
							width: 20px;
							height: 20px;
							margin-right: 12px;
							border: 1px solid #a7a7a7;
							border-radius: 50%;
							box-sizing: border-box;
							position: absolute;
							left: 11px;
							top: 50%;
							transform: translateY(-50%);
						}
					}

					.tip {
						display: inline-block;
						margin-left: 5px;
						margin-top: 5px;
						position: absolute;
						top: 0;
						right: 10px;

						@include hover {
							svg path.fill {
								fill: #a0a3a9;
							}
						}

						@media (min-width: 901px) {
							display: none;
						}

						svg {
							position: relative;
							top: 6px;
						}

						svg path.fill {
							fill: #a0a3a9;
						}
					}

					&--sm {
						width: calc(50% - 11px) !important;

						@media (min-width: 901px) {
							width: 100% !important;
							margin: 0;
							margin-bottom: 14px;
						}

						&:first-child {
							@media (max-width: 900px) {
								margin-right: 16px !important;
							}
						}
					}
				}
			}

			&__content {
				display: none;
				width: 100%;
				padding: 25px;
				background-color: #f7f7f7;
				border: 1px solid #e6e6e6;
				color: #2c2c2c;
				box-sizing: border-box;

				@media (min-width: 901px) {
					display: block;
					width: calc(58% - 10px);
					margin: 0 5px;
				}
			}

			&__content-item {
				height: 166px;
				overflow: hidden;

				@media (min-width: 901px) {
					display: none;
				}

				.mCSB_dragger_bar {
					background-color: #c4c4c4;
				}

				.mCSB_draggerRail {
					background-color: #e3e3e3;
				}

				&--active {
					display: block;
				}
			}

			&__content-title {
				margin: 7px 0;
				font-size: 18px;
				font-weight: bold;
				text-transform: uppercase;
			}

			&__content-text {
				font-size: 14px;
				line-height: 22px;

				p {
					margin-top: 17px;
				}
			}
		}
	}

	.radiogroup-wrap {
		display: none;

		@media (min-width: 901px) {
			display: block;
		}

		&--mobile {
			display: block;
			width: 100%;

			@media (min-width: 901px) {
				display: none;
			}
		}
	}

	.radiogroup-v2 {
		margin-top: 20px;
	}

	.custom-slider {
		@media screen and (max-width:900px) {
			max-width: calc(100% - 34px);
			margin: 0 auto;
		}

		.slider-val {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;

			.right input {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				width: 150px;
				height: unset;
				padding: 0;
				border: 0;
				background: none;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 18px;
				line-height: 23px;
				font-weight: bold;
				color: #ce171f;
				text-align: right;
				text-decoration: underline;
				cursor: pointer;

				&:focus {
					width: 100px;
					padding-left: 8px;
					height: 24px;
					border: 1px solid #a5a5a5;
					background-color: #ffffff;
					font-family: "Vauxhall Neue", sans-serif;
					font-size: 16px;
					line-height: 28px;
					font-weight: 400;
					color: #2c2c2c;
					text-align-last: left;
					text-decoration: none;
					cursor: text;
				}

				@media screen and (max-width:540px) {
					width: 100px;
				}
			}
		}

		.slider {
			height: 4px;
			background-color: #85aded;
			position: relative;
			border-radius: 2px;

			.ui-slider-handle {
				display: block;
				width: 30px;
				height: 30px;
				margin-left: -15px;
				border: 1px solid #124390;
				background-color: #ffffff;
				border-radius: 50%;
				position: absolute;
				top: calc(50% - 15px);
				cursor: pointer;

				&::before {
					content: "";
					width: 12px;
					height: 12px;
					background-color: #124390;
					border-radius: 50%;
					position: absolute;
					top: calc(50% - 6px);
					left: calc(50% - 6px);
				}
			}

			.ui-slider-range {
				height: 100%;
				border-radius: 2px;
				background-color: #ce171f;
			}
		}

		.slider-desc {
			display: flex;
			justify-content: space-between;
			margin-top: 17px;

			.left,
			.right {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 14px;
				line-height: 16px;
				font-weight: 400;
				color: #2c2c2c;
			}
		}
	}

	&--small {
		@media (min-width: 988px) {
			.radiogroup {
				&__tabs-wrapper {
					flex-direction: column;
				}

				&__tabs,
				&__content {
					width: 100% !important;
				}

				&__tabs {
					padding-bottom: 14px;
				}
			}

			.radiogroup-v2 {
				&__lbl {
					width: 100%;
				}
			}
		}

		.personalise {
			&__row {
				flex-direction: column-reverse;
			}

			&__left-side {
				width: 100%;
				margin-right: 0;
				margin-bottom: 20px;
			}

			&__ext {
				.row {
					flex-direction: column;
				}

				.blk {
					width: 100%;
				}
			}
		}
	}
}
