.your-order-thanks {
	.page-title {
		display: flex;
		align-items: center;
		background-color: #f6f6f6;
		text-align: center;
		padding: 10px 0;
		height: 132px;
		box-sizing: border-box;
	}

	.page-title-inner {
		width: 100%;
		max-width: 609px;
		margin: 0 auto;
	}

	.title {
		font-size: 35px;
		line-height: 33px;
	}

	.your-order {
		max-width: 609px;
		width: 100%;
		margin: 0 auto;
		padding: 40px 20px;
		box-sizing: border-box;
		text-align: center;

		@media (min-width: 609px) {
			padding: 85px 0;
		}

		@media (min-width: 1024px) {
			padding: 153px 0 438px;
		}

		&__thanks {
			p {
				&:first-child {
					font-size: 30px;
					line-height: 32px;
					font-weight: bold;
					margin-bottom: 14px;
				}

				&:last-child {
					font-size: 18px;
					line-height: 20px;
				}
			}
		}
	}
}