.online-payment {
	.page-main-title {
		margin-bottom: 20px !important;
	}

	.payment {
		max-width: 755px;
		width: 100%;
		margin: 0 auto;
		padding: 0 20px 40px;
		box-sizing: border-box;
		overflow: hidden;

		@media (min-width: 768px) {
			padding: 0 0 86px;
		}

		&__title {
			font-size: 30px;
			line-height: 33px;
			font-weight: bold;
			text-align: center;
			position: relative;

			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: -22px;
				left: 0;
				background-color: #e6e6e6;
			}
		}

		.form-block {
			margin-top: 15px;

			@media (min-width: 1024px) {
				margin-top: 43px;
			}
		}

		&__main {
			margin: 0;
			padding: 0;
		}

		&__contacts {
			font-size: 16px;
			line-height: 28px;
			position: relative;

			&::after {
				content: '';
				display: block;
				width: 100%;
				height: 1px;
				position: absolute;
				bottom: -22px;
				left: 0;
				background-color: #e6e6e6;
			}
		}

		&__collect {
			span {
				color: #ce171f;
			}
		}

		&__address {
			display: flex;
			font-size: 15px;
			line-height: 22px;
			margin: 12px 0;

			@media (max-width: 1023px) {
				flex-direction: column;
			}
		}

		&__address-text {
			display: flex;
			align-items: center;
			margin-right: 20px;

			&::before {
				content: '';
				display: block;
				width: 14px;
				height: 20px;
				background: url(../img/pin.svg) no-repeat center center;
				margin-right: 5px;
			}

			@media (max-width: 1023px) {
				margin-right: 0;
			}
		}

		&__address-phone {
			display: flex;
			align-items: center;

			&::before {
				content: '';
				display: block;
				width: 16px;
				height: 16px;
				background: url(../img/phone.svg) no-repeat center center;
				margin-right: 5px;
			}
		}

		&__mode {
			font-size: 14px;
			line-height: 26px;
			color: #666666;

			span {
				&:first-child {
					margin-right: 25px;
				}

				@media (max-width: 1023px) {
					display: block;
				}
			}
		}

		form {
			margin-top: 50px;
		}

		&__block {
			&:not(:last-of-type) {
				border-bottom: 1px solid #e6e6e6;
				padding-bottom: 20px;
				margin-bottom: 30px;
			}

			&:nth-child(2) {
				padding-bottom: 2px;
				margin-bottom: 35px;
			}
		}

		&__branch {
			margin-top: 38px;
		}

		&__block-title {
			font-size: 24px;
			text-align: center;
		}

		&__branch {
			font-size: 16px;
			text-align: center;
		}

		&__checkbox {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 22px;
			cursor: pointer;

			input {
				display: none;

				&:checked + b {
					background: #fff center/100% auto url(../img/admin_checked.png) no-repeat;
				}
			}

			b {
				display: block;
				flex-shrink: 0;
				width: 20px;
				height: 20px;
				border: 1px solid #a5a5a5;
				border-radius: 3px;
				margin-right: 10px;
				background: #fff;
				box-sizing: border-box;
			}

			span {
				a {
					color: #2c2c2c;
					text-decoration: underline;
				}
			}
		}

		.radio-label {
			margin-bottom: 20px;

			&:not(:last-child) {
				margin-right: 38px;
			}
		}

		.input-block {
			width: 100%;
			margin: 0 15px 20px;

			@media (min-width: 768px) {
				width: calc(50% - 20px);
				margin: 0 10px 20px;
			}

			input {
				font-size: 14px;

				&::placeholder {
					font-size: 14px;
				}

				&:focus::placeholder {
					opacity: 0;
				}
			}

			input[type='text'],
			select {
				margin-top: 6px;
				box-sizing: border-box;
			}

			input[type='radio'] {
				width: 18px;
				height: 18px;

				&:checked {
					background-size: 10px;
				}
			}

			&--branch {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
				margin: 26px 0 0;

				@media (min-width: 768px) {
					margin: 20px 0 0;
				}
			}

			&--lookup {
				align-self: flex-end;
			}
		}

		&__input-text {
			width: 100%;
			margin-bottom: 0 !important;
		}

		&__inputs {
			display: flex;
			flex-wrap: wrap;
			margin: 22px -15px 0;
			align-items: flex-start;

			@media (min-width: 768px) {
				margin: 16px -9px 0;
			}

			&:nth-child(2) {
				margin-top: 33px;
				margin-bottom: 18px;
			}

			&--w33 {
				.input-block {
					width: 100%;

					@media (min-width: 768px) {
						width: calc(33.3333% - 20px);
						margin: 0 10px 20px;
					}
				}
			}
		}

		&__lookup {
			width: 100px;
			margin-top: 0;
		}

		.btn-payment {
			display: block;
			width: 100%;
			height: 46px;
			margin: 39px auto 29px;
			border-radius: 0;
			font-size: 15px;
			line-height: 26px;
			font-weight: bold;
			text-transform: uppercase;
			box-sizing: border-box;
			cursor: pointer;

			@media (min-width: 768px) {
				width: 438px;
			}

			@media (max-width: 1023px) {
				width: 100%;
				font-size: 15px;
				line-height: 26px;
			}
		}
		
		&__footer {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 20px 0;
			background-color: #fff;

			img {
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
	}
}