@import "common/reset.css";
@import "common/fonts.css";
@import "libs/jquery.fancybox.min.css";
@import "libs/slick.css";
@import "libs/jquery.mCustomScrollbar.min.css";
@import "libs/nice-select.css";
@import "libs/animate.css";
@import "libs/tooltipster.bundle.css";
@import "libs/fontawesome.min.css";
@import "libs/jquery-ui.min.css";

/* common */
@import "utils/_var.scss";
@import "utils/mixin.scss";
@import "common/global.scss";
@import "common/main.scss";
@import "common/adaptive.scss";

/* partials */
@import "partials/_header.scss";
@import "partials/footer.scss";
@import "partials/modal.scss";
@import "partials/main-menu.scss";

/* components */
@import "components/button.scss";
@import "components/breadcrumbs.scss";
@import "components/top-nav.scss";
@import "components/pagination.scss";
@import "components/holyday-info.scss";
@import "components/progress-bar.scss";
@import "components/imgload.scss";
@import "components/booking-tip.scss";
@import "components/header-covid-message.scss";
@import "components/contacts-caption.scss";
@import "components/header-pdf.scss";
@import "components/toggler.scss";

/* blocks */
@import "blocks/page-main-title.scss";
@import "blocks/slide-title.scss";
@import "blocks/form-block.scss";
@import "blocks/accordion.scss";
@import "blocks/mobile-toggle.scss";
@import "blocks/sell-car-page.scss";
@import "blocks/log-page.scss";
@import "blocks/winter-service.scss";
@import "blocks/express-store.scss";
@import "blocks/used-stock.scss";
@import "blocks/used-stock-page.scss";
@import "blocks/used-stock-page-2.scss";
@import "blocks/catalog.scss";
@import "blocks/manager-appraisal-page.scss";
@import "blocks/build-your-deal.scss";
@import "blocks/news-car-page.scss";
@import "blocks/new-cars-page.scss";
@import "blocks/exclusive.scss";
@import "blocks/exclusive-2.scss";
@import "blocks/configurator.scss";
@import "blocks/corsa.scss";
@import "blocks/corsa-remake.scss";
@import "blocks/van-offer.scss";
@import "blocks/car-info.scss";
@import "blocks/finance-calc.scss";
@import "blocks/personalise.scss";
@import "blocks/personal-table.scss";
@import "blocks/calc-new.scss";
@import "blocks/part-request.scss";
@import "blocks/new-quote-request.scss";
@import "blocks/bodyshop-quote-req.scss";
@import "blocks/booking.scss";
@import "blocks/booking-1.scss";
@import "blocks/speco.scss";
@import "blocks/online-payment.scss";
@import "blocks/covid.scss";
@import "blocks/privacy-statement.scss";
@import "blocks/thanks-for-payment.scss";
@import "blocks/contact-us.scss";
@import "blocks/commercial-vehicle.scss";
@import "blocks/enquiry.scss";
@import "blocks/reservation-policy.scss";
@import "blocks/terms-conditions.scss";
@import "blocks/marketing-consent.scss";
@import "blocks/quote-request.scss";
@import "blocks/appointment.scss";
@import "blocks/refund-form.scss";
@import "blocks/ancl-page.scss";
@import "blocks/vehicle-info.scss";
@import "blocks/warranty-log.scss";
@import "blocks/about-us.scss";
@import "blocks/about-detail.scss";
@import "blocks/automotive-compliance.scss";
@import "blocks/body-shop-diary.scss";
@import "blocks/valuation-requests.scss";
@import "blocks/body-shop-calculation.scss";
@import "blocks/calculation.scss";
@import "blocks/cashflow-log.scss";
@import "blocks/check-list.scss";
@import "blocks/credit-check.scss";
@import "blocks/sales-manager.scss";
@import "blocks/deal-setup.scss";
@import "blocks/delivery-mileage.scss";
@import "blocks/drilldown-log.scss";
@import "blocks/event-offer.scss";
@import "blocks/exclusive-event.scss";
@import "blocks/export-to-feeds.scss";
@import "blocks/manager-contact.scss";
@import "blocks/invoice.scss";
@import "blocks/lockdown-event.scss";
@import "blocks/motability.scss";
@import "blocks/my-enquiries.scss";
@import "blocks/car-handover.scss";
@import "blocks/vehicle-pre-delivery-checklist.scss";
@import "blocks/online-quote-request-2.scss";
@import "blocks/page-404.scss";
@import "blocks/order-enquiry.scss";
@import "blocks/order-cart.scss";
@import "blocks/payment-request.scss";
@import "blocks/print-page.scss";
@import "blocks/quote.scss";
@import "blocks/thanks-credit.scss";
@import "blocks/users-management.scss";
@import "blocks/video-page.scss";
@import "blocks/video-showroom.scss";
@import "blocks/video-appointment.scss";
@import "blocks/video-page-editing.scss";
@import "blocks/your-order.scss";
@import "blocks/your-order-sorry.scss";
@import "blocks/your-order-thanks.scss";
@import "blocks/men-from-factory.scss";
@import "blocks/service-plan-contract.scss";