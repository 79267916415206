.new-quote-request {
	padding-bottom: 50px;

	.inner {
		max-width: 560px;
	}

	.page-main-title {
		margin-bottom: 75px;
	}

	.ah1 {
		font-size: 24px;
		line-height: 27px;
		font-weight: bold;
		margin-bottom: 25px;

		@media (max-width: 768px) {
			text-align: center;
		}
	}

	.number-line {
		font-size: 16px;
		margin-bottom: 40px;

		@media (max-width: 768px) {
			text-align: center;
		}
	}

	.input-block {
		margin-bottom: 25px;
	}

	.input-row {
		display: flex;
		justify-content: space-between;

		@media (max-width: 768px) {
			display: block;
		}

		.input-block {
			width: calc(50% - 10px);

			@media (max-width: 768px) {
				width: auto;
			}
		}

		.gb-reg-number {
			position: relative;
			background: transparent;

			input {
				padding-left: 65px;
			}
		}

		.gb-reg-number:before {
			content: '';
			display: block;
			width: 40px;
			height: 40px;
			background: url(../img/new-gb-rn.png);
			position: absolute;
			left: 0;
			top: 4px;
			z-index: 0;
			border-top-left-radius: 3px;
			border-bottom-left-radius: 3px;
		}
	}

	.images-block {
		font-size: 16px;

		@media (max-width: 768px) {
			text-align: center;
		}

		.ah2 {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 10px;

			@media (max-width: 768px) {
				text-align: center;
			}
		}

		.button--red {
			display: block;
			width: 150px;
			height: 35px;
			line-height: 35px;
			font-size: 13px;
			margin-top: 17px;

			@media (max-width: 768px) {
				margin-left: auto;
				margin-right: auto;
			}

			&:before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				background: url("../img/img.svg") no-repeat center center;
				margin-right: 9px;
				margin-top: -2px;
			}

			&.make-photo {
				display: none;
				margin-bottom: 17px;

				@media (max-width: 1000px) {
					display: block;
				}

				&::before {
					background: url("../img/make-photo.svg") no-repeat center center;
				}
			}
		}
	}

	.thumbs {
		margin: -10.5px;

		.flex-block {
			display: flex;
			flex-wrap: wrap;
			margin-top: 25px;

			@media (max-width: 380px) {
				margin: 25px 10px 0;
			}
		}

		.thumb {
			width: 172px;
			height: 131px;
			margin: 10.5px;
			border: 1px solid #e6e6e6;
			background-position: center center !important;
			background-repeat: no-repeat !important;
			background-size: 100% auto !important;
			position: relative;
			box-sizing: border-box;

			@media (max-width: 380px) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			.selection {
				width: 15px;
				height: 15px;
				border-radius: 2px;
				background: url("../img/red_x.svg") no-repeat center center;
				background-size: 10px auto;
				background-color: rgba(255, 255, 255, 0.5);
				position: absolute;
				right: 11.5px;
				top: 10.5px;
				cursor: pointer;
			}
		}

		.upload-more {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 172px;
			height: 131px;
			margin: 10.5px;
			border: 1px solid #e6e6e6;
			background-position: center center !important;
			background-repeat: no-repeat !important;
			background-size: 100% auto !important;
			position: relative;
			box-sizing: border-box;

			@media (max-width: 380px) {
				width: calc(50% - 10px);
				margin: 5px;
			}

			input {
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				opacity: 0;
				cursor: pointer;
			}

			p {
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 13px;
				line-height: 25px;
				font-weight: 400;
				color: #026fa8;
			}
		}
	}

	.btn--blue {
		display: block;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
	}

	.lookup {
		margin-bottom: 25px;

		.btn_red {
			display: inline-block;
			width: 220px;
			margin-top: 0;
			background: #ce181f;
			font-size: 16px;
			font-weight: 600;
			color: #fff;
			text-transform: uppercase;
			vertical-align: top;

			@media (max-width: 1000px) {
				display: block;
				margin-right: auto;
				margin-left: auto;
			}

			&:hover {
				background: #2c2c2c;
			}
		}

		.no_trade {
			display: inline-block;
			margin-left: 20px;
			vertical-align: top;
			font-size: 18px;
			line-height: 46px;
			color: #ce171f;
			text-decoration: underline;

			@media (max-width: 1000px) {
				display: block;
				margin-top: 0;
				margin-left: 0;
				text-align: center;
			}
		}
	}

	.car_det {
		.ah2 {
			margin-bottom: 10px;

			@media (max-width: 1000px) {
				text-align: center;
			}

			a {
				margin-left: 17px;
				font-size: 14px;
				color: #ce171f;
				text-decoration: underline;
			}
		}

		table {
			width: 100%;
			margin-bottom: 25px;
			border-bottom: 2px solid #e6e6e6;

			tr:nth-child(odd) {
				background: #f2f2f2;
			}

			td {
				width: 25%;
				padding: 13px 18px;

				.ah3 {
					margin-bottom: 5px;
					font-size: 13px;
					color: #757575;
				}
			}
		}
	}
}
