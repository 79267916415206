.booking-1 {
	.page-main-title {
		margin-bottom: 0;

		@media (min-width: 1000px) {
			margin-bottom: 43px;
		}
	}

	.inner2 {
		width: 100%;
		padding: 0 15px;
	}

	.inner2--first {
		padding: 0;

		> .ah3 {
			padding: 0 15px;
			margin: 0 auto 20px;
		}

		> .ah4 {
			font-size: 18px;
			padding: 0 15px;
			margin: 0 auto 40px;
			text-align: center;

			@media (min-width: 1000px) {
				margin: 0;
				margin-bottom: 70px;
				text-align: left;
			}
		}
	}

	.big_banner {
		height: auto;
		position: relative;
		overflow: hidden;
		padding: 0;
		margin-bottom: 40px;
	}

	.big_banner__bg {
		picture img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.big_banner__content {
		position: relative;

		@media (min-width: 1000px) {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.ah-wrap {
			@media (min-width: 1000px) {
				padding: 0 0 30px 65px;
			}
		}

		.ah2,
		.ah3,
		.ah4 {
			text-align: center;

			@media (min-width: 1000px) {
				text-align: left;
			}
		}

		.ah2-outer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			transform-origin: top right;
			background-color: #333333;

			@media (min-width: 1000px) {
				display: none;
			}
		}

		.ah2 {
			font-size: 25px;
			line-height: 28px;
			position: relative;
			padding: 30px 30px 50px 30px;
			margin-bottom: 0;
			max-width: 100%;

			@media (min-width: 1000px) {
				padding: 30px 0 0 65px;
				max-width: 400px;
			}
		}

		.ah2 span {
			position: relative;
			z-index: 1;
		}

		.ah2-outer {
			transform: skew(0, 3deg);
			border-top: 12px solid #ce171f;
		}

		.ah3 {
			margin-top: 10px;

			@media (min-width: 1000px) {
				margin-top: 70px;
			}
		}

		.button--red {
			margin: 15px auto 30px;

			@media (min-width: 1000px) {
				margin: 0;
				margin-top: 15px;
			}
		}
	}

	.second_block {
		margin-bottom: 40px;

		&::before {
			display: none;

			@media (min-width: 1000px) {
				display: block;
			}
		}

		.row {
			margin-right: 0;
			margin-left: 0;
		}

		.block {
			align-items: flex-start !important;
			border-top: 1px solid #d9d9d9 !important;
			border-left: 6px solid #ce171f !important;
			padding: 15px 25px 25px 25px !important;
			margin-bottom: 15px !important;

			@media (min-width: 1000px) {
				margin-bottom: 30px;
			}
		}
	}

	.block {
		flex-direction: column;
	}

	.block__top {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.image_block {
		width: auto !important;
	}

	.block__bottom {
		width: 100%;
		border-top: none !important;
		margin: 0 !important;
		padding: 0 !important;
		text-align: left;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: #e6e6e6;
			margin: 15px 0;
		}

		li {
			padding-left: 17px;
			position: relative;

			&::before {
				content: '';
				display: block;
				width: 7px;
				height: 7px;
				position: absolute;
				top: 7px;
				left: 0;
				background-color: #ce171f;
			}

			span {
				color: #ce171f;
			}
		}
	}

	.ah3--middle {
		margin: 30px 0 0 0 !important;
		padding: 0 !important;
		text-align: center !important;
	}

	.tel-block {
		margin-bottom: 10px !important;
	}

	.title {
		font-size: 24px;
		line-height: 33px;

		@media (min-width: 1000px) {
			font-size: 32px;
			line-height: 35px;
		}
	}

	.subtitle {
		font-size: 16px;
		line-height: 26px;
		text-align: center;
	}

	.reg-and-mile {
		text-align: center;
	}

	.btn--lookup {
		margin: 20px auto !important;
	}

	.booking-form {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		margin-top: 30px;

		@media (min-width: 1000px) {
			margin: 0 -20px;
		}

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: #e6e6e6;
			position: absolute;
			top: 0;
			left: 0;
		}

		.radio-label {
			display: flex;

			input[type='radio'] {
				margin: 0;
				width: 20px;
				height: 20px;
				border: 1px solid #a5a5a5;
				border-radius: 25px;
				cursor: pointer;
				margin-top: 3px;
				margin-right: 10px;

				&:checked {
					background: #fff url(../img/radio.svg) no-repeat center center;
					background-size: 10px;
				}
			}

			span span {
				font-size: 13px;
				line-height: 16px;
				color: #757575;
			}
		}

		.checkbox-label {
			display: flex;
			align-items: center;

			img {
				width: 18px;
				height: 18px;
				cursor: pointer;
			}
		}

		label {
			display: block;
			margin-bottom: 0;
			font-size: 16px;
			line-height: 28px;
		}

		input[type='text'],
		textarea {
			outline: 0;
			border: 1px solid #a5a5a5;
			font-size: 16px;
			padding: 12px 15px;
			line-height: 1em;
			width: 100%;
			height: auto;
			box-sizing: border-box;
			cursor: auto;
			margin: 0;
			margin-top: 1rem;
		}

		textarea {
			height: 120px;
			resize: none;
		}

		.input-date {
			background: #fff url(../img/datepicker.png) no-repeat;
			background-position: calc(100% - 9px) center;
		}

		select {
			box-sizing: border-box;
			width: 100%;
			padding: 9.5px 15px;
			resize: none;
			appearance: none;
			border: 1px solid #a5a5a5;
			border-radius: 0;
			outline: 0;
			background-position: calc(100% - 15px) center;
			font-size: 16px;
			margin-top: 1rem;
		}

		.tooltip {
			&-icon {
				margin-left: 10px;

				&:hover {
					.tooltip-window {
						display: block;
					}
				}
			}

			&-window {
				display: none;
				width: 85%;
				position: absolute;
				bottom: 90%;
				left: 0;
				background-color: #fff;
				border: 1px solid #d9d9d9;
				box-shadow: 0 2px 10px rgba(29, 30, 34, 0.18);
				z-index: 5;
				padding: 30px;
			}

			&__title {
				font-size: 20px;
				line-height: 33px;
				font-weight: bold;
			}

			&__text {
				font-size: 14px;
				line-height: 22px;
			}
		}

		&__table-row {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;
			padding: 11px 10px;
			position: relative;

			&:nth-child(even) {
				background-color: #f7f7f7;
			}
		}

		&__price {
			font-size: 16px;
			line-height: 28px;
		}

		&__row-input {
			display: flex;
			flex-wrap: wrap;

			label,
			.select-label {
				width: 100%;
			}

			.select-label {
				margin-bottom: 0;
			}

			&--narrow {
				@media (min-width: 1000px) {
					margin: 0 -10px;
				}

				label,
				.input-select {
					width: 100%;
					margin-bottom: 25px;

					@media (min-width: 1000px) {
						width: calc(50% - 20px);
						margin: 0 10px 25px;
					}
				}
			}
		}

		&__reg-number {
			position: relative;

			&::before {
				content: "";
				display: block;
				background: url(../img/new-gb-rn.png);
				width: 43px;
				height: 43px;
				min-width: 42px;
				min-height: 42px;
				position: absolute;
				left: 1px;
				bottom: 1px;
			}

			input[type='text'] {
				padding-left: 57px;
			}
		}

		&__table-title {
			font-size: 24px;
			line-height: 28px;
			margin-bottom: 20px;
		}

		&__left {
			width: 100%;

			@media (min-width: 1000px) {
				width: calc(50% - 40px);
				margin: 0 20px;
			}

			.booking-form {
				&__table-item {
					&::after {
						content: '';
						display: block;
						width: 100%;
						height: 1px;
						background-color: #d7d7d7;
					}
				}

				&__table-title {
					margin-top: 40px;

					&:first-child {
						margin-top: 30px;
					}
				}
			}
		}

		&__right {
			width: 100%;
			text-align: center;

			@media (min-width: 1000px) {
				width: calc(50% - 40px);
				margin: 0 20px;
				text-align: left;
			}

			.booking-form {
				&__table-title {
					margin-top: 30px;
				}

				&__row-input {
					margin-bottom: 25px;

					&--narrow {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}