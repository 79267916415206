.bqr-iwt-item__pic--parts-request {
	 img {
		 display: block;
		 height: 24px;
		 width: 28px;
		 object-fit: contain;
		 object-position: center center;
	 }
 }

.bqr-main-flex-right-desc--gray {
	font-family: "Myriad Pro", sans-serif;
	color: #4d4d4d;
}

.form-block button.bqr-form-btn-look-up--pr, .btn.btn-submit-bqr--pr {
	font-weight: 400;
}

.parts-req-upload-photos {
	display: block;
	width: 150px;
	height: 35px;
	line-height: 35px;
	font-size: 13px;
	margin-top: 17px;

	@media (max-width: 900px) {
		margin-left: auto;
		margin-right: auto;
	}

	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 18px;
		height: 18px;
		background: url(../img/img.svg) no-repeat center center;
		margin-right: 9px;
		margin-top: -2px;
	}
	cursor: pointer;
}

.bqr-page {
	.inner2 {
		@media (max-width: 900px) {
			width: 90%;
		}
	}

	.full-width-banner {
		margin-top: 15px;
		margin-bottom: 45px;

		@media (max-width: 900px) {
			margin-top: 5px;
			margin-bottom: 12px;
			min-height: 162px;
		}

		img {
			min-height: 162px;
			object-fit: cover;
			object-position: right;
		}
	}

	.bqr-main-flex {
		&__mobile {
			display: none;

			@media (max-width: 900px) {
				display: block;
				margin-top: 55px;

				.bqr-main-flex__h2 {
					font-size: 20px;
				}

				.call-us-on-btn {
					margin-top: 0;
				}
			}
		}

		&__desk {
			margin-top: 28px;

			@media (max-width: 900px) {
				display: none;
			}
		}

		&__h2 {
			line-height: 30px;

			@media (max-width: 900px) {
				font-size: 18px;
				line-height: 24px;
				text-align: center;
			}

			+ * {
				margin-top: 10px;

				@media (max-width: 900px) {
					text-align: center;
				}
			}

			&--list {
				@media (max-width: 900px) {
					display: block !important;
				}
			}

			&--top {
				span {
					display: none;

					@media (max-width: 900px) {
						display: inline;
					}
				}

				br {
					display: none;

					@media (max-width: 900px) {
						display: block;
					}
				}
			}

			&--red {
				text-transform: uppercase;

				@media (max-width: 900px) {
					font-size: 13px;
					text-transform: none;
					color: #ce1820;

					+ p {
						display: none;
					}
				}

				span {
					display: none;

					@media (max-width: 900px) {
						display: inline;
					}
				}
			}
		}
	}

	.bqr-main-flex-left p + .bqr-main-flex__h2 {
		margin-top: 40px;

		@media (max-width: 900px) {
			margin-top: 9px;
		}
	}

	.bqr-main-flex-right {
		@media (max-width: 900px) {
			margin-top: 19px;
		}

		.bqr-main-flex__h2,
		.bqr-main-flex-right-desc {
			@media (max-width: 900px) {
				display: none;
			}
		}
	}

	.bqr-icons-with-text {
		margin-top: 26px;

		@media (max-width: 900px) {
			text-align: left;
			margin-bottom: 35px;
		}
	}

	.bqr-iwt-item__text {
		font-size: 15px;
		line-height: 20px;
	}

	.call-us-on-btn {
		padding: 14px 10px;
		color: #2c2c2c;

		&__number {
			@media (max-width: 500px) {
				font-size: 20px;
			}
		}
	}

	.new-quote-request {
		&.bqr-wrap {
			margin-top: 21px;
		}

		.input-block {
			margin-bottom: 18px;

			input,
			select,
			textarea {
				border-radius: 3px;
			}

			textarea {
				height: 130px;

				@media (max-width: 900px) {
					height: 150px;
				}
			}

			label {
				width: 100%;
			}
		}

		.input-row--lookup {
			align-items: flex-end;

			@media (max-width: 900px) {
				display: flex !important;
			}
		}

		.bqr-form-btn-look-up {
			height: 40px;
			font-size: 15px;
			line-height: 40px;
			border-radius: 5px;
			margin-bottom: 0;
			margin-left: 0;
			max-width: 192px;

			@media (max-width: 900px) {
				max-width: 151px;
			}
		}

		.bqr-images-block {
			margin-top: 24px;

			@media (max-width: 900px) {
				text-align: center;
				margin-top: 18px;
			}

			p {
				font-size: 16px;
				line-height: 24px;
			}
		}

		.parts-req-upload-photos {
			width: 180px;
			height: 40px;
			border-radius: 5px;
			font-size: 15px;
			line-height: 40px;
			margin-top: 9px;
		}

		.bqr-form-bottom {
			display: flex;
			align-items: center;
			margin-top: 30px;

			@media (max-width: 900px) {
				flex-direction: column;
				margin-top: 21px;
			}
		}

		.btn-submit-bqr {
			width: 266px;
			height: 40px;
			margin-top: 0;
			margin-right: 20px;
			border-radius: 5px;
			font-size: 16px;
			line-height: 40px;
			font-weight: bold;
			box-sizing: border-box;

			@media (max-width: 900px) {
				max-width: 342px;
				width: 100%;
				order: 1;
				margin-top: 10px;
				margin-right: 0;
			}
		}

		.bqr-form-privacy-policy {
			margin: 0;
		}
	}

	.up-file {
		@media (max-width: 900px) {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		input {
			display: none;
		}

		&__list {
			margin-top: 10px;
		}

		&__list-item {
			display: none;
			align-items: center;

			+ .up-file__list-item {
				margin-top: 8px;
			}
		}

		&__name {
			font-size: 14px;
			text-decoration: underline;
			color: #CE171F;
		}

		.btn-remove {
			width: 14px;
			height: 14px;
			background: url(../img/close-btn-2.png) no-repeat center center;
			background-size: contain;
			margin-left: 10px;
			cursor: pointer;

			&::before {
				display: none;
			}
		}
	}
}