.page-main-title {
	width: 100%;
	height: 100px;
	text-align: center;
	background-color: #f6f6f6;

	@media (max-width: 900px) {
		height: 80px;
	}

	.inner {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		position: relative;
	}

	.title {
		font-size: 34px;
		line-height: 33px;
		font-weight: 400;
		color: #2c2c2c;

		@media (max-width: 768px) {
			font-size: 30px;
		}

		@media (max-width: 600px) {
			font-size: 24px;
			line-height: 29px;
		}
	}
}