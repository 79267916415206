.exclusive-event {
	.inner--main {
		max-width: 1140px;
		margin: 20px auto 45px;

		@media (min-width: 1024px) {
			flex-direction: row;
			margin: 39px auto 118px;
		}

		.title {
			text-transform: uppercase;
		}
	}

	&__banner {
		img {
			width: 100%;
			height: 100%;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		margin-top: 18px;

		@media (min-width: 1024px) {
			flex-direction: row;
			margin: 18px -20px 0;
		}
	}

	&__video {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.4%;

		@media (min-width: 1024px) {
			width: calc(68% - 40px);
			margin: 0 20px;
			padding-bottom: 36.4%;
		}

		iframe {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__video-preview {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		cursor: pointer;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__content {
		width: 100%;
		margin-top: 35px;

		@media (min-width: 1024px) {
			width: calc(32% - 40px);
			margin: 0 20px;
		}
	}

	&__content-title {
		font-size: 24px;
		font-weight: bold;
		line-height: 32px;
		margin-top: -7px;
		margin-bottom: 15px;
	}

	&__content-text {
		font-size: 16px;
		line-height: 24px;

		p + p {
			padding-top: 23px;
		}
	}

	&__links {
		display: flex;
		justify-content: center;
		flex-direction: column;
		margin-top: 53px;

		@media (min-width: 1024px) {
			flex-direction: row;
		}

		a {
			font-size: 18px;
			color: #ce171f;
			text-decoration: underline;
			text-align: center;
			margin-bottom: 30px;

			@media (min-width: 1024px) {
				margin-bottom: 0;
			}

			&:hover {
				text-decoration: none;
			}

			+ a {
				@media (min-width: 1024px) {
					margin-left: 95px;
				}
			}
		}
	}
}