.part-order-cart {
	.content {
		.flex-table {
			margin-top: 15px;
		}
	}

	.content-bg {
		background-color: #f7f7f7;
		padding-bottom: 45px;

		.table-bottom {
			margin-bottom: 0;
		}
	}

	.subtitle-select {
		margin-top: 26px;
	}

	.row {
		.field.description{
			width: 515px;
		}

		.field.vat {
			width: 78px;
		}

		.field.stock {
			width: 88px;
		}

		.field.button {
			width: 90px;
		}

		.btn-remove,
		.btn-add {
			color: #b1b1b1;
			display: flex;
			align-items: center;
			text-decoration: underline;
			cursor: pointer;

			&::before {
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				background: url(../img/close.svg) no-repeat center center;
				background-size: contain;
				margin-right: 5px;
			}
		}

		.btn-add {
			color: #ce181f;
			display: none;
			width: auto;
			padding: 0;

			&::before {
				width: 10px;
				height: 10px;
				background: url(../img/plus-red.svg) no-repeat center center;
			}
		}
	}

	.table-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		margin-bottom: 45px;

		@media (max-width: 650px) {
			flex-direction: column;
		}
	}

	.total {
		margin-top: 0;
	}

	.order-nav {
		display: flex;
	}

	.order-nav-btn {
		height: 35px;
		width: 174px;
		line-height: 32px;

		&--check {
			display: none;
			align-items: center;
			justify-content: center;

			&:hover {
				background-color: #124390 !important;
				color: #fff !important;
				cursor: auto;
			}

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 20px;
				background: url(../img/checked-white.svg) no-repeat center center;
				background-size: contain;
				margin-right: 5px;
			}
		}
	}

	.btn-cancel {
		width: 85px;
		margin-left: 10px;
		border: 1px solid #514c4c;
		background-color: #fff;

		&:hover {
			background-color: #514c4c;
			color: #fff;
		}
	}

	.confirm-wrapper {
		margin: 34px 0 110px;
		text-align: center;

		@media (max-width: 650px) {
			margin: 30px 0 40px;
		}
	}

	.confirm-text {
		font-size: 16px;
		line-height: 42px;

		span {
			font-size: 20px;
		}
	}

	.confirm-btn {
		width: 350px;
		margin: 6px auto 0;
		background-color: #124390;
		font-size: 18px;
		font-weight: bold;
		line-height: 28px;
		color: #fff;

		@media (max-width: 650px) {
			width: 250px;
		}

		&--disabled {
			background-color: #cdcdcd;
			color: #a6a6a6;
		}

		&--icon {
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: '';
				display: block;
				width: 25px;
				height: 25px;
				background: url(../img/arrow-triangle.svg) no-repeat center center;
				background-size: contain;
				margin-right: 8px;
			}
		}
	}

	.btn-back {
		font-size: 15px;
		line-height: 42px;
		text-decoration: underline;
		cursor: pointer;
	}
}
