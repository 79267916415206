.button {
	display: block;
	width: 249px;
	height: 40px;
	line-height: 40px;
	color: #fff;
	text-transform: uppercase;
	text-align: center;
	box-sizing: border-box;
	transition: 0.3s;

	&--red {
		background-color: #ce181f;
		color: #fff;

		@include hover {
			background-color: #841215;
			text-decoration: none;
			color: #fff;
		}
	}

	&--light-red {
		background-color: #b72126;

		@include hover {
			background-color: #841215;
		}
	}

	&--grey {
		background-color: #58585a;
		border: 1px solid #58585a;

		@include hover {
			background-color: #ffffff;
			color: #58585a;
		}
	}

	&--white {
		color: #2c2c2c;
		background-color: #fff;

		@include hover {
			background-color: #d7d7d7;
		}
	}

	&--bottom {
		margin-top: 52px;
	}
}

.btn {
	height: 40px;
	line-height: 40px;
	width: 220px;
	text-align: center;
	border-radius: 5px;
	transition: 0.3s;

	&--blue {
		background-color: #124390 !important;
		color: #fff !important;

		@include hover {
			background-color: #58585a !important;
		}

		&:disabled {
			background-color: #cccccc;
		}
	}

	&--gray {
		background-color: #4d4d4d;
		color: #fff;

		@include hover {
			background-color: #58585a;
		}

		&:disabled {
			background-color: #cccccc;
		}
	}

	&--gray-invert {
		color: #4d4d4d;
		background-color: #fff;
		border: 1px #4d4d4d solid;

		@include hover {
			background-color: #e0e0e0;
		}

		&:disabled {
			background-color: #cccccc;
		}
	}

	&--red {
		background-color: #ce181f;
		color: #fff;

		@include hover {
			background-color: #981316;
		}

		&:disabled {
			background-color: #cccccc;
		}
	}

	&--mb {
		margin-bottom: 10px;
	}
}
