.form-block {
	.input-block {
		label:not(.checkbox-container) {
			display: inline-block;
			user-select: none;
			vertical-align: top;
			font-size: 14px;
			color: #2c2c2c;

			.outer {
				white-space: nowrap;

				@media (max-width: 600px) {
					white-space: initial;
				}
			}
		}

		input[type=number],
		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=password],
		textarea {
			width: 100%;
			height: 40px;
			margin-top: 4px;
			padding: 8px 15px;
			border: 1px solid #a5a5a5;
			border-radius: 3px;
			outline: none;
			font-size: 14px;
			line-height: 1em;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-sizing: border-box;

			&.gb-bg {
				padding-left: 57px;
				background: #FFF url(../img/gb.png) no-repeat top left;
				background-size: auto 100%;
			}

			&::placeholder {
				opacity: 0.5;
				font-size: 15px;
			}

			&:focus::placeholder {
				opacity: 0;
			}
		}

		textarea {
			height: 120px;
		}

		select {
			width: 100%;
			height: 40px;
			margin-top: 4px;
			padding: 8px 25px 8px 6px;
			resize: none;
			border: 1px solid #a5a5a5;
			border-radius: 3px;
			outline: none;
			background: #FFF url(../img/select_hov.svg) no-repeat;
			background-position: calc(100% - 10px) center;
			font-size: 14px;
			line-height: 1em;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-sizing: border-box;

			&::-ms-expand {
				display: none;
			}
		}

		.datepicker-input {
			width: 100%;
			height: 40px;
			padding: 8px 15px;
			resize: none;
			border: 1px solid #a5a5a5;
			border-radius: 3px;
			outline: none;
			background: #FFF url(../img/datepicker.png) no-repeat;
			background-position: calc(100% - 9px) center;
			font-size: 14px;
			line-height: 1em;
			-webkit-appearance: none;
			-moz-appearance: none;
			box-sizing: border-box;
		}

		input[type=number],
		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=password],
		select,
		textarea {
			width: 100%;

			&.err {
				transition: .2s;
				border: none;
				box-shadow: 0 0 1px 1px #d01c15;
			}
		}

		.radio-btn {
			+.radio-btn {
				margin-top: 15px;
			}
		}

		.radio-label {
			margin-bottom: 0;
			cursor: pointer;
			vertical-align: middle;
		}

		input[type=radio] {
			max-width: 18px;
			min-width: 18px;
			width: 100%;
			height: 18px;
			margin: 0;
			margin-right: 8px;
			cursor: pointer;
			vertical-align: middle;
			border: 1px solid #a5a5a5;
			border-radius: 25px;
			outline: none !important;
			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				background: #FFF url(../img/radio.svg) no-repeat center center;
				background-size: 10px;
			}
		}

		&.err-required::after {
			position: relative;
			top: 6px;
			display: block;
			width: 100%;
			content: "This field is required";
			color: #d01c15;
		}

		&.err-format::after {
			display: block;
			width: 100%;
			position: relative;
			top: 6px;
			content: "Invalid format";
			color: #d01c15;
		}
	}

	button {
		width: 100%;
		max-width: 455px;
		margin-top: 40px;
		font-weight: 400;
		font-size: 15px;
	}

	.row {
		margin-bottom: 20px;

		.row-title {
			display: inline-block;
			margin-top: 16px;
			margin-bottom: 1rem;
			user-select: none;
			vertical-align: top;
			color: #2c2c2c;
			font-size: 16px;
		}
	}

	.agree-row {
		display: flex;
		align-items: center;
		margin-top: 18px;

		.checkbox-container {
			margin: 0;
		}

		.agree-text {
			margin-left: 10px;
			color: #2c2c2c;
			font-size: 16px;

			a {
				text-decoration: underline;
				color: #841215;

				@include hover {
					text-decoration: none;
				}
			}
		}
	}

	.speco-map-row {
		.input-block {
			.radio-btn + .radio-btn {
				margin-top: 0;
			}

			input[type=radio] {
				margin-top: 5px;
			}
		}
	}
}