.export-to-feeds {
	.form-block .input-block {
		.radio-btn+.radio-btn {
			margin-top: 0;
		}

		select {
			max-width: 100%;
			width: 300px;
			margin-bottom: 15px;

			@media (max-width: 500px) {
				width: 100%;
			}
		}
	}

	.form-title {
		font-size: 24px;
		line-height: 1.3;

		@media (max-width: 500px) {
			font-size: 20px;
			margin-bottom: 10px;
		}
	}

	.radio-btn {
		display: flex;
	}

	.extf-btn-row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;

		.btn {
			width: calc(50% - 15px);
			max-width: 100% !important;

			@media (max-width: 500px) {
				width: 100%;
				margin-top: 10px;
			}
		}
	}

	.extf-gforce {
		margin-top: 50px;

		@media (max-width: 500px) {
			margin-top: 30px;
		}
	}
}
