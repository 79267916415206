.video-page {
	margin-bottom: 38px;
	padding-bottom: 0;

	.page-main-title {
		.title {
			@media (max-width: 660px) {
				font-size: 24px;
			}
		}
	}

	.inner2 {
		max-width: 1162px;

		@media (max-width: 660px) {
			width: 100%;
		}
	}

	.relative_block {
		position: relative;
		display: flex;
		margin-top: 60px;

		@media (max-width: 1100px) {
			flex-direction: column;

			.left {
				width: 100%;
				margin: 0;
			}
		}

		@media (max-width: 660px) {
			margin-top: 37px;
		}

		> .left {
			flex-shrink: 0;
			width: 66%;
			margin-right: 3%;

			@media (max-width: 1100px) {
				width: 100%;
				margin: 0;
			}
		}

		.personal-table__title {
			margin-bottom: 7px;
			border-bottom: 0;
			font-size: 21px;
		}

		.personal-table__table-row {

			&:nth-child(2n+1) {
				background-color: #F2F2F2;
			}

			.personal-table__table-cell {
				height: 41px;

				&--w30 {
					padding-right: 21px;
				}
			}
		}

		.personalise__left-side {
			width: 99%;
		}

		.Choose-colors {
			margin-top: 10px;
			letter-spacing: 0.7px;
			font-size: 18px;
			line-height: 28px;
		}
	}

	.text_block {
		display: flex;
		flex-direction: column;
		max-width: 435px;
		width: 34%;
		color: #2c2c2c;

		@media (max-width: 1100px) {
			width: 100%;
		}

		@media (max-width: 660px) {
			padding: 0 20px;
		}

		.ah2 {
			font-size: 23px;
			line-height: 26px;
			margin-top: 16px;

			@media (max-width: 660px) {
				margin-top: 60px;
			}
		}

		.ah3 {
			font-size: 15px;
			line-height: 23px;
			margin-top: 22px;
		}

		.description {
			margin-top: 20px;

			li {
				display: flex;
				margin-bottom: 12px;
				font-size: 17px;
				line-height: 22px;

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					flex-shrink: 0;
					margin-right: 11px;
					margin-top: 10px;
					background-color: #ce171f;
				}
			}
		}
	}

	.video_block {
		position: relative;
		width: 100%;
		height: 429px;

		@media (max-width: 660px) {
			height: 217px;
		}

		iframe {
			width: 100%;
			height: 100%;
			z-index: 1;
		}

		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: -1;
		}
	}

	.video_like-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		margin-top: 15px;

		@media (max-width: 1180px) {
			justify-content: center;
		}

		@media (max-width: 660px) {
			padding: 0 20px;
		}

		p {
			font-size: 19px;
			color: #2c2c2c;

			@media (max-width: 1180px) {
				font-size: 18px;
				line-height: 24px;
				text-align: center;
				width: 100%;
				margin-bottom: 18px;
			}

			@media (max-width: 660px) {
				padding: 0 20px;
			}

			@media (max-width: 330px) {
				padding: 0;
			}
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 141px;
			height: 46px;
			font-size: 18px;
			line-height: 46px;

			@media (max-width: 660px) {
				width: calc(50% - 5px);
			}

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 18px;
			}

			&.like_button {
				background-color: #124390;
				transition: 0.3s;

				@media (max-width: 1180px) {
					margin-right: 10px;
				}

				@media (max-width: 660px) {
					margin-right: 5px;
				}

				@include hover {
					background-color: #58585a;
				}

				&::before {
					margin-right: 6px;
					margin-top: -5px;
					background: center / cover url(./../img/like-punch.svg) no-repeat;
				}
			}

			&.dislike_button {
				background-color: #666666;

				@media (max-width: 660px) {
					margin-left: 5px;
				}

				&::before {
					margin-right: 10px;
					margin-top: 5px;
					background: center / cover url(./../img/dislike-punch.svg) no-repeat;
				}
			}
		}
	}

	.bottom_block {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 14px 0;
		border-top: 1px solid #e6e6e6;
		border-bottom: 1px solid #e6e6e6;
		margin-top: 60px;

		@media (max-width: 660px) {
			flex-direction: column;
			margin-top: 20px;
			padding: 20px;
		}

		.call_block {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #2c2c2c;
			margin-right: 51px;

			span {
				font-size: 16px;
			}

			a {
				font-size: 22px;
				color: #2c2c2c;
				display: flex;
				justify-content: center;
				align-items: center;

				&::before {
					content: '';
					flex-shrink: 0;
					display: block;
					width: 18px;
					height: 18px;
					margin-left: 11px;
					margin-right: 12px;
					background: center / cover url(./../img/call-red.svg) no-repeat;
				}
			}

			@media (max-width: 660px) {
				display: none;
			}
		}

		.mail_button {
			width: 265px;
			height: 40px;
			font-size: 18px;
			color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: #ce181f;
			margin-right: 10px;
			transition: 0.3s;

			&::before {
				content: '';
				display: block;
				width: 20px;
				height: 16px;
				margin-right: 11px;
				margin-left: -20px;
				background: center / cover url(./../img/mail.svg) no-repeat;
			}

			@media (max-width: 660px) {
				margin-right: 0;
				margin-top: 10px;
				width: 100%;
				height: 46px;

				&::before {
					margin-left: 0;
				}
			}

			@include hover {
				background-color: #841215;
			}
		}

		.mobile_tel-button {
			display: none;
			transition: 0.3s;

			@media (max-width: 660px) {
				width: 100%;
				height: 46px;
				font-size: 18px;
				color: #fff;
				display: flex;
				align-items: center;
				justify-content: center;
				background-color: #ce181f;
				margin-right: 0;

				&::before {
					content: '';
					display: block;
					width: 18px;
					height: 18px;
					margin-right: 11px;
					margin-left: 0;
					background: center / cover url(./../img/call-w.svg) no-repeat;
				}
			}

			@include hover {
				background-color: #841215;
			}
		}
	}
}
