.speco {
	&-nfpr {
		&-content {
			width: 243px;
			max-width: 100%;
			padding-bottom: 20px;
		}

		&-pic {
			display: flex;
			justify-content: center;
			padding: 25px 0 25px;
		}

		&-title {
			text-align: center !important;
		}

		&-ul {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		&-block {
			display: flex;
			justify-content: center;
			margin-top: 0;
			padding: 0 20px;
			box-sizing: border-box;

			@media (min-width: 1000px) {
				margin-top: 0;
			}
		}
	}

	&-sidebar-info {
		margin-bottom: 20px;
	}

	&-ah1 {
		font-weight: 400 !important;
	}

	&-map-red {
		font-size: 16px !important;
		line-height: 28px !important;
		font-weight: 400 !important;
		color: #ce171f !important;
	}

	&-accordeon {
		margin-bottom: 39px;

		&-heading {
			display: flex;
			align-items: center;
			border: 1px solid #d7d7d7;
			background-color: #f7f7f7;
			padding: 17px 10px 15px;
			cursor: pointer;

			&__name {
				display: block;
				font-size: 20px;
				font-weight: 400;
				line-height: 28px;
				color: #2c2c2c;
				text-decoration: underline;
			}

			&__show-btn {
				margin-left: 9px;
				display: flex;
				align-items: center;

				&::before {
					content: 'Show';
					display: block;
					min-width: 42px;
					margin-right: 5px;
					font-size: 15px;
					font-weight: 400;
					color: #ce171f;
					text-decoration: underline;
					transition-duration: .2s;
				}

				&::after {
					content: '';
					display: block;
					width: 12px;
					height: 8px;
					background: url(../img/arrow-top-red.png) no-repeat center center;
					transition-duration: .2s;
					transform: rotate(180deg);
				}
			}
		}

		&-body {
			display: none;
			border-bottom: 1px solid #d7d7d7;
		}

		&-item--chb {
			padding: 9px 10px;

			span {
				font-size: 16px !important;
				line-height: 28px !important;
				font-weight: 400;
				color: #2c2c2c;
			}

			&:nth-child(2n) {
				background-color: #f7f7f7;
			}
		}

		&-chb {
			display: flex;
			align-items: center;
			margin-bottom: 0 !important;
		}

		&-chb-name {
			flex-grow: 1;
			margin-right: 10px;
		}

		&--open {
			.speco-accordeon-heading__show-btn {
				&::before {
					content: 'Hide';
				}

				&::after {
					transform: rotate(0);
				}
			}
		}
	}

	&-calendar {
		display: flex;
		width: 100%;
		max-width: 100%;

		&-wrap {
			margin-top: 30px;
		}

		&-title {
			margin-bottom: 5px;
			color: #2c2c2c;
			font-size: 16px;
			font-weight: 400;
			line-height: 28px;

			.red {
				font-weight: bold;
			}
		}

		&-left,
		&-right {
			width: 44px;
			padding-top: 56px;

			@media (max-width: 550px) {
				width: 28px;
			}
		}

		&-left {
			margin-right: 10px;

			@media (max-width: 550px) {
				margin-right: 5px;
			}
		}

		&-right {
			margin-left: 10px;

			@media (max-width: 550px) {
				margin-left: 5px;
			}
		}

		&-main {
			display: block;
			width: calc(100% - 54px * 2);
			position: relative;

			@media (max-width: 550px) {
				width: calc(100% - 33px * 2);
			}
		}

		&-nav-container {
			width: 100%;
			height: 1px;
			position: relative;

			.owl-prev,
			.owl-next {
				width: 44px;
				height: 46px;
				border: 1px solid #ce181f;
				background-position: center center;
				background-repeat: no-repeat;
				background-color: #ffffff;
				background-size: 10px auto;
				position: absolute;
				cursor: pointer;
				box-sizing: border-box;
				transition-duration: .2s;

				@media (max-width: 550px) {
					width: 28px;
				}

				span {
					display: none;
				}

				&.disabled {
					opacity: 0.5;
					pointer-events: none;
				}

				@include hover {
					opacity: .8;
				}
			}

			.owl-prev {
				background-image: url("../../img/calendar-arrow.svg");
				margin: 0;
				top: 0;
				left: 0;
			}

			.owl-next {
				margin: 0;
				top: 0;
				right: 0;
				background-image: url("../../img/calendar-arrow.svg");
				transform: rotate(180deg);
			}
		}
	}

	&-cal {
		&-side-block {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 44px;
			height: 46px;
			margin-bottom: 10px;
			background-color: #ce181f;
			font-size: 16px;
			line-height: 28px;
			font-weight: bold;
			color: #ffffff;
			text-transform: uppercase;

			@media (max-width: 550px) {
				width: 28px;
				font-size: 12px;
			}
		}

		&-date,
		&-item__am,
		&-item__pm {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			max-width: 100%;
			height: 46px;
			margin-bottom: 10px;
			background-color: #f2f2f2;
		}

		&-date {
			display: flex;
			flex-direction: column;
			font-size: 14px;
			font-weight: 400;
			line-height: 16px;

			&__day {
				display: block;
				text-align: center;
			}

			&__digits {
				display: block;
				text-align: center;
			}
		}

		&-item {
			&--empty {
				background-color: #d9d9d9;
			}
		}
	}

	&-customer-service {
		border-bottom: 1px solid #d7d7d7;
	}

	&-cs {
		&-item {
			display: flex;
			padding: 10px 9px;

			&:nth-child(2n-1) {
				background-color: #f7f7f7;
			}

			input[type="radio"] {
				width: 20px;
				height: 20px;
				border-radius: 50%;

				&:checked {
					background: #fff url(../img/radio.svg) no-repeat center center;
					background-size: 10px 10px;
				}
			}
		}

		&-text {
			flex-grow: 1;
			margin-right: 10px;

			&__name {
				font-size: 16px;
				line-height: 28px;
				font-weight: 400;
				color: #2c2c2c;
			}

			&__desc {
				font-size: 13px;
				line-height: 16px;
				font-weight: 400;
				color: #757575;
			}
		}

		&-price {
			margin-top: 4px;
			font-size: 16px;
			line-height: 28px;
			font-weight: 400;
			color: #2c2c2c;
		}

		&-title {
			margin-bottom: 10px;
			font-size: 20px;
			line-height: 28px;
			font-weight: 400;
			color: #2c2c2c;
		}
	}

	&-book-btn {
		width: 319px;
		margin: 0 auto;
		max-width: 100%;
	}
}