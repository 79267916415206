.credit-check {
	padding-bottom: 100px;

	.credit-check-top-section {
		width: 849px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 0 45px;
		border-bottom: 1px solid #d7d7d7;
	}

	.credit-check-top {
		display: flex;
		width: 849px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		background-color: #f7f7f7;

		@media (max-width: 975px) {
			display: block;
		}

		&__car-pic {
			align-self: flex-start;
			width: 312px;
			min-width: 312px;
			max-width: 100%;
			position: relative;

			@media (max-width: 975px) {
				margin: 0 auto;
				width: 400px;
				max-width: 100%;
			}

			@media (max-width: 500px) {
				max-width: unset;
			}

			&::before {
				content: '';
				display: block;
				padding-top: calc(205% / 312 * 100);
			}

			img {
				max-width: 100%;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center center;
				font-family: 'object-fit: cover; object-position: center center;';
			}
		}
	}

	.bold {
		font-weight: bold;
	}

	.red {
		color: #d22320;
	}

	.yellow {
		color: #e59730;
	}

	.green {
		color: #21c679;
	}

	.finace-check {
		max-width: 607px;
		padding-top: 40px;
		margin-left: auto;
		margin-right: auto;

		.ah1 {
			font-size: 24px;
			line-height: 27px;
			font-weight: bold;
			text-align: center;
			margin-bottom: 18px;
		}

		.ah2 {
			font-size: 16px;
			text-align: center;
			margin-bottom: 34px;
		}

		.table-header {
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;

			.table-header-text {
				font-size: 22px;
				line-height: 25px;
				margin-left: 25px;
			}

			.text-editor {
				line-height: 25px;
				color: #ce171f;
				text-decoration: underline;
				margin-left: 30px;
				margin-right: 25px;

				&::before {
					content: '';
					display: inline-block;
					width: 16px;
					height: 16px;
					margin-top: -2px;
					margin-right: 4px;
					background: url("../img/edit.svg") no-repeat;
					background-size: contain;
					vertical-align: middle;
				}
			}
		}

		table {
			width: 100%;
			margin-bottom: 35px;
			border-bottom: 1px solid #e6e6e6;
			font-size: 15px;
			line-height: 18px;

			@media (max-width: 700px) {
				font-size: 14px;
			}

			@media (max-width: 500px) {
				font-size: 12px;
			}

			tr:nth-child(odd) td {
				background: #f7f7f7;
			}

			td {
				padding-left: 25px;
				padding-right: 25px;
				padding-top: 7px;
				padding-bottom: 7px;
			}

			tr td:last-child {
				width: 50%;
				text-align: right;
				box-sizing: border-box;
			}
		}

		.agree label {
			display: flex;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 16px;
			line-height: 26px;
			font-weight: 400;
			color: #2c2c2c;
			margin-bottom: 30px;

			input {
				width: 20px;
				min-width: 20px;
				height: 20px;
				margin-right: 9px;
				margin-bottom: 0;
				margin-top: 0;
				border: 1px solid #a5a5a5;
				background-color: #fff;
				cursor: pointer;
				-webkit-appearance: none;
				-moz-appearance: none;

				&:checked {
					background-image: url(../img/checked.svg);
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100%;
				}
			}
		}
	}

	.attention {
		border:1px solid #d7d7d7;
		display: flex;
		margin-bottom: 35px;

		.img {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			min-height: 108px;
			width: 108px;
			border-right: 1px solid #d7d7d7;

			img {
				max-width: 60px;
			}
		}

		.text {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px 25px;
			font-size: 14px;
			line-height: 26px;
			letter-spacing: -0.02em;
		}
	}

	.input-row {
		display: flex;
		justify-content: space-between;
		max-width: 100%;
		margin: 19px auto 0;

		@media (max-width: 500px) {
			display: block;
			margin-top: 0;
		}

		.input-block {
			max-width: 48.3%;
			width: 100%;

			@media (max-width: 500px) {
				margin-top: 20px;
				max-width: none;
			}

			label {
				display: block !important;
				margin-bottom: 1rem;
				vertical-align: top;
				font-size: 16px;
				color: #2c2c2c;
				user-select: none;
			}

			select {
				width: 100%;
				padding: 13px 15px;
				border: 1px solid #a5a5a5;
				border-radius: 0;
				outline: 0;
				background: #fff url(../img/select_bg.svg) no-repeat;
				background-position: calc(100% - 15px) center;
				font-size: 16px;
				line-height: 1em;
				-webkit-appearance: none;
				-moz-appearance: none;
				resize: none;
				box-sizing: border-box;
			}

			input {
				padding: 12px 15px;
				margin-right: 15px;
				border: 1px solid #a5a5a5;
				outline: 0;
				font-size: 16px;
				line-height: 1em;
				-webkit-appearance: none;
				-moz-appearance: none;
				box-sizing: border-box;

				@media (max-width: 500px) {
					display: block;
					margin-left: 0 !important;
					margin-top: 10px;
					margin-bottom: 10px;
				}

				&.ml35 {
					margin-left: 30px;
				}
			}

			.btn {
				display: inline-block;
				vertical-align: middle;
				margin-top: 31px;

				@media (max-width: 500px) {
					margin-top: 5px;
				}
			}

			&.many-input {
				input {
					width: 120px;
				}
			}
		}

		&.double_row {
			.input-block {
				max-width: none;
			}
		}

		&.border {
			padding-bottom: 30px;
			border-bottom: 1px solid  #d7d7d7;
		}

		&.third-input-row {
			@media (max-width: 700px) {
				display: block;
				margin-top: 0;
			}

			.input-block {
				max-width: 31%;

				@media (max-width: 700px) {
					max-width: none;
					margin-top: 20px;
				}
			}
		}
	}

	.buttons {
		text-align: center;

		& > *{
			display: inline-block;
			width: 265px;
			margin-top: 20px;
			margin-left: 12px;
			margin-right: 12px;
			font-size: 18px;
			vertical-align: middle;
		}

		.btn--white{
			border: 1px solid #58585a;
			color: #2c2c2c;
			box-sizing: border-box;
			transition: 0.3s all;

			@include hover {
				color: #fff;
				background: #58585a;
			}
		}
	}

	.next-block {
		max-width: 845px;
		padding-top: 30px;
		margin-left: auto;
		margin-right: auto;

		.buttons{
			padding-top: 40px;
		}
	}

	.third-block {
		max-width: 845px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 30px;

		.input-row .input-block .btn--red{
			width: 150px;
			margin-right: 35px;
			background: #ce171f;
			font-size: 18px;
			transition: 0.3s background;

			@include hover {
				background: #58585a;
			}
		}

		.input-row .input-block .enter-address {
			width: auto;
			text-decoration: underline;
			color: #2c2c2c;
		}

		.buttons {
			padding-top: 60px;
		}
	}

	.first-block {
		.crch-return-later {
			text-align: center;
			padding-left: 60px;
			padding-right: 60px;
		}
	}

	.crch-t-info {
		display: flex;
		flex-direction: column;
		min-height: 205px;
		width: 100%;
		padding: 26px 33px 24px 31px;
		box-sizing: border-box;

		@media (max-width: 500px) {
			width: 100%;
			min-height: unset;
			padding: 10px;
		}
	}

	.crch-t-info-top {
		display: flex;
		justify-content: space-between;
		flex-grow: 1;
		width: 100%;
		padding-bottom: 15px;

		@media (max-width: 500px) {
			display: block;
		}

		&__left,
		&__right {
			max-width: calc(50% - 5px);

			@media (max-width: 500px) {
				max-width: 100%;
			}
		}
	}

	.crch-t-info-top-text-style-1 {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 18px;
		line-height: 28px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.crch-t-info-top-text-style-2 {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 24px;
		line-height: 28px;
		font-weight: bold;
		color: #2c2c2c;
	}

	.crch-t-info-top-text-style-3 {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 14px;
		line-height: 28px;
		font-weight: 400;
		color: #2c2c2c;
	}

	.crch-t-info-bottom {
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 13px;
		line-height: 28px;
		font-weight: 400;
		color: #2c2c2c;
	}

	.credit-check-inputs-row {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-top: 30px;

		@media (max-width: 850px) {
			display: block;
		}

		.input-lane {
			width: calc(33% - 19px * 2 / 3);

			@media (max-width: 850px) {
				width: 400px;
				max-width: 100%;
				margin-left: auto;
				margin-right: auto;
				box-sizing: border-box;
			}

			@media (max-width: 500px) {
				width: 100%;
			}

			+ .input-lane {
				margin-top: 0;

				@media (max-width: 850px) {
					margin-top: 10px;
				}
			}

			&__title {
				display: block;
				vertical-align: top;
				font-size: 16px;
				line-height: 19px;
				color: #2c2c2c;
				margin-bottom: 8px;
			}

			&__input {
				display: block;
				width: 100%;
				height: 42px;
				padding: 0 35px 0 15px;
				border: 1px solid #a5a5a5;
				font-size: 16px;
				line-height: 19px;
				color: #2c2c2c;
				box-sizing: border-box;
			}

			.input-box {
				width: 100%;
				position: relative;

				.plus {
					width: 42px;
					height: 100%;
					background: #ce181e url(../img/input-plus.png) no-repeat center center;
					position: absolute;
					top: 0;
					right: 0;
					cursor: pointer;

					+ .input-lane__input {
						text-align: center;
						padding-left: 40px;
						padding-right: 40px;
					}
				}

				.minus {
					width: 42px;
					height: 100%;
					background: #ce181e url(../img/input-minus.png) no-repeat center center;
					position: absolute;
					top: 0;
					left: 0;
					cursor: pointer;
				}
			}
		}
	}

	.btn--credit-check-top {
		margin: 0 auto;
		margin-top: 25px;
		font-size: 18px;
	}

	.ryg-scale {
		width: 236px;
		max-width: 100%;
		background: url(../img/crche-shkala.png) no-repeat top center;
		background-size: 100% auto;
		color: #fff;
		position: relative;

		&::before {
			content: '';
			display: block;
			padding-top: calc(215% / 236 * 100);
		}

		&__arrow {
			position: absolute;
			left: 44%;
			top: 21%;
			transition-duration: 0.2s;
			transform-origin: 50% calc(100% - 20px);

			img {
				max-width: 100%;
				height: auto;
			}
		}

		&__text {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 270px;
			height: 60px;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
			border-radius: 5px;
			border: 1px solid #d9d9d9;
			background-color: #ffffff;
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 24px;
			font-weight: bold;
			line-height: 26px;
			text-transform: uppercase;
			position: absolute;
			left: 50%;
			top: 100%;
			transform: translate(-50%, -100%);
			box-sizing: border-box;
		}

		&--r {
			color: #d22320;

			.ryg-scale__arrow {
				transform: rotate(-90deg);
			}
		}

		&--y {
			color: #e59730;
		}

		&--g {
			color: #21c679;

			.ryg-scale__arrow {
				transform: rotate(90deg);
			}
		}
	}

	.lender-help {
		display: flex;
		align-items: center;
		width: 849px;
		max-width: 100%;
		margin: 46px auto 87px;

		@media (max-width: 700px) {
			flex-direction: column;
			align-items: center;
		}

		&__left {
			color: #2c2c2c;
			margin-right: 54px;

			h4 {
				font-size: 24px;
				font-weight: 400;
				line-height: 1.3;

				& + p {
					margin-top: 25px;
				}
			}

			p {
				font-size: 16px;
				font-weight: 400;
				line-height: 1.3;

				& + p {
					margin-top: 25px;
				}
			}
		}

		&__right {
			padding-right: 17px;

			@media (max-width: 700px) {
				margin-top: 10px;
			}
		}
	}

	.checked-quotes-title {
		margin-bottom: 25px;
		font-size: 24px;
		line-height: 26px;
		font-weight: 400;
		color: #2c2c2c;
		text-align: center;
		text-transform: uppercase;
	}

	.checked-quote {
		display: flex;

		@media (max-width: 700px) {
			display: block;
			padding: 15px;
		}

		&:nth-child(odd) {
			background-color: #f5f5f5;
		}

		&:last-child {
			border-bottom: 1px solid #d7d7d7;
		}
	}

	.checked-quote-info {
		padding: 24px 0 28px 22px;

		@media (max-width: 700px) {
			padding: 0;
			padding-bottom: 15px;
		}

		&__top {
			display: flex;
			justify-content: space-between;
			padding-right: 20px;
			margin: -5px;

			@media (max-width: 975px) {
				justify-content: flex-start;
				flex-wrap: wrap;
			}

			@media (max-width: 700px) {
				padding-right: 0;
				margin: -10px;
			}
		}
	}

	.checked-quote-info-part {
		display: block;
		margin: 5px;

		@media (max-width: 700px) {
			margin: 10px;
		}

		&__name {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 13px;
			line-height: 1.3;
			font-weight: 400;
			color: #757575;
		}

		&__value {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 16px;
			font-weight: 400;
			line-height: 1.3;
			color: #2c2c2c;
			text-transform: uppercase;
			margin-top: 5px;
		}
	}

	.checked-quote-info-bottom {
		margin-top: 20px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 13px;
		line-height: 22px;
		font-weight: 400;
		color: #757575;

		span {
			font-weight: bold;
			color: #2c2c2c;
			text-transform: uppercase;
		}
	}

	.cq-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 85px;
		height: 32px;
		margin-top: 24px;
		margin-right: 20px;
		font-size: 14px;
		line-height: 1;
		font-weight: bold;
		text-transform: uppercase;
		cursor: pointer;

		@media (max-width: 700px) {
			width: 300px;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}

	.btn-cq-save {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 250px;
		height: 46px;
		margin: 0 auto;
		margin-top: 22px;
		background-color: #ce171f;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 18px;
		line-height: 1;
		font-weight: bold;
		color: #ffffff;
		text-transform: uppercase;
		cursor: pointer;
		transition-duration: 0.2s;

		@include hover {
			background-color: darken(#ce171f, 10%);
		}
	}

	.crch-return-later {
		width: 847px;
		max-width: 100%;
		margin: 59px auto 0;
		padding: 47px 114px 62px;
		border: 1px solid #d7d7d7;
		box-sizing: border-box;

		@media (max-width: 700px) {
			padding: 30px 30px !important;
		}

		@media (max-width: 500px) {
			padding: 15px !important;
		}

		h4 {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 24px;
			line-height: 33px;
			font-weight: bold;
			color: #2c2c2c;
			text-align: center;

			+ p {
				margin-top: 38px;

				@media (max-width: 500px) {
					margin-top: 15px;
				}
			}
		}

		p {
			font-family: "Vauxhall Neue", sans-serif;
			font-size: 16px;
			line-height: 26px;
			font-weight: 400;
			color: #2c2c2c;

			+ .btn {
				margin-top: 44px;

				@media (max-width: 500px) {
					margin-top: 15px;
				}
			}

			+ p {
				margin-top: 31px;

				@media (max-width: 500px) {
					margin-top: 10px;
				}
			}
		}

		.btn {
			display: flex;
			align-items: center;
			justify-content: center;
			max-width: 100%;
			width: 459px;
			min-height: 46px;
			height: unset;
			margin: 0 auto;
			padding: 16px 15px;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.3;
			cursor: pointer;
			box-sizing: border-box;

			@media (max-width: 500px) {
				padding: 10px !important;
				font-size: 16px;
			}

			&.button--white {
				padding: 15px 14px;
				border: 1px solid #58585a;
			}

			+ .btn {
				margin-top: 20px;
			}
		}

		.agree {
			label {
				display: flex;
				justify-content: center;
				font-family: "Vauxhall Neue", sans-serif;
				font-size: 16px;
				line-height: 26px;
				font-weight: 400;
				color: #2c2c2c;
				margin-bottom: 30px;
				margin-top: 30px;

				input {
					min-width: 20px;
					width: 20px;
					height: 20px;
					border: 1px solid #a5a5a5;
					background-color: #fff;
					margin-right: 9px;
					margin-bottom: 0;
					margin-top: 0;
					-webkit-appearance: none;
					-moz-appearance: none;
					cursor: pointer;

					&:checked {
						background-image: url(../img/checked.svg);
						background-repeat: no-repeat;
						background-position: center center;
						background-size: 100%;
					}
				}
			}
		}
	}
}
