.service-plan-contract{
  &__container{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__flex{
    display: flex;
    justify-content: space-between;
    @media (max-width: 900px) {
      display: block;
    }
    +.service-plan-contract__flex{
      margin-top: 40px;
    }
  }
  &__left{
    width: calc(100% - 389px);
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  &__right{
    width: 359px;
    @media (max-width: 900px) {
      width: 100%;
      margin-top: 40px;
    }
  }
  &__title{
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      font-size: 25px;
      line-height: 30px;
    }
    span{
      font-size: 15px;
      color: #9da4b0;
      margin-left: 5px;
      display: inline-block;
    }
  }
  &__text{
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 17px;
    }
    strong{
      font-weight: 700;
    }
    span{
      color: #ce181f;
    }
    > p + p{
      margin-top: 23px;
      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }
  &__main{
    padding-bottom: 30px;
    margin-bottom: 23px;
    border-bottom: 1px solid #e6e6e6;
  }
  &__block{
    +.service-plan-contract__block{
      margin-top: 30px;
    }
  }
  &__subtitle{
    font-size: 22px;
    line-height: 33px;
    margin-bottom: 18px;
    @media (max-width: 767px) {
      font-size: 19px;
      line-height: 25px;
    }
  }
  &__list{
    &-item{
      +.service-plan-contract__list-item{
        margin-top: 18px;
      }
    }
    &-title{
      font-size: 14px;
      line-height: 16px;
      color: #9da4b0;
      @media (max-width: 767px) {
        font-size: 13px;
        line-height: 15px;
      }
    }
    &-text{
      font-size: 18px;
      line-height: 28px;
      @media (max-width: 767px) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }
  table{
    border-collapse: collapse;
    width: calc(100% + 8px);
    margin-left: -8px;
    thead{
      tr{
        th{
          font-size: 14px;
          line-height: 15px;
          padding: 0 8px 8px;
          color: #9da4b0;
          font-weight: 400;
          text-align: left;
        }
      }
    }
    tbody{
      tr{
        td{
          padding: 12px 8px;
          font-size: 15px;
          line-height: 16px;
          text-align: left;
          @media (max-width: 767px) {
            font-size: 14px;
            line-height: 15px;
          }
        }
        &:nth-child(odd){
          td{
            background-color: #f7f7f7;
          }
        }
      }
    }
    &.service-plan-contract__price{
      tbody{
        tr{
          td{
            &:last-child{
              text-align: right;
            }
          }
        }
      }
    }
  }
  &__check{
    padding-left: 24px;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 14px;
      height: 10px;
      background: url("../img/checked.svg") center center no-repeat;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
  &__download{
    color: #124390;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0 31px;
    background: transparent;
    border: 1px solid #124390;
    width: auto;
    max-width: 100%;
    font-size: 14px;
    margin-top: 30px;
    svg{
      margin-right: 10px;
    }
    &:hover{
      background: #124390;
      color: #ffffff;
    }
  }
  &__btn{
    width: auto;
    font-size: 14px;
    padding: 0 30px;
    min-width: 150px;
    max-width: 100%;
  }
  &__code{
    display: none;
    border-top: 1px solid #e6e6e6;
    padding-top: 23px;
    &-text{
      font-size: 14px;
      line-height: 23px;
      @media (max-width: 767px) {
        line-height: 17px;
      }
    }
    &-row{
      display: flex;
      align-items: center;
      margin-top: 15px;
      flex-wrap: wrap;
    }
    &-form{
      display: flex;
      button{
        font-size: 14px;
        width: auto;
        margin-top: 0;
        margin-left: 10px;
      }
    }
    &-input{
      margin-top: 0;
      input{
        margin-top: 0 !important;
        font-size: 14px;
        &::placeholder{
          font-size: 14px;
        }
      }
    }
    &-new{
      color: #ce171f;
      font-size: 14px;
      font-weight: 400;
      text-decoration: underline;
      margin-left: 24px;
      margin-top: 12px;
      margin-bottom: 12px;
      display: inline-block;
      &:hover{
        text-decoration: none;
      }
    }
  }

}