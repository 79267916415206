.pagi {
	ul {
		display: flex;
		justify-content: center;

		li {
			width: 33px;
			text-align: center;
			border: 1px solid #d7d7d7;
			font-size: 16px;
			line-height: 33px;

			&.control {
				&:first-child {
					border-top-left-radius: 5px;
					border-bottom-left-radius: 5px;
				}

				&:last-child {
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
			}

			& + li {
				border-left: 0;
			}

			* {
				display: block;
				color: #2c2c2c;
			}

			span {
				color: #fff;
				background: #ce171f;
			}

			.pagination-link {
				color: #000;
				background-color: #fff;
				cursor: pointer;

				@include hover {
					&:not(.disabled) {
						background-color: #a5a5a5;
						text-decoration: underline;
					}
				}

				&.disabled {
					color: #d3d3d3;
					cursor: default;
				}

				&.current-page {
					color: #fff;
					background-color: #ce171f;
					cursor: default;

					@include hover {
						background-color: #ce171f;
						text-decoration: none;
					}
				}
			}

			a {
				@include hover {
					text-decoration: underline;
					background: #a5a5a5;
				}
			}
		}
	}
}