.drilldown-log {
	.main-flex {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		max-width: 1526px;
		margin: 0 auto;
		padding-top: 44px;
		padding-bottom: 100px;

		@media (max-width: 700px) {
			display: block;
			width: 100%;
			padding: 35px 15px 50px;
			box-sizing: border-box;
		}
	}

	.logs-menu {
		width: 217px;
		padding-left: 46px;
		margin-right: 40px;
		flex-shrink: 0;

		@media (max-width: 700px) {
			width: 100%;
			margin: 0;
			padding: 0;
		}

		.label {
			font-size: 20px;
			font-weight: 400;
			color: #2c2c2c;
			position: relative;
			padding-bottom: 12px;

			&::after {
				content: '';
				display: block;
				width: 90%;
				height: 1px;
				background-color: #ce171f;
				position: absolute;
				bottom: 0;
				left: 0;

				@media (max-width: 700px) {
					width: 100%;
				}
			}
		}

		.menu-list {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				margin-top: 15px;

				a {
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					color: #2c2c2c;
					list-style: none;

					&.active {
						color: #ce171f;
					}

					@include hover {
						color: #ce171f;
					}
				}
			}
		}
	}

	.log {
		width: auto;
		max-width: calc(100% - 303px);
		flex-grow: 1;

		@media (max-width: 700px) {
			margin-top: 30px;
			max-width: 100%;
		}

		.filters {
			justify-content: flex-start;
			margin: 0 0 25px;

			.input-block {
				margin: 0;

				label {
					display: inline-block;
					line-height: 40px;
					vertical-align: top;
				}

				input {
					width: 200px;
					margin-left: 10px;
					padding: 9px 15px 10px;
				}
			}
			.apply {
				width: 120px;
				margin: 0;
				margin-left: 15px;
			}
		}

		.filters {
			justify-content: center;
		}

		.table-wrap {
			@media (max-width: 700px) {
				width: calc(100% + 30px);
				margin-left: -15px;
				margin-right: -15px;
			}
		}

		.table {
			display: table;
			width: 100%;
			box-sizing: border-box;

			.headline {
				height: 50px;
			}

			.row {
				display: table-row;

				.col {
					display: table-cell;
					width: auto!important;
					padding: 9px 12px;
					vertical-align: middle;

					.name-before {
						content: '';
						display: inline-block;
						width: 16px;
						height: 14px;
						margin-top: -2px;
						margin-right: 3px;
						vertical-align: middle;
						background: url(../img/name.png) no-repeat;
						transition-duration: 0.2s;
					}

					.name {
						white-space: nowrap;
					}

					.datepicker-input {
						width: 160px;
						padding: 9px 15px 10px;
						border: 1px solid #a5a5a5;
						border-radius: 0;
						outline: 0;
						background: #fff url(../img/datepicker.png) no-repeat;
						background-position: calc(100% - 9px) center;
						font-size: 16px;
						line-height: 1em;
						resize: none;
						box-sizing: border-box;
					}
				}

				&.content {
					.col {
						border-bottom: 1px solid #e6e6e6;
					}
				}
			}
		}

		.btns-row {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			@media (max-width: 700px) {
				display: flex;
				justify-content: center;
				margin-top: 21px;

				.save-btn {
					width: 195px;
					margin: 0;
					background-color: #124390;
					font-size: 16px;
					font-weight: bold;
					color: #ffffff;

					&:before {
						content: '';
						display: inline-block;
						width: 18px;
						height: 18px;
						margin-right: 8px;
						margin-top: -5px;
						vertical-align: middle;
						background: url(../img/save-img.png) no-repeat center center;
					}

					@include hover {
						background-color: #122d5b;
					}
				}
			}
		}
	}
}
