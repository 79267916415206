.motability-page {
	&__inner {
		max-width: 1140px;
	}

	.motability {
		padding: 14px 0 95px;
		color: #2c2c2c;

		&__banner {
			width: 100%;
			height: 400px;
			margin-bottom: 50px;

			@media (max-width: 767px) {
				height: 300px;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				font-family: 'object-fit: cover;';
			}
		}

		&__title {
			font-size: 24px;
			line-height: 30px;
			margin-bottom: 21px;

			@media (max-width: 767px) {
				text-align: center;
			}
		}

		&__subtitle {
			font-size: 18px;
			line-height: 30px;
			margin-bottom: 12px;

			@media (max-width: 767px) {
				text-align: center;
			}
		}

		&__text {
			font-size: 15px;
			line-height: 24px;
			color: #4d4d4d;

			@media (max-width: 767px) {
				font-size: 14px;
				line-height: 22px;
			}

			p + p {
				margin-top: 24px;

				@media (max-width: 767px) {
					margin-top: 16px;
				}
			}

			&--contacts {
				color: #2c2c2c;

				p {
					margin-bottom: 12px;
				}

				a {
					color: #2c2c2c;
					transition: color 0.3s ease;

					@media (min-width: 1024px) {
						&:hover {
							color: #ce181f;
						}
					}
				}
			}
		}

		&__content {
			margin-bottom: 58px;

			@media (max-width: 767px) {
				margin-bottom: 40px;
			}
		}

		&__content-wrapper {
			display: flex;
			margin: 0 -28px 40px;

			@media (max-width: 767px) {
				flex-direction: column;
				margin: 0 0 30px;
			}
		}

		&__col {
			width: calc(50% - 56px);
			margin: 0 28px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0;
			}

			&:last-child {
				@media (max-width: 767px) {
					margin-top: 30px;
				}
			}
		}

		&__make {
			height: 40px;
			margin-top: 21px;
			font-size: 14px;
			line-height: 40px;
		}

		&__video {
			margin-top: 7px;

			&::before {
				padding-top: 59%;
			}
		}

		&__tabs {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #ce171f;
			margin-bottom: 35px;

			@media (max-width: 767px) {
				flex-direction: column;
				border-bottom: none;
				margin-bottom: 20px;
			}
		}

		&__tab {
			padding: 8px 38px;
			margin-bottom: -1px;
			border: 1px solid transparent;
			border-radius: 5px 5px 0 0;
			font-size: 18px;
			color: #4d4d4d;
			cursor: pointer;
			transition: all 0.3s ease;
			box-sizing: border-box;
			position: relative;

			@media (max-width: 767px) {
				width: 100%;
				padding: 6px 15px;
				border-radius: 5px;
				font-size: 15px;
				text-align: center;
			}

			+ .motability__tab::before {
				content: '';
				display: block;
				width: 1px;
				height: 44%;
				background-color: #e6e6e6;
				border-radius: 1px 1px 0 0;
				position: absolute;
				left: 0;
				top: 50%;
				transform: translateY(-50%);

				@media (max-width: 767px) {
					display: none;
				}
			}

			&.active {
				background-color: #ce171f;
				color: #fff;

				&::before {
					display: none;
				}

				+ .motability__tab::before {
					display: none;
				}
			}
		}

		&__content-item {
			display: none;
		}

		&__tabs-content-wrapper {
			display: flex;
			margin: 0 -26px;

			@media (max-width: 767px) {
				flex-direction: column;
				margin: 0;
			}
		}

		&__tabs-content-col {
			width: calc(50% - 52px);
			margin: 0 26px;

			@media (max-width: 767px) {
				width: 100%;
				margin: 0;
			}

			&:last-child {
				@media (max-width: 767px) {
					margin-top: 20px;
				}
			}
		}

		&__list {
			margin-top: 31px;

			@media (max-width: 767px) {
				margin-top: 15px;
			}

			li {
				font-size: 14px;
				line-height: 18px;
				color: #4d4d4d;
				padding-left: 14px;
				position: relative;

				+ li {
					margin-top: 14px;
				}

				&::before {
					content: '';
					display: block;
					width: 5px;
					height: 5px;
					background-color: #ce171f;
					position: absolute;
					top: 6px;
					left: 1px;
				}
			}
		}

		&__text-block {
			+ .motability__text-block {
				margin-top: 24px;
			}
		}

		&__phone {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			+ .motability__phone {
				margin-top: 6px;
			}

			a {
				display: flex;
				align-items: center;
				margin-bottom: 6px;
				font-size: 15px;
				color: #2c2c2c;
				transition: color 0.3s ease;

				@media (max-width: 767px) {
					font-size: 14px;
				}

				@media (min-width: 1024px) {
					&:hover {
						color: #ce181f;
					}
				}
			}

			svg {
				width: 16px;
				height: 16px;
				fill: #ce181f;
				margin-right: 5px;
			}
		}
	}
}
