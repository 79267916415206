.video-page-editing {
	padding-bottom: 100px;

	.page-main-title {
		margin-bottom: 32px;
	}

	.button_block {
		text-align: right;

		@media (max-width: 999px) {
			display: none;
			text-align: center;
		}

		a {
			display: inline-block;
			vertical-align: top;
			font-size: 13px;

			@media (max-width: 999px) {
				margin-right: 7px;
				margin-bottom: 10px;
				margin-left: 7px;
			}

			&.btn--blue::before {
				content: '';
				display: inline-block;
				width: 18px;
				height: 18px;
				margin-top: -3px;
				margin-right: 10px;
				vertical-align: middle;
				background: url("../img/save.svg") no-repeat;
			}

			&.btn--gray {
				margin-left: 7px;
				background: #4d4d4d;

				@include hover {
					opacity: 0.9;
				}
			}
		}

		&.button_block2 {
			text-align: center;

			@media (max-width: 999px) {
				display: block;
			}
		}
	}

	.ah2 {
		margin-bottom: 18px;
		font-size: 22px;
		line-height: 25px;
		font-weight: bold;

		&.second {
			margin-top: 20px;
		}
	}

	.ah3 {
		margin-bottom: 12px;
		font-size: 18px;
		font-weight: bold;
	}

	.ah4 {
		margin-bottom: 3px;
		font-size: 16px;

		span {
			color: #a6a6a6;
			font-size: 13px;
		}
	}

	input[type="text"],
	textarea {
		width: 100%;
		margin-bottom: 17px;
		padding: 8px 15px;
		border: 1px solid #a5a5a5;
		outline: 0;
		font-size: 16px;
		line-height: 1em;
		-webkit-appearance: none;
		-moz-appearance: none;
		box-sizing: border-box;
	}

	textarea {
		height: 138px;
		margin-bottom: 29px;
	}

	.flex {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding-bottom: 31px;
		border-bottom: 1px solid #e6e6e6;

		@media (max-width: 999px) {
			display: block;
		}

		& > div {
			width: 100%;
		}

		.block {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 248px;
			border: 1px solid #a5a5a5;
			background: #eef3fb;

			span {
				color: #026fa8;
				font-size: 18px;

				&::before {
					content: '';
					display: inline-block;
					width: 20px;
					height: 20px;
					margin-top: -2px;
					margin-right: 8px;
					vertical-align: middle;
					background: url("../img/file.svg") no-repeat;
				}
			}

			.img {
				width: 100%;
				height: 100%;
				background-position: center center;
				background-size: cover;
				position: absolute;
			}

			input {
				width: 100%;
				height: 100%;
				cursor: pointer;
				opacity: 0;
				position: absolute;
			}
		}

		.left_block {
			flex-shrink: 0;
			width: 65%;
			margin-right: 23px;

			@media (max-width: 999px) {
				width: auto;
				margin-right: 0;
				margin-bottom: 25px;
			}
		}
	}

	.check_block {
		display: flex;
		margin-bottom: 15px;
		font-family: "Vauxhall Neue", sans-serif;
		font-size: 16px;
		line-height: 22px;
		font-weight: 400;
		color: #2c2c2c;

		input {
			width: 20px;
			min-width: 20px;
			height: 20px;
			margin-top: 0;
			margin-right: 9px;
			margin-bottom: 0;
			margin-left: 0;
			cursor: pointer;
			border: 1px solid #a5a5a5;
			background-color: #fff;
			-webkit-appearance: none;
			-moz-appearance: none;

			&:checked {
				background-image: url(../img/checked.svg);
				background-repeat: no-repeat;
				background-position: center center;
				background-size: 100%;
			}
		}

		i {
			font-size: 13px;
			font-style: normal;
			color: #a6a6a6;
		}
	}

	hr {
		height: 1px;
		margin-bottom: 30px;
		border: 0;
		background: #e6e6e6;
	}

	.add-module {
		display: block;
		margin-bottom: 30px;
		background: #eef3fb;
		font-size: 13px;
		line-height: 49px;
		text-align: center;
		color: #026fa8;

		&::before {
			content: '';
			display: inline-block;
			width: 20px;
			height: 20px;
			margin-top: -2px;
			margin-right: 8px;
			vertical-align: middle;
			background: url("../img/file.svg") no-repeat;
		}
	}

	.a_block {
		margin-bottom: 30px;
		padding-bottom: 30px;
		border-bottom: 1px solid #e6e6e6;

		& > .btn-vc-module-add-btn {
			margin-top: 20px;
		}

		.top_buttons {
			float: right;
			margin-bottom: 8px;

			@media (max-width: 350px) {
				width: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			a {
				display: inline-block;
				margin-left: 5px;
				padding: 0 10px;
				vertical-align: top;
				font-size: 13px;
				line-height: 28px;
				transition: opacity 0.2s;

				@media (max-width: 810px) {
					margin-bottom: 10px;
				}

				@media (max-width: 350px) {
					width: 100%;
					margin-bottom: 10px;
					margin-left: 0;
					box-sizing: border-box;
				}

				@include hover {
					opacity: 0.8;
				}

				&.red {
					color: #ce171f;
					border: 1px solid #ce171f;

					&::before {
						content: '';
						display: inline-block;
						width: 13px;
						height: 13px;
						margin-top: -2px;
						margin-right: 6px;
						vertical-align: middle;
						background: url("../img/close-btn-2.png") no-repeat;
						background-size: 100% auto;
					}
				}

				&.blue {
					color: #1077ad;
					border: 1px solid #1077ad;
					background: #eef3fb;

					&::before {
						content: '';
						display: inline-block;
						width: 13px;
						height: 13px;
						margin-top: -2px;
						margin-right: 6px;
						vertical-align: middle;
						background: url("../img/insert.svg") no-repeat;
						background-size: 100% auto;
					}
				}
			}
		}

		.title {
			float: left;
			font-weight: bold;
			font-size: 18px;
			line-height: 30px;

			@media (max-width: 810px) {
				line-height: 1.3;
				margin-bottom: 10px;
			}
		}

		.oveflow-vc-modue-table {
			overflow-x: auto;

			table {
				min-width: 768px;
				margin-bottom: 5px;
			}
		}

		table {
			width: 100%;

			tr {
				display: table-row !important;
			}

			tr:nth-child(even) {
				background: #f2f2f2;
			}

			td {
				width: auto !important;
				height: 70px;
				padding: 7px 10px;
				vertical-align: middle;
				box-sizing: border-box;

				input {
					width: 100%;
					padding: 8px 15px;
					margin-bottom: 0;
					border: 1px solid #a5a5a5;
					outline: 0;
					font-size: 16px;
					line-height: 1em;
					-webkit-appearance: none;
					box-sizing: border-box;

					&[type="number"] {
						padding-right: 0;
					}
				}
			}

			th {
				padding: 6px 10px;
				font-size: 16px;
				text-align: left;
			}
		}

		tr {
			td {
				&:first-child {
					width: calc(100% - 116px) !important;
				}

				&:nth-child(2) {
					width: 116px !important;
				}

				&:nth-child(3) {
					width: 150px !important;
				}

				&:nth-child(5) {
					text-align: right;
				}

				&:nth-child(6) {
					width: 100px !important;
					text-align: right;
				}
			}
		}

		.image_block {
			width: 67px;
			height: 50px;
			background-size: cover;
			background-position: center center;
			border: 1px solid #e6e6e6;
			position: relative;

			.del{
				position: absolute;
				width: 15px;
				height: 15px;
				border-radius: 50%;
				right: 2px;
				top: 2px;
				background: url(../img/red_x.svg) no-repeat center center #ccc;
				background-size: 8px auto;
			}
		}

		.dell {
			margin-left: 15px;
			font-size: 14px;
			line-height: 37px;
			text-decoration: underline;
			color: #f01019;

			&::before {
				content: '';
				display: inline-block;
				width: 13px;
				height: 13px;
				margin-top: -2px;
				margin-right: 6px;
				vertical-align: middle;
				background: url("../img/close-btn-2.png") no-repeat;
				background-size: 100% auto;
			}
		}

		.upload {
			width: 70px;
			height: 30px;
			position: relative;
			border: 0;
			font-size: 14px;

			&:before {
				content: 'Upload';
				width: 100%;
				height: 100%;
				background: #026fa8;
				color: #fff;
				text-align: center;
				line-height: 30px;
				position: absolute;
				left: 0;
				top: 0;
				cursor: pointer;
				transition: 0.3s background-color;
			}

			@include hover {
				&::before {
					background-color: #58585a;
				}
			}
		}

		.save {
			font-size: 14px;
			line-height: 37px;
			text-decoration: underline;
			color: #f01019;
		}

		select {
			margin-bottom: 0;
		}
	}

	select {
		width: 100%;
		max-width: 180px;
		margin-bottom: 15px;
		padding: 9px 28px 10px 15px;
		border: 1px solid #a5a5a5;
		border-radius: 0;
		outline: 0;
		background: #fff url(../img/select_bg.svg) no-repeat;
		background-position: calc(100% - 15px) center;
		font-size: 16px;
		line-height: 1em;
		-webkit-appearance: none;
		-moz-appearance: none;
		resize: none;
		box-sizing: border-box;

		&::-ms-expand {
			display: none;
		}
	}

	.vpe-datas-row {
		display: flex;
		margin: 0 -10px;

		@media (max-width: 620px) {
			flex-direction: column;
		}

		label {
			line-height: 45px;
			margin-right: 15px;

			@media (max-width: 620px) {
				line-height: 24px;
			}
		}

		.input-block {
			white-space: nowrap;
			display: flex;
			margin: 0 10px;

			@media (max-width: 620px) {
				justify-content: space-between;
			}

			@media (max-width: 620px) {
				flex-direction: column;
			}

			input {
				@media (max-width: 620px) {
					max-width: 250px;
				}

				@media (max-width: 620px) {
					max-width: 100%;
				}
			}
		}
	}

	.btn-vc-module-add-btn {
		margin-top: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 154px;
		height: 30px;
		color: #ffffff;
		background-color: #124390;
		font-weight: 400;
		font-size: 13px;
		line-height: 64px;
		transition-duration: .2s;
		cursor: pointer;

		@media (max-width: 350px) {
			width: 100%;
			height: 45px;
			margin-left: 0;
		}

		&::before {
			content: '';
			display: block;
			width: 12px;
			height: 12px;
			margin-right: 12px;
			background: url('../img/white-plus.png');
		}

		@include hover {
			opacity: 0.8;
		}
	}
}
