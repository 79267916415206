.express-store {
	+.footer {
		margin-top: 0;
	}

	&__slide {
		-webkit-background-size: cover;
		background-size: cover;
		background-position: center;
	}

	&__row {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	&__title {
		font-size: 50px;
		line-height: 52px;
		font-weight: bold;
		text-align: center;
		color: #fff;
	}

	&__subtitle {
		color: #fff;
		font-size: 24px;
		line-height: 28px;
		text-align: center;

		&--center {
			margin: 8px 0 16px;
		}
	}

	&__row-buttons {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__button {
		margin: 11.5px 11.5px;
		border-radius: 5px;
	}
}
